import { AutoFactory, DynamicApplicationDocumentSetting, DynamicApplicationDocumentSettingsService, LoanApplication } from '@sageworks/jpi';
import { defineModule, localActionContext } from 'direct-vuex';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => localActionContext(context, DocumentSettingsModule);

export interface DocumentSettingsModuleState {
	documentSettings: DynamicApplicationDocumentSetting;
}
const DocumentSettingsModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			documentSettings: {} as DynamicApplicationDocumentSetting
		} as DocumentSettingsModuleState;
	},
	mutations: {
		SET_DOCUMENT_SETTINGS(state, documentSettings: DynamicApplicationDocumentSetting) {
			state.documentSettings = documentSettings;
		}
	},
	actions: {
		async fetch(context, applicationType: LoanApplication.TypeEnum): Promise<void> {
			const { commit } = actionContext(context);
			const documentSettingsService = AutoFactory.get(DynamicApplicationDocumentSettingsService);
			commit.SET_DOCUMENT_SETTINGS(await documentSettingsService.getByApplicationType(applicationType));
		}
	}
});

export default DocumentSettingsModule;
