import ApplicantBusinessQuestionWidget from './applicant-business-question';
import { ComponentDataHelper } from '../../../utils';
import { FetchDataType, UpdateDataType, DataObject } from '../../../enums';
import { ObjectPropertyValues, ProposedLoanSBAInfo } from '@sageworks/jpi';
import { FetchDataHookOnChangeHandler } from '../../../models';

export interface ApplicantBusinessQuestionServiceOptions {
	onSbaInfoChange?: FetchDataHookOnChangeHandler;
}

export class ApplicantBusinessQuestionService {
	public get sbaInfo() {
		return this._sbaInfo;
	}

	private _sbaInfo: any;
	private _sbaInfoChangeHandler: FetchDataHookOnChangeHandler;

	constructor(private component: ApplicantBusinessQuestionWidget, private options: ApplicantBusinessQuestionServiceOptions = {}) {
		this._sbaInfoChangeHandler = this.onSbaInfoChange.bind(this);
	}

	public destroy() {
		// Need to remove sba data on change handler so it doesn't get called in the future
		if (this.sbaInfo && this.sbaInfo.id) {
			ComponentDataHelper.removeOnChangeHandler(this.component, {
				onChangeHandler: this._sbaInfoChangeHandler,
				cacheKey: this.createSbaInfoCacheKey(this.sbaInfo.id)
			});
		}
	}

	public async loadData() {
		const loanId = await ComponentDataHelper.fetchData<number | null>(this.component, { fetchType: FetchDataType.CurrentProposedLoanId });

		if (!loanId || loanId <= 0) return;

		this._sbaInfo = await ComponentDataHelper.fetchData<any>(this.component, {
			fetchType: FetchDataType.SbaInfo,
			fetchContext: { loanId },
			cacheKey: this.createSbaInfoCacheKey(loanId),
			onChange: this._sbaInfoChangeHandler
		});
	}

	public async updateApplicantBusiness(businessId?: number) {
		if (!this.sbaInfo || !this.sbaInfo.id) return;

		const { id } = this.sbaInfo;

		const updatedSbaInfo: ProposedLoanSBAInfo = {
			id,
			applicantBusinessCustomerId: businessId
		};

		this._sbaInfo = await ComponentDataHelper.updateData(this.component, {
			updateType: UpdateDataType.SbaInfo,
			updateContext: { sbaInfo: updatedSbaInfo },
			cacheKey: this.createSbaInfoCacheKey(id)
		});
	}

	public async getBusiness(businessId: number) {
		return await ComponentDataHelper.fetchData<ObjectPropertyValues>(this.component, {
			fetchType: FetchDataType.DataObject,
			fetchContext: { type: DataObject.TypeEnum.Business, id: businessId }
		});
	}

	public async getCurrentApplicantBusiness() {
		if (!this.sbaInfo || !this.sbaInfo.applicantBusinessCustomerId) return null;

		const { applicantBusinessCustomerId: currentApplicantBusinessId } = this.sbaInfo;
		return this.getBusiness(currentApplicantBusinessId);
	}

	private onSbaInfoChange(newValue: any) {
		this._sbaInfo = newValue;

		if (this.options.onSbaInfoChange) {
			this.options.onSbaInfoChange(this._sbaInfo);
		}
	}

	private createSbaInfoCacheKey(loanId: number) {
		return `sba-data-${loanId}`;
	}
}
