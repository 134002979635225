import editForm from './retirement-account.form';
import { CustomComponentType, CustomComponentLabel, RetirementAccountCustomFieldKey } from '../../../../enums';
import { FinancialSubaccount } from '../financial-subaccount/financial-subaccount';

export class RetirementAccountWidget extends FinancialSubaccount {
	static schema(...extend: any) {
		return FinancialSubaccount.schema(
			{
				label: CustomComponentLabel()[CustomComponentType.retirementAccount],
				type: CustomComponentType.retirementAccount,
				key: CustomComponentType.retirementAccount,
				addAnother: 'Add Retirement Account'
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: CustomComponentLabel()[CustomComponentType.retirementAccount],
			group: '',
			weight: 10,
			schema: RetirementAccountWidget.schema()
		};
	}

	protected getFormattedDescription(_: string, row: any) {
		return `${row.data?.[RetirementAccountCustomFieldKey.NameOfInstitution]} - ${row.data?.[RetirementAccountCustomFieldKey.RetirementAccountType]}`;
	}
}
