import { CustomComponentType } from '../../../../enums';
import Component from 'formiojs/components/_classes/component/Component';
import { LoanApplicationCreditAuthorization, CreditReportCredential } from '@sageworks/jpi';
import { getAllCredentials } from '../helpers';

export default class CreditReportCredentialsInput extends Component {

	private credentialsPromise!: Promise<CreditReportCredential[]>;
	private credentials: CreditReportCredential[] = [];

	public loanAppAuthorizationStatus!: Promise<LoanApplicationCreditAuthorization>;
	private resultResolve!: (creditAuthorization: LoanApplicationCreditAuthorization) => void;

	public static schema(...extend: any) {
		return Component.schema(
			{
				label: 'Credit Report Credentials Input',
				type: CustomComponentType.creditReportCredentialsInput,
				key: CustomComponentType.creditReportCredentialsInput
			},
			...extend
		);
	}

	public init() {
		this.loanAppAuthorizationStatus = new Promise(resolve => (this.resultResolve = resolve));
	}

	public render(): any {
		return super.renderTemplate('creditReportCredentials', {
			credentials: this.credentials,
		});
	}

	public async attach(element: any) {
		await super.attach(element);

		await this.getCredentialsAndRedraw();

		this.loadRefs(element, {
			backButton: 'single',
			selectCredentialButton: 'multiple'
		});

		this.removeEventListener(this.refs.backButton, 'click');
		this.addEventListener(this.refs.backButton as any, 'click', this.backButtonClick);

		for (let selectCredButton of this.refs.selectCredentialButton){
			this.removeEventListener(selectCredButton, 'click');
			this.addEventListener(selectCredButton as any, 'click', this.selectEvent);
		}
	}

	public async getCredentialsAndRedraw() {
		let shouldRedraw = false;

		if (!this.credentialsPromise) {
			this.credentialsPromise = getAllCredentials();
			shouldRedraw = true;
		}

		this.credentials = await this.credentialsPromise;

		if (shouldRedraw) {
			this.redraw();
		}
	}

	private getCredentialById(id: number): CreditReportCredential | null {
		let credential = this.credentials
			?.filter(c => c.id === id)
			?.pop();

		return credential ?? null;
	}

	/**
	 *  Arrow functions to preserve `this` property
	 */
	private backButtonClick = async () => {

		this?.options?.selectCredential(null);
	};

	private selectEvent = async (e: any) => {
		let id = parseInt(e.srcElement.dataset.credentialid);
		let credential = this.getCredentialById(id);

		this?.options?.selectCredential(credential);
	};

}