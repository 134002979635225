import {
	CashAccountCustomFieldKey,
	CustomComponentType,
	DataObject,
	FinancialSubaccountParent,
	LiabilityCustomFieldKey,
	LiabilityTypeOption,
	LifeInsuranceCustomFieldKey,
	RetirementAccountCustomFieldKey,
	TemplateDataField
} from '../../../enums';
import { ObjectPropertyValues } from '@sageworks/jpi';
import { createDataObjectFormObject, FactoryContext } from '../form-object-factory';
import * as OtherLiabilitiesUtils from '../../other-liabilities-utilities';
import { ObjectPropertyValuesWithRoleType } from '../../../models';
import _ from 'lodash';

export const createOtherLiabilitiesFormObject = (
	dataObject: ObjectPropertyValues,
	{ financialSubaccounts }: FactoryContext,
	parentLoanRoleId?: number | null
) => {
	const formObject = createDataObjectFormObject(dataObject, parentLoanRoleId);
	const subaccount = financialSubaccounts.find(x => x.id === dataObject.id);

	if (!subaccount) {
		return formObject;
	}

	const parent = subaccount.parent! as FinancialSubaccountParent;
	const [name, type] = (subaccount.name ?? '').split(' - ');

	formObject.data[LiabilityCustomFieldKey.DueDate] = OtherLiabilitiesUtils.calculateDueDate(parent);
	formObject.data[LiabilityCustomFieldKey.DueTo] = name.trim();
	formObject.data[LiabilityCustomFieldKey.LiabilityType] = OtherLiabilitiesUtils.calculateLiabilityType(type?.trim() as LiabilityTypeOption, parent);

	return formObject;
};

export const createCashAccountsFormObject = (dataObject: ObjectPropertyValues, { financialSubaccounts }: FactoryContext, parentLoanRoleId?: number | null) => {
	const formObject = createDataObjectFormObject(dataObject, parentLoanRoleId);
	const subaccount = financialSubaccounts.find(x => x.id === dataObject.id);

	if (!subaccount) {
		return formObject;
	}

	const [name, type] = (subaccount.name ?? '').split(' - ');

	formObject.data[CashAccountCustomFieldKey.Name] = name.trim();
	formObject.data[CashAccountCustomFieldKey.AccountType] = type.trim();

	return formObject;
};

export const createLifeInsuranceFormObject = (dataObject: ObjectPropertyValues, { financialSubaccounts }: FactoryContext, parentLoanRoleId?: number | null) => {
	const formObject = createDataObjectFormObject(dataObject, parentLoanRoleId);
	const subaccount = financialSubaccounts.find(x => x.id === dataObject.id);

	if (!subaccount) {
		return formObject;
	}

	const [name, type] = (subaccount.name ?? '').split(' - ');

	formObject.data[LifeInsuranceCustomFieldKey.NameOfInsuranceCompany] = name.trim();
	formObject.data[LifeInsuranceCustomFieldKey.InsuranceType] = type.trim();

	return formObject;
};

export const createRetirementAccountsFormObject =
	(dataObject: ObjectPropertyValues, { financialSubaccounts }: FactoryContext, parentLoanRoleId?: number | null) =>
{
	const formObject = createDataObjectFormObject(dataObject, parentLoanRoleId);
	const subaccount = financialSubaccounts.find(x => x.id === dataObject.id);

	if (!subaccount) {
		return formObject;
	}

	const [name, type] = (subaccount.name ?? '').split(' - ');

	formObject.data[RetirementAccountCustomFieldKey.NameOfInstitution] = name.trim();
	formObject.data[RetirementAccountCustomFieldKey.RetirementAccountType] = type.trim();

	return formObject;
};

// eslint-disable-next-line max-lines-per-function
export const createBusinessInvestmentFormObject = (
	dataObject: ObjectPropertyValues,
	{ dataObjectsByType, presetDataFieldsByDataObject }: FactoryContext,
	parentLoanRoleId?: number | null
) => {
	const formObject = createDataObjectFormObject(dataObject, parentLoanRoleId);

	const datafields = (presetDataFieldsByDataObject ?? {})[DataObject.TypeEnum.BusinessInvestments];
	const datafieldsByTemplateId = _.keyBy(datafields, 'templateDataFieldId');
	const businessInvestmentEntityDataFieldId = datafieldsByTemplateId[TemplateDataField.BusinessInvestmentInvestmentEntityId]?.id;

	if (!businessInvestmentEntityDataFieldId) {
		return formObject;
	}

	const businessInvestmentEntityId = dataObject.dataFieldValues?.find(x => x.id === businessInvestmentEntityDataFieldId)?.value as Number;
	if (!businessInvestmentEntityId) {
		return formObject;
	}

	let matchingEntity: ObjectPropertyValuesWithRoleType | null = null;
	let entityDataKey: string = '';

	const matchingBusinessEntities = (dataObjectsByType[DataObject.TypeEnum.Business] ?? []).filter(
		businessEntity => businessEntity.id === businessInvestmentEntityId
	);
	if (matchingBusinessEntities.length > 0) {
		matchingEntity = matchingBusinessEntities[0];
		entityDataKey = CustomComponentType.businessInfo;
	}

	const matchingFarmEntities = (dataObjectsByType[DataObject.TypeEnum.Farm] ?? []).filter(farmEntity => farmEntity.id === businessInvestmentEntityId);
	if (matchingFarmEntities.length > 0) {
		matchingEntity = matchingFarmEntities[0];
		entityDataKey = CustomComponentType.farmInfo;
	}

	if (matchingEntity == null) {
		return formObject;
	}

	formObject.data[entityDataKey] = createDataObjectFormObject(matchingEntity);

	return formObject;
};
