module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div>\n	<div ref="modal">\n		<header class="modal-header">\n			<h2 class="modal-title">' +
((__t = ( ctx.modalTitle )) == null ? '' : __t) +
'</h2>\n		</header>\n		<div class="modal-body">\n			';
 if (ctx.popupState === 'MainMenu') { ;
__p += '\n			<div class="pb-1 d-flex justify-content-between align-items-center" data-test-id="MainMenu">\n				<h5 class="mb-0">Select from related applicants or:</h5>\n				<button id="AddNewCustomerButton" class="btn btn-primary" ref="addNewCustomerButton" data-test-id="addNewCustomerButton">\n					Add New\n				</button>\n			</div>\n\n				';
 if (ctx.applicationEntities && ctx.applicationEntities.length > 0) { ;
__p += '\n					<div class="list-group">\n						';
 for(var i=0; i < ctx.applicationEntities.length; i++) { ;
__p += '\n						<button type="button" \n						class="list-group-item list-group-item-action" \n						ref="addExistingEntity" \n						data-test-id="' +
((__t = ( ctx.applicationEntities[i].value )) == null ? '' : __t) +
'" \n						id="' +
((__t = ( ctx.applicationEntities[i].value )) == null ? '' : __t) +
'"\n						>\n						' +
((__t = ( ctx.applicationEntities[i].label )) == null ? '' : __t) +
'\n						</button>\n						';
 } ;
__p += '\n					</div>\n				';
 } else { ;
__p += '\n				<div class="list-group" data-test-id="NoApplicationsEntities">\n					<div class="list-group-item">No Application Entities</div>\n				</div>\n				';
 } ;
__p += '\n\n				';
 if (ctx.isLender) { ;
__p += '\n				<div class="mt-4">\n					<h5>Search all customers (internal-only):</h5>\n					<div ref="customerSearch" class="mt-2" data-test-id="customerSearch">\n						' +
((__t = ( ctx.customerSearch )) == null ? '' : __t) +
'\n					</div>\n				</div>\n				';
 } ;
__p += '\n				';
 } else if (ctx.popupState === 'AddNewPrimary') { ;
__p += '\n					<div ref="addNewPrimaryRole" data-test-id=\'AddNewPrimary\'>\n						' +
((__t = ( ctx.addNewPrimaryRole )) == null ? '' : __t) +
'\n					</div>\n				';
 } ;
__p += '\n				\n		</div>\n	</div>\n</div>\n';
return __p
}