import { DataFieldConvertStrategy } from '../../data-field-convert-strategy';
import { DataField } from '@sageworks/jpi';
import { CustomComponentType, TemplateDataField } from '../../../../../enums';
import { setDropdownProperties } from '../../select-field-converter-utils';
import { formatDataFieldIdKey } from '../../../../../components/form-components/simple-inputs/extended-field-helper';

export class ProposedProductSelectDataFieldConverter implements DataFieldConvertStrategy {
	private static AllowedFields = [TemplateDataField.ProposedProductDropdown];

	private dataFieldMaps: Map<number | undefined | null, DataField>;
	private applicationTemplateId: number;

	constructor(allDataFields: DataField[], loanApplicationTemplateId: number) {
		const entries: any[] = allDataFields.filter(x => x.templateDataFieldId != null).map(x => [x.templateDataFieldId, x]);

		this.dataFieldMaps = new Map(entries);
		this.applicationTemplateId = loanApplicationTemplateId;
	}

	isFieldValid(dataField: DataField): boolean {
		return ProposedProductSelectDataFieldConverter.AllowedFields.includes(dataField.templateDataFieldId as TemplateDataField);
	}

	convert(dataField: DataField, currentProperties: any) {
		const properties = { ...currentProperties };

		switch (dataField.templateDataFieldId) {
			case TemplateDataField.ProposedProductDropdown:
				this.setProposedProductDropdownFieldProperties(properties);
				break;
		}

		return properties;
	}
	private setProposedProductDropdownFieldProperties(properties: any) {
		properties.dataType = 'string';
		properties.type = CustomComponentType.extendedSelectField;

		this.refreshOnDataFieldIfExists(properties, TemplateDataField.ProposedProductDropdown);

		setDropdownProperties(properties, {
			path: ''
		});

		properties.data.url = `{{Formio.apiUrl}}/v1/dynamic-application/proposed-product-select-options/get-all-by-template-id/${this.applicationTemplateId}`;
		properties.selectValues = 'items';
		properties.searchField = 'label';
		properties.valueProperty = 'value';
		properties.dataSrc = 'url';
		properties.lazyLoad = false;
	}

	private refreshOnDataFieldIfExists(properties: any, templateDataField: TemplateDataField) {
		if (!this.dataFieldMaps.has(templateDataField)) {
			return;
		}

		properties.refreshOn = properties.refreshOn ?? [];

		const foundDatafield = this.dataFieldMaps.get(templateDataField) as DataField;
		properties.refreshOn.push(formatDataFieldIdKey(foundDatafield?.id?.toString() ?? '0'));
	}
}
