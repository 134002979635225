
import Vue from 'vue';
import ConfirmReasonModal from '../confirm-reason-modal/confirm-reason-modal.vue';

export default Vue.extend({
	name: 'BorrowerNotUploadingReasonModal',
	components: { ConfirmReasonModal },
	props: {
		isLender: Boolean,
		documentRequestId: Number,
		documentName: String,
		notUploadedReason: String,
		documentNamesId: Number
	},
	computed: {
		modalId(): string {
			return `borrowerNotUploadingReasonModal-${this.documentRequestId}`;
		},
		reasonId(): string {
			return `viewReason-${this.documentRequestId}`;
		},
		truncateReason(): string {
			let truncatedReason = this.notUploadedReason;
			if (truncatedReason.length > 40) {
				truncatedReason = truncatedReason.substring(0, 39) + '...';
			}
			return truncatedReason;
		},
		reasonForNotUploadingText(): string {
			if (this.adobeTemplateStatus === 'NOT_A_VALID_ADOBE_TEMPLATE' || this.adobeTemplateStatus === '') {
				return 'If you are unable to upload this document at this time, please provide an explanation below:';
			}
			return 'If you are unable to sign this document at this time, please provide an explanation below:';
		},
		reasonErrorMessageText(): string {
			if (this.adobeTemplateStatus === 'NOT_A_VALID_ADOBE_TEMPLATE' || this.adobeTemplateStatus === '') {
				return 'Please enter an explanation for why you are unable to upload this document at this time';
			}
			return 'Please enter an explanation for why you are unable to sign this document at this time';
		},
		adobeTemplateStatus(): string | undefined {
			const statusMap = this.directStore.state.AdobeESignModule.adobeTemplateStatuses;
			return statusMap.get(this.documentRequestId);
		},
	},
	methods: {
		saveReason({ reason }: { reason: string }) {
			this.directStore.dispatch.ApplicationDocumentRequest.patchApplicationDocumentRequest({
				id: this.documentRequestId,
				documentRequestProperties: { notUploadedReason: reason }
			});
		},
	}
});
