import BaseComponent from '../base/base-repeater-widget/base-repeater-widget';
import editForm from './additional-entity.form';
import { CustomComponentType } from '../../../enums';

export default class AdditionalEntityWidget extends BaseComponent {
	static schema(...extend: any) {
		return BaseComponent.schema(
			{
				label: 'Additional Entities',
				type: CustomComponentType.additionalEntity,
				key: CustomComponentType.additionalEntity,
				addAnother: 'Add an Additional Entity'
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: 'Additional Entities',
			group: '', // Set group as `CustomBuilderGroups.widgets` once it is ready to be added back
			weight: 10,
			schema: AdditionalEntityWidget.schema({
				skipRemoveConfirm: true
			})
		};
	}
}
