import SelectComponent from 'formiojs/components/select/Select';
import { getKey, checkConditionalityAcrossForms, shouldEditSelectUseOriginalConditionality } from '../extended-field-helper';
import { FormioConditionalityUtils } from '../../../../utils/formio-conditionality-utils';

export class EditableSelect extends SelectComponent {
	static schema(...extend: any) {
		return SelectComponent.schema(
			{
				dataSrc: 'values',
				template: '<span>{{ item.label }}</span>',
				lazyLoad: false,
				searchEnabled: false,
				dataFieldId: null,
				disableAddingRemovingRows: true
			},
			...extend
		);
	}

	// @ts-ignore
	set visible(value: boolean) {
		var isChanged = this.visible !== value;

		super.visible = value;

		if (isChanged) {
			this.setValue(this.dataValue);
		}
	}

	get visible() {
		return super.visible;
	}

	// @ts-ignore
	get key(): string {
		return getKey(this, super.key);
	}

	/**
	 * Check if this component is conditionally visible.
	 *
	 * @param data
	 * @return {boolean}
	 */
	conditionallyVisible(data: any): boolean {
		if (shouldEditSelectUseOriginalConditionality(this)) {
			return super.conditionallyVisible(data);
		}

		return checkConditionalityAcrossForms(this);
	}

	checkCondition(row: any, data: any) {
		return FormioConditionalityUtils.checkCondition(
			this.component,
			row || this.data,
			data || this.rootValue,
			this.root ? (this.root as any)._form : {},
			this
		);
	}
}
