import Component from 'formiojs/components/_classes/component/Component';

export class EditableSelectOptionsRequired {
	public component: Component;
	public settings: any;
	public config: any;

	constructor(component: Component, settings: any, config: any) {
		this.component = component;
		this.settings = settings;
		this.config = config;
	}

	get defaultMessage() {
		return 'At least one option must be enabled';
	}

	check(value: any[]) {
		if (value == null || value.length === 0) {
			return false;
		}

		return value.some(v => {
			return v.enabledForConsumers != null && (v.enabledForConsumers as boolean);
		});
	}
}
