import { CustomComponentType, LiabilityCustomFieldKey } from '../../../../../enums';
import { PersonalFinancialTypeSelect } from '../personal-financial-type-select/personal-financial-type-select';
import { createSubaccountDefaultEditConfiguration } from '../configuration/default-edit-configuration';

export class PersonalLiabilityTypeSelect extends PersonalFinancialTypeSelect {
	static schema(...extend: any) {
		return PersonalFinancialTypeSelect.schema(
			{
				label: 'Liability Type',
				type: CustomComponentType.personalLiabilityTypeSelect,
				key: LiabilityCustomFieldKey.LiabilityType,
			},
			...extend
		);
	}

	static editForm = createSubaccountDefaultEditConfiguration(CustomComponentType.personalLiabilityTypeSelectOptions);

	static get builderInfo() {
		return {
			title: 'Liability Type',
			weight: 10,
			schema: PersonalLiabilityTypeSelect.schema()
		};
	}
}
