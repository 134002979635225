// TODO: move to core-logic package

/**
 * Pass this into the filter function of array to filter distinct
 *  values (primitive values only)
 *
 * @param value
 * @param index
 * @param self
 *
 * @example
 *  const years = [2016, 2017, 2017, 2016, 2019, 2018, 2019];
 *  const distinctYears = years.filter(distinct);
 *  console.log(distinctYears); // [2016, 2017, 2018, 2019]
 */

export const distinct = <T extends string | number | boolean | undefined>(value: T, index: number, self: T[]) => {
	return self.indexOf(value) === index;
};

/**
 * Convert an array to a map
 * @param array
 * @param getKey
 */
export function arrayToMap<K, V>(array: V[], getKey: (item: V) => K): Map<K, V> {
	return array.reduce((map: Map<K, V>, item: V) => {
		map.set(getKey(item), item);
		return map;
	}, new Map<K, V>());
}
