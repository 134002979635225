
import { Customer } from '@sageworks/jpi';
import BaseTemplateMixin from './base-template-mixin.vue';

interface BusinessInvestmentDisplayValue {
	labelName: string;
	value: string;
}

interface FormattedBusinessInvestments {
	id: any;
	values: BusinessInvestmentDisplayValue[];
}

export default BaseTemplateMixin.extend({
	name: 'BusinessInvestments',
	components: {},
	computed: {
		basePath(): string {
			return `${this.exportMetadata.ComponentPath}.${this.exportMetadata.ComponentTree.id}`;
		},
		businessInvestmentValues(): any {
			return this.configurationLookup?.value ?? [];
		},
		formattedData(): FormattedBusinessInvestments[] {
			const businessInvestments: any[] = this.businessInvestmentValues;
			return businessInvestments.map<FormattedBusinessInvestments>(x => ({ id: x.id, values: this.getDisplayFields(x) }));
		}
	},
	methods: {
		getDisplayFields(investmentData: any): BusinessInvestmentDisplayValue[] {
			return this.buildBusinessInvestmentDisplay(investmentData);
		},
		buildBusinessInvestmentDisplay(investmentData: any): BusinessInvestmentDisplayValue[] {
			switch (investmentData.customerType as Customer.TypeEnum) {
				case Customer.TypeEnum.Business:
					return this.getFieldsForBusiness(investmentData);
				case Customer.TypeEnum.Farm:
					return this.getFieldsForFarm(investmentData);
				default:
					throw Error(`Unsupported customer type ${investmentData.customerType}`);
			}
		},
		getFieldsForBusiness(investmentData: any): BusinessInvestmentDisplayValue[] {
			return [
				{
					labelName: 'Business Name',
					value: investmentData.name
				},
				{
					labelName: 'Business Type',
					value: investmentData.subEntityType
				},
				...this.getSharedFields(investmentData)
			];
		},
		getFieldsForFarm(investmentData: any): BusinessInvestmentDisplayValue[] {
			return [
				{
					labelName: 'Farm Name',
					value: investmentData.name
				},
				...this.getSharedFields(investmentData)
			];
		},
		getSharedFields(investmentData: any): BusinessInvestmentDisplayValue[] {
			return [
				{
					labelName: 'Investment Value',
					value: `$${investmentData.investmentValue}`
				},
				{
					labelName: 'Industry Code',
					value: investmentData.industryCode
				},
				{
					labelName: 'State',
					value: investmentData.state
				}
			];
		}
	}
});
