import { unionBy } from 'lodash';
import { AutoFactory, Customer, CustomersService } from '@sageworks/jpi';

export const searchCustomerWithFuzzySearch = async (name: string, allowedEntityTypes: Customer.TypeEnum[], maxSearchResults = 25) => { 
	const customersService = AutoFactory.get(CustomersService);
	const searchResults = await Promise.all([
		customersService.getPaged(
			1,
			maxSearchResults,
			undefined,
			undefined,
			name,
			undefined,
			undefined,
			undefined,
			undefined,
			false
		),
		customersService.getPaged(
			1,
			maxSearchResults,
			name,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			false
		)
	]);

	const fullResults = unionBy(searchResults[0].items ?? [], searchResults[1].items ?? [], 'id');

	const items = fullResults
		// Only allow customers with the allowed types
		.filter(x => x.id && x.type && allowedEntityTypes.includes(x.type))
		// Sort by id field
		.sort((a, b) => (a.id ?? 0) - (b.id ?? 0))
		// Update the name field so it contains a suffix with the unique Indentifier
		.map<Customer>(x => {
			const indentifierExists = x.uniqueIdentifier != null && x.uniqueIdentifier.length > 0;
			const name = indentifierExists ? `${x.name} [${x.uniqueIdentifier}]` : x.name;

			return {
				...x,
				name
			};
		});

	// Only return the max amount of allowed search results
	return items.slice(0, Math.min(items.length, maxSearchResults));
};