module.exports = function(ctx) {
var __t, __p = '';
__p += '<div>\n	<header class="modal-header">\n			<h2 class="modal-title">' +
((__t = ( ctx.name )) == null ? '' : __t) +
'</h2>\n	</header>\n	<div class="modal-body" ref="' +
((__t = (ctx.nestedKey)) == null ? '' : __t) +
'">\n		' +
((__t = ( ctx.children )) == null ? '' : __t) +
'\n	</div>\n</div>	';
return __p
}