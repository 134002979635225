
import Vue, { PropType } from 'vue';
import { Customer } from '@sageworks/jpi/';
import { CustomComponentType } from '@sageworks/dynamic-forms';
import EntityRepeater from '../entity-repeater/entity-repeater.vue';
import { mapState } from 'vuex';
import { getDataObjectsDetails, getRowDetails } from '../../utils/repeater-widget';

export default Vue.extend({
	name: 'CoBorrowerEntity',
	components: { EntityRepeater },
	props: {
		entityTypes: {
			type: Array as PropType<Customer.TypeEnum[]>,
			default: () => []
		}
	},

	computed: {
		...mapState('MultiLoanApplicationForm', ['renderData']),
		templateDataFieldMappings(): { [templateDataFieldId: number]: number } {
			return this.directStore.state.CustomComponentProperties.templateDataFieldIdToDataFieldId ?? {};
		},

		mappings() {
			return this.renderData.formData.coBorrowerMappings ?? null;
		},
		dataObjectsDetails() {
			return getDataObjectsDetails(this.mappings, this.renderData.formData.dataObjects);
		},
		rowDetails() {
			return getRowDetails(this.dataObjectsDetails, this.templateDataFieldMappings);
		},
		myComponentType() {
			return CustomComponentType.coBorrowerEntityVue;
		}
	}
});
