
import Vue from 'vue';
import { OnlinePortalApplicationDocumentRequest } from '@/models/OnlinePortalApplicationDocumentRequest';
import { OnlinePortalDocumentRequest } from '@/models/OnlinePortalDocumentRequest';

export default Vue.extend({
	name: 'DocumentUploadPopup',
	props: {
		fileToUpload: { type: File, default: null },
		isOpen: { type: Boolean, default: false },
		documentRequest: { type: [OnlinePortalApplicationDocumentRequest, OnlinePortalDocumentRequest], default: null },
		isAdditionalDocument: { type: Boolean, default: false },
		uploadInProgress: { type: Boolean, default: false }
	},
	data() {
		return {
			filePassword: ''
		};
	},
	computed: {
		documentName() {
			return this.documentRequest?.documentName ?? '';
		},
		isTaxReturnDocument(): boolean {
			return this.documentName?.includes('Tax Return') ?? false;
		}
	},
	methods: {
		chooseFile(): void {
			this.$emit('choose-file');
		},
		startFileUpload(modalEvent: Event): void {
			this.$emit('start-file-upload', this.filePassword);
			modalEvent.preventDefault();
		},
		cancelFileUpload(): void {
			this.$emit('cancel-file-upload');
		}
	}
});
