import { AutoFactory, IAPIConfiguration, SetupApi } from '@sageworks/jpi';

export function setApiAccessToken(accessToken: string) {
	if (!accessToken) return false;

	if (AutoFactory.isBound('IAPIConfiguration')) {
		const apiConfiguration: IAPIConfiguration = AutoFactory.get('IAPIConfiguration');
		apiConfiguration.accessToken = accessToken;
	} else {
		SetupApi({
			accessToken: accessToken
		} as IAPIConfiguration);
	}
	return true;
}
