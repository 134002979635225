
import Vue, { PropType } from 'vue';
import { OnlinePortalApplicationDocumentRequest } from '../../models';
import DocumentUpload from './document-upload.vue';
import BorrowerNotUploadingReasonModal from './borrower-not-uploading-reason-modal/borrower-not-uploading-reason-modal.vue';
import EsignForm from './esign-form.vue';

export default Vue.extend({
	name: 'DocumentUploadRow',
	components: { DocumentUpload, EsignForm, BorrowerNotUploadingReasonModal },
	props: {
		documentRequest: Object as PropType<OnlinePortalApplicationDocumentRequest>,
		showAdditionalButtons: Boolean
	},
	computed: {
		isLender(): boolean {
			return this.directStore.getters.User.isLender;
		},
		isEsignForm(): boolean {
			return !!this.adobeTemplateStatus && this.adobeTemplateStatus !== 'NOT_A_VALID_ADOBE_TEMPLATE';
		},
		adobeTemplateStatus(): string | undefined {
			const statusMap = this.directStore.state.AdobeESignModule.adobeTemplateStatuses;

			if (this.documentRequest.id == null) {
				return undefined;
			}

			return statusMap.get(this.documentRequest.id);
		}
	},
	async beforeMount() {
		await this.directStore.dispatch.AdobeESignModule.fetchTemplateStatus({
			documentRequestId: this.documentRequest.id,
			documentNamesId: this.documentRequest.documentNamesId
		});
	}
});
