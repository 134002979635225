import Component from 'formiojs/components/_classes/component/Component';

export function configureLinkTemplate(component: Component, templateData: any = {}): string {
	component.options.hooks.renderWidgets = addBuilderComponent(component);

	component.rendered = true;
	return component.renderTemplate('widgets', {
		visible: component.visible,
		id: component.id,
		classes: component.className,
		styles: component.customStyle,
		children: (component.component as any).label,
		showEditButton: true,
		...templateData
	});
}

export function addBuilderComponent(component: Component) {
	return function(html: string, { self }: { self: any }) {
		if ((component.options.disabled && component.options.disabled.includes(self.key)) || self.parent.noDragDrop) {
			return html;
		}

		return component.renderTemplate('builderComponent', {
			html
		});
	};
}

export function configureLinkBehavior(component: Component, element: any) {
	component.loadRefs(element, {
		editChildForm: 'single'
	});

	const editChildForm = function(component: Component) {
		component.emit('edit-child', component);
	};

	if ((component.refs as any).editChildForm) {
		(component.refs as any).editChildForm.removeEventListener('click', () => editChildForm(component));
		component.addEventListener((component.refs as any).editChildForm, 'click', () => editChildForm(component));
	}
}
