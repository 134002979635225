module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div>\n	<div ref="modal">\n		<header class="modal-header">\n			<h2 class="modal-title">' +
((__t = ( ctx.encodeHtml(ctx.modalTitle) )) == null ? '' : __t) +
'</h2>\n		</header>\n		<div class="modal-body" role="form">\n			';
 if (ctx.popupState === 'MainMenu') { ;
__p += '\n			<div class="pb-1 d-flex justify-content-between align-items-center" data-test-id="MainMenu">\n				<h5 class="mb-1">' +
((__t = ( ctx.encodeHtml(ctx.mainMenuPrompt) )) == null ? '' : __t) +
'</h5>\n			</div>\n			<input type="radio" id="yesCanEnterBorrowerInfo" name="canEnterBorrowerInfo" ref="yesCanEnterBorrowerInfo" value="Yes" ';
 if (ctx.modalState.canEnterBorrowerInfo) { ;
__p += 'checked';
 } ;
__p += '>\n			<label ref="yesCanEnterBorrowerInfoLabel" for="yesCanEnterBorrowerInfo">Yes, I am able to fill out their information</label><br>\n			<input type="radio" id="noCanEnterBorrowerInfo" name="canEnterBorrowerInfo" ref="noCanEnterBorrowerInfo" value="No" ';
 if (!ctx.modalState.canEnterBorrowerInfo) { ;
__p += 'checked';
 } ;
__p += '>\n			<label ref="noCanEnterBorrowerInfoLabel" for="noCanEnterBorrowerInfo">No, I need them to fill out their information</label><br>\n\n			';
 if (!ctx.modalState.canEnterBorrowerInfo) { ;
__p += '\n			<label class="col-form-label field-required">First Name</label>\n			<input class="form-control" type="text" ref="firstNameInput" value="' +
((__t = ( ctx.modalState.firstName )) == null ? '' : __t) +
'" />\n			';
 if (ctx.modalState.firstNameValidation) { ;
__p += '\n			<p class="text-danger">\n				' +
((__t = ( ctx.encodeHtml(ctx.modalState.firstNameValidation) )) == null ? '' : __t) +
'\n			</p>\n			';
 } ;
__p += '\n			<label class="col-form-label field-required">Last Name</label>\n			<input class="form-control" type="text" ref="lastNameInput" value="' +
((__t = ( ctx.modalState.lastName )) == null ? '' : __t) +
'" />\n			';
 if (ctx.modalState.lastNameValidation) { ;
__p += '\n			<p class="text-danger">\n				' +
((__t = ( ctx.encodeHtml(ctx.modalState.lastNameValidation) )) == null ? '' : __t) +
'\n			</p>\n			';
 } ;
__p += '\n			<label class="col-form-label field-required">Email</label>\n			<input class="form-control" type="text" ref="emailInput" value="' +
((__t = ( ctx.modalState.email )) == null ? '' : __t) +
'" />\n			';
 if (ctx.modalState.emailValidation) { ;
__p += '\n			<p class="text-danger">\n				' +
((__t = ( ctx.encodeHtml(ctx.modalState.emailValidation) )) == null ? '' : __t) +
'\n			</p>\n			';
 } ;
__p += '\n\n			\n			';
 } ;
__p += '\n			\n			';
 } else if (ctx.popupState === 'ConfirmDetails') { ;
__p += '\n			<p> \n				Clicking <strong>' +
((__t = ( ctx.encodeHtml(ctx.continueButtonText) )) == null ? '' : __t) +
'</strong> will send an email to the person below inviting them to register for the portal and complete their information. \n				Neither of you will be able to access the other\'s personal information or be able to remove the other from the application.\n			</p>\n			<label class="col-form-label field-required">First Name</label>\n			<p>' +
((__t = ( ctx.encodeHtml(ctx.modalState.firstName) )) == null ? '' : __t) +
'</p>\n			<label class="col-form-label field-required">Last Name</label>\n			<p>' +
((__t = ( ctx.encodeHtml(ctx.modalState.lastName) )) == null ? '' : __t) +
'</p>\n			<label class="col-form-label field-required">Email</label>\n			<p>' +
((__t = ( ctx.encodeHtml(ctx.modalState.email) )) == null ? '' : __t) +
'</p>\n\n			';
 } ;
__p += '\n		</div>\n	</div>\n\n	<footer class="modal-footer">\n		';
 if (ctx.popupState === 'ConfirmDetails') { ;
__p += '\n		<button data-test-id="BorrowerAccessBackButton" id="BorrowerAccessBackButton" class="btn btn-secondary" ref="backBorrowerAccessButton">Back</button>\n		';
 } ;
__p += '\n		<button data-test-id="BorrowerAccessContinueButton" id="BorrowerAccessSaveButton" class="btn btn-primary" ref="continueBorrowerAccessButton">' +
((__t = ( ctx.encodeHtml(ctx.continueButtonText) )) == null ? '' : __t) +
'</button>\n		<button data-test-id="BorrowerAccessCancelButton" id="BorrowerAccessCancelButton" class="btn btn-secondary" ref="cancelBorrowerAccessButton">Cancel</button>\n	</footer>\n</div>\n';
return __p
}