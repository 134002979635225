export default [
	{
		weight: 140,
		type: 'checkbox',
		label: 'Clear Value When Hidden',
		key: 'clearOnHide',
		defaultValue: true,
		tooltip: 'When a field is hidden, clear the value.',
		input: true
	},
	{
		type: 'textfield',
		label: 'Default Value',
		key: 'defaultValue',
		forcePreview: true,
		weight: 5,
		placeholder: 'Default Value',
		tooltip: 'The Default Value will be the value for this field, before user interaction. Having a default value will override the placeholder text.',
		input: true
	},
];
