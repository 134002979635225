import directStore from '@/store';
import { getOnlinePortalSubdomain } from './UrlUtils';
import { Environment } from '@sageworks/core-logic/src/enums/environment';
import { SbaForm } from '@sageworks/jpi';

export enum AnalystPageType {
	LoanApplication = 'loan-application',

	// Sba Form Pages
	Form1919PersonPrincipalInformation = 'form-1919-person-principal',
	Form1919BusinessPrincipalInformation = 'form-1919-business-principal',
	Form1919Applicant = 'form-1919-applicant',
	Form1920 = 'form-1920'
}

export function getLinkToSageworksAnalyst(pageType: AnalystPageType, pageObjectId: number) {
	if (!pageType || !(pageObjectId > 0)) {
		return undefined;
	}

	let environment = directStore.state.AppConfig.appConfig?.environment;
	if (environment === Environment.Production) {
		environment = 'www';
	}
	const subdomain = getOnlinePortalSubdomain();
	const authToken = directStore.state.Authentication.userToken;
	return `https://${environment}.sageworksanalyst.com/online-portal/auth-redirect/${subdomain}/${pageType}/${pageObjectId}/${authToken}`;
}

export function getLinkToLoanApplication(loanApplicationId: number) {
	return getLinkToSageworksAnalyst(AnalystPageType.LoanApplication, loanApplicationId);
}

export function getLinkToFillOutSbaFormPage(formType: SbaForm.FormTypeEnum, formId: number) {
	switch (formType) {
		case SbaForm.FormTypeEnum.Form1919ApplicantBusinessInformation:
			return getLinkToSageworksAnalyst(AnalystPageType.Form1919Applicant, formId);
		case SbaForm.FormTypeEnum.Form1919PersonPrincipalInformation:
			return getLinkToSageworksAnalyst(AnalystPageType.Form1919PersonPrincipalInformation, formId);
		case SbaForm.FormTypeEnum.Form1919BusinessPrincipalInformation:
			return getLinkToSageworksAnalyst(AnalystPageType.Form1919BusinessPrincipalInformation, formId);
		case SbaForm.FormTypeEnum.Form1920:
			return getLinkToSageworksAnalyst(AnalystPageType.Form1920, formId);
		default:
			throw new Error('Form type does not have an analyst fill out form page');
	}
}
