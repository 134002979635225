import editForm from './non-investment-real-estate.form';
import { CustomComponentType, CustomComponentLabel } from '../../../../enums/custom-component-type';
import { FinancialSubaccount } from '../financial-subaccount/financial-subaccount';

export class NonInvestmentRealEstateWidget extends FinancialSubaccount {
	static schema(...extend: any) {
		return FinancialSubaccount.schema(
			{
				label: CustomComponentLabel()[CustomComponentType.nonInvestmentRealEstate],
				type: CustomComponentType.nonInvestmentRealEstate,
				key: CustomComponentType.nonInvestmentRealEstate,
				addAnother: 'Add Non-Investment Real Estate'
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: CustomComponentLabel()[CustomComponentType.nonInvestmentRealEstate],
			group: '',
			weight: 10,
			schema: NonInvestmentRealEstateWidget.schema()
		};
	}
}
