import editForm from './beneficial-ownership.form';
import { CustomComponentType, LoanRoleType, TemplateDataField, EditRowState } from '../../../enums';
import { RelatedRoleRepeaterWidget } from '../related-role-repeater-widget/related-role-repeater-widget';
import { BeneficialOwnershipService } from './beneficial-ownership.service';
import { generateFormJson } from './beneficial-ownership-form-helper';
import { DataField } from '@sageworks/jpi';
import { formatDataFieldIdKey } from '../simple-inputs/extended-field-helper';
import { BeneficialOwnerUtils } from '../../../utils';
import { UpdateFlags } from 'formiojs/components/_classes/component/Component';
import { BeneficialOwnershipRoleType } from '../../../enums/beneficial-ownership-role-type';

export default class BeneficialOwnershipWidget extends RelatedRoleRepeaterWidget {
	static schema(...extend: any) {
		return RelatedRoleRepeaterWidget.schema(
			{
				label: 'Beneficial Ownership',
				type: CustomComponentType.beneficialOwnership,
				key: CustomComponentType.beneficialOwnership,
				addAnother: 'Add an Owner'
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: 'Beneficial Ownership',
			group: '',
			weight: 10,
			schema: BeneficialOwnershipWidget.schema()
		};
	}

	private service!: BeneficialOwnershipService;
	private dataFields: DataField[] = [];

	init() {
		this.roleType = LoanRoleType.BeneficialOwner;

		super.init();

		this.service = new BeneficialOwnershipService(this);
	}

	saveRow(rowIndex: number, modified?: boolean) {
		if (this.editRows[rowIndex].state === EditRowState.New) {
			this.editRows[rowIndex].state = EditRowState.Saved;

			// Once we initially add a row, be sure to update the UI so the user can begin editing it
			this.editRow(rowIndex);
			return true;
		} else {
			return super.saveRow(rowIndex, modified);
		}
	}

	protected initDataLoader() {
		this.dataLoader.addDataFunc(this.setupDataFields.bind(this));

		this.dataLoader.isSuccessfulPromise.then(this.onDataLoaded.bind(this));

		super.initDataLoader();
	}

	private async setupDataFields() {
		if (this.isJpiAuthenticated) {
			this.dataFields = await this.service.getDataFields();
		}
	}

	private onDataLoaded() {
		this.component.formJson = generateFormJson(this.dataFields, this.options.readOnly);
		this.component.components = this.componentComponents;
		this.resetRowComponents();
	}

	checkComponentValidity(data: any, dirty: boolean, row: any, options: any = {}): boolean | Promise<boolean> {
		const superResult = super.checkComponentValidity(data, dirty, row, options);

		data = data || this.rootValue;
		row = row || this.data;
		const { async = false, silentCheck = false } = options;

		if (async) {
			return (superResult as Promise<boolean>).then(valid => {
				const percentOwnershipValid = this.validatePercentOwnership(this.dataValue, dirty, silentCheck);

				return valid && percentOwnershipValid;
			});
		}

		return superResult && this.validatePercentOwnership(this.dataValue, dirty, silentCheck);
	}

	private validatePercentOwnership(data: any[], dirty: boolean, silentCheck: boolean) {
		if (data == null || data.length <= 0) {
			return true;
		}

		const percentOwnershipDataField = this.dataFields?.find(field => field.templateDataFieldId === TemplateDataField.BeneficialOwnerPercent);

		if (percentOwnershipDataField == null) {
			return true;
		}

		const percentOwnershipDataFieldKey = formatDataFieldIdKey(percentOwnershipDataField.id!.toString());

		let totalPercentOwnership = 0;
		data.forEach(row => {
			totalPercentOwnership += parseFloat(row.form.data[percentOwnershipDataFieldKey] ?? 0);
		});

		if (totalPercentOwnership > 1) {
			const currentMessages = this.error?.messages ?? [];
			currentMessages.push({
				message: 'Total ownership of this business cannot be greater than 100%',
				level: 'error'
			});
			this.setComponentValidity(currentMessages, dirty, silentCheck);
			return false;
		}

		return true;
	}

	rowDescriptionGetEntityDescription(row: any): string {
		const { beneficialOwnershipPercent, beneficialOwnershipRoleType } = this.getDescriptionKeys();

		const beneficialOwnerName = this.getOwnerName(row);
		const beneficialOwnershipDescription = this.getBeneficialOwnerRoleDescription(row, beneficialOwnershipPercent, beneficialOwnershipRoleType);

		return `${beneficialOwnerName}${beneficialOwnershipDescription != null ? ' ' + beneficialOwnershipDescription : ''}`;
	}

	// eslint-disable-next-line max-lines-per-function
	getDescriptionKeys() {
		let descriptionKeys = {
			personalFirstNameKey: '',
			personalLastNameKey: '',
			businessNameKey: '',
			nonprofitNameKey: '',
			farmNameKey: '',
			beneficialOwnershipPercent: '',
			beneficialOwnershipRoleType: ''
		};
		const releventTemplateDataFields = [
			TemplateDataField.PersonalFirstName,
			TemplateDataField.PersonalLastName,
			TemplateDataField.BusinessName,
			TemplateDataField.NonprofitName,
			TemplateDataField.FarmName,
			TemplateDataField.BeneficialOwnerPercent,
			TemplateDataField.BeneficialOwnerRoleType
		];
		releventTemplateDataFields.forEach((templateDataField: TemplateDataField) => {
			const dataFieldId = this.options?.contextData?.templateDataFieldMappings?.[templateDataField];

			if (dataFieldId == null) {
				return;
			}

			switch (templateDataField) {
				case TemplateDataField.PersonalFirstName:
					descriptionKeys.personalFirstNameKey = formatDataFieldIdKey(dataFieldId);
					break;
				case TemplateDataField.PersonalLastName:
					descriptionKeys.personalLastNameKey = formatDataFieldIdKey(dataFieldId);
					break;
				case TemplateDataField.BusinessName:
					descriptionKeys.businessNameKey = formatDataFieldIdKey(dataFieldId);
					descriptionKeys.nonprofitNameKey = descriptionKeys.businessNameKey;
					break;
				case TemplateDataField.FarmName:
					descriptionKeys.farmNameKey = formatDataFieldIdKey(dataFieldId);
					break;
				case TemplateDataField.BeneficialOwnerPercent:
					descriptionKeys.beneficialOwnershipPercent = formatDataFieldIdKey(dataFieldId);
					break;
				case TemplateDataField.BeneficialOwnerRoleType:
					descriptionKeys.beneficialOwnershipRoleType = formatDataFieldIdKey(dataFieldId);
					break;
			}
		});

		return descriptionKeys;
	}

	getOwnerName(row: any) {
		const { personalFirstNameKey, personalLastNameKey, businessNameKey, nonprofitNameKey, farmNameKey } = this.getDescriptionKeys();

		const description =
			this.getPersonalInfoDescription(row, personalFirstNameKey, personalLastNameKey) ??
			this.getBusinessInfoDescription(row, businessNameKey) ??
			this.getNonProfitInfoDescription(row, nonprofitNameKey) ??
			this.getFarmInfoDescription(row, farmNameKey);

		if (description == null) {
			throw new Error('Invalid configuration found');
		}

		return description;
	}

	protected getBeneficialOwnerRoleDescription(row: any, ownerPercentKey: string, ownerRoleTypeKey: string) {
		const data = row.data;

		if (!data) return null;

		const ownerPercent = data[ownerPercentKey] as number;
		const ownerRoleType = data[ownerRoleTypeKey] as BeneficialOwnershipRoleType;

		let description = `(${BeneficialOwnerUtils.BeneficialOwnerRoleLabel(ownerRoleType) ?? 'No role assigned'})`;

		if (ownerPercent != null && ownerPercent > 0) {
			description += ` - ${ownerPercent.toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 1 })}`;
		}

		return description;
	}

	setValue(value: any, flags: UpdateFlags | undefined = {}): boolean {
		if (this.options.readOnly) {
			if (!value) {
				value = this.defaultValue;
			}

			if (!Array.isArray(value)) {
				if (typeof value === 'object') {
					value = [value];
				} else {
					return false;
				}
			}

			value.forEach((v: any) => {
				v.form.data.displayName = this.getOwnerName(v.form);
			});
		}

		return super.setValue(value, flags);
	}
}
