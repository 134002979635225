import { Utils as FormioUtils } from 'formiojs';
import { CustomComponentType, CustomerCustomComponentTypes } from '../../enums';

export function detectAllowedEntityTypes(components: any[]): CustomComponentType[] {
	return detectComponentTypes(components, CustomerCustomComponentTypes);
}

export function detectComponentTypes(hayStackComponents: any[], needleComponentTypes: string[]): CustomComponentType[] {
	// Immediately return if we either don't have anything to search against or anything to search for
	if (!Array.isArray(hayStackComponents) || !hayStackComponents.length || !Array.isArray(needleComponentTypes) || !needleComponentTypes.length) {
		return [];
	}
	const foundNeedles: CustomComponentType[] = [];
	FormioUtils.eachComponent(hayStackComponents, (hayStackComponent: any) => {
		if (!foundNeedles.includes(hayStackComponent.type as CustomComponentType)) {
			if (needleComponentTypes.includes(hayStackComponent.type)) {
				foundNeedles.push(hayStackComponent.type as CustomComponentType);
			}
		}
	});
	return foundNeedles;
}
