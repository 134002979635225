import { PlaidCredentialsResponse } from '@sageworks/jpi';

type OnSuccessCallback = (token: string, metadata: any) => Promise<void>;
type OnExitCallback = () => Promise<void>;

export const createPlaidLink = (response: PlaidCredentialsResponse, onSuccess: OnSuccessCallback, onExit: OnExitCallback) => {
	const handler = window.Plaid.create({
		token: response.linkToken,
		clientName: response.clientName,
		env: response.environment,
		product: ['assets'],
		onSuccess: async (public_token: string, metadata: any) => {
			await onSuccess(public_token, metadata);
			handler.exit();
		},
		onExit
	});

	handler.open();
};
