import ColumnComponent from 'formiojs/components/columns/Columns';
import ContentComponent from 'formiojs/components/content/Content';
import { DataField } from '@sageworks/jpi';
import { TemplateDataField, CreditPullCountryOption } from '../../../../enums';
import TextFieldComponent from 'formiojs/components/textfield/TextField';
import SelectComponent from 'formiojs/components/select/Select';
import { StaticFormUtils } from '../../../../utils';
import { FormioComponentSchema, FormioValidationSchema, FormioFormDisplayType, FormioFormType } from '../../../../formio-interfaces';

export const PullCreditDataFieldIds = new Set([
	TemplateDataField.PersonalFirstName,
	TemplateDataField.PersonalLastName,
	TemplateDataField.PersonalMiddleName,
	TemplateDataField.PersonalSuffix,
	TemplateDataField.PersonalSSN,
	TemplateDataField.PersonalStreetAddress,
	TemplateDataField.PersonalCity,
	TemplateDataField.PersonalState,
	TemplateDataField.PersonalZipCode
]);

export enum CustomPullCreditFieldKey {
	CreditPullCountry = 'creditPullCountry',
	ExperianCreditFreezePin = 'experianCreditFreezePin'
}

const createSectionHeaderHTML = (name: string) => `<h4><strong>${name}</strong></h4>`;

// eslint-disable-next-line max-lines-per-function
export const generateFormJson = (dataFields: DataField[]) => {
	const dataFieldSchemaMap = StaticFormUtils.dataFieldListToSchemaMap(dataFields);

	// Temporary - set all fields to disabled until partial save is complete
	Object.keys(dataFieldSchemaMap).forEach(key => {
		const fieldId = Number(key);
		const schema = dataFieldSchemaMap[fieldId] as FormioComponentSchema<any, FormioValidationSchema>;

		schema.disabled = true;
	});

	return {
		type: FormioFormType.form,
		title: 'credit report -pull credit form',
		display: FormioFormDisplayType.form,
		components: [
			// //////////////////////////////
			// Personal information - section
			// //////////////////////////////
			ContentComponent.schema({ html: createSectionHeaderHTML('Personal Info') }),
			dataFieldSchemaMap[TemplateDataField.PersonalFirstName],
			dataFieldSchemaMap[TemplateDataField.PersonalMiddleName],
			dataFieldSchemaMap[TemplateDataField.PersonalLastName],
			dataFieldSchemaMap[TemplateDataField.PersonalSuffix],
			dataFieldSchemaMap[TemplateDataField.PersonalSSN],

			// /////////////////////////////
			// Address information - section
			// /////////////////////////////
			ContentComponent.schema({ html: createSectionHeaderHTML('Current Address') }),
			dataFieldSchemaMap[TemplateDataField.PersonalStreetAddress],
			ColumnComponent.schema({
				columns: [
					dataFieldSchemaMap[TemplateDataField.PersonalCity],
					dataFieldSchemaMap[TemplateDataField.PersonalState],
					dataFieldSchemaMap[TemplateDataField.PersonalZipCode]
				].map(schema => ({
					components: [schema],
					offset: 0,
					push: 0,
					pull: 0,
					width: 4
				}))
			}),
			SelectComponent.schema({
				label: 'Country',
				key: CustomPullCreditFieldKey.CreditPullCountry,
				data: {
					values: [
						{ label: 'United States', value: CreditPullCountryOption.UnitedStates },
						{ label: 'Canada', value: CreditPullCountryOption.Canada }
					]
				}
			}),

			// ///////////////////////////////////
			// Credit freeze information - section
			// ///////////////////////////////////
			ContentComponent.schema({ html: createSectionHeaderHTML('Credit Freeze Pin (as applicable)') }),

			TextFieldComponent.schema({
				label: 'Experian Credit Freeze PIN (as applicable)',
				key: CustomPullCreditFieldKey.ExperianCreditFreezePin
			})
		]
	};
};
