import { CustomComponentType } from '../../../../../enums/custom-component-type';
import { PersonalFinancialTypeSelect } from '../personal-financial-type-select/personal-financial-type-select';
import { getKey } from '../../../../../components/form-components/simple-inputs/extended-field-helper';
import { createSubaccountDefaultEditConfiguration } from '../configuration';

export class PersonalIncomeTypeSelect extends PersonalFinancialTypeSelect {
	static schema(...extend: any) {
		return PersonalFinancialTypeSelect.schema(
			{
				label: 'Income Type',
				type: CustomComponentType.personalIncomeTypeSelect,
				key: CustomComponentType.personalIncomeTypeSelect
			},
			...extend
		);
	}

	static editForm = createSubaccountDefaultEditConfiguration(CustomComponentType.personalIncomeTypeSelectOptions);

	static get builderInfo() {
		return {
			title: 'Income Type',
			weight: 10,
			schema: PersonalIncomeTypeSelect.schema()
		};
	}

	// @ts-ignore
	get key(): string {
		return getKey(this, super.key);
	}
}
