module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<section\n	id="' +
((__t = ( ctx.id )) == null ? '' : __t) +
'"\n	class="' +
((__t = ( ctx.classes )) == null ? '' : __t) +
' d-flex flex-column align-items-start"\n	';

	if
	(ctx.styles)
	{
	;
__p += '\n	styles="' +
((__t = ( ctx.styles )) == null ? '' : __t) +
'"\n	';

	}
	;
__p += '\n	ref="component"\n>\n	<h3 style="font-weight: 600;">' +
((__t = ( ctx.name )) == null ? '' : __t) +
'</h3>\n\n	';
 if ((ctx.rows && ctx.rows.length > 0) || ctx.rowToAdd) { ;
__p += '\n	<ul style="list-style: none;" class="p-0 mb-2 mt-4">\n		' +
((__t = (
			ctx.rows.join('')
		)) == null ? '' : __t) +
'\n	</ul>\n	';
 } else { ;
__p += '\n	<span class="mt-4 mb-5">' +
((__t = ( ctx.emptyListText )) == null ? '' : __t) +
'</span>\n	';
 } ;
__p += ' ';
 if (ctx.rows.length === 0 || (ctx.rows.length > 0 && ctx.allowRepeat)) { ;
__p += '\n	<div class="btn btn-secondary" ref="addRow">+ ' +
((__t = ( ctx.addButtonText )) == null ? '' : __t) +
'</div>\n	';
 } ;
__p += '\n</section>\n';
return __p
}