import { DataField } from '@sageworks/jpi';

const locateDataField = (propertiesByType: { [key: string]: DataField[] }, dataFieldId: number) => {
	if (!propertiesByType) {
		return null;
	}

	for (let dataFields of Object.values(propertiesByType)) {
		const found = dataFields.find(x => x.id === dataFieldId);

		if (found) {
			return found;
		}
	}

	return null;
};

const transformEqForBooleanType = (eq: any) => {
	if (Array.isArray(eq)) {
		eq = eq[0];
	}

	switch (eq) {
		case '0':
			return false;
		case '1':
			return true;
		default:
			return eq;
	}
};

export const formatEq = (eq: any) => {
	if (eq == null) {
		return [];
	}

	return Array.isArray(eq) ? eq : [eq];
};

export const getDataFieldIdFromwhenClause = (whenClause: string) => {
	const values = (whenClause ?? '').split('_');

	if (values.length === 0) {
		return null;
	}

	const value = values[values.length - 1];

	if (!value) {
		return null;
	}

	const possibleId = Number(values[values.length - 1]);
	return Number.isNaN(possibleId) ? null : possibleId;
};

export const transformConditionalitySchema = (conditionalSchema: any, propertiesByType: { [key: string]: DataField[] }) => {
	// Grab the data field ID from the when clause
	const dataFieldId = getDataFieldIdFromwhenClause(conditionalSchema?.when);
	if (dataFieldId == null) {
		return conditionalSchema;
	}

	// Grab the data field so we can tranform the value based on the data field type
	const dataField = locateDataField(propertiesByType, dataFieldId);
	if (dataField == null) {
		return conditionalSchema;
	}

	if (dataField.dataFieldType === DataField.DataFieldTypeEnum.Boolean) {
		conditionalSchema.eq = transformEqForBooleanType(conditionalSchema.eq);
	}

	return conditionalSchema;
};
