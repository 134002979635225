import { DynamicApplicationGMIFormLink } from '@sageworks/jpi';

export const listToMapByCustomerId = (gmiForms: DynamicApplicationGMIFormLink[]) => {
	return gmiForms.reduce<{ [customerId: number]: DynamicApplicationGMIFormLink[] }>((map, gmiForm) => {
		const { customerId } = gmiForm;

		if (!customerId) return map;

		if (map[customerId] == null) {
			map[customerId] = [];
		}

		map[customerId].push(gmiForm);

		return map;
	}, {});
};
