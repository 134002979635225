import { BaseEditTab} from './base.edit.tab';
import { IBaseWidgetConfigurationEditTabOptions } from './base.edit.tab.options';

interface IBaseWidgetConfigurationEditLogicOptions extends IBaseWidgetConfigurationEditTabOptions {}

export class BaseEditLogic extends BaseEditTab<IBaseWidgetConfigurationEditLogicOptions> {
	public get tabKey(): string {
		return 'logic';
	}

	public get label(): string {
		return 'Logic';
	}

	public get weight(): number {
		return 20;
	}

	public defaultOptions: IBaseWidgetConfigurationEditLogicOptions = {
		ignore: true
	};
}
