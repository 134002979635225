
import { isEmptyObject } from '@/utils/ObjectUtils';
import { formatValue } from '@/utils/render-utils';
import { CustomComponentType } from '@sageworks/dynamic-forms';
import BaseTemplateMixin from './base-template-mixin.vue';

export default BaseTemplateMixin.extend({
	name: 'FormioInput',
	computed: {
		prefix() {
			if (this.exportMetadata.ComponentTree.prefix instanceof HTMLElement) {
				return this.exportMetadata.ComponentTree.prefix.outerHTML;
			}

			return this.exportMetadata.ComponentTree.prefix ?? '';
		},
		suffix() {
			if (this.exportMetadata.ComponentTree.suffix instanceof HTMLElement) {
				return this.exportMetadata.ComponentTree.suffix.outerHTML;
			}

			return this.exportMetadata.ComponentTree.suffix ?? '';
		},
		value() {
			const lookupString = `${this.exportMetadata.ComponentPath}.${this.exportMetadata.ComponentTree.id}`;
			const value = this.directStore.state.PDFPreviewModule.renderData[this.exportMetadata.LoanIndex].configurationLookup![lookupString]?.value;

			if (value == null || (typeof value === 'object' && isEmptyObject(value))) {
				return '-';
			}

			// For any inputs with the mask property, the transformation has already occurred on the server-side
			if (this.exportMetadata.ComponentTree.mask) {
				return value;
			}

			return `${this.prefix} ${formatValue(value, this.exportMetadata.ComponentTree)} ${this.suffix}`;
		},
		labelClass() {
			let classList = '';
			if (this.exportMetadata.ComponentTree.validate?.required) {
				classList += 'field-required';
			}
			if (this.exportMetadata.ComponentTree.hideLabel) {
				classList += 'control-label--hidden';
			}
			return classList;
		},
		isNumber() {
			return [CustomComponentType.extendedNumber, CustomComponentType.extendedPhoneNumber, CustomComponentType.extendedCurrency].includes(
				this.exportMetadata.ComponentTree.type
			);
		},
		inputClass() {
			return `formio-component-${this.exportMetadata.ComponentTree.type} pb-1`;
		},
		hideLabel() {
			return this.exportMetadata.ComponentTree.hideLabel;
		}
	},
	methods: {}
});
