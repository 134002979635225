
import Vue from 'vue';

export default Vue.extend({
	name: 'ResetFormModal',
	props: {
		id: { type: String, default: null },
		title: { type: String, default: null }
	},
	data() {
		return {};
	},
	computed: {},
	methods: {
		confirmReset() {
			this.$emit('confirm');
		}
	}
});
