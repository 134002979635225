import { PluginObject } from 'vue';

export default {
	install(Vue) {
		Vue.mixin({
			beforeCreate() {
				Object.defineProperty(this, 'directStore', {
					get() {
						return this.$store.direct;
					}
				});
			}
		});
	}
} as PluginObject<{}>;
