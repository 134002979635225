
import Vue from 'vue';
import { CustomComponentType, LoanRoleType } from '@sageworks/dynamic-forms';

export default Vue.extend({
	name: 'ApplicationFormSignature',
	components: {},
	props: {
		signatureLoanRoleType: { type: String, required: true },
		proposedLoanBorrowers: { type: Array, default: null },
		sectionNumber: { type: Number, default: 0 }
	},
	data() {
		return {
			CustomComponentType
		};
	},
	computed: {
		componentHeaderLabel(): string | null {
			switch (this.signatureLoanRoleType) {
				case LoanRoleType.PrimaryBorrower:
					return `Borrower${this.proposedLoanBorrowers.length > 1 ? '(s)' : ''}`;
				case LoanRoleType.CoBorrower:
					return `Co-Borrower${this.proposedLoanBorrowers.length > 1 ? '(s)' : ''}`;
				case LoanRoleType.Guarantor:
					return `Guarantor${this.proposedLoanBorrowers.length > 1 ? '(s)' : ''}`;
				case LoanRoleType.CreditApplicant:
					return `Credit Applicant${this.proposedLoanBorrowers.length > 1 ? '(s)' : ''}`;
				case LoanRoleType.CoSigner:
					return `Co-Signer${this.proposedLoanBorrowers.length > 1 ? '(s)' : ''}`;
				default:
					return '';
			}
		},
		componentBorrowerType(): string | null {
			switch (this.signatureLoanRoleType) {
				case LoanRoleType.PrimaryBorrower:
					return `borrower`;
				case LoanRoleType.CoBorrower:
					return `coborrower`;
				case LoanRoleType.Guarantor:
					return `guarantor`;
				case LoanRoleType.CreditApplicant:
					return `creditapplicant`;
				case LoanRoleType.CoSigner:
					return `cosigner`;
				default:
					return '';
			}
		}
	},
	methods: {
		signatureElementId(customerId: Number): string {
			return `signature-${this.componentBorrowerType}-${customerId}-${this.sectionNumber}`;
		},
		titleElementId(customerId: Number): string {
			return `title-${this.componentBorrowerType}-${customerId}-${this.sectionNumber}`;
		},
		dateElementId(customerId: Number): string {
			return `date-${this.componentBorrowerType}-${customerId}-${this.sectionNumber}`;
		}
	}
});
