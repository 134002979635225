
import { Component, Mixins } from 'vue-property-decorator';
import { ScaleMixin, RouteMixin } from '@/mixins';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { UrlUtils } from '@/utils';
import { AutoFactory, OnlinePortalAuthenticationService } from '@sageworks/jpi';

@Component({
	components: { FontAwesomeIcon }
})
export default class VerifyEmail extends Mixins(ScaleMixin, RouteMixin) {
	private submitting = false;
	private sendingEmail = false;
	private verificationCode: string | null = null;
	private verificationFailed = false;
	private verificationRateLimitExceeded = false;
	private couldNotSendVerificationEmail = false;

	private mounted() {
		this.verificationCode = this.directStore.state.Login.emailVerificationCode;
		this.sendVerificationEmail();
	}

	private beforeDestroy() {
		if (!this.verificationFailed) {
			this.directStore.dispatch.Login.setEmailVerificationCode(this.verificationCode);
		}
	}

	get userEmail(): string {
		return this.directStore.state.Login.userEmail;
	}

	public async sendVerificationEmail(forceSend = false) {
		if (this.directStore.state.Login.userEmail === this.directStore.state.Authentication.lastEmailVerificationSent && !forceSend) {
			return;
		}
		this.couldNotSendVerificationEmail = false;
		this.sendingEmail = true;
		try {
			await this.directStore.dispatch.Authentication.sendVerificationEmail({
				email: this.directStore.state.Login.userEmail,
				subdomain: UrlUtils.getOnlinePortalSubdomain()
			});
		} catch {
			this.couldNotSendVerificationEmail = true;
		} finally {
			this.sendingEmail = false;
			this.verificationFailed = false;
		}
	}

	private validationState(fieldName: string) {
		if (!this.showValidation) {
			return null;
		} else if (this.$v[fieldName].$invalid) {
			return false;
		}
		return true;
	}

	private async submit(): void {
		this.showValidation = true;
		try {
			await AutoFactory.get(OnlinePortalAuthenticationService).checkVerificationCode({
				email: this.directStore.state.Login.userEmail,
				emailVerificationCode: this.verificationCode
			});
		} catch (err) {
			if (err.status === 429) {
				this.verificationRateLimitExceeded = true;
				return;
			}
			this.verificationFailed = true;
			return;
		}
		this.submitting = true;
		this.directStore.dispatch.Login.setEmailVerificationCode(this.verificationCode);
		this.$router.push(this.OnlinePortalRoutes.CreatePassword.path);
	}
}
