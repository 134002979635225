import { KeyAlphaCode } from '../../enums/key-alpha-code';

const keyboardClickEquivalents = [
	KeyAlphaCode.Enter,
	KeyAlphaCode.Space,
	KeyAlphaCode.Space_Old
];

export function isClickEquivalent(event: Event) {
	if (!(event instanceof KeyboardEvent)) return false;

	return keyboardClickEquivalents.includes(event.key as KeyAlphaCode);
}