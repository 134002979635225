import { DataObject } from '@sageworks/dynamic-forms';
import { AutoFactory, LoanApplicationProposedLien, ObjectPropertyValues, OnlinePortalApplicationProposedLiensService } from '@sageworks/jpi';
import { defineActions } from 'direct-vuex';
import { MultiLoanApplicationFormModuleState } from '../state';
import { actionContext } from '../store-helper';

export default defineActions({
	async removeProposedLien(context, { id }: { id: number }): Promise<void> {
		const { state, commit } = actionContext(context);
		const { renderData } = state as MultiLoanApplicationFormModuleState;
		const { proposedLiens = [] } = renderData?.formData ?? {};

		if (id == null) {
			return;
		}

		const proposedLien = proposedLiens.find(x => x.collateralID === id);
		if (proposedLien?.id) {
			const lienService = AutoFactory.get(OnlinePortalApplicationProposedLiensService);
			await lienService._delete(proposedLien.id);
			commit.REMOVE_PROPOSED_LIEN({ id: proposedLien.id! });
			commit.REMOVE_DATA_OBJECT({ type: DataObject.TypeEnum.Collateral, id });
		}
	},
	async createNewProposedLien(context, { loanApplicationId, collateral }: { loanApplicationId: number, collateral: ObjectPropertyValues }): Promise<void> {
		const { state, commit, getters } = actionContext(context);
		const { renderData } = state as MultiLoanApplicationFormModuleState;
		const { proposedLiens = [] } = renderData?.formData ?? {};

		if (loanApplicationId == null) {
			throw new Error('loanApplication id cannot be null or undefined');
		}

		const proposedLoanId = getters.proposedLoanId;
		const exists = proposedLiens.some(x => x.collateralID === collateral.id);

		if (exists) {
			return;
		}

		const newProposedLien: LoanApplicationProposedLien = {
			loanApplicationId,
			proposedLoanId,
			collateralId: collateral.id
		};
		const lienService = AutoFactory.get(OnlinePortalApplicationProposedLiensService);
		const newLien = await lienService.create(newProposedLien);
		commit.UPSERT_PROPOSED_LIEN({ proposedLien: newLien });
	},
});