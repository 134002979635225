import { DataFieldConvertStrategy } from '../../data-field-convert-strategy';
import { DataField } from '@sageworks/jpi';
import { TemplateDataField, CustomComponentType } from '../../../../../enums';
import { setDropdownProperties, setUrlSelectProperties, setSharedSelectProperties } from '../../select-field-converter-utils';
import { formatDataFieldIdKey } from '../../../../../components/form-components/simple-inputs/extended-field-helper';

export class VehicleSelectDataFieldConverter implements DataFieldConvertStrategy {
	private static AllowedFields = [
		TemplateDataField.VehicleBodyStyle,
		TemplateDataField.VehicleMake,
		TemplateDataField.VehicleModel,
		TemplateDataField.VehicleYear,
		TemplateDataField.VehicleState
	];

	private dataFieldMaps: Map<number | undefined | null, DataField>;

	constructor(allDataFields: DataField[]) {
		const entries: any[] = allDataFields.filter(x => x.templateDataFieldId != null).map(x => [x.templateDataFieldId, x]);

		this.dataFieldMaps = new Map(entries);
	}

	isFieldValid(dataField: DataField): boolean {
		return VehicleSelectDataFieldConverter.AllowedFields.includes(dataField.templateDataFieldId as TemplateDataField);
	}

	convert(dataField: DataField, currentProperties: any) {
		const properties = { ...currentProperties };

		switch (dataField.templateDataFieldId) {
			case TemplateDataField.VehicleState:
				this.setStateFieldProperties(properties);
				break;
			case TemplateDataField.VehicleYear:
				this.setYearFieldProperties(properties);
				break;
			case TemplateDataField.VehicleMake:
				this.setMakeFieldProperties(properties);
				break;
			case TemplateDataField.VehicleModel:
				this.setModelFieldProperties(properties);
				break;
			case TemplateDataField.VehicleBodyStyle:
				this.setBodyStyleFieldProperties(properties);
				break;
		}

		return properties;
	}

	private setStateFieldProperties(properties: any) {
		properties.dataType = 'number';
		properties.type = CustomComponentType.vehicleStateSelect;
	}

	private setMakeFieldProperties(properties: any) {
		properties.dataType = 'string';

		this.setVehicleSelectBaseProperties(properties);
		this.refreshOnDataFieldIfExists(properties, TemplateDataField.VehicleYear);

		const vehicleYearValue = `{{ instance.data[component.refreshOn[0]] }}`;
		setDropdownProperties(properties, {
			searchField: '',
			path: `nada-vehicle-valuations/makes?modelYear=${vehicleYearValue}`,
			valueProperty: 'make',
			template: '<span>{{ item.make }}</span>'
		});
	}

	private setModelFieldProperties(properties: any) {
		properties.dataType = 'string';

		this.setVehicleSelectBaseProperties(properties);
		this.refreshOnDataFieldIfExists(properties, TemplateDataField.VehicleYear);
		this.refreshOnDataFieldIfExists(properties, TemplateDataField.VehicleMake);

		const vehicleYearValue = `{{ instance.data[component.refreshOn[0]] }}`;
		const vehicleMakeValue = `{{ instance.data[component.refreshOn[1]] }}`;
		setDropdownProperties(properties, {
			searchField: '',
			path: `nada-vehicle-valuations/models?modelYear=${vehicleYearValue}&make=${vehicleMakeValue}`,
			valueProperty: 'model',
			template: '<span>{{ item.model }}</span>'
		});
	}

	private setBodyStyleFieldProperties(properties: any) {
		properties.dataType = 'string';

		this.setVehicleSelectBaseProperties(properties);
		this.refreshOnDataFieldIfExists(properties, TemplateDataField.VehicleYear);
		this.refreshOnDataFieldIfExists(properties, TemplateDataField.VehicleMake);
		this.refreshOnDataFieldIfExists(properties, TemplateDataField.VehicleModel);

		const vehicleYearValue = `{{ instance.data[component.refreshOn[0]] }}`;
		const vehicleMakeValue = `{{ instance.data[component.refreshOn[1]] }}`;
		const vehicleModelValue = `{{ instance.data[component.refreshOn[2]] }}`;
		setDropdownProperties(properties, {
			searchField: '',
			path: `nada-vehicle-valuations/bodies?modelYear=${vehicleYearValue}&make=${vehicleMakeValue}&model=${vehicleModelValue}`,
			valueProperty: 'body',
			template: '<span>{{ item.body }}</span>'
		});
	}

	private setYearFieldProperties(properties: any) {
		properties.dataType = 'number';

		this.setVehicleSelectBaseProperties(properties);

		setDropdownProperties(properties, {
			searchField: '',
			path: 'nada-vehicle-valuations/years',
			valueProperty: 'modelYear',
			template: '<span>{{ item.modelYear }}</span>'
		});
	}

	private setVehicleSelectBaseProperties(properties: any) {
		properties.allowCustomValues = true;

		setSharedSelectProperties(properties);
		setUrlSelectProperties(properties);
	}

	private refreshOnDataFieldIfExists(properties: any, templateDataField: TemplateDataField) {
		if (!this.dataFieldMaps.has(templateDataField)) {
			return;
		}

		properties.refreshOn = properties.refreshOn ?? [];

		const foundDatafield = this.dataFieldMaps.get(templateDataField) as DataField;
		properties.refreshOn.push(formatDataFieldIdKey(foundDatafield?.id?.toString() ?? '0'));
	}
}
