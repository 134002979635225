
import { Component, Mixins } from 'vue-property-decorator';
import { MainTemplate } from '../../components/templates';
import { OnlinePortalPostLoginHeader, SessionInactivityPrompt, LoanOfficerContactCard, LenderViewAlert } from '@/components';
import { ScaleMixin, RouteMixin } from '@/mixins';
import { StylingConstants } from '@/enums/styling-constants';

@Component({
	components: {
		MainTemplate,
		OnlinePortalPostLoginHeader,
		SessionInactivityPrompt,
		LoanOfficerContactCard,
		LenderViewAlert
	}
})
export default class PortalTemplate extends Mixins(ScaleMixin, RouteMixin) {
	get backgroundClassName(): string {
		return `${StylingConstants.backgroundClassName} pb-2`;
	}
}
