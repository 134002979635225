
import { Component, Mixins } from 'vue-property-decorator';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import { ValidationUtils } from '@/utils';
import { ScaleMixin, RouteMixin } from '@/mixins';
import { OnlinePortalUserPasswordChangeRequest } from '@sageworks/jpi';

@Component({
	validations: {
		currentSecret: {
			required
		},
		newSecret: {
			required,
			minLength: minLength(8),
			containsUppercase: ValidationUtils.containsUppercase,
			containsAlpha: ValidationUtils.containsAlpha,
			containsNumeric: ValidationUtils.containsNumeric
		},
		confirmNewSecret: {
			required,
			sameAs: sameAs('newSecret')
		}
	}
})
export default class ChangePassword extends Mixins(ScaleMixin, RouteMixin) {
	private currentSecret?: string = '';
	private newSecret?: string = '';
	private confirmNewSecret?: string = '';
	private submitting: boolean = false;
	private saveFeedback: string | null = null;
	private validationMessages: Map<string, string> = new Map([
		['currentSecret', 'Please enter your current password'],
		['newSecret', 'Password must meet all requirements'],
		['confirmNewSecret', 'Passwords must match']
	]);
	private showValidation: boolean = false;

	private async created() {
		await this.directStore.dispatch.User.loadCurrentUser();
	}

	private validationState(fieldName: string) {
		if (!this.showValidation) {
			return null;
		} else if (this.$v[fieldName].$invalid) {
			return false;
		}
		return true;
	}

	private async submit(): Promise<void> {
		if (this.$v.$invalid) {
			this.showValidation = true;
			return;
		}

		this.submitting = true;
		try {
			await this.changeSecret();
			this.showValidation = true;
			this.saveFeedback = 'Password changed!';
		} catch (err) {
			this.showValidation = false;
			this.saveFeedback = 'Error attempting to change your password. The provided current password may be incorrect.';
		}
		this.submitting = false;
	}

	private async changeSecret(): Promise<void> {
		await this.directStore.dispatch.User.updateCurrentUserPassword({
			currentSecret: this.currentSecret,
			newSecret: this.newSecret
		} as OnlinePortalUserPasswordChangeRequest);
	}
}
