import { createBasicFactoryContext, CustomComponentType, CustomerCustomComponentTypes, FactoryContext, FormDataSubmissionDataMapperUtils } from '@sageworks/dynamic-forms';
import { AggregatedFormDataModel } from '@sageworks/jpi';
import { StoreType } from '../../../store';

export const findChildCustomerComponentType = (data: any): CustomComponentType | undefined => {
	if (data == null) {
		return;
	}

	const presentComponentTypes = CustomerCustomComponentTypes.filter(componentType => Object.keys(data).includes(componentType));
	const presentComponentTypesWithDataInstances = presentComponentTypes.filter(componentType => data[componentType]?.data?.id);
	if (presentComponentTypesWithDataInstances?.length > 0) {
		return presentComponentTypesWithDataInstances[0];
	}
	return;
};

export const createFactoryContext = (store: StoreType, creditReportAuthorization?: any): FactoryContext => {
	const {
		beneficialOwners = [],
		creditReportAuthorizations = [],
		dataObjects = {},
		financialSubaccountMappings = []
	} = (store.state.MultiLoanApplicationForm.renderData?.formData as AggregatedFormDataModel) ?? {};

	const formattedDataObjects = FormDataSubmissionDataMapperUtils.convertDataObjects(dataObjects);
	const formattedBeneficialOwners = FormDataSubmissionDataMapperUtils.convertBeneficialOwnerData(beneficialOwners);

	const proposedLoanId = FormDataSubmissionDataMapperUtils.getProposedLoanId(dataObjects) ?? 0;

	return {
		...createBasicFactoryContext(),
		beneficialOwners: formattedBeneficialOwners,
		creditReportAuthorizations: creditReportAuthorization != null ? [creditReportAuthorization] : creditReportAuthorizations,
		currentProposedLoanId: proposedLoanId,
		dataObjectsByType: formattedDataObjects,
		financialSubaccounts: financialSubaccountMappings,
		presetDataFieldsByDataObject: store.state.LoanApplicationMetadata.presetDataFieldsByDataObject
	};
};
