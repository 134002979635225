
import Vue from 'vue';
import { OnlinePortalRoutes } from '@/OnlinePortalRoutes';
import { ApplicationSpinner } from '@/components';

export default Vue.extend({
	name: 'ApplicationDataLoadRedirect',
	components: {
		ApplicationSpinner
	},
	props: {
		redirectRoutePath: {
			type: String,
			required: true,
			default: OnlinePortalRoutes.PreApplication.path
		}
	},
	mounted() {
		this.loadAllRequiredData();
	},
	methods: {
		async loadAllRequiredData() {
			await this.directStore.dispatch.User.loadCurrentUser();
			await Promise.all([
				this.directStore.dispatch.ApplicationTemplates.fetchPreAppTemplate(),
				this.directStore.dispatch.InstitutionSettings.fetchAuthorizationSetting(),
				this.directStore.dispatch.InstitutionSettings.fetchDynamicApplicationSettings(),
				this.directStore.dispatch.InstitutionSettings.fetchAllMessages()
			]);
			this.$router.push({ path: this.redirectRoutePath, query: this.$route.query });
		}
	}
});
