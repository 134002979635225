module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="card form-builder-panel" ref="group-panel-' +
((__t = ( ctx.groupKey )) == null ? '' : __t) +
'">\n	<div class="card-header form-builder-group-header p-0" id="heading-' +
((__t = ( ctx.groupKey )) == null ? '' : __t) +
'">\n		<h5 class="mb-0 mt-0">\n			<button\n				class="btn btn-block builder-group-button p-3"\n				type="button"\n				data-toggle="collapse"\n				data-target="#group-' +
((__t = ( ctx.groupKey )) == null ? '' : __t) +
'"\n				data-parent="#' +
((__t = ( ctx.groupId )) == null ? '' : __t) +
'"\n				aria-expanded="' +
((__t = ( ctx.group.default )) == null ? '' : __t) +
'"\n				aria-controls="group-' +
((__t = ( ctx.groupKey )) == null ? '' : __t) +
'"\n				ref="sidebar-anchor"\n			>\n				' +
((__t = ( ctx.t(ctx.group.title) )) == null ? '' : __t) +
'\n			</button>\n		</h5>\n	</div>\n	<div\n		id="group-' +
((__t = ( ctx.groupKey )) == null ? '' : __t) +
'"\n		class="collapse"\n		data-parent="#' +
((__t = ( ctx.groupId )) == null ? '' : __t) +
'"\n		data-default="' +
((__t = ( ctx.group.default )) == null ? '' : __t) +
'"\n		aria-labelledby="heading-' +
((__t = ( ctx.groupKey )) == null ? '' : __t) +
'"\n		ref="sidebar-group"\n		style="max-height: 500px; overflow-y: auto; overflow-x: hidden;"\n	>\n		<div id="group-container-' +
((__t = ( ctx.groupKey )) == null ? '' : __t) +
'" class="card-body no-drop p-2 col tabs mt-0 mb-0" ref="sidebar-container">\n			<div id="search-container-' +
((__t = (ctx.groupKey)) == null ? '' : __t) +
'" class="d-flex flex-row align-items-center no-drag">\n			<input \n				type="text" \n				placeholder="Search" \n				class="text-muted bg-light border rounded pl-2 py-2 w-100" \n				ref="searchComponents" \n				value="' +
((__t = ( ctx.group.searchValue || '' )) == null ? '' : __t) +
'"\n				data-group-key="' +
((__t = (ctx.groupKey)) == null ? '' : __t) +
'" />\n				<i class="fa fas fa-sm ' +
((__t = ( (ctx.group.searchValue || '').length > 0 ? 'fa-times' : 'fa-search')) == null ? '' : __t) +
' text-muted" \n					style="margin-left: -25px; cursor: pointer;"\n					ref="searchIcon"\n					data-group-key="' +
((__t = (ctx.groupKey)) == null ? '' : __t) +
'"></i>\n			</div>\n			';
 if (ctx.groupKey === 'udFields') { ;
__p += '\n			<button \n				type="button" \n				class="btn btn-primary w-100 mt-1 mb-1"\n				ref="addCustomField">Add New</button>\n			';
 } ;
__p += '\n			';
 !ctx.group.componentOrder || ctx.group.componentOrder.forEach(function(componentKey) { ;
__p += '\n			<span\n				data-group="' +
((__t = ( ctx.groupKey )) == null ? '' : __t) +
'"\n				data-key="' +
((__t = ( ctx.group.components[componentKey].key )) == null ? '' : __t) +
'"\n				data-type="' +
((__t = ( ctx.group.components[componentKey].schema.type )) == null ? '' : __t) +
'"\n				class="btn btn-sm btn-block formcomponent drag-copy nav-item ' +
((__t = ( ctx.group.components[componentKey].hidden ? 'd-none' : '' )) == null ? '' : __t) +
'"\n			>\n				<span class="d-flex align-items-center font-weight-normal">\n					<i class="fa fa-grip-vertical mr-3"></i>\n					';
 if (ctx.group.components[componentKey].icon) { ;
__p += '\n					<i class="' +
((__t = ( ctx.iconClass(ctx.group.components[componentKey].icon) )) == null ? '' : __t) +
'" style="margin-right: 5px;"></i>\n					';
 } ;
__p += '\n					' +
((__t = ( ctx.t(ctx.group.components[componentKey].title) )) == null ? '' : __t) +
'\n				</span>\n			</span>\n			';
 }) ;
__p += '\n			' +
((__t = ( ctx.subgroups.join('') )) == null ? '' : __t) +
'\n		</div>\n	</div>\n</div>\n';
return __p
}