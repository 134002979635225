import Component from 'formiojs/components/_classes/component/Component';
import { CustomComponentType, TemplateDataField } from '../../../../../enums';
import { Customer, DataField } from '@sageworks/jpi';
import { CustomerSearch, PopupState, AddNewBusinessInvestment, AddNewCustomer } from '../../../../form-components';
import AddCustomerPopup from '../../../../form-components/add-customer/add-customer-popup';

export default class AddBusinessInvestmentPopup extends AddCustomerPopup {
	public preClose = () => {
		return Promise.resolve();
	};

	protected get bypassCustomerSearch() {
		return false;
	}

	protected get businessDataFields(): DataField[] {
		const templateDataFieldNameMapping: TemplateDataField[] = [
			TemplateDataField.BusinessName,
			TemplateDataField.BusinessState,
			TemplateDataField.BusinessIndustryCode,
			TemplateDataField.BusinessSubEntityType
		];

		return this.filterDataFields(templateDataFieldNameMapping);
	}

	protected get farmDataFields(): DataField[] {
		const templateDataFieldNameMapping: TemplateDataField[] = [TemplateDataField.FarmName, TemplateDataField.FarmState, TemplateDataField.FarmIndustryCode];

		return this.filterDataFields(templateDataFieldNameMapping);
	}

	protected get modalTitle(): string {
		return `${this.popupState === PopupState.EditEntity ? 'Edit Business Information' : 'Add New Business Investment'}`;
	}

	protected get componentTitle(): string {
		return 'Add Business Investment';
	}

	protected filterDataFields(allowedFields: TemplateDataField[]): DataField[] {
		return this.addNewEntityDataFields.filter(field => allowedFields.includes(field.templateDataFieldId as TemplateDataField));
	}

	public static schema(...extend: any) {
		return Component.schema(
			{
				label: 'Add Business Investment',
				type: CustomComponentType.addBusinessInvestmentPopup,
				key: CustomComponentType.addBusinessInvestmentPopup,
				roleType: null,
				input: false
			},
			...extend
		);
	}

	protected createCustomerSearch(): CustomerSearch {
		return new CustomerSearch(
			CustomerSearch.schema({
				ignoredCustomerIds: this.component.ignoredCustomerIds,
				allowedEntityTypes: [Customer.TypeEnum.Business, Customer.TypeEnum.Farm]
			}),
			{ ...this.options, events: this.events },
			{}
		);
	}

	protected createAddCustomerForm(): AddNewCustomer {
		return new AddNewBusinessInvestment(
			AddNewBusinessInvestment.schema(this.options.editMode, this.component.addNewEntityDataFields),
			{ ...this.options, events: this.events },
			this.data
		);
	}
}
