import {
	ObjectPropertyValues,
	DataFieldValue,
	LoanApplicationCreditAuthorization,
	BeneficialOwner,
	FinancialSubaccount,
	DataField,
	ProposedLoanSBAInfo
} from '@sageworks/jpi';
import { CustomComponentType, FormioComponentType, DataObject, TemplateDataField } from '../../enums';
import { DataObjectsByType, RelatedRoleWithCustomerType, LoanRoleWithCustomerType, ObjectPropertyValuesWithRoleType } from '../../models';
import CreditReportWidget from '../../components/form-components/credit-report/credit-report';
import { DataObjectsByTypeFactory } from '../../utils';
import _ from 'lodash';
import { createCashAccountsFormObject, createLifeInsuranceFormObject, createOtherLiabilitiesFormObject, createBusinessInvestmentFormObject, createRetirementAccountsFormObject } from './financial-subaccount-object-factory-utils';

export interface RepeaterEntity {
	form: Form;
}

export interface Form {
	data: FormData;
}

export interface InfoFormData {
	[infoType: string]: Info;
}

export interface Info {
	data: InfoData;
	state: string;
}

export interface InfoData {
	id: number;

	[formattedDataFieldIdKey: string]: any;
}

export type PresetDataFieldsByDataObject = {
	[type in DataObject.TypeEnum]?: DataField[];
};

export interface FactoryContext {
	currentProposedLoanId: number | null;
	dataObjectsByType: DataObjectsByType;
	creditReportAuthorizations: LoanApplicationCreditAuthorization[] | null;
	beneficialOwners: BeneficialOwner[];
	financialSubaccounts: FinancialSubaccount[];
	presetDataFieldsByDataObject: PresetDataFieldsByDataObject | null;
	sbaInfo: ProposedLoanSBAInfo | null;
}

export function createBasicFactoryContext(): FactoryContext {
	return {
		currentProposedLoanId: null,
		dataObjectsByType: DataObjectsByTypeFactory.createDataObjectByType(),
		creditReportAuthorizations: null,
		beneficialOwners: [],
		financialSubaccounts: [],
		presetDataFieldsByDataObject: {},
		sbaInfo: null
	};
}

export function createBasicFormObject(data: any): Info {
	const formObject = {
		data,
		state: 'draft'
	};

	return formObject;
}

export function createDataObjectFormObject(dataObject: ObjectPropertyValues, parentLoanRoleId?: number | null): Info {
	if (dataObject == null) {
		throw new Error('dataObject can not be null or undefined');
	}

	const data: any = {
		id: dataObject.id ?? 0
	};

	if (parentLoanRoleId) {
		data.parentLoanRoleId = parentLoanRoleId;
	}

	// Add data fields to data
	dataObject?.dataFieldValues?.forEach((item: DataFieldValue) => {
		if (item.id != null) {
			data[`__id_${item.id}__`] = item.value;
		}
	});

	return createBasicFormObject(data);
}

export function createCreditReportFormObject(dataObject: ObjectPropertyValues): any {
	return {
		[CustomComponentType.creditReportPullCreditInput]: {
			[FormioComponentType.Form]: createDataObjectFormObject(dataObject)
		}
	};
}

export function getCreditReportAuthorizationObject(
	dataObject: ObjectPropertyValues,
	creditReportAuthorizations?: LoanApplicationCreditAuthorization[] | null
): Object | null {
	const id = dataObject.id;

	if (id == null) {
		return null;
	}

	return {
		[CreditReportWidget.authorizationKey]: (creditReportAuthorizations ?? []).find(auth => auth.customerId === id) ?? null
	};
}

export function createPfsFormObject(factoryContext: FactoryContext, customerId?: number): any {
	const datafields = (factoryContext.presetDataFieldsByDataObject ?? {})[DataObject.TypeEnum.PersonalFinancial];
	const datafieldsByTemplateId = _.keyBy(datafields, 'templateDataFieldId');
	const customerDataFieldId = datafieldsByTemplateId[TemplateDataField.PfsCustomer]?.id;
	const personalFinancialDataObjects = (factoryContext.dataObjectsByType ?? {})[DataObject.TypeEnum.PersonalFinancial] ?? [];

	// Locate financial statement by the customer id
	let personalFinancial: ObjectPropertyValuesWithRoleType | undefined;
	if (customerId) {
		personalFinancial = personalFinancialDataObjects.find(x => {
			const dataFieldValue = (x.dataFieldValues ?? []).find(y => y.id === customerDataFieldId);
			return (dataFieldValue?.value as any) === customerId;
		});
	}

	if (personalFinancial == null) {
		return createBasicFormObject({});
	}

	const formObject = createDataObjectFormObject(personalFinancial, null);

	return formObject;
}

export function createApplicantBusinessQuestionFormObject(dataObject: ObjectPropertyValues): any {
	return {
		[CustomComponentType.creditReportPullCreditInput]: {
			[FormioComponentType.Form]: createDataObjectFormObject(dataObject)
		}
	};
}

export function createPersonalInfoFormObject(dataObject: ObjectPropertyValues, factoryContext: FactoryContext, parentLoanRoleId?: number | null): Info {
	const { creditReportAuthorizations } = factoryContext;
	const formObject = createDataObjectFormObject(dataObject, parentLoanRoleId);

	formObject.data[CustomComponentType.creditReport] = {
		...createCreditReportFormObject(dataObject),
		...getCreditReportAuthorizationObject(dataObject, creditReportAuthorizations)
	};

	formObject.data[CustomComponentType.personalFinancialStatement] = createPfsFormObject(factoryContext, dataObject.id);

	return formObject;
}

export function createBusinessInfoFormObject(dataObject: ObjectPropertyValues, factoryContext: FactoryContext, parentLoanRoleId?: number | null): Info {
	const formObject = createDataObjectFormObject(dataObject, parentLoanRoleId);
	formObject.data[CustomComponentType.applicantBusinessQuestion] = createBasicFormObject({
		proposedLoanId: factoryContext.currentProposedLoanId
	});
	formObject.data['applicantBusinessQuestion'] = factoryContext.sbaInfo?.applicantBusinessCustomerId === dataObject.id;

	return formObject;
}

export function createRepeaterInfo(dataObjects: ObjectPropertyValues[]): any {
	return dataObjects.map(item => {
		return { form: createDataObjectFormObject(item) };
	});
}

export function createInfoWidgetFormObject(
	propName: string,
	dataObject: ObjectPropertyValues,
	factoryContext: FactoryContext,
	parentLoanRoleId?: number | null
): Info {
	const data = {
		[propName]: createFormObject(propName, dataObject, factoryContext, parentLoanRoleId)
	};

	return createBasicFormObject(data);
}

export function createFormObject(propName: string, dataObject: ObjectPropertyValues, factoryContext: FactoryContext, parentLoanRoleId?: number | null): Info {
	if (dataObject == null) {
		throw new Error('dataObject can not be null or undefined');
	}

	switch (propName) {
		case CustomComponentType.personalInfo:
			return createPersonalInfoFormObject(dataObject, factoryContext, parentLoanRoleId);
		case CustomComponentType.businessInfo:
			return createBusinessInfoFormObject(dataObject, factoryContext, parentLoanRoleId);
		case CustomComponentType.otherLiabilities:
			return createOtherLiabilitiesFormObject(dataObject, factoryContext, parentLoanRoleId);
		case CustomComponentType.cashAccount:
			return createCashAccountsFormObject(dataObject, factoryContext, parentLoanRoleId);
		case CustomComponentType.lifeInsurance:
			return createLifeInsuranceFormObject(dataObject, factoryContext, parentLoanRoleId);
		case CustomComponentType.businessInvestment:
			return createBusinessInvestmentFormObject(dataObject, factoryContext, parentLoanRoleId);
		case CustomComponentType.retirementAccount:
			return createRetirementAccountsFormObject(dataObject, factoryContext, parentLoanRoleId);
		default:
			return createDataObjectFormObject(dataObject, parentLoanRoleId);
	}
}

export function createRelatedRoleInfoFormObject(
	roleType: CustomComponentType,
	relatedMapping: RelatedRoleWithCustomerType,
	roleMapping: LoanRoleWithCustomerType,
	propName: string,
	dataObject: ObjectPropertyValues,
	factoryContext: FactoryContext
) {
	switch (roleType) {
		case CustomComponentType.beneficialOwnership:
			throw new Error('Beneficial Ownership form data is no longer valid for related role info and should be filtered');
		default:
			return createInfoWidgetFormObject(propName, dataObject, factoryContext, relatedMapping.parentLoanRoleId);
	}
}

export function createBeneficialOwnershipFormObject(
	customerId: number,
	ownerCustomerId: number,
	infoType: string,
	dataObject: ObjectPropertyValues,
	factoryContext: FactoryContext,
	parentLoanRoleId?: number | null
) {
	const beneficialOwner = factoryContext.beneficialOwners.find(x => x.customerId === customerId && x.ownerCustomerId === ownerCustomerId);
	const beneficialOwnerDataObject = factoryContext.dataObjectsByType[DataObject.TypeEnum.BeneficialOwnerDetails]?.find(x => x.id === beneficialOwner?.id);
	const formObject = createInfoWidgetFormObject(infoType, dataObject, factoryContext, parentLoanRoleId);

	if (beneficialOwnerDataObject) {
		// Populate data object property values for beneficial ownership details
		formObject.data = {
			...formObject.data,
			...createFormObject(CustomComponentType.beneficialOwnership, beneficialOwnerDataObject, factoryContext, parentLoanRoleId).data
		};
	}

	return formObject;
}

export function createRelatedRoleInfoObject(
	infoType: CustomComponentType.personalInfo | CustomComponentType.businessInfo,
	dataObject: ObjectPropertyValues,
	parentLoanRoleId?: number | undefined
) {
	return {
		[infoType]: createDataObjectFormObject(dataObject, parentLoanRoleId)
	};
}
