import Vue from 'vue';
import { defineModule } from 'direct-vuex';
import { getPaged, update, reopen } from '@/logic/DocumentRequest.service';
import { OnlinePortalDocumentRequest } from '@/models';
import { GetDocRequestParams } from '@/models/store-action-params/DocumentRequestModuleParams';

// Using moduleActionContext instead of localActionContext because we need access to rootGetters
import { moduleActionContext } from '.';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => moduleActionContext(context, DocumentRequestModule);

export interface DocumentRequestModuleState {
	documentRequests: OnlinePortalDocumentRequest[] | null;
	currentPageDocumentRequests: OnlinePortalDocumentRequest[];
	lastRetrievalTime: Date;
}
const DocumentRequestModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			documentRequests: null,
			currentPageDocumentRequests: [],
			lastRetrievalTime: new Date()
		} as DocumentRequestModuleState;
	},
	mutations: {
		SET_DOCUMENT_REQUESTS(state, updatedDocumentRequests: OnlinePortalDocumentRequest[]) {
			state.documentRequests = updatedDocumentRequests;
		},
		SET_CURRENT_PAGE_DOCUMENT_REQUESTS(state, updatedDocumentRequests: OnlinePortalDocumentRequest[]) {
			state.currentPageDocumentRequests = updatedDocumentRequests;
		},
		SET_LAST_RETRIEVAL_TIME(state, newTime: Date) {
			state.lastRetrievalTime = newTime;
		},
		UPDATE_DOCUMENT_REQUEST(state, updatedRequest: OnlinePortalDocumentRequest) {
			if (state.documentRequests) {
				let indexToUpdate = state.documentRequests.findIndex(x => x.id === updatedRequest.id);
				Vue.set(state.documentRequests, indexToUpdate, updatedRequest);
			}
		}
	},
	actions: {
		async updateDocumentRequest(context, updatedRequest: OnlinePortalDocumentRequest): Promise<void> {
			const { commit } = actionContext(context);
			commit.UPDATE_DOCUMENT_REQUEST(updatedRequest);
			await update(updatedRequest);
		},
		async reopenDocumentRequest(context, reopenedRequest: OnlinePortalDocumentRequest): Promise<void> {
			const { commit } = actionContext(context);
			let updatedRequest = await reopen(reopenedRequest);
			commit.UPDATE_DOCUMENT_REQUEST(updatedRequest);
		},
		/**
		 * Returns either all document requests or 1000, whichever is smaller
		 */
		async loadAllDocumentRequests(context): Promise<void> {
			const { dispatch, getters } = actionContext(context);
			if (getters.documentRequestsExpired) {
				await dispatch.loadPagedDocumentRequests({ page: 1, perPage: 1000, refreshCache: true });
			}
		},
		async loadPagedDocumentRequests(context, { page, perPage, refreshCache }: GetDocRequestParams): Promise<void> {
			const { rootGetters, rootState, state, commit, getters } = actionContext(context);
			refreshCache = refreshCache || false;
			if (refreshCache || getters.documentRequestsExpired) {
				// Get all loaded loan app ids to filter on
				let loanApplicationIds = rootState.Application.loanApplications ? rootState.Application.loanApplications.map(x => x.id) : undefined;
				let newRequests = await getPaged(1, 1000, loanApplicationIds);

				// Don't show waived requests in the borrower view, show for lender for un-waiving
				if (!rootGetters.User.isLender) {
					newRequests = newRequests.filter(
						documentRequest => !documentRequest.dateWaived && !documentRequest.waivedReason && !documentRequest.waivedByLoanOfficerId
					);
				}
				commit.SET_LAST_RETRIEVAL_TIME(new Date());
				commit.SET_DOCUMENT_REQUESTS(newRequests);
			}
			(window as any).reqs = state.documentRequests;
			let currentDocumentRequests: OnlinePortalDocumentRequest[] = state
				.documentRequests!.filter(x => x.isOpenRequest)
				.slice(perPage * (page - 1), perPage * page);
			let previousDocumentRequests: OnlinePortalDocumentRequest[] = state
				.documentRequests!.filter(x => !x.isOpenRequest)
				.slice(perPage * (page - 1), perPage * page);
			commit.SET_CURRENT_PAGE_DOCUMENT_REQUESTS(currentDocumentRequests.concat(previousDocumentRequests));
		}
	},
	getters: {
		numOpenRequests(state): number {
			if (!state.documentRequests) {
				return 0;
			}
			return state.documentRequests.reduce((totalOpenRequests, requestToCheck) => {
				let isOpenRequest = !requestToCheck.dateUploaded;
				if (isOpenRequest) {
					totalOpenRequests += 1;
				}
				return totalOpenRequests;
			}, 0);
		},
		documentRequestsExpired(state): boolean {
			let dataExpired: boolean = (Date.now() - state.lastRetrievalTime.getTime()) / 60000 > 10;
			return dataExpired || !state.documentRequests || state.documentRequests.length === 0;
		}
	}
});

export default DocumentRequestModule;
