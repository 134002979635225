
import Vue, { PropType } from 'vue';
import { DocumentRequestTypes, OnlinePortalApplicationDocumentRequest } from '../../models';
import DocumentUploadHeader from './document-upload-header.vue';
import { ApplicationDocumentRequest, DocumentName } from '@sageworks/jpi';


const POLLING_INTERVAL = 5000;
const MAX_POLLING_AMOUNT = 450000; // 7.5 minutes

export default Vue.extend({
	name: 'EsignForm',
	components: { DocumentUploadHeader },
	props: {
		documentRequest: Object as PropType<OnlinePortalApplicationDocumentRequest>,
	},
	data() {
		return {
			sendingForEsign: false,
			statusPollingIntervalId: null as number | null,
		};
	},
	computed: {
		isLenderView(): boolean {
			return this.directStore.getters.User.isLender;
		},
		isApplicationDocumentRequest(): boolean {
			return this.documentRequest.documentRequestType === DocumentRequestTypes.LoanApplicationDocumentRequest;
		},
		isOpenApplicationDocumentRequest(): boolean {
			return (
				!this.documentRequest.dateUploaded &&
				!this.documentRequest.dateWaived &&
				!(this.documentRequest as ApplicationDocumentRequest).waivedReason &&
				!(this.documentRequest as ApplicationDocumentRequest).waivedByLoanOfficerId &&
				!(this.documentRequest as ApplicationDocumentRequest).notUploadedReason &&
				this.adobeTemplateStatus !== 'SIGNED'
			);
		},
		documentName(): DocumentName | null {
			const  { documentNamesId } = this.documentRequest;
			if (documentNamesId == null || documentNamesId <= 0) {
				return null;
			}

			return this.directStore.getters.DocumentName.documentNameById(documentNamesId);
		},
		adobeTemplateMappingId(): number | null | undefined {
			return this.documentName?.adobeTemplateDocumentNameMappingsId;
		},
		adobeTemplateStatus (): string | undefined {
			const statusMap = this.directStore.state.AdobeESignModule.adobeTemplateStatuses;

			if (this.documentRequest.id == null) {
				return undefined;
			}

			return statusMap.get(this.documentRequest.id);
		},
	},
	destroyed() {
		this.stopStatusPolling();
	},
	methods: {
		async resetTemplateSignature() {
			const { documentNamesId, customerId , id } = this.documentRequest;

			if (documentNamesId == null || customerId == null || id == null) {
				this.showToast('E-signature could not be reset.', 'Please try again');
				return;
			}

			try {
				await this.directStore.dispatch.AdobeESignModule.resetTemplateEsignature({
					customerId: customerId,
					documentRequestId: id
				});

				this.getAdobeDocumentStatus();
			} catch {
				this.showToast('E-signature could not be reset.', 'Please try again');
			}
		},
		async getTemplateUrl() {
			this.sendingForEsign = true;
			const url = await this.directStore.dispatch.AdobeESignModule.getTemplateUrl({
				documentRequestId: this.documentRequest.id,
			});
			if (url != null) {
				window.open(url);
			} else {
				this.showToast('Esignature Error', 'Url could not be retrieved.');
			}
			this.sendingForEsign = false;
		},
		stopStatusPolling() {
			if (this.statusPollingIntervalId) {
				clearInterval(this.statusPollingIntervalId!);
				this.statusPollingIntervalId = null;
			}
		},
		async startStatusPolling() {
			await this.getAdobeDocumentStatus();

			// If a previous polling interval is still running, clear it
			this.stopStatusPolling();

			if (this.adobeTemplateStatus === 'SIGNED') {
				return;
			}

			this.statusPollingIntervalId = setInterval(() => {
				if (this.adobeTemplateStatus === 'SIGNED') {
					this.stopStatusPolling();
				}

				this.getAdobeDocumentStatus();
			}, POLLING_INTERVAL);

			setTimeout(() => {
				this.stopStatusPolling();
			}, MAX_POLLING_AMOUNT);
		},
		async sendForEsignature() {
			const { documentNamesId, customerId , id } = this.documentRequest;

			if (documentNamesId == null || customerId == null || id == null) {
				this.showToast('Document could not be sent for E-Signature', 'Please try again.');
				return;
			}

			this.sendingForEsign = true;
			try {
				const adobeTemplateResponse = await this.directStore.dispatch.AdobeESignModule.sendAdobeTemplatesForSignature({
					adobeTemplateDocumentNameMappingId: this.adobeTemplateMappingId,
					customerId: customerId,
					documentRequestId: id,
				});

				if (adobeTemplateResponse.url != null) {
					window.open(adobeTemplateResponse.url);
					if (adobeTemplateResponse.webhookError) {
						this.showToast(
							'E-Signature Webhook Error',
							'An issue has occurred and the application can only be submitted once the scheduled job has been complete.'
						);
					}
				} else if (adobeTemplateResponse.phoneNumberError) {
					this.showToast(
						'Document could not be sent for E-Signature',
						'You cannot sign this document as a phone number was not provided for authentication.'
					);
				} else {
					this.showToast('Document could not be sent for E-Signature', 'Url could not be retrieved.');
				}
				await this.startStatusPolling();
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e);
				this.showToast('Document could not be sent for E-Signature', 'Please try again.');
			} finally {
				this.sendingForEsign = false;
			}
		},
		async getAdobeDocumentStatus() {
			const { documentNamesId, customerId , id } = this.documentRequest;

			if (documentNamesId == null || customerId == null || id == null) {
				return;
			}

			await this.directStore.dispatch.AdobeESignModule.fetchTemplateStatus({
				documentRequestId: id,
				documentNamesId: documentNamesId
			});
		},
		showToast(title: string, message: string, variant?: string) {
			this.$root.$bvToast.toast(message, {
				title: title,
				solid: true,
				autoHideDelay: 3000,
				noCloseButton: true,
				toaster: 'b-toaster-bottom-right',
				variant: variant
			});
		},
	}
});
