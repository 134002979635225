import { CustomComponentType, DataObject, DeletePayload, SubmitPayload, TemplateDataField } from '@sageworks/dynamic-forms';
import { StoreType } from '../../../../store';
import { formatDataFieldIdKey } from '@sageworks/dynamic-forms/src/components/form-components/simple-inputs/extended-field-helper';
import { DataObjectDataHandler } from '../data-object-data-handler';
import { getOrForgePfs } from '../financials-data-handler-utils';
import { RealEstateFinancialData } from '@sageworks/jpi';

export class RealEstateFinancialsDataHandler extends DataObjectDataHandler {

	private get loanMappingId() {
		const { metadata } = this._store.state.LoanApplicationMetadata;
		const { loanIndex } = this._store.state.MultiLoanApplicationForm;

		return metadata[loanIndex].loanMappingId!;
	}

	private get currentRealEstateFinancials() {
		return this._store.state.MultiLoanApplicationForm.renderData?.formData?.realEstateFinancials ?? [];
	}

	private get templateDataFieldMappings(): { [templateDataFieldId: number]: number } {
		const keyValuePresetFields = this._store.state.LoanApplicationMetadata.presetDataFieldsByDataObject[DataObject.TypeEnum.PersonalFinancial]?.map(x => [
			x.templateDataFieldId,
			x.id
		]) as any;

		const pfsPresetDataFields = Object.fromEntries(keyValuePresetFields);

		return {
			...this._store.state.CustomComponentProperties.templateDataFieldIdToDataFieldId,
			...pfsPresetDataFields
		};
	}

	constructor(store: StoreType) {
		super(store, CustomComponentType.investmentRealEstate);
	}

	public async submit(payload: SubmitPayload): Promise<any> {
		const pfsId = await getOrForgePfs(payload, this.loanMappingId, this._store);

		const inputDataIdFieldId = formatDataFieldIdKey(this.templateDataFieldMappings[TemplateDataField.RealEstateOwnerFinancialDataId].toString());
		const propertyIdFieldId  = formatDataFieldIdKey(this.templateDataFieldMappings?.[TemplateDataField.RealEstatePropertyId].toString());

		payload.submission.data[inputDataIdFieldId] = pfsId;
		const propertyId = payload.submission.data[propertyIdFieldId];

		const result = await this.saveDataObject(DataObject.TypeEnum.RealEstateFinancials, payload);

		if (!this.currentRealEstateFinancials.find(x => x.id === result.id)) {
			const newRealEstateFinancials: RealEstateFinancialData = { 
				id: result.id,
				ownerInputDataID: pfsId,
				customerID: payload.metadata?.customerId,
				propertyID: propertyId
			};
			await this._store.dispatch.MultiLoanApplicationForm.setRealEstateFinancial({ 
				realEstateFinancials: [...this.currentRealEstateFinancials, newRealEstateFinancials]
			});
		}

		return this.createFormObject(payload, result);
	}

	public async delete(payload: DeletePayload) {
		const result = await super.delete(payload);

		const idRemoved = payload.submission?.form?.data?.id as number;

		await this._store.dispatch.MultiLoanApplicationForm.setRealEstateFinancial({ 
			realEstateFinancials: this.currentRealEstateFinancials.filter(x => x.id !== idRemoved),
		});
		
		return result;
	}
}