import { checkConditionalityAcrossForms, shouldFieldUseOriginalConditionality, checkReadOnlyComponentVisibility } from '../../simple-inputs/extended-field-helper';
import editForm from './modified-panel-component.form';
import PanelComponent from 'formiojs/components/panel/Panel';
import Component from 'formiojs/components/_classes/component/Component';
import { FormioConditionalityUtils } from '../../../../utils/formio-conditionality-utils';
import { LocateParentHelper } from '../../../../utils';
import { FormioComponentType } from '../../../../enums';
import FormComponent from 'formiojs/components/form/Form';

export default class ModifiedPanelComponent extends PanelComponent {
	static schema(...extend: any[]) {
		return PanelComponent.schema(
			{
				title: '',
			},
			...extend
		);
	}

	static get builderInfo() {
		return {
			...super.builderInfo,
			schema: ModifiedPanelComponent.schema()
		};
	}

	static editForm = editForm;

	private get hideBorderWrapper() {
		const form: any = LocateParentHelper.locateClosestParent<FormComponent>(
			this,
			parent => parent.type === FormioComponentType.Form && parent instanceof FormComponent,
			2
		);

		return form?.component?.hideTopLevelPanelBorderWrapper ?? false;
	}

	renderTemplate(name: any, options?: any, topLevel?: any) {
		options = options ?? {};
		options.hideBorderWrapper = this.hideBorderWrapper;

		return super.renderTemplate(name, options, topLevel);
	}

	conditionallyVisible(data: any): boolean {
		// If we are rendering the component in readonly and every child component is hidden, we should hide the panel
		if (this.options.readOnly) {
			if (!checkReadOnlyComponentVisibility(this)) {
				return false;
			}

			var components = this.getComponents();

			if (components != null && components.length > 0) {
				return components.reduce((accumulator: any, component: Component) => {
					return accumulator || component.conditionallyVisible(data);
				}, false);
			}
		}

		if (shouldFieldUseOriginalConditionality(this)) {
			return super.conditionallyVisible(data);
		}

		return checkConditionalityAcrossForms(this);
	}

	checkCondition(row: any, data: any) {
		return FormioConditionalityUtils.checkCondition(
			this.component,
			row || this.data,
			data || this.rootValue,
			this.root ? (this.root as any)._form : {},
			this
		);
	}
}
