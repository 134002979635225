import { defineModule } from 'direct-vuex';
import { LocalStorageUtils } from '@/utils';
import { LocalStorageKeys } from '@/enums';
import { isSessionActive, TEN_SECONDS_IN_MS } from '@/utils/session-utils';
import { SessionActivityService, AutoFactory } from '@sageworks/jpi';
import _ from 'lodash';

// Using moduleActionContext instead of localActionContext because we need access to rootGetters
import { moduleActionContext } from '.';
import { ActionContext } from 'vuex';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => moduleActionContext(context, SessionActivityModule);

// Needs to be defined as a constant, or else a new function will be defined every time we call the updateLastUserActionTime action
const updateLastUserActionTimeThrottled = _.throttle(async (context: ActionContext<any, any>) => {
	const { rootGetters, commit } = actionContext(context);
	commit.SET_COUNTDOWN_INTERVAL_ID(0);
	LocalStorageUtils.setItem(LocalStorageKeys.lastUserActionTime, new Date().toISOString());
	commit.SET_SHOW_LOGOUT_MODAL(false);
	if (rootGetters.User.isLender) {
		const sessionActivityService = AutoFactory.get(SessionActivityService);
		await sessionActivityService.refreshSessionExpirationTime();
	}
}, TEN_SECONDS_IN_MS);

export interface SessionActivityModuleState {
	sessionActivityWatcherIntervalId: number;
	countdownIntervalId: number;
	showLogoutModal: boolean;
}
const SessionActivityModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			sessionActivityWatcherIntervalId: 0,
			countdownIntervalId: 0,
			showLogoutModal: false
		} as SessionActivityModuleState;
	},
	mutations: {
		SET_SESSION_ACTIVITY_WATCHER_INTERVAL_ID(state, sessionActivityWatcherintervalId: number) {
			if (state.sessionActivityWatcherIntervalId !== sessionActivityWatcherintervalId) {
				clearInterval(state.sessionActivityWatcherIntervalId);
			}
			state.sessionActivityWatcherIntervalId = sessionActivityWatcherintervalId;
		},
		SET_COUNTDOWN_INTERVAL_ID(state, countdownIntervalId: number) {
			if (state.countdownIntervalId !== countdownIntervalId) {
				clearInterval(state.countdownIntervalId);
			}
			state.countdownIntervalId = countdownIntervalId;
		},
		SET_SHOW_LOGOUT_MODAL(state, showLogoutModal: boolean) {
			state.showLogoutModal = showLogoutModal;
		}
	},
	actions: {
		/**
		 * Updates the user's last action time, throttled to once every 10 seconds to avoid performance issues.
		 * @param context Automatically passed in from store, method should be called without parameters
		 */
		async updateLastUserActionTime(context) {
			updateLastUserActionTimeThrottled(context);
		},
		startSessionActivityWatcher(context) {
			const { state, commit } = actionContext(context);
			commit.SET_SESSION_ACTIVITY_WATCHER_INTERVAL_ID(
				setInterval(() => {
					if (!state.showLogoutModal && !isSessionActive()) {
						commit.SET_SHOW_LOGOUT_MODAL(true);
					}
				}, 5000)
			);
		},
		async pollForExpirationTime(context) {
			const { rootGetters } = actionContext(context);
			if (!rootGetters.User.isLender) return;
			const sessionLifetime = rootGetters.InstitutionSettings.sessionLifetime;
			const sessionActivityService = AutoFactory.get(SessionActivityService);
			let expirationTime =  (await sessionActivityService.getSessionExpirationTime()).sessionExpirationTime ?? sessionLifetime;
			let lastUserActionTimeString = LocalStorageUtils.getItem(LocalStorageKeys.lastUserActionTime);
			let lastUserActionFromSageworks = sessionLifetime - expirationTime;
			// The session is more fresh when lastUserActionFromSageworks is closer to 0
			if (lastUserActionTimeString == null || lastUserActionFromSageworks < Date.now() - Date.parse(lastUserActionTimeString)) {
				LocalStorageUtils.setItem(LocalStorageKeys.lastUserActionTime, new Date(new Date().getTime() - lastUserActionFromSageworks).toISOString());
			}
		}
	}
});

export default SessionActivityModule;
