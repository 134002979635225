import BaseComponent from '../base/base-widget/base-widget';
import editForm from './sba-form-1919.form';
import { CustomComponentType } from '../../../enums';

export default class SbaForm1919Widget extends BaseComponent {
	static schema(...extend: any) {
		return BaseComponent.schema(
			{
				label: 'Form 1919',
				type: CustomComponentType.sbaForm1919,
				key: CustomComponentType.sbaForm1919
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: 'Form 1919',
			group: '',
			weight: 10,
			schema: SbaForm1919Widget.schema()
		};
	}
}
