import { DataObject, FetchDataType, PresetDataFieldsByDataObject } from '@sageworks/dynamic-forms';
import { SbaInfoApiHelper } from '../data-helpers/SbaInfoApiHelper';
import { StoreType } from '@/store';
import {
	ApplicationTemplateMetadata,
	AutoFactory,
	CustomersService,
	FeatureFlagging,
	LoanApplication,
	OnlinePortalDataFieldsService,
	Collateral,
	DataObject as DataObjectModel,
	ObjectPropertyValues
} from '@sageworks/jpi';
import { CollateralUtils } from '@/utils';
import { MultiLoanApplicationFormModuleState } from '../../store/MultiLoanApplicationFormModule/state';

export class DataFetchHelper {
	constructor(private store: StoreType) {}

	public async getData(fetchDataType: FetchDataType, context: any) {
		switch (fetchDataType) {
			case FetchDataType.SbaInfo:
				return this.getSbaInfo(context);
			case FetchDataType.DataObject:
				return this.getDataObject(context);
			case FetchDataType.CurrentProposedLoanId:
				return this.getCurrentProposedLoanId();
			case FetchDataType.DataFields:
				return this.getDataFields(context);
			case FetchDataType.PresetDataFields:
				return this.getPresetDataFields(context);
			case FetchDataType.FeatureFlagActive:
				return this.getFeatureFlagActive(context);
			case FetchDataType.CurrentUserWritableEntities:
				return this.getCurrentUserWritableEntities(context);
			case FetchDataType.UseBorrowerAccessRestrictions:
				return this.getUseBorrowerAccessRestrictions();
			case FetchDataType.AllowBorrowerToSendInvites:
				return this.getAllowBorrowerToSendInvites();
			case FetchDataType.ApplicationEntities:
				return this.getApplicationEntities();
			case FetchDataType.Collateral:
				return this.getCollateral(context);
			default:
				return null;
		}
	}

	public async getSbaInfo({ loanId }: { loanId: number }) {
		return await SbaInfoApiHelper.getSbaInfo(loanId);
	}

	public async getDataObject({ type, id }: any): Promise<ObjectPropertyValues | undefined> {
		const dataFieldMetadata = await this.getDataFieldMetadata();

		if (dataFieldMetadata == null) {
			return undefined;
		}

		const dataObjects: DataObjectModel[] = await this.store.dispatch.MultiLoanApplicationForm.loadDataObjects({ type, ids: [id], dataFieldMetadata });

		if (dataObjects.length > 0) {
			let entityDataObject = dataObjects.find(dataObject => dataObject.rowId === id);
			return entityDataObject ? { id: entityDataObject.rowId, dataFieldValues: entityDataObject.values } : undefined;
		}

		return undefined;
	}

	public async getCurrentProposedLoanId() {
		return this.store.getters.MultiLoanApplicationForm.proposedLoanId;
	}

	public async getDataFields({ templateFieldIds }: { templateFieldIds: number[] }) {
		const dataFieldsService = AutoFactory.get(OnlinePortalDataFieldsService);

		return (await dataFieldsService.getPaged(1, templateFieldIds.length, undefined, undefined, false, templateFieldIds)).items ?? [];
	}

	public async getPresetDataFields({ dataObjectType }: { dataObjectType: DataObject.TypeEnum }) {
		return (this.store.state.LoanApplicationMetadata.presetDataFieldsByDataObject as PresetDataFieldsByDataObject)[dataObjectType] ?? [];
	}

	public async getFeatureFlagActive({ feature }: { feature: FeatureFlagging.FeatureFlagEnum }) {
		return await this.isFeatureFlagActive(feature);
	}

	public async getCurrentUserWritableEntities({ reloadFromServer }: { reloadFromServer: boolean }) {
		const isBankCustomerLoggedIn = !!this.store.state.User.user;

		if (!isBankCustomerLoggedIn) {
			return [];
		}

		if (reloadFromServer) {
			await this.store.dispatch.BorrowerAccessRestrictions.fetchCurrentUserBorrowerAccessMappings();
		}

		await this.store.dispatch.BorrowerAccessRestrictions.fetchCurrentUserWritableEntities();
		return this.store.state.BorrowerAccessRestrictions.currentUserWritableEntities;
	}

	public async getUseBorrowerAccessRestrictions() {
		const applicationType = await this.getApplicationType();

		await this.store.dispatch.InstitutionSettings.fetchUseBorrowerAccessRestrictions({ applicationType });
		return this.store.getters.InstitutionSettings.useBorrowerAccessRestrictions;
	}
	public async getAllowBorrowerToSendInvites() {
		const applicationType = await this.getApplicationType();
		await this.store.dispatch.InstitutionSettings.fetchAllowBorrowerToSendInvites({ applicationType });
		return this.store.getters.InstitutionSettings.allowBorrowerToSendInvites;
	}

	private async getApplicationType(): Promise<LoanApplication.TypeEnum | null> {
		return this.store.state.LoanApplicationData.loanApplication?.type ?? null;
	}

	public async getApplicationEntities() {
		return (
			(
				await AutoFactory.get(CustomersService).getCustomersRelatedToLoans(
					1,
					1000,
					this.store.state.LoanApplicationMetadata.metadata.map((loan: ApplicationTemplateMetadata) => loan.proposedLoanId ?? 0)
				)
			)?.items ?? []
		).map(customer => {
			return {
				label: customer.name,
				value: customer.id
			};
		});
	}

	private async getCollateral({ isLender }: { isLender: boolean }): Promise<Collateral[]> {
		await this.store.dispatch.MultiLoanApplicationForm.loadEntitiesOnLoan();
		const entityIds = this.store.state.MultiLoanApplicationForm.entityIdsOnLoan;
		const loanApplicationId = this.store.state.LoanApplicationData.loanApplication?.id;

		return await CollateralUtils.getCollateralForEntities(entityIds ?? [], loanApplicationId!, isLender);
	}

	private async isFeatureFlagActive(feature: FeatureFlagging.FeatureFlagEnum): Promise<boolean> {
		return await this.store.dispatch.FeatureFlag.fetchIsFeatureFlagActive(feature);
	}

	public async getDataFieldMetadata() {
		const multiLoanState = this.store.state.MultiLoanApplicationForm as MultiLoanApplicationFormModuleState;
		const loanIndex = multiLoanState.loanIndex;

		const loanMappingId = this.store.state.LoanApplicationMetadata.metadata[loanIndex].loanMappingId;
		if (loanMappingId == null) {
			return;
		}

		return this.store.state.LoanApplicationData.renderDataLookup[loanMappingId].dataFieldIdsByType;
	}
}
