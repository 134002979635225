import Rules from '../validation/rules';
import { initializeComponentsManager, useComponentManager } from '../components/component-manager';
import { enTranslations } from '../i18n';
import TemplateManager from '../templates/template-manager';
import builderTemplateHelpers from '../utils/formio-template-helpers';
import formDataHelpers from '../utils/form-data-helpers';
import ModifiedWizardBuilder from '../components/configuration-components/modified-wizard-builder/modified-wizard-builder';
import { FormioFormDisplayType } from '../formio-interfaces';
import ModifiedWizard from '../components/formio-display/modified-wizard';
import { NestedWizard } from '../components/formio-display/nested-wizard';
import { updateComponentClassWithOverrides } from '../utils/formio-component-utils';
import { componentList } from '../components/component-list';
import { formioComponentOverrides } from '../components/formio-component-overrides';

// @ts-ignore
let formioPlugin: unknown = null;
export function useFormioPlugin() {
	if (formioPlugin) {
		return formioPlugin;
	}
	initializeComponentsManager(componentList, formioComponentOverrides);
	const { componentManager } = useComponentManager();
	// This overrides certain methods on the Component class by manipulating the prototype of the class. This is only
	// 	done since we want this changes to affect all components within formio.

	updateComponentClassWithOverrides();

	formioPlugin = buildFormioPlugin({ ...componentManager.CustomComponents, ...componentManager.FormioComponentOverrides });

	return formioPlugin;
}
function buildFormioPlugin(components: any = {}) {
	return {
		options: {
			form: {
				i18n: enTranslations,
				evalContext: {
					builderTemplateHelpers,
					formDataHelpers
				},
				builderTemplateHelpers,
				formDataHelpers
			}
		},
		builders: {
			[FormioFormDisplayType.wizard]: ModifiedWizardBuilder,
			[FormioFormDisplayType.nestedWizard]: ModifiedWizardBuilder
		},
		components: {
			...components
		},
		displays: {
			[FormioFormDisplayType.wizard]: ModifiedWizard,
			[FormioFormDisplayType.nestedWizard]: NestedWizard
		},
		templates: {
			...TemplateManager.CustomTemplateFrameworks
		},
		framework: TemplateManager.defaultFramework,
		rules: {
			...Rules
		}
	};
}
