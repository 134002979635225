import { render, staticRenderFns } from "./save-to-doc-library-modal.vue?vue&type=template&id=c32882ae&"
import script from "./save-to-doc-library-modal.vue?vue&type=script&lang=ts&"
export * from "./save-to-doc-library-modal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports