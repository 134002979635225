import nestedComponentForm from 'formiojs/components/_classes/nested/NestedComponent.form';
import { CustomComponentType } from '../../../../../enums';
import { BaseEditDisplay } from '../../../../configuration-components/base-widget-configuration/base.edit.display';
import { SubaccountEditData } from './subaccount-edit-data';
import { omit } from 'lodash';

const getIgnoredTabs = function() {
	return [
		{
			key: 'logic',
			ignore: true
		},
		{
			key: 'api',
			ignore: true
		},
		{
			key: 'conditional',
			ignore: true
		},
		{
			key: 'layout',
			ignore: true
		}
	];
};

export const createSubaccountDefaultEditConfiguration = (selectOptionComponentType: CustomComponentType) => (...extend: any) => {
	const editDisplay = new BaseEditDisplay().getTab({ components: { showLenderOnly: true }, beforeForm: true });
	// Ensure we have the labelPosition field(s) available
	editDisplay.components = editDisplay.components.map(component => (['labelPosition'].includes(component.key) ? omit(component, 'ignore') : component));

	const form = nestedComponentForm(
		[editDisplay, new SubaccountEditData().getTab({ optionsComponentType: selectOptionComponentType }), ...getIgnoredTabs()],
		...extend
	);
	return form;
};
