import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ScaleMixin extends Vue {
	public scaledComponentSize(originalSize: string) {
		let componentSizes = ['sm', 'md', 'lg'];
		let originalSizeIndex = componentSizes.indexOf(originalSize);
		if (originalSizeIndex > 0 && this.scaleDownComponents) {
			return componentSizes[originalSizeIndex - 1];
		}
		return originalSize;
	}

	public scaledIconSize(originalSize: string) {
		let iconSizes = ['xs', 'sm', '1x', 'lg', '2x', '3x', '4x', '5x', '6x', '7x', '8x', '9x', '10x'];
		let originalSizeIndex = iconSizes.indexOf(originalSize);
		if (originalSizeIndex > 0 && this.scaleDownComponents) {
			return iconSizes[originalSizeIndex - 1];
		}
		return originalSize;
	}

	get scaleDownComponents() {
		return this.directStore.state.WindowInfo.shouldScaleDownComponents;
	}
}
