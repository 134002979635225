import { CashAccountTypeOption, FinancialSubaccountParent } from '../../enums';

export const calculateParent = (accountType: CashAccountTypeOption) => {
	switch (accountType) {
		case CashAccountTypeOption.Checking:
		case CashAccountTypeOption.Savings:
		case CashAccountTypeOption.MoneyMarket:
			return FinancialSubaccountParent.Cash;
		case CashAccountTypeOption.CD:
		case CashAccountTypeOption.Other:
			return FinancialSubaccountParent.OtherLiquidAssets;
		default:
			return null;
	}
};

const acceptableParentTypes = [FinancialSubaccountParent.Cash, FinancialSubaccountParent.OtherLiquidAssets];
export const isCashAccount = (parent: FinancialSubaccountParent, subaccountName: string) => {
	if (!acceptableParentTypes.includes(parent)) {
		return false;
	}

	const nameValues = subaccountName.split(' - ');
	const accountType = nameValues[1] as CashAccountTypeOption;

	if (!Object.values(CashAccountTypeOption).includes(accountType)) {
		return null;
	}

	return calculateParent(accountType) != null;
};