import { CustomComponentType } from '../../../enums/custom-component-type';
import { AutoFactory, ProposedProductsService, ProposedProduct, CollectionResponseProposedProduct } from '@sageworks/jpi';
import EditableApiSelectOptions from '../editable-api-select-options/editable-api-select-options';

export default class ProposedProductSelectOptions extends EditableApiSelectOptions {
	static schema(...extend: any) {
		return super.schema(
			{
				type: CustomComponentType.proposedProductSelectOptions
			},
			...extend
		);
	}

	static get builderInfo() {
		return {
			weight: 10,
			schema: ProposedProductSelectOptions.schema({})
		};
	}

	protected getApiOptions(): Promise<CollectionResponseProposedProduct> {
		let appType: ProposedProduct.ApplicationTypesEnum = this.options.editComponent.appType;
		const proposedProductsService: ProposedProductsService = AutoFactory.get(ProposedProductsService);
		return proposedProductsService.getPaged(1, 500, appType);
	}

	protected getOptionLabelForItem(item: ProposedProduct): string {
		return item.customerFacingName || item.name || '';
	}

	protected getOptionValueForItem(item: ProposedProduct): number {
		return item.id;
	}
}
