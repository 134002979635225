module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="col px-sm-6">\n	<div ref="optionsDropdown" class="dropdown-options border border-top-0 rounded" role="search">\n		<div ref="optionsList">\n			';
 for(var i=0; i < ctx.searchResults.length; i++) { ;
__p += '\n			<div \n				id="' +
((__t = ( ctx.searchResults[i].id )) == null ? '' : __t) +
'"\n				ref="optionItem" \n				class="option-item p-2"\n				tabindex="0"\n			>\n				' +
((__t = ( ctx.searchResults[i].name )) == null ? '' : __t) +
'\n			</div>\n			';
 } ;
__p += '\n		</div>\n	</div>\n</div>\n';
return __p
}