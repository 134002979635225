
import { TermsAndConditionsUtils } from '../../utils';
import Vue from 'vue';
import { LoanApplication } from '@sageworks/jpi';

export default Vue.extend({
	name: 'TermsAndConditions',
	data() {
		return {
			internalTermsAccepted: false,
			loading: false
		};
	},
	computed: {
		termsAndConditionsMessageHtml() {
			return this.directStore.getters.TermsAndConditions.termsAndConditionsMessageHtml;
		},
		loanApplication(): LoanApplication | null {
			return this.directStore.state.LoanApplicationData.loanApplication;
		},
		termsAcceptedText() {
			return TermsAndConditionsUtils.buildAuthorizationMessage(this.loanApplication?.agreedToTermsUsername, this.loanApplication?.agreedtoTermsDate);
		},
		loanTermsAccepted(): boolean {
			return !!this.loanApplication?.agreedToTerms;
		},
		isLenderView() {
			return this.directStore.getters.User.isLender;
		},
		acceptedOrLender() {
			return this.loanTermsAccepted || this.isLenderView;
		}
	},
	mounted() {
		this.internalTermsAccepted = this.loanTermsAccepted;
	},
	methods: {
		openTermsModal() {
			this.$root.$bvModal.show('accept-terms');
		},
		async agreeToTerms() {
			this.loading = true;

			// Accept the terms on the loan application
			const savedLoanApplication = await this.directStore.dispatch.TermsAndConditions.acceptTermsAndConditions({
				loanApplication: this.loanApplication,
				username: this.directStore.getters.User.email
			});

			// Update the store with the updated loan application
			this.directStore.commit.LoanApplicationData.SET_LOAN_APPLICATION({ loanApplication: savedLoanApplication });

			this.loading = false;
			this.closeModal();
		},
		declineTerms() {
			this.closeModal();
		},
		closeModal() {
			this.internalTermsAccepted = this.loanTermsAccepted;
			this.$root.$bvModal.hide('accept-terms');
		}
	}
});
