
import Vue, { PropType } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { DynamicApplicationGMIFormLink } from '@sageworks/jpi';
import { DateUtils } from '../../utils';

export default Vue.extend({
	name: 'HmdaGmiForm',
	components: {
		FontAwesomeIcon
	},
	props: {
		formInfo: {
			type: Object as PropType<DynamicApplicationGMIFormLink>
		}
	},
	data() {
		return {};
	},
	computed: {
		showWaiveButton(): boolean {
			return this.directStore.getters.User.isLender && this.$props.formInfo.allowWaive;
		},
		isGMIRequestCompleted(): boolean {
			return (
				[DynamicApplicationGMIFormLink.HmdaCollectionStatusEnum.Complete, DynamicApplicationGMIFormLink.HmdaCollectionStatusEnum.Waived].includes(
					this.$props.formInfo.hmdaCollectionStatus
				) || this.submittedDate != null
			);
		},
		isGMIRequestWaived(): boolean {
			return this.$props.formInfo.hmdaCollectionStatus === DynamicApplicationGMIFormLink.HmdaCollectionStatusEnum.Waived;
		},
		formStatus(): string {
			if (this.submittedDate != null) {
				return `Submitted on ${this.submittedDateText}`;
			}

			return `Status: ${this.formStatusFromCollectionStatus}`;
		},
		formStatusFromCollectionStatus() {
			switch (this.formInfo.hmdaCollectionStatus) {
				case DynamicApplicationGMIFormLink.HmdaCollectionStatusEnum.NotSent:
				case DynamicApplicationGMIFormLink.HmdaCollectionStatusEnum.Sent:
					return 'New';
				case DynamicApplicationGMIFormLink.HmdaCollectionStatusEnum.NeedsReview:
					return 'In Progress';
				case DynamicApplicationGMIFormLink.HmdaCollectionStatusEnum.Complete:
					return 'Complete';
				case DynamicApplicationGMIFormLink.HmdaCollectionStatusEnum.Waived:
					return 'Waived';
			}
			return 'Unknown';
		},
		waiveText(): string {
			return this.isGMIRequestWaived ? 'Re-open Request' : 'Waive';
		},
		hmdaFillButtonText(): string {
			switch (this.formInfo.hmdaCollectionStatus) {
				case DynamicApplicationGMIFormLink.HmdaCollectionStatusEnum.NeedsReview:
				case DynamicApplicationGMIFormLink.HmdaCollectionStatusEnum.Complete:
					return 'View Form';
				case DynamicApplicationGMIFormLink.HmdaCollectionStatusEnum.NotSent:
				case DynamicApplicationGMIFormLink.HmdaCollectionStatusEnum.Sent:
				case DynamicApplicationGMIFormLink.HmdaCollectionStatusEnum.Waived:
				default:
					return 'Fill Out Form';
			}
		},
		submittedDate(): string {
			return this.$props.formInfo.submittedDateTime;
		},
		submittedDateText(): string {
			if (!this.submittedDate) {
				return '';
			}
			return DateUtils.easternTimestampToMonthDateYear(this.submittedDate);
		}
	},
	methods: {
		onFillOutFormClick(): void {
			this.$emit('form-link-opened', this.formInfo);
			window.open(this.$props.formInfo.formLink, '_blank', 'noreferrer');
		},
		onClickToggleWaive(): void {
			this.directStore.dispatch.HmdaGmi.toggleWaiveForm(this.$props.formInfo);
		}
	}
});
