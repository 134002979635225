module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }

 if (ctx.id === ctx.instance.root.id) { ;
__p += '\n<div class="dynamic-forms-wizard">\n	';
 if (!ctx.options || !ctx.options.contextData) { ;
__p += '\n	<div class="dynamic-forms-wizard-nav-container">\n		' +
((__t = ( ctx.wizardHeader )) == null ? '' : __t) +
'\n	</div>\n	';
 } ;
__p += '\n	<div class="dynamic-forms-wizard-page-container">\n';
 } ;
__p += '\n	<div class="position-relative">\n		<div class="wizard-page" ref="' +
((__t = ( ctx.wizardKey )) == null ? '' : __t) +
'">\n			' +
((__t = ( ctx.components )) == null ? '' : __t) +
'\n		</div>\n		' +
((__t = ( ctx.wizardNav )) == null ? '' : __t) +
'\n	</div>\n\n';
 if (ctx.id === ctx.instance.root.id) { ;
__p += '\n	</div>\n</div>\n';
 } ;
__p += '\n';
return __p
}