import { CustomComponentType } from '../../../../enums';
import Component from 'formiojs/components/_classes/component/Component';
import { IPopupBodyComponent } from '../../popup';
import { EncodingHelper } from '@sageworks/core-logic';

export default class ApplicantBusinessConfirmChangePopup extends Component implements IPopupBodyComponent {
	public preClose(): Promise<void> {
		return Promise.resolve();
	}

	public static schema(...extend: any) {
		return Component.schema(
			{
				type: CustomComponentType.applicantBusinessConfirmChangePopup,
				key: CustomComponentType.applicantBusinessConfirmChangePopup,
				input: false,
				oldBusinessName: '',
				newBusinessName: ''
			},
			...extend
		);
	}

	public readonly encodeHtml = EncodingHelper.encodeHtml;
	public resultPromise!: Promise<void>;
	public isLoading = false;

	private resultPromiseResolve!: (result: any) => void;

	private get template() {
		return {
			body: `
				<div class="mb-3">
					Are you sure you would like to change the applicant business 
					from {{ instance.encodeHtml(ctx.oldBusinessName) }} to {{ instance.encodeHtml(ctx.newBusinessName) }}?
				</div>
				<div>Changing the applicant business will change the SBA forms in the Documentation section.</div>
			`,
			footer: `
				<button class="btn btn-primary" ref="appBusinessChangeContinueButton">Continue</button>
				<button class="btn btn-primary" ref="appBusinessChangeCancelButton">Cancel</button>
			`
		};
	}

	public init() {
		this.resultPromise = new Promise(resolve => (this.resultPromiseResolve = resolve));
	}

	public render(): any {
		const body = this.renderString(this.template.body, {
			oldBusinessName: this.component.oldBusinessName,
			newBusinessName: this.component.newBusinessName
		});
		const footer = this.renderString(this.template.footer, {});

		return super.render(
			this.renderTemplate('styledDialog', {
				body,
				footer
			})
		);
	}

	public async attach(element: any) {
		const attachResult = await super.attach(element);

		this.loadRefs(element, {
			appBusinessChangeContinueButton: 'single',
			appBusinessChangeCancelButton: 'single'
		});

		const { appBusinessChangeContinueButton: continueButton, appBusinessChangeCancelButton: cancelButton } = this.refs;

		this.removeEventListener(continueButton, 'click');
		this.addEventListener(continueButton as any, 'click', this.onContinueClick.bind(this));

		this.removeEventListener(cancelButton, 'click');
		this.addEventListener(cancelButton as any, 'click', this.onCancelClick.bind(this));

		return attachResult;
	}

	private onContinueClick() {
		this.resultPromiseResolve(true);
	}

	private onCancelClick() {
		this.resultPromiseResolve(false);
	}
}
