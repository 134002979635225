
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ApplicationDocumentRequest } from '@sageworks/jpi/';
import { OnlinePortalDocumentRequest, OnlinePortalApplicationDocumentRequest, IDocumentRequest } from '@/models';
import ConfirmReasonModal from '../../documents/confirm-reason-modal/confirm-reason-modal.vue';
import { DateFormatting } from '@sageworks/core-logic';

@Component({
	components: { ConfirmReasonModal }
})
export default class DocumentDetailsLenderFeatures extends Vue {
	@Prop()
	public isApplicationDocumentRequest!: boolean;
	@Prop()
	public isOpenRequest!: boolean;
	@Prop()
	public documentRequest!: IDocumentRequest;
	@Prop()
	public customerFacingName!: string;
	@Prop()
	public isAdditionalDocument!: boolean;

	public waiveInProgress: boolean = false;
	public reopenInProgress: boolean = false;

	get disableFileInput(): boolean {
		return (
			this.isApplicationDocumentRequest &&
			(!!this.documentRequest.dateUploaded || this.reopenInProgress || this.waiveInProgress || !!this.documentRequest.dateWaived)
		);
	}

	get documentHasBeenUploaded(): boolean {
		return !!this.documentRequest.documentId && this.documentRequest.documentId > 0;
	}

	get waiveModalId() {
		return `waiveModal${this.documentRequest.id}`;
	}

	get statusMessageForDocumentRequest(): string {
		let docStatusMessage = 'Status: New';

		if (this.documentRequest.dateUploaded) {
			docStatusMessage = `Uploaded ${this.documentRequest.dateUploaded}`;
		} else if (this.documentRequest.dateWaived && (this.documentRequest as OnlinePortalApplicationDocumentRequest).waivedReason === 'Document on file') {
			docStatusMessage = `Marked on file ${this.documentRequest.dateWaived}`;
		} else if (this.documentRequest.dateWaived) {
			docStatusMessage = `Waived ${this.documentRequest.dateWaived} for reason: ${
				(this.documentRequest as OnlinePortalApplicationDocumentRequest).waivedReason
			}`;
		}

		return docStatusMessage;
	}

	public async unWaiveDocumentRequest(): Promise<void> {
		try {
			if (this.isApplicationDocumentRequest) {
				(this.documentRequest as ApplicationDocumentRequest).waivedByLoanOfficerId = this.directStore.state.User.proxyUser?.id;
				(this.documentRequest as ApplicationDocumentRequest).dateWaived = undefined;
				(this.documentRequest as ApplicationDocumentRequest).waivedReason = undefined;
				await this.directStore.dispatch.ApplicationDocumentRequest.updateApplicationDocumentRequest(
					this.documentRequest as OnlinePortalApplicationDocumentRequest
				);
			} else {
				(this.documentRequest as OnlinePortalDocumentRequest).request.waivedByLoanOfficerId = this.directStore.state.User.proxyUser?.id;
				(this.documentRequest as OnlinePortalDocumentRequest).request.dateWaived = undefined;
				(this.documentRequest as OnlinePortalDocumentRequest).request.waivedReason = undefined;
				await this.directStore.dispatch.DocumentRequest.updateDocumentRequest(this.documentRequest as OnlinePortalDocumentRequest);
				await this.directStore.dispatch.DocumentRequest.loadPagedDocumentRequests({ page: 1, perPage: 20, refreshCache: true });
			}
			return Promise.resolve();
		} catch (err) {
			return Promise.reject(err);
		}
	}

	public async waiveDocumentRequest({ reason }: any): Promise<void> {
		try {
			this.waiveInProgress = true;
			let waiveDate = DateFormatting.formatDateForJpi(new Date());
			if (this.isApplicationDocumentRequest) {
				(this.documentRequest as ApplicationDocumentRequest).waivedByLoanOfficerId = this.directStore.state.User.proxyUser?.id;
				(this.documentRequest as ApplicationDocumentRequest).dateWaived = waiveDate;
				(this.documentRequest as ApplicationDocumentRequest).waivedReason = reason;
				await this.directStore.dispatch.ApplicationDocumentRequest.updateApplicationDocumentRequest(
					this.documentRequest as OnlinePortalApplicationDocumentRequest
				);
			} else {
				(this.documentRequest as OnlinePortalDocumentRequest).request.waivedByLoanOfficerId = this.directStore.state.User.proxyUser?.id;
				(this.documentRequest as OnlinePortalDocumentRequest).request.dateWaived = waiveDate;
				(this.documentRequest as OnlinePortalDocumentRequest).request.waivedReason = reason;
				await this.directStore.dispatch.DocumentRequest.updateDocumentRequest(this.documentRequest as OnlinePortalDocumentRequest);
				await this.directStore.dispatch.DocumentRequest.loadPagedDocumentRequests({ page: 1, perPage: 20, refreshCache: true });
			}
			this.waiveInProgress = false;
			return Promise.resolve();
		} catch (err) {
			this.waiveInProgress = false;
			return Promise.reject(err);
		}
	}

	public async reopenDocumentRequest(): Promise<void> {
		try {
			this.reopenInProgress = true;
			if (this.isAdditionalDocument) {
				await this.directStore.dispatch.ApplicationDocumentRequest.deleteApplicationDocumentRequest(
					this.documentRequest as OnlinePortalApplicationDocumentRequest
				);
			} else if (this.isApplicationDocumentRequest) {
				await this.directStore.dispatch.ApplicationDocumentRequest.reOpenApplicationDocumentRequest(
					this.documentRequest as OnlinePortalApplicationDocumentRequest
				);
			} else {
				await this.directStore.dispatch.DocumentRequest.reopenDocumentRequest(this.documentRequest as OnlinePortalDocumentRequest);
			}
			this.reopenInProgress = false;
			this.$bvModal.hide('reopenModal' + this.documentRequest.id);

			return Promise.resolve();
		} catch (err) {
			this.reopenInProgress = false;
			return Promise.reject(err);
		}
	}
}
