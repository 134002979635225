import Component from 'formiojs/components/_classes/component/Component';
import Formio from 'formiojs/Formio';
import { FormioFormSchema } from '../../../formio-interfaces/FormioFormSchema';
import { CustomComponentType } from '../../../enums/custom-component-type';
import SelectComponent from 'formiojs/components/select/Select';
import { handleFormCopy, buildNestedComponents } from './copy-form.helper';
import 'regenerator-runtime/runtime.js';
import { CopyFormUtils } from '../../../utils';
import { OpnPlatformType } from '@sageworks/core-logic/src/enums';

export default class CopyFormComponent extends SelectComponent {
	static schema(...extend: any) {
		return super.schema(
			{
				type: CustomComponentType.copyForm,
				key: CustomComponentType.copyForm,
				componentType: undefined
			},
			...extend
		);
	}

	init() {
		super.init();
	}

	// @ts-ignore
	get labelInfo() {
		return super.labelInfo;
	}

	originalDataValue: string = '';

	render() {
		const info = (this as any).inputInfo;
		info.attr = info.attr || {};
		info.multiple = (this.component as any).multiple;
		return Component.prototype.render.apply(this, [
			(this as any).wrapElement(
				this.renderTemplate('copyForm', {
					input: info,
					selectOptions: '',
					index: null
				})
			)
		]);
	}

	attach(element: HTMLElement) {
		if (element.id != null && document.getElementById(element.id) != null) {
			element = document.getElementById(element.id)!;
		}

		this.originalDataValue = this.getValue();
		const component = this;
		super.attach(element);
		component.loadRefs(element, {
			copyForm: 'single',
			copySpinner: 'single'
		});

		if ((component.refs as any).copyForm) {
			(component.refs as any).copyForm.removeEventListener('click', this.copyForm(component));
			component.addEventListener((component.refs as any).copyForm, 'click', this.copyForm(component));
		}

		if ((component.refs as any).selectContainer) {
			(component.refs as any).selectContainer.removeEventListener('change', this.handleChange(component));
			component.addEventListener((component.refs as any).selectContainer, 'change', this.handleChange(component));
		}
	}

	// eslint-disable-next-line max-lines-per-function
	copyForm(component: CopyFormComponent) {
		// eslint-disable-next-line max-lines-per-function
		return async () => {
			this.removeClass(this.refs.copySpinner, 'd-none');

			const formSelect = (component.refs.selectContainer as unknown) as HTMLElement;
			const formId = (formSelect as any).value as string;
			const form = (component as any).cachedForms.filter((formOption: any) => formOption._id === formId)[0];

			const platformType = component.options.platformType ?? OpnPlatformType.LOS;
			const inSandbox = component.options.inSandbox;
			const projectUrl = form.isBestPractice
				?  CopyFormUtils.getBestPracticeProjectUrl(platformType, inSandbox)
				: Formio.getProjectUrl();
			const selectedSchema = await Formio.makeRequest(undefined, 'form', `${projectUrl}/form/${formId}`, 'get', null, null);

			const rootInfo = {
				path: component.options.editForm.path,
				title: component.options.editForm.title,
				name: component.options.editForm.name
			};
			const componentInfo = {
				label: component.data.label,
				type: component.options.editComponent.type
			};

			const templateDataFieldMapping = component.options.templateDataFieldMapping;
			const copiedForm = await handleFormCopy(selectedSchema, rootInfo, componentInfo, templateDataFieldMapping, form.isBestPractice);
			component.root.data.formPath = copiedForm.path;
			buildNestedComponents(copiedForm, form.isBestPractice, platformType, inSandbox, templateDataFieldMapping).then(() => {
				this.updateToNewForm(component, copiedForm);
			});
		};
	}

	updateToNewForm(component: any, form: FormioFormSchema) {
		component.addOption(component.itemValue(form), component.itemTemplate(form), {}, component.selectOptions.length);
		component.setValue(form._id);
		component.originalDataValue = component.dataValue;
		component.handleChange(component)();
	}

	handleChange(component: CopyFormComponent): () => any {
		return () => {
			if (component.dataValue === 'null' || component.dataValue === '' || component.dataValue === component.originalDataValue) {
				(component.refs as any).copyForm.setAttribute('disabled', 'disabled');
				this.addClass(this.refs.copySpinner, 'd-none');
			} else {
				(component.refs as any).copyForm.removeAttribute('disabled');
			}
		};
	}
}
