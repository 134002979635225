import { CustomComponentType } from '../../../enums/custom-component-type';
import DataGridComponent from 'formiojs/components/datagrid/DataGrid';

export default class EditableApiSelectOptions extends DataGridComponent {
	static schema(...extend: any) {
		return DataGridComponent.schema(
			{
				type: CustomComponentType.editableApiSelectOptions,
				key: 'data.values',
				reorder: false,
				clearOnHide: false
			},
			...extend
		);
	}

	static get builderInfo() {
		return {
			weight: 10,
			schema: EditableApiSelectOptions.schema({})
		};
	}

	public static get defaultComponents() {
		return [
			EditableApiSelectOptions.getEnabledComponent(false),
			EditableApiSelectOptions.getLabelComponent(false),
			EditableApiSelectOptions.getValueComponent(true)
		];
	}

	static get defaultComponentsHiddenValue() {
		return [
			EditableApiSelectOptions.getEnabledComponent(false),
			EditableApiSelectOptions.getLabelComponent(false),
			{
				...EditableApiSelectOptions.getValueComponent(true),
				hidden: true
			}
		];
	}

	static get nonEditableComponents() {
		return [
			EditableApiSelectOptions.getEnabledComponent(false),
			EditableApiSelectOptions.getLabelComponent(true),
			EditableApiSelectOptions.getValueComponent(true)
		];
	}

	protected static getEnabledComponent(disabled: boolean) {
		return {
			label: 'Enabled',
			key: 'enabledForConsumers',
			input: true,
			type: 'checkbox',
			dataGridLabel: false,
			tableView: false,
			disabled: disabled
		};
	}

	// eslint-disable-next-line max-lines-per-function
	protected static getLabelComponent(disabled: boolean) {
		const baseLabelSchema = {
			label: 'Label',
			key: 'label',
			input: true,
			type: 'textfield',
			disabled: disabled
		};
		if (disabled) {
			return baseLabelSchema;
		}

		// when the label is not disabled, it should have logic to make it dynamically editable
		return {
			...baseLabelSchema,
			logic: [
				{
					name: 'disableLogic',
					trigger: {
						type: 'simple',
						simple: {
							show: true,
							when: 'enabledForConsumers',
							eq: false
						}
					},
					actions: [
						{
							name: 'disableAction',
							type: 'property',
							property: {
								label: 'Disabled',
								value: 'disabled',
								type: 'boolean'
							},
							state: true
						}
					]
				}
			]
		};
	}

	protected static getValueComponent(disabled: boolean) {
		return {
			label: 'Value',
			key: 'value',
			input: true,
			type: 'textfield',
			disabled: disabled
		};
	}

	optionsLoaded: boolean = false;
	optionsPromise: Promise<any> | null = null;

	attach(element: any) {
		let selectAttachedPromise = super.attach(element).then(() => {
			const messageContainerRef = 'messageContainer';
			const messageContainers = element.querySelectorAll(`[ref="${messageContainerRef}"]`);
			if (messageContainers.length > 0) {
				(this.refs as any)[messageContainerRef] = messageContainers[messageContainers.length - 1];
			}
		});

		if (!this.optionsLoaded && this.optionsPromise == null) {
			const dataReadyPromise: Promise<any> = this.root != null ? this.root.submissionReady : Promise.resolve();

			// Once the select is attached to the form and the data is prepared, process the options
			this.optionsPromise = Promise.all([selectAttachedPromise, dataReadyPromise])
				.then(() => {
					return this.getApiOptions();
				})
				.then((itemResponse: any) => {
					let options = this.getReconciledOptionsFromResponse(itemResponse);
					this.setValue(options, {});
					this.optionsLoaded = true;
					this.optionsPromise = null;
					// throw event to know when component is done attaching
					this.emit('attached', this);
				});
		}

		return selectAttachedPromise;
	}

	protected getApiOptions(): Promise<any> {
		// Override this method to load actual values for a use case
		return Promise.resolve([]);
	}

	protected getOptionLabelForItem(item: any): string {
		return item ? item + '' : '';
	}

	protected getOptionValueForItem(item: any): any {
		return item;
	}

	protected createOptionFromItem(item: any, enabledForConsumers: boolean = true): any {
		return {
			enabledForConsumers: enabledForConsumers,
			label: this.getOptionLabelForItem(item),
			value: this.getOptionValueForItem(item)
		};
	}

	private getReconciledOptionsFromResponse(itemResponse: any): Array<any> {
		let existingOptions: Array<any> = this.dataValue || [];

		let newItems: Array<any> = [];
		if (Array.isArray(itemResponse)) {
			newItems = itemResponse;
		} else if (itemResponse && itemResponse.items && Array.isArray(itemResponse.items)) {
			newItems = itemResponse.items;
		}

		return this.mergeExistingOptionsWithNewItems(existingOptions, newItems);
	}

	private isOptionEquivalentToItemForReconciliation(option: any, item: any): boolean {
		if (option == null || option.value == null) {
			return false;
		}
		return option.value == this.getOptionValueForItem(item); // eslint-disable-line eqeqeq
	}

	private isOptionListEmpty(options: Array<any>): boolean {
		if (!options || options.length === 0) {
			// an actually empty list
			return true;
		}
		if (options.length === 1 && !options[0].value) {
			// something which looks like the single default placeholder option
			return true;
		}
		return false;
	}

	private mergeExistingOptionsWithNewItems(existingOptions: Array<any>, newItems: Array<any>): Array<any> {
		// we duplicate the existing options to avoid modifying the original collection
		const existingOptionCandidates = existingOptions.concat([]);
		const isNewlyCreatedWidget = this.isOptionListEmpty(existingOptions);
		const mergedOptions: Array<any> = [];

		for (let i = 0; i < newItems.length; i++) {
			const newItem = newItems[i];

			// grab and re-use an equivalent existing option, if we can find one
			let optionToAdd = null;
			for (let j = 0; j < existingOptionCandidates.length && optionToAdd === null; j++) {
				const existingOption = existingOptionCandidates[j];
				if (existingOption != null && this.isOptionEquivalentToItemForReconciliation(existingOption, newItem)) {
					optionToAdd = existingOption;
					// each option should only be used once, so we'll remove it from the candidate list
					existingOptionCandidates.splice(j, 1);
				}
			}

			// no equivalent existing option found, we'll make a new one from our item
			if (optionToAdd === null) {
				optionToAdd = this.createOptionFromItem(newItem, isNewlyCreatedWidget);
			}

			mergedOptions.push(optionToAdd);
		}

		return mergedOptions;
	}
}
