import { DataObjectsByType } from '../models';
import { DataObject as DataObjectModel } from '@sageworks/jpi';
import { DataObject } from '../enums';

export class DataObjectsByTypeFactory {
	public static createDataObjectByType(): DataObjectsByType {
		return {
			[DataObject.TypeEnum.Business]: [],
			[DataObject.TypeEnum.Person]: [],
			[DataObject.TypeEnum.Farm]: [],
			[DataObject.TypeEnum.NonProfit]: [],
			[DataObject.TypeEnum.Contact]: [],
			[DataObject.TypeEnum.PortfolioLoan]: [],
			[DataObject.TypeEnum.ProposedLoan]: [],
			[DataObject.TypeEnum.Collateral]: [],
			[DataObject.TypeEnum.BusinessFinancial]: [],
			[DataObject.TypeEnum.PersonalFinancial]: [],
			[DataObject.TypeEnum.FinancialSubaccounts]: [],
			[DataObject.TypeEnum.BeneficialOwnerDetails]: [],
			[DataObject.TypeEnum.RealEstateFinancials]: [],
			[DataObject.TypeEnum.BusinessInvestments]: []
		};
	}

	public static createBlankFormDataObjectDict(): { [key: string]: Array<DataObjectModel> } {
		return {
			[DataObject.TypeEnum.Business]: [],
			[DataObject.TypeEnum.Person]: [],
			[DataObject.TypeEnum.Farm]: [],
			[DataObject.TypeEnum.NonProfit]: [],
			[DataObject.TypeEnum.Contact]: [],
			[DataObject.TypeEnum.PortfolioLoan]: [],
			[DataObject.TypeEnum.ProposedLoan]: [],
			[DataObject.TypeEnum.Collateral]: [],
			[DataObject.TypeEnum.BusinessFinancial]: [],
			[DataObject.TypeEnum.PersonalFinancial]: [],
			[DataObject.TypeEnum.FinancialSubaccounts]: [],
			[DataObject.TypeEnum.BeneficialOwnerDetails]: [],
			[DataObject.TypeEnum.RealEstateFinancials]: []
		};
	}
}
