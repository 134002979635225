export enum CustomComponentType {
	loans = 'loans',
	collateralInfo = 'collateral-info',
	personalInfo = 'personal-info',
	businessInfo = 'business-info',
	farmInfo = 'farm-info',
	copyForm = 'copyForm',
	locationVerification = 'location-verification',
	productSelection = 'product-selection',
	editableApiSelectOptions = 'editable-api-select-options',
	appTypeSelect = 'app-type-select',
	appTypeSelectOptions = 'app-type-select-options',
	proposedProductSelect = 'proposed-product-select',
	proposedProductSelectOptions = 'proposed-product-select-options',
	primaryBorrowerEntity = 'primary-borrower-entity',
	primaryBorrowerEntityVue = 'primary-borrower-entity-vue',
	personalFinancialStatement = 'personal-financial-statement',
	coBorrowerEntity = 'co-borrower-entity',
	coBorrowerEntityVue = 'co-borrower-entity-vue',
	coSignerEntity = 'co-signer-entity',
	coSignerEntityVue = 'co-signer-entity-vue',
	guarantorEntity = 'guarantor-entity',
	guarantorEntityVue = 'guarantor-entity-vue',
	creditApplicantEntity = 'credit-applicant-entity',
	creditApplicantEntityVue = 'credit-applicant-entity-vue',
	additionalEntity = 'additional-entity',
	nonprofitInfo = 'nonprofit-info',
	creditReport = 'credit-report',
	authorizedSigner = 'authorized-signer',
	trustee = 'trustee',
	relatedContacts = 'related-contacts',
	sbaForm1919 = 'sba-form-1919',
	sbaForm1920 = 'sba-form-1920',
	beneficialOwnership = 'beneficial-ownership',
	extendedTextField = 'extended-text-field',
	extendedSelectField = 'extended-select-field',
	extendedNumber = 'extended-number',
	extendedPhoneNumber = 'extended-phone-number',
	extendedCurrency = 'extended-currency',
	extendedRadio = 'extended-radio',
	extendedTextArea = 'extended-text-area',
	extendedDateTime = 'extended-date-time',
	extendedPercent = 'extended-percent',
	creditReportAuthorizationInput = 'credit-report-authorization-input',
	creditReportPullCreditInput = 'credit-report-pull-credit-input',
	customerTypeSelect = 'customer-type-select',
	creditReportCredentialsInput = 'credit-report-credentials-input',
	creditReportPullCreditPopup = 'credit-report-pull-credit-popup',
	applicantBusinessQuestion = 'applicant-business-question',
	addCustomField = 'add-custom-field',
	applicantBusinessConfirmChangePopup = 'applicant-business-confirm-change-popup',
	applicantBusinessConfirmRemovePopup = 'applicant-business-confirm-remove-popup',
	addCustomerPopup = 'add-customer-popup',
	collateralTypeSelect = 'collateral-type-select',
	collateralTypeSelectOptions = 'collateral-type-select-options',
	addNewCustomer = 'add-new-customer',
	income = 'income',
	primaryRoleAddRowPopup = 'primary-role-add-row-popup',
	loadingPopup = 'loading-popup',
	financialSubaccount = 'financial-subaccount',
	personalIncomeTypeSelect = 'personal-income-type-select',
	personalIncomeTypeSelectOptions = 'personal-income-type-select-options',
	borrowerAccessPopup = 'borrower-access-popup',
	vehicleStateSelect = 'vehicle-state-select',
	vehicleStateSelectOptions = 'vehicle-state-select-options',
	expense = 'expense',
	personalExpenseTypeSelect = 'personal-expense-type-select',
	personalExpenseTypeSelectOptions = 'personal-expense-type-select-options',
	collateralAddRowPopup = 'collateral-add-row-popup',
	otherLiabilities = 'other-liabilities',
	personalLiabilityTypeSelect = 'personal-liability-type-select',
	personalLiabilityTypeSelectOptions = 'personal-liability-type-select-options',
	cashAccount = 'cash-account',
	personalCashAccountTypeSelect = 'personal-cash-account-type-select',
	personalCashAccountTypeSelectOptions = 'personal-cash-account-type-select-options',
	stocksBondsBusinessHoldings = 'stocks-bonds-business-holdings',
	personalInvestmentTypeSelect = 'personal-investment-type-select',
	personalInvestmentTypeSelectOptions = 'personal-investment-type-select-options',
	nonInvestmentRealEstate = 'non-investment-real-estate',
	personalPropertyTypeSelect = 'personal-property-type-select',
	personalPropertyTypeSelectOptions = 'personal-property-type-select-options',
	investmentRealEstate = 'investment-real-estate',
	businessInvestment = 'business-investment',
	addNewBusinessInvestment = 'add-new-business-investment',
	addBusinessInvestmentPopup = 'add-business-investment-popup',
	lifeInsurance = 'life-insurance',
	personalInsuranceTypeSelect = 'personal-insurance-type-select',
	personalInsuranceTypeSelectOptions = 'personal-insurance-type-select-options',
	retirementAccount = 'retirement-account',
	personalRetirementAccountTypeSelect = 'personal-retirement-account-type-select',
	personalRetirementAccountTypeSelectOptions = 'personal-retirement-account-type-select-options',
	baseRepeaterWidget = 'base-repeater-widget',
	otherAssets = 'other-assets',
	personalOtherAssetsTypeSelect = 'personal-other-assets-type-select',
	personalOtherAssetsTypeSelectOptions = 'personal-other-assets-type-select-options',
	hmdaLoanPurposeSelect = 'hmda-loan-purpose-select',
	hmdaLoanPurposeSelectOptions = 'hmda-loan-purpose-select-options',

	// Temporary until widget is released
	beneficialOwnershipVue = 'beneficial-ownership-vue',

	plaid = 'plaid-component'
}

export type CustomerComponentType =
	| CustomComponentType.personalInfo
	| CustomComponentType.businessInfo
	| CustomComponentType.nonprofitInfo
	| CustomComponentType.farmInfo;

export const RelatedRoleTypes = [CustomComponentType.beneficialOwnership, CustomComponentType.beneficialOwnershipVue, CustomComponentType.authorizedSigner];

export const CustomerCustomComponentTypes = [
	CustomComponentType.businessInfo,
	CustomComponentType.personalInfo,
	CustomComponentType.farmInfo,
	CustomComponentType.nonprofitInfo
];

export const LoanRoleCustomComponentTypes = [
	CustomComponentType.primaryBorrowerEntity,
	CustomComponentType.primaryBorrowerEntityVue,

	CustomComponentType.coBorrowerEntity,
	CustomComponentType.coBorrowerEntityVue,

	CustomComponentType.coSignerEntity,
	CustomComponentType.guarantorEntity,
	CustomComponentType.guarantorEntityVue,
	CustomComponentType.creditApplicantEntity,
	CustomComponentType.creditApplicantEntityVue
];

export const FinancialSubaccountCustomComponentTypes = [
	CustomComponentType.income,
	CustomComponentType.expense,
	CustomComponentType.otherLiabilities,
	CustomComponentType.stocksBondsBusinessHoldings,
	CustomComponentType.investmentRealEstate,
	CustomComponentType.nonInvestmentRealEstate,
	CustomComponentType.cashAccount,
	CustomComponentType.lifeInsurance,
	CustomComponentType.businessInvestment,
	CustomComponentType.retirementAccount,
	CustomComponentType.otherAssets
];

export const TopLevelComponentTypes = [CustomComponentType.loans, CustomComponentType.collateralInfo, ...LoanRoleCustomComponentTypes];

// eslint-disable-next-line max-lines-per-function
export function CustomComponentLabel(): { [key in CustomComponentType]: string } {
	return {
		[CustomComponentType.loans]: 'Loans',
		[CustomComponentType.collateralInfo]: 'Collateral Info',
		[CustomComponentType.personalInfo]: 'Personal Info',
		[CustomComponentType.businessInfo]: 'Business Info',
		[CustomComponentType.farmInfo]: 'Farm Info',
		[CustomComponentType.copyForm]: 'Copy Form',
		[CustomComponentType.locationVerification]: 'Location Verification',
		[CustomComponentType.productSelection]: 'Product Selection',
		[CustomComponentType.editableApiSelectOptions]: 'Editable Api Select Options',
		[CustomComponentType.appTypeSelect]: 'App Type Select',
		[CustomComponentType.appTypeSelectOptions]: 'App Type Select Options',
		[CustomComponentType.proposedProductSelect]: 'Proposed Product Select',
		[CustomComponentType.proposedProductSelectOptions]: 'Proposed Product Select Options',
		[CustomComponentType.primaryBorrowerEntity]: 'Primary Borrower',
		[CustomComponentType.primaryBorrowerEntityVue]: 'Primary Borrower',
		[CustomComponentType.personalFinancialStatement]: 'Personal Financial Statement',
		[CustomComponentType.coBorrowerEntity]: 'Co-Borrower',
		[CustomComponentType.coBorrowerEntityVue]: 'Co-Borrower',
		[CustomComponentType.coSignerEntity]: 'Co-Signer',
		[CustomComponentType.coSignerEntityVue]: 'Co-Signer',
		[CustomComponentType.guarantorEntity]: 'Guarantor',
		[CustomComponentType.guarantorEntityVue]: 'Guarantor',
		[CustomComponentType.creditApplicantEntity]: 'Credit Applicant',
		[CustomComponentType.creditApplicantEntityVue]: 'Credit Applicant',
		[CustomComponentType.additionalEntity]: 'Additional Entities',
		[CustomComponentType.nonprofitInfo]: 'Nonprofit',
		[CustomComponentType.creditReport]: 'Credit Report',
		[CustomComponentType.authorizedSigner]: 'Authorized Signer',
		[CustomComponentType.trustee]: 'Trustee',
		[CustomComponentType.relatedContacts]: 'Related Contacts',
		[CustomComponentType.sbaForm1919]: 'SBA Form 1919',
		[CustomComponentType.sbaForm1920]: 'SBA Form 1920',
		[CustomComponentType.beneficialOwnership]: 'Beneficial Ownership',
		[CustomComponentType.beneficialOwnershipVue]: 'Beneficial Ownership',
		[CustomComponentType.extendedTextField]: 'Extended Text Field',
		[CustomComponentType.extendedSelectField]: 'Extended Select Field',
		[CustomComponentType.extendedNumber]: 'Extended Number',
		[CustomComponentType.extendedCurrency]: 'Extended Currency',
		[CustomComponentType.extendedTextArea]: 'Extended TextArea',
		[CustomComponentType.extendedRadio]: 'Extended Radio',
		[CustomComponentType.extendedDateTime]: 'Extended Date Time',
		[CustomComponentType.extendedPhoneNumber]: 'Extended Phone Number',
		[CustomComponentType.extendedPercent]: 'Extended Percent',
		[CustomComponentType.creditReportAuthorizationInput]: 'Credit Report Authorization Input',
		[CustomComponentType.creditReportPullCreditInput]: 'Credit Report Pull Credit Input',
		[CustomComponentType.customerTypeSelect]: 'Customer Type Selection',
		[CustomComponentType.creditReportCredentialsInput]: 'Credit Report Credentials Input',
		[CustomComponentType.creditReportPullCreditPopup]: 'Credit Report Pull Credit Popup',
		[CustomComponentType.applicantBusinessQuestion]: 'Is this business the applicant business?',
		[CustomComponentType.addCustomField]: 'Add Custom Field',
		[CustomComponentType.applicantBusinessConfirmChangePopup]: 'Applicant Business Confirm Change Popup',
		[CustomComponentType.applicantBusinessConfirmRemovePopup]: 'Applicant Business Confirm Remove Popup',
		[CustomComponentType.addCustomerPopup]: 'Add Customer',
		[CustomComponentType.collateralTypeSelect]: 'Collateral Type Select',
		[CustomComponentType.collateralTypeSelectOptions]: 'Collateral Type Select Options',
		[CustomComponentType.addNewCustomer]: 'Add New Customer',
		[CustomComponentType.income]: 'Income',
		[CustomComponentType.primaryRoleAddRowPopup]: 'Primary Role Add Row Popup',
		[CustomComponentType.loadingPopup]: 'Loading Popup',
		[CustomComponentType.financialSubaccount]: 'Financial Subaccount',
		[CustomComponentType.personalIncomeTypeSelect]: 'Income Type',
		[CustomComponentType.personalIncomeTypeSelectOptions]: 'Income Type Select Options',
		[CustomComponentType.borrowerAccessPopup]: 'Borrower Access Popup',
		[CustomComponentType.vehicleStateSelect]: 'Vehicle State Select',
		[CustomComponentType.vehicleStateSelectOptions]: 'Vehicle State Select Options',
		[CustomComponentType.expense]: 'Expenses',
		[CustomComponentType.personalExpenseTypeSelect]: 'Expense Type',
		[CustomComponentType.personalExpenseTypeSelectOptions]: 'Expense Type Select Options',
		[CustomComponentType.collateralAddRowPopup]: 'Collateral Add Row Popup',
		[CustomComponentType.otherLiabilities]: 'Other Liabilities',
		[CustomComponentType.personalLiabilityTypeSelect]: 'Liability Type',
		[CustomComponentType.personalLiabilityTypeSelectOptions]: 'Liability Type Select Options',
		[CustomComponentType.cashAccount]: 'Cash Accounts',
		[CustomComponentType.personalCashAccountTypeSelect]: 'Cash Account Type',
		[CustomComponentType.personalCashAccountTypeSelectOptions]: 'Cash Account Type Select Options',
		[CustomComponentType.stocksBondsBusinessHoldings]: 'Stocks, Bonds, and Business Holdings',
		[CustomComponentType.personalInvestmentTypeSelect]: 'Investment Type',
		[CustomComponentType.personalInvestmentTypeSelectOptions]: 'Investment Type Select Options',
		[CustomComponentType.nonInvestmentRealEstate]: 'Non-Investment Real Estate',
		[CustomComponentType.personalPropertyTypeSelect]: 'Property Type',
		[CustomComponentType.personalPropertyTypeSelectOptions]: 'Property Type Select Options',
		[CustomComponentType.investmentRealEstate]: 'Investment Real Estate',
		[CustomComponentType.businessInvestment]: 'Business Investments',
		[CustomComponentType.addNewBusinessInvestment]: 'Add New Business Investment',
		[CustomComponentType.addBusinessInvestmentPopup]: 'Add Business Investment Popup',
		[CustomComponentType.lifeInsurance]: 'Life Insurance',
		[CustomComponentType.personalInsuranceTypeSelect]: 'Insurance Type Select',
		[CustomComponentType.personalInsuranceTypeSelectOptions]: 'Insurance Type Select Options',
		[CustomComponentType.retirementAccount]: 'Retirement Accounts',
		[CustomComponentType.personalRetirementAccountTypeSelect]: 'Retirement Account Type',
		[CustomComponentType.personalRetirementAccountTypeSelectOptions]: 'Retirement Account Type Select Options',
		[CustomComponentType.baseRepeaterWidget]: 'Base Repeater Widget',
		[CustomComponentType.otherAssets]: 'Other Assets',
		[CustomComponentType.personalOtherAssetsTypeSelect]: 'Asset Type',
		[CustomComponentType.personalOtherAssetsTypeSelectOptions]: 'Other Assets Type Select Options',
		[CustomComponentType.hmdaLoanPurposeSelect]: 'HMDA Loan Purpose',
		[CustomComponentType.hmdaLoanPurposeSelectOptions]: 'HMDA Loan Purpose Select Options',
		[CustomComponentType.plaid]: 'Plaid'
	};
}
