
import { SbaForm } from '@sageworks/jpi';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SbaFormUtils } from '../../../utils';
import ConfirmReasonModal from '../confirm-reason-modal/confirm-reason-modal.vue';
import ResetFormModal from './ResetFormModal.vue';

enum ActionType {
	Approve,
	Decline,
	Revert,
	Waive
}

@Component({
	components: { ConfirmReasonModal, ResetFormModal }
})
export default class SbaFormFeatures extends Vue {
	@Prop()
	public sbaFormId!: number;

	public ActionType = ActionType;
	private inProgressAction: ActionType | null = null;

	get approveModalId() {
		return `approveModal${this.sbaFormId}`;
	}

	get declineModalId() {
		return `declineModal${this.sbaFormId}`;
	}

	get waiveModalId() {
		return `waiveModal${this.sbaFormId}`;
	}

	get resetModalId() {
		return `resetModal${this.sbaFormId}`;
	}

	get sbaForm() {
		const { sbaForms = [] } = this.directStore.state.SbaForm;

		return sbaForms.find(x => x.id === this.sbaFormId);
	}

	get isButtonsDisabled(): boolean {
		return this.inProgressAction != null;
	}

	get isDecisioned() {
		if (!this.sbaForm?.status) return false;
		return [SbaForm.StatusEnum.Approved, SbaForm.StatusEnum.Declined, SbaForm.StatusEnum.Waived].includes(this.sbaForm.status);
	}

	get formTitle() {
		if (!this.sbaForm?.formType) return '';
		return SbaFormUtils.getSbaFormTitle(this.sbaForm.formType);
	}

	get isFormLenderOnly() {
		if (!this.sbaForm?.formType) return false;
		return SbaFormUtils.isFormTypeLenderOnly(this.sbaForm.formType);
	}

	public async approveForm({ reason }: { reason: string }) {
		try {
			this.inProgressAction = ActionType.Approve;
			const userId = this.directStore.state.User.proxyUser?.id;

			if (userId) {
				await this.directStore.dispatch.SbaForm.approveSbaForm({ sbaFormId: this.sbaFormId, reason, userId });
			}
		} catch (err) {
			this.showToast('Approval Failed', 'An error occurred while approving the form.');
			throw err;
		} finally {
			this.inProgressAction = null;
		}
	}

	public async declineForm({ reason }: { reason: string }) {
		try {
			this.inProgressAction = ActionType.Decline;
			const userId = this.directStore.state.User.proxyUser?.id;

			if (userId) {
				await this.directStore.dispatch.SbaForm.declineSbaForm({ sbaFormId: this.sbaFormId, reason, userId });
			}
		} catch (err) {
			this.showToast('Decline Failed', 'An error occurred while declining the form.');
			throw err;
		} finally {
			this.inProgressAction = null;
		}
	}

	public async waiveForm({ reason }: { reason: string }) {
		try {
			this.inProgressAction = ActionType.Waive;
			const userId = this.directStore.state.User.proxyUser?.id;

			if (userId) {
				await this.directStore.dispatch.SbaForm.waiveSbaForm({ sbaFormId: this.sbaFormId, reason, userId });
			}
		} catch (err) {
			this.showToast('Waive Failed', 'An error occurred while waiving the form.');
			throw err;
		} finally {
			this.inProgressAction = null;
		}
	}

	public async resetForm() {
		try {
			this.inProgressAction = ActionType.Revert;
			const userId = this.directStore.state.User.proxyUser?.id;

			if (userId) {
				await this.directStore.dispatch.SbaForm.resetSbaFormStatus({ sbaFormId: this.sbaFormId });
			}
		} catch (err) {
			this.showToast('Reset Failed', 'An error occurred while resetting status of the form.');
			throw err;
		} finally {
			this.inProgressAction = null;
		}
	}

	private showToast(title: string, message: string) {
		this.$root.$bvToast.toast(message, {
			title: title,
			solid: true,
			autoHideDelay: 3000,
			toaster: 'b-toaster-bottom-right'
		});
	}
}
