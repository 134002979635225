export enum CustomBuilderGroups {
	widgets = 'widgets',
	preAppWidgets = 'preAppWidgets',
	productSelectionWidgets = 'productSelectionWidgets',
	nonprofitWidgets = 'nonprofitWidgets',
	defaultFields = 'defaultFields',
	udFields = 'udFields',
	entitySubWidgets = 'entitySubWidgets',
	entityInfoWidgets = 'entityInfoWidgets',
	widgetsVue = 'widgetsVue'
}
