import { DataObject, LoanRoleType, ObjectPropertyValuesWithRoleType, TemplateDataField } from '@sageworks/dynamic-forms';
import { AggregatedFormDataModel, DataFieldValue, LoanRoleDataModel, RelatedLoanRoleData } from '@sageworks/jpi';

export const locateRoles = (formData: AggregatedFormDataModel, loanRoleType: LoanRoleType) => {
	switch (loanRoleType) {
		case LoanRoleType.PrimaryBorrower:
			return formData?.primaryBorrowerMapping ? [formData.primaryBorrowerMapping] : [];
		case LoanRoleType.CoBorrower:
			return formData?.coBorrowerMappings ?? [];
		case LoanRoleType.CoSigner:
			return formData?.coSignerMappings ?? [];
		case LoanRoleType.CreditApplicant:
			return formData?.creditApplicantMappings ?? [];
		case LoanRoleType.Trustee:
			return formData?.trusteeMappings ?? [];
		case LoanRoleType.Guarantor:
			return formData?.guarantorMappings ?? [];
		default:
			return [];
	}
};

export const locateRole = (formData: AggregatedFormDataModel, id: number) => {
	for (let roleType of Object.values(LoanRoleType)) {
		const role = locateRoles(formData, roleType).find(role => role.dataObjectID === id);

		if (role) {
			return role;
		}
	}

	return undefined;
};

export const setRoles = (formData: AggregatedFormDataModel, loanRoleType: LoanRoleType, roles: LoanRoleDataModel[]) => {
	if (!formData) {
		return;
	}

	switch (loanRoleType) {
		case LoanRoleType.PrimaryBorrower:
			formData.primaryBorrowerMapping = roles.length > 0 ? roles[0] : undefined;
			break;
		case LoanRoleType.CoBorrower:
			formData.coBorrowerMappings = roles;
			break;
		case LoanRoleType.CoSigner:
			formData.coSignerMappings = roles;
			break;
		case LoanRoleType.CreditApplicant:
			formData.creditApplicantMappings = roles;
			break;
		case LoanRoleType.Trustee:
			formData.trusteeMappings = roles;
			break;
		case LoanRoleType.Guarantor:
			formData.guarantorMappings = roles;
			break;
	}
};

export const locateRelatedRoles = (formData: AggregatedFormDataModel, loanRoleType: LoanRoleType) => {
	switch (loanRoleType) {
		case LoanRoleType.AuthorizedSigner:
			return formData?.authorizedSigners ?? [];
		default:
			return [];
	}
};

export const setRelatedRoles = (formData: AggregatedFormDataModel, loanRoleType: LoanRoleType, roles: RelatedLoanRoleData[]) => {
	if (!formData) {
		return;
	}

	switch (loanRoleType) {
		case LoanRoleType.AuthorizedSigner:
			formData.authorizedSigners = roles;
			break;
	}
};

export const setParentProfitEntityToDataFieldValues = (
	dataObjectType: DataObject.TypeEnum,
	customerDataObject: ObjectPropertyValuesWithRoleType,
	parentLoanRole: LoanRoleDataModel,
	loanRoleType: LoanRoleType,
	templateDataFieldIdToDataFieldId: any
) => {
	if (!templateDataFieldIdToDataFieldId || loanRoleType !== LoanRoleType.AuthorizedSigner) return;

	let parentTemplateDataField: TemplateDataField;
	let signingCapacityTemplateDataField: TemplateDataField;
	switch (dataObjectType) {
		case DataObject.TypeEnum.Person:
			parentTemplateDataField = TemplateDataField.PersonalParentProfitEntity;
			signingCapacityTemplateDataField = TemplateDataField.PersonalSigningCapacity;
			break;
		case DataObject.TypeEnum.Business:
			parentTemplateDataField = TemplateDataField.BusinessParentProfitEntity;
			signingCapacityTemplateDataField = TemplateDataField.BusinessSigningCapacity;
			break;
		case DataObject.TypeEnum.Farm:
			parentTemplateDataField = TemplateDataField.FarmParentProfitEntity;
			signingCapacityTemplateDataField = TemplateDataField.FarmSigningCapacity;
			break;
		default:
			return;
	}

	const signingCapacityComponentId = templateDataFieldIdToDataFieldId[signingCapacityTemplateDataField];
	if (customerDataObject.dataFieldValues?.find(x => (x.id === signingCapacityComponentId))?.value) {
		const parentComponentId = templateDataFieldIdToDataFieldId[parentTemplateDataField];
		customerDataObject.dataFieldValues?.push({ id: parentComponentId, value: parentLoanRole.customerID } as DataFieldValue);
	}
};
