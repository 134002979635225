import { CustomComponentType, CustomValidationRule } from '../../../../enums';
import { removeInvalidFormsForCurrentComponent } from '../../copy-form/copy-form.helper';
import { Formio } from 'formiojs';
import { OpnPlatformType } from '@sageworks/core-logic/src/enums';
import { CopyFormUtils } from '../../../../utils';

const fetchForms = (componentType: CustomComponentType, projectUrl: string) => {
	return Formio.makeRequest(
		undefined,
		'form',
		`${projectUrl}/form?select=_id,title,path&path__regex=/${componentType}/&sort=-modified&limit=20`,
		'get',
		null,
		{
			ignoreCache: true
		}
	);
};

const fetchAllForms = function(componentType: CustomComponentType, platformType: OpnPlatformType, inSandbox: boolean) {
	const baseOptionsPromise = fetchForms(componentType, Formio.getProjectUrl());
	const bestPracticeOptionsPromise = fetchForms(componentType, CopyFormUtils.getBestPracticeProjectUrl(platformType, inSandbox)); 

	return Promise.all([baseOptionsPromise, bestPracticeOptionsPromise]);
};

const getFormOptions = function({ instance }: any, componentType: CustomComponentType) {
	if (instance.cachedForms != null) {
		return instance.cachedForms;
	}

	const { platformType, inSandbox } = instance.options;
	fetchAllForms(componentType, platformType, inSandbox).then(([baseOptions, bestPracticeOptions]) => {
		bestPracticeOptions = bestPracticeOptions.map((option: any) => {
			option.isBestPractice = true;
			return option;
		});
		baseOptions = baseOptions.map((option: any) => {
			option.isBestPractice = false;
			return option;
		});

		instance.cachedForms = [...bestPracticeOptions, ...baseOptions];
		instance.setItems(instance.cachedForms);
	});
	instance.setItems([{ value: -1, title: 'Loading...', isBestPractice: false }]);
};

export const getCopyFormComponent = (componentType: CustomComponentType) => {
	return {
		type: CustomComponentType.copyForm,
		input: true,
		dataSrc: 'custom',
		data: {
			custom(context: any) {
				return getFormOptions(context, componentType);
			}
		},
		filter: `path__regex=/${componentType}/`,
		searchField: 'title__regex',
		template: '<span title="{{item.title}}">{{ (item.isBestPractice ? "<b>Best Practice</b> - " : "") + item.title }}</span>',
		valueProperty: '_id',
		authenticate: true,
		label: 'Copy Form',
		key: 'form',
		weight: 10,
		lazyLoad: false,
		tooltip: 'The form to load within this form component.',
		placeholder: 'Choose form to copy',
		validations: [
			{
				rule: CustomValidationRule.copyFormRequired
			}
		],
		componentType: componentType,
		onSetItems: removeInvalidFormsForCurrentComponent
	};
};
