import { CustomComponentType } from '../enums';
import ComponentBaseWithCustomTypings from 'formiojs/components/_classes/component/Component';
import Loans from './form-components/loans/loans';
import { CollateralAddRowPopup } from './form-components/collateral-add-row-popup';
import CollateralInfo from './form-components/collateral-info/collateral-info';
import PersonalInfo from './form-components/personal-info/personal-info';
import BusinessInfo from './form-components/business-info/business-info';
import FarmInfo from './form-components/farm-info/farm-info';
import CopyForm from './configuration-components/copy-form/copy-form';
import LocationVerification from './form-components/location-verification/location-verification';
import ProductSelection from './form-components/product-selection/product-selection';
import EditableApiSelectOptions from './configuration-components/editable-api-select-options/editable-api-select-options';
import AppTypeSelect from './form-components/app-type-select/app-type-select';
import AppTypeSelectOptions from './configuration-components/app-type-select-options/app-type-select-options';
import ProposedProductSelect from './form-components/proposed-product-select/proposed-product-select';
import ProposedProductSelectOptions from './configuration-components/proposed-product-select-options/proposed-product-select-options';
import PrimaryBorrowerEntityWidget from './form-components/primary-borrower-entity/primary-borrower-entity';
import PrimaryBorrowerEntityWidgetVue from './form-components/primary-borrower-entity/primary-borrower-entity-vue';
import PersonalFinancialStatementWidget from './form-components/personal-financial-statement/personal-financial-statement';
import CoBorrowerEntityWidget from './form-components/co-borrower-entity/co-borrower-entity';
import CoBorrowerEntityWidgetVue from './form-components/co-borrower-entity/co-borrower-entity-vue';
import CoSignerEntityWidget from './form-components/co-signer-entity/co-signer-entity';
import CoSignerEntityWidgetVue from './form-components/co-signer-entity/co-signer-entity-vue';
import GuarantorEntityWidget from './form-components/guarantor-entity/guarantor-entity';
import GuarantorEntityWidgetVue from './form-components/guarantor-entity/guarantor-entity-vue';
import CreditApplicantEntityWidget from './form-components/credit-applicant-entity/credit-applicant-entity';
import CreditApplicantEntityWidgetVue from './form-components/credit-applicant-entity/credit-applicant-entity-vue';
import AdditionalEntityWidget from './form-components/additional-entity/additional-entity';
import NonprofitInfo from './form-components/nonprofit-info/nonprofit-info';
import CreditReport from './form-components/credit-report/credit-report';
import AuthorizedSigner from './form-components/authorized-signer/authorized-signer';
import Trustee from './form-components/trustee/trustee';
import RelatedContacts from './form-components/related-contacts/related-contacts';
import SbaForm1919 from './form-components/sba-form-1919/sba-form-1919';
import SbaForm1920 from './form-components/sba-form-1920/sba-form-1920';
import BeneficialOwnership from './form-components/beneficial-ownership/beneficial-ownership';
import BeneficialOwnershipVueWidget from './form-components/beneficial-ownership/beneficial-ownership-vue';
import ExtendedTextField from './form-components/simple-inputs/extended-text-field/extended-text-field';
import ExtendedSelectField from './form-components/simple-inputs/extended-select-field/extended-select-field';
import ExtendedNumber from './form-components/simple-inputs/extended-number/extended-number';
import ExtendedCurrency from './form-components/simple-inputs/extended-currency/extended-currency';
import ExtendedRadio from './form-components/simple-inputs/extended-radio/extended-radio';
import ExtendedTextArea from './form-components/simple-inputs/extended-text-area/extended-text-area';
import ExtendedDateTime from './form-components/simple-inputs/extended-date-time/extended-date-time';
import ExtendedPhoneNumber from './form-components/simple-inputs/extended-phone-number/extended-phone-number';
import ExtendedPercent from './form-components/simple-inputs/extended-percent/extended-percent';
import CreditReportAuthorizationInput from './form-components/credit-report/credit-report-authorization-input/credit-report-authorization-input';
import CreditReportPullCreditInput from './form-components/credit-report/credit-report-pull-credit-input/credit-report-pull-credit-input';
import CustomerTypeSelect from './form-components/customer-type-select/customer-type-select';
import CreditReportCredentialsInput from './form-components/credit-report/credit-report-credentials-input/credit-report-credentials-input';
import CreditReportPullCreditPopup from './form-components/credit-report/credit-report-pull-credit-popup/credit-report-pull-credit-popup';
import ApplicantBusinessQuestionWidget from './form-components/applicant-business-question/applicant-business-question';
import AddCustomField from './form-components/custom-field/add-custom-field';
import ApplicantBusinessConfirmChangePopup from './form-components/applicant-business-question/applicant-business-confirm-change-popup/applicant-business-confirm-change-popup';
import ApplicantBusinessConfirmRemovePopup from './form-components/applicant-business-question/applicant-business-confirm-remove-popup/applicant-business-confirm-remove-popup';
import AddCustomerPopup from './form-components/add-customer/add-customer-popup';
import CollateralTypeSelect from './form-components/collateral-type-select/collateral-type-select';
import CollateralTypeSelectOptions from './configuration-components/collateral-type-select-options/collateral-type-select-options';
import AddNewCustomer from './form-components/add-customer/add-new-customer/add-new-customer';
import {
	AddBusinessInvestmentPopup,
	AddNewBusinessInvestment,
	BusinessInvestmentWidget,
	ExpenseWidget,
	FinancialSubaccount,
	IncomeWidget,
	InvestmentRealEstateWidget,
	LifeInsuranceWidget,
	NonInvestmentRealEstateWidget,
	OtherAssetsWidget,
	OtherLiabilitiesWidget,
	RetirementAccountWidget,
	StocksBondsBusinessHoldingsWidget
} from './form-components';
import { PrimaryRoleAddRowPopup } from './form-components/primary-role-repeater-widget/primary-role-add-row-popup/primary-role-add-row-popup';
import { LoadingPopup } from './form-components/loading-popup';
import {
	PersonalExpenseTypeSelect,
	PersonalIncomeTypeSelect,
	PersonalInsuranceTypeSelect,
	PersonalInvestmentTypeSelect,
	PersonalLiabilityTypeSelect,
	PersonalOtherAssetsTypeSelect,
	PersonalPropertyTypeSelect,
	PersonalRetirementAccountTypeSelect
} from './form-components/financial-subaccount-components/type-select-components';
import {
	PersonalExpenseTypeSelectOptions,
	PersonalIncomeTypeSelectOptions,
	PersonalInsuranceTypeSelectOptions,
	PersonalInvestmentTypeSelectOptions,
	PersonalLiabilityTypeSelectOptions,
	PersonalOtherAssetsTypeSelectOptions,
	PersonalPropertyTypeSelectOptions,
	PersonalRetirementAccountTypeSelectOptions
} from './configuration-components/financial-subaccount-type-select-options';
import BorrowerAccessPopup from './form-components/add-customer/borrower-access-popup';
import { VehicleStateSelect } from './form-components/vehicle-state-select/vehicle-state-select';
import VehicleStateSelectOptions from './form-components/vehicle-state-select/vehicle-state-select-options';
import { CashAccountWidget } from './form-components/financial-subaccount-components/cash-account/cash-account';
import { PersonalCashAccountTypeSelect } from './form-components/financial-subaccount-components/type-select-components/personal-cash-account-type-select';
import { PersonalCashAccountTypeSelectOptions } from './configuration-components/financial-subaccount-type-select-options/personal-cash-account-type-select-options';
import BaseRepeaterWidget from './form-components/base/base-repeater-widget/base-repeater-widget';
import HmdaLoanPurposeSelect from './form-components/hmda-loan-purpose-select/hmda-loan-purpose-select';
import HmdaLoanPurposeSelectOptions from './configuration-components/hmda-loan-purpose-select-options/hmda-loan-purpose-select-options';
import PlaidComponent from './form-components/plaid/plaid-component';

export const componentList: { [type in CustomComponentType]: typeof ComponentBaseWithCustomTypings } = {
	[CustomComponentType.loans]: Loans,
	[CustomComponentType.collateralAddRowPopup]: CollateralAddRowPopup,
	[CustomComponentType.collateralInfo]: CollateralInfo,
	[CustomComponentType.personalInfo]: PersonalInfo,
	[CustomComponentType.businessInfo]: BusinessInfo,
	[CustomComponentType.farmInfo]: FarmInfo,
	[CustomComponentType.copyForm]: CopyForm,
	[CustomComponentType.locationVerification]: LocationVerification,
	[CustomComponentType.productSelection]: ProductSelection,
	[CustomComponentType.editableApiSelectOptions]: EditableApiSelectOptions,
	[CustomComponentType.appTypeSelect]: AppTypeSelect,
	[CustomComponentType.appTypeSelectOptions]: AppTypeSelectOptions,
	[CustomComponentType.proposedProductSelect]: ProposedProductSelect,
	[CustomComponentType.proposedProductSelectOptions]: ProposedProductSelectOptions,
	[CustomComponentType.primaryBorrowerEntity]: PrimaryBorrowerEntityWidget,
	[CustomComponentType.primaryBorrowerEntityVue]: PrimaryBorrowerEntityWidgetVue,
	[CustomComponentType.personalFinancialStatement]: PersonalFinancialStatementWidget,
	[CustomComponentType.coBorrowerEntity]: CoBorrowerEntityWidget,
	[CustomComponentType.coBorrowerEntityVue]: CoBorrowerEntityWidgetVue,
	[CustomComponentType.coSignerEntity]: CoSignerEntityWidget,
	[CustomComponentType.coSignerEntityVue]: CoSignerEntityWidgetVue,
	[CustomComponentType.guarantorEntity]: GuarantorEntityWidget,
	[CustomComponentType.guarantorEntityVue]: GuarantorEntityWidgetVue,
	[CustomComponentType.creditApplicantEntity]: CreditApplicantEntityWidget,
	[CustomComponentType.creditApplicantEntityVue]: CreditApplicantEntityWidgetVue,
	[CustomComponentType.additionalEntity]: AdditionalEntityWidget,
	[CustomComponentType.nonprofitInfo]: NonprofitInfo,
	[CustomComponentType.creditReport]: CreditReport,
	[CustomComponentType.authorizedSigner]: AuthorizedSigner,
	[CustomComponentType.trustee]: Trustee,
	[CustomComponentType.relatedContacts]: RelatedContacts,
	[CustomComponentType.sbaForm1919]: SbaForm1919,
	[CustomComponentType.sbaForm1920]: SbaForm1920,
	[CustomComponentType.beneficialOwnership]: BeneficialOwnership,
	[CustomComponentType.beneficialOwnershipVue]: BeneficialOwnershipVueWidget,
	[CustomComponentType.extendedTextField]: ExtendedTextField,
	[CustomComponentType.extendedSelectField]: ExtendedSelectField,
	[CustomComponentType.extendedNumber]: ExtendedNumber,
	[CustomComponentType.extendedCurrency]: ExtendedCurrency,
	[CustomComponentType.extendedRadio]: ExtendedRadio,
	[CustomComponentType.extendedTextArea]: ExtendedTextArea,
	[CustomComponentType.extendedDateTime]: ExtendedDateTime,
	[CustomComponentType.extendedPhoneNumber]: ExtendedPhoneNumber,
	[CustomComponentType.extendedPercent]: ExtendedPercent,
	[CustomComponentType.creditReportAuthorizationInput]: CreditReportAuthorizationInput,
	[CustomComponentType.creditReportPullCreditInput]: CreditReportPullCreditInput,
	[CustomComponentType.customerTypeSelect]: CustomerTypeSelect,
	[CustomComponentType.creditReportCredentialsInput]: CreditReportCredentialsInput,
	[CustomComponentType.creditReportPullCreditPopup]: CreditReportPullCreditPopup,
	[CustomComponentType.applicantBusinessQuestion]: ApplicantBusinessQuestionWidget,
	[CustomComponentType.addCustomField]: AddCustomField,
	[CustomComponentType.applicantBusinessConfirmChangePopup]: ApplicantBusinessConfirmChangePopup,
	[CustomComponentType.applicantBusinessConfirmRemovePopup]: ApplicantBusinessConfirmRemovePopup,
	[CustomComponentType.addCustomerPopup]: AddCustomerPopup,
	[CustomComponentType.collateralTypeSelect]: CollateralTypeSelect,
	[CustomComponentType.collateralTypeSelectOptions]: CollateralTypeSelectOptions,
	[CustomComponentType.addNewCustomer]: AddNewCustomer,
	[CustomComponentType.income]: IncomeWidget,
	[CustomComponentType.primaryRoleAddRowPopup]: PrimaryRoleAddRowPopup,
	[CustomComponentType.loadingPopup]: LoadingPopup,
	[CustomComponentType.financialSubaccount]: FinancialSubaccount,
	[CustomComponentType.personalIncomeTypeSelect]: PersonalIncomeTypeSelect,
	[CustomComponentType.personalIncomeTypeSelectOptions]: PersonalIncomeTypeSelectOptions,
	[CustomComponentType.borrowerAccessPopup]: BorrowerAccessPopup,
	[CustomComponentType.vehicleStateSelect]: VehicleStateSelect,
	[CustomComponentType.vehicleStateSelectOptions]: VehicleStateSelectOptions,
	[CustomComponentType.expense]: ExpenseWidget,
	[CustomComponentType.personalExpenseTypeSelect]: PersonalExpenseTypeSelect,
	[CustomComponentType.personalExpenseTypeSelectOptions]: PersonalExpenseTypeSelectOptions,
	[CustomComponentType.otherLiabilities]: OtherLiabilitiesWidget,
	[CustomComponentType.personalLiabilityTypeSelect]: PersonalLiabilityTypeSelect,
	[CustomComponentType.personalLiabilityTypeSelectOptions]: PersonalLiabilityTypeSelectOptions,
	[CustomComponentType.cashAccount]: CashAccountWidget,
	[CustomComponentType.personalCashAccountTypeSelect]: PersonalCashAccountTypeSelect,
	[CustomComponentType.personalCashAccountTypeSelectOptions]: PersonalCashAccountTypeSelectOptions,
	[CustomComponentType.stocksBondsBusinessHoldings]: StocksBondsBusinessHoldingsWidget,
	[CustomComponentType.personalInvestmentTypeSelect]: PersonalInvestmentTypeSelect,
	[CustomComponentType.personalInvestmentTypeSelectOptions]: PersonalInvestmentTypeSelectOptions,
	[CustomComponentType.nonInvestmentRealEstate]: NonInvestmentRealEstateWidget,
	[CustomComponentType.personalPropertyTypeSelect]: PersonalPropertyTypeSelect,
	[CustomComponentType.personalPropertyTypeSelectOptions]: PersonalPropertyTypeSelectOptions,
	[CustomComponentType.investmentRealEstate]: InvestmentRealEstateWidget,
	[CustomComponentType.businessInvestment]: BusinessInvestmentWidget,
	[CustomComponentType.addNewBusinessInvestment]: AddNewBusinessInvestment,
	[CustomComponentType.addBusinessInvestmentPopup]: AddBusinessInvestmentPopup,
	[CustomComponentType.lifeInsurance]: LifeInsuranceWidget,
	[CustomComponentType.personalInsuranceTypeSelect]: PersonalInsuranceTypeSelect,
	[CustomComponentType.personalInsuranceTypeSelectOptions]: PersonalInsuranceTypeSelectOptions,
	[CustomComponentType.retirementAccount]: RetirementAccountWidget,
	[CustomComponentType.personalRetirementAccountTypeSelect]: PersonalRetirementAccountTypeSelect,
	[CustomComponentType.personalRetirementAccountTypeSelectOptions]: PersonalRetirementAccountTypeSelectOptions,
	[CustomComponentType.baseRepeaterWidget]: BaseRepeaterWidget,
	[CustomComponentType.otherAssets]: OtherAssetsWidget,
	[CustomComponentType.personalOtherAssetsTypeSelect]: PersonalOtherAssetsTypeSelect,
	[CustomComponentType.personalOtherAssetsTypeSelectOptions]: PersonalOtherAssetsTypeSelectOptions,
	[CustomComponentType.hmdaLoanPurposeSelect]: HmdaLoanPurposeSelect,
	[CustomComponentType.hmdaLoanPurposeSelectOptions]: HmdaLoanPurposeSelectOptions,
	[CustomComponentType.plaid]: PlaidComponent
};
