
import { ApplicationExportMetadata } from '@/models';
import BaseTemplateMixin from './base-template-mixin.vue';

export default BaseTemplateMixin.extend({
	name: 'RoleRepeater',
	components: {},
	computed: {
		numberOfRepeatingRoles(): number {
			return this.configurationLookup.rowCount ?? 0;
		}
	},
	methods: {
		buildRepeatingRoleExportMetadata(componentTree: any, roleIndex: number): ApplicationExportMetadata {
			return {
				...this.buildExportMetadata(componentTree),
				ComponentPath: `${this.dataLookupPath}.${roleIndex}`
			};
		}
	}
});
