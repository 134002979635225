import BaseComponent from '../base/base-widget/base-widget';
import editForm from './nonprofit-info.form';
import { CustomComponentType } from '../../../enums/custom-component-type';
import { forcedLoadSubForm } from '../simple-inputs/extended-field-helper';
import { ConditionalHelper, CustomConditionalType } from '../../../utils/conditional-helper';

export default class NonprofitInfoWidget extends BaseComponent {
	static schema(...extend: any) {
		return BaseComponent.schema(
			{
				label: 'Nonprofit Info',
				type: CustomComponentType.nonprofitInfo,
				key: CustomComponentType.nonprofitInfo
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: 'Nonprofit Info',
			group: '',
			weight: 10,
			schema: NonprofitInfoWidget.schema()
		};
	}

	constructor(component: any, options: any, data: any) {
		if (!component?.preserveConditional) {
			ConditionalHelper.clearConditionals(component);
			ConditionalHelper.addCustomConditional(component, CustomConditionalType.OnlyShowIfDataContainsId);
		}

		super(component, options, data);
	}

	loadSubForm() {
		if (this.component.formJson) {
			return super.loadSubForm();
		}
		return forcedLoadSubForm(this);
	}
}
