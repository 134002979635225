
import Vue,{ PropType } from 'vue';
import { WidgetValidationMessage } from '../../models/widget-validation-message';

export default Vue.extend({
	name: 'ValidationMessagesBox',
	props: {
		messages: {
			type: Array as PropType<WidgetValidationMessage[]>,
			default: () => []
		},
	},
});
