export enum FormioEventName {
	error = 'error',
	checkValidity = 'checkValidity',
	submitButton = 'submitButton',
	customEvent = 'customEvent',
	requestButton = 'requestButton',
	requestUrl = 'requestUrl',
	resetForm = 'resetForm',
	deleteSubmission = 'deleteSubmission',
	submitError = 'submitError',
	redraw = 'redraw',
	componentChange = 'componentChange',
	componentError = 'componentError',
	editGridAddRow = 'editGridAddRow',
	subWizardsUpdated = 'subWizardsUpdated',
	focus = 'focus',
	blur = 'blur',
	build = 'build',
	render = 'render',
	attach = 'attach',
	formio = 'formio',
	pdfUploaded = 'pdfUploaded',
	updateComponent = 'updateComponent',
	change = 'change',
	addComponent = 'addComponent',
	iframe = 'iframe',
	languageChanged = 'languageChanged',
	formLoad = 'formLoad',
	saveDraftBegin = 'saveDraftBegin',
	saveDraft = 'saveDraft',
	restoreDraft = 'restoreDraft',
	submit = 'submit',
	submitDone = 'submitDone',
	initialized = 'initialized',
	submissionDeleted = 'submissionDeleted',
	requestDone = 'requestDone',
	nosubmit = 'nosubmit',
	disabled = 'disabled',
	hide = 'hide',
	require = 'require',
	removeComponent = 'removeComponent',
	saveComponent = 'saveComponent',
	cancelComponent = 'cancelComponent',
	editComponent = 'editComponent',
	wizardNavigationClicked = 'wizardNavigationClicked',
	wizardPageSelected = 'wizardPageSelected',
	nextPage = 'nextPage',
	prevPage = 'prevPage',
	formEmbedded = 'formEmbedded',
	update = 'update',

	// Custom events
	nestedWizardPageChange = 'nestedWizardPageChange',
	nestedWizardSaveAndClose = 'nestedWizardSaveAndClose',
	rootWizardPreviousPage = 'rootWizardPreviousPage',
	rootWizardNextPage = 'rootWizardNextPage',
	rootWizardSaveOnPageChange = 'rootWizardSaveOnPageChange',
	showParentNav = 'showParentNav',
	hideParentNav = 'hideParentNav'
}
