import editForm from './related-contacts.form';
import { CustomComponentType } from '../../../enums/custom-component-type';
import { RelatedRoleRepeaterWidget } from '../related-role-repeater-widget/related-role-repeater-widget';

export default class RelatedContactsWidget extends RelatedRoleRepeaterWidget {
	static schema(...extend: any) {
		return RelatedRoleRepeaterWidget.schema(
			{
				label: 'Related Contacts',
				type: CustomComponentType.relatedContacts,
				key: CustomComponentType.relatedContacts,
				addAnother: 'Add a Related Contact'
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: 'Related Contacts',
			group: '',
			weight: 10,
			schema: RelatedContactsWidget.schema({ skipRemoveConfirm: true })
		};
	}

	render(children: any, topLevel?: boolean): any {
		return super.render(children, topLevel);
	}
}
