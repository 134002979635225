import { StoreType } from '../../../../store';
import { DataObjectMapper, CustomComponentType, DataObject, SubmitPayload, DeletePayload, TemplateDataField } from '@sageworks/dynamic-forms';
import { BusinessInvestment } from '@sageworks/jpi';
import { getOrForgePfs } from '../financials-data-handler-utils';
import { DataObjectDataHandler } from '../data-object-data-handler';
import { formatDataFieldIdKey } from '@sageworks/dynamic-forms/src/components/form-components/simple-inputs/extended-field-helper';

export class BusinessInvestmentsDataHandler extends DataObjectDataHandler {
	constructor(_store: StoreType) {
		super(_store, CustomComponentType.businessInvestment);
	}

	private get loanMappingId() {
		const { metadata } = this._store.state.LoanApplicationMetadata;
		const { loanIndex } = this._store.state.MultiLoanApplicationForm;

		return metadata[loanIndex].loanMappingId!;
	}

	private get currentBusinessInvestments() {
		return this._store.state.MultiLoanApplicationForm.renderData?.formData?.businessInvestments ?? [];
	}

	private get templateDataFieldMappings(): { [templateDataFieldId: number]: number } {
		const keyValuePresetFields = this._store.state.LoanApplicationMetadata.presetDataFieldsByDataObject[DataObject.TypeEnum.BusinessInvestments]?.map(x => [
			x.templateDataFieldId,
			x.id
		]) as any;

		const pfsPresetDataFields = keyValuePresetFields == null ? {} : Object.fromEntries(keyValuePresetFields);

		return {
			...this._store.state.CustomComponentProperties.templateDataFieldIdToDataFieldId,
			...pfsPresetDataFields
		};
	}

	public async submit(payload: SubmitPayload) {
		const pfsId = await getOrForgePfs(payload, this.loanMappingId, this._store);
		const investmentEntityId = await this.saveInvestmentEntity(payload);
		const investmentOwnerCustomerId = payload.metadata?.customerId;

		const inputDataIdFieldId = formatDataFieldIdKey(this.templateDataFieldMappings[TemplateDataField.BusinessInvestmentOwnerFinancialDataId].toString());
		const investmentEntityDataFieldId = formatDataFieldIdKey(
			this.templateDataFieldMappings[TemplateDataField.BusinessInvestmentInvestmentEntityId].toString()
		);
		const investmentOwnerCustomerIdFieldId = formatDataFieldIdKey(
			this.templateDataFieldMappings[TemplateDataField.BusinessInvestmentOwnerCustomerId].toString()
		);

		payload.submission.data[inputDataIdFieldId] = pfsId;
		payload.submission.data[investmentEntityDataFieldId] = investmentEntityId;
		payload.submission.data[investmentOwnerCustomerIdFieldId] = investmentOwnerCustomerId;

		const result = await this.saveDataObject(DataObject.TypeEnum.BusinessInvestments, payload);

		if (!this.currentBusinessInvestments.find(x => x.id === result.id)) {
			const newBusinessInvestment: BusinessInvestment = {
				id: result.id,
				ownerInputDataId: pfsId,
				customerId: investmentOwnerCustomerId,
				investmentEntityId: investmentEntityId
			};
			await this._store.dispatch.MultiLoanApplicationForm.setBusinessInvestments({
				businessInvestments: [...this.currentBusinessInvestments, newBusinessInvestment]
			});
		}

		return this.createFormObject(payload, result);
	}

	public async delete(payload: DeletePayload) {
		const result = await super.delete(payload);

		const idRemoved = payload.submission?.form?.data?.id as number;

		await this._store.dispatch.MultiLoanApplicationForm.setBusinessInvestments({
			businessInvestments: this.currentBusinessInvestments.filter(x => x.id !== idRemoved)
		});

		return result;
	}

	private async saveInvestmentEntity(payload: SubmitPayload) {
		const { submission, metadata } = payload;
		const propName = Object.keys(submission.data).find(key =>
			[CustomComponentType.businessInfo, CustomComponentType.farmInfo].includes(key as CustomComponentType)
		);
		let investmentEntityId = submission.data[propName!]?.data?.id;

		if (propName == null) {
			throw new Error('no dataObject of the correct type');
		}

		if (investmentEntityId == null || investmentEntityId <= 0) {
			const savedDataObject = await this.saveDataObject(DataObjectMapper.submissionDataPropertiesLookup[propName], {
				submission: submission.data[propName!],
				metadata
			});
			submission.data[propName!].data.id = savedDataObject.id;
			investmentEntityId = savedDataObject.id;
		}

		return investmentEntityId;
	}
}
