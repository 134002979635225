import '@/polyfills';
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import GetRouter from '@/router';
import App from '@/App.vue';
import store from '@/store';
import { InitializeAppUtils, UrlUtils } from '@/utils';
import '@/RouterHooks';
import '@/styles/styles.scss';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import DirectStorePlugin from '@/libraries/direct-store-plugin';
import { OnlinePortalRoutes } from './OnlinePortalRoutes';
import initializeFortAwesome from './libraries/fortawesome';
import VueRouter from 'vue-router';

import { useAuth0 } from './composables/auth0';
const { instantiateAuth0Client } = useAuth0();

const MESSAGE_SOURCE = 'OnlinePortalNow';

async function _initializeAuth0(router: VueRouter) {
	const domain = window.app.auth0Domain;
	const clientId = window.app.auth0ClientId;
	const auth0RedirectUrl = UrlUtils.getRedirectUrl();
	await instantiateAuth0Client(
		{
			domain,
			clientId,
			authorizationParams: { redirect_uri: auth0RedirectUrl }
		},
		function onAuth0RedirectCallback(appState: any) {
			router.push(appState && appState.targetUrl ? appState.targetUrl : auth0RedirectUrl);
		}
	);
}

// eslint-disable-next-line max-lines-per-function
async function StartVue(): Promise<void> {
	Vue.use(Vuelidate);
	Vue.use(BootstrapVue);
	Vue.use(DirectStorePlugin);
	const rawConfig = await InitializeAppUtils.loadAppConfigJSON();
	window.app = JSON.parse(rawConfig);

	InitializeAppUtils.configureApi();
	InitializeAppUtils.configureAuth();
	const router = await GetRouter();
	await _initializeAuth0(router);

	new Vue({
		router,
		store: store.original, // direct-vuex wrapper around "original" vuex store
		created() {
			window.addEventListener('resize', this.directStore.dispatch.WindowInfo.recalculateCurrentBreakpoint, { passive: true });
			// Ignore click/keypress events and only allow the user to close the logout modal by clicking the 'Remain Logged In' button
			window.addEventListener(
				'click',
				() => {
					if (!this.directStore.state.SessionActivity.showLogoutModal) {
						this.directStore.dispatch.SessionActivity.updateLastUserActionTime();
					}
				},
				{ passive: true }
			);
			window.addEventListener(
				'keyup',
				() => {
					if (!this.directStore.state.SessionActivity.showLogoutModal) {
						this.directStore.dispatch.SessionActivity.updateLastUserActionTime();
					}
				},
				{ passive: true }
			);
			window.addEventListener('message', this.receiveMessageFromChildWindow);
			this.directStore.dispatch.InstitutionSettings.fetchLoginMessage();
			this.directStore.dispatch.SessionActivity.startSessionActivityWatcher();

			this.$root.$on('parent-window-message', this.sendMessageToParentWindow);
		},
		destroyed() {
			window.removeEventListener('resize', this.directStore.dispatch.WindowInfo.recalculateCurrentBreakpoint);
			window.removeEventListener('click', this.directStore.dispatch.SessionActivity.updateLastUserActionTime);
			window.removeEventListener('keyup', this.directStore.dispatch.SessionActivity.updateLastUserActionTime);
			window.removeEventListener('message', this.receiveMessageFromChildWindow);
			this.directStore.commit.SessionActivity.SET_SESSION_ACTIVITY_WATCHER_INTERVAL_ID(0);
			this.$root.$off('message-to-parent-window', this.sendMessageToParentWindow);
		},
		methods: {
			receiveMessageFromChildWindow({ origin, data }: MessageEvent<any>) {
				if (origin !== window.location.origin || data?.source !== MESSAGE_SOURCE) {
					return;
				}

				this.$root.$emit('child-window-message', { ...data });
			},
			sendMessageToParentWindow(message: any) {
				const payload = { payload: message.payload, channel: message.channel, source: MESSAGE_SOURCE };

				window.parent.postMessage(payload, window.location.origin);
			}
		},
		render: h => h(App)
	}).$mount('#app');

	// If requested route requires context of a subdomain and we do not have a valid subdomain, show the 404 Not Found page.
	let routesNotRequiringSubdomain = [OnlinePortalRoutes.NotFound.name, OnlinePortalRoutes.HealthPage.name] as (string | undefined)[];
	let requiresSubdomain = !routesNotRequiringSubdomain.includes(router.currentRoute.name);
	if (requiresSubdomain && !(await UrlUtils.isSubdomainValid())) {
		router.push({ name: OnlinePortalRoutes.NotFound.name });
	}
}

initializeFortAwesome();
if (process.env.NODE_ENV === 'production') Vue.config.productionTip = false;

async function main() {
	await StartVue();

	store.commit.AppConfig.SET_APP_CONFIG(window.app);
}

main();
