import baseEditForm from 'formiojs/components/radio/Radio.form';

import ForceRequiredField from '../../../configuration-components/force-required/force-required';
import OriginalLabel from '../../../configuration-components/base-widget-configuration/display/original-label';
import BaseEditConditionalComponents from '../../../configuration-components/base-widget-configuration/base.edit.conditional.components';
import { BaseEditData } from '../../../configuration-components/base-widget-configuration/data/base.edit.data';
import LenderOnly from '../../../configuration-components/base-widget-configuration/display/lender-only-field-checkbox';
// eslint-disable-next-line max-lines-per-function
export default function(...extend: any[]) {
	return baseEditForm(
		[
			{
				key: 'api',
				ignore: true
			},
			{
				key: 'display',
				components: [OriginalLabel, LenderOnly]
			},
			new BaseEditData().getTab({
				components: {
					defaultComponents: [
						{
							key: 'values',
							components: [
								{
									label: 'Label',
									key: 'label',
									input: true,
									type: 'textfield'
								},
								{
									label: 'Value',
									key: 'value',
									input: true,
									type: 'textfield',
									disabled: true
								},
								{
									key: 'shortcut',
									ignore: true
								}
							],
							disableAddingRemovingRows: true
						},
						{
							key: 'dataType',
							ignore: true
						}
					]
				}
			}),
			{
				key: 'validation',
				components: ForceRequiredField
			},
			{
				key: 'conditional',
				components: BaseEditConditionalComponents
			}
		],
		...extend
	);
}
