// Will leave the styles exported through a string instead of scss in case we need to dynamically manipulate in the future
const styles: string = `
<style>
/* */ /* Grid and Layout */ /* */
*,
:after,
:before {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
	margin-bottom: 16px;
}
.col {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 100%;
}
.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-xs,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto,
.col-xxl,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl-auto {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xl-1 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 8.3333333333%;
	flex: 0 0 8.3333333333%;
	max-width: 8.3333333333%;
}
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xl-2 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 16.6666666667%;
	flex: 0 0 16.6666666667%;
	max-width: 16.6666666667%;
}
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xl-3 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 25%;
	flex: 0 0 25%;
	max-width: 25%;
}
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xl-4 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 33.3333333333%;
	flex: 0 0 33.3333333333%;
	max-width: 33.3333333333%;
}
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xl-5 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 41.6666666667%;
	flex: 0 0 41.6666666667%;
	max-width: 41.6666666667%;
}
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xl-6 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%;
}
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xl-7 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 58.3333333333%;
	flex: 0 0 58.3333333333%;
	max-width: 58.3333333333%;
}
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xl-8 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 66.6666666667%;
	flex: 0 0 66.6666666667%;
	max-width: 66.6666666667%;
}
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xl-9 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 75%;
	flex: 0 0 75%;
	max-width: 75%;
}
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xl-10 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 83.3333333333%;
	flex: 0 0 83.3333333333%;
	max-width: 83.3333333333%;
}
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xl-11 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 91.6666666667%;
	flex: 0 0 91.6666666667%;
	max-width: 91.6666666667%;
}
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12,
.col-xl-12 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%;
} /* */ /* Typography */ /* */
body {
	font-family: sans-serif;
	color: black;
	line-height: 1.5;
}
.font-weight-bold {
	font-weight: bold !important;
} /* Navigation section titles */
.card-header {
	font-size: 24px;
	font-weight: normal;
	margin-bottom: 12px;
} /* Tab titles */
.card-header-tabs {
	font-size: 16px;
}
.card-header-tabs a {
	text-decoration: none;
	color: inherit;
} /* Field labels */
.col-form-label {
	font-weight: bold;
	font-size: 80%;
} /* Descriptions */
.text-muted {
	color: #797979 !important;
	font-size: 80%;
} /* */ /* Components */ /* */ /* Field Set */
fieldset {
	margin-inline-start: 0;
	margin-inline-end: 0;
	padding-block-start: 0;
	padding-inline-start: 0;
	padding-inline-end: 0;
	padding-block-end: 0;
	min-inline-size: min-content;
	-webkit-padding-start: 0;
	-webkit-padding-end: 0;
	border-width: 0;
}
legend {
	padding-inline-start: 0;
	padding-inline-end: 0;
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 8px;
} /* Panel */
.formio-component-panelTitle > .card.border {
	border: 1px solid #c5c5c5;
}
.formio-component-panelTitle > div > .panel-body {
	padding: 10px;
}
.formio-component-panelTitle > div > .card-header.bg-default {
	display: block;
	font-size: 16px;
	padding: 0.75rem 1.25rem;
	margin-bottom: 0;
	background-color: rgba(0, 0, 0, 0.03);
	border-bottom: 1px solid #c5c5c5;
} /* Table */
.table {
	width: 100%;
}
.b-table-sticky-header > .table,
.table-responsive > .table,
[class*="table-responsive-"] > .table {
	margin-bottom: 0;
}
.table-responsive > .table-bordered {
	border: 0;
}
table {
	border-collapse: collapse;
}
.table-bordered,
.table-bordered td,
.table-bordered th {
	border: 1px solid #c5c5c5;
}
.table-striped tbody tr:nth-of-type(2n) {
	background-color: rgba(0, 0, 0, 0.04);
} /* Text Area - remove background and border */
.formio-component-extended-text-area
	> div
	> .card.card-body.bg-light {
	background-color: inherit;
	min-height: 0;
	padding: 0;
	border: 0;
} /* Well */
.card.card-body.bg-light {
	background-color: #f8f8f8;
	min-height: 1px;
	padding: 20px;
	border: 1px solid #c5c5c5;
	margin-bottom: 16px;
} /* */ /* Spacing */ /* */ /* Navigation sections - after */
.card.border {
	margin-bottom: 16px;
} /* Navigation sections - within */ /* See .card-header in typography section */ /* Fields - after */
.form-group {
	margin-bottom: 16px;
} /* Rows - after */ /* Needed because field spacing inside of rows (columns) doesn't apply */ 
/* See .row in grid and layout section */ 
/* Signature lines - between */ 
/* Note: purposefully didn't add more utilities to allow targetting specific PDF styling */
.mb-4,
.my-4 {
	margin-bottom: 24px !important;
}
.mt-4,
.my-4 {
	margin-top: 24px !important;
} /* Tabs - after */ /* Needed because field spacing inside of tabs doesn't apply */
.formio-component-tabs {
	margin-bottom: 16px;
}/* Terms - after */
#TermsAndConditionsMessage {
	margin-bottom: 24px;
} /* */ /* Miscellaneous */ /* */ /* Hide all buttons */
button {
	display: none;
} /* Required field asterisks */
.editgrid-listgroup {
	padding-inline-start: 0;
} /* Remove indents and list item styles */
ul {
	list-style-type: none;
	-webkit-padding-start: 0;
} /* Signature lines */
.border-bottom:empty {
	border-bottom: 1px solid #c5c5c5 !important;
}
.d-inline-block {
	display: inline-block !important;
} /* Widths - used for signature lines */
.w-10 {
	width: 10% !important;
}
.w-20 {
	width: 20% !important;
}
.w-30 {
	width: 30% !important;
}
.w-40 {
	width: 40% !important;
}
.w-50 {
	width: 50% !important;
}
.w-60 {
	width: 60% !important;
}
.w-70 {
	width: 70% !important;
}
.w-80 {
	width: 80% !important;
}
.w-90 {
	width: 90% !important;
}
.w-100 {
	width: 100% !important;
}
.max-w-150px {
	max-width: 150px;
}
.max-h-50px {
	max-height: 50px;
}
.py-3 {
	padding-top: 1rem !important;
	padding-bottom: 1rem !important;
}
.bg-white {
	background-color: #f8f8f8;
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
	padding-right: 0;
	padding-left: 0;
}

.justify-content-between {
	-webkit-box-pack: justify !important;
	-ms-flex-pack: justify !important;
	justify-content: space-between !important;
}

.d-flex {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
}
[dir="rtl"] .b-time > .d-flex:not(.flex-column) {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
}

.pt-0 {
	padding: 0rem !important;
}

.p-2 {
	padding: 0.5rem !important;
}

.pt-2,
.py-2 {
	padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
	padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
	padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
	padding-left: 0.5rem !important;
}
.p-3 {
	padding: 1rem !important;
}

.pt-3,
.py-3 {
	padding-top: 1rem !important;
}

.pr-3,
.px-3 {
	padding-right: 1rem !important;
}

.pb-3,
.py-3 {
	padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
	padding-left: 1rem !important;
}

.p-5 {
	padding: 3rem !important;
}

.pt-5,
.py-5 {
	padding-top: 3rem !important;
}

.pr-5,
.px-5 {
	padding-right: 3rem !important;
}

.pb-5,
.py-5 {
	padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
	padding-left: 3rem !important;
}

.m-5 {
	margin: 3rem !important;
}

.mt-5,
.my-5 {
	margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
	margin-right: 3rem !important;
}

.mb-5,
.my-5 {
	margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
	margin-left: 3rem !important;
}
.justify-content-start {
	-webkit-box-pack: start !important;
	-ms-flex-pack: start !important;
	justify-content: flex-start !important;
}

.justify-content-end {
	-webkit-box-pack: end !important;
	-ms-flex-pack: end !important;
	justify-content: flex-end !important;
}

.justify-content-center {
	-webkit-box-pack: center !important;
	-ms-flex-pack: center !important;
	justify-content: center !important;
}

.justify-content-around {
	-ms-flex-pack: distribute !important;
	justify-content: space-around !important;
}

.panel-body {
	padding-left: 1rem !important;
	padding-top: 1rem !important;
}

*,
:after,
:before {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

html {
	font-family: sans-serif;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
	display: block;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
		Helvetica Neue, Arial, Noto Sans, Liberation Sans,
		sans-serif, Apple Color Emoji, Segoe UI Emoji,
		Segoe UI Symbol, Noto Color Emoji;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #333;
	text-align: left;
	background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
	outline: 0 !important;
}

hr {
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
}

abbr[data-original-title],
abbr[title] {
	text-decoration: underline;
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted;
	cursor: help;
	border-bottom: 0;
	-webkit-text-decoration-skip-ink: none;
	text-decoration-skip-ink: none;
}

address {
	font-style: normal;
	line-height: inherit;
}

address,
dl,
ol,
ul {
	margin-bottom: 1rem;
}

dl,
ol,
ul {
	margin-top: 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
	margin-bottom: 0;
}

dt {
	font-weight: 700;
}

dd {
	margin-bottom: 0.5rem;
	margin-left: 0;
}

blockquote {
	margin: 0 0 1rem;
}

b,
strong {
	font-weight: bolder;
}

small {
	font-size: 80%;
}

sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

a {
	color: #295e8e;
	background-color: transparent;
}

a,
a:hover {
	text-decoration: underline;
}

a:hover {
	color: #183753;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
	color: inherit;
	text-decoration: none;
}

code,
kbd,
pre,
samp {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas,
		Liberation Mono, Courier New, monospace;
	font-size: 1em;
}

pre {
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
	-ms-overflow-style: scrollbar;
}

figure {
	margin: 0 0 1rem;
}

img {
	border-style: none;
}

img,
svg {
	vertical-align: middle;
}

svg {
	overflow: hidden;
}

table {
	border-collapse: collapse;
}

caption {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	color: #797979;
	text-align: left;
	caption-side: bottom;
}

th {
	text-align: inherit;
	text-align: -webkit-match-parent;
}

label {
	display: inline-block;
	margin-bottom: 0.5rem;
}

button {
	border-radius: 0;
}

button:focus:not(:focus-visible) {
	outline: 0;
}

button,
input,
optgroup,
select,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

button,
input {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

[role="button"] {
	cursor: pointer;
}

select {
	word-wrap: normal;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
	-webkit-appearance: button;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
	cursor: pointer;
}

[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

input[type="checkbox"],
input[type="radio"] {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0;
}

textarea {
	overflow: auto;
	resize: vertical;
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
}

legend {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin-bottom: 0.5rem;
	font-size: 1.5rem;
	line-height: inherit;
	color: inherit;
	white-space: normal;
}

progress {
	vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto;
}

[type="search"] {
	outline-offset: -2px;
	-webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
}

output {
	display: inline-block;
}

summary {
	display: list-item;
	cursor: pointer;
}

template {
	display: none;
}

[hidden] {
	display: none !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
}

.h1,
h1 {
	font-size: 2.5rem;
}

.h2,
h2 {
	font-size: 2rem;
}

.h3,
h3 {
	font-size: 1.75rem;
}

.h4,
.h5,
.h6,
h4,
h5,
h6 {
	font-size: 1rem;
}

.lead {
	font-size: 1.25rem;
	font-weight: 300;
}

.display-1 {
	font-size: 6rem;
}

.display-1,
.display-2 {
	font-weight: 300;
	line-height: 1.2;
}

.display-2 {
	font-size: 5.5rem;
}

.display-3 {
	font-size: 4.5rem;
}

.display-3,
.display-4 {
	font-weight: 300;
	line-height: 1.2;
}

.display-4 {
	font-size: 3.5rem;
}

hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.small,
small {
	font-size: 80%;
	font-weight: 400;
}

.mark,
mark {
	padding: 0.2em;
	background-color: #fcf8e3;
}

.list-inline,
.list-unstyled {
	padding-left: 0;
	list-style: none;
}

.list-inline-item {
	display: inline-block;
}

.list-inline-item:not(:last-child) {
	margin-right: 0.5rem;
}

.initialism {
	font-size: 90%;
	text-transform: uppercase;
}

.blockquote {
	margin-bottom: 1rem;
	font-size: 1.25rem;
}

.blockquote-footer {
	display: block;
	font-size: 80%;
	color: #797979;
}

.img-fluid,
.img-thumbnail {
	max-width: 100%;
	height: auto;
}

.img-thumbnail {
	padding: 0.25rem;
	background-color: #fff;
	border: 1px solid #c5c5c5;
	border-radius: 0.25rem;
}

.figure {
	display: inline-block;
}

.figure-img {
	margin-bottom: 0.5rem;
	line-height: 1;
}

.figure-caption {
	font-size: 90%;
	color: #797979;
}

code {
	font-size: 87.5%;
	color: #e83e8c;
	word-wrap: break-word;
}

a > code {
	color: inherit;
}

kbd {
	padding: 0.2rem 0.4rem;
	font-size: 87.5%;
	color: #fff;
	background-color: #333;
	border-radius: 0.1875rem;
}

kbd kbd {
	padding: 0;
	font-size: 100%;
	font-weight: 700;
}

pre {
	display: block;
	font-size: 87.5%;
	color: #333;
}

pre code {
	font-size: inherit;
	color: inherit;
	word-break: normal;
}

.pre-scrollable {
	max-height: 340px;
	overflow-y: scroll;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

@media (min-width: 576px) {
	.container,
	.container-sm {
		max-width: 540px;
	}
}

@media (min-width: 768px) {
	.container,
	.container-md,
	.container-sm {
		max-width: 720px;
	}
}

@media (min-width: 992px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm {
		max-width: 960px;
	}
}

@media (min-width: 1200px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl {
		max-width: 1140px;
	}
}

@media (min-width: 1440px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl,
	.container-xxl {
		max-width: 1380px;
	}
}

.row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
	padding-right: 0;
	padding-left: 0;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto,
.col-xxl,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl-auto {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}

.col {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 100%;
}

.row-cols-1 > * {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%;
}

.row-cols-2 > * {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%;
}

.row-cols-3 > * {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 33.3333333333%;
	flex: 0 0 33.3333333333%;
	max-width: 33.3333333333%;
}

.row-cols-4 > * {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 25%;
	flex: 0 0 25%;
	max-width: 25%;
}

.row-cols-5 > * {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 20%;
	flex: 0 0 20%;
	max-width: 20%;
}

.row-cols-6 > * {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 16.6666666667%;
	flex: 0 0 16.6666666667%;
	max-width: 16.6666666667%;
}

.col-auto {
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
}

.col-1,
.col-auto {
	-webkit-box-flex: 0;
}

.col-1 {
	-ms-flex: 0 0 8.3333333333%;
	flex: 0 0 8.3333333333%;
	max-width: 8.3333333333%;
}

.col-2 {
	-ms-flex: 0 0 16.6666666667%;
	flex: 0 0 16.6666666667%;
	max-width: 16.6666666667%;
}

.col-2,
.col-3 {
	-webkit-box-flex: 0;
}

.col-3 {
	-ms-flex: 0 0 25%;
	flex: 0 0 25%;
	max-width: 25%;
}

.col-4 {
	-ms-flex: 0 0 33.3333333333%;
	flex: 0 0 33.3333333333%;
	max-width: 33.3333333333%;
}

.col-4,
.col-5 {
	-webkit-box-flex: 0;
}

.col-5 {
	-ms-flex: 0 0 41.6666666667%;
	flex: 0 0 41.6666666667%;
	max-width: 41.6666666667%;
}

.col-6 {
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%;
}

.col-6,
.col-7 {
	-webkit-box-flex: 0;
}

.col-7 {
	-ms-flex: 0 0 58.3333333333%;
	flex: 0 0 58.3333333333%;
	max-width: 58.3333333333%;
}

.col-8 {
	-ms-flex: 0 0 66.6666666667%;
	flex: 0 0 66.6666666667%;
	max-width: 66.6666666667%;
}

.col-8,
.col-9 {
	-webkit-box-flex: 0;
}

.col-9 {
	-ms-flex: 0 0 75%;
	flex: 0 0 75%;
	max-width: 75%;
}

.col-10 {
	-ms-flex: 0 0 83.3333333333%;
	flex: 0 0 83.3333333333%;
	max-width: 83.3333333333%;
}

.col-10,
.col-11 {
	-webkit-box-flex: 0;
}

.col-11 {
	-ms-flex: 0 0 91.6666666667%;
	flex: 0 0 91.6666666667%;
	max-width: 91.6666666667%;
}

.col-12 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%;
}

.col-form-label {
	padding-top: calc(0.375rem + 1px);
	padding-bottom: calc(0.375rem + 1px);
	margin-bottom: 0;
	font-size: inherit;
	line-height: 1.5;
}

.col-form-label-lg {
	padding-top: calc(0.5rem + 1px);
	padding-bottom: calc(0.5rem + 1px);
	font-size: 1.25rem;
	line-height: 1.5;
}

.col-form-label-sm {
	padding-top: calc(0.25rem + 1px);
	padding-bottom: calc(0.25rem + 1px);
	font-size: 0.875rem;
	line-height: 1.5;
}

.form-group {
	margin-bottom: 1rem;
}

.form-text {
	display: block;
	margin-top: 0.25rem;
}

.form-row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
	padding-right: 5px;
	padding-left: 5px;
}

.form-check {
	position: relative;
	display: block;
	padding-left: 1.25rem;
}

.form-check-input {
	position: absolute;
	margin-top: 0.3rem;
	margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
	color: #797979;
}

.form-check-label {
	margin-bottom: 0;
}

.form-check-inline {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-left: 0;
	margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
	position: static;
	margin-top: 0;
	margin-right: 0.3125rem;
	margin-left: 0;
}

.form-row > .col > .valid-tooltip,
.form-row > [class*="col-"] > .valid-tooltip {
	left: 5px;
}

.form-inline {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-flow: row wrap;
	flex-flow: row wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.form-inline .form-check {
	width: 100%;
}

.input-group {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	width: 100%;
}

.input-group-append,
.input-group-prepend {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.input-group-prepend {
	margin-right: -1px;
}

.input-group-append {
	margin-left: -1px;
}

.input-group-text {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0.375rem 0.75rem;
	margin-bottom: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #333;
	text-align: center;
	white-space: nowrap;
	background-color: #ebebeb;
	border: 1px solid #acacac;
	border-radius: 0.25rem;
}

.input-group-text input[type="checkbox"],
.input-group-text input[type="radio"] {
	margin-top: 0;
}

.card {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
}

.card > hr {
	margin-right: 0;
	margin-left: 0;
}

.card > .list-group {
	border-top: inherit;
	border-bottom: inherit;
}

.card > .list-group:first-child {
	border-top-width: 0;
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
	border-bottom-width: 0;
	border-bottom-right-radius: calc(0.25rem - 1px);
	border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
	border-top: 0;
}

.card-body {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	min-height: 1px;
	padding: 1.25rem;
}

.card-title {
	margin-bottom: 0.75rem;
}

.card-subtitle {
	margin-top: -0.375rem;
}

.card-subtitle,
.card-text:last-child {
	margin-bottom: 0;
}

.card-link:hover {
	text-decoration: none;
}

.card-link + .card-link {
	margin-left: 1.25rem;
}

.card-header {
	padding: 0.75rem 1.25rem;
	margin-bottom: 0;
	background-color: rgba(0, 0, 0, 0.03);
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
	border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
	padding: 0.75rem 1.25rem;
	background-color: rgba(0, 0, 0, 0.03);
	border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
	border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
	margin-bottom: -0.75rem;
	border-bottom: 0;
}

.card-header-pills,
.card-header-tabs {
	margin-right: -0.625rem;
	margin-left: -0.625rem;
}

.card-img-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1.25rem;
	border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom,
.card-img-top {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	width: 100%;
}

.card-img,
.card-img-top {
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
	border-bottom-right-radius: calc(0.25rem - 1px);
	border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
	margin-bottom: 15px;
}

.card-columns .card {
	margin-bottom: 0.75rem;
}

.progress {
	height: 1rem;
	line-height: 0;
	font-size: 0.75rem;
	background-color: #ebebeb;
	border-radius: 0.25rem;
}

.progress,
.progress-bar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
}

.progress-bar {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	background-color: #295e8e;
	-webkit-transition: width 0.6s ease;
	transition: width 0.6s ease;
}

.progress-bar-striped {
	background-image: linear-gradient(
		45deg,
		hsla(0, 0%, 100%, 0.15) 25%,
		transparent 0,
		transparent 50%,
		hsla(0, 0%, 100%, 0.15) 0,
		hsla(0, 0%, 100%, 0.15) 75%,
		transparent 0,
		transparent
	);
	background-size: 1rem 1rem;
}

.progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	animation: progress-bar-stripes 1s linear infinite;
}

.list-group {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	border-radius: 0.25rem;
}

.list-group-item-action {
	width: 100%;
	color: #5f5f5f;
	text-align: inherit;
}

.list-group-item-action:focus,
.list-group-item-action:hover {
	z-index: 1;
	color: #5f5f5f;
	text-decoration: none;
	background-color: #f8f8f8;
}

.list-group-item-action:active {
	color: #333;
	background-color: #ebebeb;
}

.list-group-item {
	position: relative;
	display: block;
	padding: 0.75rem 1.25rem;
	text-decoration: none;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
	border-top-left-radius: inherit;
	border-top-right-radius: inherit;
}

.list-group-item:last-child {
	border-bottom-right-radius: inherit;
	border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
	color: #797979;
	pointer-events: none;
	background-color: #fff;
}

.list-group-item.active {
	z-index: 2;
	color: #fff;
	background-color: #295e8e;
	border-color: #295e8e;
}

.list-group-item + .list-group-item {
	border-top-width: 0;
}

.list-group-item + .list-group-item.active {
	margin-top: -1px;
	border-top-width: 1px;
}

.list-group-horizontal {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
	border-bottom-left-radius: 0.25rem;
	border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
	border-top-right-radius: 0.25rem;
	border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
	margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
	border-top-width: 1px;
	border-left-width: 0;
}

.list-group-horizontal
	> .list-group-item
	+ .list-group-item.active {
	margin-left: -1px;
	border-left-width: 1px;
}

.list-group-flush {
	border-radius: 0;
}

.list-group-flush > .list-group-item {
	border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
	border-bottom-width: 0;
}

.list-group-item-primary {
	color: #15314a;
	background-color: #c3d2df;
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
	color: #15314a;
	background-color: #b2c6d6;
}

.list-group-item-primary.list-group-item-action.active {
	color: #fff;
	background-color: #15314a;
	border-color: #15314a;
}

.list-group-item-secondary {
	color: #15314a;
	background-color: #c3d2df;
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
	color: #15314a;
	background-color: #b2c6d6;
}

.list-group-item-secondary.list-group-item-action.active {
	color: #fff;
	background-color: #15314a;
	border-color: #15314a;
}

.list-group-item-success {
	color: #3a5a25;
	background-color: #d7e8cb;
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
	color: #3a5a25;
	background-color: #c9e0b9;
}

.list-group-item-success.list-group-item-action.active {
	color: #fff;
	background-color: #3a5a25;
	border-color: #3a5a25;
}

.list-group-item-info {
	color: #15314a;
	background-color: #c3d2df;
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
	color: #15314a;
	background-color: #b2c6d6;
}

.list-group-item-info.list-group-item-action.active {
	color: #fff;
	background-color: #15314a;
	border-color: #15314a;
}

.list-group-item-warning {
	color: #7b4119;
	background-color: #fadbc5;
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
	color: #7b4119;
	background-color: #f8ccae;
}

.list-group-item-warning.list-group-item-action.active {
	color: #fff;
	background-color: #7b4119;
	border-color: #7b4119;
}

.list-group-item-danger {
	color: #6b1313;
	background-color: #f1c2c2;
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
	color: #6b1313;
	background-color: #ecadad;
}

.list-group-item-danger.list-group-item-action.active {
	color: #fff;
	background-color: #6b1313;
	border-color: #6b1313;
}

.list-group-item-light {
	color: #818181;
	background-color: #fdfdfd;
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
	color: #818181;
	background-color: #f0f0f0;
}

.list-group-item-light.list-group-item-action.active {
	color: #fff;
	background-color: #818181;
	border-color: #818181;
}

.list-group-item-dark {
	color: #242424;
	background-color: #cbcbcb;
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
	color: #242424;
	background-color: #bebebe;
}

.list-group-item-dark.list-group-item-action.active {
	color: #fff;
	background-color: #242424;
	border-color: #242424;
}

.align-baseline {
	vertical-align: baseline !important;
}

.align-top {
	vertical-align: top !important;
}

.align-middle {
	vertical-align: middle !important;
}

.align-bottom {
	vertical-align: bottom !important;
}

.align-text-bottom {
	vertical-align: text-bottom !important;
}

.align-text-top {
	vertical-align: text-top !important;
}

.bg-primary {
	background-color: #295e8e !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
	background-color: #1e4466 !important;
}

.bg-secondary {
	background-color: #295e8e !important;
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
	background-color: #1e4466 !important;
}

.bg-success {
	background-color: #70ad47 !important;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
	background-color: #598938 !important;
}

.bg-info {
	background-color: #295e8e !important;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
	background-color: #1e4466 !important;
}

.bg-warning {
	background-color: #ed7d31 !important;
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
	background-color: #d86313 !important;
}

.bg-danger {
	background-color: #ce2424 !important;
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
	background-color: #a31c1c !important;
}

.bg-light {
	background-color: #f8f8f8 !important;
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
	background-color: #dfdfdf !important;
}

.bg-dark {
	background-color: #464646 !important;
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
	background-color: #2c2c2c !important;
}

.bg-white {
	background-color: #fff !important;
}

.bg-transparent {
	background-color: transparent !important;
}

.border {
	border: 1px solid #c5c5c5 !important;
}

.border-top {
	border-top: 1px solid #c5c5c5 !important;
}

.border-right {
	border-right: 1px solid #c5c5c5 !important;
}

.border-bottom:empty {
	border-bottom: 1px solid #c5c5c5 !important;
}

.border-left {
	border-left: 1px solid #c5c5c5 !important;
}

.border-0 {
	border: 0 !important;
}

.border-top-0 {
	border-top: 0 !important;
}

.border-right-0 {
	border-right: 0 !important;
}

.border-bottom-0 {
	border-bottom: 0 !important;
}

.border-left-0 {
	border-left: 0 !important;
}

.border-primary,
.border-secondary {
	border-color: #295e8e !important;
}

.border-success {
	border-color: #70ad47 !important;
}

.border-info {
	border-color: #295e8e !important;
}

.border-warning {
	border-color: #ed7d31 !important;
}

.border-danger {
	border-color: #ce2424 !important;
}

.border-light {
	border-color: #f8f8f8 !important;
}

.border-dark {
	border-color: #464646 !important;
}

.border-white {
	border-color: #fff !important;
}

.rounded-sm {
	border-radius: 0.1875rem !important;
}

.rounded {
	border-radius: 0.25rem !important;
}

.rounded-top {
	border-top-left-radius: 0.25rem !important;
}

.rounded-right,
.rounded-top {
	border-top-right-radius: 0.25rem !important;
}

.rounded-bottom,
.rounded-right {
	border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom,
.rounded-left {
	border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
	border-top-left-radius: 0.25rem !important;
}

.rounded-lg {
	border-radius: 0.3rem !important;
}

.rounded-circle {
	border-radius: 50% !important;
}

.rounded-pill {
	border-radius: 50rem !important;
}

.rounded-0 {
	border-radius: 0 !important;
}

.clearfix:after {
	display: block;
	clear: both;
	content: "";
}

.d-none {
	display: none !important;
}

.d-inline {
	display: inline !important;
}

.d-inline-block {
	display: inline-block !important;
}

.d-block {
	display: block !important;
}

.d-table {
	display: table !important;
}

.d-table-row {
	display: table-row !important;
}

.d-table-cell {
	display: table-cell !important;
}

.d-flex {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
}

.d-inline-flex {
	display: -webkit-inline-box !important;
	display: -ms-inline-flexbox !important;
	display: inline-flex !important;
}

.embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;
}

.embed-responsive:before {
	display: block;
	content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}

.embed-responsive-21by9:before {
	padding-top: 42.8571428571%;
}

.embed-responsive-16by9:before {
	padding-top: 56.25%;
}

.embed-responsive-4by3:before {
	padding-top: 75%;
}

.embed-responsive-1by1:before {
	padding-top: 100%;
}

.flex-row {
	-webkit-box-orient: horizontal !important;
	-ms-flex-direction: row !important;
	flex-direction: row !important;
}

.flex-column,
.flex-row {
	-webkit-box-direction: normal !important;
}

.flex-column {
	-webkit-box-orient: vertical !important;
	-ms-flex-direction: column !important;
	flex-direction: column !important;
}

.flex-row-reverse {
	-webkit-box-orient: horizontal !important;
	-ms-flex-direction: row-reverse !important;
	flex-direction: row-reverse !important;
}

.flex-column-reverse,
.flex-row-reverse {
	-webkit-box-direction: reverse !important;
}

.flex-column-reverse {
	-webkit-box-orient: vertical !important;
	-ms-flex-direction: column-reverse !important;
	flex-direction: column-reverse !important;
}

.flex-wrap {
	-ms-flex-wrap: wrap !important;
	flex-wrap: wrap !important;
}

.flex-nowrap {
	-ms-flex-wrap: nowrap !important;
	flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
	-ms-flex-wrap: wrap-reverse !important;
	flex-wrap: wrap-reverse !important;
}

.flex-fill {
	-webkit-box-flex: 1 !important;
	-ms-flex: 1 1 auto !important;
	flex: 1 1 auto !important;
}

.flex-grow-0 {
	-webkit-box-flex: 0 !important;
	-ms-flex-positive: 0 !important;
	flex-grow: 0 !important;
}

.flex-grow-1 {
	-webkit-box-flex: 1 !important;
	-ms-flex-positive: 1 !important;
	flex-grow: 1 !important;
}

.flex-shrink-0 {
	-ms-flex-negative: 0 !important;
	flex-shrink: 0 !important;
}

.flex-shrink-1 {
	-ms-flex-negative: 1 !important;
	flex-shrink: 1 !important;
}

.justify-content-start {
	-webkit-box-pack: start !important;
	-ms-flex-pack: start !important;
	justify-content: flex-start !important;
}

.justify-content-end {
	-webkit-box-pack: end !important;
	-ms-flex-pack: end !important;
	justify-content: flex-end !important;
}

.justify-content-center {
	-webkit-box-pack: center !important;
	-ms-flex-pack: center !important;
	justify-content: center !important;
}

.justify-content-between {
	-webkit-box-pack: justify !important;
	-ms-flex-pack: justify !important;
	justify-content: space-between !important;
}

.justify-content-around {
	-ms-flex-pack: distribute !important;
	justify-content: space-around !important;
}

.align-items-start {
	-webkit-box-align: start !important;
	-ms-flex-align: start !important;
	align-items: flex-start !important;
}

.align-items-end {
	-webkit-box-align: end !important;
	-ms-flex-align: end !important;
	align-items: flex-end !important;
}

.align-items-center {
	-webkit-box-align: center !important;
	-ms-flex-align: center !important;
	align-items: center !important;
}

.align-items-baseline {
	-webkit-box-align: baseline !important;
	-ms-flex-align: baseline !important;
	align-items: baseline !important;
}

.align-items-stretch {
	-webkit-box-align: stretch !important;
	-ms-flex-align: stretch !important;
	align-items: stretch !important;
}

.align-content-start {
	-ms-flex-line-pack: start !important;
	align-content: flex-start !important;
}

.align-content-end {
	-ms-flex-line-pack: end !important;
	align-content: flex-end !important;
}

.align-content-center {
	-ms-flex-line-pack: center !important;
	align-content: center !important;
}

.align-content-between {
	-ms-flex-line-pack: justify !important;
	align-content: space-between !important;
}

.align-content-around {
	-ms-flex-line-pack: distribute !important;
	align-content: space-around !important;
}

.align-content-stretch {
	-ms-flex-line-pack: stretch !important;
	align-content: stretch !important;
}

.align-self-auto {
	-ms-flex-item-align: auto !important;
	align-self: auto !important;
}

.align-self-start {
	-ms-flex-item-align: start !important;
	align-self: flex-start !important;
}

.align-self-end {
	-ms-flex-item-align: end !important;
	align-self: flex-end !important;
}

.align-self-center {
	-ms-flex-item-align: center !important;
	align-self: center !important;
}

.align-self-baseline {
	-ms-flex-item-align: baseline !important;
	align-self: baseline !important;
}

.align-self-stretch {
	-ms-flex-item-align: stretch !important;
	align-self: stretch !important;
}

.float-left {
	float: left !important;
}

.float-right {
	float: right !important;
}

.float-none {
	float: none !important;
}

.user-select-all {
	-webkit-user-select: all !important;
	-moz-user-select: all !important;
	-ms-user-select: all !important;
	user-select: all !important;
}

.user-select-auto {
	-webkit-user-select: auto !important;
	-moz-user-select: auto !important;
	-ms-user-select: auto !important;
	user-select: auto !important;
}

.user-select-none {
	-webkit-user-select: none !important;
	-moz-user-select: none !important;
	-ms-user-select: none !important;
	user-select: none !important;
}

.overflow-auto {
	overflow: auto !important;
}

.overflow-hidden {
	overflow: hidden !important;
}

.position-static {
	position: static !important;
}

.position-relative {
	position: relative !important;
}

.position-absolute {
	position: absolute !important;
}

.position-fixed {
	position: fixed !important;
}

.position-sticky {
	position: -webkit-sticky !important;
	position: sticky !important;
}

.fixed-top {
	top: 0;
}

.fixed-bottom,
.fixed-top {
	position: fixed;
	right: 0;
	left: 0;
	z-index: 1030;
}

.fixed-bottom {
	bottom: 0;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
	.sticky-top {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	overflow: visible;
	clip: auto;
	white-space: normal;
}

.shadow-sm {
	-webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
	-webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
	-webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

.w-25 {
	width: 25% !important;
}

.w-50 {
	width: 50% !important;
}

.w-75 {
	width: 75% !important;
}

.w-100 {
	width: 100% !important;
}

.w-auto {
	width: auto !important;
}

.h-25 {
	height: 25% !important;
}

.h-50 {
	height: 50% !important;
}

.h-75 {
	height: 75% !important;
}

.h-100 {
	height: 100% !important;
}

.h-auto {
	height: auto !important;
}

.mw-100 {
	max-width: 100% !important;
}

.mh-100 {
	max-height: 100% !important;
}

.min-vw-100 {
	min-width: 100vw !important;
}

.min-vh-100 {
	min-height: 100vh !important;
}

.vw-100 {
	width: 100vw !important;
}

.vh-100 {
	height: 100vh !important;
}

.m-0 {
	margin: 0 !important;
}

.mt-0,
.my-0 {
	margin-top: 0 !important;
}

.mr-0,
.mx-0 {
	margin-right: 0 !important;
}

.mb-0,
.my-0 {
	margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
	margin-left: 0 !important;
}

.m-1 {
	margin: 0.25rem !important;
}

.mt-1,
.my-1 {
	margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
	margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
	margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
	margin-left: 0.25rem !important;
}

.m-2 {
	margin: 0.5rem !important;
}

.mt-2,
.my-2 {
	margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
	margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
	margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
	margin-left: 0.5rem !important;
}

.m-3 {
	margin: 1rem !important;
}

.mt-3,
.my-3 {
	margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
	margin-right: 1rem !important;
}

.mb-3,
.my-3 {
	margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
	margin-left: 1rem !important;
}

.m-4 {
	margin: 1.5rem !important;
}

.mt-4,
.my-4 {
	margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
	margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
	margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
	margin-left: 1.5rem !important;
}

.m-5 {
	margin: 3rem !important;
}

.mt-5,
.my-5 {
	margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
	margin-right: 3rem !important;
}

.mb-5,
.my-5 {
	margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
	margin-left: 3rem !important;
}

.p-0 {
	padding: 0 !important;
}

.pt-0,
.py-0 {
	padding-top: 0 !important;
}

.pr-0,
.px-0 {
	padding-right: 0 !important;
}

.pb-0,
.py-0 {
	padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
	padding-left: 0 !important;
}

.p-1 {
	padding: 0.25rem !important;
}

.pt-1,
.py-1 {
	padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
	padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
	padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
	padding-left: 0.25rem !important;
}

.p-2 {
	padding: 0.5rem !important;
}

.pt-2,
.py-2 {
	padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
	padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
	padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
	padding-left: 0.5rem !important;
}

.p-3 {
	padding: 1rem !important;
}

.pt-3,
.py-3 {
	padding-top: 1rem !important;
}

.pr-3,
.px-3 {
	padding-right: 1rem !important;
}

.pb-3,
.py-3 {
	padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
	padding-left: 1rem !important;
}

.p-4 {
	padding: 1.5rem !important;
}

.pt-4,
.py-4 {
	padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
	padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
	padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
	padding-left: 1.5rem !important;
}

.p-5 {
	padding: 3rem !important;
}

.pt-5,
.py-5 {
	padding-top: 3rem !important;
}

.pr-5,
.px-5 {
	padding-right: 3rem !important;
}

.pb-5,
.py-5 {
	padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
	padding-left: 3rem !important;
}

.m-n1 {
	margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
	margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
	margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
	margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
	margin-left: -0.25rem !important;
}

.m-n2 {
	margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
	margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
	margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
	margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
	margin-left: -0.5rem !important;
}

.m-n3 {
	margin: -1rem !important;
}

.mt-n3,
.my-n3 {
	margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
	margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
	margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
	margin-left: -1rem !important;
}

.m-n4 {
	margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
	margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
	margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
	margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
	margin-left: -1.5rem !important;
}

.m-n5 {
	margin: -3rem !important;
}

.mt-n5,
.my-n5 {
	margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
	margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
	margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
	margin-left: -3rem !important;
}

.m-auto {
	margin: auto !important;
}

.mt-auto,
.my-auto {
	margin-top: auto !important;
}

.mr-auto,
.mx-auto {
	margin-right: auto !important;
}

.mb-auto,
.my-auto {
	margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
	margin-left: auto !important;
}

.stretched-link:after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	pointer-events: auto;
	content: "";
	background-color: transparent;
}

.text-monospace {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas,
		Liberation Mono, Courier New, monospace !important;
}

.text-justify {
	text-align: justify !important;
}

.text-wrap {
	white-space: normal !important;
}

.text-nowrap {
	white-space: nowrap !important;
}

.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.text-left {
	text-align: left !important;
}

.text-right {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.text-lowercase {
	text-transform: lowercase !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.font-weight-light {
	font-weight: 300 !important;
}

.font-weight-lighter {
	font-weight: lighter !important;
}

.font-weight-normal {
	font-weight: 400 !important;
}

.font-weight-bold {
	font-weight: 700 !important;
}

.font-weight-bolder {
	font-weight: bolder !important;
}

.font-italic {
	font-style: italic !important;
}

.text-white {
	color: #fff !important;
}

.text-primary {
	color: #295e8e !important;
}

a.text-primary:focus,
a.text-primary:hover {
	color: #183753 !important;
}

.text-secondary {
	color: #295e8e !important;
}

a.text-secondary:focus,
a.text-secondary:hover {
	color: #183753 !important;
}

.text-success {
	color: #70ad47 !important;
}

a.text-success:focus,
a.text-success:hover {
	color: #4d7731 !important;
}

.text-info {
	color: #295e8e !important;
}

a.text-info:focus,
a.text-info:hover {
	color: #183753 !important;
}

.text-warning {
	color: #ed7d31 !important;
}

a.text-warning:focus,
a.text-warning:hover {
	color: #c15811 !important;
}

.text-danger {
	color: #ce2424 !important;
}

a.text-danger:focus,
a.text-danger:hover {
	color: #8d1919 !important;
}

.text-light {
	color: #f8f8f8 !important;
}

a.text-light:focus,
a.text-light:hover {
	color: #d2d2d2 !important;
}

.text-dark {
	color: #464646 !important;
}

a.text-dark:focus,
a.text-dark:hover {
	color: #1f1f1f !important;
}

.text-body {
	color: #333 !important;
}

.text-muted {
	color: #797979 !important;
}

.text-black-50 {
	color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
	color: hsla(0, 0%, 100%, 0.5) !important;
}

.text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}

.text-decoration-none {
	text-decoration: none !important;
}

.text-break {
	word-break: break-word !important;
	word-wrap: break-word !important;
}

.text-reset {
	color: inherit !important;
}

.visible {
	visibility: visible !important;
}

.invisible {
	visibility: hidden !important;
}

@media print {
	*,
	:after,
	:before {
		text-shadow: none !important;
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
	}

	abbr[title]:after {
		content: " (" attr(title) ")";
	}

	pre {
		white-space: pre-wrap !important;
	}

	blockquote,
	pre {
		border: 1px solid #929292;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group;
	}

	img,
	tr {
		page-break-inside: avoid;
	}

	h2,
	h3,
	p {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}

	@page {
		size: a3;
	}

	.container,
	body {
		min-width: 992px !important;
	}

	.navbar {
		display: none;
	}

	.badge {
		border: 1px solid #000;
	}

	.table {
		border-collapse: collapse !important;
	}

	.table td,
	.table th {
		background-color: #fff !important;
	}

	.table-bordered td,
	.table-bordered th {
		border: 1px solid #c5c5c5 !important;
	}

	.table-dark {
		color: inherit;
	}

	.table-dark tbody + tbody,
	.table-dark td,
	.table-dark th,
	.table-dark thead th {
		border-color: #c5c5c5;
	}

	.table .thead-dark th {
		color: inherit;
		border-color: #c5c5c5;
	}
}

.bv-no-focus-ring:focus {
	outline: none;
}

@media (max-width: 575.98px) {
	.bv-d-xs-down-none {
		display: none !important;
	}
}

@media (max-width: 767.98px) {
	.bv-d-sm-down-none {
		display: none !important;
	}
}

@media (max-width: 991.98px) {
	.bv-d-md-down-none {
		display: none !important;
	}
}

@media (max-width: 1199.98px) {
	.bv-d-lg-down-none {
		display: none !important;
	}
}

@media (max-width: 1439.98px) {
	.bv-d-xl-down-none {
		display: none !important;
	}
}

.bv-d-xxl-down-none {
	display: none !important;
}

.form-control.focus {
	color: #333;
	background-color: #fff;
	border-color: #649ed2;
	outline: 0;
	-webkit-box-shadow: 0 0 0 0.1rem rgba(41, 94, 142, 0.25);
	box-shadow: 0 0 0 0.1rem rgba(41, 94, 142, 0.25);
}

.form-control.focus.is-valid {
	border-color: #70ad47;
	-webkit-box-shadow: 0 0 0 0.1rem rgba(112, 173, 71, 0.25);
	box-shadow: 0 0 0 0.1rem rgba(112, 173, 71, 0.25);
}

.form-control.focus.is-invalid {
	border-color: #ce2424;
	-webkit-box-shadow: 0 0 0 0.1rem rgba(206, 36, 36, 0.25);
	box-shadow: 0 0 0 0.1rem rgba(206, 36, 36, 0.25);
}

.card-img-left {
	border-top-left-radius: calc(0.25rem - 1px);
	border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-img-right {
	border-top-right-radius: calc(0.25rem - 1px);
	border-bottom-right-radius: calc(0.25rem - 1px);
}

.dropdown.dropleft .dropdown-toggle.dropdown-toggle-no-caret:before,
.dropdown:not(.dropleft)
	.dropdown-toggle.dropdown-toggle-no-caret:after {
	display: none !important;
}

.dropdown .dropdown-menu:focus {
	outline: none;
}

.b-dropdown-form {
	display: inline-block;
	padding: 0.25rem 1.5rem;
	width: 100%;
	clear: both;
	font-weight: 400;
}

.b-dropdown-form:focus {
	outline: 1px dotted !important;
	outline: 5px auto -webkit-focus-ring-color !important;
}

.b-dropdown-form.disabled,
.b-dropdown-form:disabled {
	outline: 0 !important;
	color: #929292;
	pointer-events: none;
}

.b-dropdown-text {
	display: inline-block;
	padding: 0.25rem 1.5rem;
	margin-bottom: 0;
	width: 100%;
	clear: both;
	font-weight: lighter;
}

.custom-checkbox.b-custom-control-lg,
.input-group-lg .custom-checkbox {
	font-size: 1.25rem;
	line-height: 1.5;
	padding-left: 1.875rem;
}

.custom-checkbox.b-custom-control-lg .custom-control-label:before,
.input-group-lg .custom-checkbox .custom-control-label:before {
	top: 0.3125rem;
	left: -1.875rem;
	width: 1.25rem;
	height: 1.25rem;
	border-radius: 0.3rem;
}

.custom-checkbox.b-custom-control-lg .custom-control-label:after,
.input-group-lg .custom-checkbox .custom-control-label:after {
	top: 0.3125rem;
	left: -1.875rem;
	width: 1.25rem;
	height: 1.25rem;
	background-size: 50% 50%;
}

.custom-checkbox.b-custom-control-sm,
.input-group-sm .custom-checkbox {
	font-size: 0.875rem;
	line-height: 1.5;
	padding-left: 1.3125rem;
}

.custom-checkbox.b-custom-control-sm .custom-control-label:before,
.input-group-sm .custom-checkbox .custom-control-label:before {
	top: 0.21875rem;
	left: -1.3125rem;
	width: 0.875rem;
	height: 0.875rem;
	border-radius: 0.1875rem;
}

.custom-checkbox.b-custom-control-sm .custom-control-label:after,
.input-group-sm .custom-checkbox .custom-control-label:after {
	top: 0.21875rem;
	left: -1.3125rem;
	width: 0.875rem;
	height: 0.875rem;
	background-size: 50% 50%;
}

.custom-switch.b-custom-control-lg,
.input-group-lg .custom-switch {
	padding-left: 2.8125rem;
}

.custom-switch.b-custom-control-lg .custom-control-label,
.input-group-lg .custom-switch .custom-control-label {
	font-size: 1.25rem;
	line-height: 1.5;
}

.custom-switch.b-custom-control-lg .custom-control-label:before,
.input-group-lg .custom-switch .custom-control-label:before {
	top: 0.3125rem;
	height: 1.25rem;
	left: -2.8125rem;
	width: 2.1875rem;
	border-radius: 0.625rem;
}

.custom-switch.b-custom-control-lg .custom-control-label:after,
.input-group-lg .custom-switch .custom-control-label:after {
	top: calc(0.3125rem + 2px);
	left: calc(-2.8125rem + 2px);
	width: calc(1.25rem - 4px);
	height: calc(1.25rem - 4px);
	border-radius: 0.625rem;
	background-size: 50% 50%;
}

.custom-switch.b-custom-control-lg
	.custom-control-input:checked
	~ .custom-control-label:after,
.input-group-lg
	.custom-switch
	.custom-control-input:checked
	~ .custom-control-label:after {
	-webkit-transform: translateX(0.9375rem);
	transform: translateX(0.9375rem);
}

.custom-switch.b-custom-control-sm,
.input-group-sm .custom-switch {
	padding-left: 1.96875rem;
}

.custom-switch.b-custom-control-sm .custom-control-label,
.input-group-sm .custom-switch .custom-control-label {
	font-size: 0.875rem;
	line-height: 1.5;
}

.custom-switch.b-custom-control-sm .custom-control-label:before,
.input-group-sm .custom-switch .custom-control-label:before {
	top: 0.21875rem;
	left: -1.96875rem;
	width: 1.53125rem;
	height: 0.875rem;
	border-radius: 0.4375rem;
}

.custom-switch.b-custom-control-sm .custom-control-label:after,
.input-group-sm .custom-switch .custom-control-label:after {
	top: calc(0.21875rem + 2px);
	left: calc(-1.96875rem + 2px);
	width: calc(0.875rem - 4px);
	height: calc(0.875rem - 4px);
	border-radius: 0.4375rem;
	background-size: 50% 50%;
}

.custom-switch.b-custom-control-sm
	.custom-control-input:checked
	~ .custom-control-label:after,
.input-group-sm
	.custom-switch
	.custom-control-input:checked
	~ .custom-control-label:after {
	-webkit-transform: translateX(0.65625rem);
	transform: translateX(0.65625rem);
}

.custom-file-label {
	white-space: nowrap;
	overflow-x: hidden;
}

.b-custom-control-lg.custom-file,
.b-custom-control-lg .custom-file-input,
.b-custom-control-lg .custom-file-label,
.input-group-lg.custom-file,
.input-group-lg .custom-file-input,
.input-group-lg .custom-file-label {
	font-size: 1.25rem;
	height: calc(1.5em + 1rem + 2px);
}

.b-custom-control-lg .custom-file-label,
.b-custom-control-lg .custom-file-label:after,
.input-group-lg .custom-file-label,
.input-group-lg .custom-file-label:after {
	padding: 0.5rem 1rem;
	line-height: 1.5;
}

.b-custom-control-lg .custom-file-label,
.input-group-lg .custom-file-label {
	border-radius: 0.3rem;
}

.b-custom-control-lg .custom-file-label:after,
.input-group-lg .custom-file-label:after {
	font-size: inherit;
	height: calc(1.5em + 1rem);
	border-radius: 0 0.3rem 0.3rem 0;
}

.b-custom-control-sm.custom-file,
.b-custom-control-sm .custom-file-input,
.b-custom-control-sm .custom-file-label,
.input-group-sm.custom-file,
.input-group-sm .custom-file-input,
.input-group-sm .custom-file-label {
	font-size: 0.875rem;
	height: calc(1.5em + 0.5rem + 2px);
}

.b-custom-control-sm .custom-file-label,
.b-custom-control-sm .custom-file-label:after,
.input-group-sm .custom-file-label,
.input-group-sm .custom-file-label:after {
	padding: 0.25rem 0.5rem;
	line-height: 1.5;
}

.b-custom-control-sm .custom-file-label,
.input-group-sm .custom-file-label {
	border-radius: 0.1875rem;
}

.b-custom-control-sm .custom-file-label:after,
.input-group-sm .custom-file-label:after {
	font-size: inherit;
	height: calc(1.5em + 0.5rem);
	border-radius: 0 0.1875rem 0.1875rem 0;
}

.form-control.is-invalid,
.form-control.is-valid,
.was-validated .form-control:invalid,
.was-validated .form-control:valid {
	background-position: right calc(0.375em + 0.1875rem) center;
}

input[type="color"].form-control {
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.125rem 0.25rem;
}

.input-group-sm input[type="color"].form-control,
input[type="color"].form-control.form-control-sm {
	height: calc(1.5em + 0.5rem + 2px);
	padding: 0.125rem 0.25rem;
}

.input-group-lg input[type="color"].form-control,
input[type="color"].form-control.form-control-lg {
	height: calc(1.5em + 1rem + 2px);
	padding: 0.125rem 0.25rem;
}

input[type="color"].form-control:disabled {
	background-color: #929292;
	opacity: 0.65;
}

.input-group > .custom-range {
	position: relative;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	width: 1%;
	margin-bottom: 0;
}

.input-group > .custom-file + .custom-range,
.input-group > .custom-range + .custom-file,
.input-group > .custom-range + .custom-range,
.input-group > .custom-range + .custom-select,
.input-group > .custom-range + .form-control,
.input-group > .custom-range + .form-control-plaintext,
.input-group > .custom-select + .custom-range,
.input-group > .form-control + .custom-range,
.input-group > .form-control-plaintext + .custom-range {
	margin-left: -1px;
}

.input-group > .custom-range:focus {
	z-index: 3;
}

.input-group > .custom-range:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.input-group > .custom-range:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.input-group > .custom-range {
	padding: 0 0.75rem;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #acacac;
	height: calc(1.5em + 0.75rem + 2px);
	border-radius: 0.25rem;
	-webkit-transition: border-color 0.15s ease-in-out,
		-webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out,
		-webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out,
		-webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
	.input-group > .custom-range {
		-webkit-transition: none;
		transition: none;
	}
}

.input-group > .custom-range:focus {
	color: #333;
	background-color: #fff;
	border-color: #649ed2;
	outline: 0;
	-webkit-box-shadow: 0 0 0 0.1rem rgba(41, 94, 142, 0.25);
	box-shadow: 0 0 0 0.1rem rgba(41, 94, 142, 0.25);
}

.input-group > .custom-range:disabled,
.input-group > .custom-range[readonly] {
	background-color: #ebebeb;
}

.input-group-lg > .custom-range {
	height: calc(1.5em + 1rem + 2px);
	padding: 0 1rem;
	border-radius: 0.3rem;
}

.input-group-sm > .custom-range {
	height: calc(1.5em + 0.5rem + 2px);
	padding: 0 0.5rem;
	border-radius: 0.1875rem;
}

.input-group .custom-range.is-valid,
.was-validated .input-group .custom-range:valid {
	border-color: #70ad47;
}

.input-group .custom-range.is-valid:focus,
.was-validated .input-group .custom-range:valid:focus {
	border-color: #70ad47;
	-webkit-box-shadow: 0 0 0 0.1rem rgba(112, 173, 71, 0.25);
	box-shadow: 0 0 0 0.1rem rgba(112, 173, 71, 0.25);
}

.custom-range.is-valid:focus::-webkit-slider-thumb,
.was-validated .custom-range:valid:focus::-webkit-slider-thumb {
	-webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.1rem #d0e6c1;
	box-shadow: 0 0 0 1px #fff, 0 0 0 0.1rem #d0e6c1;
}

.custom-range.is-valid:focus::-moz-range-thumb,
.was-validated .custom-range:valid:focus::-moz-range-thumb {
	box-shadow: 0 0 0 1px #fff, 0 0 0 0.1rem #d0e6c1;
}

.custom-range.is-valid:focus::-ms-thumb,
.was-validated .custom-range:valid:focus::-ms-thumb {
	box-shadow: 0 0 0 1px #fff, 0 0 0 0.1rem #d0e6c1;
}

.custom-range.is-valid::-webkit-slider-thumb,
.was-validated .custom-range:valid::-webkit-slider-thumb {
	background-color: #70ad47;
	background-image: none;
}

.custom-range.is-valid::-webkit-slider-thumb:active,
.was-validated .custom-range:valid::-webkit-slider-thumb:active {
	background-color: #d0e6c1;
	background-image: none;
}

.custom-range.is-valid::-webkit-slider-runnable-track,
.was-validated .custom-range:valid::-webkit-slider-runnable-track {
	background-color: rgba(112, 173, 71, 0.35);
}

.custom-range.is-valid::-moz-range-thumb,
.was-validated .custom-range:valid::-moz-range-thumb {
	background-color: #70ad47;
	background-image: none;
}

.custom-range.is-valid::-moz-range-thumb:active,
.was-validated .custom-range:valid::-moz-range-thumb:active {
	background-color: #d0e6c1;
	background-image: none;
}

.custom-range.is-valid::-moz-range-track,
.was-validated .custom-range:valid::-moz-range-track {
	background: rgba(112, 173, 71, 0.35);
}

.custom-range.is-valid ~ .valid-feedback,
.custom-range.is-valid ~ .valid-tooltip,
.was-validated .custom-range:valid ~ .valid-feedback,
.was-validated .custom-range:valid ~ .valid-tooltip {
	display: block;
}

.custom-range.is-valid::-ms-thumb,
.was-validated .custom-range:valid::-ms-thumb {
	background-color: #70ad47;
	background-image: none;
}

.custom-range.is-valid::-ms-thumb:active,
.was-validated .custom-range:valid::-ms-thumb:active {
	background-color: #d0e6c1;
	background-image: none;
}

.custom-range.is-valid::-ms-track-lower,
.custom-range.is-valid::-ms-track-upper,
.was-validated .custom-range:valid::-ms-track-lower,
.was-validated .custom-range:valid::-ms-track-upper {
	background: rgba(112, 173, 71, 0.35);
}

.input-group .custom-range.is-invalid,
.was-validated .input-group .custom-range:invalid {
	border-color: #ce2424;
}

.input-group .custom-range.is-invalid:focus,
.was-validated .input-group .custom-range:invalid:focus {
	border-color: #ce2424;
	-webkit-box-shadow: 0 0 0 0.1rem rgba(206, 36, 36, 0.25);
	box-shadow: 0 0 0 0.1rem rgba(206, 36, 36, 0.25);
}

.custom-range.is-invalid:focus::-webkit-slider-thumb,
.was-validated .custom-range:invalid:focus::-webkit-slider-thumb {
	-webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.1rem #f2b3b3;
	box-shadow: 0 0 0 1px #fff, 0 0 0 0.1rem #f2b3b3;
}

.custom-range.is-invalid:focus::-moz-range-thumb,
.was-validated .custom-range:invalid:focus::-moz-range-thumb {
	box-shadow: 0 0 0 1px #fff, 0 0 0 0.1rem #f2b3b3;
}

.custom-range.is-invalid:focus::-ms-thumb,
.was-validated .custom-range:invalid:focus::-ms-thumb {
	box-shadow: 0 0 0 1px #fff, 0 0 0 0.1rem #f2b3b3;
}

.custom-range.is-invalid::-webkit-slider-thumb,
.was-validated .custom-range:invalid::-webkit-slider-thumb {
	background-color: #ce2424;
	background-image: none;
}

.custom-range.is-invalid::-webkit-slider-thumb:active,
.was-validated .custom-range:invalid::-webkit-slider-thumb:active {
	background-color: #f2b3b3;
	background-image: none;
}

.custom-range.is-invalid::-webkit-slider-runnable-track,
.was-validated
	.custom-range:invalid::-webkit-slider-runnable-track {
	background-color: rgba(206, 36, 36, 0.35);
}

.custom-range.is-invalid::-moz-range-thumb,
.was-validated .custom-range:invalid::-moz-range-thumb {
	background-color: #ce2424;
	background-image: none;
}

.custom-range.is-invalid::-moz-range-thumb:active,
.was-validated .custom-range:invalid::-moz-range-thumb:active {
	background-color: #f2b3b3;
	background-image: none;
}

.custom-range.is-invalid::-moz-range-track,
.was-validated .custom-range:invalid::-moz-range-track {
	background: rgba(206, 36, 36, 0.35);
}

.custom-range.is-invalid ~ .invalid-feedback,
.custom-range.is-invalid ~ .invalid-tooltip,
.was-validated .custom-range:invalid ~ .invalid-feedback,
.was-validated .custom-range:invalid ~ .invalid-tooltip {
	display: block;
}

.custom-range.is-invalid::-ms-thumb,
.was-validated .custom-range:invalid::-ms-thumb {
	background-color: #ce2424;
	background-image: none;
}

.custom-range.is-invalid::-ms-thumb:active,
.was-validated .custom-range:invalid::-ms-thumb:active {
	background-color: #f2b3b3;
	background-image: none;
}

.custom-range.is-invalid::-ms-track-lower,
.custom-range.is-invalid::-ms-track-upper,
.was-validated .custom-range:invalid::-ms-track-lower,
.was-validated .custom-range:invalid::-ms-track-upper {
	background: rgba(206, 36, 36, 0.35);
}

.custom-radio.b-custom-control-lg,
.input-group-lg .custom-radio {
	font-size: 1.25rem;
	line-height: 1.5;
	padding-left: 1.875rem;
}

.custom-radio.b-custom-control-lg .custom-control-label:before,
.input-group-lg .custom-radio .custom-control-label:before {
	top: 0.3125rem;
	left: -1.875rem;
	width: 1.25rem;
	height: 1.25rem;
	border-radius: 50%;
}

.custom-radio.b-custom-control-lg .custom-control-label:after,
.input-group-lg .custom-radio .custom-control-label:after {
	top: 0.3125rem;
	left: -1.875rem;
	width: 1.25rem;
	height: 1.25rem;
	background: no-repeat 50%/50% 50%;
}

.custom-radio.b-custom-control-sm,
.input-group-sm .custom-radio {
	font-size: 0.875rem;
	line-height: 1.5;
	padding-left: 1.3125rem;
}

.custom-radio.b-custom-control-sm .custom-control-label:before,
.input-group-sm .custom-radio .custom-control-label:before {
	top: 0.21875rem;
	left: -1.3125rem;
	width: 0.875rem;
	height: 0.875rem;
	border-radius: 50%;
}

.custom-radio.b-custom-control-sm .custom-control-label:after,
.input-group-sm .custom-radio .custom-control-label:after {
	top: 0.21875rem;
	left: -1.3125rem;
	width: 0.875rem;
	height: 0.875rem;
	background: no-repeat 50%/50% 50%;
}

.form-control-lg .b-form-tag,
.form-control-sm .b-form-tag {
	line-height: 1.5;
}

.media-aside {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-right: 1rem;
}

.media-aside-right {
	margin-right: 0;
	margin-left: 1rem;
}

a {
	-webkit-text-decoration-color: rgba(41, 94, 142, 0.3);
	text-decoration-color: rgba(41, 94, 142, 0.3);
}

.h1,
h1 {
	font-weight: 500;
}

.h1,
.h2,
h1,
h2 {
	letter-spacing: normal;
	text-transform: none;
}

.h2,
h2 {
	font-weight: 400;
}

.h3,
h3 {
	letter-spacing: normal;
	font-weight: 300;
	text-transform: none;
}

.h4,
h4 {
	letter-spacing: 0.125rem;
	font-weight: 700;
	text-transform: uppercase;
}

.h5,
.h6,
h5,
h6 {
	letter-spacing: normal;
	font-weight: 700;
	text-transform: none;
}

.alert-danger {
	color: var(--danger);
	background-color: var(--danger-900);
	border-color: var(--danger-900);
}

.tux-input-money > .form-control,
.tux-input-number > .form-control,
.tux-input-percent > .form-control {
	padding-right: 1rem;
	padding-left: 0.75rem;
}

.tux-input-money > .input-group-append,
.tux-input-money > .input-group-prepend,
.tux-input-percent > .input-group-append,
.tux-input-percent > .input-group-prepend {
	z-index: 4;
	color: #797979;
}

.tux-input-money > .input-group-prepend > svg,
.tux-input-percent > .input-group-prepend > svg {
	margin-left: auto;
	margin-right: -9px;
	width: 0.4375em;
}

.tux-input-money > .input-group-append > svg,
.tux-input-percent > .input-group-append > svg {
	margin-left: -13px;
	width: 0.625em;
}

.tux-input-money > .form-control:not(:first-child) {
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}

.tux-input-percent:not(.has-validation)
	> .form-control:not(:last-child) {
	border-top-right-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
}

.input-group > .tux-input-money,
.input-group > .tux-input-number,
.input-group > .tux-input-percent,
.input-group > .tux-input-text {
	position: relative;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	width: 1%;
	min-width: 0;
	margin-bottom: 0;
}

.dynamic-forms-wizard {
	min-height: 60vh;
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-items: start;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.dynamic-forms-wizard-nav-container {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 20%;
	flex: 0 0 20%;
	min-width: 200px;
	position: relative;
	padding-top: 3rem;
	padding-bottom: 1.5rem;
	background-color: rgba(0, 0, 0, 0.05);
	border-right: 1px solid rgba(0, 0, 0, 0.3);
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
	font-size: 14px;
}

.dynamic-forms-wizard-page-container {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 80%;
	flex: 1 1 80%;
	position: relative;
	padding-top: 3rem;
	padding-bottom: 1.5rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.dynamic-forms-wizard-nav-item,
.dynamic-forms-wizard-nav-text {
	position: relative;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	font-weight: 700;
	z-index: 10;
	-webkit-transition: background-color 0.2s ease;
	transition: background-color 0.2s ease;
}

.dynamic-forms-wizard-nav-item {
	cursor: pointer;
}

.dynamic-forms-wizard-nav-item:not(.dynamic-forms-wizard-product-name):hover {
	background-color: var(--primary-900);
}

.dynamic-forms-wizard-active-product {
	background-color: rgba(0, 0, 0, 0.05);
}

.dynamic-forms-wizard-active-page {
	background-color: var(--primary-900);
}

.dynamic-forms-wizard-active-page:after {
	content: "";
	display: block;
	width: 4px;
	background-color: var(--primary);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: auto;
	z-index: 11;
}

.formio-dialog.formio-dialog-theme-default .formio-dialog-content {
	background: #fff;
	padding: 0;
	font-family: inherit;
	max-width: 800px;
}

.formio-dialog {
	color: #333;
}

.list-group-item {
	border: none;
	padding: 0;
}

.panel-body {
	padding: 1rem !important;
}

.panel-body .card {
	border: none !important;
	padding: 0 !important;
	margin: 0 !important;
}

.panel-body .card .panel-body {
	padding: 0 !important;
	margin: 0 !important;
}

.panel-body .card .panel-body .card-body {
	padding: 0 !important;
	margin: 0 !important;
}

.formio-component-panel .formio-component-panel > .card > .card-header {
	display: none
}

.formio-component-tabs > .card > .card-header.bg-default {
	display: block;
	font-size: 16px;
	padding: 0.75rem 1.25rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: rgba(0, 0, 0, 0.03);
	border: 1px solid #c5c5c5;
	border-radius: 3px
}

</style>
`;

export function retrieveApplicationFormPdfStyles(): string {
	return styles;
}
