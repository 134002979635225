// Taken from a newer Formio version

export function isPromise(value: any) {
	return value && value.then && typeof value.then === 'function' && Object.prototype.toString.call(value) === '[object Promise]';
}

export const waitForConditionToBeMet = (predicate: Function, waitTime = 300, maxAttempts = 100) => {
	return new Promise<void>((resolve, reject) => {
		if (predicate()) {
			resolve();
		}

		let counter = maxAttempts;

		const interval = setInterval(() => {
			if (predicate()) {
				clearInterval(interval);
				resolve();
				return;
			}

			counter--;
			if (counter <= 0) {
				clearInterval(interval);
				reject();
			}
		}, waitTime);
	});
};
