import nestedComponentForm from 'formiojs/components/_classes/nested/NestedComponent.form';

import {
	BaseEditApi,
	BaseEditConditional,
	BaseEditDisplay,
	BaseEditForm,
	BaseEditLayout,
	BaseEditLogic,
	BaseEditValidate,
	IBaseWidgetConfigurationEditFormOptions
} from '../../configuration-components/base-widget-configuration';
import { CustomComponentType } from '../../../enums';
export default function(...extend: any) {
	return nestedComponentForm(
		[
			new BaseEditDisplay().getTab({ beforeForm: true, components: { showAllowRepeat: true, showLenderOnly: true } }),
			new BaseEditForm().getTab({ ignore: true } as IBaseWidgetConfigurationEditFormOptions),
			new BaseEditLogic().getTab({ ignore: true }),
			new BaseEditApi().getTab({ ignore: true }),
			new BaseEditConditional().getTab({ beforeForm: true }),
			new BaseEditLayout().getTab({ ignore: true }),
			new BaseEditValidate().getTab({
				beforeForm: true,
				componentType: CustomComponentType.authorizedSigner
			} as IBaseWidgetConfigurationEditFormOptions)
		],
		...extend
	);
}
