import { CustomComponentLabel, CustomComponentType, FinancialSubaccountCustomComponentTypes, RelatedRoleTypes } from '../../../enums';
import { BaseEditTab, IBaseWidgetConfigurationEditTabOptions } from '.';

interface IBaseWidgetConfigurationEditValidateOptions extends IBaseWidgetConfigurationEditTabOptions {}

export class BaseEditValidate extends BaseEditTab<IBaseWidgetConfigurationEditValidateOptions> {
	public get tabKey(): string {
		return 'validate';
	}

	public get label(): string {
		return 'Validation';
	}

	public get weight(): number {
		return 30;
	}

	public defaultOptions: IBaseWidgetConfigurationEditValidateOptions = {
		beforeForm: false
	};

	public getComponents(options?: any) {
		const componentType = options.componentType as CustomComponentType;
		const componentLabel = CustomComponentLabel()[componentType];

		if (FinancialSubaccountCustomComponentTypes.includes(componentType)) {
			return this.getPfsComponents(componentLabel);
		}
		if (RelatedRoleTypes.includes(componentType)) {
			return this.getRelatedRoleComponents(componentLabel);
		}

		return this.primaryRoleComponents;
	}

	public getRelatedRoleComponents(componentLabel: string) {
		return [
			{
				key: 'validate.required',
				label: 'Required',
				tooltip: `If the "Required" checkbox is selected, there needs to be at least one ${componentLabel.toLowerCase()} 
				in the application in order to successfully submit the application. If the "Required" checkbox 
				is not selected, this ${componentLabel.toLowerCase()} widget is optional, and the user can leave the widget blank.`,
				type: 'checkbox'
			}
		];
	}

	public getPfsComponents(componentLabel: string) {
		return [
			{
				key: 'validate.minLength',
				label: `Minimum # of ${componentLabel} Cards in App`,
				type: 'number',
				tooltip: `Minimum number of ${componentLabel.toLowerCase()} entries that a user can input.`,
				input: true
			},
			{
				key: 'validate.maxLength',
				label: `Maximum # of ${componentLabel} Cards in App`,
				type: 'number',
				tooltip: `Maximum number of ${componentLabel.toLowerCase()} entries that a user can input.`,
				input: true
			}
		];
	}

	public get primaryRoleComponents() {
		return [
			{
				key: 'validate.required',
				label: 'Required',
				tooltip: `If the "Required" checkbox is selected, all of the required fields within this widget will have to be 
					completed in order for the application to be successfully submitted.
					If the "Required" checkbox is not selected, a user does not have to complete this widget for the application 
					to be successfully submitted.`,
				type: 'checkbox'
			}
		];
	}
}
