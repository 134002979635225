import { DeleteHookStrategy, DeleteHook, PreDeleteHook, PostDeleteHook } from './crud-hook-interfaces';
import { DataHandler } from './data-handler';

const noopPreHook = () => Promise.resolve();
const noopPostHook = () => Promise.resolve();

export class BasicDeleteHook implements DeleteHookStrategy {
	createHook(handler: DataHandler, preHook: PreDeleteHook = noopPreHook, postHook: PostDeleteHook = noopPostHook): DeleteHook {
		return async (submission: any, next: Function) => {
			const payload = { submission };
			try {
				await preHook(payload);
				await handler.delete(payload);
				next();
				await postHook(payload);
			} catch (err) {
				// we want to gracefully handle the error to notify formio but also output
				// 	error to console so devs can easily identify the issue
				// eslint-disable-next-line
				console.error(err);
				next(err);
				await postHook(payload, err);
			}
		};
	}
}
