import { Customer } from '@sageworks/jpi';
export function formatUrl(url: string, origin: string) {
	if (!url) {
		return undefined;
	}
	if (url.includes('?')) {
		return url + `&origin=${origin}`;
	} else {
		return url + `?origin=${origin}`;
	}
}

export function createCustomerEmailObject(customers: Customer[]): { [key: number]: string } {
	const customerEmailObject: { [key: number]: string } = {};

	customers.forEach(customer => {
		if (customer.id !== undefined) {
			customerEmailObject[customer.id] = customer.emailAddress ?? '';
		}
	});

	return customerEmailObject;
}
