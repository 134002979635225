import { LocalStorageUtils } from '@/utils';
import { LocalStorageKeys } from '@/enums';
import { ONE_MINUTE_IN_MS } from '@/utils/session-utils';
import store from '@/store';

export function isSessionActive(): boolean {
	// institution might not want timeout -> return true if so
	if (store.state.InstitutionSettings.sessionLifetimeInMinutes === 0) return true;

	let sessionLifetime = store.state.InstitutionSettings.sessionLifetimeInMinutes * ONE_MINUTE_IN_MS;
	store.dispatch.SessionActivity.pollForExpirationTime();
	const lastUserActionTimeString: string | null = LocalStorageUtils.getItem(LocalStorageKeys.lastUserActionTime);

	return lastUserActionTimeString != null && Boolean(Date.now() - Date.parse(lastUserActionTimeString) < sessionLifetime);
}

export function timeElapsedSinceLastAction(): number {
	const lastUserActionTimeString: string | null = LocalStorageUtils.getItem(LocalStorageKeys.lastUserActionTime);
	if (lastUserActionTimeString == null) {
		return -1;
	}
	const timeElapsedSinceLastAction = Date.now() - Date.parse(lastUserActionTimeString);
	return timeElapsedSinceLastAction;
}
