import { CustomComponentType } from '../../enums';
import { Customer } from '@sageworks/jpi';

export function convertEntityTypeToCustomerType(customComponentType: CustomComponentType): Customer.TypeEnum {
	switch (customComponentType) {
		case CustomComponentType.businessInfo:
			return Customer.TypeEnum.Business;
		case CustomComponentType.personalInfo:
			return Customer.TypeEnum.Person;
		case CustomComponentType.nonprofitInfo:
			return Customer.TypeEnum.NonProfit;
		case CustomComponentType.farmInfo:
			return Customer.TypeEnum.Farm;
		default:
			throw Error('Unsupported type');
	}
}

export function convertCustomerTypeToEntityType(customerType: Customer.TypeEnum): CustomComponentType {
	switch (customerType) {
		case Customer.TypeEnum.Business:
			return CustomComponentType.businessInfo;
		case Customer.TypeEnum.Person:
			return CustomComponentType.personalInfo;
		case Customer.TypeEnum.NonProfit:
			return CustomComponentType.nonprofitInfo;
		case Customer.TypeEnum.Farm:
			return CustomComponentType.farmInfo;
		default:
			throw Error('Unsupported type');
	}
}
