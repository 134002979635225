import { DataField, ObjectPropertyValues, DataFieldValue } from '@sageworks/jpi';
import { BeneficialOwnershipRoleType, CustomComponentType, CustomerCustomComponentTypes, ObjectPropertyValuesWithRoleType, TemplateDataField } from '@sageworks/dynamic-forms';
import { DateFormatting } from '@sageworks/core-logic';

export class BeneficialOwnerDataObjectUtils {

	public static prepareDataObject(
		dataObject: ObjectPropertyValuesWithRoleType,
		submission: any,
		customerId: number,
		dataFields: DataField[]
	) {
		const propName = Object.keys(submission.data).find(key => CustomerCustomComponentTypes.includes(key as CustomComponentType));
		const ownerCustomerId = submission?.data[propName!]?.data?.id;

		if (!customerId) {
			throw new Error('cannot locate customer id for parent loan role');
		} else if (propName == null) {
			throw new Error('no dataObject of the correct type');
		} else if (ownerCustomerId == null) {
			throw new Error('no customer ID is set for the Beneficial Owner');
		}

		BeneficialOwnerDataObjectUtils.setCustomerInfoToDataObjectIfNotExists(
			dataObject,
			dataFields,
			customerId,
			ownerCustomerId
		);
		BeneficialOwnerDataObjectUtils.setDefaultsToDataObjectIfNotExists(dataObject, dataFields);
	}

	public static setCustomerInfoToDataObjectIfNotExists(
		dataObject: ObjectPropertyValues,
		beneficialOwnerDataFields: DataField[],
		companyCustomerId: number,
		ownerCustomerId: number
	) {
		if (!dataObject.dataFieldValues) {
			dataObject.dataFieldValues = [];
		}

		const customerDataFields = this.dataFieldsToMap(beneficialOwnerDataFields);
		const dataFieldValues = dataObject.dataFieldValues;

		this.setDataFieldValueIfNotExists(dataFieldValues, customerDataFields.get(TemplateDataField.BeneficialOwnerCompanyCustomerId)?.id, companyCustomerId);
		this.setDataFieldValueIfNotExists(dataFieldValues, customerDataFields.get(TemplateDataField.BeneficialOwnerOwnerCustomerId)?.id, ownerCustomerId);

		return dataObject;
	}

	public static setDefaultsToDataObjectIfNotExists(dataObject: ObjectPropertyValues, beneficialOwnerDataFields: DataField[]) {
		if (!dataObject.dataFieldValues) {
			dataObject.dataFieldValues = [];
		}

		const customerDataFields = this.dataFieldsToMap(beneficialOwnerDataFields);
		const dataFieldValues = dataObject.dataFieldValues;

		this.setDataFieldValueIfNotExists(
			dataFieldValues,
			customerDataFields.get(TemplateDataField.BeneficialOwnerRoleType)?.id,
			BeneficialOwnershipRoleType.BeneficialOwner
		);
		this.setDataFieldValueIfNotExists(dataFieldValues, customerDataFields.get(TemplateDataField.BeneficialOwnerPercent)?.id, 0);
		this.setDataFieldValueIfNotExists(
			dataFieldValues,
			customerDataFields.get(TemplateDataField.BeneficialOwnerDateUpdated)?.id,
			DateFormatting.formatDateForJpi(new Date()) as any
		);

		return dataObject;
	}

	protected static setDataFieldValueIfNotExists(fieldValues: DataFieldValue[], fieldId: number | undefined, value: any) {
		const index = fieldValues.findIndex(x => x.id === fieldId);

		if (index < 0) {
			fieldValues.push({
				id: fieldId,
				value
			});
		}
	}

	protected static dataFieldsToMap(dataFields: DataField[]) {
		return dataFields.reduce((map, field) => {
			map.set(field.templateDataFieldId ?? 0, field);
			return map;
		}, new Map<number, DataField>());
	}
}
