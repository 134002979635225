import Component from 'formiojs/components/_classes/component/Component';
import { PopupWrapper } from '../popup';

export class LoadingPopupWrapper extends PopupWrapper {
	protected createPopup(component: Component, wrapperRefName: string, contents = '', refs = {}, canClosePromise?: () => Promise<void>) {
		const result = super.createPopup(component, wrapperRefName, contents, refs, canClosePromise);
		const { dialog } = result;

		// Hide close button since user should not be able to close the modal
		dialog.refs.dialogClose.style.display = 'none';

		// Center the modal
		const primaryWrapperElement = (dialog as HTMLElement).children[0];
		if (primaryWrapperElement != null) {
			primaryWrapperElement.classList.add('d-flex', 'flex-column', 'justify-content-center');
		}

		return result;
	}
}
