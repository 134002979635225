

import BaseTemplateMixin from './base-template-mixin.vue';
import { PlaidAssetReport, PlaidAssetReportData } from '@/models';


export default BaseTemplateMixin.extend({
	name: 'Plaid',
	components: {},
	computed: {
		formattedData(): PlaidAssetReport[] {
			const plaidData = this.configurationLookup?.value as PlaidAssetReportData;
			return plaidData?.assetReports ?? [];
		}
	}
});
