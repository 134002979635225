import { SubmitHookStrategy, SubmitHook, PostSubmitHook, PreSubmitHook } from './crud-hook-interfaces';
import { DataHandler, SubmitPayload } from './data-handler';

const noopPreHook = () => Promise.resolve();
const noopPostHook = () => Promise.resolve();

export class BasicSubmitHook implements SubmitHookStrategy {
	createHook(handler: DataHandler, preHook: PreSubmitHook = noopPreHook, postHook: PostSubmitHook = noopPostHook): SubmitHook {
		return async (payload: SubmitPayload, error: Function, success: Function) => {
			try {
				await preHook(payload);
				const result = await handler.submit(payload);
				success(result);
				await postHook(result);
			} catch (err) {
				// we want to gracefully handle the error to notify formio but also output
				// 	error to console so devs can easily identify the issue
				// eslint-disable-next-line
				console.error(err);
				error(err);
				await postHook(undefined, err);
			}
		};
	}
}
