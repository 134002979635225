
import Vue from 'vue';
import { Form, Displays } from 'formiojs';

const WebForm = Displays.displays.webform;

// This component was originally copied from the formio/vue-formio package
export default Vue.extend({
	props: {
		src: {
			default: '',
			required: false,
			type: String
		},
		url: {
			default: '',
			required: false,
			type: String
		},
		form: {
			default: () => {},
			required: false,
			type: Object
		},
		submission: {
			default: () => {},
			required: false,
			type: Object
		},
		language: {
			default: '',
			required: false,
			type: String
		},
		options: {
			default: () => {},
			required: false,
			type: [Function, Object]
		}
	},
	data() {
		return {
			formio: null as typeof WebForm | null
		};
	},
	watch: {
		src: {
			handler(value: string) {
				if (this.formio) {
					this.formio.src = value;
				}
			},
			immediate: true
		},
		url: {
			handler(value: string) {
				if (this.formio) {
					this.formio.url = value;
				}
			}
		},
		form: {
			handler(value: object) {
				if (this.formio) {
					this.formio.form = value;
				}
			}
		},
		submission: {
			handler(value: object) {
				if (this.formio) {
					this.formio.submission = value;
				}
			}
		},
		language: {
			handler(value: string) {
				if (this.formio) {
					this.formio.language = value;
				}
			}
		}
	},
	async mounted() {
		try {
			await this.initializeForm();
			await this.setupForm();
			this.$emit('setup-complete');
		} catch (err) {
			// swallow error for now
		}
	},
	destroyed() {
		this.formio?.destroy(true);
	},
	methods: {
		async initializeForm(): Promise<any> {
			if (this.src) {
				try {
					const form = new Form(this.$refs.formio, this.src, this.options);
					const formio = await form.ready;
					this.formio = formio;
					this.formio.src = this.src;

					this.$emit('form-rendered');
					return formio;
				} catch (err) {
					// swallow error for now
				}
			} else if (this.form) {
				try {
					const form = new Form(this.$refs.formio, this.form, this.options);
					const formio = await form.ready;
					this.formio = formio;
					this.formio.form = this.form;

					if (this.url) {
						this.formio.url = this.url;
					}

					this.$emit('form-rendered');
					return formio;
				} catch (err) {
					// swallow error for now
				}
			} else {
				// If we get to here there is no src or form
				throw new Error('Must set src or form attribute');
			}
		},
		async setupForm() {
			if (!this.formio) {
				return;
			}
			if (this.submission) {
				this.formio.setSubmission(this.submission, { noValidate: true });
				await this.formio.submissionReady;
			}

			if (this.url) {
				this.formio.url = this.url;
			}

			this.formio.language = this.language ? this.language : 'en';

			this.formio.events.onAny((...args: any[]) => {
				const eventParts = args[0].split('.');

				// Only handle formio events.
				const namespace: string = (this.options as any).namespace || 'formio';
				if (eventParts[0] !== namespace || eventParts.length !== 2) {
					return;
				}

				// Remove formio. from event.
				args[0] = eventParts[1];

				this.$emit.apply(this, args as any);

				// Emit custom events under their own name as well.
				if (eventParts[1] === 'customEvent') {
					args[0] = args[1].type;
					this.$emit.apply(this, args as any);
				}
			});
		}
	},
	render(createElement: any) {
		return createElement('div', { ref: 'formio' });
	}
});
