
import Vue from 'vue';
import { BModal, VBModal, BButton, BFormSelect, BSpinner } from 'bootstrap-vue';
import { InvestmentRealEstateAddPopupCloseEvent, InvestmentRealEstateAddPopupCloseEventType } from './close-event';
import { AutoFactory, FormioRenderDataModel, OnlinePortalRealEstatePropertiesService, RealEstateFinancialData, RealEstateProperty } from '@sageworks/jpi';
import { mapActions } from 'vuex';

export default Vue.extend({
	name: 'InvestmentRealEstateAddPopupContent',
	components: { BModal, BButton, BFormSelect, BSpinner },
	directives: { 'b-modal': VBModal },
	props: {
		customerId: Number,
		inputDataId: Number,
	},
	data() {
		return {
			isLoading: false,
			existingProperties: [] as Array<RealEstateProperty>,
			selectedPropertyId: null as number | null,
		};
	},
	computed: {
		modal(): BModal {
			return this.$refs['add-property-modal'] as BModal;
		},
	},
	methods: {
		...mapActions('MultiLoanApplicationForm', ['createNewProfitProperty']),
		showModal() {
			this.selectedPropertyId = null;
			this.modal.show();
			this.loadData();
		},
		async loadData() {
			try {
			this.isLoading = true;

			await this.loadProperties();
			} finally {
				this.isLoading = false;
			}
		},
		async addNew() {
			const property: RealEstateProperty  = await this.createNewProfitProperty({
				customerId: this.customerId,
				propertyType: 'Existing',
				name: '',
			} as RealEstateProperty);

			// Need to have this here so the widget can correctly close when called close()
			this.isLoading = false;
			this.close({ type: InvestmentRealEstateAddPopupCloseEventType.AddNew, property });
		},
		async loadProperties() {
			if (!this.customerId) {
				return;
			}

			try {
				this.isLoading = true;

				const { items } = await AutoFactory.get(OnlinePortalRealEstatePropertiesService)
					.getPaged(1, 200, [this.customerId], false, true);

				const activeRealEstateFinancials = this.getCustomerActiveRealEstateFinancials();
				this.existingProperties = (items ?? [])
					.filter(existing =>
						!activeRealEstateFinancials.find(active => active.propertyID === existing.id));
			} finally {
				this.isLoading = false;
			}
		},
		getCustomerActiveRealEstateFinancials(): Array<RealEstateFinancialData> {
			const customerId = this.customerId;
			const inputDataId = this.inputDataId;
			const renderData = (this.directStore.state as any).MultiLoanApplicationForm.renderData as FormioRenderDataModel;
			const activeRealEstateFinancials = renderData?.formData?.realEstateFinancials ?? [];

			if (!customerId || !inputDataId) return [];

			return activeRealEstateFinancials
				.filter(x => x.customerID === customerId && x.ownerInputDataID === inputDataId);
		},
		addExisting() {
			if (this.selectedPropertyId == null) {
				return;
			}

			const selectProperty = this.existingProperties.find(x => x.id === this.selectedPropertyId);

			if (!selectProperty) {
				return;
			}

			this.close({ type: InvestmentRealEstateAddPopupCloseEventType.AddExisting, property: selectProperty });
		},
		cancel() {
			this.close({ type: InvestmentRealEstateAddPopupCloseEventType.Cancel });
		},
		hide(bvModalEvent: any) {
			if (this.isLoading) {
				bvModalEvent && bvModalEvent.preventDefault();
				return;
			}
		},
		close(result: InvestmentRealEstateAddPopupCloseEvent) {
			this.$emit('close', result);
			this.modal.hide();
		}
	}
});
