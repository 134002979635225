
import BaseTemplateMixin from './base-template-mixin.vue';

export default BaseTemplateMixin.extend({
	name: 'CreditReport',
	computed: {
		message() {
			if (this.isAuthorized) {
				return `Credit Report Authorized on ${this.creditAuthDetails.authorizedDate}`;
			}

			if (this.isPulled) {
				return `Credit Report Pulled on ${this.creditAuthDetails.pullDate}`;
			}

			if (this.isWaived) {
				const waivedName = `${this.creditAuthDetails.waivedByUserFirstName} ${this.creditAuthDetails.waivedByUserLastName}`;
				return `Requirement waived by ${waivedName} on ${this.creditAuthDetails.waivedDate}`;
			}

			return '-';
		},
		creditAuthDetails() {
			return this.configurationLookup?.value as any;
		},
		isWaived() {
			return this.creditAuthDetails?.waivedByUsersID != null;
		},
		isAuthorized() {
			return this.creditAuthDetails?.usersID != null && !this.creditAuthDetails?.pullComplete;
		},
		isPulled() {
			return this.creditAuthDetails?.pullComplete;
		},
		authorizationMessageHtml() {
			return this.creditAuthDetails?.authorizationMessage;
		}
	},
	methods: {}
});
