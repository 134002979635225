import Component from 'formiojs/components/_classes/component/Component';
import _ from 'lodash';
import { formatDataFieldIdKey } from '../../components/form-components/simple-inputs/extended-field-helper';
import { isRepeaterWidget } from '../form-path-utils';
import { CustomComponentType } from '../../enums';

// eslint-disable-next-line max-lines-per-function
export function findMatchingField(component: Component, componentToFind: string, recursionCounter = 0): any {
	if (recursionCounter > 100) {
		throw Error('Recursion limit reached!');
	}
	if (componentToFind == null) return {};

	let splitComponentToFind = componentToFind.split('_');

	// Handles edge case where 'when' rule was incorrectly inserted by the builder
	if (splitComponentToFind[0] === 'undefined') {
		splitComponentToFind = splitComponentToFind.slice(1);
	}

	const key = splitComponentToFind.pop() ?? '';
	let dataFieldId;
	if (isNaN(parseInt(key))) {
		dataFieldId = key;
	} else {
		dataFieldId = formatDataFieldIdKey(key);
	}

	// Go to root level
	if (component?.parent && component?.parent?.component?.type !== splitComponentToFind[0]) {
		return findMatchingField(component.parent, componentToFind, recursionCounter + 1);
	}

	let builtPath = '';

	if (isRepeaterWidget(splitComponentToFind[0] as CustomComponentType) && component.parent != null) {
		splitComponentToFind.shift(); // Discard the widget type from the front of the path
		builtPath += 'form.data.';
	}

	splitComponentToFind.forEach(pathComponent => {
		// If we are looking for a non-repeating widget at the root, we should already be "inside" it's data object
		// So no need to include it in the path
		if (isRepeaterWidget(pathComponent as CustomComponentType)) {
			builtPath += pathComponent + '[0].form.data.';
		} else if (component.parent == null || builtPath !== '') {
			builtPath += pathComponent + '.data.';
		}
	});

	builtPath += dataFieldId;

	let dataObject: any = {};

	const foundField = _.get(component?.data, builtPath);
	if (foundField != null) {
		dataObject[componentToFind] = foundField;
	}

	return dataObject;
}
