import { EditableSelect } from '../simple-inputs/editable-select';
import { CustomComponentType } from '../../../enums';
import { createEditableSelectEditForm } from '../simple-inputs/editable-select/editable-select-form-utils';

export class VehicleStateSelect extends EditableSelect {
	static schema(...extend: any) {
		return EditableSelect.schema(
			{
				label: 'Vehicle State',
				type: CustomComponentType.vehicleStateSelect,
				key: CustomComponentType.vehicleStateSelect
			},
			...extend
		);
	}

	static get builderInfo() {
		const schema = VehicleStateSelect.schema();
		const { label } = schema;

		return {
			title: label,
			weight: 10,
			schema: schema
		};
	}

	static editForm = createEditableSelectEditForm(CustomComponentType.vehicleStateSelectOptions);
}
