import { SaveToDocLibraryFolderOption } from '@sageworks/JPI';
import { DocumentFolderOptionsGroupedByCategory } from '@/models/DocumentFoldersGroupedByCategory';

export function getFoldersGroupedByCategory(folders: SaveToDocLibraryFolderOption[]): DocumentFolderOptionsGroupedByCategory[] {
	const result = [
		{
			category: 'Customer Folders',
			folders: []
		},
		{
			category: 'General Folders',
			folders: []
		}
	] as DocumentFolderOptionsGroupedByCategory[];

	// It is important that order of folders within category
	// is maintained to keep sub-folder indentation working
	folders.forEach((f: SaveToDocLibraryFolderOption) => {
		if (f.isGeneralFolder) {
			result[1].folders.push(f);
		} else {
			result[0].folders.push(f);
		}
	});
	return result;
}

export function getDefaultFolder(folderId: number | null | undefined, groupedFolders: DocumentFolderOptionsGroupedByCategory[]) {
	for (let i = 0; i < groupedFolders.length; i++) {
		for (let k = 0; k < groupedFolders[i].folders.length; k++) {
			if (groupedFolders[i].folders[k].id === folderId) {
				return groupedFolders[i].folders[k];
			}
		}
	}
	if (groupedFolders?.length > 0 && groupedFolders[0]?.folders?.length > 0) {
		return groupedFolders[0].folders[0];
	}
	return undefined;
}
