
import BaseFormBuilder from '../base-form-builder/base-form-builder.vue';
import { FormioBuilderOptionsSchema, FormioBuilderGroupSchema } from '../../formio-interfaces/FormioBuilderOptionsSchema';
import { OnlinePortalWidgetBuilderOptionsSchema } from '../../formio-interfaces/OnlinePortalWidgetBuilderOptionsSchema';

import { FormioFormSchema } from '../../formio-interfaces/FormioFormSchema';
import { ValidationBuilder } from '../../utils/validation-builder';
import { CustomComponentType } from '../../enums/custom-component-type';

import { useComponentManager } from '../../components/component-manager';

export default BaseFormBuilder.extend({
	name: 'TemplateBuilder',
	computed: {
		builder(): FormioBuilderOptionsSchema {
			const builder: OnlinePortalWidgetBuilderOptionsSchema = {
				widgets: {
					title: 'Widgets',
					weight: 2,
					default: true
				} as FormioBuilderGroupSchema
			};

			return builder;
		}
	},
	methods: {
		validateForm(): string[] {
			if (this.form == null) {
				return [];
			}
			const form: FormioFormSchema = this.form;

			const validationBuilder = new ValidationBuilder(form.components);
			this.addPrimaryBorrowerWidgetValidation(validationBuilder);
			this.addLoansWidgetValidation(validationBuilder);
			this.addCollateralWidgetValidation(validationBuilder);
			this.addOptionalLoanRoleWidgetValidation(validationBuilder);
			return validationBuilder.buildValidations();
		},
		addPrimaryBorrowerWidgetValidation(validationBuilder: ValidationBuilder) {
			validationBuilder.validateForMaxUsages(
				1,
				{ type: CustomComponentType.primaryBorrowerEntity },
				'Template cannot be saved with multiple Primary Borrower widgets.'
			);
			validationBuilder.validateForMinUsages(
				1,
				{ type: CustomComponentType.primaryBorrowerEntity },
				'Template cannot be saved without a Primary Borrower widget.'
			);
		},
		addLoansWidgetValidation(validationBuilder: ValidationBuilder) {
			validationBuilder.validateForMaxUsages(1, { type: CustomComponentType.loans }, 'Template cannot be saved with multiple Loan Info widgets.');
		},
		addCollateralWidgetValidation(validationBuilder: ValidationBuilder) {
			validationBuilder.validateForMaxUsages(
				1,
				{ type: CustomComponentType.collateralInfo },
				'Template cannot be saved with multiple Collateral Info widgets.'
			);
		},
		addOptionalLoanRoleWidgetValidation(validationBuilder: ValidationBuilder) {
			const optionalLoanRoleWidgetTypes = [
				CustomComponentType.coBorrowerEntity,
				CustomComponentType.coSignerEntity,
				CustomComponentType.creditApplicantEntity,
				CustomComponentType.guarantorEntity
			];
			optionalLoanRoleWidgetTypes.forEach(widgetType => {
				const { componentManager } = useComponentManager();
				const widgetComponent = componentManager.CustomComponents[widgetType];
				// widget title should always be defined in our use cases, but a fallback still feels responsible
				const widgetName = (widgetComponent as any).builderInfo?.title ?? 'Optional Entity';
				const errorMessage = `Template cannot be saved with multiple ${widgetName} widgets.`;
				validationBuilder.validateForMaxUsages(1, { type: widgetType }, errorMessage);
			});
		}
	}
});
