import baseEditForm from 'formiojs/components/radio/Radio.form';
import LenderOnly from '../../configuration-components/base-widget-configuration/display/lender-only-field-checkbox';
export default function(...extend: any[]) {
	return baseEditForm(
		[
			{
				key: 'display',
				components: [LenderOnly]
			}
		],
		...extend
	);
}
