
/**
 * /health-page is accessed by external tools as an uptime monitoring URL for the Online Portal Now website.
 * This page should not be deleted.
 */

import Vue from 'vue';
import { AutoFactory, TokenService, OnlinePortalInstitutionsService } from '@sageworks/jpi';
export default Vue.extend({
	name: 'HealthPage',
	data() {
		return {
			status: ''
		};
	},
	async created() {
		try {
			const onlinePortalInstitutionsService: OnlinePortalInstitutionsService = AutoFactory.get(OnlinePortalInstitutionsService);
			await onlinePortalInstitutionsService.getBySubdomain('health-page');
		} catch (error) {
			if (error.message === 'Failed to fetch') {
				this.status += 'Cannot reach sageworks api. ';
			}
		}

		try {
			const tokenService: TokenService = AutoFactory.get(TokenService);
			await tokenService.get();
		} catch (error) {
			if (error.message === 'Failed to fetch') {
				this.status += 'Cannot reach sageworks auth. ';
			}
		}

		this.status = this.status || 'Up';
	}
});
