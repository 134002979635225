
import Vue, { PropType } from 'vue';
import { Multiselect } from 'vue-multiselect';
import { debounce} from 'lodash';
import { searchCustomerWithFuzzySearch } from '../../utils/customer-search';
import { Customer } from '@sageworks/jpi';

const MIN_SEARCH_LENGTH = 3;
const DEFAULT_DEBOUNCE = 500;

export default Vue.extend({
	name: 'CustomerSearch',
	components: {
		Multiselect
	},
	props: {
		allowedEntityTypes: {
			type: Array as PropType<Customer.TypeEnum[]>,
			default: () => []
		},
		ignoredCustomers: {
			type: Array as PropType<number[]>,
			default: () => []
		},
	},
	data() {
		return {
			isLoading: false,
			searchOptions: [] as Customer[],
			searchCounter: 0,
			selectedOption: null as Customer | null,
		};
	},
	created() {
		(this as any).onChange = debounce((searchQuery: string) => this.search(searchQuery), DEFAULT_DEBOUNCE);
	},
	methods: {
		async search(name: string) {
			// We need to track the local searchCounter and the component's searchCounter as multiple async searches could be executed
			// 	and we only want to consider updating the search options with the latest search request
			const searchCounter = (this.searchCounter = this.searchCounter + 1);
			this.isLoading = true;

			if (name.length < MIN_SEARCH_LENGTH) {
				this.isLoading = false;
				this.searchOptions = [];
				return;
			}

			try {
				const results = await searchCustomerWithFuzzySearch(name, this.allowedEntityTypes);

				// Ensure we are on the latest search request to update the search options
				if (searchCounter === this.searchCounter) {
					this.searchOptions = results.filter(x => !this.ignoredCustomers.includes(x.id ?? 0));
				}
			} finally {
				if (searchCounter === this.searchCounter) {
					this.isLoading = false;
				}
			}
		}
	}
});
