import { StoreType } from '@/store';
import { LoanApplicationMetadataModuleState } from '@/store/LoanApplicationMetadataModule';
import { MultiLoanApplicationFormModuleState } from '@/store/MultiLoanApplicationFormModule/state';
import { CustomComponentType, DataObject, FinancialStatementType, SubmitPayload, TemplateDataField } from '@sageworks/dynamic-forms';
import { DataObjectDataHandler } from '../data-object-data-handler';
import { formatDataFieldIdKey } from '@sageworks/dynamic-forms/src/components/form-components/simple-inputs/extended-field-helper';
import { getOrForgePfs } from '../financials-data-handler-utils';

export class FinancialSubaccountDataHandler extends DataObjectDataHandler {
	protected get nameDataFieldId() {
		return this._store.state.CustomComponentProperties.templateDataFieldIdToDataFieldId[TemplateDataField.SubaccountName];
	}

	protected get parentDataFieldId() {
		return this._store.state.CustomComponentProperties.templateDataFieldIdToDataFieldId[TemplateDataField.SubaccountParentField];
	}

	protected get dataObjectType() {
		return DataObject.TypeEnum.FinancialSubaccounts;
	}

	constructor(store: StoreType, componentType: CustomComponentType) {
		super(store, componentType);
	}

	private get loanMappingId() {
		const { metadata } = this._store.state.LoanApplicationMetadata as LoanApplicationMetadataModuleState;
		const { loanIndex } = this._store.state.MultiLoanApplicationForm as MultiLoanApplicationFormModuleState;

		return metadata[loanIndex].loanMappingId!;
	}

	private get templateDataFieldMappings(): { [templateDataFieldId: number]: number } {
		const keyValuePresetFields = this._store.state.LoanApplicationMetadata.presetDataFieldsByDataObject[DataObject.TypeEnum.PersonalFinancial]?.map(x => [
			x.templateDataFieldId,
			x.id
		]) as any;

		const pfsPresetDataFields = Object.fromEntries(keyValuePresetFields);

		return {
			...this._store.state.CustomComponentProperties.templateDataFieldIdToDataFieldId,
			...pfsPresetDataFields
		};
	}

	public async submit(payload: SubmitPayload): Promise<any> {
		if (this.missingValues(payload)) {
			return;
		}

		const pfsId = await getOrForgePfs(payload, this.loanMappingId, this._store);

		const pfsDataFieldId = formatDataFieldIdKey(this.templateDataFieldMappings[TemplateDataField.SubaccountFinancialDataId].toString());
		const subaccountCustomerId = formatDataFieldIdKey(this.templateDataFieldMappings[TemplateDataField.SubaccountCustomer].toString());
		const financialStatementId = formatDataFieldIdKey(this.templateDataFieldMappings[TemplateDataField.SubaccountFinancialStatement].toString());

		payload.submission.data[pfsDataFieldId] = pfsId;
		payload.submission.data[subaccountCustomerId] = payload.metadata.customerId;
		payload.submission.data[financialStatementId] = this.getFinancialStatementName(this._componentType);

		return await super.submit(payload);
	}

	protected getFinancialStatementName(widgetType: CustomComponentType): FinancialStatementType {
		switch (widgetType) {
			case CustomComponentType.income:
			case CustomComponentType.expense:
				return FinancialStatementType.IncomeExpenses;
			case CustomComponentType.stocksBondsBusinessHoldings:
			case CustomComponentType.nonInvestmentRealEstate:
			case CustomComponentType.lifeInsurance:
			case CustomComponentType.otherAssets:
				return FinancialStatementType.CurrentAssets;
			default: {
				throw Error('Widget type is not mapped to a financial statement name');
			}
		}
	}

	protected missingValues(payload: any): boolean {
		return Object.values(payload.submission.data).some(dataValue => dataValue === '');
	}
}
