import baseEditForm from 'formiojs/components/radio/Radio.form';
import ForceRequiredField from '../../configuration-components/force-required/force-required';
import {
	BaseEditDisplay,
	BaseEditForm,
	IBaseWidgetConfigurationEditFormOptions,
	BaseEditLogic,
	BaseEditApi,
	BaseEditConditional,
	BaseEditLayout,
	BaseEditData
} from '../../configuration-components/base-widget-configuration';
export default function(...extend: any) {
	return baseEditForm(
		[
			new BaseEditDisplay().getTab({ beforeForm: true }),
			new BaseEditData().getTab({ ignore: true }),
			new BaseEditForm().getTab({ ignore: true } as IBaseWidgetConfigurationEditFormOptions),
			{
				key: 'validation',
				components: ForceRequiredField
			},
			new BaseEditLogic().getTab({ beforeForm: true }),
			new BaseEditApi().getTab({ beforeForm: true }),
			new BaseEditConditional().getTab({ beforeForm: true }),
			new BaseEditLayout().getTab({ beforeForm: true })
		],
		...extend
	);
}
