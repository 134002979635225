import { SbaForm } from '@sageworks/jpi';

export const getSbaFormTitle = (formType: SbaForm.FormTypeEnum) => {
	switch (formType) {
		case SbaForm.FormTypeEnum.Form1919ApplicantBusinessInformation:
			return 'SBA Eligibility Form 1919 - Applicant Business Information';
		case SbaForm.FormTypeEnum.Form1919BusinessPrincipalInformation:
			return 'SBA Eligibility Form 1919 - Business Principal Information';
		case SbaForm.FormTypeEnum.Form1919PersonPrincipalInformation:
			return 'SBA Eligibility Form 1919 - Person Principal Information';
		case SbaForm.FormTypeEnum.Form1919BusinessInformationJan2024Revision:
			return 'SBA Eligibility Form 1919 - Business Information';
		case SbaForm.FormTypeEnum.PersonDemographicsInformation:
			return 'SBA Eligibility Form 1919 - Demographic Information';
		case SbaForm.FormTypeEnum.Form1920:
			return 'SBA Eligibility Form 1920';
		default:
			return '';
	}
};

export const getSbaFormStatusLabel = (formStatus: SbaForm.StatusEnum) => {
	switch (formStatus) {
		case SbaForm.StatusEnum.New:
			return 'New';
		case SbaForm.StatusEnum.Sent:
			return 'Sent';
		case SbaForm.StatusEnum.Approved:
			return 'Approved';
		case SbaForm.StatusEnum.Declined:
			return 'Declined';
		case SbaForm.StatusEnum.Waived:
			return 'Waived';
		case SbaForm.StatusEnum.UnderReview:
			return 'Under Review';
		default:
			return '';
	}
};

export const isFormTypeLenderOnly = (formType: SbaForm.FormTypeEnum) => {
	switch (formType) {
		case SbaForm.FormTypeEnum.Form1920:
			return true;
		default:
			return false;
	}
};

export const listToMapByCustomerId = (sbaForms: SbaForm[]) => {
	return sbaForms.reduce<{ [customerId: number]: SbaForm[] }>((map, sbaForm) => {
		const { customerId } = sbaForm;

		if (!customerId) return map;

		const customerForms = map[customerId] || [];
		customerForms.push(sbaForm);
		map[customerId] = customerForms;

		return map;
	}, {});
};
