import { defineModule } from 'direct-vuex';
import { moduleActionContext } from '@/store/index';
import {
	StaticRenderDataModel,
	OnlinePortalRenderEngineDataService,
	AutoFactory,
	LoanApplicationProposedLoanMapping,
	OnlinePortalLoanApplicationProposedLoanMappingsService
} from '@sageworks/jpi';
import { LoanRelationship, OnlinePortalLoanRelationshipsService } from '../../../JPI';

const actionContext = (context: any) => moduleActionContext(context, PDFPreviewModule);

export interface PDFPreviewModuleState {
	loanMappings: LoanApplicationProposedLoanMapping[];
	renderData: StaticRenderDataModel[];
	loanRelationships: (LoanRelationship | null)[];
}
const PDFPreviewModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			renderData: [],
			loanMappings: [],
			loanRelationships: []
		} as PDFPreviewModuleState;
	},
	mutations: {
		SET_RENDER_DATA(state, renderData: StaticRenderDataModel[]) {
			state.renderData = renderData;
		},
		SET_LOAN_MAPPINGS(state, loanMappings: LoanApplicationProposedLoanMapping[]) {
			state.loanMappings = loanMappings;
		},
		SET_LOAN_RELATIONSHIPS(state, loanRelationships: (LoanRelationship | null)[]) {
			state.loanRelationships = loanRelationships;
		}
	},
	actions: {
		async loadPdfData(context, loanApplicationId: number) {
			const { state, dispatch, rootDispatch } = actionContext(context);

			await dispatch.fetchLoanMappings(loanApplicationId);
			await Promise.all([dispatch.fetchRenderData(), dispatch.fetchLoanRelationships()]);

			// Each template in the application will have the same type, so we'll just grab it from the first one
			const applicationTemplateType = state.renderData.length > 0 ? state.renderData[0]?.applicationType : null;
			if (!applicationTemplateType) {
				return;
			}

			await rootDispatch.LoanApplicationData.fetchApplicationTermsAndCondition({ applicationTemplateType });
		},
		async fetchRenderData(context) {
			const { state, commit } = actionContext(context);
			const service = AutoFactory.get(OnlinePortalRenderEngineDataService);

			const renderData = await Promise.all(
				state.loanMappings.map((loanMapping: LoanApplicationProposedLoanMapping) => {
					return service.getStaticRenderData(loanMapping.id!);
				})
			);
			commit.SET_RENDER_DATA(renderData);
		},
		async fetchLoanMappings(context, loanApplicationId: number) {
			const { commit } = actionContext(context);
			const service = AutoFactory.get(OnlinePortalLoanApplicationProposedLoanMappingsService);
			const mappings = await service.getPaged(1, 100, loanApplicationId);

			commit.SET_LOAN_MAPPINGS(mappings.items ?? []);
		},
		async fetchLoanRelationships(context) {
			const { state, commit } = actionContext(context);
			const service = AutoFactory.get(OnlinePortalLoanRelationshipsService);
			const loanIds = state.loanMappings.map(x => x.proposedLoanId);
			const relationships: (LoanRelationship | null)[] = [];
			loanIds.forEach(async x => {
				const res = await service.getByProposedBankLoanId(x);
				relationships.push(res || null);
			});
			commit.SET_LOAN_RELATIONSHIPS(relationships);
		}
	}
});

export default PDFPreviewModule;
