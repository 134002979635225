import { CustomComponentType, FinancialSubaccountParent } from '../../../enums';
import { FinancialSubaccountSelectOptions } from './financial-subaccount-type-select-options';

export class PersonalPropertyTypeSelectOptions extends FinancialSubaccountSelectOptions {
	static schema(...extend: any) {
		return super.schema(
			{
				type: CustomComponentType.personalPropertyTypeSelectOptions,
			},
			...extend
		);
	}

	static get builderInfo() {
		return {
			weight: 10,
			schema: PersonalPropertyTypeSelectOptions.schema({})
		};
	}

	protected async getApiOptions(): Promise<any> {
		return {
			items: [
				{
					label: 'Primary Residence',
					value: FinancialSubaccountParent.Home
				},
				{
					label: 'Non-Investment Property',
					value: FinancialSubaccountParent.VacationProperties
				},
			]
		};
	}
}
