import { CustomComponentLabel, CustomComponentType } from '../../../../enums';
import { Formio } from 'formiojs';
import { detectAllowedEntityTypes } from '../../../../utils/component-finder/component-finder';

export const getAutoOpenOptions = async (primaryRoleForm: string): Promise<{ label: string; value: string }[]> => {
	const form = await Formio.makeRequest(undefined, 'form', Formio.getProjectUrl() + '/form/' + primaryRoleForm, 'get', null, undefined);
	const types = detectAllowedEntityTypes(form.components).map(c => {
		return {
			label: CustomComponentLabel()[c],
			value: c
		};
	});

	return types;
};

export const primaryRoleAutoOpenCheckbox = {
	type: CustomComponentType.extendedSelectField,
	key: 'autoOpenFormType',
	label: 'Auto Open Form Type',
	dataSrc: 'custom',
	data: {
		custom: async (context: any): Promise<{ label: string; value: string }[]> => {
			return await getAutoOpenOptions(context.instance.data.form);
		}
	},
	weight: 1050,
	tooltip: 'Once the drop down option is selected, the selected widget will automatically open in the application.',
	uniqueOptions: true
};
