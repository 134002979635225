import { CustomComponentType, FormioComponentType } from '../../../enums';
import { BaseEditDisplay } from './base.edit.display';
import { BaseEditForm } from './base.edit.form';
import { BaseEditLogic } from './base.edit.logic';
import { BaseEditApi } from './base.edit.api';
import { BaseEditConditional } from './base.edit.conditional';
import { BaseEditLayout } from './base.edit.layout';
import { IBaseWidgetConfigurationEditFormOptions } from './base.edit.form.options';
import { BaseEditValidate } from '.';

export class BaseEdit {
	public static getTabs(componentType: CustomComponentType) {
		const tabs = [
			new BaseEditDisplay().getTab({
				components: { showAllowRepeat: true, showLenderOnly: true, showAutoOpen: this.usePrimaryRoleEditDisplay(componentType) }
			}),
			new BaseEditForm().getTab({ componentType: componentType } as IBaseWidgetConfigurationEditFormOptions),
			new BaseEditLogic().getTab(),
			new BaseEditApi().getTab(),
			new BaseEditConditional().getTab(),
			new BaseEditLayout().getTab()
		];

		if (this.showValidate(componentType)) {
			tabs.push(new BaseEditValidate().getTab({ componentType: componentType } as IBaseWidgetConfigurationEditFormOptions));
		}

		return tabs;
	}

	public static getInfoWidgetTabs(componentType: CustomComponentType | FormioComponentType) {
		return [
			new BaseEditDisplay().getTab({ components: { showAllowRepeat: true, showLenderOnly: true } }),
			new BaseEditForm().getTab({ componentType: componentType } as IBaseWidgetConfigurationEditFormOptions),
			new BaseEditLogic().getTab(),
			new BaseEditApi().getTab(),
			new BaseEditConditional().getTab({ ignore: true }),
			new BaseEditLayout().getTab()
		];
	}

	public static showValidate(componentType: CustomComponentType): boolean {
		return ![CustomComponentType.primaryBorrowerEntity, CustomComponentType.loans].includes(componentType);
	}

	public static usePrimaryRoleEditDisplay(componentType: CustomComponentType): boolean {
		return [
			CustomComponentType.primaryBorrowerEntity,
			CustomComponentType.coBorrowerEntity,
			CustomComponentType.coSignerEntity,
			CustomComponentType.guarantorEntity
		].includes(componentType);
	}
}
