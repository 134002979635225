import { CustomConditionalType } from './custom-conditional-type';

export class ConditionalHelper {
	public static clearConditionals(component: any) {
		if (component == null) {
			return;
		}

		component.conditional = { show: null };
		component.customConditional = null;
	}

	public static addCustomConditional(component: any, type: CustomConditionalType) {
		if (component == null) {
			return;
		}

		switch (type) {
			case CustomConditionalType.AlwaysHidden:
				component.customConditional = 'show = false';
				return;
			case CustomConditionalType.OnlyShowIfDataContainsId:
				component.customConditional = 'var compValue = data[self.key];show = compValue && compValue.data != null && compValue.data.id != null';
				return;
			default:
				throw new Error('CustomConditionalType does not exist or not not mapped to a conditional value');
		}
	}
}
