import defaultComponents from './display/default-components';
import allowRepeatCheckboxComponents from './display/allow-repeat-checkbox';
import lenderOnlyCheckboxComponent from './display/lender-only-field-checkbox';
import { BaseEditTab } from './base.edit.tab';
import { IBaseWidgetConfigurationEditTabOptions } from './base.edit.tab.options';
import { primaryRoleAutoOpenCheckbox } from './display/primary-role-auto-open-checkbox';

export interface IBaseWidgetConfigurationEditDisplayOptions extends IBaseWidgetConfigurationEditTabOptions {
	components?: {
		showAllowRepeat?: boolean;
		showLenderOnly?: boolean;
		showAutoOpen?: boolean;
	};
}

export class BaseEditDisplay extends BaseEditTab<IBaseWidgetConfigurationEditDisplayOptions> {
	public get tabKey(): string {
		return 'display';
	}

	public get label(): string {
		return 'Display';
	}

	public get weight(): number {
		return 0;
	}

	public getComponents(customOptions: IBaseWidgetConfigurationEditDisplayOptions): any[] {
		const components = [];
		components.push(...defaultComponents);
		if (customOptions.components && customOptions.components.showAllowRepeat) {
			components.push(...allowRepeatCheckboxComponents);
		}
		if (customOptions.components && customOptions.components.showLenderOnly) {
			components.push(...[lenderOnlyCheckboxComponent]);
		}
		if (customOptions.components && customOptions.components.showAutoOpen) {
			components.push(primaryRoleAutoOpenCheckbox);
		}

		return components;
	}

	public defaultOptions: IBaseWidgetConfigurationEditDisplayOptions = {
		components: {
			showAllowRepeat: false,
			showLenderOnly: true
		},
		beforeForm: false
	};
}
