import { CustomComponentType } from '../../../../enums';
import Component from 'formiojs/components/_classes/component/Component';

export class AddNewPrimaryRole extends Component {
	private entityTypes: object[];
	private roleType: string;

	constructor(component: any, options: any, data: object) {
		super(component, options, data);
		this.entityTypes = [];
		this.initializeEntityTypes(options.entityTypes);
		this.roleType = options.roleType;
	}

	private initializeEntityTypes(types: string[]) {
		types.forEach(type => {
			switch (type) {
				case CustomComponentType.businessInfo:
					this.entityTypes.push({ key: type, label: 'Business' });
					break;
				case CustomComponentType.personalInfo:
					this.entityTypes.push({ key: type, label: 'Personal' });
					break;
				case CustomComponentType.nonprofitInfo:
					this.entityTypes.push({ key: type, label: 'Nonprofit' });
					break;
				case CustomComponentType.farmInfo:
					this.entityTypes.push({ key: type, label: 'Farm' });
					break;
				default:
					break;
			}
		});
	}

	public render(): any {
		return this.renderTemplate('addNewPrimaryRole', {
			entityTypes: this.entityTypes,
			typeTitle: this.typeTitle
		});
	}

	public async attach(element: any) {
		await super.attach(element);

		this.loadRefs(element, {
			addNewEntityType: 'multiple'
		});

		const { addNewEntityType } = this.refs as any;

		addNewEntityType.forEach((entity: HTMLElement) => {
			this.addEventListener(entity, 'click', (e: any) => this.onAddNewEntityType(e.target.id));
		});
	}

	private onAddNewEntityType = (htmlID: any) => {
		this.handleTypeSelect(htmlID);
	};

	public handleTypeSelect(customerType: string) {
		this.emit('entityTypeSelect', customerType as CustomComponentType);
	}

	private get typeTitle(): string {
		return `Select the type of ${this.roleType} to add:`;
	}
}
