module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="container">\n	<div class="row">\n		<div class="col-sm">\n			<div class="text-center">\n			</div>\n		</div>\n	</div>\n\n	<div class="row mb-2">\n		<div class="container">\n			';
 ctx.credentials.forEach( function (credential) { ;
__p += '\n			<div class="row border p-2 m-1">\n				<p class="col-sm font-weight-bold">' +
((__t = ( credential.name )) == null ? '' : __t) +
'</p>\n				<p clsas="col-sm text-center font-weight-light">' +
((__t = ( credential.pullType )) == null ? '' : __t) +
'</p>\n				<div class="col-sm text-right"><button data-credentialId="' +
((__t = (credential.id)) == null ? '' : __t) +
'" ref="selectCredentialButton" class="btn btn-primary">Select</button></div>\n			</div>\n			';
 }) ;
__p += '\n		</div>\n	</div>\n\n	<div class="row mb-2">\n		<div class="col-sm text-center">\n			<button ref="backButton" class="btn btn-primary">Back</button>\n		</div>\n	</div>\n</div>';
return __p
}