module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }


	var _helpers = ctx.options.builderTemplateHelpers || {};

	// logic helpers for the wizard header template (this will get more complex when we have more views)
	var currentViewIsForm = true;
	var dummyPageKeyPrefix = 'navigationEventDummy-';
	var hideContent = ctx.options.contextData && ctx.options.contextData.hideNavigationHeader;

	// css classes for wizard navigation template
	var wizardCss = {};
	wizardCss.navItem = 'dynamic-forms-wizard-nav-item';
	wizardCss.navText = 'dynamic-forms-wizard-nav-text';
	wizardCss.activePage = 'dynamic-forms-wizard-active-page';
	wizardCss.activeProduct = 'dynamic-forms-wizard-active-product';

	var firstPanelForEachRootPanel = {};
	var rootPanelIds = ctx.self.components.map(function(comp) {
		return comp.id;
	});
	ctx.panels.forEach(function(panel) {
		if(firstPanelForEachRootPanel[panel.rootPanelId] == null && rootPanelIds.indexOf(panel.rootPanelId) >= 0) {
			firstPanelForEachRootPanel[panel.rootPanelId] = panel.id;
		}
	});
;
__p += '\n\n<div aria-label="navigation" id="' +
((__t = ( ctx.wizardKey )) == null ? '' : __t) +
'-header" class="dynamic-forms-wizard-nav-items">\n	';
 if (ctx.options.contextData && ctx.options.contextData.productName && !hideContent) { ;
__p += '\n		';
 if (currentViewIsForm) { ;
__p += '\n			<div class="' +
((__t = (wizardCss.navText)) == null ? '' : __t) +
' ' +
((__t = (wizardCss.activeProduct)) == null ? '' : __t) +
'">' +
((__t = (ctx.options.contextData.productName)) == null ? '' : __t) +
'</div>\n		';
 } else { ;
__p += '\n			<div class="' +
((__t = (wizardCss.navItem)) == null ? '' : __t) +
'">' +
((__t = (ctx.options.contextData.productName)) == null ? '' : __t) +
'</div>\n		';
 } ;
__p += '\n	';
 } ;
__p += '\n\n	';
 if (!hideContent) { ;
__p += '\n	';
 ctx.panels.forEach(function(panel, index) {
		// detect our dummy page links
		var panelComponent = panel.component;

        var isDummyPageLink = false;
        
        if(panelComponent != null){
		    isDummyPageLink = panelComponent.key && panelComponent.key.indexOf(dummyPageKeyPrefix) === 0;
        }
	;
__p += '\n\n		';
 if (!isDummyPageLink) {
			// "normal" page links (right now, only one product, so it's always selected)
			var isProductSelected = currentViewIsForm;
			var isPageSelected = ctx.currentPage === index;

			var panelCss = 'pl-5 font-weight-normal ' + wizardCss.navItem;

			if(isProductSelected) {
				panelCss += ' ' + wizardCss.activeProduct;
			}

			if(isPageSelected) {
				panelCss += ' ' + wizardCss.activePage;
			}

			if(firstPanelForEachRootPanel[panel.rootPanelId] != panel.id) {
				panelCss += ' d-none';
			}
		;
__p += '\n			<div class="' +
((__t = (panelCss)) == null ? '' : __t) +
'"\n				ref="' +
((__t = ( ctx.wizardKey )) == null ? '' : __t) +
'-link"\n				tabindex="0">\n                ' +
((__t = ( panelComponent != null ? panelComponent.title : '' )) == null ? '' : __t) +
'\n			</div>\n		';
 } ;
__p += '\n\n		';
 if (isDummyPageLink) {
			 // (when we have view component for these, we'll need to detect when they're selected)
			 var isPageSelected = false;			 
		;
__p += '\n			<div class="' +
((__t = (wizardCss.navItem)) == null ? '' : __t) +
' ' +
((__t = ( isPageSelected ? wizardCss.activePage : '' )) == null ? '' : __t) +
'"\n				ref="' +
((__t = ( ctx.wizardKey )) == null ? '' : __t) +
'-link"\n				tabindex="0">	\n				' +
((__t = ( panelComponent != null ? panelComponent.title : '' )) == null ? '' : __t) +
'\n			</div>\n		';
 } ;
__p += '\n		\n	';
 })} ;
__p += '\n</div>\n';
return __p
}