import { FormioComponentType } from '../../../enums';
import { FormioFormDisplayType } from '../../../formio-interfaces';
import { generateHiddenRoleFieldComponents } from '../related-role-repeater-widget/create-components-helper';

export const generateFormJson = () => {
	return {
		type: FormioComponentType.Form,
		title: 'authorized signer - edit form',
		display: FormioFormDisplayType.form,
		components: [
			// Needed so the component is aware that data exists (this will prevent it from being empty and not clear out the existing data)
			...generateHiddenRoleFieldComponents()
		]
	};
};
