import { PrimaryRoleRepeaterWidget } from '../primary-role-repeater-widget/primary-role-repeater-widget';
import editForm from './primary-borrower-entity.form';
import { CustomComponentType, CustomBuilderGroups, LoanRoleType } from '../../../enums';

export default class PrimaryBorrowerEntityWidget extends PrimaryRoleRepeaterWidget {
	static schema(...extend: any) {
		return PrimaryRoleRepeaterWidget.schema(
			{
				label: 'Primary Borrower',
				type: CustomComponentType.primaryBorrowerEntity,
				key: CustomComponentType.primaryBorrowerEntity,
				addAnother: 'Add a Primary Borrower'
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: 'Primary Borrower',
			group: CustomBuilderGroups.widgets,
			weight: 10,
			schema: PrimaryBorrowerEntityWidget.schema({
				skipRemoveConfirm: true
			})
		};
	}

	init() {
		this.component.validate = this.component.validate ?? {};
		this.roleType = LoanRoleType.PrimaryBorrower;

		// Force widget to only allow a single row
		this.component.allowRepeat = false;
		this.component.validate.maxLength = 1;
		this.component.validate.required = true;

		super.init();
	}
}
