import Component from 'formiojs/components/_classes/component/Component';
import { CustomComponentType } from '../../enums';
import formDataLoanRoleHelpers from './form-data-loan-role-helpers';

const getApplicationEntities = async function(component: Component, isPrimaryRole?: boolean, allowedEntityTypes?: CustomComponentType[]) {
	let root = component;
	while (root.parent != null && root.id !== root.parent.id) {
		root = root.parent;
	}

	const rootData = root.data;
	const rootComponents = root.components;
	const currentParentCustomerId = component.data.id;

	const applicationEntities: { value: string; label: string }[] = [];
	await formDataLoanRoleHelpers.addRoleEntitiesToApplicationEntities(
		applicationEntities,
		rootData,
		rootComponents,
		currentParentCustomerId,
		isPrimaryRole,
		allowedEntityTypes
	);

	const distinctApplicationEntities = applicationEntities.filter((value, index, self) => self.map(entity => entity.value).indexOf(value.value) === index);
	return distinctApplicationEntities;
};

export default {
	getApplicationEntities
};
