import EditGridComponent from 'formiojs/components/editgrid/EditGrid';
import FormComponent from 'formiojs/components/form/Form';
import { Utils as FormioUtils } from 'formiojs';
import { configureLinkTemplate, configureLinkBehavior } from '../edit-link-helper';
import { formatDataFieldIdKey, checkConditionalityAcrossForms, shouldComponentUseOriginalConditionality } from '../../simple-inputs/extended-field-helper';
import { TemplateDataField, CustomOnDeleteHookName, FormioComponentType, EditRowState, FormioEventName, CustomerCustomComponentTypes } from '../../../../enums';
import { isInPreviewMode } from '../../../../utils/formio-template-helpers/builder-template-helpers';
import { FormioComponentSchema, FormioValidationSchema, FormioFormType } from '../../../../formio-interfaces';
import ModifiedFormComponent from '../../formio-components/form/modified-form-component';
import Component from 'formiojs/components/_classes/component/Component';
import {
	getPersonalInfoDescription,
	getBusinessInfoDescription,
	getNonProfitInfoDescription,
	getFarmInfoDescription
} from '../../../../utils/edit-grid-helpers';
import { FormioConditionalityUtils } from '../../../../utils/formio-conditionality-utils';
import { EncodingHelper } from '@sageworks/core-logic';
import { LocateParentHelper } from '../../../../utils';

export default class BaseRepeaterWidget extends EditGridComponent {
	private _formComponent: FormComponent | null = null;

	protected readonly rowDescriptionError = 'Invalid configuration found';
	protected showEditGridNav = true;
	public readonly encodeHtml = EncodingHelper.encodeHtml;

	static schema(...extend: any) {
		return EditGridComponent.schema(
			{
				form: '',
				formPath: '',
				formJson: null,
				allowRepeat: false,
				rowDrafts: true,
				validate: {
					maxLength: 1
				}
			},
			...extend
		);
	}

	/*
		Override group in the top-most component possible
		Doing so here has side-effects if we try to register components in component manager for testing
	*/
	static get builderInfo() {
		return {
			group: '',
			schema: BaseRepeaterWidget.schema()
		};
	}

	// @ts-ignore
	get key() {
		return this.component.type;
	}

	get isInPreviewMode() {
		return isInPreviewMode(this);
	}

	get submitMetadata() {
		return {};
	}

	get componentComponents(): FormioComponentSchema<any, FormioValidationSchema>[] {
		const { label, form, formPath, formJson } = this.component;

		return [
			{
				label,
				form,
				formPath,
				formJson,
				hideTopLevelPanelBorderWrapper: true,
				tableView: true,
				type: FormioFormType.form,
				submitMetadata: this.submitMetadata,
				input: true,
				key: 'form',
				calculateServer: false,
				reference: false,
				keyModified: true
			}
		];
	}

	get headerTemplate() {
		return '';
	}

	protected get autoOpenEnabled() {
		return this.component.autoOpenFormType?.value != null;
	}

	rowDescription(row: any, rowIndex: number): string {
		try {
			if (!this.rowDescriptionSubformLoaded(rowIndex)) {
				return 'Loading...';
			}

			return this.rowDescriptionGetEntityDescription(row);
		} catch (error) {
			return 'Unable to load preview';
		}
	}

	// eslint-disable-next-line max-lines-per-function
	getDescriptionKeys(): { [key: string]: string } {
		let descriptionKeys = {
			personalFirstNameKey: '',
			personalLastNameKey: '',
			businessNameKey: '',
			nonprofitNameKey: '',
			farmNameKey: '',
			collateralDescriptionKey: ''
		};
		const releventTemplateDataFields = [
			TemplateDataField.PersonalFirstName,
			TemplateDataField.PersonalLastName,
			TemplateDataField.BusinessName,
			TemplateDataField.NonprofitName,
			TemplateDataField.FarmName,
			TemplateDataField.CollateralDescription
		];
		releventTemplateDataFields.forEach((templateDataField: TemplateDataField) => {
			const dataFieldId = this.options?.contextData?.templateDataFieldMappings?.[templateDataField];

			if (dataFieldId == null) {
				return;
			}

			switch (templateDataField) {
				case TemplateDataField.PersonalFirstName:
					descriptionKeys.personalFirstNameKey = formatDataFieldIdKey(dataFieldId);
					break;
				case TemplateDataField.PersonalLastName:
					descriptionKeys.personalLastNameKey = formatDataFieldIdKey(dataFieldId);
					break;
				case TemplateDataField.FarmName:
					descriptionKeys.farmNameKey = formatDataFieldIdKey(dataFieldId);
					break;
				case TemplateDataField.BusinessName:
					descriptionKeys.businessNameKey = formatDataFieldIdKey(dataFieldId);
					descriptionKeys.nonprofitNameKey = descriptionKeys.businessNameKey;
					break;
				case TemplateDataField.CollateralDescription:
					descriptionKeys.collateralDescriptionKey = formatDataFieldIdKey(dataFieldId);
					break;
			}
		});

		return descriptionKeys;
	}

	rowDescriptionSubformLoaded(rowIndex: number): boolean {
		const editRow = this.editRows[rowIndex];

		if (!editRow || (editRow?.components?.length ?? 0) <= 0) {
			return false;
		}

		const componentsFound = FormioUtils.searchComponents(editRow.components, { type: FormioComponentType.Form });

		if (componentsFound.length <= 0) {
			return false;
		}

		const form = componentsFound[0] as ModifiedFormComponent;
		if (form?.subForm == null) {
			form?.subFormReady?.then(() => form.subForm && this.redraw());
			return false;
		}

		return true;
	}

	rowDescriptionGetEntityDescription(row: any): string {
		const { personalFirstNameKey, personalLastNameKey, businessNameKey, nonprofitNameKey, farmNameKey } = this.getDescriptionKeys();

		const description =
			this.getPersonalInfoDescription(row, personalFirstNameKey, personalLastNameKey) ??
			this.getBusinessInfoDescription(row, businessNameKey) ??
			this.getNonProfitInfoDescription(row, nonprofitNameKey) ??
			this.getFarmInfoDescription(row, farmNameKey);

		if (description == null) {
			throw new Error(this.rowDescriptionError);
		}

		return description;
	}

	rowGetEntityId(row: any): string {
		const componentTypeContainingData = CustomerCustomComponentTypes.find(x => row.data[x]?.data?.id != null);

		if (!componentTypeContainingData) {
			throw new Error(this.rowDescriptionError);
		}

		return row.data[componentTypeContainingData].data.id;
	}

	get rowTemplate() {
		return `<div class="row d-flex align-items-center">
					<div class="col">
						<span>{{ instance.encodeHtml(instance.rowDescription(row.form, rowIndex)) }}</span>
					</div>
				{% if (!instance.disabled) { %}
					<div class="col-auto">
					<div class="btn-group pull-right">
						<button class="btn btn-sm btn-primary editRow"><i aria-label="Edit row" class="{{ iconClass('edit') }}"></i>Edit</button>
						{% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}
						<button class="btn btn-danger btn-sm removeRow"><i  aria-label="Remove row" class="{{ iconClass('trash') }}"></i></button>
						{% } %}
					</div>
					</div>
				{% } %}
				</div>
		`;
	}

	init() {
		// Needed so when rendering the component, it doesn't add an input field label
		this.noField = true;
		this.component.components = this.componentComponents;
		this.component.templates.row = this.rowTemplate;
		this.component.templates.header = this.headerTemplate;
		this.component.rowDrafts = true;
		this.component.removeRow = null;
		this.type = this.component.type;
		this.on(FormioEventName.nestedWizardPageChange, (payload: any) => this.onSubWizardPageChanged(payload.parentWidgetId, false), false);
		this.on(FormioEventName.nestedWizardSaveAndClose, (payload: any) => this.onSubWizardPageChanged(payload.parentWidgetId, true), false);
		this.on(FormioEventName.rootWizardSaveOnPageChange, (payload: any) => this.onSubWizardPageChanged(payload.parentWidgetId, true, payload.pageId), false);
		return super.init();
	}

	render(children: any, topLevel?: boolean): string {
		if (this.builderMode) {
			return configureLinkTemplate(this);
		} else if (this.isInPreviewMode) {
			// Need to cache the created form component since it is needed in DOM attachment
			this._formComponent = this.createComponent(this.componentComponents[0], this.options, {}, null);
			return super.render(this._formComponent?.render());
		}

		return super.render(children, topLevel);
	}

	attach(element: any): Promise<void> {
		this.loadRefs(element, {
			editGridActions: 'single'
		});

		return super.attach(element).then(() => {
			if (this.builderMode) {
				configureLinkBehavior(this, element);
			} else if (this.isInPreviewMode) {
				return this._formComponent?.attach(element);
			}
		});
	}

	async editRow(rowIndex: number) {
		await super.editRow(rowIndex);

		const editRow = this.editRows[rowIndex];
		this.checkConditions(this.data, {}, editRow.data);
	}

	renderRow(row: any, rowIndex: number) {
		if (this.options.readOnly) {
			row.state = 'editing';
		}

		return super.renderRow(row, rowIndex);
	}

	saveRow(rowIndex: number, modified?: boolean) {
		this.setPristine(false);
		const isNew = this.editRows[rowIndex].state === EditRowState.New;

		const savedToGridData = super.saveRow(rowIndex, modified);

		if (savedToGridData && this.shouldPartialSaveOnSaveRow(rowIndex, isNew)) {
			this.partialSaveRow(rowIndex);
		}

		return savedToGridData;
	}

	protected async partialSaveRow(rowIndex: number) {
		const loader = this.createLoadingSpinner();
		this.rowElements[rowIndex].appendChild(loader);

		await this.partialSave(rowIndex);
		this.removeChildFrom(loader, this.rowElements[rowIndex]);
	}

	protected async partialSave(rowIndex: number) {
		return Promise.all((this.iteratableRows[rowIndex].components as ModifiedFormComponent[]).map(component => component?.savePartial()));
	}

	conditionallyVisible(data: any): boolean {
		if (shouldComponentUseOriginalConditionality(this)) {
			return super.conditionallyVisible(data);
		}

		return checkConditionalityAcrossForms(this);
	}

	checkCondition(row: any, data: any) {
		return FormioConditionalityUtils.checkCondition(
			this.component,
			row || this.data,
			data || this.rootValue,
			this.root ? (this.root as any)._form : {},
			this
		);
	}

	removeRow(rowIndex: number): void {
		if (this.options.readOnly) {
			return;
		}

		const editRow = this.editRows[rowIndex];

		const loader = this.createLoadingSpinner();
		this.rowElements[rowIndex].appendChild(loader);

		this.hook(CustomOnDeleteHookName(this.component.type), editRow?.data, (errorMessage: string) => {
			this.removeChildFrom(loader, this.rowElements[rowIndex]);

			if (errorMessage != null) {
				this.setCustomValidity(errorMessage, true);
				return;
			}

			super.removeRow(rowIndex);
		});
	}

	protected shouldPartialSaveOnSaveRow(_rowIndex: number, _isNew: boolean) {
		return true;
	}

	protected createLoadingSpinner() {
		const spinner = this.ce('div', { class: 'loader text-center' });
		return this.ce('div', { class: 'loader-wrapper' }, spinner);
	}

	protected resetRowComponents() {
		this.editRows.forEach((editRow, rowIndex) => {
			editRow.components = this.createRowComponents(editRow.data, rowIndex);
		});
	}

	protected getPersonalInfoDescription(row: any, firstNameKey: string, lastNameKey: string) {
		return getPersonalInfoDescription(row, firstNameKey, lastNameKey);
	}

	protected getBusinessInfoDescription(row: any, businessNameKey: string) {
		return getBusinessInfoDescription(row, businessNameKey);
	}

	protected getNonProfitInfoDescription(row: any, nonprofitNameKey: string) {
		return getNonProfitInfoDescription(row, nonprofitNameKey);
	}

	protected getFarmInfoDescription(row: any, farmNameKey: string) {
		return getFarmInfoDescription(row, farmNameKey);
	}

	isOpen(editRow: any): boolean {
		// When validating, all rows need to render to allow for validating
		if (this.options.validationMode) {
			return true;
		}
		return super.isOpen(editRow);
	}

	// Copied from Formio with changes to not skip certain steps when in validation mode
	// eslint-disable-next-line max-lines-per-function
	public checkComponentValidity(data: any, dirty: boolean, row: any, options: any) {
		// Begin custom modifications to copied form.io code
		const superValid = Component.prototype.checkComponentValidity.bind(this)(data, dirty, row, options);
		// End custom modifications to copied form.io code

		// If super tells us that component invalid and there is no need to update alerts, just return false
		if (!superValid && (!this.alert || !this.hasOpenRows())) {
			return false;
		}

		if (this.shouldSkipValidation(data, dirty, row)) {
			return true;
		}

		let rowsValid = true;
		let rowsEditing = false;

		this.editRows.forEach((editRow, index) => {
			// Trigger all errors on the row.
			const rowValid = this.validateRow(editRow, editRow.alerts || dirty);

			rowsValid = rowsValid && rowValid;

			if (this.rowRefs as any) {
				const rowContainer = this.rowRefs[index];

				if (rowContainer) {
					const errorContainer = rowContainer.querySelector('.editgrid-row-error');

					if (!rowValid && errorContainer !== null) {
						errorContainer.textContent = this.t('invalidRowError');
					}
				}
			}
			// If this is a dirty check, and any rows are still editing, we need to throw validation error.
			// Begin custom modifications to copied form.io code
			rowsEditing = !this.options.validationMode && dirty && this.isOpen(editRow);
			// End custom modifications to copied form.io code
		});

		if (!rowsValid) {
			if (!this.hasOpenRows()) {
				this.setCustomValidity(this.t('invalidRowsError'), dirty);
			}
			return false;
		} else if (rowsEditing && this.saveEditMode) {
			this.setCustomValidity(this.t('unsavedRowsError'), dirty);
			return false;
		}

		const message = this.invalid || this.invalidMessage(data, dirty);
		this.setCustomValidity(message, dirty);

		return superValid;
	}

	protected onSubWizardPageChanged(parentWidgetId: string, closeRow: boolean, pageId?: number) {
		if (this.id !== parentWidgetId && !LocateParentHelper.locateClosestParent(this, x => x.id === pageId)) return;
		const rowIndex = this.editRows.findIndex(r => r.state === EditRowState.Editing || r.state === EditRowState.New);
		if (rowIndex < 0) return;

		this.setPristine(false);
		closeRow ? this.saveRow(rowIndex) : this.partialSave(rowIndex);
	}

	protected emitEditEvent() {
		this.emit(FormioEventName.hideParentNav, {});
	}

	protected emitEditEndEvent() {
		this.emit(FormioEventName.showParentNav, {});
	}

	protected toggleEditGridNavigation(show: boolean) {
		show ? this.removeClass(this.refs.editGridActions, 'd-none') : this.addClass(this.refs.editGridActions, 'd-none');
	}

	// New functionality from Formio
	protected createRow(dataObj: any, rowIndex: number) {
		const editRow = {
			components: this.createRowComponents(dataObj, rowIndex),
			data: dataObj,
			state: EditRowState.New,
			backup: null,
			error: null,
			rowIndex
		};

		this.editRows.push(editRow);

		return editRow;
	}
}
