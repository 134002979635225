import { Utils as FormioUtils } from 'formiojs';
import { FormioComponentSchema, FormioValidationSchema } from '../formio-interfaces/FormioComponentSchema';
import _ from 'lodash';

export class ValidationBuilder {
	private validations: string[] = [];
	constructor(private allComponents?: FormioComponentSchema<any, FormioValidationSchema>[]) {}

	public buildValidations() {
		return this.validations;
	}

	public validateForExactUsages(usages: number, query: any, message: string) {
		if (usages < 0) {
			return this;
		}
		const matchingComponents = this.searchComponents(query);
		if (matchingComponents.length !== usages) {
			this.validations.push(message);
		}
		return this;
	}

	public validateForMaxUsages(maximum: number, query: any, message: string) {
		if (maximum < 0) {
			return this;
		}
		const matchingComponents = this.searchComponents(query);
		if (matchingComponents.length > maximum) {
			this.validations.push(message);
		}
		return this;
	}

	public validateForMinUsages(minimum: number, query: any, message: string) {
		if (minimum < 0) {
			return this;
		}
		const matchingComponents = this.searchComponents(query);
		if (matchingComponents.length < minimum) {
			this.validations.push(message);
		}
		return this;
	}

	private searchComponents(query: any): any[] {
		if (this.allComponents == null) {
			return [];
		}

		const results: any[] = [];
		FormioUtils.eachComponent(
			this.allComponents,
			(component: any) => {
				if (this.matchComponent(component, query)) {
					results.push(component);
				}
			},
			true
		);

		return results;
	}

	// Modified version from formio
	private matchComponent(component: any, query: any) {
		if (_.isString(query)) {
			return component.key === query || component.path === query;
		} else {
			let matches = false;
			_.forOwn(query, (value, key) => {
				/* 
					If 'key' is the literally the component key do a fuzzy match 
					that ignores extra characters formio might insert into the key 
					when duplicate fields are present in a form
				*/
				if (key === 'key' && component.key != null) {
					matches = (component.key as string).includes(value);
				} else {
					matches = _.get(component, key) === value;
				}

				// Short-circuit here since all values in the query need to be matched
				if (!matches) {
					return false;
				}
			});
			return matches;
		}
	}
}
