
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class LenderViewAlert extends Vue {
	get showBanner() {
		return this.directStore.getters.User.isLender && !!this.directStore.state.User.user;
	}

	get borrowerEmailLink() {
		return 'mailto:' + (this.directStore.state.User.user!.email || '');
	}

	get userIdentifier() {
		return this.directStore.getters.User.fullName !== '' ? this.directStore.getters.User.fullName : this.directStore.state.User.user!.email;
	}
}
