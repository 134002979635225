
import BaseTemplateMixin from './base-template-mixin.vue';

export default BaseTemplateMixin.extend({
	name: 'Columns',
	components: {},
	methods: {
		getColumnClasses(column: any): string {
			// eslint-disable-next-line max-len
			return `col-${column.size}-${column.width} col-${column.size}-offset-${column.offset} col-${column.size}-push-${column.push} col-${column.size}-pull-${column.pull}`;
		}
	}
});
