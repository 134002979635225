import EditGridComponent from 'formiojs/components/editgrid/EditGrid';
import { LocateParentHelper } from '../locate-parent-helper/locate-parent-helper';
import { Utils as FormioUtils } from 'formiojs';
import {CustomComponentType} from '../../enums';

const FormioEditGridType = 'editgrid';

export function containsOpenEditGrid(component: any): boolean {
	if (!component || !component.components) {
		return false;
	}
	const editGridComponents: EditGridComponent[] = FormioUtils.searchComponents(component.components, { type: FormioEditGridType });
	return editGridComponents && editGridComponents.some(c => c.hasOpenRows());
}

export function isNestedWithinOpenEditGrid(nestedComponent: any) {
	const editGridComponent = LocateParentHelper.locateClosestParent(nestedComponent, (c: any) => c.type === FormioEditGridType) as
		| EditGridComponent
		| undefined;
	return editGridComponent && editGridComponent.hasOpenRows();
}

export function getPersonalInfoDescription(row: any, firstNameKey: string, lastNameKey: string): string | null {
	const firstName = getFieldValueFromData(row, firstNameKey, CustomComponentType.personalInfo);
	const lastName = getFieldValueFromData(row, lastNameKey, CustomComponentType.personalInfo);

	return firstName == null || lastNameKey == null
		? null
		: `${firstName} ${lastName}`;
}

export function getBusinessInfoDescription(row: any, businessNameKey: string): string | null {
	return getFieldValueFromData(row, businessNameKey, CustomComponentType.businessInfo);
}

export function getNonProfitInfoDescription(row: any, nonprofitNameKey: string): string | null {
	return getFieldValueFromData(row, nonprofitNameKey, CustomComponentType.nonprofitInfo);
}

export function getFarmInfoDescription(row: any, farmNameKey: string): string | null {
	return getFieldValueFromData(row, farmNameKey, CustomComponentType.farmInfo);
}

export function getFieldValueFromData(row: any, fieldKey: string, componentType: CustomComponentType): string | null {
	if ((fieldKey ?? '').length === 0) {
		return null;
	}

	const data = row.data?.[componentType]?.data;
	if (data?.id == null) return null;

	const fieldValue = data[fieldKey];

	return fieldValue != null ? fieldValue : null;
}