import SelectComponent from 'formiojs/components/select/Select';
import { CustomComponentType } from '../../../enums';
import { EditableSelect } from '../simple-inputs/editable-select';
import { createEditableSelectEditForm } from '../simple-inputs/editable-select/editable-select-form-utils';

export default class HmdaLoanPurposeSelect extends EditableSelect {
	static schema(...extend: any) {
		return SelectComponent.schema(
			{
				label: 'HMDA Loan Purpose',
				type: CustomComponentType.hmdaLoanPurposeSelect,
				key: CustomComponentType.hmdaLoanPurposeSelect
			},
			...extend
		);
	}

	static get builderInfo() {
		const schema = HmdaLoanPurposeSelect.schema();
		const { label } = schema;

		return {
			title: label,
			weight: 10,
			schema: schema
		};
	}

	static editForm = createEditableSelectEditForm(CustomComponentType.hmdaLoanPurposeSelectOptions);

	setItems(items: any, fromSearch: boolean): void {
		let massagedItems = items;
		if (items && Array.isArray(items)) {
			massagedItems = items.filter(option => option.enabledForConsumers);
		}
		super.setItems(massagedItems, fromSearch);
	}
}
