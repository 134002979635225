export async function AddPersonaSnippetScript(): Promise<void> {
	const scriptSrc = 'https://cdn.withpersona.com/dist/persona-v5.0.0.js';
	if (!document.querySelector(`script[src="${scriptSrc}"]`)) {
		const personaScript = document.createElement('script');
		personaScript.src = scriptSrc;
		personaScript.integrity = 'sha384-0LXHuG9ceBdEVRdF698trmE0xe0n9LgW8kNTJ9t/mH3U8VXJy0jNGMw/jPz9W82M';
		personaScript.crossOrigin = 'anonymous';

		await new Promise((resolve, reject) => {
			personaScript.onload = resolve;
			personaScript.onerror = reject;
			document.head.appendChild(personaScript);
		});
	}
}
