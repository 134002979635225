import { Customer, DataObject } from '@sageworks/jpi';
import { DataObject as DataObjectEnum, TemplateDataField } from '@sageworks/dynamic-forms';

export const getBusinessName = (
	customerId: number,
	dataObjects: Array<DataObject>,
	templateDataFieldIdToDataFieldId: { [templateDataFieldId: number]: number }) => {

	const business = dataObjects?.find(x => x.rowId === customerId);

	if (!business) return '';

	const businessNameFieldId = templateDataFieldIdToDataFieldId[TemplateDataField.BusinessName];
	const businessName = business.values?.find(x => x.id === businessNameFieldId);

	return businessName?.value?.toString() ?? '';
};

export const getPersonName = (
	customerId: number,
	dataObjects: Array<DataObject>,
	templateDataFieldIdToDataFieldId: { [templateDataFieldId: number]: number }) => {

	const person = dataObjects?.find(x => x.rowId === customerId);

	if (!person ) return '';

	const firstNameFieldId = templateDataFieldIdToDataFieldId[TemplateDataField.PersonalFirstName];
	const lastNameFieldId = templateDataFieldIdToDataFieldId[TemplateDataField.PersonalLastName];
	const firstName = person.values?.find(x => x.id === firstNameFieldId);
	const lastName = person.values?.find(x => x.id === lastNameFieldId);

	if (!lastName?.value) return firstName?.value?.toString() ?? '';
	if (!firstName?.value) return lastName.value?.toString() ?? '';

	return `${firstName?.value} ${lastName?.value}`;
};

export const getNonProfitName = (
	customerId: number,
	dataObjects: Array<DataObject>,
	templateDataFieldIdToDataFieldId: { [templateDataFieldId: number]: number }) => {

	const nonprofit = dataObjects?.find(x => x.rowId === customerId);

	if (!nonprofit) return '';

	const nonprofitNameFieldId = templateDataFieldIdToDataFieldId[TemplateDataField.NonprofitName];
	const nonprofitName = nonprofit.values?.find(x => x.id === nonprofitNameFieldId);

	return nonprofitName?.value?.toString() ?? '';
};

export const getFarmName = (
	customerId: number,
	dataObjects: Array<DataObject>,
	templateDataFieldIdToDataFieldId: { [templateDataFieldId: number]: number }) => {

	const farm = dataObjects.find(x => x.rowId === customerId);

	if (!farm) return '';

	const farmNameFieldId = templateDataFieldIdToDataFieldId[TemplateDataField.FarmName];
	const farmName = farm.values?.find(x => x.id === farmNameFieldId);

	return farmName?.value?.toString() ?? '';
};


export const getCustomerName = (
	customerId: number,
	dataObjects: { [key: string]: Array<DataObject>; },
	templateDataFieldIdToDataFieldId: { [templateDataFieldId: number]: number }) => {

		const businessName = getBusinessName(customerId, dataObjects[DataObjectEnum.TypeEnum.Business] ?? [], templateDataFieldIdToDataFieldId);
		if (businessName) return businessName;

		const personName = getPersonName(customerId, dataObjects[DataObjectEnum.TypeEnum.Person] ?? [], templateDataFieldIdToDataFieldId);
		if (personName) return personName;

		const nonProfitName = getNonProfitName(customerId, dataObjects[DataObjectEnum.TypeEnum.NonProfit] ?? [], templateDataFieldIdToDataFieldId);
		if (nonProfitName) return nonProfitName;

		const farmName = getFarmName(customerId, dataObjects[DataObjectEnum.TypeEnum.Farm] ?? [], templateDataFieldIdToDataFieldId);
		if (farmName) return farmName;

		return '';
};

export const getNameFromCustomer = (customer: Customer) => {
	if (customer?.type === Customer.TypeEnum.Person) {
		const { firstName, lastName } = customer;
		if (!lastName) return firstName?.toString() ?? '';
		if (!firstName) return lastName?.toString() ?? '';

		return `${firstName} ${lastName}`;
	}

	return customer?.name ?? '';
};