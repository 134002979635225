import editForm from './credit-applicant-entity.form';
import { CustomComponentType, CustomBuilderGroups, LoanRoleType } from '../../../enums';
import { PrimaryRoleRepeaterWidget } from '../primary-role-repeater-widget/primary-role-repeater-widget';

export default class CreditApplicantEntityWidget extends PrimaryRoleRepeaterWidget {
	static schema(...extend: any) {
		return PrimaryRoleRepeaterWidget.schema(
			{
				label: 'Credit Applicant',
				type: CustomComponentType.creditApplicantEntity,
				key: CustomComponentType.creditApplicantEntity,
				addAnother: 'Add a Credit Applicant'
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: 'Credit Applicant',
			group: CustomBuilderGroups.widgets,
			weight: 10,
			schema: CreditApplicantEntityWidget.schema({
				skipRemoveConfirm: true
			})
		};
	}

	init() {
		this.roleType = LoanRoleType.CreditApplicant;
		super.init();
	}
}
