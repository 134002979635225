function buildDefaultString(iconset: string, name: string, spinning: boolean): string {
	return (spinning ? `${iconset} ${iconset}-${name} ${iconset}-spin` : `${iconset} ${iconset}-${name}`) + frameworkExtraClasses(iconset);
}

function defaultFAOverrides(iconset: string, name: string, spinning: boolean) {
	switch (name) {
		case 'save':
			name = 'download';
			break;
		case 'zoom-in':
			name = 'search-plus';
			break;
		case 'zoom-out':
			name = 'search-minus';
			break;
		case 'question-sign':
			name = 'question-circle';
			break;
		case 'remove-circle':
			name = 'trash';
			break;
		case 'new-window':
			name = 'window-restore';
			break;
		case 'move':
			name = 'arrows';
			break;
		case 'time':
			name = 'clock-o';
			break;
	}

	return buildDefaultString(iconset, name, spinning);
}

function frameworkExtraClasses(iconset: string): string {
	switch (iconset) {
		case 'fa':
			return ' fa-fw';
	}
	return '';
}

export function getIconClass(iconset: string, name: string, spinning: boolean) {
	let classString: string = '';
	if (iconset === 'fa') {
		switch (name) {
			case 'html5':
				classString = (spinning ? `fab ${iconset}-${name} ${iconset}-spin` : `fab ${iconset}-${name}`) + frameworkExtraClasses(iconset);
				break;
			case 'folder-o':
				classString = buildDefaultString(iconset, 'folder-open', spinning);
				break;
			case 'times-circle-o':
				classString = buildDefaultString(iconset, 'time-cirle', spinning);
				break;
			case 'square-o':
				name = 'square';
				classString = (spinning ? `far ${iconset}-${name} ${iconset}-spin` : `far ${iconset}-${name}`) + frameworkExtraClasses(iconset);
				break;
			default:
				classString = defaultFAOverrides(iconset, name, spinning);
				break;
		}
	} else {
		classString = buildDefaultString(iconset, name, spinning);
	}

	return classString;
}
