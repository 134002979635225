import Component from 'formiojs/components/_classes/component/Component';
import { CustomComponentType } from '../../../enums';
import { IPopupBodyComponent } from '../popup';
import EventEmitter from 'formiojs/EventEmitter';

import { EncodingHelper } from '@sageworks/core-logic';

enum PopupState {
	MainMenu = 'MainMenu',
	ConfirmDetails = 'ConfirmDetails'
}

export interface BorrowerAccessModalState {
	canceled: boolean;
	canEnterBorrowerInfo: boolean;
	firstName: string;
	firstNameValidation: string;
	lastName: string;
	lastNameValidation: string;
	email: string;
	emailValidation: string;
}

export default class BorrowerAccessPopup extends Component implements IPopupBodyComponent {
	public resultPromise!: Promise<any>;
	public resultPromiseResolve: any;
	private popupState: PopupState;
	public modalState: BorrowerAccessModalState;

	public encodeHtml = EncodingHelper.encodeHtml;

	constructor(component: any, options: any, data: object) {
		super(component, options, data);

		this.events = new EventEmitter({});
		this.popupState = PopupState.MainMenu;
		this.modalState = {
			canEnterBorrowerInfo: true,
			canceled: false,
			firstName: '',
			firstNameValidation: '',
			lastName: '',
			lastNameValidation: '',
			email: '',
			emailValidation: ''
		};
	}

	public preClose = () => {
		return Promise.resolve();
	};

	private get modalTitle(): string {
		return `Add New ${this.roleType}`;
	}

	private get continueButtonText(): string {
		return this.popupState === 'MainMenu' ? 'Save and Continue' : `Invite ${this.roleType} to Application`;
	}

	private get mainMenuPrompt(): string {
		return `Are you able to fill out personal information for this ${this.roleType}?:`;
	}

	private get roleType() {
		return this.component.roleType;
	}

	public static schema(...extend: any) {
		return Component.schema(
			{
				label: 'Borrower Access',
				type: CustomComponentType.borrowerAccessPopup,
				key: CustomComponentType.borrowerAccessPopup,
				roleType: null,
				input: false
			},
			...extend
		);
	}

	public init() {
		super.init();
		this.resultPromise = new Promise(r => (this.resultPromiseResolve = r));
	}

	public render(): any {
		return this.renderTemplate('borrowerAccessPopup', {
			title: 'BorrowerAccess',
			modalTitle: this.modalTitle,
			popupState: this.popupState,
			mainMenuPrompt: this.mainMenuPrompt,
			modalState: this.modalState,
			continueButtonText: this.continueButtonText,
			encodeHtml: this.encodeHtml
		});
	}

	public async attach(element: any) {
		await super.attach(element);

		this.loadRefs(element, {
			firstNameInput: 'single',
			lastNameInput: 'single',
			emailInput: 'single',
			backBorrowerAccessButton: 'single',
			continueBorrowerAccessButton: 'single',
			cancelBorrowerAccessButton: 'single',
			yesCanEnterBorrowerInfo: 'single',
			yesCanEnterBorrowerInfoLabel: 'single',
			noCanEnterBorrowerInfo: 'single',
			noCanEnterBorrowerInfoLabel: 'single'
		});

		this.attachEventListeners();
	}

	private attachEventListeners() {
		const {
			backBorrowerAccessButton,
			continueBorrowerAccessButton,
			cancelBorrowerAccessButton,
			yesCanEnterBorrowerInfo,
			yesCanEnterBorrowerInfoLabel,
			noCanEnterBorrowerInfo,
			noCanEnterBorrowerInfoLabel
		} = this.refs as any;

		this.addEventListener(backBorrowerAccessButton, 'click', this.onBackBorrowerAccessClick);
		this.addEventListener(continueBorrowerAccessButton, 'click', this.onContinueBorrowerAccessClick);
		this.addEventListener(cancelBorrowerAccessButton, 'click', (e: any) => this.onCancelBorrowerAccessClick(e));
		this.addEventListener(yesCanEnterBorrowerInfo, 'click', (e: any) => this.onCanEnterBorrowerInfo(e, true));
		this.addEventListener(yesCanEnterBorrowerInfoLabel, 'click', (e: any) => this.onCanEnterBorrowerInfo(e, true));
		this.addEventListener(noCanEnterBorrowerInfo, 'click', (e: any) => this.onCanEnterBorrowerInfo(e, false));
		this.addEventListener(noCanEnterBorrowerInfoLabel, 'click', (e: any) => this.onCanEnterBorrowerInfo(e, false));
	}

	private onCanEnterBorrowerInfo = (e: any, canEnter: boolean) => {
		this.modalState.canEnterBorrowerInfo = canEnter;
		this.redraw();
	};
	private onContinueBorrowerAccessClick = (_e: any) => {
		switch (this.popupState) {
			case PopupState.MainMenu:
				this.handleMainMenuContinue();
				return;
			case PopupState.ConfirmDetails:
				this.submitModal();
				return;
		}
	};

	private onBackBorrowerAccessClick = (_e: any) => {
		this.popupState = PopupState.MainMenu;
		this.redraw();
	};

	private handleMainMenuContinue() {
		if (this.modalState.canEnterBorrowerInfo) {
			this.submitModal();
		} else {
			if (this.verifyInviteDetails()) {
				this.popupState = PopupState.ConfirmDetails;
			}
			this.redraw();
		}
	}

	private submitModal() {
		this.resultPromiseResolve(this.modalState);
	}

	private verifyInviteDetails() {
		const { firstNameInput, lastNameInput, emailInput } = this.refs as any;
		this.modalState.firstName = firstNameInput.value;
		this.modalState.firstNameValidation = firstNameInput.value ? '' : 'First Name is required';
		this.modalState.lastName = lastNameInput.value;
		this.modalState.lastNameValidation = lastNameInput.value ? '' : 'Last Name is required';
		this.modalState.email = emailInput.value;
		this.modalState.emailValidation = emailInput.value ? '' : 'Email is required';

		return !this.modalState.firstNameValidation && !this.modalState.lastNameValidation && !this.modalState.emailValidation;
	}

	private onCancelBorrowerAccessClick = (_e: any) => {
		this.modalState.canceled = true;
		this.resultPromiseResolve(this.modalState);
	};
}
