export function getRadioSchema(editMode: boolean, defaultValue: string): any {
	const business = [
		{
			label: 'Business',
			value: 'business'
		}
	];
	const farm = [
		{
			label: 'Farm',
			value: 'farm'
		}
	];

	return {
		label: 'Add New:',
		key: 'entityTypeRadio',
		values: [...business, ...farm],
		inline: true,
		defaultValue,
		customConditional: `
            show = !component.editMode
        `,
		editMode: editMode
	};
}
