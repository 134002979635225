export default {
	weight: 1450,
	type: 'checkbox',
	defaultValue: false,
	disabled: false,
	input: true,
	key: 'lenderOnly',
	label: 'Lender-only',
	tooltip:
		'Components marked as Lender Only will be shown on the application when logged in as a lender and hidden when logged in as a borrower. ' +
		'The borrower-facing application PDFs, those sent for eSignature and available when an application is locked, will not include Lender Only components.'
};
