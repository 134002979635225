import { IBaseWidgetConfigurationEditTabOptions } from './base.edit.tab.options';

export abstract class BaseEditTab<T extends IBaseWidgetConfigurationEditTabOptions> {
	public get tabKey(): string {
		return '';
	}

	public get label(): string {
		return '';
	}

	public get weight(): number {
		return 0;
	}

	abstract get defaultOptions(): T;

	public getTab(customOptions?: T) {
		const options = {
			...this.defaultOptions,
			...customOptions,
		};

		return {
			key: this.tabKey,
			components: this.getComponents(options),
			ignore: options.ignore || false,
			logic: this.getLogic(options.beforeForm),
			hidden: !options.beforeForm || false,
			weight: this.weight,
			label: this.label
		};
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public getComponents(options: T): any[] {
		return [];
	}

	public getLogic(beforeForm?: boolean) {
		if (beforeForm === undefined) {
			return [];
		} else {
			return [
				{
					name: 'Show/Hide',
					trigger: {
						type: 'javascript',
						javascript: 'result = (data["formPath"] || "") != ""'
					},
					actions: [
						{
							name: 'Show/Hide',
							type: 'property',
							property: {
								label: 'Hidden',
								value: 'hidden',
								type: 'boolean'
							},
							state: beforeForm
						}
					]
				}
			];
		}
	}
}
