export function formatAsPercentage(value: number, decimalPlaces: number = 4): string {
	return (value * 100).toFixed(decimalPlaces) + '%';
}

export function formatAsBasisPointValue(value: number, decimalPlaces: number = 2): string {
	return (value * 10000).toFixed(decimalPlaces);
}

export function formatAsCurrency(value: number, decimalPlaces: number = 2, useCommas: boolean = false): string {
	let rounded: number = +value.toFixed(decimalPlaces);
	let formatted: string = '';
	if (useCommas) {
		formatted = rounded.toLocaleString();
	} else {
		formatted = rounded.toString();
	}

	if (decimalPlaces > 0) {
		let decimalIndex = formatted.indexOf('.');
		if (decimalIndex === -1) {
			formatted += '.';
			decimalIndex = formatted.length - 1;
		}

		let decimalPlacesToAdd = decimalPlaces - (formatted.length - 1 - decimalIndex);
		for (let decimalPlaces = 0; decimalPlaces < decimalPlacesToAdd; decimalPlaces++) {
			formatted += '0';
		}
	}

	return '$' + formatted;
}
