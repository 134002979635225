import { FormioComponentType } from '../enums';
import ComponentBaseWithCustomTypings from 'formiojs/components/_classes/component/Component';
import ModifiedTabsComponent from '../components/form-components/formio-components/tabs/tabs';
import ModifiedColumnsComponent from '../components/form-components/formio-components/columns/modified-columns-component';
import ModifiedContentComponent from '../components/form-components/formio-components/content/modified-content-component';
import ModifiedFieldsetComponent from '../components/form-components/formio-components/fieldset/modified-fieldset-component';
import ModifiedHTMLComponent from '../components/form-components/formio-components/html/modified-html-component';
import ModifiedPanelComponent from '../components/form-components/formio-components/panel/modified-panel-component';
import ModifiedTableComponent from '../components/form-components/formio-components/table/modified-table-component';
import ModifiedWellComponent from '../components/form-components/formio-components/well/modified-well-component';
import ModifiedFormComponent from '../components/form-components/formio-components/form/modified-form-component';

export const formioComponentOverrides: { [type in FormioComponentType]: typeof ComponentBaseWithCustomTypings } = {
	[FormioComponentType.Tabs]: ModifiedTabsComponent,
	[FormioComponentType.Columns]: ModifiedColumnsComponent,
	[FormioComponentType.Content]: ModifiedContentComponent,
	[FormioComponentType.Fieldset]: ModifiedFieldsetComponent,
	[FormioComponentType.HTML]: ModifiedHTMLComponent,
	[FormioComponentType.Panel]: ModifiedPanelComponent,
	[FormioComponentType.Table]: ModifiedTableComponent,
	[FormioComponentType.Well]: ModifiedWellComponent,
	[FormioComponentType.Form]: ModifiedFormComponent
};
