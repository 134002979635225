import Component from 'formiojs/components/_classes/component/Component';

export class CopyFormRequired {
	public component: Component;
	public settings: any;
	public config: any;

	constructor(component: Component, settings: any, config: any) {
		this.component = component;
		this.settings = settings;
		this.config = config;
	}

	get defaultMessage() {
		return 'Copy a form to use as a starting place for this widget.';
	}

	check(value: string) {
		if (!value || value.length === 0) {
			return false;
		} else if (value !== (this.component as any).originalDataValue) {
			return false;
		} else {
			return true;
		}
	}
}
