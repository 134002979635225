
import Vue from 'vue';

export default Vue.extend({
	name: 'LoanApplicationCardFieldValue',
	props: {
		label: String,
		value: String
	}
});
