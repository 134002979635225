export enum DynamicFormsHookType {
	FetchData,
	UpdateData,
	RemoveOnChangeHandler,
	CreateVueComponent
}

export enum FetchDataType {
	SbaInfo,
	DataObject,
	CurrentProposedLoanId,
	DataFields,
	PresetDataFields,
	CurrentUserWritableEntities,
	FeatureFlagActive,
	UseBorrowerAccessRestrictions,
	AllowBorrowerToSendInvites,
	ApplicationEntities,
	Collateral
}

export enum UpdateDataType {
	SbaInfo
}
