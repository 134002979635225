export default [
	{
		key: 'multiple',
		ignore: true
	},
	{
		key: 'persistent',
		ignore: true
	},
	{
		key: 'protected',
		ignore: true
	},
	{
		key: 'dbIndex',
		ignore: true
	},
	{
		key: 'encrypted',
		ignore: true
	},
	{
		key: 'redrawOn',
		ignore: true
	},
	{
		key: 'calculateServer',
		ignore: true
	},
	{
		key: 'allowCalculateOverride',
		ignore: true
	}
];
