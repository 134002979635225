import { BaseEditTab } from '../base.edit.tab';
import { IBaseWidgetConfigurationEditTabOptions } from '../base.edit.tab.options';
import defaultComponents from './base.edit.data.component';

export interface IBaseWidgetConfigurationEditDataOptions extends IBaseWidgetConfigurationEditTabOptions {
	components?: {
		defaultComponents?: any[];
	};
}

export class BaseEditData extends BaseEditTab<IBaseWidgetConfigurationEditDataOptions> {
	public get tabKey(): string {
		return 'data';
	}

	public get label(): string {
		return 'Data';
	}

	public get weight(): number {
		return 10;
	}

	public getComponents(customOptions: IBaseWidgetConfigurationEditDataOptions): any[] {
		const components = [...defaultComponents];

		if (customOptions.components && customOptions.components.defaultComponents) {
			components.push(...customOptions.components.defaultComponents);
		}

		return components;
	}

	public defaultOptions: IBaseWidgetConfigurationEditDataOptions = {
		beforeForm: true,
		components: {
			defaultComponents: []
		}
	};
}
