import { BaseEditTab} from './base.edit.tab';
import { IBaseWidgetConfigurationEditTabOptions } from './base.edit.tab.options';

interface IBaseWidgetConfigurationEditApiOptions extends IBaseWidgetConfigurationEditTabOptions {}

export class BaseEditApi extends BaseEditTab<IBaseWidgetConfigurationEditApiOptions> {
	public get tabKey(): string {
		return 'api';
	}

	public get label(): string {
		return 'API';
	}

	public get weight(): number {
		return 30;
	}

	public defaultOptions: IBaseWidgetConfigurationEditApiOptions = {
		ignore: true
	};
}
