
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { ApplicationSpinner, ApplicationWizardCard } from '@/components/application-wizard';
import { UnauthenticatedForm } from '@sageworks/dynamic-forms';
import { UrlUtils } from '@/utils';
import { IAppConfiguration } from '@/models';
import { ScaleMixin } from '@/mixins';
import { DynamicFormsContextData } from '@sageworks/dynamic-forms';

@Component({
	components: {
		ApplicationSpinner,
		ApplicationWizardCard,
		UnauthenticatedForm
	}
})
export default class Form extends Mixins(ScaleMixin) {
	@Prop()
	private formPath!: string;

	get subdomain(): string {
		return UrlUtils.getOnlinePortalSubdomain();
	}

	get loaded(): boolean {
		return this.directStore.state.AppConfig.appConfig != null;
	}

	get formioUrl(): string {
		if (!this.loaded) {
			return '';
		}

		return (this.directStore.state.AppConfig.appConfig as IAppConfiguration).formioUrl;
	}

	get appType(): string {
		return this.formPath.split('/')[1];
	}

	get contextData(): DynamicFormsContextData {
		return {
			applicationId: 0,
			productName: 'Preview Product',
			hideNavigationHeader: this.appType.toLowerCase() === 'preapp'
		};
	}

	public showDocumentation(form: any, contextData: DynamicFormsContextData) {
		if (!form || !contextData) {
			return;
		}
		this.showToast('Documentation', 'Not available in the application preview.');
	}

	public submitApplication(form: any, contextData: DynamicFormsContextData) {
		if (!form || !contextData) {
			return;
		}
		this.showToast('Submit Application', 'Not available in the application preview.');
	}

	private showToast(title: string, message: string) {
		this.$root.$bvToast.toast(message, {
			title: title,
			solid: true,
			autoHideDelay: 3000,
			toaster: 'b-toaster-bottom-right'
		});
	}
}
