import { DynamicApplicationThirdPartySetting, AutoFactory, DynamicApplicationThirdPartySettingsService, LoanApplication } from '@sageworks/jpi';

export const loanAppTypeToThirdPartSettingsAppType = (applicationType: LoanApplication.TypeEnum) => {
	switch (applicationType) {
		case LoanApplication.TypeEnum.Personal:
			return DynamicApplicationThirdPartySetting.TypeEnum.Person;
		case LoanApplication.TypeEnum.Business:
			return DynamicApplicationThirdPartySetting.TypeEnum.Business;
		case LoanApplication.TypeEnum.Nonprofit:
			return DynamicApplicationThirdPartySetting.TypeEnum.Nonprofit;
		case LoanApplication.TypeEnum.Sba:
			return DynamicApplicationThirdPartySetting.TypeEnum.Sba;
		case LoanApplication.TypeEnum.Farm:
			return DynamicApplicationThirdPartySetting.TypeEnum.Farm;
		default:
			throw new Error('Cannot convert LoanApplication type to DynamicApplicationThirdPartySetting App type');
	}
};

export const getThirdPartySettings = async (applicationType: LoanApplication.TypeEnum) => {
	const service = AutoFactory.get(DynamicApplicationThirdPartySettingsService);
	const thirdPartySettingsAppType = loanAppTypeToThirdPartSettingsAppType(applicationType);
	const results = await service.getPaged(1, 1, [thirdPartySettingsAppType]);

	return results.items?.pop() ?? null;
};
