import { BaseEditTab} from './base.edit.tab';
import { IBaseWidgetConfigurationEditTabOptions } from './base.edit.tab.options';

interface IBaseWidgetConfigurationEditLayoutOptions extends IBaseWidgetConfigurationEditTabOptions {}

export class BaseEditLayout extends BaseEditTab<IBaseWidgetConfigurationEditLayoutOptions> {
	public get tabKey(): string {
		return 'layout';
	}

	public get label(): string {
		return 'Layout';
	}

	public get weight(): number {
		return 50;
	}

	public defaultOptions: IBaseWidgetConfigurationEditLayoutOptions = {
		beforeForm: false
	};
}
