
import Vue from 'vue';
import sortBy from 'lodash/sortBy';
import { UsableProducts } from '@sageworks/jpi';

export default Vue.extend({
	name: 'NewLoanRequestModal',
	components: {},
	props: {
		applicationType: {
			type: String,
			required: true,
			default: null
		}
	},
	data() {
		return {
			selected: null as any,
			addingToApplication: false
		};
	},
	computed: {
		options() {
			return sortBy(this.directStore.state.LoanApplicationData.proposedProductSelectOptions, [x => x.text]);
		},
		itemLabel(): string {
			if (this.hasCommunityLendingSubscription === undefined) {
				return '';
			}
			return this.hasCommunityLendingSubscription ? 'Request Form': 'Application';
		},
		hasCommunityLendingSubscription(): boolean | undefined {
			return this.directStore.state.UsableProducts.usableProducts?.has('abrigoCommunityLending' as UsableProducts.ProductsEnum);
		},
	},
	async mounted() {
		await this.directStore.dispatch.UsableProducts.fetchUsableProducts();
	},
	methods: {
		async addNewLoan(close: Function) {
			this.addingToApplication = true;
			const loanApplicationId = await this.directStore.state.LoanApplicationData.loanApplication.id;
			const loanMapping = await this.directStore.dispatch.LoanApplicationData.addProductToApplication({
				proposedProductId: this.selected,
				loanApplicationId
			});
			await this.directStore.dispatch.LoanApplicationMetadata.addLoanMetadata({ loanMappingId: loanMapping.id });
			this.$root.$emit('new-loan-request');
			close();
			this.addingToApplication = false;
		}
	}
});
