import { CrudHookConfig, CustomComponentType } from '@sageworks/dynamic-forms';
import { StoreType } from '../../store';
import { updateMultiLoanApplicationStatus } from '../dya-hooks/hook-conditions';

export type IsProfileCompleteResolver = () => boolean;

export const createCrudHookConfig = (store: StoreType, isProfileCompleteResolver: IsProfileCompleteResolver): CrudHookConfig[] => {
	const updateAppStatusPostHook = async () => updateMultiLoanApplicationStatus(store, isProfileCompleteResolver());

	return [
		// PFS hooks
		CustomComponentType.income,
		CustomComponentType.expense,
		CustomComponentType.otherLiabilities,
		CustomComponentType.cashAccount,
		CustomComponentType.stocksBondsBusinessHoldings,
		CustomComponentType.nonInvestmentRealEstate,
		CustomComponentType.investmentRealEstate,
		CustomComponentType.businessInvestment,
		CustomComponentType.lifeInsurance,
		CustomComponentType.retirementAccount,
		CustomComponentType.otherAssets,

		// Related role hooks
		CustomComponentType.beneficialOwnership,
		CustomComponentType.authorizedSigner,

		// Loan Role hooks
		{ componentType: CustomComponentType.primaryBorrowerEntity, postDeleteHook: updateAppStatusPostHook },
		{ componentType: CustomComponentType.primaryBorrowerEntityVue, postDeleteHook: updateAppStatusPostHook },
		{ componentType: CustomComponentType.coBorrowerEntity, postDeleteHook: updateAppStatusPostHook },
		{ componentType: CustomComponentType.coBorrowerEntityVue, postDeleteHook: updateAppStatusPostHook },
		{ componentType: CustomComponentType.creditApplicantEntity, postDeleteHook: updateAppStatusPostHook },
		{ componentType: CustomComponentType.creditApplicantEntityVue, postDeleteHook: updateAppStatusPostHook },
		{ componentType: CustomComponentType.coSignerEntity, postDeleteHook: updateAppStatusPostHook },
		{ componentType: CustomComponentType.coSignerEntityVue, postDeleteHook: updateAppStatusPostHook },
		{ componentType: CustomComponentType.guarantorEntity, postDeleteHook: updateAppStatusPostHook },
		{ componentType: CustomComponentType.guarantorEntityVue, postDeleteHook: updateAppStatusPostHook },

		// Data object hooks
		{ componentType: CustomComponentType.personalInfo, postSubmitHook: updateAppStatusPostHook },
		{ componentType: CustomComponentType.businessInfo, postSubmitHook: updateAppStatusPostHook },
		{ componentType: CustomComponentType.nonprofitInfo, postSubmitHook: updateAppStatusPostHook },
		{ componentType: CustomComponentType.farmInfo, postSubmitHook: updateAppStatusPostHook },
		{ componentType: CustomComponentType.loans, postSubmitHook: updateAppStatusPostHook },
		{ componentType: CustomComponentType.collateralInfo, postSubmitHook: updateAppStatusPostHook, postDeleteHook: updateAppStatusPostHook }
	] as CrudHookConfig[];
};
