export function getBase64FromDataUri(dataURI: string) {
	const [, base64Data] = dataURI.split(',');
	return base64Data;
}

/* eslint-disable max-lines-per-function */
export function downloadFile(memoryStream: string, fileName: string) {
	var arrayBuffer = _base64MemoryStreamToArrayBuffer(memoryStream);
	var blob = new Blob([arrayBuffer]);
	_startBlobDownload(blob, fileName);

	function _base64MemoryStreamToArrayBuffer(base64: string) {
		var binaryString = window.atob(base64);
		var binaryLen = binaryString.length;
		var bytes = new Uint8Array(binaryLen);
		for (var i = 0; i < binaryLen; i++) {
			var ascii = binaryString.charCodeAt(i);
			bytes[i] = ascii;
		}
		return bytes;
	}

	function _startBlobDownload(blob: Blob, fileName: string) {
		if (window.navigator.msSaveOrOpenBlob) {
			// many IE strains only support blog download via internal API
			window.navigator.msSaveOrOpenBlob(blob, fileName);
		} else {
			// general/future blob download support is powered by a simple link
			var link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = fileName;
			// we attach and remove the link from the document to support Firefox
			document.body.appendChild(link);
			link.click();
			setTimeout(function() {
				document.body.removeChild(link);
				window.URL.revokeObjectURL(link.href);
			}, 200);
		}
	}
}
