import { FormioFormSchema } from './FormioFormSchema';
import { FormioBuilderOptionsSchema } from './FormioBuilderOptionsSchema';

// WIP from formio.js

export enum FormioHookType {
	renderComponent,
	renderComponents,
	renderInput,
	renderLoading,
	attachComponents,
	attachDataGrid,
	attachComponent
}

export enum FormioAttachMode {
	builder = 'builder',
	full = 'full'
}

export interface FormioFormOptionsSchema {
	noNewEdit?: boolean;
	language?: string;
	editForm?: FormioFormSchema;
	builder?: FormioBuilderOptionsSchema;
	hooks?: Map<FormioHookType, Function>;
	attachMode?: FormioAttachMode;
	skipInit?: boolean;
	calculatedValue?: any;
	sideBarScroll?: boolean;
	sideBarScrollOffset?: number;
	resourceTag?: string;
	resourceFilter?: string;
	iconset?: string;
	namespace?: string;
	template?: string;

	// array of component keys to be disabled (?)
	disabled?: string[];
}
