import * as CashAccountsUtils from '../cash-accounts-utils';
import { CustomComponentType, FinancialSubaccountParent } from '../../enums';

export function convertSubaccountParentToComponentType(parent: FinancialSubaccountParent, subaccountName?: string): CustomComponentType {
	// Cash Account widget occasionally can be of type 'OtherLiquidAssets' (which is also a type
	// 	for Stocks, Bonds, and Bussiness Holdings widget). So we need to force check this isn't a Cash Account subaccount
	// 	before deciding it belongs to another widget
	if (CashAccountsUtils.isCashAccount(parent, subaccountName ?? '')) {
		return CustomComponentType.cashAccount;
	}

	switch (parent) {
		case FinancialSubaccountParent.PersonalIncome:
		case FinancialSubaccountParent.OtherIncome:
			return CustomComponentType.income;
		case FinancialSubaccountParent.PersonalExpenses:
		case FinancialSubaccountParent.RentPayment:
		case FinancialSubaccountParent.TaxesPaid:
			return CustomComponentType.expense;
		case FinancialSubaccountParent.OtherLongTermLoans:
		case FinancialSubaccountParent.OtherShortTermLoans:
		case FinancialSubaccountParent.Taxes:
			return CustomComponentType.otherLiabilities;
		case FinancialSubaccountParent.OtherLiquidAssets:
		case FinancialSubaccountParent.Stocks:
		case FinancialSubaccountParent.MutualFunds:
			return CustomComponentType.stocksBondsBusinessHoldings;
		case FinancialSubaccountParent.Home:
		case FinancialSubaccountParent.VacationProperties:
			return CustomComponentType.nonInvestmentRealEstate;
		case FinancialSubaccountParent.LifeInsurance:
			return CustomComponentType.lifeInsurance;
		case FinancialSubaccountParent.Retirement:
			return CustomComponentType.retirementAccount;
		case FinancialSubaccountParent.Furniture:
		case FinancialSubaccountParent.Jewelry:
		case FinancialSubaccountParent.OtherAssets:
			return CustomComponentType.otherAssets;
		default:
			throw new Error('Cannot map parent to component type');
	}
}
