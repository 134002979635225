import { DataFieldConvertStrategy } from '../data-field-convert-strategy';
import { DataField } from '@sageworks/jpi';

export class NoopDataFieldConverter implements DataFieldConvertStrategy {
	isFieldValid(_dataField: DataField): boolean {
		return true;
	}

	convert(_dataField: DataField, currentProperties: any) {
		return { ...currentProperties };
	}
}
