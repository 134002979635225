import { defineModule, localActionContext } from 'direct-vuex';
import { UsableProducts, AutoFactory, OnlinePortalSubscriptionsService } from '@sageworks/jpi';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => localActionContext(context, UsableProductsModule);
export interface UsableProductsModuleState {
	usableProducts: Set<UsableProducts.ProductsEnum> | null;
	_initializationPromise: Promise<any> | null;
}

const UsableProductsModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			usableProducts: null,
			_initializationPromise: null
		} as UsableProductsModuleState;
	},
	mutations: {
		SET_USABLE_PRODUCTS(state, products: Set<UsableProducts.ProductsEnum>) {
			state.usableProducts = products;
		}
	},
	actions: {
		async fetchUsableProducts(context): Promise<void> {
			const { commit, state } = actionContext(context);

			if (state.usableProducts !== null) {
				return;
			}
			// Don't make api call if we are already in the process of making the api call.
			if (state._initializationPromise) {
				return state._initializationPromise;
			}

			const subscriptionsService = AutoFactory.get(OnlinePortalSubscriptionsService);
			state._initializationPromise = subscriptionsService.listActiveSubscriptions();
			const features = await state._initializationPromise;
			commit.SET_USABLE_PRODUCTS(new Set<UsableProducts.ProductsEnum>(features));
		}
	}
});

export default UsableProductsModule;
