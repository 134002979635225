import { CustomComponentType, FetchDataType } from '../../enums';
import { Utils as FormioUtils } from 'formiojs';
import { RelatedRoleRepeaterWidget } from '../../components/form-components/related-role-repeater-widget/related-role-repeater-widget';
import { ComponentDataHelper } from '../';
import { AggregatedFormDataModel } from '@sageworks/jpi';

export const getPrimaryRoleComponentTypes = function() {
	return [
		CustomComponentType.primaryBorrowerEntity,
		CustomComponentType.coBorrowerEntity,
		CustomComponentType.creditApplicantEntity,
		CustomComponentType.guarantorEntity,
		CustomComponentType.additionalEntity,
		CustomComponentType.coSignerEntity
	];
};

export function getEntitiesOnLoan(formData: AggregatedFormDataModel) {
	const mappings = ['coBorrowerMappings', 'coSignerMappings', 'creditApplicantMappings', 'trusteeMappings', 'guarantorMappings', 'authorizedSigners'];
	let entityIds: number[] = [];
	if (formData.primaryBorrowerMapping) entityIds.push(formData.primaryBorrowerMapping.customerID!);
	for (const mapping of mappings) {
		for (const roleData of (formData as any)[mapping] ?? []) {
			if (!entityIds.includes(roleData.customerID)) entityIds.push(roleData.customerID);
		}
	}
	for (const roleData of formData.beneficialOwners ?? []) {
		if (!entityIds.includes(roleData.ownerCustomerId!)) entityIds.push(roleData.ownerCustomerId!);
	}
	return entityIds;
}

export const getRelatedRoleComponentTypes = function() {
	return [CustomComponentType.authorizedSigner, CustomComponentType.trustee, CustomComponentType.beneficialOwnership];
};

interface RoleDataWithInstance {
	instance: RelatedRoleRepeaterWidget;
	rolesToCheck: any[];
}

export const addRelatedRoleEntitiesToApplicationEntities = function(
	applicationEntities: { value: string; label: string }[],
	relatedRolesToIterate: RoleDataWithInstance,
	parentEntityIds: string[],
	allowedEntityTypes?: CustomComponentType[]
) {
	relatedRolesToIterate.rolesToCheck.forEach((relatedRoleRow: any) => {
		if (allowedEntityTypes != null && Object.keys(relatedRoleRow.form.data).filter((x: any) => allowedEntityTypes.includes(x)).length === 0) return;

		const value = relatedRolesToIterate.instance.rowGetEntityId(relatedRoleRow.form);
		// Skip entities used in a primary role
		if (parentEntityIds.includes(value)) return;

		const label = relatedRolesToIterate.instance.rowDescriptionGetEntityDescription(relatedRoleRow.form);
		applicationEntities.push({ value, label });
	});
};

export function accumulateRelatedRoleData(relatedRolesToIterate: RoleDataWithInstance[], flattenedComponents: any) {
	getRelatedRoleComponentTypes().forEach((componentType: CustomComponentType) => {
		// flattened components operate on component key, this is currently hard-coded to type for our widgets
		if (flattenedComponents[componentType] == null) {
			return;
		}
		const relatedRoleComponent = flattenedComponents[componentType];
		const relatedRoleArray = relatedRoleComponent.data[componentType];

		if (relatedRoleArray != null && relatedRoleArray.length >= 0) {
			relatedRolesToIterate.push({
				instance: relatedRoleComponent,
				rolesToCheck: relatedRoleArray
			} as RoleDataWithInstance);
		}
	});
}

export const handlePrimaryRoleEntities = function(
	applicationEntities: any[],
	currentRootComponent: any,
	rowData: any,
	rowIndex: number,
	currentParentCustomerId: any,
	primaryRoleEntityIds: string[],
	relatedRolesToIterate: RoleDataWithInstance[],
	isPrimaryRole?: boolean
) {
	if (!currentRootComponent.rowDescriptionSubformLoaded(rowIndex)) return;
	const flattenedComponents = currentRootComponent.flattenComponents(rowIndex);
	const value = currentRootComponent.rowGetEntityId(rowData.form);
	let label = currentRootComponent.rowDescriptionGetEntityDescription(rowData.form);

	if (isPrimaryRole || value === currentParentCustomerId) {
		primaryRoleEntityIds.push(value);
	}

	/*
	 * Adds the entity to the list when the following conditions are true:
	 * 1. The current component is not the same as the originating component
	 * 2. When the originating component was a primary role, this comeonent is NOT a primary role
	 */

	if (value !== currentParentCustomerId && !(isPrimaryRole && getPrimaryRoleComponentTypes().includes(currentRootComponent.type))) {
		applicationEntities.push({ value, label });
	}

	accumulateRelatedRoleData(relatedRolesToIterate, flattenedComponents);
};

const addApplicationEntitiesFromOtherLoans = async function(
	applicationEntities: { value: string; label: string }[],
	primaryRoleEntityIds: string[],
	component: any
) {
	const allLoanApplicationEntities = await ComponentDataHelper.fetchData<any[]>(component, {
		fetchType: FetchDataType.ApplicationEntities,
		fetchContext: {}
	});
	allLoanApplicationEntities.forEach(entity => {
		if (primaryRoleEntityIds.includes(entity.value) || applicationEntities.find(appEntity => entity.value === appEntity.value)) {
			return;
		}
		applicationEntities.push(entity);
	});
};

const addRoleEntitiesToApplicationEntities = async function(
	applicationEntities: { value: string; label: string }[],
	rootData: any,
	rootComponents: any,
	currentParentCustomerId: any,
	isPrimaryRole?: boolean,
	allowedEntityTypes?: CustomComponentType[]
) {
	let primaryRoleEntityIds: string[] = [];
	let relatedRolesToIterate: RoleDataWithInstance[] = [];

	getPrimaryRoleComponentTypes().forEach((componentType: CustomComponentType) => {
		addPrimaryRoleEntitiesToApplicationEntities(
			applicationEntities,
			rootData,
			rootComponents,
			componentType,
			currentParentCustomerId,
			primaryRoleEntityIds,
			relatedRolesToIterate,
			isPrimaryRole
		);
	});

	relatedRolesToIterate.forEach((relatedRoleToCheck: RoleDataWithInstance) => {
		addRelatedRoleEntitiesToApplicationEntities(applicationEntities, relatedRoleToCheck, primaryRoleEntityIds, allowedEntityTypes);
	});
	await addApplicationEntitiesFromOtherLoans(applicationEntities, primaryRoleEntityIds, rootComponents[0]);
};

function addPrimaryRoleEntitiesToApplicationEntities(
	applicationEntities: { value: string; label: string }[],
	rootData: any,
	rootComponents: any,
	componentType: CustomComponentType,
	currentParentCustomerId: any,
	primaryRoleEntityIds: string[],
	relatedRolesToIterate: RoleDataWithInstance[],
	isPrimaryRole?: boolean
) {
	if (rootData[componentType] == null || rootData[componentType].length <= 0) return;

	const currentRootComponents = FormioUtils.searchComponents(rootComponents, { key: componentType });
	if (currentRootComponents.length <= 0) return;
	const currentRootComponent = currentRootComponents[0];

	rootData[componentType].forEach((primaryRoleRow: any, i: number) => {
		handlePrimaryRoleEntities(
			applicationEntities,
			currentRootComponent,
			primaryRoleRow,
			i,
			currentParentCustomerId,
			primaryRoleEntityIds,
			relatedRolesToIterate,
			isPrimaryRole
		);
	});
}

export default {
	addRoleEntitiesToApplicationEntities,
	getPrimaryRoleComponentTypes,
	getRelatedRoleComponentTypes,
	handlePrimaryRoleEntities
};
