import nestedComponentForm from 'formiojs/components/_classes/nested/NestedComponent.form';

import { BaseEditDisplay } from '../../configuration-components/base-widget-configuration/base.edit.display';
import { BaseEditForm } from '../../configuration-components/base-widget-configuration/base.edit.form';
import { BaseEditLogic } from '../../configuration-components/base-widget-configuration/base.edit.logic';
import { BaseEditApi } from '../../configuration-components/base-widget-configuration/base.edit.api';
import { BaseEditConditional } from '../../configuration-components/base-widget-configuration/base.edit.conditional';
import { BaseEditLayout } from '../../configuration-components/base-widget-configuration/base.edit.layout';

import { IBaseWidgetConfigurationEditFormOptions } from '../../configuration-components/base-widget-configuration/base.edit.form.options';

export default function(...extend: any) {
	return nestedComponentForm(
		[
			new BaseEditDisplay().getTab({ beforeForm: true }),
			new BaseEditForm().getTab({ ignore: true } as IBaseWidgetConfigurationEditFormOptions),
			new BaseEditLogic().getTab({ ignore: true }),
			new BaseEditApi().getTab({ ignore: true }),
			new BaseEditConditional().getTab({ beforeForm: true }),
			new BaseEditLayout().getTab({ ignore: true })
		],
		...extend
	);
}
