
import { Component, Mixins } from 'vue-property-decorator';
import { MainTemplate } from '@/components/templates/';
import { OnlinePortalPostLoginHeader, SessionInactivityPrompt } from '@/components';
import { ScaleMixin, RouteMixin } from '@/mixins';
import { StylingConstants } from '@/enums/styling-constants';

@Component({
	components: {
		MainTemplate,
		OnlinePortalPostLoginHeader,
		SessionInactivityPrompt
	}
})
export default class ApplicationTemplate extends Mixins(ScaleMixin, RouteMixin) {
	get validateView(): boolean {
		if (this.$route.meta.unauthenticated) {
			return false;
		}
		return true;
	}

	get backgroundClassName(): string {
		return `${StylingConstants.backgroundClassName} content-frame-root pb-2`;
	}
}
