import { LocalStorageKeys } from '@/enums';
import * as LocalStorageUtils from './LocalStorageUtils';

function _getUserToken(): string {
	return LocalStorageUtils.getItem(LocalStorageKeys.userToken) || '';
}

function _addQueryParameter(url: string, name: string, value: string): string {
	const alreadyContainsQueryString = url.includes('?');
	const queryParameterDelimiterCharacter = alreadyContainsQueryString ? '&' : '?';
	return url + queryParameterDelimiterCharacter + name + '=' + value;
}

/**
 * Use Cases:
 * 1. Transfer a staging OPN session to local by generating a localhost url
 *    with the current staging session auth token.
 * 2. Share a local OPN session with another developer by generating a localhost url
 *    with the current local or staging session auth token.
 */
export function getShareableLocalLink() {
	let currentUrl = _addQueryParameter(window.location.href, 'token', _getUserToken());
	return currentUrl.replace('https://staging.onlineportalnow.com', 'http://localhost:8080');
}

/**
 * Use Cases:
 * 1. Transfer a local OPN session to staging by generating a staging url
 *    with the current local session auth token.
 * 2. Share a staging OPN session with a PM, QA, or another developer by generating
 *    a staging url with the current local or staging session auth token.
 */
export function getShareableStagingLink() {
	let currentUrl = _addQueryParameter(window.location.href, 'token', _getUserToken());
	return currentUrl.replace('http://localhost:8080', 'https://staging.onlineportalnow.com');
}
