export enum FinancialStatementType {
	All = 'All',
	IncomeStatement = 'IncomeStatement',
	BalanceSheet = 'BalanceSheet',
	StatementOfEquity = 'StatementOfEquity',
	IncomeExpenses = 'IncomeExpenses',
	ValuationKeyLoanEquityData = 'ValuationKeyLoanEquityData',
	PersonalAssets = 'PersonalAssets',
	PersonalLiabilities = 'PersonalLiabilities',
	StatementOfActivities = 'StatementOfActivities',
	StatementOfActivitiesTempRestricted = 'StatementOfActivitiesTempRestricted',
	StatementOfActivitiesPermRestricted = 'StatementOfActivitiesPermRestricted',
	StatementOfActivitiesDonorRestricted = 'StatementOfActivitiesDonorRestricted',
	StatementOfFinancialPosition = 'StatementOfFinancialPosition',
	ReconciliationOfNetAssetsTempRestricted = 'ReconciliationOfNetAssetsTempRestricted',
	ReconciliationOfNetAssetsPermRestricted = 'ReconciliationOfNetAssetsPermRestricted',
	ReconciliationOfNetAssetsDonorRestricted = 'ReconciliationOfNetAssetsDonorRestricted',
	ReconciliationOfNetAssetsTotal = 'ReconciliationOfNetAssetsTotal',
	DebtService = 'DebtService',
	Liabilities = 'Liabilities',
	CurrentAssets = 'CurrentAssets',
}
