
import Vue, { PropType } from 'vue';
import { DetailedLoanApplication, OnlinePortalProposedLoanStageGroup } from '@sageworks/jpi';
import { LoanApplicationCard } from '../loan-application-card';

export default Vue.extend({
	name: 'LoanApplicationSection',
	components: {
		LoanApplicationCard
	},
	props: {
		title: String,
		noDataText: String,
		isLoading: Boolean,
		hasMoreData: Boolean,
		loanApplications: {
			type: Array as PropType<DetailedLoanApplication[]>,
			required: true
		},
		stageGroups: {
			type: Array as PropType<OnlinePortalProposedLoanStageGroup[]>,
				required: true
		}
	}
});
