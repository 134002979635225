import { getBaseFormUrl, getTopLevelWidget, getWidgetLabels, getWidgetNames } from '../../../utils/form-path-utils';
import { Formio, Utils as FormioUtils } from 'formiojs';
import { CustomComponentType } from '../../../enums/custom-component-type';
import { getPrimaryRoleComponentTypes } from '../../../utils/form-data-helpers/form-data-loan-role-helpers';

export default [
	{
		key: 'simple-conditional',
		weight: -10,
		components: [
			{
				label: 'This component should:',
				key: 'conditional.show',
				dataSrc: 'values',
				data: {
					values: [
						{ label: 'Show', value: 'true' },
						{ label: 'Hide', value: 'false' }
					]
				}
			},
			{
				type: CustomComponentType.extendedSelectField,
				key: 'conditional.when',
				uniqueOptions: true,
				dataSrc: 'custom',
				valueProperty: 'value',
				customOptions: {
					shouldSort: true
				},
				data: {
					async custom(context: any) {
						const results = await getComponents(context);
						results.forEach((option: any) => {
							if (context.instance.dataFieldTypeMapping[`${option.dataFieldId}`] == null) {
								context.instance.dataFieldTypeMapping[`${option.dataFieldId}`] = `${option.type}`;
							}
						});

						return results;
					}
				}
			},
			{
				type: 'textfield',
				input: true,
				label: 'Has the value:',
				key: 'conditional.eq',
				customConditional: getCustomConditionLogic(false)
			},
			{
				type: CustomComponentType.extendedSelectField,
				input: true,
				label: 'Has the following value(s):',
				key: 'conditional.test',
				customConditional: getCustomConditionLogic(true),
				dataSrc: 'url',
				data: {
					url:
						'{{Formio.apiUrl}}/v1/' +
						'property-options/' +
						'{{utils.getComponent(instance.parent.components, "conditional.when").dataValue.split("_").pop()}}' +
						'?perPage={{limit}}&page={{page + 1}}',
					headers: [
						{
							key: 'authorization',
							value: 'Bearer {{Formio.apiToken}}'
						}
					]
				},
				selectValues: 'items',
				searchField: 'label',
				valueProperty: 'value',
				dataType: 'string',
				multiple: true
			}
		]
	}
];

export function getCustomConditionLogic(equal: boolean): string {
	return `
		var equal = ${equal};
		var whenComponent = utils.getComponent(instance.parent.components, 'conditional.when');
		var key = (whenComponent.dataValue || '').split('_').pop();
		
		if (!_.isEmpty(whenComponent.dataFieldTypeMapping) && whenComponent.dataValue != null) {
			var type = whenComponent.dataFieldTypeMapping[key];
			var urlOptionTypes = [
				'${CustomComponentType.extendedSelectField}', 
				'${CustomComponentType.extendedRadio}', 
				'${CustomComponentType.collateralTypeSelect}',
				'${CustomComponentType.hmdaLoanPurposeSelect}'
			];
			show = equal ? urlOptionTypes.indexOf(type) >= 0
					: urlOptionTypes.indexOf(type) < 0;
		}
		else {
			show = !equal;
		}

		component.key = show ? 'conditional.eq' : '';	
	`;
}

export async function getComponents(context: any): Promise<any> {
	const path = context.instance.options.editForm.path;
	const baseFormUrl = getBaseFormUrl(path);
	const editedComponentKey = `${getWidgetTrail(path)}_${context.data.key}`;
	return await getNestedComponents(context, baseFormUrl, path, editedComponentKey);
}

// eslint-disable-next-line max-lines-per-function
export async function getNestedComponents(
	context: any,
	currentUrl: string,
	originalUrl: string,
	editedComponentKey: string,
	options: any[] = [],
	recursionCounter = 0
): Promise<any> {
	if (recursionCounter > 100) {
		throw Error('Recursion limit reached!');
	}
	const form = await Formio.makeRequest(undefined, 'form', Formio.getProjectUrl() + '/form?path=' + currentUrl, 'get', null, undefined);
	if (form.length === 0) return [];

	const flattenedComponents = FormioUtils.flattenComponents(form[0].components, false);
	const allowedWidgets = getAllowedWidgets(originalUrl);

	for (const component in flattenedComponents) {
		// On the first pass we'll be at the top-most widget level
		// For now we are manually skipping anything not in the allowed list
		if (recursionCounter === 0 && !allowedWidgets.includes(flattenedComponents[component].type)) continue;

		if (flattenedComponents[component].formPath != null) {
			await getNestedComponents(context, flattenedComponents[component].formPath, originalUrl, editedComponentKey, options, recursionCounter + 1);
		} else if (
			getAllowedFields().includes(flattenedComponents[component].type) &&
			`${getWidgetTrail(currentUrl)}_${flattenedComponents[component].key}` !== editedComponentKey
		) {
			const path =
				flattenedComponents[component].dataFieldId != null
					? flattenedComponents[component].dataFieldId
					: flattenedComponents[component].path ?? flattenedComponents[component].key;

			const parentLabel = getWidgetLabels(currentUrl).join(' - ');
			const newOption = {
				value: `${getWidgetTrail(currentUrl)}_${path}`,
				label: `${flattenedComponents[component].label || flattenedComponents[component].component.key} (${parentLabel})`,
				dataFieldId: flattenedComponents[component].dataFieldId,
				type: flattenedComponents[component].type
			};
			options.push(newOption);
		}
	}
	return options;
}

export function getWidgetTrail(url: string): string {
	const widgetNames = getWidgetNames(url) as string[];
	return widgetNames.length > 0 ? widgetNames.slice(1).reduce((accumulator, currentValue) => `${accumulator}_${currentValue}`, widgetNames[0]) : '';
}

export function getAllowedWidgets(path: string): CustomComponentType[] {
	let allowedWidgets: CustomComponentType[] = [...getPrimaryRoleComponentTypes(), CustomComponentType.collateralInfo, CustomComponentType.loans];

	const topLevelWidget = getTopLevelWidget(path);
	if (topLevelWidget != null && !allowedWidgets.some(x => x === topLevelWidget)) {
		allowedWidgets.push(topLevelWidget);
	}

	return allowedWidgets;
}

export function getAllowedFields(): CustomComponentType[] {
	return [
		CustomComponentType.extendedTextField,
		CustomComponentType.extendedSelectField,
		CustomComponentType.extendedNumber,
		CustomComponentType.extendedPhoneNumber,
		CustomComponentType.extendedCurrency,
		CustomComponentType.extendedRadio,
		CustomComponentType.extendedTextArea,
		CustomComponentType.extendedDateTime,
		CustomComponentType.primaryBorrowerEntity,
		CustomComponentType.loans,
		CustomComponentType.collateralTypeSelect,
		CustomComponentType.hmdaLoanPurposeSelect
	];
}
