import { CustomComponentType, DataObject as DataObjectEnum, LoanRoleType } from '../../enums';
import { AggregatedFormDataModel, DataObject } from '@sageworks/jpi';
import {
	convertAllLoanRoleData,
	convertAllRelatedRoleData,
	convertBeneficialOwnerData,
	convertDataObjects,
	convertProposedLienData,
	createCustomerTypeLookup
} from './form-data-submission-data-mapper-utils';
import { SubmissionDataMapper } from './submission-data-mapper';
import { LoanRoleWithCustomerType } from '../../models';
import * as FormObjectFactory from './form-object-factory';
import { FormDataSubmissionDataMapperUtils } from '.';

export class FormDataSubmissionDataMapper extends SubmissionDataMapper {
	private readonly RepeatablePrimaryRoles = [LoanRoleType.CoBorrower, LoanRoleType.Guarantor, LoanRoleType.CoSigner, LoanRoleType.CreditApplicant];
	private aggregatedFormData: AggregatedFormDataModel = {};

	constructor(private dataObjects: { [key: string]: Array<DataObject> }) {
		super(convertDataObjects(dataObjects), 0);
	}

	setAggregatedFormData(aggregatedFormData: AggregatedFormDataModel) {
		const { proposedLiens = [], beneficialOwners = [], financialSubaccountMappings = [], creditReportAuthorizations = [] } = aggregatedFormData;
		this.aggregatedFormData = aggregatedFormData;

		const customerTypeLookup = createCustomerTypeLookup(this.dataObjects);
		const formattedLoanRoles = convertAllLoanRoleData(aggregatedFormData, this.dataObjects, customerTypeLookup);
		const formattedRelatedRoles = convertAllRelatedRoleData(aggregatedFormData, customerTypeLookup);
		const formattedBeneficialOwners = convertBeneficialOwnerData(beneficialOwners);
		const formattedLiens = convertProposedLienData(proposedLiens, this.dataObjects);

		this.setCreditReportAuthorizations(creditReportAuthorizations)
			.setBeneficialOwners(formattedBeneficialOwners)
			.setFinancialSubaccounts(financialSubaccountMappings);

		if (formattedLoanRoles.length > 0) {
			this.addPrimaryBorrower(formattedLoanRoles, formattedRelatedRoles);

			this.RepeatablePrimaryRoles.forEach(loanRoleType => {
				this.addRepeatingRole(formattedLoanRoles, loanRoleType, formattedRelatedRoles);
			});
		}

		if (formattedLiens.length > 0) {
			this.addLiens(formattedLiens);
		}

		return this;
	}

	protected addFinancialSubaccounts(roleMapping: LoanRoleWithCustomerType, roleSubmissionData: any, financialStatementType: CustomComponentType): void {
		super.addFinancialSubaccounts(roleMapping, roleSubmissionData, financialStatementType);

		this.addRealEstateFinancials(roleSubmissionData[financialStatementType]);
		this.addBusinessInvestments(roleSubmissionData[financialStatementType]);
	}

	private addRealEstateFinancials(financialStatementFormDataObject: any): void {
		const financialStatementId = financialStatementFormDataObject.data.id;

		if (!financialStatementId) {
			return;
		}

		const allRealEstateDataObjects = (this.aggregatedFormData.dataObjects ?? {})[DataObjectEnum.TypeEnum.RealEstateFinancials] ?? [];

		const filteredRealEstateDataObjects = (this.aggregatedFormData.realEstateFinancials ?? [])
			.filter(x => x.ownerInputDataID === financialStatementId)
			.map(x => allRealEstateDataObjects.find(realEstateFinancial => realEstateFinancial.rowId === x.id))
			.filter((x): x is DataObject => !!x);

		financialStatementFormDataObject.data[CustomComponentType.investmentRealEstate] = filteredRealEstateDataObjects.map(dataObject => ({
			form: FormObjectFactory.createFormObject(
				CustomComponentType.investmentRealEstate,
				FormDataSubmissionDataMapperUtils.convertDataObject(dataObject),
				this.factoryContext
			)
		}));
	}

	private addBusinessInvestments(financialStatementFormDataObject: any): void {
		const financialStatementId = financialStatementFormDataObject.data.id;

		if (!financialStatementId) {
			return;
		}

		const allBusinessInvestmentDataObjects: DataObject[] = (this.aggregatedFormData.dataObjects ?? {})[DataObjectEnum.TypeEnum.BusinessInvestments] ?? [];

		const filteredBusinessInvestmentDataObjects = (this.aggregatedFormData.businessInvestments ?? [])
			.filter(x => x.ownerInputDataId === financialStatementId)
			.map(x => allBusinessInvestmentDataObjects.find(businessInvestment => businessInvestment.rowId === x.id))
			.filter((x): x is DataObject => !!x);

		financialStatementFormDataObject.data[CustomComponentType.businessInvestment] = filteredBusinessInvestmentDataObjects.map(dataObject => ({
			form: FormObjectFactory.createFormObject(
				CustomComponentType.businessInvestment,
				FormDataSubmissionDataMapperUtils.convertDataObject(dataObject),
				this.factoryContext
			)
		}));
	}
}
