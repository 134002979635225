import { IPopupBodyComponent } from '../../popup';
import Component from 'formiojs/components/_classes/component/Component';
import EventEmitter from 'formiojs/EventEmitter';
import CustomerSearch from '../../customer-search/customer-search';
import { CustomComponentType, ContextDataProperty, FetchDataType } from '../../../../enums';
import { getContextDataValue } from '../../../../utils/context-data-helper/context-data-helper';
import { Customer, AutoFactory, CustomersService, ObjectPropertyValues } from '@sageworks/jpi';
import { AddCustomerResult } from '../../add-customer/add-customer-result';
import { AddNewPrimaryRole } from '../../add-customer/add-new-primary-role/add-new-primary-role';
import { ComponentDataHelper, DataObjectUtils } from '../../../../utils';
import { formatDataFieldIdKey } from '../../simple-inputs/extended-field-helper';
import { CustomerTypeUtils } from '../../../../utils/customer-type-utils';
import { convertEntityTypeToCustomerType, convertCustomerTypeToEntityType } from '../../../../utils/customer-type-utils/customer-type-utils';

enum PopupState {
	MainMenu = 'MainMenu',
	AddNewPrimary = 'AddNewPrimary'
}

enum AddType {
	AddNew = 'AddNew',
	AddExisting = 'AddExisting',
	EditExisting = 'EditExisting'
}

export class PrimaryRoleAddRowPopup extends Component implements IPopupBodyComponent {
	public resultPromise!: Promise<any>;
	public resultPromiseResolve: any;
	private customerSearch: CustomerSearch;
	private popupState: PopupState;
	private addNewPrimaryRole: AddNewPrimaryRole;

	private get initialPopupState(): PopupState {
		if (this.isLender || this.applicationEntities?.length > 0) {
			return PopupState.MainMenu;
		}
		return PopupState.AddNewPrimary;
	}

	private get userInfo() {
		return getContextDataValue(this, ContextDataProperty.CurrentUserInfo);
	}

	private get isLender() {
		return this.userInfo == null || this.userInfo?.proxyUserId != null;
	}

	private get roleType() {
		return this.component.roleType;
	}

	private get applicationEntities() {
		return this.component.applicationEntities;
	}

	private get modalTitle(): string {
		return `Add New ${this.roleType}`;
	}

	private get entityTypes() {
		return this.component.entityTypes;
	}

	constructor(component: any, options: any, data: object) {
		super(component, options, data);

		this.events = new EventEmitter({});
		this.customerSearch = new CustomerSearch(
			CustomerSearch.schema({
				ignoredCustomerIds: this.component.ignoredCustomerIds,
				allowedEntityTypes: this.convertEntityToCustomerType(this.entityTypes)
			}),
			{ ...options, events: this.events },
			{}
		);
		this.addNewPrimaryRole = new AddNewPrimaryRole(
			AddNewPrimaryRole.schema({}),
			{ ...options, events: this.events, entityTypes: this.entityTypes, roleType: this.roleType },
			{}
		);
		// skip the pop up if it is not needed
		if (this.applicationEntities?.length < 1 && this.entityTypes?.length === 1 && !this.isLender) {
			this.addBlankNewRoleForType(this.entityTypes[0]);
		}

		this.popupState = this.initialPopupState;
	}

	public preClose = () => {
		return Promise.resolve();
	};

	public static schema(...extend: any) {
		return Component.schema(
			{
				label: 'Add Customer',
				type: CustomComponentType.primaryRoleAddRowPopup,
				key: CustomComponentType.primaryRoleAddRowPopup,
				roleType: null,
				input: false
			},
			...extend
		);
	}

	public init() {
		super.init();
		this.resultPromise = new Promise(r => (this.resultPromiseResolve = r));
	}

	public render(): any {
		return this.renderTemplate('primaryRoleAddRowPopup', {
			isLender: this.isLender,
			modalTitle: this.modalTitle,
			popupState: this.popupState,
			applicationEntities: this.applicationEntities,
			customerSearch: this.customerSearch.render(),
			addNewPrimaryRole: this.addNewPrimaryRole.render()
		});
	}

	public async attach(element: any) {
		await super.attach(element);

		this.loadRefs(element, {
			addNewCustomerButton: 'single',
			addExistingEntity: 'multiple',
			customerSearch: 'single',
			modal: 'single',
			addNewPrimaryRole: 'single'
		});

		const { addNewCustomerButton, addExistingEntity, modal, customerSearch, addNewPrimaryRole } = this.refs as any;

		this.addEventListener(addNewCustomerButton, 'click', this.onAddNewCustomerClick);

		if (customerSearch != null) {
			await this.customerSearch.attach(this.refs.customerSearch);
			this.on('customerSearchSelect', (selectedCustomer: Customer) => this.addFromCustomerSearch(selectedCustomer), false);
		}

		if (addNewPrimaryRole != null) {
			await this.addNewPrimaryRole.attach(this.refs.addNewPrimaryRole);
			this.on('entityTypeSelect', (selectedCustomerType: CustomComponentType) => this.addBlankNewRoleForType(selectedCustomerType), false);
		}

		if (customerSearch != null) {
			this.addEventListener(modal, 'mouseup', (e: Event) => this.onModalBodyClick(e));
		}

		addExistingEntity.forEach((entity: HTMLElement) => {
			this.addEventListener(entity, 'click', (e: Event) => this.onAddExistingEntity(e));
		});
	}

	private onModalBodyClick(e: Event) {
		if (!(this.refs.customerSearch as any).contains(e.target)) {
			e.preventDefault();
			this.customerSearch.hideOptions();
		}
	}

	private onAddNewCustomerClick = (e: Event) => {
		e.preventDefault();
		if (this.entityTypes.length === 1) {
			this.addBlankNewRoleForType(this.entityTypes[0]);
			return;
		}
		this.popupState = PopupState.AddNewPrimary;
		this.redraw();
	};

	private onAddExistingEntity = (e: Event) => {
		e.preventDefault();
		this.savePopup(e, AddType.AddExisting);
	};

	async savePopup(e: any, addType: AddType) {
		let addCustomerResult: AddCustomerResult;
		switch (addType) {
			case AddType.AddExisting: {
				const customersService = AutoFactory.get(CustomersService);
				const customer = await customersService.getById(e.target.id);
				addCustomerResult = await this.buildExistingCustomerResult(customer);
				this.resultPromiseResolve(addCustomerResult);
				break;
			}
		}
	}

	private async buildExistingCustomerResult(customer: Customer) {
		const customerType = customer.type;
		let submissionKey = convertCustomerTypeToEntityType(customerType as Customer.TypeEnum);

		let dataObject: ObjectPropertyValues | null = null;

		if (customer.id != null && customer.id > 0) {
			const dataObjectType = DataObjectUtils.customerTypeToDataObjectType(customer.type);
			dataObject = await ComponentDataHelper.fetchData<ObjectPropertyValues>(this, {
				fetchType: FetchDataType.DataObject,
				fetchContext: { type: dataObjectType, id: customer.id }
			});
		}

		const data: any = { id: customer.id };
		if (dataObject != null) {
			(dataObject.dataFieldValues ?? []).forEach(x => {
				if (x.id) {
					data[formatDataFieldIdKey(String(x.id))] = x.value;
				}
			});
		}

		return {
			customerType: customerType,
			[submissionKey]: { data }
		} as AddCustomerResult;
	}

	private async addFromCustomerSearch(selectedCustomer: Customer) {
		const result = await this.buildExistingCustomerResult(selectedCustomer);
		this.resultPromiseResolve(result);
	}

	private async addBlankNewRoleForType(roleType: CustomComponentType) {
		const newCustomer: Customer = {
			id: -1,
			type: convertEntityTypeToCustomerType(roleType),
			addresses: []
		};

		const result = await this.buildExistingCustomerResult(newCustomer);
		this.resultPromiseResolve(result);
	}

	private convertEntityToCustomerType(customComponentTypes: CustomComponentType[]): Customer.TypeEnum[] {
		return customComponentTypes.map(type => CustomerTypeUtils.convertEntityTypeToCustomerType(type));
	}
}
