import editForm from './plaid-component.form';
import { CustomComponentLabel, CustomComponentType } from '../../../enums';
import VueFormioComponent from '../base/vue-formio-component/vue-formio-component';
import Component from 'formiojs/components/_classes/component/Component';
import { locateClosestLoanRoleParent } from '../../../utils/locate-parent-helper/locate-parent-helper';
import { DataObjectMapper } from '../../../utils';

export default class PlaidWidget extends VueFormioComponent {
	static schema(...extend: any) {
		return VueFormioComponent.schema(
			{
				label: CustomComponentLabel()[CustomComponentType.plaid],
				type: CustomComponentType.plaid,
				key: CustomComponentType.plaid,
				addAnother: 'Add Bank Account(s)'
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: 'Plaid',
			group: '',
			weight: 10,
			schema: PlaidWidget.schema()
		};
	}

	private loanRoleWidget: Component | undefined;

	constructor(component: any, options: any, data: object) {
		super(component, options, data);

		this.loanRoleWidget = locateClosestLoanRoleParent(this);
	}

	protected updateVueComponentProps(data: any) {
		this.vueComponent.$props.customerId = data.id;
		this.vueComponent.$props.parentRoleType = DataObjectMapper.roleTypeLookup[this.loanRoleWidget?.type ?? ''];
		this.vueComponent.$props.parentRoleId = data.parentLoanRoleId;
	}
}
