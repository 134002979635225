import Wizard from 'formiojs/Wizard';
import Component from 'formiojs/components/_classes/component/Component';
import { Utils as FormioUtils } from 'formiojs';
import { isRepeaterWidget } from '../../utils/form-path-utils';
import _ from 'lodash';
import Components from 'formiojs/components/Components';
import { getContextDataValue } from '../../utils/context-data-helper/context-data-helper';
import { ContextDataProperty, FormioEventName, TopLevelComponentTypes, CustomComponentType } from '../../enums';
import { LocateParentHelper, EventUtils } from '../../utils';

export default class ModifiedWizard extends Wizard {
	protected _topLevelCustomComponent: Component | undefined;

	// @ts-ignore
	get renderContext() {
		return {
			wizardKey: this.wizardKey,
			isBreadcrumbClickable: this.isBreadcrumbClickable(),
			isSubForm: !!this.parent && this.root?.component?.type !== 'wizard',
			panels: this.allPages?.length ? this.allPages : this.pages,
			buttons: this.buttons,
			currentPage: this.page
		};
	}

	// being used to "fix" our "extraneous" submission data issues
	get editMode(): boolean {
		return true;
	}

	set editMode(value: boolean) {
		// intentionally empty for now
	}

	get topLevelCustomComponent(): Component | undefined {
		if (this._topLevelCustomComponent == null) {
			this._topLevelCustomComponent = LocateParentHelper.locateParentComponent(this, TopLevelComponentTypes, 10);
		}

		return this._topLevelCustomComponent;
	}

	get isRepeaterWidget() {
		return isRepeaterWidget(this.topLevelCustomComponent?.type as CustomComponentType);
	}

	constructor(element: any, options: Object) {
		super(element, options);

		const isRootWizard = this.options.parentPath == null ? true : false;
		if (isRootWizard) {
			this.on(FormioEventName.showParentNav, this.toggleNavButtons.bind(this, true), false);
			this.on(FormioEventName.hideParentNav, this.toggleNavButtons.bind(this, false), false);
			this.on(FormioEventName.rootWizardNextPage, this.nextPage.bind(this), false);
			this.on(FormioEventName.rootWizardPreviousPage, this.prevPage.bind(this), false);
		}
	}

	// eslint-disable-next-line max-lines-per-function
	transformPages() {
		const allComponents = [] as Component[];
		const components = this.getSortedComponents(this);
		let defferedComponents = [] as Component[];
		this.allPages = [];

		// Get all components including all nested components and line up in the correct order
		// eslint-disable-next-line max-lines-per-function
		const getAllComponents = (nestedComp: Component, compsArr: Component[], pushAllowed = true) => {
			const nestedPages = [] as any;
			const currentComponents = (nestedComp as any)?.subForm ? this.getSortedComponents((nestedComp as any).subForm) : nestedComp?.components || [];
			const visibleComponents = currentComponents.filter((comp: any) => comp._visible);
			const additionalComponents = visibleComponents.filter((comp: any) => !comp.subForm);
			let hasNested = false;

			FormioUtils.eachComponent(
				visibleComponents,
				(comp: Component) => {
					if (comp.component.type === 'panel' && comp?.parent.wizard && !getAllComponents(comp, compsArr, false)) {
						if (pushAllowed) {
							this.setRootPanelId(comp);
							nestedPages.push(comp);
						}
						hasNested = true;
					}

					if (comp && (comp as any).subForm) {
						return true;
					}

					if (isRepeaterWidget(comp.component.type)) {
						return true;
					}
				},
				true
			);

			if (nestedComp.component.type === 'panel') {
				if (!hasNested && pushAllowed) {
					this.setRootPanelId(nestedComp);
					compsArr.push(nestedComp);
				}
				if (hasNested && additionalComponents.length) {
					const newComp = _.clone(nestedComp);
					newComp.components = additionalComponents;
					this.setRootPanelId(newComp);
					defferedComponents.push(newComp);
				}
			}
			if (pushAllowed) {
				compsArr.push(...defferedComponents, ...nestedPages);
				defferedComponents = [];
			}
			return hasNested;
		};

		components.forEach(component => {
			getAllComponents(component, allComponents);
		}, []);

		this.allPages = allComponents;
	}

	get currentWizardPage() {
		if (this.refs[this.wizardKey] == null) {
			this.loadRefs(this.element, {
				[this.wizardKey]: 'single'
			});
		}

		return this.refs[this.wizardKey];
	}

	/*
	 *	Copied from Formio with tweaks to the get the root pointing
	 *	to the correct form
	 */

	establishPages(data = this.data) {
		this.pages = [];
		this.prefixComps = [];
		this.suffixComps = [];
		const visible: any[] = [];
		const currentPages: { [key: string]: any } = {};

		const pageOptions = _.clone(this.options);
		pageOptions.root = this;

		if (this.components && this.components.length) {
			this.components.map(page => {
				if (page.component.type === 'panel') {
					currentPages[page.component.key || page.component.title] = page;
				}
			});
		}
		if (this.originalComponents) {
			this.originalComponents.forEach(item => {
				this.evaluateAndBuildComponents(item, data, pageOptions, currentPages, visible);
			});
		}

		if (this.pages.length) {
			this.emit('pagesChanged', null);
		}

		this.transformPages();
		if (this.allPages && this.allPages.length) {
			this.pages = this.allPages;
		}

		return visible;
	}

	get isLender(): boolean {
		return getContextDataValue(this, ContextDataProperty.IsLender);
	}

	private evaluateAndBuildComponents(item: any, data: any, pageOptions: any, currentPages: { [key: string]: any }, visible: any[]) {
		if (item.type === 'panel') {
			if (!item.key) {
				item.key = item.title;
			}
			let page = currentPages[item.key];
			const isVisible = FormioUtils.checkCondition(item, data, data, this.component, this) && !item.hidden && !(item.lenderOnly && !this.isLender);
			if (isVisible) {
				visible.push(item);
				if (page) {
					this.pages.push(page);
				}
			}
			if (!page && isVisible) {
				page = this.createComponent(item, pageOptions, null, null);
				page.visible = isVisible;
				this.pages.push(page);
				page.eachComponent((component: any) => {
					component.page = this.pages.length - 1;
				});
			} else if (page && !isVisible) {
				this.removeComponent(page);
			}
		} else if (item.type !== 'button') {
			this.createPrefixAndSuffixComps(item, pageOptions);
		}
	}

	private createPrefixAndSuffixComps(item: any, pageOptions: any) {
		if (!this.pages.length) {
			this.prefixComps.push(this.createComponent(item, pageOptions, null, null));
		} else {
			this.suffixComps.push(this.createComponent(item, pageOptions, null, null));
		}
	}

	createComponent(component: any, options: any, data: any, before: any) {
		if (!component) return;

		options = options || this.options;
		data = data || this.data;
		options.parent = this;
		options.parentVisible = this.visible;

		// Only override the root if it's not provided
		if (options.root == null) {
			options.root = this.root || this;
		}

		options.skipInit = true;
		if (!this.isInputComponent && this.component.shouldIncludeSubFormPath) {
			component.shouldIncludeSubFormPath = true;
		}
		const comp = Components.create(component, options, data);

		const path = this.calculateComponentPath(comp);
		if (path) {
			comp.path = path;
		}
		comp.init();
		if (component.internal) {
			return comp;
		}

		if (before) {
			this.createBefore(before, comp);
		} else {
			this.components.push(comp);
		}
		return comp;
	}

	public attachHeader() {
		const isAllowPrevious = this.isAllowPrevious();

		if (this.isBreadcrumbClickable() || isAllowPrevious) {
			this.refs[`${this.wizardKey}-link`].forEach((link, index) => {
				if (!isAllowPrevious || index <= this.enabledIndex) {
					this.addEventListener(link, 'click', e => this.headerItemSelected(e, index));
					this.addEventListener(link, 'keypress', e => {
						if (EventUtils.isClickEquivalent(e)) {
							this.headerItemSelected(e, index);
						}
					});
				}
			});
		}
	}

	private headerItemSelected(event: any, pageIndex: number) {
		this.emit('wizardNavigationClicked', this.pages[pageIndex]);
		event.preventDefault();
		return this.setPage(pageIndex).then(() => {
			this.emitWizardPageSelected(pageIndex);
		});
	}

	private createBefore(before: any, comp: any) {
		const index = _.findIndex(this.components, { id: before.id });
		if (index !== -1) {
			this.components.splice(index, 0, comp);
		} else {
			this.components.push(comp);
		}
	}

	private toggleNavButtons(show: boolean) {
		for (const button of Object.values(this.buttons)) {
			const buttonElement = (this.refs[`${this.wizardKey}-${button.name}`] as any) as HTMLButtonElement;

			if (buttonElement) {
				show ? buttonElement.classList.remove('d-none') : buttonElement.classList.add('d-none');
			}
		}
	}

	protected isFirstPage(): boolean {
		return this.page === 0;
	}

	// copied from formio and modified it (check below comments) so that we are always going to the next page not only when there is no validation errors
	nextPage() {
		// Read-only forms should not worry about validation before going to next page, nor should they submit.
		if (this.options.readOnly) {
			return this.beforePage().then(() => {
				return this.setPage(this.getNextPage()).then(() => {
					this.emitNextPage();
				});
			});
		}

		this.checkValidity(this.submission.data, true, this.submission.data);
		this.emit('validation-errors', { pageId: this.component.id, errors: this.errors });

		// removed the code below from inside an if statement that was running if this.checkValidity returned true
		// removed the the code that was running if invalid (showing the error messages and highlighting the components that had validation errors)
		return this.beforePage().then(() => {
			return this.setPage(this.getNextPage()).then(() => {
				if (!(this.options.readOnly || this.editMode) && this.enabledIndex < this.page) {
					this.enabledIndex = this.page;
					this.redraw();
				}

				this.emitNextPage();
			});
		});
	}

	async validateAndSetPage(num: number, showExistingValidationErrors: boolean = false, previousPageId: number): Promise<any> {
		this.checkValidity(this.submission.data, true, this.submission.data);
		this.emit('validation-errors', { pageId: this.component.id, errors: this.errors });
		this.emit(FormioEventName.rootWizardSaveOnPageChange, { parentWidgetId: this.topLevelCustomComponent?.id, pageId: previousPageId });
		return this.setPage(num, showExistingValidationErrors);
	}

	// copied from formio and slightly modified it
	// eslint-disable-next-line max-lines-per-function
	async setPage(num: number, showExistingValidationErrors: boolean = false): Promise<any> {
		if (num === this.page) {
			return Promise.resolve();
		}

		if (num >= 0 && num < this.pages.length) {
			this.page = num;
			this.pageFieldLogic(num);
			this.getNextPage();
			let parentNum = num;

			if (this.hasExtraPages) {
				const pageFromPages = this.pages[num];
				const pageFromComponents = this.components[num];
				if (!pageFromComponents || pageFromPages.id !== pageFromComponents.id) {
					parentNum = this.components.findIndex(comp => {
						return comp.id === this.pages[parentNum].rootPanelId;
					});
				}
			}
			if (!this._seenPages.includes(parentNum)) {
				this._seenPages = this._seenPages.concat(parentNum);
			}
			return await this.redraw().then(() => {
				this.checkData(this.submission.data);
				// start of changes
				if (showExistingValidationErrors) {
					this.checkValidity(this.submission.data, true, this.submission.data);
					this.currentPage.components.forEach((comp: any) => comp.setPristine(false));
				}
				// end of changes
			});
		} else if (!this.pages.length) {
			return await this.redraw();
		}
		return Promise.reject('Page not found');
	}
}
