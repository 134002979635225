
import Vue, { PropType } from 'vue';
import { mapState } from 'vuex';
import { Customer } from '@sageworks/jpi/';
import CustomerType = Customer.TypeEnum;
import { CustomComponentLabel, CustomComponentType } from '@sageworks/dynamic-forms';
export default Vue.extend({
	name: 'AddNewPrimaryRole',
	props: {
		entityTypes: {
			type: Array as PropType<Customer.TypeEnum[]>,
			default: () => []
		},
		componentType: {
			type: String as PropType<CustomComponentType>
		}
	},
	computed: {
		...mapState('MultiLoanApplicationForm', ['renderData']),
		roleType() {
			return CustomComponentLabel()[this.componentType];
		}
	},
	methods: {
		getLabel(item: CustomerType) {
			switch (item) {
				case CustomerType.Business:
					return 'Business';
				case CustomerType.Person:
					return 'Person';
				case CustomerType.Farm:
					return 'Farm';
			}
			return item ? item + '' : '';
		}
	}
});
