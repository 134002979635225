export function getSuccessOptions() {
	return {
		...getDefaultOptions(),
		variant: 'success',
	};
}

export function getFailureOptions() {
	const failureOptions = {
		...getDefaultOptions(),
		variant: 'danger',
	};
	// want failure toasts to last longer than normal toasts so they aren't missed by user
	failureOptions.autoHideDelay = 10000;
	return failureOptions;
}

export function getDefaultOptions() {
	return {
		toaster: 'b-toaster-bottom-right',
		noCloseButton: true,
		autoHideDelay: 3000,
		solid: true
	};
}
