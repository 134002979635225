import { IHasId } from '../interfaces/has-id';
import { IHasChildren } from '../interfaces/has-children';

export function findObject<T extends IHasId & IHasChildren>(id: number, currentObject: T): T | null {
	var i: number, currentChild: T, result: T | null;

	if (id === currentObject.id) {
		return currentObject;
	} else {
		if (currentObject.children.length === 0) return null;
		for (i = 0; i < currentObject.children.length; i += 1) {
			var n = <T>currentObject.children[i];
			if (n == null) continue;
			currentChild = n;

			result = findObject(id, currentChild);

			if (result !== null) {
				return result;
			}
		}

		return null;
	}
}
