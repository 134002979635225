import Component from 'formiojs/components/_classes/component/Component';
import CustomerSearchOptions from './customer-search-options';
import { AutoFactory, CustomersService, Customer } from '@sageworks/jpi';
import _ from 'lodash';

export default class CustomerSearch extends Component {
	private customerSearchOptions: CustomerSearchOptions;
	private MIN_SEARCH: number = 3;
	private static DEFAULT_DEBOUNCE: number = 500;
	private static MAX_SEARCH_RESULTS: number = 25;
	private customersService: CustomersService;
	private searchCounter!: number;
	private searchSpinner?: HTMLElement;

	private get debouncetime() {
		return this.component.debouncetime ?? CustomerSearch.DEFAULT_DEBOUNCE;
	}

	private get ignoredCustomerIds() {
		return this.component.ignoredCustomerIds ?? [];
	}

	private get allowedEntityTypes() {
		return this.component.allowedEntityTypes ?? this.defaultAllowedEntityTypes;
	}

	private get defaultAllowedEntityTypes() {
		return [Customer.TypeEnum.Business, Customer.TypeEnum.Person, Customer.TypeEnum.NonProfit, Customer.TypeEnum.Farm];
	}

	constructor(component: any, options: object, data: object) {
		super(component, options, data);
		this.customerSearchOptions = new CustomerSearchOptions(null, options, {});
		this.customersService = AutoFactory.get(CustomersService);
		this.searchCounter = 0;
	}

	public render() {
		return this.renderTemplate('customerSearch', {
			searchOptions: this.customerSearchOptions.render()
		});
	}

	public async attach(element: any) {
		await super.attach(element);

		this.loadRefs(element, {
			searchInput: 'single',
			searchOptions: 'single',
			searchControls: 'single',
			searchSpinner: 'single'
		});

		const { searchInput, searchOptions, searchSpinner } = this.refs as any;

		// Cache the loading spinnner so we don't have to redraw everything when only the spinner needs to be toggled
		this.searchSpinner = searchSpinner;

		this.addEventListener(
			searchInput,
			'input',
			_.debounce((e: any) => this.search(e?.target?.value ?? ''), this.debouncetime)
		);
		this.addEventListener(searchInput, 'click', () => this.onSearchClick());

		await this.customerSearchOptions.attach(searchOptions);
		this.customerSearchOptions.hide();
	}

	public hideOptions() {
		this.customerSearchOptions.hide();
	}

	// eslint-disable-next-line max-lines-per-function
	private async search(name: string) {
		// We need to track the local searchCounter and the component's searchCounter as multiple async searches could be executed
		// 	and we only want to consider updating the search options with the latest search request
		const searchCounter = (this.searchCounter = this.searchCounter + 1);
		this.setSpinnerVisibility(true);

		if (name.length < this.MIN_SEARCH) {
			this.customerSearchOptions.hide();
			this.setSpinnerVisibility(false);
			return;
		}

		try {
			const searchResults = await Promise.all([
				this.customersService.getPaged(
					1,
					CustomerSearch.MAX_SEARCH_RESULTS,
					undefined,
					undefined,
					name,
					undefined,
					undefined,
					undefined,
					undefined,
					false
				),
				this.customersService.getPaged(
					1,
					CustomerSearch.MAX_SEARCH_RESULTS,
					name,
					undefined,
					undefined,
					undefined,
					undefined,
					undefined,
					undefined,
					false
				)
			]);

			// Ensure we are on the latest search request to update the search options
			if (searchCounter === this.searchCounter) {
				const fullResults = _.unionBy(searchResults[0].items ?? [], searchResults[1].items ?? [], 'id');

				const items = fullResults.filter(x => x.id && !this.ignoredCustomerIds.includes(x.id) && this.allowedEntityTypes.includes(x.type));
				_.sortBy(items, 'id').forEach(function(item: Customer) {
					if (item.uniqueIdentifier != null && item.uniqueIdentifier.length > 0) {
						item.name = `${item.name} [${item.uniqueIdentifier}]`;
					}
				});

				this.customerSearchOptions.setSearchResults(items.slice(0, Math.min(items.length, CustomerSearch.MAX_SEARCH_RESULTS)));
			}
		} finally {
			if (searchCounter === this.searchCounter) {
				this.setSpinnerVisibility(false);
			}
		}
	}

	private onSearchClick() {
		this.customerSearchOptions.checkAndShowSearchResults();
	}

	private setSpinnerVisibility(isOpen: boolean) {
		if (this.searchSpinner) {
			this.searchSpinner.style.visibility = isOpen ? 'visible' : 'hidden';
		}
	}
}
