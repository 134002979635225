import { CustomComponentType } from '../../../../enums';
import { LoanApplication } from '@sageworks/jpi';

export function customConditional(instance: any) {
	if (instance.component.editMode) {
		const hasBusinessInfo = instance.data[CustomComponentType.businessInfo]?.data?.id != null;
		const hasPersonalInfo = instance.data[CustomComponentType.personalInfo]?.data?.id != null;
		const hasFarmInfo = instance.data[CustomComponentType.farmInfo]?.data?.id != null;

		if (hasBusinessInfo && instance.component.type === CustomComponentType.businessInfo) {
			return true;
		}

		if (hasPersonalInfo && instance.component.type === CustomComponentType.personalInfo) {
			return true;
		}

		if (hasFarmInfo && instance.component.type === CustomComponentType.farmInfo) {
			return true;
		}

		return false;
	} else {
		const radioSelectValue = instance.data.entityTypeRadio;
		if (radioSelectValue === 'person' && instance.component.type === CustomComponentType.personalInfo) {
			return true;
		}

		if (radioSelectValue === 'business' && instance.component.type === CustomComponentType.businessInfo) {
			return true;
		}

		if (radioSelectValue === 'farm' && instance.component.type === CustomComponentType.farmInfo) {
			return true;
		}

		return false;
	}
}
// eslint-disable-next-line max-lines-per-function
export function getRadioSchema(editMode: boolean, applicationType: LoanApplication.TypeEnum, defaultValue: string): any {
	const business = [
		{
			label: 'Business',
			value: 'business'
		}
	];
	const person = [
		{
			label: 'Person',
			value: 'person'
		}
	];
	const farm = [
		{
			label: 'Farm',
			value: 'farm'
		}
	];

	return {
		label: 'Add New:',
		key: 'entityTypeRadio',
		values: applicationType === LoanApplication.TypeEnum.Farm ? [...person, ...farm] : [...person, ...business],
		inline: true,
		defaultValue,
		customConditional: `
			show = !component.editMode
		`,
		editMode: editMode
	};
}
