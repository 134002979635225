import { FormioTemplate } from '@/enums';
import { CustomComponentType, FormioComponentType } from '@sageworks/dynamic-forms';

export const formioComponentToTemplateMapping = new Map<string, FormioTemplate>([
	[FormioComponentType.Panel, FormioTemplate.FormioPanel],
	[FormioComponentType.Fieldset, FormioTemplate.FormioFieldset],
	[FormioComponentType.Table, FormioTemplate.FormioTable],
	[FormioComponentType.Columns, FormioTemplate.FormioColumns],
	[FormioComponentType.Content, FormioTemplate.FormioContent],
	[FormioComponentType.HTML, FormioTemplate.FormioHtml],
	[FormioComponentType.Well, FormioTemplate.FormioWell],
	[FormioComponentType.Form, FormioTemplate.FormioForm],
	[FormioComponentType.Tabs, FormioTemplate.FormioTabs]
]);

export const customComponentToTemplateMapping = new Map<string, FormioTemplate>([
	[CustomComponentType.loans, FormioTemplate.FormioForm],
	[CustomComponentType.primaryBorrowerEntity, FormioTemplate.FormioRoleRepeater],
	[CustomComponentType.coBorrowerEntity, FormioTemplate.FormioRoleRepeater],
	[CustomComponentType.guarantorEntity, FormioTemplate.FormioRoleRepeater],
	[CustomComponentType.coSignerEntity, FormioTemplate.FormioRoleRepeater],
	[CustomComponentType.creditApplicantEntity, FormioTemplate.FormioRoleRepeater],
	[CustomComponentType.collateralInfo, FormioTemplate.FormioRoleRepeater],
	[CustomComponentType.personalInfo, FormioTemplate.FormioEntityInfoForm],
	[CustomComponentType.businessInfo, FormioTemplate.FormioEntityInfoForm],
	[CustomComponentType.nonprofitInfo, FormioTemplate.FormioEntityInfoForm],
	[CustomComponentType.farmInfo, FormioTemplate.FormioEntityInfoForm],
	[CustomComponentType.personalFinancialStatement, FormioTemplate.FormioForm],
	[CustomComponentType.income, FormioTemplate.FormioRoleRepeater],
	[CustomComponentType.expense, FormioTemplate.FormioRoleRepeater],
	[CustomComponentType.otherLiabilities, FormioTemplate.FormioRoleRepeater],
	[CustomComponentType.cashAccount, FormioTemplate.FormioRoleRepeater],
	[CustomComponentType.stocksBondsBusinessHoldings, FormioTemplate.FormioRoleRepeater],
	[CustomComponentType.nonInvestmentRealEstate, FormioTemplate.FormioRoleRepeater],
	[CustomComponentType.lifeInsurance, FormioTemplate.FormioRoleRepeater],
	[CustomComponentType.investmentRealEstate, FormioTemplate.FormioRoleRepeater],
	[CustomComponentType.retirementAccount, FormioTemplate.FormioRoleRepeater],
	[CustomComponentType.businessInvestment, FormioTemplate.FormioBusinessInvestments],
	[CustomComponentType.beneficialOwnership, FormioTemplate.FormioRelatedRoleRepeater],
	[CustomComponentType.beneficialOwnershipVue, FormioTemplate.FormioRelatedRoleRepeater],
	[CustomComponentType.authorizedSigner, FormioTemplate.FormioRelatedRoleRepeater],
	[CustomComponentType.creditReport, FormioTemplate.FormioCreditReport],
	[CustomComponentType.otherAssets, FormioTemplate.FormioRoleRepeater],
	[CustomComponentType.plaid, FormioTemplate.FormioPlaid],
]);
