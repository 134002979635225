import { AutoFactory, OnlinePortalApplicationContactInformationService, OnlinePortalApplicationContactInformation } from '@sageworks/jpi';
import { defineModule, localActionContext } from 'direct-vuex';

export interface ContactInformationModuleState {
	contactInfo: OnlinePortalApplicationContactInformation;
}

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => localActionContext(context, ContactInformationModule);

const ContactInformationModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			contactInfo: {
				name: '',
				phone: '',
				email: '',
				imageURL: ''
			} as OnlinePortalApplicationContactInformation
		} as ContactInformationModuleState;
	},
	mutations: {
		SET_CONTACT_INFO(state, contactInfo: OnlinePortalApplicationContactInformation) {
			state.contactInfo = contactInfo;
		}
	},
	actions: {
		async fetchContactInformation(context): Promise<void> {
			const { commit } = actionContext(context);
			const contactInformationService = AutoFactory.get(OnlinePortalApplicationContactInformationService);
			let contactInfo = {
				name: '',
				phone: '',
				email: '',
				imageURL: ''
			} as OnlinePortalApplicationContactInformation;

			try {
				contactInfo = await contactInformationService.getUserContact();
			} catch (err) {
				contactInfo = {
					name: '',
					phone: '',
					email: '',
					imageURL: ''
				} as OnlinePortalApplicationContactInformation;
			}
			commit.SET_CONTACT_INFO(contactInfo);
		}
	}
});

export default ContactInformationModule;
