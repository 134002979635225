import { DataFieldOption, UserDefinedDataFieldPostRequest } from '@sageworks/jpi';

export function buildAddCustomFieldPayload(
	name: string,
	inputFormat: UserDefinedDataFieldPostRequest.DataFieldInputFormatEnum,
	options: NodeList,
	formatString?: UserDefinedDataFieldPostRequest.DataFieldFormatStringEnum
): UserDefinedDataFieldPostRequest {
	if (name == null || inputFormat == null) {
		throw new Error('Cannot create custom field when name or format type is null');
	}

	const request: UserDefinedDataFieldPostRequest = {
		name: name,
		dataFieldInputFormat: inputFormat,
		dataFieldFormatString: formatString ?? getDefaultFormatString(inputFormat),
		options: inputFormat === UserDefinedDataFieldPostRequest.DataFieldInputFormatEnum.Dropdown ? convertOptionsFromInput(options) : undefined
	};

	return request;
}

function getDefaultFormatString(
	inputFormat: UserDefinedDataFieldPostRequest.DataFieldInputFormatEnum
): UserDefinedDataFieldPostRequest.DataFieldFormatStringEnum | undefined {
	switch (inputFormat) {
		case UserDefinedDataFieldPostRequest.DataFieldInputFormatEnum.Text:
			return UserDefinedDataFieldPostRequest.DataFieldFormatStringEnum.TextLength200;
		case UserDefinedDataFieldPostRequest.DataFieldInputFormatEnum.Numeric:
			return UserDefinedDataFieldPostRequest.DataFieldFormatStringEnum.N2;
		case UserDefinedDataFieldPostRequest.DataFieldInputFormatEnum.Currency:
			return UserDefinedDataFieldPostRequest.DataFieldFormatStringEnum.C2;
		case UserDefinedDataFieldPostRequest.DataFieldInputFormatEnum.Percent:
			return UserDefinedDataFieldPostRequest.DataFieldFormatStringEnum.P2;
		case UserDefinedDataFieldPostRequest.DataFieldInputFormatEnum.Date:
			return UserDefinedDataFieldPostRequest.DataFieldFormatStringEnum.YyyyMm;
		case UserDefinedDataFieldPostRequest.DataFieldInputFormatEnum.Radio:
			return UserDefinedDataFieldPostRequest.DataFieldFormatStringEnum.YesNo;
		case UserDefinedDataFieldPostRequest.DataFieldInputFormatEnum.Dropdown:
			return undefined;
		default:
			return UserDefinedDataFieldPostRequest.DataFieldFormatStringEnum.Other;
	}
}

function convertOptionsFromInput(options: NodeList): DataFieldOption[] {
	let dataFieldOptions: DataFieldOption[] = [];

	options.forEach(option => {
		dataFieldOptions.push({
			label: (option as HTMLInputElement).value,
			value: 0 as any
		} as DataFieldOption);
	});

	return dataFieldOptions;
}
