module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }

  
	for (var index=0; index<ctx.component.components.length; index++) {
		var tab = ctx.component.components[index];
 
		var contiansVisibleSubComponents = ctx.instance.tabs[index].some(component => {
			return component._visible == true;
		});
;
__p += '\n	<div class="mb-2 card" style="display: ' +
((__t = ( (tab.hidden || !contiansVisibleSubComponents) ? 'none' : 'block' )) == null ? '' : __t) +
';">\n		<div class="card-header bg-default">\n			<span class="mb-0 card-title"> ' +
((__t = ( ctx.t(tab.label) )) == null ? '' : __t) +
'</span>\n		</div>\n		<div class="card-body" ref="' +
((__t = ( ctx.tabKey )) == null ? '' : __t) +
'">\n			' +
((__t = ( ctx.tabComponents[index] )) == null ? '' : __t) +
'\n		</div>\n	</div>\n';
 } ;

return __p
}