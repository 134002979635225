import _ from 'lodash';
import { fetchCustomerByIds } from '@/logic/Customer.service';
import { FormDataModelConverter } from '@/utils/form-data-model-converter';
import { BeneficialOwnerWithCustomerType, DataObject } from '@sageworks/dynamic-forms';
import { AutoFactory, BeneficialOwner, Customer, LoanRole, OnlinePortalBeneficialOwnersService, OnlinePortalObjectsByPropertyValuesService } from '@sageworks/jpi';

export const loadDataObjects = async (ids: number[], type: DataObject.TypeEnum, dataFieldsByType: { [key: string]: Array<number>; }) => {
	const dataFieldIds = dataFieldsByType[type] ?? [];

	if (ids.length === 0 || dataFieldIds.length === 0) {
		return [];
	}
		
	const dataObjectService = AutoFactory.get(OnlinePortalObjectsByPropertyValuesService);
	let response = await dataObjectService.getMultiple(type, { ids, dataFieldIds });
	let dataObjects = response.items || [];

	return dataObjects.map(FormDataModelConverter.convertObjectPropertyValuesToDataObject);
};

export const fetchCustomers = async (customerIds: number[]): Promise<Customer[]> => fetchCustomerByIds(customerIds);

export const fetchBeneficialOwners = async (loanRoles: LoanRole[]) => {
	const beneficialOwnersService = AutoFactory.get(OnlinePortalBeneficialOwnersService);

	if (loanRoles.length <= 0) {
		return [];
	}

	const promises = loanRoles.map(async x => {
		// NOTE: the jpi page size is max at 1000
		const response = await beneficialOwnersService.getPaged(1, 1000, x.customerId);
		const owners = response.items as BeneficialOwner[];

		const ownerIds = owners.map(x => x.ownerCustomerId);
		const customers = await fetchCustomers(ownerIds);
		const customersById = _.keyBy(customers, x => x.id as number);

		return owners
			.filter(f => customersById?.[f.ownerCustomerId]?.type != null)
			.map<BeneficialOwnerWithCustomerType>(x => ({
				...x,
				ownerCustomerType: customersById[x.ownerCustomerId].type
			}));
	});

	const unFlattenededBeneficialOwnerDetails = await Promise.all(promises);

	return ([] as BeneficialOwnerWithCustomerType[]).concat.apply<
		BeneficialOwnerWithCustomerType[],
		BeneficialOwnerWithCustomerType[][],
		BeneficialOwnerWithCustomerType[]
	>([], unFlattenededBeneficialOwnerDetails);
};