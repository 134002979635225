// TODO:  figure out how to make this guy live in the JPI

export namespace DataObject {
	export enum TypeEnum {
		Business = 'business',
		Person = 'person',
		Farm = 'farm',
		NonProfit = 'nonProfit',
		Contact = 'contact',
		PortfolioLoan = 'portfolioLoan',
		ProposedLoan = 'proposedLoan',
		Collateral = 'collateral',
		BusinessFinancial = 'businessFinancial',
		PersonalFinancial = 'personalFinancial',
		FinancialSubaccounts = 'financialSubaccounts',
		BeneficialOwnerDetails = 'beneficialOwnerDetails',
		RealEstateFinancials = 'realEstateFinancial',
		BusinessInvestments = 'businessInvestment'
	}
}
