import { OnlinePortalCss, OnlinePortalStylingSettingsService, AutoFactory } from '@sageworks/jpi';
import { UrlUtils } from '@/utils';
import * as bootstrapCssVariables from '@abrigo/override-bootstrap-variables';
import { scaleLightness } from '@abrigo/color-calculation';
import { StylingConstants } from '@/enums/styling-constants';

export async function fetchAllCss(): Promise<OnlinePortalCss[] | null> {
	const onlinePortalCssService: OnlinePortalStylingSettingsService = AutoFactory.get(OnlinePortalStylingSettingsService);
	try {
		const onlinePortalCss: OnlinePortalCss[] = await onlinePortalCssService.getAllBySubdomain(UrlUtils.getOnlinePortalSubdomain());
		return onlinePortalCss;
	} catch (e) {
		// Silently fail here, we're not able to show an error toaster with $bvToast
		// If we can find a way to show a toaster, use that to show a more specific failure message
	}
	return null;
}

/**
 * Creates and attaches a new style element to the document which contains CSS variable overrides and our background class body
 * @param onlinePortalCss
 * @returns The created style element reference, in case the element and overrides need to be removed later on
 */
export function createAndAttachCustomCssElement(onlinePortalCss: OnlinePortalCss): HTMLElement | null {
	var dynamicStyleSheets = document.querySelectorAll('[dynamic-style-sheet]');
	var customStyleElement = bootstrapCssVariables.overrideBootstrapCssVariables({
		primary: onlinePortalCss.primaryColorHexCode,
		secondary: onlinePortalCss.secondaryColorHexCode,
		danger: onlinePortalCss.errorColorHexCode
	} as bootstrapCssVariables.BootstrapVariableValues);
	if (customStyleElement) {
		customStyleElement?.appendChild(
			document.createTextNode(
				createBackgroundCssClass(
					onlinePortalCss.backgroundColorHexCode || '',
					onlinePortalCss.backgroundGradientPercent || 0,
					onlinePortalCss.backgroundImageUrl || ''
				)
			)
		);
	}
	dynamicStyleSheets.forEach(s => s.remove());
	return customStyleElement || null;
}

/**
 * Returns the background CSS class for the given parameters (image, solid, or gradient depending on what values are provided)
 * @param backgroundColorCode
 * @param gradientPercent
 * @param imageUrl
 */
// eslint-disable-next-line max-lines-per-function
export function createBackgroundCssClass(backgroundColorCode: string, gradientPercent: number, imageUrl: string): string {
	if (imageUrl) {
		return `.${StylingConstants.backgroundClassName} {
			overflow: auto;
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			display: block;
			height: 100%;
			background-position: center;
			background-repeat: no-repeat;
			background-color: #d5d5d5 !important;
			-webkit-background-size: cover;
			-moz-background-size: cover;
			-o-background-size: cover;
			background-size: cover;
			background-image: url('${imageUrl}') !important;
		}`;
	}
	if (gradientPercent) {
		return `.${StylingConstants.backgroundClassName} { 
				position: fixed; 
				height: 100%; 
				overflow: auto; 
				background: linear-gradient(
					180deg,
					${backgroundColorCode},
					${scaleLightness(backgroundColorCode, gradientPercent * 100)}
				)
				!important; });`;
	}
	if (!gradientPercent) {
		return `.${StylingConstants.backgroundClassName} { 
			position: fixed;
			height: 100%;
			overflow: auto;
			background-color: ${backgroundColorCode} !important; }`;
	}
	return '';
}
