import baseEditForm from 'formiojs/components/select/Select.form';

import ForceRequiredField from '../../../configuration-components/force-required/force-required';
import OriginalLabel from '../../../configuration-components/base-widget-configuration/display/original-label';
import BaseEditConditionalComponents from '../../../configuration-components/base-widget-configuration/base.edit.conditional.components';
import SelectFieldComponents from '../extended-select-field/extended-select-field.form.data';
import LenderOnly from '../../../configuration-components/base-widget-configuration/display/lender-only-field-checkbox';
// eslint-disable-next-line max-lines-per-function
export default function(...extend: any[]) {
	return baseEditForm(
		[
			{
				key: 'api',
				ignore: true
			},
			{
				key: 'display',
				components: [OriginalLabel, LenderOnly]
			},
			{
				key: 'validation',
				components: ForceRequiredField
			},
			{
				key: 'data',
				ignore: true
			},
			{
				label: 'Data',
				key: 'customDataTab',
				weight: 10,
				components: SelectFieldComponents
			},
			{
				key: 'conditional',
				components: BaseEditConditionalComponents
			}
		],
		...extend
	);
}
