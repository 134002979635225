
import Vue from 'vue';

export default Vue.extend({
	name: 'PortalCard',
	components: {
	},
	props: {
		title: String,
	}
});
