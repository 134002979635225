import { Address, Customer, DataFieldOption } from '@sageworks/jpi';

export interface AddNewCustomerFormValues { 
	entityType: Customer.TypeEnum;
	firstName: string | null;
	lastName: string | null;
	email: string | null;
	businessName: string | null;
	industryCode: DataFieldOption | null;
	state: DataFieldOption | null;
}

export const generateBlankNewCustomerForm = (): AddNewCustomerFormValues => {
	return {
		entityType: Customer.TypeEnum.Person,
		firstName: null,
		lastName: null,
		email: null,
		businessName: null,
		industryCode: null,
		state: null
	};
};

export const createCustomerObjectfromForm = (addNewCustomerFormValues: AddNewCustomerFormValues) : Customer | null => {
		const { entityType,
			firstName, lastName, email,
			businessName, state, industryCode
		} = addNewCustomerFormValues;

	switch (entityType) {
		case Customer.TypeEnum.Person:
			return {
				type: entityType,
				firstName: firstName ?? undefined,
				lastName: lastName ?? undefined,
				emailAddress: email ?? undefined,
				addresses: []
			};
		case Customer.TypeEnum.Business:
		case Customer.TypeEnum.Farm:
			return {
				type: entityType,
				name: businessName ?? undefined,
				industryCode: industryCode?.value as unknown as string,
				addresses: [
					{
						addressType: Address.AddressTypeEnum.Primary,
						state: state?.value as unknown as string
					}
				]
			};
		default:
			return null;
	}
};