import { BeneficialOwner } from '@sageworks/jpi';
import { BeneficialOwnershipRoleType } from '../../enums/beneficial-ownership-role-type';

export function BeneficialOwnerRoleLabel(roleType: BeneficialOwnershipRoleType) {
	switch (roleType) {
		case BeneficialOwnershipRoleType.BeneficialOwner:
			return 'Beneficial Owner';
		case BeneficialOwnershipRoleType.ControlPerson:
			return 'Control Person';
		case BeneficialOwnershipRoleType.BeneficialOwnerControlPerson:
			return 'Beneficial Owner & Control Person';
		case BeneficialOwnershipRoleType.Certifier:
			return 'Certifier';
		case BeneficialOwnershipRoleType.BeneficialOwnerControlPersonCertifier:
			return 'Beneficial Owner & Control Person & Certifier';
		case BeneficialOwnershipRoleType.BeneficialOwnerCertifier:
			return 'Beneficial Owner & Certifier';
		case BeneficialOwnershipRoleType.ControlPersonCertifier:
			return 'Control Person & Certifier';
	}
}

export function convertBenOwnerRoleIntEnumToStringEnum(roleType?: BeneficialOwnershipRoleType | null) {
	switch (roleType) {
		case BeneficialOwnershipRoleType.BeneficialOwner:
			return BeneficialOwner.RoleEnum.BeneficialOwner;
		case BeneficialOwnershipRoleType.ControlPerson:
			return BeneficialOwner.RoleEnum.ControlPerson;
		case BeneficialOwnershipRoleType.BeneficialOwnerControlPerson:
			return BeneficialOwner.RoleEnum.BeneficialOwnerControlPerson;
		case BeneficialOwnershipRoleType.Certifier:
			return BeneficialOwner.RoleEnum.Certifier;
		case BeneficialOwnershipRoleType.BeneficialOwnerControlPersonCertifier:
			return BeneficialOwner.RoleEnum.BeneficialOwnerControlPersonCertifier;
		case BeneficialOwnershipRoleType.BeneficialOwnerCertifier:
			return BeneficialOwner.RoleEnum.BeneficialOwnerCertifier;
		case BeneficialOwnershipRoleType.ControlPersonCertifier:
			return BeneficialOwner.RoleEnum.ControlPersonCertifier;
	}
}

export function convertBenOwnerRoleStringEnumToIntEnum(roleType?: BeneficialOwner.RoleEnum | null) {
	switch (roleType) {
		case BeneficialOwner.RoleEnum.BeneficialOwner:
			return BeneficialOwnershipRoleType.BeneficialOwner;
		case BeneficialOwner.RoleEnum.ControlPerson:
			return BeneficialOwnershipRoleType.ControlPerson;
		case BeneficialOwner.RoleEnum.BeneficialOwnerControlPerson:
			return BeneficialOwnershipRoleType.BeneficialOwnerControlPerson;
		case BeneficialOwner.RoleEnum.Certifier:
			return BeneficialOwnershipRoleType.Certifier;
		case BeneficialOwner.RoleEnum.BeneficialOwnerControlPersonCertifier:
			return BeneficialOwnershipRoleType.BeneficialOwnerControlPersonCertifier;
		case BeneficialOwner.RoleEnum.BeneficialOwnerCertifier:
			return BeneficialOwnershipRoleType.BeneficialOwnerCertifier;
		case BeneficialOwner.RoleEnum.ControlPersonCertifier:
			return BeneficialOwnershipRoleType.ControlPersonCertifier;
	}
}