export enum BeneficialOwnershipRoleType {
	// Beneficial Owner
	BeneficialOwner = 1,

	// Control Person
	ControlPerson = 2,

	// Beneficial Owner and Control Person
	BeneficialOwnerControlPerson = 3,

	// Certifier
	Certifier = 4,

	// Beneficial Owner & Control Person & Certifier
	BeneficialOwnerControlPersonCertifier = 5,

	// Beneficial Owner & Certifier
	BeneficialOwnerCertifier = 6,

	// Control Person & Certifier
	ControlPersonCertifier = 7
}
