import { CustomComponentType, CustomComponentLabel, ContextDataProperty, TemplateDataField, FetchDataType, EditRowState, DataObject } from '../../../../enums';
import { AutoFactory, DataField, OnlinePortalDataFieldsService } from '@sageworks/jpi';
import { PopupWrapper, AddCustomerResult } from '../../../form-components';
import { FinancialSubaccount } from '../../../form-components/financial-subaccount-components/financial-subaccount/financial-subaccount';
import { configureLinkBehavior, configureLinkTemplate } from '../../base/edit-link-helper';
import { ComponentDataHelper, RepeaterWidgetUtils, ComponentDataLoader, getContextDataValue, FormioTemplateHelpers, showLoadingPopup } from '../../../../utils';
import { generateFormJson, AddBusinessInvestmentPopup } from '.';
import editForm from './business-investments.form';
import FormComponent from 'formiojs/components/form/Form';
import { Utils as FormioUtils } from 'formiojs';
import { getRowDescription } from './business-investments-component-helper';

export class BusinessInvestmentWidget extends FinancialSubaccount {
	private dataLoader!: ComponentDataLoader;
	private componentDataFields!: DataField[];
	private popupDataFields!: DataField[];

	protected descriptionPropertyKey = TemplateDataField.BusinessName;
	protected valuePropertyKey = TemplateDataField.BusinessInvestmentValue;

	private get isJpiAuthenticated() {
		return getContextDataValue(this, ContextDataProperty.IsJpiAuthenticated);
	}

	static schema(...extend: any) {
		return FinancialSubaccount.schema(
			{
				label: CustomComponentLabel()[CustomComponentType.businessInvestment],
				type: CustomComponentType.businessInvestment,
				key: CustomComponentType.businessInvestment,
				addAnother: 'Add Business Investment'
			},
			...extend
		);
	}

	init() {
		this.popupDataFields = this.popupDataFields ?? [];
		this.componentDataFields = this.componentDataFields ?? [];
		super.init();

		if (!this.dataLoader) {
			this.dataLoader = new ComponentDataLoader();
			this.initDataLoader();
		}
	}

	render(children: any, topLevel?: boolean): any {
		if (this.builderMode) {
			return configureLinkTemplate(this, { showEditButton: false });
		} else {
			return super.render(children, topLevel);
		}
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: CustomComponentLabel()[CustomComponentType.businessInvestment],
			group: '',
			weight: 10,
			schema: BusinessInvestmentWidget.schema(),
			preview: false
		};
	}

	addRow(): any {
		this.component.modal = true;
		var newRow = undefined;

		try {
			newRow = super.addRow();
		} finally {
			this.component.modal = false;
		}

		return newRow;
	}

	async attach(element: HTMLElement) {
		await super.attach(element);

		if (this.builderMode) {
			configureLinkBehavior(this, element);
			return;
		} else if (FormioTemplateHelpers.isInPreviewMode(this)) {
			return;
		}

		this.loadRefs(element, {
			[`${this.addRowRef}`]: 'single'
		});

		this.dataLoader.loadData();
	}

	addRowModal(rowIndex: number): any {
		const editRow = this.editRows[rowIndex];

		if (editRow.state === EditRowState.New) {
			this.showAddBusinessInvestmentPopup(rowIndex, false);
			this.component.modal = false;
		} else {
			super.addRowModal(rowIndex);
		}
	}

	public async saveNewRow(rowIndex: number, selectedCustomer: AddCustomerResult) {
		const formComponent: FormComponent = this.editRows[rowIndex].components[0];
		await formComponent.subFormReady;

		formComponent.setValue(
			{
				...formComponent.subForm.getValue(),
				data: RepeaterWidgetUtils.createRowData(selectedCustomer, -1)
			},
			{
				fromSubmission: true
			}
		);

		this.editRows[rowIndex].data = formComponent.data;
		this.saveRow(rowIndex);

		const savePromise = this.partialSaveRow(rowIndex).finally(() => {
			return this.editRow(rowIndex);
		});
		showLoadingPopup(savePromise);

		await savePromise;
	}

	private initDataLoader() {
		this.dataLoader.isSuccessfulPromise.then(this.onDataLoaded.bind(this));
		this.dataLoader.addDataFunc(this.fetchComponentDataFields.bind(this));
		this.dataLoader.addDataFunc(this.getPopupDatafields.bind(this));
		this.dataLoader.isSuccessfulPromise.then(this.redraw.bind(this));
	}

	private async getPopupDatafields(): Promise<void> {
		if (this.builderMode || !this.isJpiAuthenticated || (this.popupDataFields && this.popupDataFields.length > 0)) {
			return;
		}

		const dataFieldsService = AutoFactory.get(OnlinePortalDataFieldsService);
		const allAllowedTemplateIds = this.allowedFarmTemplateDataFields.concat(this.allowedBusinessTemplateDataFields);

		var popupDataFields =
			(await dataFieldsService.getPaged(1, allAllowedTemplateIds.length, undefined, undefined, false, allAllowedTemplateIds)).items ?? [];

		if (popupDataFields.some(x => x == null)) {
			throw Error('Unable to retrieve all DataFields');
		}
		this.popupDataFields = popupDataFields as DataField[];
	}

	private onDataLoaded() {
		this.component.formJson = generateFormJson(this.componentDataFields);
		this.component.components = this.componentComponents;
		this.resetRowComponents();
	}

	private get allowedBusinessTemplateDataFields(): TemplateDataField[] {
		return [
			TemplateDataField.BusinessName,
			TemplateDataField.BusinessState,
			TemplateDataField.BusinessIndustryCode,
			TemplateDataField.BusinessSubEntityType
		];
	}
	private get allowedFarmTemplateDataFields(): TemplateDataField[] {
		return [TemplateDataField.FarmName, TemplateDataField.FarmState, TemplateDataField.FarmIndustryCode];
	}

	private async fetchComponentDataFields() {
		if (this.isJpiAuthenticated) {
			this.componentDataFields = await ComponentDataHelper.fetchData<DataField[]>(this, {
				fetchType: FetchDataType.PresetDataFields,
				fetchContext: {
					dataObjectType: DataObject.TypeEnum.BusinessInvestments
				},
				cacheKey: 'business-investment-fields'
			});
		}
	}

	async showAddBusinessInvestmentPopup(rowIndex: number, editMode = false): Promise<void> {
		const createPopupPromise = this.createAddNewBusinessInvestmentPopup(rowIndex, editMode);
		showLoadingPopup(createPopupPromise);
		const addBusinessInvestmentPopup = await createPopupPromise;

		let popupWrapper = new PopupWrapper({}, {}, {}, addBusinessInvestmentPopup);

		popupWrapper.showPopup();
		const selectedCustomer = await popupWrapper.actionCompleted;

		if (selectedCustomer == null) {
			// No customer was selected, let Formio handle the cancel logic to reset the row to the proper state
			this.cancelRow(rowIndex);
			return;
		}
		await this.saveNewRow(rowIndex, selectedCustomer);
	}

	protected async createAddNewBusinessInvestmentPopup(rowIndex: number, editMode = false) {
		const allowedEntityTypes: CustomComponentType[] = [CustomComponentType.businessInfo, CustomComponentType.farmInfo];
		const ignoredCustomerIds: Number[] = [];
		if (this.customerId && this.customerId > 0) {
			ignoredCustomerIds.push(this.customerId);
		}

		const applicationEntities = await this.getApplicationEntities(false, allowedEntityTypes, ignoredCustomerIds);
		const editRow = this.editRows[rowIndex];
		return new AddBusinessInvestmentPopup(
			AddBusinessInvestmentPopup.schema({
				addNewEntityDataFields: this.popupDataFields,
				applicationEntities: applicationEntities,
				ignoredCustomerIds: ignoredCustomerIds
			}),
			{
				...this.options,
				parentVisible: true,
				skipInit: false,
				editMode: editMode
			},
			(FormioUtils as any).fastCloneDeep(editRow.data)
		);
	}
	protected async getApplicationEntities(isPrimaryRole: boolean, allowedEntityTypes: CustomComponentType[], ignoredCustomerIds: Number[]): Promise<any[]> {
		// Defaulting to load no entities for now, until we can find a way to handle entity filtering being overwritten
		if (isPrimaryRole && allowedEntityTypes && ignoredCustomerIds) {
			return [];
		}
		return [];
	}

	protected getFormattedDescription(descriptionDatafieldId: string, row: any) {
		return getRowDescription(row, this.options);
	}

	protected shouldPartialSaveOnSaveRow(_rowIndex: number, isNew: boolean) {
		return !isNew;
	}
}
