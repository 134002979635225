import { CustomComponentType, FinancialSubaccountParent } from '../../../enums';
import { FinancialSubaccountSelectOptions } from './financial-subaccount-type-select-options';

export class PersonalIncomeTypeSelectOptions extends FinancialSubaccountSelectOptions {
	static schema(...extend: any) {
		return super.schema(
			{
				type: CustomComponentType.personalIncomeTypeSelectOptions,
			},
			...extend
		);
	}

	static get builderInfo() {
		return {
			weight: 10,
			schema: PersonalIncomeTypeSelectOptions.schema({})
		};
	}

	protected async getApiOptions(): Promise<any> {
		return {
			items: [
				{
					label: 'Primary',
					value: FinancialSubaccountParent.PersonalIncome
				},
				{
					label: 'Secondary',
					value: FinancialSubaccountParent.OtherIncome
				}
			]
		};
	}
}
