import ModifiedFormComponent from '../../formio-components/form/modified-form-component';
import { configureLinkTemplate, configureLinkBehavior } from '../edit-link-helper';
import { isInPreviewMode } from '../../../../utils/formio-template-helpers/builder-template-helpers';
import { checkConditionalityAcrossForms, shouldComponentUseOriginalConditionality } from '../../simple-inputs/extended-field-helper';
import { FormioConditionalityUtils } from '../../../../utils/formio-conditionality-utils';

export default class BaseWidget extends ModifiedFormComponent {
	static schema(...extend: any) {
		return ModifiedFormComponent.schema({}, ...extend);
	}

	static get builderInfo() {
		return {
			group: 'widgets',
			weight: 10,
			schema: BaseWidget.schema()
		};
	}

	get isInPreviewMode() {
		return isInPreviewMode(this);
	}

	render(children: any, topLevel?: boolean): any {
		if (this.builderMode) {
			return configureLinkTemplate(this);
		}

		return super.render(children, topLevel);
	}

	attach(element: any): Promise<void> {
		return super.attach(element).then(() => {
			configureLinkBehavior(this, element);
		});
	}

	// @ts-ignore
	get key() {
		return this.component.type;
	}

	redraw() {
		this.parent?.root?.redraw();
		return super.redraw();
	}

	conditionallyVisible(data: any): boolean {
		if (shouldComponentUseOriginalConditionality(this)) {
			return super.conditionallyVisible(data);
		}

		return checkConditionalityAcrossForms(this);
	}

	checkCondition(row: any, data: any) {
		return FormioConditionalityUtils.checkCondition(
			this.component,
			row || this.data,
			data || this.rootValue,
			this.root ? (this.root as any)._form : {},
			this
		);
	}
}
