export function formatPhoneNumber(phoneNumber: string | null | undefined): string {
	if (!phoneNumber || phoneNumber.trim() === '') {
		return '';
	}

	let phoneNumberDigits = phoneNumber.replace(/\D+/g, '');

	if (phoneNumberDigits.length === 10) {
		return phoneNumberDigits.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
	}

	// Uncertain how to format, strip whitespace and leave as is
	return phoneNumber.trim();
}

export function formatSocialSecurityNumber(ssn: string): string {
	ssn = ssn.replace(/[^0-9]/gi, '');
	// split ssn into [3,2,4]
	let ssnArray = [ssn.substring(0,3), ssn.substring(3,5), ssn.substring(5,9)];
	// if ssn isn't at least 6 characters long yet, remove empty section
	ssnArray = ssnArray.filter((section) => {return section !== ''});
	// return ssn formatted as ###-##-####
	return ssnArray.join('-');
}
