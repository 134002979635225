import Component from 'formiojs/components/_classes/component/Component';
import { IPopupBodyComponent } from '../popup';
import { DataObject, CustomComponentType, ContextDataProperty, FetchDataType } from '../../../enums';
import { Collateral, ObjectPropertyValues } from '@sageworks/jpi';
import { EncodingHelper } from '@sageworks/core-logic';
import { getContextDataValue } from '../../../utils/context-data-helper/context-data-helper';
import { ComponentDataHelper } from '../../../utils';
import EventEmitter from 'formiojs/EventEmitter';
import { formatDataFieldIdKey } from '../simple-inputs/extended-field-helper';
import { CollateralWithCustomerName } from '../collateral-info/collateral-with-customer-name';

enum AddType {
	AddNew = 'AddNew',
	AddExisting = 'AddExisting',
	EditExisting = 'EditExisting'
}

export class CollateralAddRowPopup extends Component implements IPopupBodyComponent {
	public resultPromise!: Promise<any>;
	public resultPromiseResolve: any;
	private collateralList: { value: string; label: string }[] = [];

	public preClose = () => {
		return Promise.resolve();
	};

	private get userInfo() {
		return getContextDataValue(this, ContextDataProperty.CurrentUserInfo);
	}

	private get isLender() {
		return this.userInfo == null || this.userInfo?.proxyUserId != null;
	}

	private get collateralObjectList() {
		return this.component.collateralObjectList;
	}

	public static schema(...extend: any) {
		return Component.schema(
			{
				label: 'Collateral Add Row Popup',
				type: CustomComponentType.collateralAddRowPopup,
				key: CustomComponentType.collateralAddRowPopup,
				roleType: null,
				input: false
			},
			...extend
		);
	}

	private getLabel(collateral: CollateralWithCustomerName) {
		let label = collateral.description;
		if (this.isLender) {
			label += ' (';
			switch (collateral.collateralMetadataType) {
				case Collateral.CollateralMetadataTypeEnum.Proposed: {
					label += 'Proposed';
					break;
				}
				case Collateral.CollateralMetadataTypeEnum.Existing: {
					label += 'Existing';
					break;
				}
			}
			label += ')';
		}

		// might need to get the collateral owners name if it isn't this beneficiary value
		label += ' - ' + collateral.customerName;
		return label;
	}

	public async init() {
		super.init();
		this.resultPromise = new Promise(r => (this.resultPromiseResolve = r));
	}

	public encodeHtml = EncodingHelper.encodeHtml;

	public render(): any {
		return this.renderTemplate('collateralAddRowPopup', {
			collateralList: this.collateralList,
			encodeHtml: this.encodeHtml
		});
	}

	constructor(component: any, options: any, data: object) {
		super(component, options, data);

		this.events = new EventEmitter({});
		// skip the pop up if it is not needed
		if (this.collateralObjectList?.length < 1) {
			this.addBlankCollateral();
		}
		this.collateralObjectList.forEach((collateral: CollateralWithCustomerName) => {
			if (this.component.ignoredCollateralIds.includes(collateral.id)) return;
			this.collateralList.push({ value: String(collateral.id ?? 0), label: this.getLabel(collateral) });
		});
	}

	private async addBlankCollateral() {
		const collateral: Collateral = {
			id: -1
		} as Collateral;
		const result = await this.buildExistingCollateralResult(collateral);
		this.resultPromiseResolve(result);
	}

	private async buildExistingCollateralResult(collateral: Collateral) {
		let dataObject: ObjectPropertyValues | null = null;
		if (collateral.id != null && collateral.id > 0) {
			dataObject = await ComponentDataHelper.fetchData<ObjectPropertyValues>(this, {
				fetchType: FetchDataType.DataObject,
				fetchContext: { type: DataObject.TypeEnum.Collateral, id: collateral.id }
			});
		}

		const data: any = { id: collateral.id };
		if (dataObject != null) {
			(dataObject.dataFieldValues ?? []).forEach(x => {
				if (x.id) {
					data[formatDataFieldIdKey(String(x.id))] = x.value;
				}
			});
		}
		return data;
	}

	public async attach(element: any) {
		await super.attach(element);

		this.loadRefs(element, {
			addNewCollateralButton: 'single',
			addExistingCollateral: 'multiple',
			addNewCollateral: 'single'
		});

		const { addNewCollateralButton, addExistingCollateral } = this.refs as any;

		this.addEventListener(addNewCollateralButton, 'click', this.onAddNewCollateralClick);

		addExistingCollateral.forEach((collateral: HTMLElement) => {
			this.addEventListener(collateral, 'click', (e: Event) => this.onAddExistingCollateral(e));
		});
	}

	private onAddNewCollateralClick = (e: Event) => {
		e.preventDefault();
		this.addBlankCollateral();
	};

	private onAddExistingCollateral = (e: Event) => {
		e.preventDefault();
		this.savePopup(e, AddType.AddExisting);
	};

	async savePopup(e: any, addType: AddType) {
		let collateralResult: any;
		switch (addType) {
			case AddType.AddExisting: {
				const collateral = this.collateralObjectList.find((collateral: Collateral) => collateral.id === Number(e.target.id)) ?? ({} as Collateral);
				collateralResult = await this.buildExistingCollateralResult(collateral);
				this.resultPromiseResolve(collateralResult);
				break;
			}
			case AddType.AddNew: {
				this.resultPromiseResolve(null);
				break;
			}
		}
	}
}
