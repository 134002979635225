module.exports = function(ctx) {
var __t, __p = '';
__p += '<div>\r\n	' +
((__t = ( ctx.wizardHeader )) == null ? '' : __t) +
'\r\n	<div class="dynamic-forms-wizard-page-container">\r\n		<div class="position-relative">\r\n			<div class="wizard-page" ref="' +
((__t = ( ctx.wizardKey )) == null ? '' : __t) +
'">\r\n				' +
((__t = ( ctx.components )) == null ? '' : __t) +
'\r\n			</div>\r\n			' +
((__t = ( ctx.wizardNav )) == null ? '' : __t) +
'\r\n		</div>\r\n	</div>\r\n</div>\r\n';
return __p
}