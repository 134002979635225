module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }


var isFormWidget = ctx.options.builderTemplateHelpers && ctx.options.builderTemplateHelpers.isFormTypeComponent(ctx.componentInfo.schema.type);
var editFormWidgetCss = isFormWidget ? 'col-lg-6 offset-3' : (ctx.preview ? 'col-lg-6' : 'col-lg-12' );
;
__p += '\n\n<div class="row">\n	<div class="col">\n		<p class="lead">' +
((__t = ( ctx.t(ctx.componentInfo.title) )) == null ? '' : __t) +
' ' +
((__t = ( ctx.t('Component') )) == null ? '' : __t) +
'</p>\n	</div>\n</div>\n<div class="row">\n	<div class="col-12 ' +
((__t = ( editFormWidgetCss )) == null ? '' : __t) +
'">\n		<div ref="editForm" role="form">\n			' +
((__t = ( ctx.editForm )) == null ? '' : __t) +
'\n		</div>\n		';
 if (!ctx.preview) { ;
__p += '\n		<div style="margin-top: 10px;">\n			<button class="btn btn-success" style="margin-right: 10px;" ref="saveButton">' +
((__t = ( ctx.t('Save') )) == null ? '' : __t) +
'</button>\n			<button class="btn btn-secondary" style="margin-right: 10px;" ref="cancelButton">' +
((__t = ( ctx.t('Cancel') )) == null ? '' : __t) +
'</button>\n			<button class="btn btn-danger" ref="removeButton">' +
((__t = ( ctx.t('Remove') )) == null ? '' : __t) +
'</button>\n		</div>\n		';
 } else if (isFormWidget) { ;
__p += '\n		<div style="margin-top: 10px;">\n			<button class="btn btn-primary" style="margin-right: 10px;" ref="saveButton">' +
((__t = ( ctx.t('Done') )) == null ? '' : __t) +
'</button>\n			<button class="btn btn-primary" ref="cancelButton">' +
((__t = ( ctx.t('Cancel') )) == null ? '' : __t) +
'</button>\n		</div>\n		';
 } ;
__p += '\n	</div>\n	';
 if (ctx.preview && !isFormWidget) { ;
__p += '\n	<div class="col-12 col-lg-6 mt-3 mt-lg-0">\n		<div class="card panel preview-panel">\n			<div class="card-header">\n				<h4 class="card-title mb-0">' +
((__t = ( ctx.t('Preview') )) == null ? '' : __t) +
'</h4>\n			</div>\n			<div class="card-body">\n				<div class="component-preview" ref="preview">\n					' +
((__t = ( ctx.preview )) == null ? '' : __t) +
'\n				</div>\n			</div>\n		</div>\n		';
 if (ctx.componentInfo.help) { ;
__p += '\n		<div class="card card-body bg-light formio-settings-help">\n			' +
((__t = ( ctx.t(ctx.componentInfo.help) )) == null ? '' : __t) +
'\n		</div>\n		';
 } ;
__p += '\n		<div style="margin-top: 10px;">\n			<button class="btn btn-primary" style="margin-right: 10px;" ref="saveButton">' +
((__t = ( ctx.t('Done') )) == null ? '' : __t) +
'</button>\n			<button class="btn btn-primary" style="margin-right: 10px;" ref="cancelButton">' +
((__t = ( ctx.t('Cancel') )) == null ? '' : __t) +
'</button>\n			<button class="btn btn-danger" ref="removeButton">' +
((__t = ( ctx.t('Remove') )) == null ? '' : __t) +
'</button>\n		</div>\n	</div>\n	';
 } ;
__p += '\n</div>\n';
return __p
}