
import Vue, { PropType } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { DataObject, BeneficialOwnerFormDataModel, DataFieldOption, Customer, ApplicationTemplate, FeatureFlagging } from '@sageworks/jpi';
import { LoanRoleType, TemplateDataField, BeneficialOwnershipRoleType } from '@sageworks/dynamic-forms';
import {
	BeneficialOwnership1071Status,
	BeneficialOwnership1071StatusByCustomerId,
	convertCustomerTypeToOwnerEntityType,
	convertToObjectPropertyValues,
	createFormattedList,
	fetchBeneficialOwnership1071StatusByCustomerId
} from '../../utils/beneficial-ownership';
import { fetchBeneficialOwnership1071Status } from '../../utils/beneficial-ownership/beneficial-ownership-1071-status-utils';
import { getNameFromCustomer } from '../../utils/customer-store';
import { validateOwners } from '../../validation/beneficial-ownership';
import { OwnerRowData } from '../../models';
import { WidgetValidationMessage, ValidationConfig } from '../../validation';
import { BeneficialOwnershipTableRow } from './beneficial-ownership-table-row';
import { BeneficialOwnershipEditForm } from './beneficial-ownership-edit-form';
import { ValidationMessagesBox } from '../validation-messages-box';
import { AddCustomerPopup } from '../add-customer-popup';
import { DateFormatting } from '@sageworks/core-logic';

export default Vue.extend({
	name: 'BeneficialOwnership',
	components: {
		FontAwesomeIcon,
		AddCustomerPopup,
		BeneficialOwnershipTableRow,
		BeneficialOwnershipEditForm,
		ValidationMessagesBox
	},
	props: {
		componentId: {
			type: String,
			required: true
		},
		addNewBtnLabel: {
			type: String,
			default: 'Add an Owner'
		},
		customerId: {
			type: Number
		},
		parentRoleType: {
			type: String as PropType<LoanRoleType>
		},
		parentRoleId: {
			type: Number
		},
		validationConfig: {
			type: Object as PropType<ValidationConfig>
		}
	},
	data() {
		return {
			addCustomerModalOpen: false,
			activeEditRowIndex: -1,
			roleOptionsLoading: false,
			isRowSaving: {} as { [index: number]: boolean },
			validationMessages: [] as WidgetValidationMessage[],
			demographic1071statuses: {} as BeneficialOwnership1071StatusByCustomerId,
			owners: [] as OwnerRowData[],
			demographicFormsEmailsEnabled: false
		};
	},
	computed: {
		...mapState('MultiLoanApplicationForm', ['renderData']),
		...mapState('CustomComponentProperties', ['templateDataFieldIdToDataFieldId', 'selectFieldOptions']),
		...mapGetters('MultiLoanApplicationForm', ['currentMetadata']),
		dataObjects(): { [key: string]: Array<DataObject> } {
			return this.renderData?.formData?.dataObjects ?? {};
		},
		beneficialOwners(): BeneficialOwnerFormDataModel[] {
			const beneficialOwners: BeneficialOwnerFormDataModel[] = this.renderData?.formData?.beneficialOwners ?? [];

			return beneficialOwners.filter(x => x.customerId === this.customerId);
		},
		ignoredRelatedCustomerIds(): number[] {
			return [this.customerId, ...this.beneficialOwners.map(x => x.ownerCustomerId!)];
		},
		addCustomerPopupTitle(): string {
			return `Add New ${LoanRoleType.BeneficialOwner}`;
		},
		allowedEntityTypes(): Customer.TypeEnum[] {
			const isFarmApplication = this.currentMetadata?.applicationTemplate?.type === ApplicationTemplate.TypeEnum.Farm;
			const allowedTypes = [Customer.TypeEnum.Person, Customer.TypeEnum.Business];

			if (isFarmApplication) {
				allowedTypes.push(Customer.TypeEnum.Farm);
			}

			return allowedTypes;
		},
		roleOptions(): DataFieldOption[] {
			return this.selectFieldOptions[TemplateDataField.BeneficialOwnerRoleType] ?? [];
		},
		is1071Loan(): boolean {
			const is1071Loan = this.directStore.state.MultiLoanApplicationForm.is1071Loan;
			return is1071Loan && this.parentRoleType === LoanRoleType.PrimaryBorrower && !this.demographicFormsEmailsEnabled;
		},
		multipleOwnersAllowed(): boolean {
			return this.validationConfig?.multiple ?? false;
		}
	},
	watch: {
		beneficialOwners: {
			handler() {
				this.setOwners();
			},
			immediate: true
		},
		demographic1071statuses: {
			handler() {
				this.setOwners();
			}
		},
		customerId: {
			handler() {
				this.setOwners();
			}
		}
	},
	mounted() {
		this.loadRoleTypeOptions();
		this.loadDemographic1071Statuses();
		this.loadEnable1071FormEmailsFeatureFlag();
	},
	methods: {
		setOwners() {
			const dataObjects = this.dataObjects;
			const templateDataFieldIdToDataFieldId = this.templateDataFieldIdToDataFieldId;

			this.owners = createFormattedList(this.beneficialOwners, dataObjects, templateDataFieldIdToDataFieldId, this.demographic1071statuses);
		},
		setActiveEditRow(index: number): void {
			this.activeEditRowIndex = index;
		},
		closeEditForm() {
			this.activeEditRowIndex = -1;
		},
		async save(updatedData: OwnerRowData, index: number) {
			try {
				this.closeEditForm();
				this.isRowSaving = { ...this.isRowSaving, [index]: true };
				const dataObject = convertToObjectPropertyValues(updatedData, this.templateDataFieldIdToDataFieldId);

				await this.directStore.dispatch.MultiLoanApplicationForm.addBeneficialOwner({
					dataObject,
					customerId: updatedData.customerId!,
					ownerCustomerId: updatedData.ownerCustomerId!,
					ownerEntityType: updatedData.entityType
				});

				await this.reloadOwnerDemographic1071Status(index);
				this.validate();
			} finally {
				// After the component's value is changed, remove the cached validation so it doens't show anymore
				await this.removeComponentValidation();

				this.isRowSaving = { ...this.isRowSaving, [index]: false };
			}
		},
		async addNewOwner(customer: Customer) {
			// Close the add customer popup since it's not needed anymre
			this.addCustomerModalOpen = false;

			const newOwner: OwnerRowData = {
				id: undefined as any,
				customerId: this.customerId,
				ownerCustomerId: customer.id,
				ownerName: getNameFromCustomer(customer),
				roleType: BeneficialOwnershipRoleType.BeneficialOwner,
				lastUpdated: DateFormatting.formatDateForJpi(new Date()),
				demographic1071InformationStatus: BeneficialOwnership1071Status.NotApplicable,
				entityType: convertCustomerTypeToOwnerEntityType(customer.type)
			};
			this.owners = [...this.owners, newOwner];

			// Open the edit form for the new owner
			this.setActiveEditRow(this.owners.length - 1);
		},
		async deleteOwner(ownerRowData: OwnerRowData, index: number) {
			if (ownerRowData.id == null) {
				return;
			}

			try {
				this.isRowSaving = { ...this.isRowSaving, [index]: true };

				await this.directStore.dispatch.MultiLoanApplicationForm.removeBeneficialOwner({ id: ownerRowData.id });
				await this.reloadOwnerDemographic1071Status(index);
				this.validate();
			} finally {
				// After the component's value is changed, remove the cached validation so it doesn't show anymore
				await this.removeComponentValidation();

				this.isRowSaving = { ...this.isRowSaving, [index]: false };
			}
		},
		async removeComponentValidation() {
			await this.directStore.dispatch.LoanApplicationData.removeComponentValidationError({
				loanMappingId: this.currentMetadata.loanMappingId,
				componentId: this.componentId
			});
		},
		async loadRoleTypeOptions() {
			try {
				this.roleOptionsLoading = true;
				await this.directStore.dispatch.CustomComponentProperties.loadSelectFieldOptions({
					templateFieldId: TemplateDataField.BeneficialOwnerRoleType
				});
			} finally {
				this.roleOptionsLoading = false;
			}
		},
		async loadDemographic1071Statuses() {
			const loanId = this.currentMetadata.proposedLoanId;

			if (loanId == null) {
				return;
			}

			this.demographic1071statuses = await fetchBeneficialOwnership1071Status(loanId);
		},
		async loadEnable1071FormEmailsFeatureFlag() {
			this.demographicFormsEmailsEnabled = await this.directStore.dispatch.FeatureFlag.fetchIsFeatureFlagActive(
				FeatureFlagging.FeatureFlagEnum.Enable1071FormEmails
			);
		},
		async reloadOwnerDemographic1071Status(index: number) {
			const loanId = this.currentMetadata.proposedLoanId;
			const ownerData = this.owners[index];

			if (loanId == null || ownerData == null || ownerData.ownerCustomerId == null) {
				return;
			}

			try {
				this.isRowSaving = { ...this.isRowSaving, [index]: true };
				const status = await fetchBeneficialOwnership1071StatusByCustomerId(loanId, ownerData.ownerCustomerId);
				this.demographic1071statuses = { ...this.demographic1071statuses, [ownerData.ownerCustomerId]: status };
			} finally {
				this.isRowSaving = { ...this.isRowSaving, [index]: false };
			}
		},
		async onDemographic1071FormSaved(index: number) {
			await this.loadDemographic1071Statuses(index);
			this.validate();
		},
		validate() {
			this.validationMessages = validateOwners(this.owners, this.is1071Loan, this.validationConfig);
		}
	}
});
