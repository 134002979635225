export enum DataFieldAlias {
	RealEstatePropertyId = 'ProfitEntitiesID',
	RealEstatePropertyName = 'PropertyName',
	RealEstatePropertyValue = 'PropertyValue',
	RealEstatePercentOwnership = 'PercentOwnership',
	RealEstatePropertyType = 'PropertyType',
	RealEstateOwnerFinancialDataId = 'OwnerInputDataID',

	BusinessInvestmentValue = 'InvestmentValue',
	BusinessInvestmentEntityId = 'InvestmentEntityID',
	BusinessInvestmentOwnerId = 'ProfitEntitiesID',
	BusinessInvestmentFinancialDataId = 'OwnerInputDataID',
}