import nestedComponentForm from 'formiojs/components/_classes/nested/NestedComponent.form';
import { CustomComponentType } from '../../../enums';
import {
	BaseEditDisplay,
	BaseEditForm,
	IBaseWidgetConfigurationEditFormOptions,
	BaseEditLogic,
	BaseEditApi,
	BaseEditConditional,
	BaseEditLayout
} from '../../configuration-components/base-widget-configuration';

export default function(...extend: any) {
	return nestedComponentForm(
		[
			new BaseEditDisplay().getTab({ components: { showAllowRepeat: false, showLenderOnly: true } }),
			new BaseEditForm().getTab({ componentType: CustomComponentType.personalFinancialStatement } as IBaseWidgetConfigurationEditFormOptions),
			new BaseEditLogic().getTab(),
			new BaseEditApi().getTab(),
			new BaseEditConditional().getTab(),
			new BaseEditLayout().getTab()
		],
		...extend
	);
}
