import { DataField } from '@sageworks/jpi';
import { CustomComponentType } from '../../../../../enums';
import { DataFieldConvertStrategy } from '../../data-field-convert-strategy';
import { setSharedSelectProperties, setUrlSelectProperties, setDropdownProperties, SetDropdownPropertiesOptions } from '../../select-field-converter-utils';

export class IndustryCodeDataFieldConverter implements DataFieldConvertStrategy {
	private readonly allowedFieldTypes = new Set<DataField.DataFieldTypeEnum>([DataField.DataFieldTypeEnum.IndustryCode]);

	constructor(private componentType?: CustomComponentType) {}

	isFieldValid(dataField: DataField): boolean {
		return this.allowedFieldTypes.has(dataField.dataFieldType);
	}

	convert(dataField: DataField, currentProperties: any) {
		const properties = { ...currentProperties };

		setSharedSelectProperties(properties);
		setUrlSelectProperties(properties);
		properties.data = {};

		const isNonProfitInfoComponent = this.componentType === CustomComponentType.nonprofitInfo;
		const options: SetDropdownPropertiesOptions = {
			path: `${isNonProfitInfoComponent ? 'sector-codes' : 'industry-codes'}?perPage={{limit}}&page={{page + 1}}&industryLevels=3,4`,
			builderAndPreviewModeBasePath: isNonProfitInfoComponent ? '' : 'industry-data/'
		};

		setDropdownProperties(properties, options);

		properties.selectValues = 'industryCodes';
		properties.searchField = 'keyword';
		properties.valueProperty = 'industryCode';
		properties.template = '<span>{{ item.industryName }} - {{ item.industryCode }}</span>';

		return properties;
	}
}
