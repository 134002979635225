import Vue from 'vue';
import { defineModule } from 'direct-vuex';
import * as ApplicationDocumentRequestService from '@/logic/ApplicationDocumentRequest.service';
import { OnlinePortalApplicationDocumentRequest } from '@/models';
import { expandGroupedDocumentRequests } from '@/utils/ApplicationDocumentRequestUtils';
import { ApplicationDocumentRequestParams } from '@/models/store-action-params/ApplicationDocumentRequestModuleParams';
import { GroupedDocumentRequests } from '@/models/GroupedDocumentRequests';

// Using moduleActionContext instead of localActionContext because we need access to rootGetters
import { moduleActionContext } from '.';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => moduleActionContext(context, ApplicationDocumentRequestModule);

export interface ApplicationDocumentRequestModuleState {
	documentRequests?: GroupedDocumentRequests;
	initializationPromise: Promise<any> | null;
}
const ApplicationDocumentRequestModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			documentRequests: undefined,
			initializationPromise: null
		} as ApplicationDocumentRequestModuleState;
	},
	mutations: {
		SET_DOCUMENT_REQUESTS(state, updatedDocumentRequests: GroupedDocumentRequests) {
			state.documentRequests = updatedDocumentRequests;
		},
		UPDATE_DOCUMENT_REQUEST(state, updatedRequest: OnlinePortalApplicationDocumentRequest) {
			if (!state.documentRequests) return;
			for (const customerName in state.documentRequests) {
				const documentRequests = state.documentRequests[customerName];
				let indexToUpdate = documentRequests.findIndex(x => x.id === updatedRequest.id);
				if (indexToUpdate >= 0) {
					Vue.set(documentRequests, indexToUpdate, updatedRequest);
				}
			}
		},
		PATCH_DOCUMENT_REQUEST(
			state,
			{ id, documentRequestProperties }: {
				id: number;
				documentRequestProperties: Partial<OnlinePortalApplicationDocumentRequest>;
			}
		) {
			if (!state.documentRequests) return;
			for (const customerName in state.documentRequests) {
				const documentRequests = state.documentRequests[customerName];
				let indexToUpdate = documentRequests.findIndex(x => x.id === id);
				if (indexToUpdate >= 0) {
					Object.entries(documentRequestProperties).forEach(([key, value]) => {
						Vue.set(documentRequests[indexToUpdate], key, value);
					});
				}
			}
		},
		SET_INITIALIZATION_PROMISE(state, initializationPromise: Promise<any> | null) {
			state.initializationPromise = initializationPromise;
		}
	},
	actions: {
		async loadPagedDocumentRequests(context, { page, perPage, loanApplicationId }: ApplicationDocumentRequestParams): Promise<GroupedDocumentRequests> {
			const { rootGetters, rootState, rootDispatch, commit, state } = actionContext(context);
			if (state.initializationPromise != null) {
				return state.initializationPromise;
			}

			commit.SET_INITIALIZATION_PROMISE(ApplicationDocumentRequestService.getApplicationDocumentRequests(loanApplicationId, page, perPage));
			let newRequests = await state.initializationPromise!;

			if (!rootGetters.User.isLender) {
				if (rootState.DocumentName.documentNames.length === 0) {
					await rootDispatch.DocumentName.loadDocumentNames();
				}
				newRequests = ApplicationDocumentRequestService.transformApplicationDocumentRequestsForBorrowerView(
					rootState.DocumentName.documentNames,
					newRequests
				);
			}
			let groupedDocumentRequests = ApplicationDocumentRequestService.groupAndSortByCustomerId(newRequests as OnlinePortalApplicationDocumentRequest[]);
			commit.SET_DOCUMENT_REQUESTS(groupedDocumentRequests);
			commit.SET_INITIALIZATION_PROMISE(null);
			return groupedDocumentRequests;
		},
		async createApplicationDocumentRequest(
			state,
			requestToCreate: OnlinePortalApplicationDocumentRequest
		): Promise<OnlinePortalApplicationDocumentRequest> {
			var newRequest = await ApplicationDocumentRequestService.createApplicationDocumentRequest(requestToCreate);
			return newRequest;
		},
		async patchApplicationDocumentRequest(
			context,
			{ id, documentRequestProperties }: { id: number; documentRequestProperties: Partial<OnlinePortalApplicationDocumentRequest> }
		): Promise<void> {
			if (!id) return;
			const { commit, getters } = actionContext(context);
			commit.PATCH_DOCUMENT_REQUEST({ id, documentRequestProperties });
			const updatedRequest = getters.getById(id);
			if (updatedRequest != null) {
				await ApplicationDocumentRequestService.updateApplicationDocumentRequest(updatedRequest);
			}
		},
		async updateApplicationDocumentRequest(context, updatedRequest: OnlinePortalApplicationDocumentRequest): Promise<void> {
			if (!updatedRequest?.id) return;
			const { commit } = actionContext(context);
			commit.UPDATE_DOCUMENT_REQUEST(updatedRequest);
			await ApplicationDocumentRequestService.updateApplicationDocumentRequest(updatedRequest);
		},
		async reOpenApplicationDocumentRequest(
			context,
			updatedRequest: OnlinePortalApplicationDocumentRequest
		): Promise<OnlinePortalApplicationDocumentRequest> {
			return await ApplicationDocumentRequestService.reopen(updatedRequest);
		},
		async addDocumentRequest(context, document: OnlinePortalApplicationDocumentRequest): Promise<void> {
			const { commit, state } = actionContext(context);
			const { documentRequests = {} } = state;

			if (!document.customerId) {
				return;
			}

			const documentRequestForCustomer = documentRequests[document.customerId] ? [...documentRequests[document.customerId], document] : [document];
			commit.SET_DOCUMENT_REQUESTS({
				...state.documentRequests,
				[document.customerId]: documentRequestForCustomer
			});
		},
		async deleteApplicationDocumentRequest(context, requestToDelete: OnlinePortalApplicationDocumentRequest): Promise<void> {
			const { state, commit } = actionContext(context);
			let requestCustomerId: number = requestToDelete.customerId ? (requestToDelete.customerId as number) : 0;
			let isAdditionalDocument: boolean = !requestToDelete.id || requestToDelete.id < 0 || !requestToDelete.dateRequested;
			if (isAdditionalDocument && requestCustomerId && state.documentRequests) {
				let updatedDocumentRequests = state.documentRequests;
				updatedDocumentRequests[requestCustomerId] = updatedDocumentRequests[requestCustomerId].filter(x => x.id && x.id !== requestToDelete.id);
				commit.SET_DOCUMENT_REQUESTS(updatedDocumentRequests);
				if (requestToDelete.id) {
					return await ApplicationDocumentRequestService.deleteApplicationDocumentRequest(requestToDelete);
				}
			}
		}
	},
	getters: {
		numOpenRequests(state): number {
			if (!state.documentRequests) {
				return 0;
			}

			return expandGroupedDocumentRequests(state.documentRequests).reduce((openRequests, requestToCheck) => {
				let isOpenRequest = !requestToCheck.dateUploaded;
				if (isOpenRequest) {
					openRequests += 1;
				}
				return openRequests;
			}, 0);
		},
		getById(state) {
			return function(id: number): OnlinePortalApplicationDocumentRequest | null {
				if (!state.documentRequests) return null;
				for (const customerName in state.documentRequests) {
					const documentRequests = state.documentRequests[customerName];
					let index = documentRequests.findIndex(x => x.id === id);
					if (index >= 0) {
						return documentRequests[index];
					}
				}
				return null;
			};
		},
		allRequestsValidForSubmit(state): boolean {
			if (!state.documentRequests) {
				return false;
			}
			// the documentRequests in this store module are grouped by customer name, so we'll need to expand those back out
			const requests = expandGroupedDocumentRequests(state.documentRequests);
			const allValid = requests.every(request => {
				return request.dateUploaded || request.dateWaived || request.waivedReason || request.waivedByLoanOfficerId || request.notUploadedReason;
			});
			return allValid;
		}
	}
});

export default ApplicationDocumentRequestModule;
