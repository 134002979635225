
import { Component, Mixins } from 'vue-property-decorator';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { ScaleMixin, RouteMixin } from '@/mixins';
import { NavigationCardWithIcon } from '@/models/NavigationCard';
import { FunctionUtils } from '@/utils';
import { UsableProducts } from '@sageworks/jpi';

@Component({
	components: {
		FontAwesomeIcon
	}
})
export default class Homepage extends Mixins(ScaleMixin, RouteMixin) {
	get numOpenDocumentRequests(): number {
		return this.directStore.getters.DocumentRequest.numOpenRequests;
	}
	get numberOfOpenApplications(): number {
		return this.directStore.getters.Application.numberOfOpenApplications;
	}
	get homepageHeader(): string {
		return `Welcome to your portal${this.directStore.getters.User.fullName ? ', ' + this.directStore.getters.User.fullName : ''}!`;
	}
	get itemLabel(): string {
		if (this.hasCommunityLendingSubscription === undefined) {
			return '';
		}
		return this.hasCommunityLendingSubscription ? 'Request Form' : 'Application';
	}
	get hasCommunityLendingSubscription(): boolean | undefined {
		return this.directStore.state.UsableProducts.usableProducts?.has('abrigoCommunityLending' as UsableProducts.ProductsEnum);
	}
	private navigationCards: Array<NavigationCardWithIcon> = [];

	public async beforeMount(): Promise<void> {
		await this.directStore.dispatch.UsableProducts.fetchUsableProducts();
	}

	private created(): void {
		this.navigationCards = [
			{
				onClickFunction: FunctionUtils.closure({ router: this.$router, routes: this.OnlinePortalRoutes }, ({ router, routes }) => {
					router.push(routes.Applications.path);
				}),
				messageCondition: FunctionUtils.closure(this.numberOfOpenApplications, numberOfOpenApplications => numberOfOpenApplications > 0),
				primaryMessage:
					this.numberOfOpenApplications > 1 ? this.numberOfOpenApplications + ' Open ' + this.itemLabel + 's' : '1 Open ' + this.itemLabel,
				secondaryMessage: this.hasCommunityLendingSubscription
					? 'No requests in progress! Click to view completed requests or start a new one.'
					: 'No applications in progress! Click to view completed apps or start a new one.',
				icon: { color: 'text-primary', size: 'lg', style: 'far', name: 'stream' }
			} as NavigationCardWithIcon,
			{
				onClickFunction: FunctionUtils.closure({ router: this.$router, routes: this.OnlinePortalRoutes }, ({ router, routes }) => {
					router.push(routes.Documents.path);
				}),
				messageCondition: FunctionUtils.closure(this.numOpenDocumentRequests, numOpenDocumentRequests => numOpenDocumentRequests > 0),
				primaryMessage: this.numOpenDocumentRequests > 1 ? this.numOpenDocumentRequests + ' Documents Requested' : '1 Document Requested',
				secondaryMessage: 'No outstanding document requests!',
				icon: { color: 'text-primary', size: 'lg', style: 'far', name: 'clipboard-list' }
			} as NavigationCardWithIcon
		];
	}
}
