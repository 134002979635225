import { CustomComponentType, TemplateDataField } from '../../../../../enums';
import RadioComponent from 'formiojs/components/radio/Radio';
import { FarmInfoWidget, BusinessInfoWidget } from '../../../../form-components';
import AddNewCustomer from '../../../../form-components/add-customer/add-new-customer/add-new-customer';
import { DataField } from '@sageworks/jpi';
import { getRadioSchema } from '.';

export class AddNewBusinessInvestment extends AddNewCustomer {
	static defaultRadioValue: string = 'business';

	static schema(editMode: boolean, dataFields: DataField[], ...extend: any) {
		return AddNewCustomer.schema(
			editMode,
			dataFields,
			null as any,
			this.getFormComponents(editMode, dataFields),
			{
				type: CustomComponentType.addNewBusinessInvestment,
				key: 'form'
			},
			...extend
		);
	}

	static getFormComponents(editMode: boolean, dataFields: DataField[]): any {
		return {
			components: [
				RadioComponent.schema(getRadioSchema(editMode, this.defaultRadioValue)),
				FarmInfoWidget.schema(this.getChildSchema(CustomComponentType.farmInfo, dataFields, editMode)),
				BusinessInfoWidget.schema(this.getChildSchema(CustomComponentType.businessInfo, dataFields, editMode))
			]
		};
	}

	private static getRequiredBusinessDataFields(): TemplateDataField[] {
		return [TemplateDataField.BusinessName, TemplateDataField.BusinessState, TemplateDataField.BusinessIndustryCode];
	}

	static getBusinessDataFields(): TemplateDataField[] {
		return [...this.getRequiredBusinessDataFields(), TemplateDataField.BusinessSubEntityType];
	}

	static getRequiredFields(): TemplateDataField[] {
		return [...this.getRequiredBusinessDataFields(), ...this.getFarmDataFields()];
	}

	static getDataFieldLabelOverrides(): Map<TemplateDataField, string> {
		return new Map<TemplateDataField, string>([[TemplateDataField.BusinessSubEntityType, 'Business Type']]);
	}
}
