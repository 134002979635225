
import Vue from 'vue';
import {UsableProducts} from '@sageworks/jpi';

export default Vue.extend({
	name: 'RemoveLoanModal',
	components: {},
	props: {
		productName: {
			type: String,
			required: true,
			default: 'Application'
		},
		id: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			removingFromApplication: false
		};
	},
	computed: {
		currentLoanIndex(): number {
			return this.directStore.state.MultiLoanApplicationForm.loanIndex;
		},
		hasLosLiteSubscription(): boolean | undefined {
			return this.directStore.state.UsableProducts.usableProducts?.has('abrigoCommunityLending' as UsableProducts.ProductsEnum);
		},
		applicationRequestLabel(): string {
			return this.hasLosLiteSubscription ? 'Request Form' : 'Application';
		},
		title(): string {
			return `Remove Loan from ${this.applicationRequestLabel}`;
		}
	},
	mounted() {},
	methods: {
		async removeFromApplication(close: Function) {
			this.removingFromApplication = true;
			await this.directStore.dispatch.LoanApplicationMetadata.removeLoan({ loanIndex: this.currentLoanIndex });

			const newIndex = this.currentLoanIndex > 0 ? this.currentLoanIndex - 1 : 0;
			await this.directStore.dispatch.MultiLoanApplicationForm.loanChange({ loanIndex: newIndex });

			close();
			this.removingFromApplication = false;
		}
	}
	
});
