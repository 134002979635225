
import Vue from 'vue';
import { MainTemplate } from '../../components/templates';
import { SessionInactivityPrompt, LoanOfficerContactCard, LenderViewAlert } from '@/components';
import { MainContentPortalCard, PortalHeader, PortalCardNavigationBar  } from '@/components/portal';
import { StylingConstants } from '@/enums/styling-constants';

export default Vue.extend({
	name: 'PortalTemplateUpdated',
	components: {
		MainTemplate,
		SessionInactivityPrompt,
		LoanOfficerContactCard,
		LenderViewAlert,
		PortalHeader,
		MainContentPortalCard,
		PortalCardNavigationBar
	},
	computed: {
		backgroundClassName(): string {
			return `${StylingConstants.backgroundClassName} pb-2`;
		}
	}
});
