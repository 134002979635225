import { DataObject } from '@sageworks/dynamic-forms';
import { AutoFactory, OnlinePortalLoanApplicationLoanPersonalInputDataMappingService, PersonalFinancialData } from '@sageworks/jpi';
import { defineActions } from 'direct-vuex';
import { actionContext } from '../store-helper';


export default defineActions({
	async addPFS(context, { id, loanMappingId }: { id: number, loanMappingId: number }) {
		const { commit } = actionContext(context);

		// Create OPN mapping for the personal financial statement
		const service = AutoFactory.get(OnlinePortalLoanApplicationLoanPersonalInputDataMappingService);
		const mapping = await service.create({
			loanApplicationProposedLoanMappingId: loanMappingId,
			personalInputDataId: id
		});

		const formattedMapping: PersonalFinancialData = {
			id: mapping.id,
			personalInputDataID: mapping.personalInputDataId	
		};
		commit.UPSERT_PFS({ pfs: formattedMapping });
	},
	async removePFS(context, { mappingId }: { mappingId: number }): Promise<void> {
		const { commit } = actionContext(context);

		if (mappingId && mappingId > 0) {
			const service = AutoFactory.get(OnlinePortalLoanApplicationLoanPersonalInputDataMappingService);
			await service._delete(mappingId);

			// Remove data object from store
			commit.REMOVE_DATA_OBJECT({ type: DataObject.TypeEnum.FinancialSubaccounts, id: mappingId });

			// Remove mapping from store
			commit.REMOVE_PFS({ id: mappingId });
		}
	},
});
