module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }

 
	var _helpers = ctx.options.builderTemplateHelpers || {};

    // because we use a Wizard as the root component, this should be an accurate indicator of whether a Wizard is root 
    var isRootWizard = ctx.options.parentPath == null ? true : false;

    // pseudo-property definitions for usage within template
	var showCancelButton = _helpers.shouldWizardShowCancelButton && _helpers.shouldWizardShowCancelButton(ctx.instance);
	var submitText = (ctx.options.contextData && ctx.options.contextData.isPreapp) ? 'continue' : 'submit';
;
__p += '\n\n<ul class="list-inline mb-0" id="' +
((__t = ( ctx.wizardKey )) == null ? '' : __t) +
'-nav" role="form">\n	';
 if (ctx.buttons.cancel) { ;
__p += '\n	<li class="list-inline-item mr-2 ' +
((__t = ( showCancelButton ? '' : 'd-none' )) == null ? '' : __t) +
'">\n		<button class="btn btn-secondary btn-wizard-nav-cancel" ref="' +
((__t = ( ctx.wizardKey )) == null ? '' : __t) +
'-cancel">' +
((__t = ( ctx.t('cancel') )) == null ? '' : __t) +
'</button>\n	</li>\n	';
 } ;
__p += ' ';
 if (ctx.buttons.previous) { ;
__p += '\n	<li class="list-inline-item mr-2">\n		<button class="btn btn-outline-primary btn-wizard-nav-previous" ref="' +
((__t = ( ctx.wizardKey )) == null ? '' : __t) +
'-previous">\n			';

				var previousPanel = ctx.panels[ctx.currentPage - 1].component;
				var previousPanelTitle = previousPanel != null ? previousPanel.title : ctx.t('rootPrevious');
			;
__p += '\n			';
 if (isRootWizard) { ;
__p += '\n				Back\n				<span class="d-none d-sm-inline-block">to ' +
((__t = ( previousPanelTitle )) == null ? '' : __t) +
'</span>\n			';
 } else { ;
__p += '\n				' +
((__t = ( ctx.t('previous') )) == null ? '' : __t) +
'\n			';
 } ;
__p += '\n		</button>\n	</li>\n	';
 } ;
__p += ' ';
 if (ctx.buttons.next) { ;
__p += '\n	<li class="list-inline-item">\n		<button class="btn btn-primary btn-wizard-nav-next" ref="' +
((__t = ( ctx.wizardKey )) == null ? '' : __t) +
'-next">\n			';

				var nextPanel = ctx.panels[ctx.currentPage + 1].component;
				var nextPanelTitle = nextPanel != null ? nextPanel.title : ctx.t('rootNext');
			;
__p += '\n			';
 if (isRootWizard) { ;
__p += '\n				Continue\n				<span class="d-none d-sm-inline-block">to ' +
((__t = ( nextPanelTitle )) == null ? '' : __t) +
'</span>\n			';
 } else { ;
__p += '\n				' +
((__t = ( ctx.t('next') )) == null ? '' : __t) +
'\n			';
 } ;
__p += '\n		</button>\n	</li>\n	';
 } ;
__p += ' ';
 if (ctx.buttons.submit && ctx.options.contextData && ctx.options.contextData.showSubmit) { ;
__p += '\n	<li class="list-inline-item">\n		<button class="btn btn-primary btn-wizard-nav-submit" ref="' +
((__t = ( ctx.wizardKey )) == null ? '' : __t) +
'-submit">' +
((__t = ( ctx.t(submitText) )) == null ? '' : __t) +
'</button>\n	</li>\n	';
 } ;
__p += '\n</ul>';
return __p
}