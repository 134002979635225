
import { Component, Vue } from 'vue-property-decorator';
import { formatPhoneNumber } from '@/utils/FormattingUtils';
import { isEmptyObject } from '@/utils/ObjectUtils';
import { PortalCard } from '@/components/portal';

@Component({
	components: {
		PortalCard
	}
})
export default class LoanOfficerContactCard extends Vue {
	private imgHeight = 0;
	private imgWidth = 0;

	private onImgLoad(): void {
		this.imgHeight = (this.$refs['contactImage'] as any).naturalHeight;
		this.imgWidth = (this.$refs['contactImage'] as any).naturalWidth;
	}

	get contactMessageAvailable(): boolean {
		return (
			!isEmptyObject(this.directStore.state.InstitutionSettings.contactMessage) &&
			this.directStore.state.InstitutionSettings.contactMessage.message.length > 0
		);
	}

	get contactInformationAvailable(): boolean {
		return !isEmptyObject(this.directStore.state.ContactInformation.contactInfo);
	}

	get hasContactImage() {
		return (this.directStore.state.ContactInformation.contactInfo.imageURL ?? '').length > 0;
	}

	get hasEmail() {
		return (this.directStore.state.ContactInformation.contactInfo.email ?? '').length > 0;
	}

	get hasPhone() {
		return this.formattedPhoneNumber.length > 0;
	}

	get contactImageUrl() {
		return this.directStore.state.ContactInformation.contactInfo.imageURL;
	}

	get formattedPhoneNumber(): string {
		return formatPhoneNumber(this.directStore.state.ContactInformation.contactInfo.phone);
	}

	get imageOrientationClasses(): Array<String> {
		if (this.directStore.state.ContactInformation.contactInfo.imageURL) {
			if (this.imgWidth > this.imgHeight) {
				return ['w-auto', 'h-100', 'ml-n25'];
			} else if (this.imgHeight > this.imgWidth) {
				return ['w-100', 'h-auto'];
			} else {
				return [];
			}
		}
		return ['image-frame'];
	}
}
