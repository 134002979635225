
import Vue, { PropType } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default Vue.extend({
	name: 'ApplicationValidationModalStep',
	components: { FontAwesomeIcon },
	props: {
		stepId: {
			type: String as PropType<string>,
			default: '',
			required: true
		},
		isValidating: {
			type: Boolean,
			default: false,
		},
		result: {
			type: Boolean as PropType<boolean | undefined>,
			default: undefined,
		},
		description: {
			type: String as PropType<string>,
			default: '',
			required: true
		},
	},
	data() {
		return {};
	},
	computed: {
		validationTextClass(): string {
			switch (this.result) {
				case true:
					return 'text-primary';
				case false:
					return 'text-danger';
				default:
					return '';
			}
		},
	},
});
