import Component from 'formiojs/components/_classes/component/Component';
import { ComponentDataHelper } from '../../../utils';
import { FetchDataType, DataObject } from '../../../enums';
import { DataField } from '@sageworks/jpi';

export class BeneficialOwnershipService {
	private readonly _datafieldCacheKey = 'ben-owner-preset-fields';

	constructor(private component: Component) {}

	public async getDataFields() {
		return await ComponentDataHelper.fetchData<DataField[]>(this.component, {
			fetchType: FetchDataType.PresetDataFields,
			fetchContext: {
				dataObjectType: DataObject.TypeEnum.BeneficialOwnerDetails
			},
			cacheKey: this._datafieldCacheKey
		});
	}
}
