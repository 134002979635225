import editForm from './other-liabilities.form';
import { CustomComponentType, CustomComponentLabel, LiabilityCustomFieldKey } from '../../../../enums';
import { FinancialSubaccount } from '../financial-subaccount/financial-subaccount';

export class OtherLiabilitiesWidget extends FinancialSubaccount {
	static schema(...extend: any) {
		return FinancialSubaccount.schema(
			{
				label: CustomComponentLabel()[CustomComponentType.otherLiabilities],
				type: CustomComponentType.otherLiabilities,
				key: CustomComponentType.otherLiabilities,
				addAnother: 'Add Other Liability'
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: CustomComponentLabel()[CustomComponentType.otherLiabilities],
			group: '',
			weight: 10,
			schema: OtherLiabilitiesWidget.schema()
		};
	}

	protected getFormattedDescription(_: string, row: any) {
		return `${row.data?.[LiabilityCustomFieldKey.DueTo]} - ${row.data?.[LiabilityCustomFieldKey.LiabilityType]}`;
	}
}
