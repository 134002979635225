
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { required, email } from 'vuelidate/lib/validators';
import { ScaleMixin, RouteMixin } from '@/mixins';
import { OnlinePortalUser } from '@sageworks/jpi';

@Component({
	validations: {
		firstName: {
			required
		},
		lastName: {
			required
		},
		email: {
			required,
			email
		}
	}
})
export default class SetContactInformation extends Mixins(ScaleMixin, RouteMixin) {
	@Prop()
	private redirectRoutePath!: string;

	private submitting = false;
	private firstName: string | undefined = '';
	private lastName: string | undefined = '';
	private email: string | undefined = '';
	private validationMessages: Map<string, string> = new Map([
		['firstName', 'This field is required'],
		['lastName', 'This field is required'],
		['email', 'Please enter a valid email address']
	]);
	private showValidation: boolean = false;

	private async mounted() {
		this.firstName = this.directStore.state.User.user!.firstName;
		this.lastName = this.directStore.state.User.user!.lastName;
		this.email = this.directStore.state.User.user!.email;
		this.auth0FFEnabled = await this.directStore.dispatch.Authentication.isAuth0Enabled();
		if (this.auth0FFEnabled) {
			// this is a hack to disable back button so auth0 errors won't appear
			history.pushState(null, null, location.href);
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			window.onpopstate = function(event) {
				history.go(1);
			};
		}
	}

	private validationState(fieldName: string) {
		if (!this.showValidation) {
			return null;
		} else if (this.$v[fieldName].$invalid) {
			return false;
		}
		return true;
	}

	private async submit(): Promise<void> {
		this.showValidation = true;
		if (this.$v.$invalid) {
			return;
		}
		this.submitting = true;
		await this.directStore.dispatch.User.patchCurrentUser({
			firstName: this.firstName,
			lastName: this.lastName,
			email: this.email
		} as OnlinePortalUser);
		this.$router.push(this.redirectRoutePath || this.OnlinePortalRoutes.Home.path);
	}
}
