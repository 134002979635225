import Component from 'formiojs/components/_classes/component/Component';
import { CustomComponentType } from '../../../enums';
import { IPopupBodyComponent } from '../popup';

export class LoadingPopup extends Component implements IPopupBodyComponent {
	public resultPromise!: Promise<any>;
	public resultPromiseResolve: any;

	public preClose = () => {
		return Promise.reject();
	};

	public static schema(...extend: any) {
		return Component.schema(
			{
				label: 'Loading',
				type: CustomComponentType.loadingPopup,
				key: CustomComponentType.loadingPopup,
				roleType: null,
				input: false
			},
			...extend
		);
	}

	public constructor(schema: any, options: any, data: any, loadingPromise?: Promise<any>) {
		super(schema, options, data);

		this.resultPromise = new Promise(r => (this.resultPromiseResolve = r));

		if (loadingPromise) {
			loadingPromise.finally(() => this.resultPromiseResolve());
		} else {
			this.resultPromiseResolve();
		}
	}

	public render(): any {
		const modalTitle = this.component.modalTitle ?? 'Saving Progress';
		return this.renderTemplate('loadingPopup', { modalTitle });
	}

	public async attach(element: any) {
		await super.attach(element);
	}
}
