import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';
import store from '@/store';
import {
	LoginTemplate,
	PortalTemplate,
	PortalTemplateUpdated,
	ApplicationTemplate,
	LoginEmail,
	LoginPassword,
	ForgotPassword,
	PasswordReset,
	CreatePassword,
	CreateAccount,
	SetAccountPassword,
	SetContactInformation,
	Homepage,
	PortalDataLoadRedirect,
	MyInfo,
	ChangeEmail,
	ChangePassword,
	Documents,
	DocumentDetails,
	Applications,
	Documentation,
	PreviewForm,
	NotFound,
	HealthPage,
	ApplicationPreapp,
	ApplicationDataLoadRedirect,
	VerifyEmail,
	VerifyIdentity,
	VerifyKnowledgeBasedAnswers,
	IdentityProtection,
	MultiLoanApplicationForm,
	MultiLoanApplicationSubmit,
	ApplicationExport,
	ApplicationsUpdated,
	DocumentsUpdated,
	HomepageUpdated,
	MyInfoUpdated
} from '@/views';
import { OnlinePortalRoutes } from '@/OnlinePortalRoutes';
import { FeatureFlagging } from '@sageworks/jpi';

Vue.use(Router);

export const routes: RouteConfig[] = [
	{
		path: OnlinePortalRoutes.Login.path,
		name: OnlinePortalRoutes.Login.name,
		meta: { unauthenticated: true },
		component: LoginTemplate,
		redirect: { path: OnlinePortalRoutes.LoginEmail.path },
		children: [
			{
				path: OnlinePortalRoutes.LoginEmail.path,
				name: OnlinePortalRoutes.LoginEmail.name,
				meta: { unauthenticated: true, title: OnlinePortalRoutes.LoginEmail.title },
				component: LoginEmail,
				async beforeEnter(to, from, next) {
					if (await store.dispatch.Authentication.validateSession()) {
						next({ path: OnlinePortalRoutes.Home.path });
					}
					next();
				}
			},
			{
				path: OnlinePortalRoutes.LoginPassword.path,
				name: OnlinePortalRoutes.LoginPassword.name,
				meta: { unauthenticated: true, title: OnlinePortalRoutes.LoginPassword.title },
				component: LoginPassword,
				beforeEnter(to, from, next) {
					if (!store.state.Login.userEmail) {
						next({ path: OnlinePortalRoutes.Login.path });
					}
					next();
				}
			},
			{
				path: OnlinePortalRoutes.IdentityProtection.path,
				name: OnlinePortalRoutes.IdentityProtection.name,
				meta: { unauthenticated: true, title: OnlinePortalRoutes.IdentityProtection.title },
				component: IdentityProtection,
				beforeEnter(to, from, next) {
					if (!store.state.Login.userEmail) {
						next({ path: OnlinePortalRoutes.Login.path });
					}
					next();
				}
			},
			{
				path: OnlinePortalRoutes.VerifyIdentity.path,
				name: OnlinePortalRoutes.VerifyIdentity.name,
				meta: { unauthenticated: true, title: OnlinePortalRoutes.VerifyIdentity.title },
				component: VerifyIdentity,
				beforeEnter(to, from, next) {
					if (!store.state.Login.userEmail) {
						next({ path: OnlinePortalRoutes.Login.path });
					}
					next();
				}
			},
			{
				path: OnlinePortalRoutes.VerifyKnowledgeBasedAnswers.path,
				name: OnlinePortalRoutes.VerifyKnowledgeBasedAnswers.name,
				meta: { unauthenticated: true, title: OnlinePortalRoutes.VerifyKnowledgeBasedAnswers.title },
				component: VerifyKnowledgeBasedAnswers,
				beforeEnter(to, from, next) {
					if (!store.state.Login.userEmail) {
						next({ path: OnlinePortalRoutes.Login.path });
					}
					next();
				}
			},
			{
				path: OnlinePortalRoutes.VerifyEmail.path,
				name: OnlinePortalRoutes.VerifyEmail.name,
				meta: { unauthenticated: true, title: OnlinePortalRoutes.VerifyEmail.title },
				component: VerifyEmail,
				beforeEnter(to, from, next) {
					if (!store.state.Login.userEmail) {
						next({ path: OnlinePortalRoutes.Login.path });
					}
					next();
				}
			},
			{
				path: OnlinePortalRoutes.ForgotPassword.path,
				name: OnlinePortalRoutes.ForgotPassword.name,
				meta: { unauthenticated: true, title: OnlinePortalRoutes.ForgotPassword.title },
				component: ForgotPassword
			},
			{
				path: OnlinePortalRoutes.PasswordReset.path,
				name: OnlinePortalRoutes.PasswordReset.name,
				meta: { title: OnlinePortalRoutes.PasswordReset.title },
				component: PasswordReset
			},
			{
				path: OnlinePortalRoutes.CreatePassword.path,
				name: OnlinePortalRoutes.CreatePassword.name,
				component: CreatePassword,
				meta: { unauthenticated: true, title: OnlinePortalRoutes.CreatePassword.title }
			},
			{
				path: OnlinePortalRoutes.CreateAccount.path,
				name: OnlinePortalRoutes.CreateAccount.name,
				component: CreateAccount,
				meta: { unauthenticated: true, title: OnlinePortalRoutes.CreateAccount.title }
			},
			{
				path: OnlinePortalRoutes.SetAccountPassword.path,
				name: OnlinePortalRoutes.SetAccountPassword.name,
				component: SetAccountPassword,
				meta: { title: OnlinePortalRoutes.SetAccountPassword.title },
				beforeEnter(to, from, next) {
					if (to.query.userEmail) {
						store.commit.Login.SET_USER_EMAIL(to.query.userEmail as string);
					}
					next();
				}
			},
			{
				path: OnlinePortalRoutes.Signup.path,
				name: OnlinePortalRoutes.Signup.name,
				meta: { unauthenticated: true, title: OnlinePortalRoutes.Signup.title },
				async beforeEnter(to, from, next) {
					if (await store.dispatch.Authentication.isAuth0Enabled()) {
						next();
					}
				}
			},
			{
				path: OnlinePortalRoutes.SetContactInformation.path,
				name: OnlinePortalRoutes.SetContactInformation.name,
				meta: { title: OnlinePortalRoutes.SetContactInformation.title },
				component: SetContactInformation
			},
			{
				path: OnlinePortalRoutes.PortalDataLoadRedirect.path,
				name: OnlinePortalRoutes.PortalDataLoadRedirect.name,
				component: PortalDataLoadRedirect,
				props: true
			}
		]
	},
	{
		path: OnlinePortalRoutes.Portal.path,
		name: OnlinePortalRoutes.Portal.name,
		meta: { title: OnlinePortalRoutes.Portal.title },
		// Remove feature check and rename vue page component when FF is no longer needed
		component: async () => {
			const isLoanStatusFeatureEnabled = await store.dispatch.FeatureFlag.fetchIsFeatureFlagActive(
				FeatureFlagging.FeatureFlagEnum.EnableOpnBorrowerLoanStatus
			);

			if (isLoanStatusFeatureEnabled) {
				return PortalTemplateUpdated;
			}

			return PortalTemplate;
		},
		redirect: { path: OnlinePortalRoutes.Home.path },
		children: [
			{
				path: OnlinePortalRoutes.Home.path,
				name: OnlinePortalRoutes.Home.name,
				// Remove feature check and rename vue page component when FF is no longer needed
				component: async () => {
					const isLoanStatusFeatureEnabled = await store.dispatch.FeatureFlag.fetchIsFeatureFlagActive(
						FeatureFlagging.FeatureFlagEnum.EnableOpnBorrowerLoanStatus
					);

					if (isLoanStatusFeatureEnabled) {
						return HomepageUpdated;
					}

					return Homepage;
				},
				meta: { title: OnlinePortalRoutes.Home.title }
			},
			{
				path: OnlinePortalRoutes.Documents.path,
				name: OnlinePortalRoutes.Documents.name,
				// Remove feature check and rename vue page component when FF is no longer needed
				component: async () => {
					const isLoanStatusFeatureEnabled = await store.dispatch.FeatureFlag.fetchIsFeatureFlagActive(
						FeatureFlagging.FeatureFlagEnum.EnableOpnBorrowerLoanStatus
					);

					if (isLoanStatusFeatureEnabled) {
						return DocumentsUpdated;
					}

					return Documents;
				},
				meta: { title: OnlinePortalRoutes.Documents.title },
				beforeEnter(to, from, next) {
					if (!to.query.page) {
						to.query.page = '1';
					}
					next();
				}
			},
			{
				path: OnlinePortalRoutes.DocumentRequestDetails.path,
				name: OnlinePortalRoutes.DocumentRequestDetails.name,
				component: DocumentDetails,
				meta: { title: OnlinePortalRoutes.DocumentRequestDetails.title },
				beforeEnter(to, from, next) {
					if (!to.params.documentRequestId) {
						next({ path: OnlinePortalRoutes.Documents.path });
					}
					next();
				}
			},
			{
				path: OnlinePortalRoutes.Applications.path,
				name: OnlinePortalRoutes.Applications.name,
				// Remove feature check and rename vue page component when FF is no longer needed
				component: async () => {
					const isLoanStatusFeatureEnabled = await store.dispatch.FeatureFlag.fetchIsFeatureFlagActive(
						FeatureFlagging.FeatureFlagEnum.EnableOpnBorrowerLoanStatus
					);

					if (isLoanStatusFeatureEnabled) {
						return ApplicationsUpdated;
					}

					return Applications;
				},
				meta: { title: OnlinePortalRoutes.Applications.title },
				beforeEnter(to, from, next) {
					if (!to.query.page) {
						to.query.page = '1';
					}
					next();
				}
			},
			{
				path: OnlinePortalRoutes.MyInfo.path,
				name: OnlinePortalRoutes.MyInfo.name,
				// Remove feature check and rename vue page component when FF is no longer needed
				component: async () => {
					const isLoanStatusFeatureEnabled = await store.dispatch.FeatureFlag.fetchIsFeatureFlagActive(
						FeatureFlagging.FeatureFlagEnum.EnableOpnBorrowerLoanStatus
					);

					if (isLoanStatusFeatureEnabled) {
						return MyInfoUpdated;
					}

					return MyInfo;
				},
				meta: { title: OnlinePortalRoutes.MyInfo.title }
			},
			{
				path: OnlinePortalRoutes.ChangeEmail.path,
				name: OnlinePortalRoutes.ChangeEmail.name,
				component: ChangeEmail,
				meta: { title: OnlinePortalRoutes.ChangeEmail.title }
			},
			{
				path: OnlinePortalRoutes.ChangePassword.path,
				name: OnlinePortalRoutes.ChangePassword.name,
				component: ChangePassword,
				meta: { title: OnlinePortalRoutes.ChangePassword.title },
				async beforeEnter(to, from, next) {
					if (await store.dispatch.Authentication.isAuth0Enabled()) {
						next({ path: OnlinePortalRoutes.MyInfo.path });
					}
					next();
				}
			}
		],
		beforeEnter(to, from, next) {
			if (!store.state.Application.loanApplications || !store.state.DocumentRequest.currentPageDocumentRequests) {
				next({ name: OnlinePortalRoutes.PortalDataLoadRedirect.name, params: { redirectRoutePath: to.path } });
			}
			next();
		}
	},
	{
		path: OnlinePortalRoutes.Application.path,
		name: OnlinePortalRoutes.Application.name,
		component: ApplicationTemplate,
		meta: { title: OnlinePortalRoutes.Application.title },
		redirect: { path: OnlinePortalRoutes.ApplicationDataLoadRedirect.path },
		children: [
			{
				path: OnlinePortalRoutes.ApplicationDataLoadRedirect.path,
				name: OnlinePortalRoutes.ApplicationDataLoadRedirect.name,
				component: ApplicationDataLoadRedirect,
				props: true
			},
			{
				path: OnlinePortalRoutes.PreApplication.path,
				name: OnlinePortalRoutes.PreApplication.name,
				meta: { title: OnlinePortalRoutes.PreApplication.title },
				component: ApplicationPreapp,
				props: true,
				beforeEnter(to, from, next) {
					// If the user clicks 'back' from a newly-started application, we want to send them to the apps homepage rather than re-entering the preapp
					if (from.name === OnlinePortalRoutes.ApplicationForm.name) {
						next({ path: OnlinePortalRoutes.Applications.path });
					}
					next();
				}
			},
			{
				path: OnlinePortalRoutes.ExportToPdf.path,
				name: OnlinePortalRoutes.ExportToPdf.name,
				meta: { title: OnlinePortalRoutes.ExportToPdf.title },
				component: async () => {
					const serverSidePdfEnabled = await store.dispatch.FeatureFlag.fetchIsFeatureFlagActive(
						FeatureFlagging.FeatureFlagEnum.EnableOnlinePortalServerSidePdf
					);

					if (serverSidePdfEnabled) {
						return ApplicationExport;
					}

					return MultiLoanApplicationForm;
				},
				props: route => ({
					applicationId: route.params.applicationId,
					forceReadOnly: true,
					lenderOnlyMode: false,
					forceExportState: Boolean(route.query.forceExportState) ?? false,
					messageChannel: route.query.messageChannel
				})
			},
			{
				path: OnlinePortalRoutes.ExportToLenderPdf.path,
				name: OnlinePortalRoutes.ExportToLenderPdf.name,
				meta: { title: OnlinePortalRoutes.ExportToLenderPdf.title },
				component: async () => {
					const serverSidePdfEnabled = await store.dispatch.FeatureFlag.fetchIsFeatureFlagActive(
						FeatureFlagging.FeatureFlagEnum.EnableOnlinePortalServerSidePdf
					);

					if (serverSidePdfEnabled) {
						return ApplicationExport;
					}

					return MultiLoanApplicationForm;
				},
				props: route => ({
					applicationId: route.params.applicationId,
					forceReadOnly: true,
					lenderOnlyMode: true,
					forceExportState: Boolean(route.query.forceExportState) ?? false,
					messageChannel: route.query.messageChannel
				})
			},
			{
				path: OnlinePortalRoutes.ApplicationForm.path,
				name: OnlinePortalRoutes.ApplicationForm.name,
				meta: { title: OnlinePortalRoutes.ApplicationForm.title },
				component: MultiLoanApplicationForm,
				props: true
			},
			{
				path: OnlinePortalRoutes.ApplicationDocumentation.path,
				name: OnlinePortalRoutes.ApplicationDocumentation.name,
				component: Documentation,
				meta: { title: OnlinePortalRoutes.ApplicationDocumentation.title },
				props: true
			},
			{
				path: OnlinePortalRoutes.ApplicationPreview.path,
				name: OnlinePortalRoutes.ApplicationPreview.name,
				component: PreviewForm,
				meta: { unauthenticated: true, title: OnlinePortalRoutes.ApplicationPreview.title },
				props: true
			},
			{
				path: OnlinePortalRoutes.ApplicationSubmit.path,
				name: OnlinePortalRoutes.ApplicationSubmit.name,
				component: MultiLoanApplicationSubmit,
				meta: { title: OnlinePortalRoutes.ApplicationSubmit.title },
				props: true
			}
		],
		beforeEnter(to, from, next) {
			if (
				to.path !== OnlinePortalRoutes.ApplicationDataLoadRedirect.path &&
				to.name !== OnlinePortalRoutes.ApplicationPreview.name &&
				((store.state.User.user == null && store.state.User.proxyUser == null) || store.state.ApplicationTemplates.preAppTemplate == null)
			) {
				next({ name: OnlinePortalRoutes.ApplicationDataLoadRedirect.name, params: { redirectRoutePath: to.path }, query: to.query });
			}
			next();
		}
	},
	{
		path: OnlinePortalRoutes.NotFound.path,
		name: OnlinePortalRoutes.NotFound.name,
		meta: { unauthenticated: true, title: OnlinePortalRoutes.NotFound.title },
		component: NotFound
	},
	{
		path: OnlinePortalRoutes.HealthPage.path,
		name: OnlinePortalRoutes.HealthPage.name,
		meta: { unauthenticated: true, title: OnlinePortalRoutes.HealthPage.title },
		component: HealthPage
	},
	{
		path: '*',
		name: 'invalid-route',
		redirect: OnlinePortalRoutes.Login.path
	}
] as RouteConfig[];

// eslint-disable-next-line
export default async function GetRouter(): Promise<Router> {
	const router = new Router({ routes });

	router.beforeEach(async (to, from, next) => {
		store.dispatch.WindowInfo.recalculateCurrentBreakpoint();
		store.dispatch.SessionActivity.updateLastUserActionTime();
		if (to.path !== OnlinePortalRoutes.NotFound.path) await store.dispatch.StylingSettings.refreshStyling(to);
		if (to.query.token) {
			store.commit.Authentication.SET_USER_TOKEN(to.query.token as string);
			next({ path: to.path });
		}
		if (to.query.userEmail) {
			store.commit.Login.SET_USER_EMAIL(to.query.userEmail as string);
		}
		if (!to.meta.unauthenticated) {
			if (await store.dispatch.Authentication.validateSession()) {
				next();
			} else {
				next({ path: OnlinePortalRoutes.Login.path });
			}
		} else {
			next();
		}
	});

	router.afterEach(route => {
		if (route?.meta?.title != null) {
			document.title = 'Online Portal - ' + route.meta.title;
		} else {
			document.title = 'Online Portal';
		}
	});
	return router;
}
