import BaseComponent from '../base/base-widget/base-widget';
import editForm from './personal-financial-statement.form';
import { CustomComponentType } from '../../../enums/custom-component-type';
export default class PersonalFinancialStatementWidget extends BaseComponent {
	static schema(...extend: any) {
		return BaseComponent.schema(
			{
				label: 'Personal Financial Statement',
				type: CustomComponentType.personalFinancialStatement,
				key: CustomComponentType.personalFinancialStatement
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: 'Personal Financial Statement',
			group: '',
			weight: 10,
			schema: PersonalFinancialStatementWidget.schema()
		};
	}

	render(children: any, topLevel?: boolean) {
		if (this.isInPreviewMode) {
			return super.renderTemplate('noPreview', { name: 'Personal Financial Statement' });
		}
		return super.render(children, topLevel);
	}
}
