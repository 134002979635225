import { CustomComponentType, TemplateDataField } from '../enums';

function _defaultWidgetTypeRequiredFields(): { [key in CustomComponentType]: TemplateDataField[] } {
	let requiredFieldsByType: any = {};
	Object.values(CustomComponentType).forEach(type => {
		requiredFieldsByType[type] = [];
	});
	return requiredFieldsByType;
}

export const RequiredFieldsByWidgetType: { [key in CustomComponentType]: TemplateDataField[] } = {
	..._defaultWidgetTypeRequiredFields(),
	[CustomComponentType.personalInfo]: [TemplateDataField.PersonalFirstName, TemplateDataField.PersonalLastName],
	[CustomComponentType.businessInfo]: [
		TemplateDataField.BusinessName,
		TemplateDataField.BusinessIndustryCode,
		TemplateDataField.BusinessState,
		TemplateDataField.FranchiseDataFranchiseName
	],
	[CustomComponentType.collateralInfo]: [TemplateDataField.CollateralDescription, TemplateDataField.CollateralPledgedBy],
	[CustomComponentType.nonprofitInfo]: [TemplateDataField.NonprofitName, TemplateDataField.NonprofitState, TemplateDataField.NonprofitSectorCode]
};
