import { FormioBuilderGroupSchema } from '../../formio-interfaces/FormioBuilderOptionsSchema';
import { CustomComponentType } from '../../enums/custom-component-type';
import { CustomBuilderGroups } from '../../enums/custom-builder-groups';
import { useComponentManager } from '../../components/component-manager';
import { ApplicationTemplate, DataField } from '@sageworks/jpi';

export function getEntitySubwidgets(
	widgetType: CustomComponentType | null,
	appType: ApplicationTemplate.TypeEnum,
	templateDataFieldMapping: { [templateDataFieldId: number]: number } = {},
	properties: DataField[] = [],
	hasPlaidFeature: boolean = false
): any {
	if (displayEntitySubwidgets(widgetType)) {
		return {
			[CustomBuilderGroups.entitySubWidgets]: {
				title: 'Widgets',
				weight: 2,
				default: true,
				components: {
					...getSubWidgetComponentSchema(widgetType, appType, templateDataFieldMapping, properties, hasPlaidFeature)
				} as FormioBuilderGroupSchema
			}
		};
	}
}

export function getWidgetGroupsForEntityInfo(
	widgetType: CustomComponentType | null,
	appType: ApplicationTemplate.TypeEnum,
	templateDataFieldMapping: { [templateDataFieldId: number]: number } = {},
	properties: DataField[] = [],
	hasPlaidFeature: boolean = false
): any {
	if (displayWidgetGroupsForEntityInfo(widgetType)) {
		return {
			[CustomBuilderGroups.entityInfoWidgets]: {
				title: 'Widgets',
				weight: 1,
				default: false,
				components: {
					...getSubWidgetComponentSchema(widgetType, appType, templateDataFieldMapping, properties, hasPlaidFeature)
				} as FormioBuilderGroupSchema
			}
		};
	}
}

export function displayEntitySubwidgets(widgetType: CustomComponentType | null): boolean {
	if (widgetType == null) {
		return false;
	}

	switch (widgetType) {
		case CustomComponentType.primaryBorrowerEntity:
		case CustomComponentType.coBorrowerEntity:
		case CustomComponentType.coSignerEntity:
		case CustomComponentType.guarantorEntity:
		case CustomComponentType.creditApplicantEntity:
		case CustomComponentType.additionalEntity:
		case CustomComponentType.personalFinancialStatement:
			return true;
		default:
			return false;
	}
}

export function displayWidgetGroupsForEntityInfo(widgetType: CustomComponentType | null): boolean {
	if (widgetType == null) {
		return false;
	}

	switch (widgetType) {
		case CustomComponentType.personalInfo:
		case CustomComponentType.businessInfo:
		case CustomComponentType.nonprofitInfo:
		case CustomComponentType.farmInfo:
			return true;
		default:
			return false;
	}
}

// eslint-disable-next-line max-lines-per-function
export function getSubWidgetComponentSchema(
	parentWidgetType: CustomComponentType | null,
	appType: ApplicationTemplate.TypeEnum,
	templateDataFieldMapping: { [templateDataFieldId: number]: number },
	properties: DataField[] = [],
	hasPlaidFeature: boolean = false
): any {
	const subWidgetSchemaByType: any = {};

	// Add the extra properties to the main array
	let subWidgetTypes = [
		CustomComponentType.businessInfo,
		CustomComponentType.nonprofitInfo,
		CustomComponentType.personalInfo,
		CustomComponentType.farmInfo,
		CustomComponentType.creditReport,
		CustomComponentType.applicantBusinessQuestion,
		CustomComponentType.authorizedSigner,
		CustomComponentType.beneficialOwnershipVue,
		CustomComponentType.income,
		CustomComponentType.personalFinancialStatement,
		CustomComponentType.expense,
		CustomComponentType.otherLiabilities,
		CustomComponentType.cashAccount,
		CustomComponentType.stocksBondsBusinessHoldings,
		CustomComponentType.nonInvestmentRealEstate,
		CustomComponentType.investmentRealEstate,
		CustomComponentType.businessInvestment,
		CustomComponentType.lifeInsurance,
		CustomComponentType.retirementAccount,
		CustomComponentType.otherAssets,
		CustomComponentType.plaid

		// Hidden widgets
		//
		// CustomComponentType.trustee,
		// CustomComponentType.relatedContacts,
		// CustomComponentType.sbaForm1919,
		// CustomComponentType.sbaForm1920
	];

	if (!hasPlaidFeature) {
		subWidgetTypes = subWidgetTypes.filter(widgetType => widgetType !== CustomComponentType.plaid);
	}
	const { componentManager } = useComponentManager();
	subWidgetTypes.forEach(widgetType => {
		if (showComponent(parentWidgetType, appType, widgetType)) {
			const widgetComponent = componentManager.CustomComponents[widgetType] as any;

			if (widgetComponent.createBuilderInfo) {
				subWidgetSchemaByType[widgetType] = widgetComponent.createBuilderInfo(properties, appType);
			} else {
				subWidgetSchemaByType[widgetType] = widgetComponent.builderInfo;
			}
		}
	});
	return subWidgetSchemaByType;
}

export function showComponent(
	parentWidgetType: CustomComponentType | null,
	appType: ApplicationTemplate.TypeEnum,
	componentType: CustomComponentType
): boolean {
	switch (parentWidgetType) {
		case CustomComponentType.primaryBorrowerEntity:
		case CustomComponentType.coBorrowerEntity:
		case CustomComponentType.coSignerEntity:
		case CustomComponentType.guarantorEntity:
		case CustomComponentType.creditApplicantEntity:
		case CustomComponentType.additionalEntity:
			return _showByEntityParentComponentType(parentWidgetType, componentType, appType);
		case CustomComponentType.personalInfo:
			return _showByPersonalInfoParentComponentType(componentType, appType);
		case CustomComponentType.businessInfo:
			return _showByBusinessInfoParentComponentType(componentType, appType);
		case CustomComponentType.nonprofitInfo:
			return _showByNonprofitInfoParentComponentType(componentType);
		case CustomComponentType.farmInfo:
			return _showByFarmInfoParentComponentType(componentType);
		case CustomComponentType.personalFinancialStatement:
			return _showByPersonalFinancialStatementParentComponentType(componentType);
		default:
			return false;
	}
}

function _showByEntityParentComponentType(
	parentWidgetType: CustomComponentType,
	componentType: CustomComponentType,
	appType: ApplicationTemplate.TypeEnum
): boolean {
	switch (componentType) {
		case CustomComponentType.nonprofitInfo:
			return appType === ApplicationTemplate.TypeEnum.Nonprofit && parentWidgetType === CustomComponentType.primaryBorrowerEntity;
		case CustomComponentType.businessInfo:
			return appType === ApplicationTemplate.TypeEnum.Business || appType === ApplicationTemplate.TypeEnum.Sba;
		case CustomComponentType.farmInfo:
			return appType === ApplicationTemplate.TypeEnum.Farm;
		case CustomComponentType.personalInfo:
			return true;
		default:
			return false;
	}
}

function _showByPersonalInfoParentComponentType(componentType: CustomComponentType, appType: ApplicationTemplate.TypeEnum): boolean {
	switch (componentType) {
		case CustomComponentType.authorizedSigner:
		case CustomComponentType.trustee:
		case CustomComponentType.creditReport:
		case CustomComponentType.relatedContacts:
		case CustomComponentType.personalFinancialStatement:
		case CustomComponentType.plaid:
			return true;
		case CustomComponentType.sbaForm1919:
			return appType === ApplicationTemplate.TypeEnum.Sba;
		default:
			return false;
	}
}

function _showByFarmInfoParentComponentType(componentType: CustomComponentType): boolean {
	switch (componentType) {
		case CustomComponentType.authorizedSigner:
		case CustomComponentType.beneficialOwnershipVue:
			return true;
		default:
			return false;
	}
}

function _showByPersonalFinancialStatementParentComponentType(componentType: CustomComponentType): boolean {
	switch (componentType) {
		case CustomComponentType.income:
		case CustomComponentType.expense:
		case CustomComponentType.otherLiabilities:
		case CustomComponentType.cashAccount:
		case CustomComponentType.stocksBondsBusinessHoldings:
		case CustomComponentType.nonInvestmentRealEstate:
		case CustomComponentType.investmentRealEstate:
		case CustomComponentType.lifeInsurance:
		case CustomComponentType.businessInvestment:
		case CustomComponentType.retirementAccount:
		case CustomComponentType.otherAssets:
			return true;
		default:
			return false;
	}
}

function _showByBusinessInfoParentComponentType(componentType: CustomComponentType, appType: ApplicationTemplate.TypeEnum): boolean {
	switch (componentType) {
		case CustomComponentType.authorizedSigner:
		case CustomComponentType.trustee:
		case CustomComponentType.relatedContacts:
		case CustomComponentType.beneficialOwnershipVue:
		case CustomComponentType.plaid:
			return true;
		case CustomComponentType.applicantBusinessQuestion:
		case CustomComponentType.sbaForm1919:
		case CustomComponentType.sbaForm1920:
			return appType === ApplicationTemplate.TypeEnum.Sba;
		default:
			return false;
	}
}

function _showByNonprofitInfoParentComponentType(componentType: CustomComponentType): boolean {
	switch (componentType) {
		case CustomComponentType.authorizedSigner:
		case CustomComponentType.trustee:
		case CustomComponentType.relatedContacts:
			return true;
		default:
			return false;
	}
}

export function showFieldBuilderGroups(componentType: CustomComponentType | null): boolean {
	switch (componentType) {
		case CustomComponentType.businessInfo:
		case CustomComponentType.personalInfo:
		case CustomComponentType.nonprofitInfo:
		case CustomComponentType.collateralInfo:
		case CustomComponentType.loans:
		case CustomComponentType.income:
		case CustomComponentType.expense:
		case CustomComponentType.farmInfo:
		case CustomComponentType.otherLiabilities:
		case CustomComponentType.cashAccount:
		case CustomComponentType.stocksBondsBusinessHoldings:
		case CustomComponentType.nonInvestmentRealEstate:
		case CustomComponentType.investmentRealEstate:
		case CustomComponentType.lifeInsurance:
		case CustomComponentType.businessInvestment:
		case CustomComponentType.retirementAccount:
		case CustomComponentType.otherAssets:
			return true;
		default:
			return false;
	}
}
