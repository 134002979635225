
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default Vue.extend({
	name: 'BeneficialOwnershipTableRowActions',
	components: {
		FontAwesomeIcon,
	},
});
