export async function fileToBase64String(fileToConvert: File): Promise<string> {
	return new Promise((resolve, reject) => {
		const fileReader = new FileReader();
		fileReader.readAsDataURL(fileToConvert);
		fileReader.onload = function() {
			resolve((fileReader.result as string).replace(/data:[^;]*;base64,/, ''));
		};
		fileReader.onerror = function(error) {
			reject(error);
		};
	});
}
