import { Customer, ObjectPropertyValues } from '@sageworks/jpi';
import { DataObjectsByType, ObjectPropertyValuesWithRoleType } from '../../models';
import { DataObject } from '../../enums';

export class DataObjectUtils {
	static createDictionaryForRoles(dataObjectsByType: DataObjectsByType): Map<number, ObjectPropertyValuesWithRoleType> {
		const flattenedDataObjectsForRoles = new Map<number, ObjectPropertyValuesWithRoleType>();

		DataObjectUtils.dataObjectTypesForRoles.forEach(type => {
			dataObjectsByType[type]?.forEach(dataObject => {
				if (dataObject.id == null) {
					return;
				}

				// NOTE: ids coming from entity table and are unique across the dataObjectTypes above.
				//      If the above dataObjectTypesForRoles array needs to be expanded to support additional types,
				//      the key below may need to become a composite key to ensure a unique key.
				//      Example - `${type}::${dataObject.id}`
				flattenedDataObjectsForRoles.set(dataObject.id, dataObject);
			});
		});

		return flattenedDataObjectsForRoles;
	}

	static get dataObjectTypesForRoles(): DataObject.TypeEnum[] {
		return [DataObject.TypeEnum.Business, DataObject.TypeEnum.Person, DataObject.TypeEnum.Farm, DataObject.TypeEnum.NonProfit];
	}

	// TODO: make this generic, something like
	//    creatingDictionaryForDataObjects<T>(dataObjects: ObjectPropertyValues[], funcForKey(dataObject):T ):Map<T,ObjectPropertyValues>
	static createDictionaryForCollateral(dataObjects?: ObjectPropertyValues[]): Map<number, ObjectPropertyValues> {
		const flattenedDataObjects = new Map<number, ObjectPropertyValues>();
		dataObjects?.forEach(dataObject => {
			if (dataObject.id == null) {
				return;
			}

			// NOTE: this is safe for both existing and proposed collateral since they are both in the same table
			flattenedDataObjects.set(dataObject.id, dataObject);
		});

		return flattenedDataObjects;
	}

	static createDictionaryForFinancialSubaccounts(dataObjects?: ObjectPropertyValues[]): Map<number, ObjectPropertyValues> {
		const flattenedDataObjects = new Map<number, ObjectPropertyValues>();
		dataObjects?.forEach(dataObject => {
			if (dataObject.id == null) {
				return;
			}

			flattenedDataObjects.set(dataObject.id, dataObject);
		});

		return flattenedDataObjects;
	}

	static customerTypeToDataObjectType(customerType: Customer.TypeEnum | null) {
		switch (customerType) {
			case Customer.TypeEnum.Business:
				return DataObject.TypeEnum.Business;
			case Customer.TypeEnum.Person:
				return DataObject.TypeEnum.Person;
			case Customer.TypeEnum.NonProfit:
				return DataObject.TypeEnum.NonProfit;
			case Customer.TypeEnum.Farm:
				return DataObject.TypeEnum.Farm;
			default:
				return undefined;
		}
	}

	static dataObjectTypeToCustomerType(dataObjectType: DataObject.TypeEnum | null) {
		switch (dataObjectType) {
			case DataObject.TypeEnum.Business:
				return Customer.TypeEnum.Business;
			case DataObject.TypeEnum.Person:
				return Customer.TypeEnum.Person;
			case DataObject.TypeEnum.NonProfit:
				return Customer.TypeEnum.NonProfit;
			case DataObject.TypeEnum.Farm:
				return Customer.TypeEnum.Farm;
			default:
				return undefined;
		}
	}
}
