
import { Component, Vue } from 'vue-property-decorator';
import { Environment } from '@sageworks/core-logic/src/enums/environment';

@Component({})
export default class NotFound extends Vue {
	public displayDemoAccountMessage: boolean = false;

	private mounted(): void {
		this.displayDemoAccountMessage = window.app.environment !== Environment.Production;
	}
}
