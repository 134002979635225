import { CustomComponentType, DataHandler, DataHandlerFactory } from '@sageworks/dynamic-forms';
import { StoreType } from '../../../store';
import { BeneficialOwnerDataHandler } from './beneficial-owner-data-handler';
import { RelatedRoleDataHandler } from './related-role-data-handler';
import { LoanRoleDataHandler } from './loan-role-data-handler';
import { DataObjectDataHandler } from './data-object-data-handler';
import { CollateralDataHandler } from './collateral-data-handler';
import { FinancialSubaccountDataHandler } from './financial-subaccount-data-handler/financial-subaccount-data-handler';
import { OtherLiabilitiesDataHandler } from './other-liabilities-data-handler';
import { CashAccountsDataHandler } from './cash-accounts-data-handler';
import { RealEstateFinancialsDataHandler } from './real-estate-financials-data-handler';
import { LifeInsuranceDataHandler } from './life-insurance-data-handler/life-insurance-data-handler';
import { BusinessInvestmentsDataHandler } from './business-investments-data-handler';
import { RetirementAccountsDataHandler } from './retirement-accounts-data-handler/retirement-accounts-data-handler';

export class MultiLoanDataHandlerFactory implements DataHandlerFactory {
	constructor(private store: StoreType) {}

	// eslint-disable-next-line max-lines-per-function
	createHandler(componentType: CustomComponentType): DataHandler {
		switch (componentType) {
			case CustomComponentType.beneficialOwnership:
				return new BeneficialOwnerDataHandler(this.store);

			case CustomComponentType.authorizedSigner:
				return new RelatedRoleDataHandler(this.store, componentType);

			case CustomComponentType.primaryBorrowerEntity:
			case CustomComponentType.primaryBorrowerEntityVue:
			case CustomComponentType.coBorrowerEntity:
			case CustomComponentType.coBorrowerEntityVue:
			case CustomComponentType.creditApplicantEntity:
			case CustomComponentType.creditApplicantEntityVue:
			case CustomComponentType.guarantorEntity:
			case CustomComponentType.guarantorEntityVue:
			case CustomComponentType.coSignerEntity:
			case CustomComponentType.coSignerEntityVue:
				return new LoanRoleDataHandler(this.store, componentType);

			case CustomComponentType.income:
			case CustomComponentType.expense:
			case CustomComponentType.stocksBondsBusinessHoldings:
			case CustomComponentType.nonInvestmentRealEstate:
			case CustomComponentType.otherAssets:
				return new FinancialSubaccountDataHandler(this.store, componentType);

			case CustomComponentType.businessInvestment:
				return new BusinessInvestmentsDataHandler(this.store);

			case CustomComponentType.investmentRealEstate:
				return new RealEstateFinancialsDataHandler(this.store);

			case CustomComponentType.otherLiabilities:
				return new OtherLiabilitiesDataHandler(this.store, componentType);

			case CustomComponentType.cashAccount:
				return new CashAccountsDataHandler(this.store, componentType);

			case CustomComponentType.lifeInsurance:
				return new LifeInsuranceDataHandler(this.store);

			case CustomComponentType.retirementAccount:
				return new RetirementAccountsDataHandler(this.store);

			case CustomComponentType.personalInfo:
			case CustomComponentType.businessInfo:
			case CustomComponentType.nonprofitInfo:
			case CustomComponentType.farmInfo:
			case CustomComponentType.loans:
				return new DataObjectDataHandler(this.store, componentType);
			case CustomComponentType.collateralInfo:
				return new CollateralDataHandler(this.store, componentType);

			default:
				throw new Error(`Data Handler does not exist for component type (${componentType}`);
		}
	}
}
