/* eslint-disable no-redeclare */
import { CustomComponentType, CustomComponentLabel } from '../enums/custom-component-type';
import { ApplicationTemplate } from '@sageworks/jpi';
import {useComponentManager} from '../components/component-manager';
import BaseRepeaterWidget from '../components/form-components/base/base-repeater-widget/base-repeater-widget';

export const createFormPath = (applicationTemplate: ApplicationTemplate) => {
	if (!applicationTemplate?.id || !applicationTemplate?.type) {
		return '';
	}

	return `application/${applicationTemplate.type}/${applicationTemplate?.id}`;
};

export function getWidgetType(formPath: string): CustomComponentType | null {
	const splitPath = slicePath(formPath);

	if (splitPath.length < 4) {
		return null;
	} else {
		return splitPath[splitPath.length - 2] as CustomComponentType;
	}
}

export function getWidgetLabel(formPath: string): string {
	if (formPath == null) {
		return '';
	}

	const widgetType = getWidgetType(formPath);
	if (widgetType == null) {
		return '';
	}

	return CustomComponentLabel()[widgetType];
}

export function getWidgetLabels(formPath: string) {
	if (formPath == null) {
		return [];
	}

	const componentLabels = CustomComponentLabel();
	return getWidgetNames(formPath).map(x => componentLabels[x]);
}

export function isInWidget(formPath: string, widgetType: CustomComponentType): boolean;
export function isInWidget(formPath: string, widgetTypeList: CustomComponentType[]): boolean;
export function isInWidget(formPath: string, widgetTypeOrList: CustomComponentType | CustomComponentType[]): boolean {
	const splitPath = slicePath(formPath);
	const widgetTypeList = widgetTypeOrList instanceof Array ? widgetTypeOrList : [widgetTypeOrList];

	for (let index = splitPath.length - 1; index >= 0; index--) {
		const currentWidgetType = splitPath[index] as CustomComponentType;

		if (widgetTypeList.includes(currentWidgetType)) {
			return true;
		}
	}

	return false;
}

export function getAppType(formPath: string): ApplicationTemplate.TypeEnum {
	const splitPath = slicePath(formPath);
	return splitPath[1] as ApplicationTemplate.TypeEnum;
}

export function getApplicationTemplateId(formPath: string): number {
	const splitPath = formPath.slice(1).split('/');
	return parseInt(splitPath[2]) ?? -1;
}

export function getAllFormUrls(formPath: string): string[] {
	if (formPath == null || formPath === '') {
		return [];
	}

	let paths: string[] = [];
	const splitPath = formPath.split('/');

	let builtString = '';
	splitPath.forEach(pathComponent => {
		builtString += pathComponent;

		if (isFinite(+pathComponent)) {
			paths.push(builtString);
		}

		builtString += '/';
	});

	return paths;
}

export function getBaseFormUrl(formPath: string): string {
	if (formPath == null || formPath === '') {
		return '';
	}

	const splitPath = formPath.split('/');

	let builtPath = '';
	for (let i = 0; i < splitPath.length; i++) {
		builtPath += splitPath[i];

		if (isFinite(+splitPath[i])) {
			return builtPath;
		}

		builtPath += '/';
	}

	return builtPath;
}

export function getTopLevelWidget(formPath: string): CustomComponentType | null {
	const widgets = getWidgetNames(formPath);
	if (widgets.length === 0) {
		return null;
	}

	return widgets[0] as CustomComponentType;
}

export function getWidgetNames(formPath: string): CustomComponentType[] {
	if (formPath == null || formPath === '') {
		return [];
	}

	const splitPath = formPath.split('/');
	let widgets = [];
	for (let i = 0; i < splitPath.length; i++) {
		if (Object.values(CustomComponentType).includes(splitPath[i] as CustomComponentType)) {
			widgets.push(splitPath[i] as CustomComponentType);
		}
	}
	return widgets;
}

export function isRepeaterWidget(widget: CustomComponentType): boolean {
	if (widget == null) return false;
	const { componentManager } = useComponentManager();
	const componentConstructor = componentManager.CustomComponents[widget];
	if (componentConstructor == null) {
		return false;
	}

	return componentConstructor.prototype instanceof BaseRepeaterWidget;
}

function slicePath(formPath: string): Array<string> {
	return formPath.slice(1, -1).split('/');
}
