import editForm from './proposed-product-select.form';
import { CustomComponentType } from '../../../enums/custom-component-type';
import { Utils as FormioUtils } from 'formiojs';
import SelectComponent from 'formiojs/components/select/Select';
import { ProposedProduct, AutoFactory, ProposedProductsService, CollectionResponseProposedProduct } from '@sageworks/jpi';
import { getContextDataValue } from '../../../utils/context-data-helper/context-data-helper';
import { ContextDataProperty } from '../../../enums';

export default class ProposedProductSelect extends SelectComponent {
	validProposedProductIds: number[] | undefined;
	proposedProductsPromise: Promise<any[]> | undefined;

	static schema(...extend: any) {
		return SelectComponent.schema(
			{
				label: 'Proposed Product',
				type: CustomComponentType.proposedProductSelect,
				key: CustomComponentType.proposedProductSelect,
				dataSrc: 'values',
				template: '<span>{{ item.label }}</span>',
				lazyLoad: false,
				searchEnabled: false,
				appType: null
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: 'Proposed Product',
			weight: 10,
			schema: ProposedProductSelect.schema()
		};
	}

	get isLender() {
		return getContextDataValue(this, ContextDataProperty.IsLender);
	}

	// @ts-ignore
	get key() {
		if (this.builderMode || this.options.attachMode === 'builder') {
			return super.key;
		}

		// check if editing this components properties in an editForm
		if (this.root?.element != null && this.root.element.getAttribute('ref') === 'editForm') {
			return super.key;
		}

		return `${this.component.type}__${this.component.appType}`;
	}

	appTypeSelectKey: string | null = null;

	attach(element: HTMLElement) {
		if (element.id != null && document.getElementById(element.id) != null) {
			element = document.getElementById(element.id)!;
		}

		return super.attach(element);
	}

	init() {
		super.init();

		this.component.onSetItems = this.removeInvalidProductOptions;
	}

	/**
	 * Check if this component is conditionally visible.
	 *
	 * @param data
	 * @return {boolean}
	 */
	conditionallyVisible(data: any) {
		if (this.builderMode) {
			return true;
		}

		if (data == null) {
			return false;
		}

		if (this.component.lenderOnly && !this.isLender) {
			return false;
		}

		if (this.appTypeSelectKey == null || this.appTypeSelectKey.length === 0) {
			// it's not clear what the type for root should be
			const rootComponents = (this.root as any)?.components || [];
			const appTypeSelect = FormioUtils.searchComponents(rootComponents, { type: CustomComponentType.appTypeSelect })[0];

			if (appTypeSelect == null) {
				return false;
			}

			this.appTypeSelectKey = appTypeSelect.key;
		}

		return data[this.appTypeSelectKey as string] === (this as any).schema.appType;
	}

	setItems(items: any, fromSearch: boolean): void {
		let massagedItems = items;
		const itemsIsNonNullArray: boolean = items && Array.isArray(items);
		if (itemsIsNonNullArray) {
			massagedItems = items.filter((option: any) => option.enabledForConsumers);
		}
		super.setItems(massagedItems, fromSearch);

		if ((this.selectOptions as any).length === 0 && this.validProposedProductIds && this.validProposedProductIds.length > 0) {
			this.triggerUpdate();
		}
	}

	removeInvalidProductOptions(component: any, items: any[]): any[] {
		if (items == null || !Array.isArray(items)) {
			return [];
		}

		if (component.validProposedProductIds != null) {
			return items.filter(item => {
				return component.validProposedProductIds.includes(item.value);
			});
		}

		const appType: ProposedProduct.ApplicationTypesEnum = component.component.appType;
		const proposedProductsService = AutoFactory.get(ProposedProductsService);

		component.proposedProductsPromise = proposedProductsService.getPaged(1, 100, appType).then((response: CollectionResponseProposedProduct) => {
			const proposedProducts: ProposedProduct[] = response.items || [];
			component.validProposedProductIds = proposedProducts
				.filter((product: ProposedProduct) => {
					return product.applicationTemplates?.some(t => t.active);
				})
				.map((product: ProposedProduct) => product.id);

			const validItems = items.filter(item => {
				return component.validProposedProductIds.includes(item.value);
			});
			component.setItems(validItems, false);
		});

		return [];
	}
}
