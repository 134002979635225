module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="text-center">\n	<h5>' +
((__t = ( ctx.typeTitle )) == null ? '' : __t) +
'</h5>\n</div>\n<div class="d-flex align-items-center flex-column">\n	';
 for(var i=0; i < ctx.entityTypes.length; i++) { ;
__p += '\n	<div class="p-2">\n		<button id="' +
((__t = ( ctx.entityTypes[i].key )) == null ? '' : __t) +
'" class="btn btn-primary" ref="addNewEntityType" data-test-id="' +
((__t = ( ctx.entityTypes[i].key )) == null ? '' : __t) +
'">\n			' +
((__t = ( ctx.entityTypes[i].label )) == null ? '' : __t) +
'\n		</button>\n	</div>\n	';
 } ;
__p += '\n</div>\n';
return __p
}