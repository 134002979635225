
import BaseFormMixin from '../mixins/base-form-mixin';
import FormioFormWrapper from './formio-form-wrapper/formio-form-wrapper.vue';

// using this syntax for the mixin as it keeps the linter/typescript happy and allows base-mixin methods/props to be seen properly
export default BaseFormMixin.extend({
	name: 'UnauthenticatedForm',
	components: {
		FormioFormWrapper
	},
	watch: {
		form: {
			handler: function() {
				this.$emit('form-change', { form: this.form, options: this.options });
			},
			immediate: true
		}
	},
	mounted() {
		this.initWithoutAuthentication();
	}
});
