import { FormioComponentSchema, FormioValidationSchema } from './FormioComponentSchema';
import { FormioRolePermissionSchema } from './FormioRolePermissionSchema';

// SOURCE: https://github.com/formio/formio.js/wiki/Form-JSON-Schema

export enum FormioFormType {
	form = 'form',
	resource = 'resource'
}

export enum FormioFormDisplayType {
	form = 'form',
	wizard = 'wizard',
	pdf = 'pdf',
	nestedWizard = 'nested-wizard'
}

export interface FormioFormSchema {
	title?: string;
	name?: string;
	path?: string;
	type?: FormioFormType;
	display?: FormioFormDisplayType;
	components?: FormioComponentSchema<any, FormioValidationSchema>[];
	_id?: string;
	_vid?: string;
	modified?: Date;
	created?: Date;
	action?: URL;
	tags?: string[];
	template?: string;

	// matches (Project ID):(Form Name)
	machineName?: string;

	project?: string;
	owner?: string;
	access?: FormioRolePermissionSchema[];
	submissionAccess?: FormioRolePermissionSchema[];
	parent?: FormioFormSchema;
}
