
import { Component, Mixins } from 'vue-property-decorator';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { ScaleMixin } from '@/mixins';
import { transformLoanApplicationsToCards } from '@/logic/loan-application.service';
import { LoanApplicationCard } from '../../../models';
import { OnlinePortalRoutes } from '../../../OnlinePortalRoutes';
import StartNewApplicationModal from '../../../components/start-new-application-modal/start-new-application-modal.vue';
import { UsableProducts, FeatureFlagging } from '@sageworks/jpi/';

@Component({
	components: {
		FontAwesomeIcon,
		StartNewApplicationModal
	}
})
export default class Applications extends Mixins(ScaleMixin) {
	public tabIndex = 0;
	public filters = [
		{
			text: 'Current',
			value: 'current',
			message: `No ${ this.itemLabel.toLowerCase() }s currently in progress. ${this.canStartNewApplications ? 'Click above to start one!' : ''}`
		},
		{	text: 'Completed',
			value: 'completed',
			message: `No ${this.itemLabel.toLowerCase()} completed. ${this.canStartNewApplications ? 'Click above to start one!' : ''}` }
	];


	get currentPageApplicationCards() {
		return (filterTag: string): LoanApplicationCard[] => {
			return transformLoanApplicationsToCards(this.directStore.state.Application.loanApplications || [],
				this.requestOrAppLabel,
				this.$router).filter(card => card.filterTag.some(tag => tag === filterTag)
			);
		};
	}

	get canStartNewApplications() {
		return !this.directStore.getters.User.isLender && this.directStore.state.InstitutionSettings.allowBorrowersToStartApplications?.settingValue;
	}


	get itemLabel(): string {
		if (this.hasCommunityLendingSubscription === undefined) {
			return '';
		}
		return this.hasCommunityLendingSubscription ? 'Request Form': 'Application';
	}
	get requestOrAppLabel(): string {
		if (this.hasCommunityLendingSubscription === undefined) {
			return '';
		}
		return this.hasCommunityLendingSubscription ? 'Request Form' : 'Loan Application';
	}
	get hasCommunityLendingSubscription(): boolean | undefined {
		return this.directStore.state.UsableProducts.usableProducts?.has('abrigoCommunityLending' as UsableProducts.ProductsEnum);
	}
	async  startNewApplication() {		
		if (await this.directStore.dispatch.FeatureFlag.fetchIsFeatureFlagActive(FeatureFlagging.FeatureFlagEnum.EnablePrelimAccountOpening)) {			
			this.$refs['start-new-application-modal'].$refs['options-modal'].show();
		}
		else {
			this.requestLoan();
		}
	}

	public requestLoan() {
		if (this.directStore.getters.ApplicationTemplates.preAppTemplateId) {
			this.$router.push({ name: OnlinePortalRoutes.PreApplication.name });
		} else {
			this.showToast(`Start ${this.itemLabel}`, `Unable to start new ${this.itemLabel}`);
		}
	}

	private showToast(title: string, message: string) {
		this.$root.$bvToast.toast(message, {
			title: title,
			solid: true,
			autoHideDelay: 3000,
			toaster: 'b-toaster-bottom-right'
		});
	}
}
