import editForm from './authorized-signer.form';
import { RelatedRoleRepeaterWidget } from '../related-role-repeater-widget/related-role-repeater-widget';
import { CustomComponentType, LoanRoleType, EditRowState, ContextDataProperty } from '../../../enums';
import { generateFormJson } from './authorized-signer-form-helper';
import AddNewCustomer from '../add-customer/add-new-customer/add-new-customer';
import { FormioComponentSchema, FormioValidationSchema } from '../../../formio-interfaces';
import { getContextDataValue } from '../../../utils/context-data-helper/context-data-helper';

export default class AuthorizedSignerWidget extends RelatedRoleRepeaterWidget {
	static schema(...extend: any) {
		return RelatedRoleRepeaterWidget.schema(
			{
				label: 'Authorized Signer',
				type: CustomComponentType.authorizedSigner,
				key: CustomComponentType.authorizedSigner,
				addAnother: 'Add an Authorized Signer'
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: 'Authorized Signer',
			group: '',
			weight: 10,
			schema: AuthorizedSignerWidget.schema(),
			preview: false
		};
	}

	get componentComponents(): FormioComponentSchema<any, FormioValidationSchema>[] {
		if (this.options.readOnly && this.allowedDataFields.length > 0) {
			return [AddNewCustomer.schema(true, this.allowedDataFields, this.applicationType)];
		}

		return super.componentComponents;
	}

	init() {
		this.roleType = LoanRoleType.AuthorizedSigner;
		super.init();
	}

	async editRow(rowIndex: number): Promise<any> {
		this.component.modal = true;
		return super.editRow(rowIndex);
	}

	addRowModal(rowIndex: number) {
		const editRow = this.editRows[rowIndex];

		if (editRow.state === EditRowState.Editing) {
			this.showAddCustomerPopup(rowIndex, this.roleType, true);
			this.component.modal = false;
		} else {
			super.addRowModal(rowIndex);
		}
	}

	protected initDataLoader() {
		this.dataLoader.isSuccessfulPromise.then(this.onDataLoaded.bind(this));

		super.initDataLoader();
	}

	private onDataLoaded() {
		this.component.formJson = generateFormJson();
		this.component.components = this.componentComponents;
		this.resetRowComponents();
	}

	private get applicationType() {
		return getContextDataValue(this, ContextDataProperty.LoanApplicationType);
	}
}
