
import Vue, { PropType } from 'vue';
import { ApplicationValidationStage } from '@/enums';
import ApplicationValidationStep from './application-validation-modal-step.vue';
import { ApplicationSubmitValidationResults } from '@/utils/application-validation';
import { UsableProducts } from '@sageworks/jpi';
import { mapGetters } from 'vuex';
import { isInFinalStatus as isApplicationInFinalStatus } from '@/utils/application-final-status-helper/application-final-status-helper';
import { LoanApplication } from '@sageworks/jpi';

export default Vue.extend({
	name: 'ApplicationValidationSteps',
	components: {
		ApplicationValidationStep
	},
	props: {
		isSubmitting: Boolean,
		isSendingESignEmails: Boolean,
		isLender: Boolean,
		submissionSuccessful: Boolean,
		eSignEmailSendingSucceeded: Boolean,
		customerCanUseEsign: Boolean,
		currentValidationStage: Number as PropType<ApplicationValidationStage>,
		validationResults: Object as PropType<ApplicationSubmitValidationResults>,
		isBypassingSignatures: Boolean,
		isBypassingReview: Boolean,
		applicationRequestLabel: String
	},
	data() {
		return {
			ApplicationValidationStage: ApplicationValidationStage
		};
	},
	computed: {
		...mapGetters('LoanApplicationMetadata', ['loanRequiresReview']),
		hasLosLiteSubscription(): boolean | undefined {
			return this.directStore.state.UsableProducts.usableProducts?.has('abrigoCommunityLending' as UsableProducts.ProductsEnum);
		},
		applicationStatus(): LoanApplication.ApplicationStatusEnum | null {
			return this.directStore.state.LoanApplicationData.loanApplication?.applicationStatus ?? null;
		},
		isInFinalStatus(): boolean {
			return isApplicationInFinalStatus(this.applicationStatus);
		}
	}
});
