module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }


	var hasMultipleTabs = ctx.component.components.filter(function(tab) { return !tab.hidden; }).length > 1;
;
__p += '\n\n<div class="card">\n	<div class="card-header pl-0 border-bottom-0">\n		<ul class="nav nav-tabs card-header-tabs mx-0">\n			';
 ctx.component.components.forEach(function(tab, index) { ;
__p += '\n			<li class="nav-item mb-0 mr-1 mt-1' +
((__t = ( ctx.currentTab === index ? ' active' : '' )) == null ? '' : __t) +
' ' +
((__t = ( tab.hidden || !hasMultipleTabs ? 'd-none' : '' )) == null ? '' : __t) +
'" role="presentation" ref="' +
((__t = ( ctx.tabLikey )) == null ? '' : __t) +
'">\n				<a class="nav-link border-0 text-body bg-gray-200' +
((__t = ( ctx.currentTab === index ? ' active' : '' )) == null ? '' : __t) +
'" href="#' +
((__t = ( tab.key )) == null ? '' : __t) +
'" ref="' +
((__t = ( ctx.tabLinkKey )) == null ? '' : __t) +
'">' +
((__t = ( ctx.t(tab.label) )) == null ? '' : __t) +
'</a>\n			</li>\n			';
 }) ;
__p += '\n		</ul>\n	</div>\n	';
 ctx.component.components.forEach(function(tab, index) { ;
__p += '\n	<div\n		role="tabpanel"\n		class="card-body tab-pane' +
((__t = ( ctx.currentTab === index ? ' active' : '' )) == null ? '' : __t) +
'"\n		style="display: ' +
((__t = ( ctx.currentTab === index && !tab.hidden ? 'block' : 'none' )) == null ? '' : __t) +
'"\n		ref="' +
((__t = ( ctx.tabKey )) == null ? '' : __t) +
'"\n	>\n		' +
((__t = ( ctx.tabComponents[index] )) == null ? '' : __t) +
'\n	</div>\n	';
 }) ;
__p += '\n</div>\n';
return __p
}