import { LoanApplicationStartRequest, ApplicationTemplate } from '@sageworks/jpi';
import { CustomComponentType, TemplateDataField } from '../enums';
import { FormioFormSchema } from '../formio-interfaces';
import { Utils as FormioUtils } from 'formiojs';

export interface ApplicationStartDto {
	applicationType: ApplicationTemplate.TypeEnum | null;
	proposedProductsId: number | null;
	appStartRequest: LoanApplicationStartRequest;
}

function _getAppType(data: any): ApplicationTemplate.TypeEnum | null {
	return data[`${CustomComponentType.appTypeSelect}`];
}

function _getProposedProductId(appType: ApplicationTemplate.TypeEnum, data: any): number {
	return data[`${CustomComponentType.proposedProductSelect}__${appType}`];
}

function _getLoanAmountKey(form: FormioFormSchema): string | null {
	if (form.components == null) {
		return null;
	}

	const productSelectionForm = FormioUtils.searchComponents(form.components, CustomComponentType.productSelection).shift();
	if (productSelectionForm == null || productSelectionForm.subForm == null) {
		return null;
	}

	const loanAmountComponent = FormioUtils.searchComponents(productSelectionForm.subForm.components, {
		['component.templateDataFieldId']: TemplateDataField.ProposedLoanLoanAmount
	}).shift();

	return loanAmountComponent?.key;
}

export function buildPreAppSelections(form: FormioFormSchema, formData: any): ApplicationStartDto {
	var preApp: ApplicationStartDto = {
		applicationType: null,
		proposedProductsId: null,
		appStartRequest: {
			loanAmount: undefined
		}
	};

	if (formData && formData[CustomComponentType.productSelection]?.data) {
		var data = formData[CustomComponentType.productSelection].data;

		preApp.applicationType = _getAppType(data);

		const loanAmountKey = _getLoanAmountKey(form);
		if (loanAmountKey != null) {
			preApp.appStartRequest.loanAmount = data[loanAmountKey];
		}

		if (preApp.applicationType == null) {
			return preApp;
		}

		const appType: ApplicationTemplate.TypeEnum = preApp.applicationType!;
		preApp.proposedProductsId = _getProposedProductId(appType, data);
	}

	return preApp;
}
