import BaseWidget from '../base/base-widget/base-widget';
import editForm from './product-selection.form';
import { CustomComponentType } from '../../../enums/custom-component-type';
import { CustomBuilderGroups } from '../../../enums/custom-builder-groups';

export default class ProductSelectionWidget extends BaseWidget {
	static schema(...extend: any) {
		return BaseWidget.schema(
			{
				label: 'Product Selection',
				type: CustomComponentType.productSelection,
				key: CustomComponentType.productSelection
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: 'Product Selection',
			group: CustomBuilderGroups.preAppWidgets,
			weight: 10,
			schema: ProductSelectionWidget.schema()
		};
	}
}
