import Component from 'formiojs/components/_classes/component/Component';
import { getContextDataValue } from '../context-data-helper/context-data-helper';
import { ContextDataProperty } from '../../enums';
import { transformConditionalitySchema } from '../conditionality';
import { OpnRenderType } from '../../enums/opn-render-type';

export function isRootLevelFormPath(path: any) {
	var safePath = '' + (path ?? '');
	var pathSegments = safePath.split('/');
	return pathSegments.length === 3 && pathSegments[0] === 'application';
}

export function isInPreviewMode(component: Component) {
	return component?.options?.preview
		|| (component?.root?.element != null && component.root.element.getAttribute('ref') === 'preview')
		// Note: Since all select field urls use this method to choose if we append "/online-portal" or not. We needed
		// 	to add a flag to get around it since, in the form builder, the select fields in the configuration popup for
		// 	a component are not in the preview mode but we need it to be true to avoid appended the added path. A usage
		// 	of this	can be found in extended-select-field.form.data.ts
		|| component?.component?.forcePreview;
}

export const updateComponentClassWithOverrides = () => {
	const originalMergeSchema = Component.prototype.mergeSchema;

	// We are updating this method so we can transform the conditionality schema for all components in formio
	Component.prototype.mergeSchema = function (component: any) {
		const updatedComponent = originalMergeSchema.call(this, component);

		const propertiesByType = getContextDataValue(this, ContextDataProperty.PropertiesByType);
		const renderMode = getContextDataValue(this, ContextDataProperty.RenderMode);

		if (renderMode === OpnRenderType.Form) {
			updatedComponent.conditional = transformConditionalitySchema(updatedComponent.conditional, propertiesByType);
		}

		return updatedComponent;
	};
};