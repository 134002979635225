import { CustomComponentType, FinancialStatementType, FinancialSubaccountParent, FinancialSubaccountUtils, RetirementAccountCustomFieldKey, SubmitPayload } from '@sageworks/dynamic-forms';
import { formatDataFieldIdKey } from '@sageworks/dynamic-forms/src/components/form-components/simple-inputs/extended-field-helper';
import { ObjectPropertyValues } from '@sageworks/jpi';
import { StoreType } from '../../../../store';
import { FinancialSubaccountDataHandler } from '../financial-subaccount-data-handler/financial-subaccount-data-handler';

export class RetirementAccountsDataHandler extends FinancialSubaccountDataHandler {

	constructor(store: StoreType) {
		super(store, CustomComponentType.lifeInsurance);
	}

	public async submit(payload: SubmitPayload): Promise<any> {
		if (this.missingValues(payload)) {
			return;
		}

		// Grab the custom fields' values so we can format the subaccount's name and parent type correctly
		const name = payload.submission.data[RetirementAccountCustomFieldKey.NameOfInstitution];
		const retirementType = payload.submission.data[RetirementAccountCustomFieldKey.RetirementAccountType];

		const nameFieldKey = formatDataFieldIdKey(this.nameDataFieldId.toString());
		const parentFieldKey = formatDataFieldIdKey(this.parentDataFieldId.toString());

		payload.submission.data[nameFieldKey] = FinancialSubaccountUtils.createSubaccountName(name, retirementType);
		payload.submission.data[parentFieldKey] = FinancialSubaccountParent.Retirement;

		return await super.submit(payload);
	}

	protected createFormObject(submitPayload: SubmitPayload, dataFieldValues: ObjectPropertyValues) {
		const updatedFormObject = super.createFormObject(submitPayload, dataFieldValues);

		// These values might change since when we re-create the form object it uses the financial subaccounts to set the values which
		// 	might be stale since only the data object is updated, so we update them here to ensure they remain the same
		updatedFormObject.data[RetirementAccountCustomFieldKey.NameOfInstitution] = submitPayload.submission
			.data[RetirementAccountCustomFieldKey.NameOfInstitution];
		updatedFormObject.data[RetirementAccountCustomFieldKey.RetirementAccountType] = submitPayload.submission
			.data[RetirementAccountCustomFieldKey.RetirementAccountType];

		return updatedFormObject;
	}

	protected getFinancialStatementName(_widgetType: CustomComponentType) {
		return FinancialStatementType.CurrentAssets;
	}
}