import CalendarWidget from 'formiojs/widgets/CalendarWidget';
import { Utils as FormioUtils } from 'formiojs';
import { Formio } from 'formiojs';
import InputWidget from 'formiojs/widgets/InputWidget';

const ISO_8601_FORMAT = 'yyyy-MM-ddTHH:mm:ssZ';
const CDN_URL = 'https://cdn.form.io/';

export default class ExtendedCalendarWidget extends CalendarWidget {
	// @ts-ignore
	get flatpickrType() {
		return 'flatpickr';
	}

	async attach(input: HTMLElement) {
		const superAttach = InputWidget.prototype.attach.apply(this, [input]);
		this.setPlaceholder(input);

		const dateFormatInfo = FormioUtils.getLocaleDateFormatInfo(this.settings.language);
		this.defaultFormat = {
			date: dateFormatInfo.dayFirst ? 'd/m/Y ' : 'm/d/Y ',
			time: 'G:i K'
		};

		this.closedOn = 0;
		this.valueFormat = this.settings.dateFormat || ISO_8601_FORMAT;

		this.valueMomentFormat = FormioUtils.convertFormatToMoment(this.valueFormat);
		this._initSettings();

		Formio.requireLibrary('flatpickr-css', 'flatpickr-css', [{ type: 'styles', src: `${CDN_URL}${this.flatpickrType}/flatpickr.min.css` }], true);

		await superAttach;

		const flatpickr = await Formio.requireLibrary('flatpickr', 'flatpickr', `${CDN_URL}${this.flatpickrType}/flatpickr.min.js`, true);

		this.settings.formatDate = this._formatDateFactory(flatpickr);

		if (this._input) {
			this._configureCalendar(flatpickr);

			if (!this.settings.readOnly) {
				// Enforce the input mask of the format.
				this.setInputMask(this.calendar._input, FormioUtils.convertFormatToMask(this.settings.format));
			}
		}
	}

	private _initSettings() {
		this.settings.minDate = FormioUtils.getDateSetting(this.settings.minDate);
		this.settings.disable = [
			...this.disabledDates,
			...(this.settings.disableWeekends ? [this.disableWeekends] : []),
			...(this.settings.disableWeekdays ? [this.disableWeekdays] : []),
			...(this.settings.disableFunction ? [this.disableFunction] : [])
		];
		this.settings.maxDate = FormioUtils.getDateSetting(this.settings.maxDate);
		this.settings.wasDefaultValueChanged = false;
		this.settings.defaultValue = '';
		this.settings.manualInputValue = '';
		this.settings.isManuallyOverriddenValue = false;
		this.settings.altFormat = FormioUtils.convertFormatToFlatpickr(this.settings.format);
		this.settings.dateFormat = FormioUtils.convertFormatToFlatpickr(this.settings.dateFormat);
		this.settings.onChange = () => this._onChange();
		this.settings.onOpen = () => this.hook('onCalendarOpen');
		this.settings.onClose = () => this._onClose();
	}

	private _onChange() {
		if (this.settings.allowInput) {
			if (this.settings.isManuallyOverriddenValue && this.settings.enableTime) {
				this.calendar._input.value = this.settings.manualInputValue;
			} else {
				this.settings.manualInputValue = '';
			}

			this.settings.isManuallyOverriddenValue = false;
		}

		this.emit('update');
	}

	private _onClose() {
		this.hook('onCalendarClose');
		this.closedOn = Date.now();

		if (this.settings.allowInput && this.settings.enableTime) {
			this.calendar._input.value = this.settings.manualInputValue || this.calendar._input.value;
			this.settings.isManuallyOverriddenValue = false;
		}

		if (this.settings.wasDefaultValueChanged) {
			this.calendar._input.value = this.settings.defaultValue;
			this.settings.wasDefaultValueChanged = false;
		}
		if (this.calendar) {
			this.emit('blur');
		}
	}

	private _formatDateFactory(flatpickr: any) {
		return (date: any, format: string) => {
			// Only format this if this is the altFormat and the form is readOnly.
			if (this.settings.readOnly && format === this.settings.altFormat) {
				if (this.settings.saveAs === 'text' || !this.settings.enableTime || this.loadZones()) {
					return flatpickr.formatDate(date, format);
				}

				return FormioUtils.formatOffset(flatpickr.formatDate.bind(flatpickr), date, format, this.timezone);
			}

			return flatpickr.formatDate(date, format);
		};
	}

	private _configureCalendar(flatpickr: any) {
		const dateValue = this._input.value;

		this.calendar = new flatpickr(this._input as any, this.settings);

		if (dateValue && dateValue.toLowerCase() !== 'invalid date') {
			this.calendar.setDate(dateValue, false, this.settings.altFormat);
		}

		this.calendar.altInput.addEventListener('input', (event: any) => {
			if (this.settings.allowInput) {
				this.settings.manualInputValue = event.target.value;
				this.settings.isManuallyOverriddenValue = true;
			}

			if (event.target.value === '' && this.calendar.selectedDates.length > 0) {
				this.settings.wasDefaultValueChanged = true;
				this.settings.defaultValue = event.target.value;
				this.calendar.clear();
			} else {
				this.settings.wasDefaultValueChanged = false;
			}
		});
	}

	addSuffix(suffix: HTMLElement) {
		suffix.classList.add('cursor-pointer');

		this.addEventListener(suffix, 'click', (event: Event) => {
			event.stopPropagation();

			if (this.calendar) {
				if (!this.calendar.isOpen && Date.now() - this.closedOn > 200) {
					this.calendar.open();
				} else if (this.calendar.isOpen) {
					this.calendar.close(event);
				}
			}
		});

		return suffix;
	}
}
