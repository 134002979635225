import store from '@/store';
import { LoanApplication } from '@sageworks/jpi';

export async function getApplicationTypeById(loanApplicationId: number): Promise<LoanApplication.TypeEnum | null> {
	let application: LoanApplication | undefined = store.getters.Application.applicationById(loanApplicationId);
	if (application) {
		return application.type || null;
	}
	await store.dispatch.Application.fetchApplicationById(loanApplicationId);
	return store.getters.Application.applicationById(loanApplicationId)?.type || null;
}
