import { UpdateDataType } from '@sageworks/dynamic-forms';
import { SbaInfoApiHelper } from '../data-helpers/SbaInfoApiHelper';
import { ProposedLoanSBAInfo } from '@sageworks/jpi';

export class DataUpdateHelper {
	public async updateData(updateDataType: UpdateDataType, context: any) {
		switch (updateDataType) {
			case UpdateDataType.SbaInfo:
				return await this.updateSbaInfo(context);
			default:
				return null;
		}
	}

	public async updateSbaInfo({ sbaInfo }: { sbaInfo: ProposedLoanSBAInfo }) {
		return await SbaInfoApiHelper.updateSbaInfo(sbaInfo);
	}
}
