
import Vue, { PropType } from 'vue';
import { ApplicationFormSignature } from '../application-form-signature';
import { LoanRoleType } from '@sageworks/dynamic-forms';
import { ComponentState } from '@/enums';
import { FormSignatureCustomerInfo } from '@sageworks/jpi';

export default Vue.extend({
	name: 'ApplicationPdfSignatureSection',
	components: {
		ApplicationFormSignature
	},
	props: {
		signatureDataList: { type: Array as PropType<Array<{ [loanRoleType: string]: Array<FormSignatureCustomerInfo> }>>, default: () => [] },
		componentState: { type: String as PropType<ComponentState>, default: null },
		showEsignLines: { type: Boolean, default: true }
	},
	data() {
		return {
			LoanRoleType
		};
	},
	computed: {
		exportingState() {
			return ComponentState.EXPORTING;
		},
		applicationTermsAndConditionsMessage() {
			return this.directStore.getters.LoanApplicationData.applicationTermsAndConditionsMessage ?? '';
		},
		formattedSignatureDataList() {
			const signatureDataList = this.signatureDataList as any[];
			const formattedDataList: any[] = [];

			signatureDataList.forEach(signatureData => {
				if (!signatureData) {
					return;
				}

				const formattedData: any = {};
				Object.keys(signatureData).forEach(loanRoleType => {
					formattedData[loanRoleType] = (signatureData[loanRoleType] as Array<FormSignatureCustomerInfo>).map(x => ({
						id: x.customerId,
						name: x.name
					}));
				});

				formattedDataList.push(formattedData);
			});

			return formattedDataList;
		}
	}
});
