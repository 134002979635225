import { DataField, AutoFactory, DataFieldsService, ApplicationTemplate } from '@sageworks/jpi';
import { FormioComponentSchema, FormioValidationSchema } from '../../formio-interfaces/FormioComponentSchema';
import { CustomComponentType } from '../../enums';
import Component from 'formiojs/components/_classes/component/Component';
import { isInPreviewMode } from '../formio-template-helpers/builder-template-helpers';
import { getDataFieldLabel } from './data-field-label-builder';
import { DataFieldComponentConverter } from './data-field-component-converter';
import {
	DefaultDataFieldConverter,
	BusinessInvestmentsDataFieldConverter,
	CollateralPledgedByDataFieldConverter,
	CollateralTypeDataFieldConverter,
	DropdownDataFieldConverter,
	FinancialSubaccountDataFieldConverter,
	HmdaLoanPurposeDataFieldConverter,
	IndustryCodeDataFieldConverter,
	InvestmentRealEstateDataFieldConverter,
	LoanCodesDataFieldConverter,
	MailingAddressDifferentFieldConverter,
	ProposedProductSelectDataFieldConverter,
	VehicleSelectDataFieldConverter
} from './data-field-component-converter/converter-strategies';

export interface ConvertDataFieldToComponentOptions {
	weight: number;
	forcedRequiredFields?: number[];
	parentComponentType?: CustomComponentType | null;
	allDataFields?: DataField[];
	applicationTemplate?: ApplicationTemplate;
}

export function convertDataFieldToComponent(dataField: DataField, options: ConvertDataFieldToComponentOptions) {
	const { weight, forcedRequiredFields = [], parentComponentType, allDataFields = [] } = options;

	// NOTE: Order matters in this list. Converter will use the first strategy it finds that accepts the data field
	const allowedConvertStrategies = [
		new VehicleSelectDataFieldConverter(allDataFields),
		new FinancialSubaccountDataFieldConverter(parentComponentType ?? undefined),
		new ProposedProductSelectDataFieldConverter(allDataFields, options.applicationTemplate?.id ?? -1),
		new InvestmentRealEstateDataFieldConverter(),
		new MailingAddressDifferentFieldConverter(),
		new BusinessInvestmentsDataFieldConverter(),
		new LoanCodesDataFieldConverter(),
		new HmdaLoanPurposeDataFieldConverter(),
		new CollateralPledgedByDataFieldConverter(),
		new CollateralTypeDataFieldConverter(),
		new IndustryCodeDataFieldConverter(parentComponentType ?? undefined),
		// Generic/base data field converters split from DefaultDataFieldConverter will go here
		new DropdownDataFieldConverter(),
		// TODO: Eventually break out DefaultDataFieldConverter into separate strategies to allow for easier testing and readability
		new DefaultDataFieldConverter()
	];
	const dataFieldConverter = new DataFieldComponentConverter(allowedConvertStrategies, { forcedRequiredFields });

	return {
		title: dataField.name,
		weight: weight,
		schema: dataFieldConverter.convert(dataField)
	} as FormioComponentSchema<any, FormioValidationSchema>;
}

const cachedDataFieldLabels: { [cache_key: string]: string } = {};

export function shouldUpdateOriginalLabel(component: Component) {
	return isInPreviewMode(component) && component.component.dataFieldId != null;
}

export async function updateOriginalLabel(component: Component) {
	const cacheKey = `${component.options.customComponentType ?? ''}_${component.component.dataFieldId}`;

	if (cacheKey in cachedDataFieldLabels) {
		component.component.originalLabel = cachedDataFieldLabels[cacheKey];
		return;
	}

	const dataFieldService = AutoFactory.get(DataFieldsService);
	const dataField = await dataFieldService.getById(component.component.dataFieldId);
	component.component.originalLabel = getDataFieldLabel(dataField, component.options.customComponentType);
	cachedDataFieldLabels[cacheKey] = component.component.originalLabel;
}
