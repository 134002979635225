
import { PropType } from 'vue';
import BaseTemplateMixin from './base-template-mixin.vue';

export default BaseTemplateMixin.extend({
	name: 'Panel',
	props: {
		hideHeader: {
			type: Boolean as PropType<Boolean>,
			default: false
		}
	},
	computed: {
		shouldHideHeader() {
			return this.hideHeader || this.exportMetadata.ComponentTree.hideHeaderInReadonly;
		},
		panelTheme() {
			return `bg-${this.exportMetadata.ComponentTree.theme ?? 'default'}`;
		}
	},
	methods: {}
});
