module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div ref="value">\n	';
 if (ctx.value) { ;
__p += '\n		';
 if(ctx.prefix instanceof HTMLElement){ ;
__p += '\n      		' +
((__t = (ctx.t(ctx.prefix.outerHTML))) == null ? '' : __t) +
'\n    	';
 } else{ ;
__p += '\n      		' +
((__t = (ctx.t(ctx.prefix))) == null ? '' : __t) +
'\n    	';
 } ;
__p += '\n\n		' +
((__t = (ctx.value)) == null ? '' : __t) +
'\n\n		';
 if(ctx.suffix instanceof HTMLElement){ ;
__p += '\n      		' +
((__t = (ctx.t(ctx.suffix.outerHTML))) == null ? '' : __t) +
'\n    	';
 } else{ ;
__p += '\n      		' +
((__t = (ctx.t(ctx.suffix))) == null ? '' : __t) +
'\n    	';
 } ;
__p += '\n	';
 } else { ;
__p += '\n		-\n	';
 } ;
__p += '\n</div>\n';
return __p
}