import { DataField } from '@sageworks/jpi';
import { CustomComponentType, TemplateDataField } from '../../enums';

export function getDataFieldLabel(dataField: DataField, componentType: CustomComponentType | null) {
	let label: string | null = null;
	switch (componentType) {
		case CustomComponentType.businessInfo:
			label = getBusinessInfoFieldOverrides(dataField);
			break;
		case CustomComponentType.nonprofitInfo:
			label = getNonprofitInfoFieldOverrides(dataField);
			break;
		case CustomComponentType.personalInfo:
			label = getPersonalInfoFieldOverrides(dataField);
			break;
		case CustomComponentType.income:
		case CustomComponentType.expense:
		case CustomComponentType.otherLiabilities:
		case CustomComponentType.cashAccount:
		case CustomComponentType.stocksBondsBusinessHoldings:
		case CustomComponentType.nonInvestmentRealEstate:
		case CustomComponentType.lifeInsurance:
		case CustomComponentType.retirementAccount:
		case CustomComponentType.otherAssets:
			label = getFinancialFieldOverrides(dataField, componentType);
	}

	return label ?? dataField.name;
}

function getNonprofitInfoFieldOverrides(dataField: DataField): string | null {
	if (dataField.templateDataFieldId === TemplateDataField.NonprofitSectorCode) {
		return 'Sector Code';
	} else if (dataField.templateDataFieldId === TemplateDataField.NonprofitPhone) {
		return 'Nonprofit Phone Number';
	}

	return null;
}

function getBusinessInfoFieldOverrides(dataField: DataField): string | null {
	if (dataField.templateDataFieldId === TemplateDataField.BusinessIndustryCode) {
		return 'Industry Code';
	} else if (dataField.templateDataFieldId === TemplateDataField.BusinessPhone) {
		return 'Business Phone Number';
	} else if (dataField.templateDataFieldId === TemplateDataField.BusinessSubEntityType) {
		return 'Business Type';
	}

	return null;
}

function getPersonalInfoFieldOverrides(dataField: DataField): string | null {
	if (dataField.templateDataFieldId === TemplateDataField.PersonalSSN) {
		return 'Social Security Number';
	}

	return null;
}

function getFinancialFieldOverrides(dataField: DataField, componentType: CustomComponentType | null): string | null {
	switch (dataField.templateDataFieldId) {
		case TemplateDataField.SubaccountName:
			return getSubaccountNameLabel(componentType);
		case TemplateDataField.SubaccountValue:
			return getSubaccountValueLabel(componentType);
		case TemplateDataField.SubaccountParentField:
			return getSubaccountParentFieldData(componentType);
		default:
			return null;
	}
}

function getSubaccountNameLabel(componentType: CustomComponentType | null): string | null {
	switch (componentType) {
		case CustomComponentType.stocksBondsBusinessHoldings:
			return 'Name of Issuer';
		case CustomComponentType.nonInvestmentRealEstate:
			return 'Address 1';
		default:
			return 'Description';
	}
}

function getSubaccountValueLabel(componentType: CustomComponentType | null): string | null {
	switch (componentType) {
		case CustomComponentType.otherLiabilities:
		case CustomComponentType.cashAccount:
		case CustomComponentType.retirementAccount:
			return 'Balance';
		case CustomComponentType.stocksBondsBusinessHoldings:
			return 'Market Value of Shares';
		case CustomComponentType.nonInvestmentRealEstate:
			return 'Property Value';
		case CustomComponentType.otherAssets:
			return 'Asset Value';
		default:
			return 'Value';
	}
}

function getSubaccountParentFieldData(componentType: CustomComponentType | null): string | null {
	switch (componentType) {
		case CustomComponentType.income:
			return 'Income Type';
		case CustomComponentType.expense:
			return 'Expense Type';
		case CustomComponentType.stocksBondsBusinessHoldings:
			return 'Investment Type';
		case CustomComponentType.nonInvestmentRealEstate:
			return 'Property Type';
		case CustomComponentType.otherAssets:
			return 'Asset Type';
		default:
			return null;
	}
}
