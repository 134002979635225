// There are certain store actions/mutations that could potentially cause issues if they count as 'user actions'
// In particular, not ignoring the logout action cause any other open tabs to cancel their logout countdown (if applicable)
// Also, setting or clearing our activity related intervals and showing/hiding the logout modal shouldn't count as action
const mutationsToIgnore = [
	'SessionActivity/SET_COUNTDOWN_INTERVAL_ID',
	'SessionActivity/SET_SESSION_ACTIVITY_WATCHER_INTERVAL_ID',
	'SessionActivity/SET_SHOW_LOGOUT_MODAL',
	'Authentication/SET_REFRESH_TOKEN_WATCHER_INTERVAL_ID'
];
const actionsToIgnore = [
	'Authentication/logout',
	'Authentication/refreshToken',
	'SessionActivity/updateLastUserActionTime',
	'SessionActivity/startSessionActivityWatcher',
	'SessionActivity/pollForExpirationTime'
	
];

export const sessionActivityPlugin = (store: any) => {
	store.subscribe((mutation: any) => {
		if (!mutationsToIgnore.includes(mutation.type)) {
			store.dispatch('SessionActivity/updateLastUserActionTime');
		}
	});
	store.subscribeAction((action: any) => {
		if (!actionsToIgnore.includes(action.type)) {
			store.dispatch('SessionActivity/updateLastUserActionTime');
		}
	});
};
