import { defineModule } from 'direct-vuex';
import { localActionContext } from 'direct-vuex';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => localActionContext(context, FormCacheModule);

export interface FormCacheModuleState {
	dataCache: Map<string, any>;
}

const FormCacheModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			dataCache: new Map<string, any>()
		} as FormCacheModuleState;
	},
	mutations: {
		SET_ITEM_IN_CACHE(state, { key, item }: { key: string; item: any }) {
			state.dataCache.set(key, item);
		}
	},
	actions: {
		async getValue<T = any>(context: any, { key }: { key: string }): Promise<T> {
			const { state } = actionContext(context);
			return state.dataCache.get(key);
		},
		async setValue(context, { key, item }: { key: string; item: any }): Promise<void> {
			const { commit } = actionContext(context);
			commit.SET_ITEM_IN_CACHE({ key, item });
		}
	},
	getters: {}
});

export default FormCacheModule;
