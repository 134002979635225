import nestedComponentForm from 'formiojs/components/_classes/nested/NestedComponent.form';
import AppTypeSelectDisplayForm from './editForm/app-type-select.edit.display';
import AppTypeSelectDataForm from './editForm/app-type-select.edit.data';
import ForceRequiredField from '../../configuration-components/force-required/force-required';
import LenderOnly from '../../configuration-components/base-widget-configuration/display/lender-only-field-checkbox';

const getIgnoredTabs = function() {
	return [
		{
			key: 'logic',
			ignore: true
		},
		{
			key: 'api',
			ignore: true
		},
		{
			key: 'conditional',
			ignore: true
		},
		{
			key: 'layout',
			ignore: true
		}
	];
};

export default function(...extend: any) {
	return nestedComponentForm(
		[
			{
				label: 'Display',
				key: 'display',
				weight: 0,
				components: [AppTypeSelectDisplayForm, LenderOnly]
			},
			{
				label: 'Data',
				key: 'data',
				weight: 10,
				components: [...ForceRequiredField, ...AppTypeSelectDataForm]
			},
			...getIgnoredTabs()
		],
		...extend
	);
}
