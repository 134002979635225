module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }

 
	var _helpers = ctx.options.builderTemplateHelpers || {};

	// pseudo-property definitions for usage within template
	var isWizardShowingRootLevelForm = _helpers.isRootLevelFormPath && _helpers.isRootLevelFormPath((ctx.self.form || {}).path);
;
__p += '\n\n<div class="formio builder row no-gutters formbuilder bg-light">\n	<div class="col-xs-5 col-sm-4 col-md-3 formcomponents">\n		' +
((__t = ( ctx.sidebar )) == null ? '' : __t) +
'\n	</div>\n	<div class="col-xs-7 col-sm-8 col-md-9 pt-2 pb-3 px-3 formarea" ref="form">\n		';
 if (ctx.instance.showPageTools) { ;
__p += '\n		<ol class="breadcrumb">\n			';
 ctx.pages.forEach(function(page, pageIndex) { ;
__p += '\n			<li class="mt-2">\n				<span\n					title="' +
((__t = ( page.title )) == null ? '' : __t) +
'"\n					class="mr-2 badge ';
 if (pageIndex === ctx.self.page) { ;
__p += 'badge-primary';
 } else { ;
__p += 'badge-tertiary text-white';
 } ;
__p += ' wizard-page-label"\n					style="';
 if (pageIndex !== ctx.self.page) { ;
__p += 'background-color: #acacac';
 } ;
__p += '"\n					ref="gotoPage"\n				>\n					' +
((__t = ( page.title )) == null ? '' : __t) +
'\n				</span>\n			</li>\n			';
 }) ;
__p += '\n			<li class="mt-2">\n				<span title="' +
((__t = ( ctx.t('Create Page') )) == null ? '' : __t) +
'" class="mr-2 badge badge-tertiary text-white wizard-page-label" ref="addPage" style="background-color: #acacac;">\n					<i class="' +
((__t = ( ctx.iconClass('plus') )) == null ? '' : __t) +
'"></i>\n					';
 var addPageToken = isWizardShowingRootLevelForm ? 'pageRootLevel' : 'Page'; ;
__p += '\n					' +
((__t = ( ctx.t(addPageToken) )) == null ? '' : __t) +
'\n				</span>\n			</li>\n		</ol>\n		';
 } ;
__p += '\n		<div ref="form" role="form">\n			' +
((__t = ( ctx.form )) == null ? '' : __t) +
'\n		</div>\n	</div>\n</div>\n';
return __p
}