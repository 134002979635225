export default [
	{
		key: 'hideLabel',
		ignore: true
	},
	{
		key: 'labelPosition',
		ignore: true
	},
	{
		key: 'placeholder',
		ignore: true
	},
	{
		key: 'description',
		ignore: true
	},
	{
		key: 'tooltip',
		ignore: true
	},
	{
		key: 'tabIndex',
		ignore: true
	},
	{
		key: 'autofocus',
		ignore: true
	}
];
