import { CustomComponentType, FinancialSubaccountParent, FinancialSubaccountUtils, LifeInsuranceCustomFieldKey, SubmitPayload } from '@sageworks/dynamic-forms';
import { formatDataFieldIdKey } from '@sageworks/dynamic-forms/src/components/form-components/simple-inputs/extended-field-helper';
import { ObjectPropertyValues } from '@sageworks/jpi';
import { StoreType } from '../../../../store';
import { FinancialSubaccountDataHandler } from '../financial-subaccount-data-handler/financial-subaccount-data-handler';

export class LifeInsuranceDataHandler extends FinancialSubaccountDataHandler {

	constructor(store: StoreType) {
		super(store, CustomComponentType.lifeInsurance);
	}

	public async submit(payload: SubmitPayload): Promise<any> {
		if (this.missingValues(payload)) {
			return;
		}

		// Grab the custom fields' values so we can format the subaccount's name and parent type correctly
		const name = payload.submission.data[LifeInsuranceCustomFieldKey.NameOfInsuranceCompany];
		const insuranceType = payload.submission.data[LifeInsuranceCustomFieldKey.InsuranceType];

		const nameFieldKey = formatDataFieldIdKey(this.nameDataFieldId.toString());
		const parentFieldKey = formatDataFieldIdKey(this.parentDataFieldId.toString());

		payload.submission.data[nameFieldKey] = FinancialSubaccountUtils.createSubaccountName(name, insuranceType);
		payload.submission.data[parentFieldKey] = FinancialSubaccountParent.LifeInsurance;

		return await super.submit(payload);
	}

	protected createFormObject(submitPayload: SubmitPayload, dataFieldValues: ObjectPropertyValues) {
		const updatedFormObject = super.createFormObject(submitPayload, dataFieldValues);

		// These values might change since when we re-create the form object it uses the financial subaccounts to set the values which
		// 	might be stale since only the data object is updated, so we update them here to ensure they remain the same
		updatedFormObject.data[LifeInsuranceCustomFieldKey.InsuranceType] = submitPayload.submission
			.data[LifeInsuranceCustomFieldKey.InsuranceType];
		updatedFormObject.data[LifeInsuranceCustomFieldKey.NameOfInsuranceCompany] = submitPayload.submission
			.data[LifeInsuranceCustomFieldKey.NameOfInsuranceCompany];

		return updatedFormObject;
	}
}