var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('table',{class:[
		'table',
		{ 'table-striped': _vm.exportMetadata.ComponentTree.striped },
		{ 'table-bordered': _vm.exportMetadata.ComponentTree.bordered },
		{ 'table-hover': _vm.exportMetadata.ComponentTree.hover },
		{ 'table-sm': _vm.exportMetadata.ComponentTree.condensed }
	]},[_c('caption',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.exportMetadata.ComponentTree.label))]),(_vm.hasHeader)?_c('thead',[_c('tr',_vm._l((_vm.exportMetadata.ComponentTree.header),function(header,index){return _c('th',{key:index},[_vm._v(_vm._s(header))])}),0)]):_vm._e(),_c('tbody',_vm._l((_vm.exportMetadata.ComponentTree.rows),function(row,rowIndex){return _c('tr',{key:rowIndex},_vm._l((row),function(column,colIndex){return _c('td',{key:colIndex,class:_vm.cellClass},_vm._l((_vm.getChildComponents(column)),function(childComponent){return _c(_vm.getComponent(childComponent.type),{key:childComponent.id,tag:"component",attrs:{"export-metadata":_vm.buildExportMetadata(childComponent)}})}),1)}),0)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }