import BaseComponent from '../base/base-widget/base-widget';
import editForm from './loans.form';
import { CustomComponentType, FormioEventName} from '../../../enums';


export default class LoanWidget extends BaseComponent {
	static schema(...extend: any) {
		return BaseComponent.schema(
			{
				label: 'Loan Info',
				type: CustomComponentType.loans,
				key: CustomComponentType.loans
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: 'Loan Info',
			group: 'widgets',
			weight: 10,
			schema: LoanWidget.schema()
		};
	}

	// TODO: look at moving to modified-form-component.ts for DYA-767
	init () {
		super.init();
		
		this.subFormReady.then(() => {
			if (this.subForm == null) {
				return;
			}

			this.subForm.on(FormioEventName.wizardNavigationClicked, this.savePartial.bind(this), true);
			this.subForm.on(FormioEventName.prevPage, this.savePartial.bind(this), true);
			this.subForm.on(FormioEventName.nextPage, this.savePartial.bind(this), true);
		});
		
		this.on(FormioEventName.wizardNavigationClicked, this.savePartial.bind(this), true);
		this.on(FormioEventName.prevPage, this.savePartial.bind(this), true);
		this.on(FormioEventName.nextPage, this.savePartial.bind(this), true);
	}

	async attach(element: any) {
		this.emit(FormioEventName.showParentNav, {});
		return await super.attach(element);
	}
}
