import Vue, { PropType } from 'vue';
import { FormioFormDisplayType, FormioFormSchema } from '../formio-interfaces/FormioFormSchema';
import { FormioFormOptionsSchema } from '../formio-interfaces/FormioFormOptionsSchema';
import { FormioBuilderOptionsSchema } from '../formio-interfaces/FormioBuilderOptionsSchema';
import { Formio } from 'formiojs';
import TokenHandler from '../authentication/token-handler';
import { CustomTemplateFramework } from '../enums';
import { useFormioPlugin } from '../plugins/formio-plugin';
import { attachExternalWizardActionsToForm, getNavigationEventPayload, onAfterWizardNavigate, onBeforeWizardNavigate } from '../utils/external-wizard-actions';
import { DynamicFormsContextData } from '../formio-interfaces/DynamicFormsContextData';
import { FormioFormSubmissionSchema } from '../formio-interfaces/FormioFormSubmissionSchema';
import { AutoFactory, IAPIConfiguration } from '@sageworks/jpi';
import { OpnRenderType } from '../enums/opn-render-type';

export default Vue.extend({
	props: {
		formObject: {
			type: Object,
			required: false,
			default: function() {
				return null;
			}
		},
		formPath: {
			type: String,
			default: ''
		},
		subdomain: {
			type: String,
			required: true
		},
		apiUrl: {
			type: String,
			required: true
		},
		contextData: {
			type: Object as PropType<DynamicFormsContextData>,
			required: false,
			default: function() {
				return {} as DynamicFormsContextData;
			}
		},
		prePopData: {
			type: Object as PropType<FormioFormSubmissionSchema | null>,
			required: false,
			default: function() {
				return null;
			}
		},
		waitUntilPrePopData: {
			type: Boolean,
			required: false,
			default: false
		},
		attachExternalWizardNavActions: {
			type: Boolean,
			required: false,
			default: true
		},
		initialPage: {
			type: Number,
			required: false,
			default: 0
		},
		readOnlyMode: {
			type: Boolean,
			required: false,
			default: false
		},
		validationMode: {
			type: Boolean,
			required: false,
			default: false
		},
		apiToken: {
			type: String,
			required: true,
			default: ''
		}
	},
	data: () => {
		return {
			formio: {} as Formio,
			form: (null as unknown) as FormioFormSchema,
			jpiAuthenticated: false
		};
	},
	computed: {
		options(): FormioFormOptionsSchema {
			return {
				builder: this.builder,
				namespace: TokenHandler.GetTokenNamespace(this.subdomain),
				template: CustomTemplateFramework.formioBootstrap,
				base: this.apiUrl,
				project: this.apiUrl + '/' + this.subdomain,
				contextData: {
					...this.contextData,
					jpiAuthenticated: this.jpiAuthenticated,
					opnRenderMode: OpnRenderType.Form
				},
				readOnly: this.readOnlyMode,
				renderMode: this.readOnlyMode ? 'html' : 'wizard'
			} as FormioFormOptionsSchema;
		},
		builder(): FormioBuilderOptionsSchema | null {
			return null;
		},
		loaded(): boolean {
			if (this.waitUntilPrePopData) {
				return !!this.form && !!this.prePopData;
			}

			return !!this.form;
		}
	},
	watch: {
		formPath: async function() {
			await this.initFormio();
			const form = await this.loadForm();
			this.setForm(form);
		},
		formObject: function(form) {
			this.setForm(form);
		},
		apiToken: {
			immediate: true,
			handler(token) {
				(Formio as any).apiToken = token;
			}
		}
	},
	methods: {
		initWithAuthentication() {
			Formio.use(useFormioPlugin());

			TokenHandler.VerifyOrGetToken(this.subdomain)
				.then(() => {
					this.initFormio();
					return this.addFormioAuth();
				})
				.then(() => {
					return this.loadForm();
				})
				.then((form: FormioFormSchema) => {
					this.setForm(form);
				});
		},
		initWithoutAuthentication() {
			Formio.use(useFormioPlugin());

			this.initFormio();
			this.loadForm().then((form: FormioFormSchema) => {
				return this.setForm(form);
			});
		},
		initFormio() {
			Formio.baseUrl = this.apiUrl;
			Formio.projectUrl = this.apiUrl + '/' + this.subdomain;
			Formio.namespace = TokenHandler.GetTokenNamespace(this.subdomain);

			this.formio = new Formio(this.apiUrl + '/' + this.subdomain + '/' + this.formPath, this.options);
		},
		async addFormioAuth() {
			const apiConfiguration: IAPIConfiguration = AutoFactory.get('IAPIConfiguration');
			(Formio as any).apiUrl = apiConfiguration.basePath;

			this.jpiAuthenticated = true;
		},
		loadForm(): Promise<FormioFormSchema> {
			if (this.formObject) {
				return this.formObject;
			}

			return this.formio.loadForm(undefined, {
				namespace: TokenHandler.GetTokenNamespace(this.subdomain)
			});
		},
		setForm(_form: FormioFormSchema) {
			if (this.attachExternalWizardNavActions && !this.readOnlyMode) {
				attachExternalWizardActionsToForm(_form, this.contextData);
			}

			// When displaying read-only or validating, we need the full form to render
			_form.display = this.readOnlyMode || this.validationMode ? FormioFormDisplayType.form : FormioFormDisplayType.wizard;
			this.form = _form;
		},
		submit() {
			return false;
		},
		getOptions() {
			return this.options;
		},
		onWizardNavigationClicked(eventPayload: any) {
			onBeforeWizardNavigate(eventPayload);
			this.$emit('page-changed');
		},
		onWizardPageSelected(eventPayload: any) {
			onAfterWizardNavigate(eventPayload);

			// if we need to emit a new repackaged event, do it from this vue component
			const customEventPayload = getNavigationEventPayload(eventPayload);
			if (customEventPayload != null) {
				this.$emit(customEventPayload.eventName, customEventPayload.form, customEventPayload.contextData);
			}
		},
		formRendered() {
			// Emit event that the form (authenticated or not) has been rendered
			this.$emit('form-rendered');
		}
	}
});
