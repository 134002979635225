import { AutoFactory, SbaForm, OnlinePortalSBAFormsService } from '@sageworks/jpi';
import { DateFormatting } from '@sageworks/core-logic';
import { SbaFormUtils } from '@/utils';

export async function getSbaForms(loanApplicationId: number): Promise<SbaForm[]> {
	const service = AutoFactory.get(OnlinePortalSBAFormsService);

	const result = await service.getOrForgeForLoanApplication(loanApplicationId);

	return result.items ?? [];
}

async function updateSbaForm(sbaForm: SbaForm) {
	const service = AutoFactory.get(OnlinePortalSBAFormsService);

	if (sbaForm.id) {
		const result = await service.update(sbaForm.id, sbaForm);
		return result;
	}

	throw new Error('Id was not specified on Sba Form');
}

export async function approveSbaForm(sbaForm: SbaForm, userId: number, statusReason = '') {
	return await updateSbaForm({
		...sbaForm,
		status: SbaForm.StatusEnum.Approved,
		statusChangedById: userId,
		statusChangedDate: DateFormatting.formatDateForJpi(new Date()),
		statusReason
	});
}

export async function declineSbaForm(sbaForm: SbaForm, userId: number, statusReason = '') {
	return await updateSbaForm({
		...sbaForm,
		status: SbaForm.StatusEnum.Declined,
		statusChangedById: userId,
		statusChangedDate: DateFormatting.formatDateForJpi(new Date()),
		statusReason
	});
}

export async function waiveSbaForm(sbaForm: SbaForm, userId: number, statusReason = '') {
	if (sbaForm.formType && SbaFormUtils.isFormTypeLenderOnly(sbaForm.formType)) {
		throw new Error('Cannot waive a form if it is a lender only form.');
	}

	return await updateSbaForm({
		...sbaForm,
		status: SbaForm.StatusEnum.Waived,
		statusChangedById: userId,
		statusChangedDate: DateFormatting.formatDateForJpi(new Date()),
		statusReason
	});
}

export async function resetSbaFormStatus(sbaForm: SbaForm) {
	return await updateSbaForm({
		...sbaForm,
		status: SbaForm.StatusEnum.UnderReview,
		statusChangedById: undefined,
		statusChangedDate: undefined,
		statusReason: undefined
	});
}
