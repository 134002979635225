interface IStylingConstants {
	backgroundClassName: string;
	fallbackLogoUrl: string;
}

const StylingConstants = {
	backgroundClassName: 'online-portal-background',
	fallbackLogoUrl: require('@/assets/blank-white-logo-box.svg')
} as IStylingConstants;

export { StylingConstants };
