import { render, staticRenderFns } from "./application-pdf-signature-section.vue?vue&type=template&id=971f1b6a&"
import script from "./application-pdf-signature-section.vue?vue&type=script&lang=ts&"
export * from "./application-pdf-signature-section.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports