import { CustomComponentType } from '../../../enums';
import { Utils as FormioUtils } from 'formiojs';
import { getCollateralTypeOptions } from '../collateral-type-select-options/collateral-type-options.helper';
import { getHmdaLoanPurposeOptions } from '../hmda-loan-purpose-select-options/hmda-loan-purpose-options.helper';
import { CollectionResponseDataFieldOption } from '@sageworks/jpi';

export class ComponentCleaner {
	private existingTemplateToDataFieldMappings: { [dataFieldId: number]: number } = {};
	private keysToRemove: string[] = [];

	private componentsToTrack: { [key in CustomComponentType]?: { dataInitializer: () => Promise<CollectionResponseDataFieldOption>; key: string | null } } = {
		[CustomComponentType.collateralTypeSelect]: { dataInitializer: getCollateralTypeOptions, key: null },
		[CustomComponentType.hmdaLoanPurposeSelect]: { dataInitializer: getHmdaLoanPurposeOptions, key: null }
	};

	constructor(private templateDataFieldMappings: { [templateDataFieldId: number]: number }, private isBestPractice: boolean, private components: any[]) {
		this.existingTemplateToDataFieldMappings = this.initializeExistingTemplateToDataFieldMappings();
	}

	public async clean(): Promise<any[]> {
		if (!this.isBestPractice) {
			return this.components;
		}

		FormioUtils.eachComponent(
			this.components,
			(component: any) => {
				// Order matters here. Must identify which components to remove before doing any data field transformation
				this.handleKeysForRemoval(component);
				this.cleanDataFieldIdComponent(component);
				this.cleanDataFieldIdsComponent(component);
				this.cleanConditionalLogic(component);
				this.trackComponentKey(component);
			},
			true
		);

		this.removeComponents();
		await this.initializeTrackedComponentOptions();

		return this.components;
	}

	private cleanConditionalLogic(component: any) {
		if (this.isBestPractice && component.conditional?.when != null) {
			// Get templateDataFieldId from best practice account dataFieldId
			const conditionalWhenParts = component.conditional.when.split('_') as string[];
			const bestPracticeDataFieldId = parseInt(conditionalWhenParts.pop()!);
			const templateDataFieldId = this.existingTemplateToDataFieldMappings[bestPracticeDataFieldId];

			// Replace the dataField in the when clause
			if (templateDataFieldId != null) {
				component.conditional.when = [...conditionalWhenParts, this.templateDataFieldMappings[templateDataFieldId]].join('_');
			}
		}
	}

	private cleanDataFieldIdsComponent(component: any) {
		if (component.dataFields == null) {
			return;
		}

		for (let i = 0; i < component.dataFields.length; i++) {
			if (this.templateDataFieldMappings[component.dataFields[i].templateDataFieldId] != null) {
				component.dataFields[i].id = this.templateDataFieldMappings[component.dataFields[i].templateDataFieldId];
			} else {
				component.dataFields.splice(i, 1);
				i--;
			}
		}
	}

	private cleanDataFieldIdComponent(component: any) {
		if (component.dataFieldId == null || component.templateDataFieldId == null || this.templateDataFieldMappings[component.templateDataFieldId] == null) {
			return;
		}

		component.dataFieldId = this.templateDataFieldMappings[component.templateDataFieldId];
	}

	private handleKeysForRemoval(component: any) {
		if (component.dataFieldId != null && (this.isUserDefined(component) || !this.hasTemplateDataFieldIdMapping(component))) {
			this.keysToRemove.push(component.key);
		}
	}

	private trackComponentKey(component: any) {
		if (!component.type || !Object.keys(this.componentsToTrack).includes(component.type)) {
			return;
		}

		this.componentsToTrack[component.type as CustomComponentType]!.key = component.key;
	}

	private async initializeTrackedComponentOptions() {
		for (const [, properties] of Object.entries(this.componentsToTrack)) {
			if (properties == null || properties.key == null) {
				return;
			}

			const options = (await properties.dataInitializer()).items;

			const formattedOptions = options?.map(o => {
				return {
					enabledForConsumers: true,
					label: o.label,
					value: o.value
				};
			});

			FormioUtils.findComponent(this.components, properties.key, null, (component: any, _: string) => {
				component.data.values = formattedOptions;
			});
		}
	}
	private initializeExistingTemplateToDataFieldMappings() {
		const mappings: { [dataFieldId: number]: number } = {};
		if (!this.isBestPractice) {
			return mappings;
		}

		FormioUtils.eachComponent(this.components, (component: any) => {
			if (component.dataFieldId != null && component.templateDataFieldId != null) {
				mappings[component.dataFieldId] = component.templateDataFieldId;
			}
		});

		return mappings;
	}

	private isUserDefined(component: any) {
		return component.dataFieldId !== null && component.templateDataFieldId == null;
	}

	private hasTemplateDataFieldIdMapping(component: any) {
		return component.templateDataFieldId != null && this.templateDataFieldMappings[component.templateDataFieldId] != null;
	}

	private removeComponents() {
		if (this.keysToRemove.length === 0) {
			return;
		}

		this.keysToRemove.forEach(k => {
			FormioUtils.findComponent(this.components, k, null, (_: any, path: string) => {
				FormioUtils.removeComponent(this.components, path);
			});
		});
	}
}
