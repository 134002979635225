import { CustomComponentType } from '../../../../enums';
import baseEditForm from 'formiojs/components/select/Select.form';
import OriginalLabel from '../../../configuration-components/base-widget-configuration/display/original-label';
import BaseEditConditionalComponents from '../../../configuration-components/base-widget-configuration/base.edit.conditional.components';
import { createDataTabComponents } from './editForm/editable-select.edit.data';
import LenderOnly from '../../../configuration-components/base-widget-configuration/display/lender-only-field-checkbox';

// eslint-disable-next-line max-lines-per-function
export const createEditableSelectEditForm = (optionComponentType: CustomComponentType) => {
	// eslint-disable-next-line max-lines-per-function
	return function(...extend: any) {
		return baseEditForm(
			[
				{
					key: 'display',
					components: [
						OriginalLabel,
						{
							key: 'widget',
							ignore: true
						},
						LenderOnly
					]
				},
				{
					label: 'Data',
					key: 'data',
					weight: 10,
					components: createDataTabComponents(optionComponentType)
				},
				{
					key: 'validation',
					components: [
						{
							key: 'unique',
							ignore: true
						}
					]
				},
				{
					key: 'api',
					ignore: true
				},
				{
					key: 'conditional',
					components: BaseEditConditionalComponents
				}
			],
			...extend
		);
	};
};
