module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<li class="py-3 border-top ' +
((__t = ( ctx.index === ctx.totalRowCount - 1 ? 'border-bottom' : '' )) == null ? '' : __t) +
'">\n	<div class="d-flex justify-content-between align-items-center" style="width: 400px;">\n		<span class="font-weight-bold">' +
((__t = ( ctx.rowTitle )) == null ? '' : __t) +
'</span>\n\n		';
 if (!ctx.subform) { ;
__p += '\n		<div class="ml-3">\n			<span>\n				<button class="btn btn-secondary btn-sm" ref="editRow">\n					Edit\n				</button>\n			</span>\n			<span><button class="btn btn-secondary btn-sm" ref="removeRow">Delete</button></span>\n		</div>\n		';
 } ;
__p += '\n	</div>\n	<div>\n		';
 if (ctx.subform) { ;
__p += '\n		<div class="mt-2 mb-3">\n			' +
((__t = ( ctx.subform() )) == null ? '' : __t) +
'\n			<div class="d-flex justify-content-end align-items-center">\n				<button class="btn btn-outline-secondary btn-sm" ref="cancelEditRow">\n					Cancel\n				</button>\n				<button class="ml-1 btn btn-secondary btn-sm" ref="saveEditRow">\n					Save\n				</button>\n			</div>\n		</div>\n		';
 } ;
__p += '\n	</div>\n</li>\n';
return __p
}