import { FinancialSubaccountParent, LiabilitiyDueDateOption, LiabilityTypeOption } from '../../enums';

export const isParentTaxesOwed = (liabilityType: LiabilityTypeOption, dueDate: LiabilitiyDueDateOption) => {
	return liabilityType === LiabilityTypeOption.TaxesOwed && dueDate === LiabilitiyDueDateOption.InNext12Months;
};

export const calculateLiabilityType = (liabilityType: LiabilityTypeOption | null, parentType: FinancialSubaccountParent) => {
	if (!liabilityType) {
		switch (parentType) {
			case FinancialSubaccountParent.Taxes:
				return LiabilityTypeOption.TaxesOwed;
		}

		throw new Error('Unable to calculate liability type');
	}

	return liabilityType;
};

export const calculateDueDate = (parent: FinancialSubaccountParent) => {
	switch (parent) {
		case FinancialSubaccountParent.OtherShortTermLoans:
		case FinancialSubaccountParent.Taxes:
			return LiabilitiyDueDateOption.InNext12Months;
		case FinancialSubaccountParent.OtherLongTermLoans:
			return LiabilitiyDueDateOption.MoreThan12Months;
		default:
			throw new Error('Unable to calculate Due Date');
	}
};

export const calculateParent = (liabilityType: LiabilityTypeOption, dueDate: LiabilitiyDueDateOption) => {
	if (isParentTaxesOwed(liabilityType, dueDate)) {
		return FinancialSubaccountParent.Taxes;
	}
	
	switch (dueDate) {
		case LiabilitiyDueDateOption.InNext12Months:
			return FinancialSubaccountParent.OtherShortTermLoans;
		case LiabilitiyDueDateOption.MoreThan12Months:
			return FinancialSubaccountParent.OtherLongTermLoans;
		default:
			throw new Error('Unable to calculate Subaccount Parent Type');
	}
};

export const createSubaccountName = (dueTo: string, liabilityType: LiabilityTypeOption, dueDate: LiabilitiyDueDateOption) => {
	if (isParentTaxesOwed(liabilityType, dueDate)) {
		return dueTo;
	}

	return `${dueTo} - ${liabilityType}`;
};