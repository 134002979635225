// Adds Object.entries implementation if none exists (IE11 support)
// Fixes bug detailed in DYA-1035
if (!Object.entries) {
	Object.entries = function(obj: any) {
		var ownProps = Object.keys(obj),
			i = ownProps.length,
			resArray = new Array(i); // preallocate the Array
		while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];
		return resArray;
	};
}
