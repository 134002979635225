import baseEditForm from 'formiojs/components/well/Well.form';
import BaseEditConditionalComponents from '../../../configuration-components/base-widget-configuration/base.edit.conditional.components';
import LenderOnly from '../../../configuration-components/base-widget-configuration/display/lender-only-field-checkbox';
export default function(...extend: any[]) {
	return baseEditForm(
		[
			{
				key: 'display',
				components: [LenderOnly]
			},
			{
				key: 'api',
				ignore: true
			},
			{
				key: 'conditional',
				components: BaseEditConditionalComponents
			}
		],
		...extend
	);
}
