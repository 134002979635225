import { default as FormioFormComponent } from 'formiojs/components/form/Form';
import _ from 'lodash';
import { FormioFormDisplayType } from '../../../../formio-interfaces';
import { FormioComponentType, FormioHookName, CustomOnSubmitHookName } from '../../../../enums';
import { isRepeaterWidget } from '../../../../utils/form-path-utils';
import ComponentModal from 'formiojs/components/_classes/componentModal/ComponentModal';

export default class ModifiedFormComponent extends FormioFormComponent {
	private _inflightSave: Promise<any> | undefined;

	getSubOptions(options?: any) {
		let subOptions = _.merge({}, _.cloneDeep(options));

		if (this.options.contextData) {
			subOptions.contextData = _.clone(this.options.contextData);
		}

		if (this.options.hooks != null) {
			subOptions.hooks = _.cloneDeep(this.options.hooks);
		}

		subOptions.hooks = subOptions.hooks ?? {};
		subOptions.hooks[FormioHookName.beforeSubmit] = this.beforeSubmitHook.bind(this);

		if (this.options.namespace != null) {
			subOptions.namespace = this.options.namespace;
		}

		if (this.options.alwaysLoad != null) {
			subOptions.alwaysLoad = this.options.alwaysLoad;
		}

		subOptions = super.getSubOptions(subOptions);

		if (subOptions && subOptions.buttonSettings) {
			subOptions.buttonSettings.showSubmit = false;
		}

		if (subOptions && this.options.preview != null) {
			subOptions.preview = this.options.preview;
		}

		return subOptions;
	}

	protected get submitMetadata() {
		return this.component.submitMetadata;
	}

	get shouldSubmit() {
		return !this.isHidden();
	}

	isHidden() {
		if (this.options.alwaysLoad) {
			return false;
		}

		return super.isHidden();
	}

	async loadSubForm() {
		if (this.component.formJson) {
			this.formObj = this.component.formJson;
			return Promise.resolve(this.component.formJson);
		}
		let form = await super.loadSubForm();

		// Force display type to 'form' in Read Only mode and when validating
		if (form && (this.options.readOnly || this.options.validationMode)) {
			form.display = FormioFormDisplayType.form;
		}

		return form;
	}

	beforePage() {
		return this.savePartial().then(() => super.beforePage());
	}

	beforeSubmit() {
		return this.savePartial()
			.then(() => {
				return this.dataValue;
			})
			.then(() => super.beforeSubmit());
	}

	// Zach said name it this ;)
	savePartial(): Promise<void> {
		// Once data is set, conditionality is not re-ran so we need to ensure we have the right value set for visible before proceeding
		// 	because a submission will not run if the component has visible set to false
		this.checkConditions();

		if (this.subForm == null || this.subForm.pristine) {
			return Promise.resolve();
		}

		return this.subFormReady.then(() => {
			if (this.subForm == null) {
				return Promise.resolve();
			}

			// TODO: Edge Case - repeater item #1 edit data will not save if user is able to edit same item
			//  after savePartial (add/edit) has started for repeater item #1 but not return.
			//  Recommend a spinner on repeater item until save has returned.
			//  However, the "this._inflightSave" solution resolves DYA-999
			let promise = this._inflightSave != null ? this._inflightSave : Promise.resolve();

			this._inflightSave = promise
				.then(() => this.subForm.beforeSubmit())
				.then(() => this.executeSubmitSubForm())
				.catch(err => {
					// @ts-ignore
					// eslint-disable-next-line no-console
					console.error('Failed to submit sub form.', err);
					// TODO: add alert some type of error notification that the save produced an error
					//  this doesn't work, but should
					//  this.root.alert.showErrors([promiseError], false);
				});
			return this._inflightSave;
		});
	}

	executeSubmitSubForm() {
		if (this.subForm == null || !this.shouldSubmit) {
			return Promise.resolve();
		}

		const options = { state: 'draft' };
		this.subForm.nosubmit = false;
		return this.subForm
			.submitForm(options)
			.then((result: any) => this.onSubmitSubForm(result))
			.catch((err: any) => {
				this.subForm.onSubmissionError(err);
				return Promise.reject(err);
			});
	}

	onSubmitSubForm(_ignoredResult: any) {
		if (this.subForm == null) {
			return Promise.resolve(this.dataValue);
		}

		// modeled from src/Webform.js and performs similar tasks without calling additional event emitters
		this.subForm.submitting = false;
		this.subForm.loading = false;
		this.subForm.setPristine(true);

		// if the parent is a repeater, we need to update the landing page
		this.updateRelatedRepeaterWidget();

		return Promise.resolve(this.dataValue);
	}

	private updateRelatedRepeaterWidget() {
		if (isRepeaterWidget(this.parent?.component?.type)) {
			this.parent.redraw();
		}
	}

	get onSubmitHookName() {
		let hookType = this.component.type;

		if (hookType === FormioComponentType.Form && this.parent?.component?.type != null) {
			hookType = this.parent.component.type;
		}

		return CustomOnSubmitHookName(hookType);
	}

	beforeSubmitHook(submission: any, next: Function) {
		if (this.subForm != null) {
			this.subForm.nosubmit = true;
		}

		// TODO: Edge Case - repeater item #1 edit data will not save if user is able to edit same item
		//  after savePartial (add/edit) has started for repeater item #1 but not return.
		//  Recommend a spinner on repeater item until save has returned.
		//  However, the "this._inflightSave" solution resolves DYA-999
		if (this.subForm.pristine) {
			next();
			return;
		}

		const metadata = this.submitMetadata;

		this.hook(
			this.onSubmitHookName,
			{ submission, metadata },
			(err: any) => {
				this.emit('save-error', null);
				next(err);
			},
			(updatedSubmissionData: any) => {
				if (updatedSubmissionData?.data != null) {
					_.merge(submission.data, updatedSubmissionData.data);
					// updates the UI with returned submission values
					this.dataValue = submission;
					this.subForm.setValue(_.cloneDeep(submission), {
						noValidate: true,
						noCheck: true,
						fromSubmission: true
					});
				}
				next();
			}
		);
	}

	restoreValue() {
		if (this.hasSetValue) {
			this.setValue(this.dataValue, {
				fromSubmission: true,
				noUpdateEvent: true
			});
		} else {
			this.setDefaultValue();
		}
	}

	setDefaultValue() {
		if (this.defaultValue) {
			const defaultValue = this.component.multiple && !this.dataValue.length ? [] : this.defaultValue;
			this.setValue(defaultValue, {
				fromSubmission: true,
				noUpdateEvent: true
			});
		}
	}

	isEmpty(value = this.dataValue) {
		return value === null || _.isEqual(value, this.emptyValue);
	}

	// Copied directly from formio with minor tweaks. If we can delete this, wonderful
	// eslint-disable-next-line max-lines-per-function
	attach(element: any) {
		if (this.builderMode || this.options.readOnly) {
			return super.attach(element);
		}

		// eslint-disable-next-line max-lines-per-function
		return super.attach(element).then(() => {
			// eslint-disable-next-line max-lines-per-function
			return this.subFormReady.then(() => {
				if (this.element == null) {
					return;
				}

				// The passed in element is not guaranteed to still exist. Pull it out of the page
				if (document.getElementById(this.id) != null) {
					element = document.getElementById(this.id);
				} else {
					// If the corresponding element is not found, use the current root page, since the panel for the current form is not rended.
					element = this.root?.currentWizardPage;
				}
				this.empty(element);
				if (this.options.builder) {
					this.setContent(
						element,
						this.ce(
							'div',
							{
								class: 'text-muted text-center p-2'
							},
							this.text(this.formObj.title)
						)
					);
					return;
				}

				this.setContent(element, this.render());
				if (this.subForm) {
					this.subForm.attach(element);
					if (!(this as any).valueChanged && this.dataValue.state !== 'submitted') {
						this.setDefaultValue();
					} else {
						this.restoreValue();
					}
				}
				if (!this.builderMode && this.component.modalEdit) {
					const modalShouldBeOpened = (this as any).componentModal ? (this as any).componentModal.isOpened : false;
					const currentValue = modalShouldBeOpened ? (this as any).componentModal.currentValue : this.dataValue;
					(this as any).componentModal = new ComponentModal(this, element, modalShouldBeOpened, currentValue);
					this.setOpenModalElement();
				}
			});
		});
	}
}
