import { defineModule } from 'direct-vuex';
import { IAppConfiguration } from '@/models';

export interface AppConfigModuleState {
	appConfig: IAppConfiguration | null;
}
const AppConfigModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			appConfig: null
		} as AppConfigModuleState;
	},
	mutations: {
		SET_APP_CONFIG(state, appConfig: IAppConfiguration) {
			state.appConfig = appConfig;
		}
	}
});

export default AppConfigModule;
