
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { ScaleMixin } from '@/mixins';
import { OnlinePortalRoutes } from '../../../OnlinePortalRoutes';
import StartNewApplicationModal from '../../../components/start-new-application-modal/start-new-application-modal.vue';
import { UsableProducts, FeatureFlagging, LoanApplication, DetailedLoanApplication, OnlinePortalProposedLoanStageGroup } from '@sageworks/jpi';
import { LoanApplicationSection } from '../../../components/portal/applications';
import { SageworksAnalystLinkUtils } from '../../../utils';
import { fetchDetailedLoanApplications  } from '../../../utils/detailed-loan-application-api-helper';
import { fetchProposedLoanStageGroups } from '../../../utils/proposed-loan-stage-group-api-helper';
import { isApplicationLocked } from '../../../logic/loan-application.service';

const MAX_ITEMS_PER_PAGE = 5;

export default Vue.extend({
	name: 'ApplicationsView',
	components: {
		StartNewApplicationModal,
		FontAwesomeIcon,
		LoanApplicationSection
	},
	mixins: [ScaleMixin],
	data() {
		return {
			tabIndex: 0,
			currentLoanApplications: [] as DetailedLoanApplication[],
			completedLoanApplications: [] as DetailedLoanApplication[],
			stageGroups: [] as OnlinePortalProposedLoanStageGroup[],

			// data management
			currentApplicationsPage: 1,
			completedApplicationsPage: 1,

			isLoadingStageGroups: false,
			isLoadingCurrentApplications: false,
			isLoadingCompletedApplications: false,

			hasMoreCurrentApplications: true,
			hasMoreCompletedApplications: true,
		};
	},
	computed: {
		noCurrentLoanApplicationsMessage() {
			return `No ${ this.itemLabel.toLowerCase() }s currently in progress. ${this.canStartNewApplications ? 'Click above to start one!' : ''}`;
		},
		noCompletedLoanApplicationsMessage() {
			return `No ${ this.itemLabel.toLowerCase() }s completed. ${this.canStartNewApplications ? 'Click above to start one!' : ''}`;
		},
		canStartNewApplications() {
			return !this.directStore.getters.User.isLender && this.directStore.state.InstitutionSettings.allowBorrowersToStartApplications?.settingValue;
		},
		itemLabel(): string {
			if (this.hasCommunityLendingSubscription === undefined) {
				return '';
			}
			return this.hasCommunityLendingSubscription ? 'Request Form': 'Application';
		},
		hasCommunityLendingSubscription(): boolean | undefined {
			return this.directStore.state.UsableProducts.usableProducts?.has('abrigoCommunityLending' as UsableProducts.ProductsEnum);
		},
	},
	mounted() {
		this.loadStageGroups();
		this.loadCurrentApplications();
		this.loadCompletedApplications();
	},
	methods: {
		async loadStageGroups() {
			try {
				this.isLoadingStageGroups = true;
				// Creating a new array here since .sort is mutable
				this.stageGroups = [...(await fetchProposedLoanStageGroups())]
					.sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0));
			} finally {
				this.isLoadingStageGroups = false;
			}
		},
		async loadCurrentApplications() {
			this.isLoadingCurrentApplications = true;
			
			try {
				const fetchedLoanApplications = (await fetchDetailedLoanApplications(false, this.completedApplicationsPage, MAX_ITEMS_PER_PAGE))
					.reverse();

				this.hasMoreCurrentApplications = fetchedLoanApplications.length >= MAX_ITEMS_PER_PAGE;
				this.currentLoanApplications = [...this.currentLoanApplications, ...fetchedLoanApplications];

				// Iterate the page number on a successful call so next time we load, it'll be data for the next page
				this.completedApplicationsPage += 1;
			} finally {
				this.isLoadingCurrentApplications = false;
			}
		},
		async loadCompletedApplications() {
			this.isLoadingCompletedApplications = true;
			
			try {
				const fetchedLoanApplications = (await fetchDetailedLoanApplications(true, this.currentApplicationsPage, MAX_ITEMS_PER_PAGE))
					.reverse();

				this.hasMoreCompletedApplications = fetchedLoanApplications.length >= MAX_ITEMS_PER_PAGE;
				this.completedLoanApplications = [...this.completedLoanApplications, ...fetchedLoanApplications];

				// Iterate the page number on a successful call so next time we load, it'll be data for the next page
				this.currentApplicationsPage += 1;
			} finally {
				this.isLoadingCompletedApplications = false;
			}
		},
		async  startNewApplication() {		
			if (await this.directStore.dispatch.FeatureFlag.fetchIsFeatureFlagActive(FeatureFlagging.FeatureFlagEnum.EnablePrelimAccountOpening)) {			
				this.$refs['start-new-application-modal'].$refs['options-modal'].show();
			}
			else {
				this.requestLoan();
			}
		},
		requestLoan() {
			if (this.directStore.getters.ApplicationTemplates.preAppTemplateId) {
				this.$router.push({ name: OnlinePortalRoutes.PreApplication.name });
			} else {
				this.showToast(`Start ${this.itemLabel}`, `Unable to start new ${this.itemLabel}`);
			}
		},
		showToast(title: string, message: string) {
			this.$root.$bvToast.toast(message, {
				title: title,
				solid: true,
				autoHideDelay: 3000,
				toaster: 'b-toaster-bottom-right'
			});
		},
		onCardClick(loanAplication: LoanApplication) { 
			if (!loanAplication.isDynamicApplication) {
				window.open(SageworksAnalystLinkUtils.getLinkToLoanApplication(loanAplication.id), '_blank');
				return;
			}

			const applicationRoute: string = isApplicationLocked(loanAplication)
					? OnlinePortalRoutes.ExportToPdf.name
					: OnlinePortalRoutes.ApplicationForm.name;

			this.$router.push({ name: applicationRoute, params: { applicationId: loanAplication.id.toString() } });
		}
	}
});
