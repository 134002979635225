import { CustomComponentType, FinancialSubaccountParent, OtherAssetsTypeOption } from '../../../enums';
import { FinancialSubaccountSelectOptions } from './financial-subaccount-type-select-options';

export class PersonalOtherAssetsTypeSelectOptions extends FinancialSubaccountSelectOptions {
	static schema(...extend: any) {
		return super.schema(
			{
				type: CustomComponentType.personalOtherAssetsTypeSelectOptions
			},
			...extend
		);
	}

	static get builderInfo() {
		return {
			weight: 10,
			schema: PersonalOtherAssetsTypeSelectOptions.schema({})
		};
	}

	protected async getApiOptions(): Promise<any> {
		return {
			items: [
				{
					label: OtherAssetsTypeOption.FurnitureFixtures,
					value: FinancialSubaccountParent.Furniture
				},
				{
					label: OtherAssetsTypeOption.Jewelry,
					value: FinancialSubaccountParent.Jewelry
				},
				{
					label: OtherAssetsTypeOption.Other,
					value: FinancialSubaccountParent.OtherAssets
				}
			]
		};
	}
}
