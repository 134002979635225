import Component from 'formiojs/components/_classes/component/Component';
import { CustomerCustomComponentTypes, CustomComponentType, LoanRoleCustomComponentTypes } from '../../enums';
import { PrimaryRoleRepeaterWidget } from '../../components/form-components/primary-role-repeater-widget/primary-role-repeater-widget';
import PersonalFinancialStatementWidget from '../../components/form-components/personal-financial-statement/personal-financial-statement';
import ModifiedWizard from '../../components/formio-display/modified-wizard';
import { FormioFormDisplayType } from '../../formio-interfaces';

export const locateClosestParent = <T = Component>(component: any, predicate: (parent: any) => boolean, depthLimit: number = 10): T | undefined => {
	const parent = (component ?? {}).parent;

	if (parent && predicate(parent)) {
		return parent;
	}

	if (parent && depthLimit > 1) {
		return locateClosestParent<T>(parent, predicate, depthLimit - 1);
	}

	return undefined;
};

export const locateClosestCustomerParent = <T = Component>(component: any, depthLimit?: number) => {
	return locateClosestParent<T>(component, parent => CustomerCustomComponentTypes.includes(parent.type), depthLimit);
};

export const locateClosestLoanRoleParent = <T = PrimaryRoleRepeaterWidget>(component: any, depthLimit?: number) => {
	return locateClosestParent<T>(component, parent => LoanRoleCustomComponentTypes.includes(parent.type), depthLimit);
};

export const locateClosestPfsParent = <T = PersonalFinancialStatementWidget>(component: any, depthLimit?: number) => {
	return locateClosestParent<T>(component, parent => parent.type === CustomComponentType.personalFinancialStatement, depthLimit);
};

export const locateRootWizard = <T = ModifiedWizard>(component: any, depthLimit?: number) => {
	return locateClosestParent<T>(component, parent => (parent.schema.display === FormioFormDisplayType.wizard  && parent.parent == null), depthLimit);
};

export const locateParentComponent = <T = Component>(component: any, typesToLocate: CustomComponentType[], depthLimit?: number) => {
	return locateClosestParent<T>(component, parent => (typesToLocate.includes(parent.type)), depthLimit);
};

export const LocateParentHelper = {
	locateClosestParent,
	locateClosestCustomerParent,
	locateClosestPfsParent,
	locateClosestLoanRoleParent,
	locateRootWizard,
	locateParentComponent
};
