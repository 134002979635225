import { FormioFormSchema } from '../formio-interfaces/FormioFormSchema';
import { FormioComponentSchema } from '../formio-interfaces/FormioComponentSchema';
import { FormioValidationSchema } from '../formio-interfaces/FormioComponentSchema';
import { DynamicFormsContextData } from '../formio-interfaces/DynamicFormsContextData';
import { ExternalWizardEventType } from '../enums/external-wizard-event-type';

export const ActionKeyPrefix = 'navigationEventDummy-';
export const ActionEventNameProperty = 'defaultValue';

export function attachExternalWizardActionsToForm(form: FormioFormSchema, contextData: DynamicFormsContextData) {
	if (!form.components) {
		form.components = [];
	}
	let addExternalWizardActions = !form.components.some((component) => {
		return component.key?.startsWith(ActionKeyPrefix);
	});
	if (addExternalWizardActions) {
		form.components.push(...getExternalWizardNavigationActions(contextData));
	}
}

export function getNavigationEventPayload(pagePanel: any): { eventName: string; form: any; contextData: DynamicFormsContextData } | null {
	if (!pagePanel || !pagePanel.root || !pagePanel.component) {
		return null;
	}
	const wizard = pagePanel.root.wizard;
	if (!wizard) {
		return null;
	}
	// for custom navigation actions, package some contextual information and emit a new event
	const panelKey = pagePanel.component.key;
	const customEventName = pagePanel.component[ActionEventNameProperty];
	if (panelKey.indexOf(ActionKeyPrefix) === 0 && customEventName) {
		return {
			eventName: customEventName,
			form: pagePanel.root.form,
			contextData: pagePanel.root.contextData || {}
		};
	}
	return null;
}

export function onBeforeWizardNavigate(pagePanel: any) {
	if (!pagePanel || !pagePanel.root) {
		return;
	}
	const wizard = pagePanel.root.wizard;
	if (!wizard) {
		return;
	}
	const panelKey = pagePanel.component.key;
	if (panelKey.indexOf(ActionKeyPrefix) === 0) {
		// we set "wizard.full" because it turns the navigation process into a no-op (Formio Wizard internals)
		wizard.full = true;
	}
}

export function onAfterWizardNavigate(pagePanel: any) {
	if (!pagePanel || !pagePanel.root) {
		return;
	}
	const wizard = pagePanel.root.wizard;
	if (!wizard) {
		return;
	}
	// we un-set "wizard.full" so the next navigation selection will be handled normally (Formio Wizard internals)
	wizard.full = false;
}

function createNavigationEventDummy(label: string, eventName: string): FormioComponentSchema<any, FormioValidationSchema> {
	return {
		type: 'panel',
		key: ActionKeyPrefix + eventName,
		[ActionEventNameProperty]: eventName,
		title: label,
		components: [],
		nextPage: `
			instance.emit('wizardPageSelected', instance);
			return null;
		`,
		disabled: true
	};
}

function getExternalWizardNavigationActions(_contextData: DynamicFormsContextData): Array<FormioComponentSchema<any, FormioValidationSchema>> {
	const documentationLink = createNavigationEventDummy('Next Section', ExternalWizardEventType.documentationClicked);
	const submitApplicationLink = createNavigationEventDummy('Submit Application', ExternalWizardEventType.submitApplicationClicked);
	return [documentationLink, submitApplicationLink];
}
