
import Vue, { PropType } from 'vue';
import { TuxInputText } from '@tuxedo/components/src/components';
import { Multiselect } from 'vue-multiselect';
import { DataFieldOption } from '@sageworks/jpi';

export default Vue.extend({
	name: 'AddNewBusinessForm',
	components: { TuxInputText, Multiselect },
	props: {
		businessName: String,
		industryCode: String,
		state: String,
		states: Array as PropType<DataFieldOption>,
		industryCodes: Array as PropType<DataFieldOption>,
	},
});
