import { DataField } from '@sageworks/jpi';
import { TemplateDataField } from '../../../../../enums';
import { DataFieldConvertStrategy } from '../../data-field-convert-strategy';
import { DefaultDataFieldConverter } from '../default-data-field-converter';

export class MailingAddressDifferentFieldConverter implements DataFieldConvertStrategy {
	private readonly allowedFields = new Set<number>([TemplateDataField.MailingAddressDifferent]);

	private readonly defaultDataFieldConverter = new DefaultDataFieldConverter();

	isFieldValid(dataField: DataField): boolean {
		return this.allowedFields.has(dataField.templateDataFieldId!);
	}

	convert(dataField: DataField, currentProperties: any) {
		const properties = { ...currentProperties };
		let field = this.defaultDataFieldConverter.convert(dataField, properties);
		field.defaultValue = '0';
		return field;
	}
}
