import { defineModule } from 'direct-vuex';
import { ArrayUtils } from '@/utils';
import { Permission, AutoFactory, OnlinePortalLoanOfficersService } from '@sageworks/jpi';
import ProductFeatureEnum = Permission.ProductFeatureEnum;

// Using moduleActionContext instead of localActionContext because we need access to rootGetters
import { moduleActionContext } from '.';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => moduleActionContext(context, LenderAuthorizationModule);

export interface LenderAuthorizationModuleState {
	userPermissions: string[] | null;
}

const LenderAuthorizationModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			userPermissions: null as string[] | null
		} as LenderAuthorizationModuleState;
	},
	getters: {
		canAccessFeature(state: any): (feature: ProductFeatureEnum) => boolean {
			if (!state.userPermissions) {
				return () => false;
			}
			const permissionsMap = ArrayUtils.arrayToMap(state.userPermissions, (permission: string) => permission);
			return feature => permissionsMap.has(feature);
		}
	},
	mutations: {
		SET_USER_PERMISSIONS(state, userPermissions: string[]) {
			state.userPermissions = userPermissions;
		}
	},
	actions: {
		async loadUserPermissions(context: any): Promise<void> {
			const { state, commit, rootGetters } = actionContext(context);
			
			if (!rootGetters.User.isLender || state.userPermissions) {
				return;
			}

			const onlinePortalLoanOfficersService = AutoFactory.get(OnlinePortalLoanOfficersService);

			const userPermissions = await onlinePortalLoanOfficersService.getCurrentUserPermissions();
			commit.SET_USER_PERMISSIONS(userPermissions);
		}
	}
});

export default LenderAuthorizationModule;
