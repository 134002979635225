
import { Component, Mixins } from 'vue-property-decorator';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { ScaleMixin, RouteMixin } from '@/mixins';

@Component({
	components: {
		FontAwesomeIcon
	}
})
export default class LoginPassword extends Mixins(ScaleMixin, RouteMixin) {
	private secret: string = '';
	private invalidFeedback: string | null = null;
	private showValidation: boolean = false;
	private submitting: boolean = false;

	private rateLimitErrorMessage = 'We have received too many requests for this action. Please try again in a few minutes';

	private mounted() {
		(this.$refs.passwordInput as any).$el.focus();
	}

	get validationState() {
		if (!this.showValidation) {
			return null;
		} else if (this.invalidFeedback) {
			return false;
		}
		return true;
	}

	get userEmail() {
		return this.directStore.state.Login.userEmail;
	}

	private async submit(): Promise<void> {
		this.submitting = true;
		try {
			this.invalidFeedback = null;
			await this.directStore.dispatch.Authentication.login({
				username: this.userEmail || undefined,
				secret: this.secret
			});
			if (this.directStore.state.Authentication.onlinePortalUserToIdVerify) {
				this.directStore.dispatch.Login.setUserPassword(this.secret);
				await this.$router.push(this.OnlinePortalRoutes.IdentityProtection.path);
			} else {
				this.directStore.dispatch.Login.setUserEmail(null);
				this.directStore.dispatch.Login.setUserPassword(null);
				await this.$router.push(this.OnlinePortalRoutes.Home.path);
			}
		} catch (err) {
			this.showValidation = true;
			this.submitting = false;

			this.invalidFeedback = err.status === 429 ? this.rateLimitErrorMessage : 'Invalid email or password';
		}
	}
}
