
import Vue from 'vue';
export default Vue.extend({
	props: {
		logoSource: {
			type: String,
			required: false,
			default: null,
		},
	},
});
