
import { BeneficialOwnership1071Status } from '../../../utils/beneficial-ownership';
import Vue, { PropType } from 'vue';

export default Vue.extend({
	name: 'BeneficialOwnership1071StatusBadge',
	props: {
		status: String as PropType<BeneficialOwnership1071Status>,
	},
	computed: {
		label() {
			switch (this.status) {
				case BeneficialOwnership1071Status.NotStarted:
				case BeneficialOwnership1071Status.InProgress:
					return 'Incomplete';
				case BeneficialOwnership1071Status.Completed:
					return 'Complete';
				default:
					return 'Not Required';
			}
		},
		badgeClass() {
			switch (this.status) {
				case BeneficialOwnership1071Status.NotStarted:
				case BeneficialOwnership1071Status.InProgress:
					return 'badge-danger';
				case BeneficialOwnership1071Status.Completed:
					return 'badge-success';
				default:
					return 'badge-dark';
			}
		}
	}
});
