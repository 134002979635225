
import BaseTemplateMixin from './base-template-mixin.vue';

export default BaseTemplateMixin.extend({
	name: 'HTML',
	computed: {
		tagName() {
			// Formio has a bug in html components.  For now just return paragraph (which is the default)
			return this.isValidHtmlTag ? this.exportMetadata.ComponentTree.tag : 'p';
		},
		isValidHtmlTag() {
			return (
				this.exportMetadata.ComponentTree.tag !== '' && ['b', 'div', 'p', 'span', 'section', 'strong'].includes(this.exportMetadata.ComponentTree.tag)
			);
		}
	}
});
