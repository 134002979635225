import { LoanApplicationDocumentRequest, DocumentRequest as ClientPortalDocumentRequest } from '@sageworks/jpi/';
import { IDocumentRequest } from '@/models';
import { DocumentRequestTypes } from '@/models';

export class OnlinePortalDocumentRequest implements IDocumentRequest {
	constructor(public request: LoanApplicationDocumentRequest | ClientPortalDocumentRequest) {}
	get id(): number | undefined {
		return this.request ? this.request.id : undefined;
	}
	set id(value: number | undefined) {
		if (this.request) {
			this.request.id = value;
		}
	}
	get customerId(): number | undefined {
		return this.request ? this.request.customerId : undefined;
	}
	set customerId(value: number | undefined) {
		if (this.request && value) {
			this.request.customerId = value;
		}
	}
	get proposedBankLoansId(): number | undefined | null {
		return null;
	}
	set proposedBankLoansId(value: number | undefined | null) {
		// proposed bank loans ID not available in this case so there's nothing to do
	}
	get documentName(): string {
		return this.request ? this.request.documentName : '';
	}
	set documentName(value: string) {
		if (this.request) {
			this.request.documentName = value;
		}
	}
	get documentFolderId(): number | undefined {
		return this.request ? this.request.documentFolderId : undefined;
	}
	set documentFolderId(value: number | undefined) {
		if (this.request) {
			this.request.documentFolderId = value;
		}
	}
	get dateRequested(): string | undefined {
		return this.request ? (this.request.dateRequested as any) : undefined;
	}
	set dateRequested(value: string | undefined) {
		if (this.request) {
			(this.request.dateRequested as any) = value;
		}
	}
	get dateUploaded(): string | undefined {
		return this.request ? (this.request.dateUploaded as any) : undefined;
	}
	set dateUploaded(value: string | undefined) {
		if (this.request) {
			(this.request.dateUploaded as any) = value;
		}
	}
	get documentId(): number | undefined {
		return this.request ? this.request.documentId : undefined;
	}
	set documentId(value: number | undefined) {
		if (this.request) {
			this.request.documentId = value;
		}
	}
	// READ ONLY
	get customerFacingName(): string | undefined {
		return this.request ? this.request.customerFacingName : undefined;
	}
	get dateWaived(): Date | undefined {
		return this.request ? this.request.dateWaived : undefined;
	}
	set dateWaived(value: Date | undefined) {
		if (this.request) {
			this.request.dateWaived = value;
		}
	}
	get waivedReason(): string | undefined {
		return this.request ? this.request.waivedReason : undefined;
	}
	set waivedReason(value: string | undefined) {
		if (this.request) {
			this.request.waivedReason = value;
		}
	}
	get waivedByLoanOfficerId(): number | undefined {
		return this.request ? this.request.waivedByLoanOfficerId : undefined;
	}
	set waivedByLoanOfficerId(value: number | undefined) {
		if (this.request) {
			this.request.waivedByLoanOfficerId = value;
		}
	}
	get documentNamesId(): number | undefined {
		return this.request && this.documentRequestType === DocumentRequestTypes.DocumentRequest ? this.request.documentNamesId : undefined;
	}
	set documentNamesId(value: number | undefined) {
		if (this.request && value && this.documentRequestType === DocumentRequestTypes.DocumentRequest) {
			this.request.documentNamesId = value;
		}
	}
	// READ ONLY
	get templateDocumentsId(): number | undefined {
		if (this.request && this.documentRequestType === DocumentRequestTypes.DocumentRequest) {
			return (this.request as ClientPortalDocumentRequest).templateDocumentsID || undefined;
		} else if (this.request && this.documentRequestType === DocumentRequestTypes.LoanApplicationDocumentRequest) {
			return (this.request as LoanApplicationDocumentRequest).templateDocumentsId || undefined;
		} else return undefined;
	}
	get documentRequestType(): number {
		if (this.request && Object.prototype.hasOwnProperty.call(this.request, 'customerInformationRequestId')) {
			return DocumentRequestTypes.DocumentRequest;
		}
		if (
			this.request &&
			(Object.prototype.hasOwnProperty.call(this.request, 'applicationId') || Object.prototype.hasOwnProperty.call(this.request, 'loanApplicationId'))
		) {
			return DocumentRequestTypes.LoanApplicationDocumentRequest;
		}
		return -2;
	}
	get isOpenRequest(): boolean {
		return (
			!this.dateUploaded &&
			!this.dateWaived &&
			!this.waivedReason &&
			!this.waivedByLoanOfficerId &&
			!(
				this.documentRequestType === DocumentRequestTypes.LoanApplicationDocumentRequest &&
				(this.request as LoanApplicationDocumentRequest).notUploadedReason
			)
		);
	}
}
