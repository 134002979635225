module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div id="' +
((__t = ( ctx.id )) == null ? '' : __t) +
'" class="' +
((__t = ( ctx.classes )) == null ? '' : __t) +
'" ';
 if (ctx.styles) { ;
__p += ' styles="' +
((__t = ( ctx.styles )) == null ? '' : __t) +
'" ';
 } ;
__p += ' ref="component">\n	<div class="mb-4">\n		' +
((__t = ( ctx.message || '' )) == null ? '' : __t) +
'\n	</div>\n	' +
((__t = ( ctx.buttons )) == null ? '' : __t) +
'\n	<div ref="messageContainer" class="formio-errors invalid-feedback mt-3"></div>\n</div>\n';
return __p
}