import ExtendedRadio from '../simple-inputs/extended-radio/extended-radio';
import { CustomComponentType } from '../../../enums';
import editForm from './customer-type-select.form';
import { ConditionalHelper, CustomConditionalType } from '../../../utils/conditional-helper';

export default class CustomerTypeSelect extends ExtendedRadio {
	static schema(...extend: any) {
		return ExtendedRadio.schema(
			{
				type: CustomComponentType.customerTypeSelect
			},
			...extend
		);
	}

	static get builderInfo() {
		return {
			title: 'Customer Type Select',
			group: '',
			weight: 10,
			schema: CustomerTypeSelect.schema()
		};
	}

	static editForm = editForm;

	constructor(component: any, options: any, data: any) {
		ConditionalHelper.clearConditionals(component);
		ConditionalHelper.addCustomConditional(component, CustomConditionalType.AlwaysHidden);

		super(component, options, data);
	}

	conditionallyVisible(): boolean {
		return false;
	}
}
