import { defineModule } from 'direct-vuex';
import {
	AutoFactory,
	OnlinePortalUsersService,
	OnlinePortalUserPasswordResetRequest,
	OnlinePortalUserPasswordResetEmailRequest,
	IdentityVerificationRequest,
	OnlinePortalIdentityVerificationService,
	IdentityVerificationResponse,
	IdentityVerificationKBARequest,
	TokenService
} from '@sageworks/jpi';
import { UrlUtils } from '@/utils';
import { moduleActionContext } from '@/store/index';
import Vue from 'vue';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => moduleActionContext(context, LoginModule);

export interface LoginModuleState {
	userEmail: string | null;
	userFirstName: string | null;
	userLastName: string | null;
	userPassword: string | null;
	emailVerificationCode: string | null;
	identityVerificationRequest: IdentityVerificationRequest;
	identityVerificationResponse: IdentityVerificationResponse;
}
const LoginModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			identityVerificationRequest: {
				state: '',
				firstName: '',
				lastName: '',
				ssn: '',
				zip: '',
				city: '',
				address: '',
				birthDate: ''
			} as IdentityVerificationRequest,
			identityVerificationResponse: {}
		} as LoginModuleState;
	},
	mutations: {
		SET_USER_EMAIL(state, email: string | null) {
			state.userEmail = email;
		},
		SET_USER_FIRST_NAME(state, firstName: string | null) {
			state.userFirstName = firstName;
		},
		SET_USER_LAST_NAME(state, lastName: string | null) {
			state.userLastName = lastName;
		},
		SET_USER_PASSWORD(state, password: string | null) {
			state.userPassword = password;
		},
		SET_EMAIL_VERIFICATION_CODE(state, emailVerificationCode: string | null) {
			state.emailVerificationCode = emailVerificationCode;
		},
		SET_IDENTITY_VERIFICATION_RESPONSE(state, identityVerificationResponse: IdentityVerificationResponse) {
			state.identityVerificationResponse = identityVerificationResponse;
			for (const question of identityVerificationResponse?.questions ?? []) {
				Vue.set(question, 'answer', null);
			}
		}
	},
	actions: {
		async verifyIdentity(context): Promise<void> {
			const { commit, state, rootState } = actionContext(context);

			state.identityVerificationRequest.subdomain = UrlUtils.getOnlinePortalSubdomain();
			state.identityVerificationRequest.username = rootState.Login.userEmail;
			state.identityVerificationRequest.secret = rootState.Login.userPassword;
			const verificationResult = await AutoFactory.get(OnlinePortalIdentityVerificationService).performIdentityVerification(
				state.identityVerificationRequest
			);
			if (!verificationResult.idNumber && !verificationResult.deviceToken) {
				throw new Error('verification failed');
			}
			commit.SET_IDENTITY_VERIFICATION_RESPONSE(verificationResult);
		},
		async verifyKnowledgeBasedAnswers(context, rememberThisDevice: boolean): Promise<void> {
			const { state, rootState, rootCommit } = actionContext(context);

			let deviceToken = state.identityVerificationResponse.deviceToken;
			if (!deviceToken) {
				state.identityVerificationRequest.subdomain = UrlUtils.getOnlinePortalSubdomain();
				const verificationResult = await AutoFactory.get(OnlinePortalIdentityVerificationService).submitIdentityVerificationAnswers(
					buildKbaVerificationRequest(
						state.identityVerificationRequest,
						state.identityVerificationResponse,
						rootState.Login.userEmail,
						rootState.Login.userPassword
					)
				);
				if (!verificationResult.identityVerified) {
					throw new Error('verification failed');
				}
				deviceToken = verificationResult.deviceToken;
			}
			rootCommit.Authentication.SET_DEVICE_TOKEN({ deviceToken: deviceToken, rememberThisDevice: rememberThisDevice });
		},
		setUserEmail(context, email: string | null) {
			const { commit } = actionContext(context);
			commit.SET_USER_EMAIL(email);
		},
		setUserFirstName(context, firstName: string | null) {
			const { commit } = actionContext(context);
			commit.SET_USER_FIRST_NAME(firstName);
		},
		setUserLastName(context, lastName: string | null) {
			const { commit } = actionContext(context);
			commit.SET_USER_LAST_NAME(lastName);
		},
		setUserPassword(context, password: string | null) {
			const { commit } = actionContext(context);
			commit.SET_USER_PASSWORD(password);
		},
		setEmailVerificationCode(context, emailVerificationCode: string | null) {
			const { commit } = actionContext(context);
			commit.SET_EMAIL_VERIFICATION_CODE(emailVerificationCode);
		},
		async sendPasswordResetEmail(context): Promise<void> {
			const { state } = actionContext(context);
			if (state.userEmail !== '' && state.userEmail != null) {
				try {
					const usersService = AutoFactory.get(OnlinePortalUsersService);
					let passwordResetEmailRequest = {
						userEmail: state.userEmail,
						subdomain: UrlUtils.getOnlinePortalSubdomain()
					} as OnlinePortalUserPasswordResetEmailRequest;
					await usersService.requestUserPasswordResetEmail(passwordResetEmailRequest);
					return Promise.resolve();
				} catch (err) {
					return Promise.reject(err);
				}
			}
		},
		async resetUserPassword(context, passwordResetRequest: OnlinePortalUserPasswordResetRequest): Promise<void> {
			const { rootCommit } = actionContext(context);
			if (passwordResetRequest.newSecret && passwordResetRequest.authToken) {
				try {
					const usersService = AutoFactory.get(OnlinePortalUsersService);
					await usersService.resetCurrentUserPassword(passwordResetRequest);

					rootCommit.Authentication.CLEAR_USER_TOKEN();
					rootCommit.Authentication.CLEAR_EXPIRATION();
					const tokenService: TokenService = AutoFactory.get(TokenService);
					tokenService.set(null as any);

					return Promise.resolve();
				} catch (err) {
					return Promise.reject(err);
				}
			}
		}
	}
});

function buildKbaVerificationRequest(
	identityVerificationRequest: IdentityVerificationRequest,
	identityVerificationResponse: IdentityVerificationResponse,
	username: string,
	secret: string
): IdentityVerificationKBARequest {
	if (!identityVerificationResponse.questions) {
		throw new Error('questions not defined');
	}
	return {
		username: username,
		secret: secret,
		idNumber: identityVerificationResponse.idNumber ?? '',
		state: identityVerificationRequest.state,
		firstName: identityVerificationRequest.firstName,
		lastName: identityVerificationRequest.lastName,
		address: identityVerificationRequest.address,
		city: identityVerificationRequest.city,
		birthDate: identityVerificationRequest.birthDate,
		subdomain: identityVerificationRequest.subdomain,
		ssn: identityVerificationRequest.ssn,
		zip: identityVerificationRequest.zip,
		question1Type: identityVerificationResponse.questions[0]?.type ?? '',
		question1Answer: (identityVerificationResponse.questions[0] as any)?.answer ?? '',
		question2Type: identityVerificationResponse.questions[1]?.type ?? '',
		question2Answer: (identityVerificationResponse.questions[1] as any)?.answer ?? '',
		question3Type: identityVerificationResponse.questions[2]?.type ?? '',
		question3Answer: (identityVerificationResponse.questions[2] as any)?.answer ?? ''
	};
}

export default LoginModule;
