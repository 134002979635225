import { LoanApplication, ApplicationTemplate } from '@sageworks/jpi';

export function ToApplicationTemplateType(type: LoanApplication.TypeEnum) {
	switch (type) {
		case LoanApplication.TypeEnum.Business:
			return ApplicationTemplate.TypeEnum.Business;
		case LoanApplication.TypeEnum.Nonprofit:
			return ApplicationTemplate.TypeEnum.Nonprofit;
		case LoanApplication.TypeEnum.Personal:
			return ApplicationTemplate.TypeEnum.Person;
		case LoanApplication.TypeEnum.Farm:
			return ApplicationTemplate.TypeEnum.Farm;
		case LoanApplication.TypeEnum.Sba:
			return ApplicationTemplate.TypeEnum.Sba;
		default:
			throw new Error(`Invalid Loan Application Type: ${type}`);
	}
}
