
import Vue, { PropType } from 'vue';
import { mapState, mapGetters } from 'vuex';
import { BModal, VBModal, BSpinner } from 'bootstrap-vue';
import { DataObject, DataObjectUtils, TemplateDataField } from '@sageworks/dynamic-forms';
import { AutoFactory, CustomersService, ApplicationTemplateMetadata, Customer, DataFieldOption } from '@sageworks/jpi';
import CustomerSearch from './customer-search.vue';
import { AddNewCustomerForm } from './add-new-customer-form';
import { generateBlankNewCustomerForm, createCustomerObjectfromForm } from '../../utils/add-customer';

export enum ComponentView {
	MainView,
	AddNew
}

export default Vue.extend({
	name: 'AddCustomerPopup',
	// TODO: Remove use of BModal and BSpinner when possible
	components: { BModal, BSpinner, CustomerSearch, AddNewCustomerForm },
	directives: { 'b-modal': VBModal },
	props: {
		value: {
			type: Boolean,
			default: false
		},
		title: {
			type: String,
			default: 'Add New',
		},
		ignoredCustomers: {
			type: Array as PropType<number[]>,
			default: () => []
		},
		allowedEntityTypes: {
			type: Array as PropType<Customer.TypeEnum[]>,
			default: () => []
		}
	},
	data() {
		return {
			ComponentView,
			open: this.modelValue ?? false,
			currentView: ComponentView.MainView,
			applicationEntities: [] as Customer[],
			isLoading: false,
			newCustomerForm: generateBlankNewCustomerForm()
		};
	},
	computed: {
		...mapState('MultiLoanApplicationForm', ['renderData', 'metadata']),
		...mapState('LoanApplicationMetadata', ['metadata']),
		...mapGetters('MultiLoanApplicationForm', ['proposedLoanId']),
		...mapState('CustomComponentProperties', ['templateDataFieldIdToDataFieldId', 'selectFieldOptions']),
		...mapGetters('User', ['isLender']),
		dataObjects(): { [key: string]: Array<DataObject.TypeEnum>; } {
			return this.renderData?.formData?.dataObjects ?? {};
		},
		stateOptions(): DataFieldOption[] {
			return this.selectFieldOptions[TemplateDataField.BusinessState] ?? [];
		},
		industryCodeOptions(): DataFieldOption[] {
			return this.selectFieldOptions[TemplateDataField.BusinessIndustryCode] ?? [];
		}
	},
	watch: {
		value(val: boolean) {
			this.open = val;

			if (val) {
				Promise.all([
					this.fetchApplicationEntities(),
					this.fetchFieldOptions()
				]);
			}
		},
		open(val: boolean) {
			// If model is re-opening then reset the view and forms
			if (val) {
				this.currentView = ComponentView.MainView;
				this.newCustomerForm = generateBlankNewCustomerForm();
			}

			this.$emit('input', val);
		}
	},
	methods: {
		hide(bvModalEvent: any) {
			if (this.isLoading) {
				bvModalEvent && bvModalEvent.preventDefault();
				return;
			}
		},
		async fetchApplicationEntities() {
			try {
				this.isLoading = true;

				const results = await AutoFactory.get(CustomersService).getCustomersRelatedToLoans(
					1,
					1000,
					(this.metadata ?? []).map((loan: ApplicationTemplateMetadata) => loan.proposedLoanId ?? 0)
				);
				
				this.applicationEntities = (results?.items ?? [])
					.filter(({ id }) => !this.ignoredCustomers.includes(id ?? 0));
			} finally {
				this.isLoading = false;
			}
		},
		async fetchFieldOptions() {
			await Promise.all([
				this.directStore.dispatch.CustomComponentProperties
					.loadSelectFieldOptions({ templateFieldId: TemplateDataField.BusinessState }),
				this.directStore.dispatch.CustomComponentProperties
					.loadSelectFieldOptions({ templateFieldId: TemplateDataField.BusinessIndustryCode })
			]);
		},
		async customerSelected(customer: Customer) {
			if (!customer.id) {
				return;
			}

			const dataObjectType = DataObjectUtils.customerTypeToDataObjectType(customer.type);

			if (!dataObjectType) {
				return;
			}

			try {
				this.isLoading = true;

				// Once a customer is selected, ensure we have the data object in the store since it is needed when managing customers
				const dataFieldMetadata = this.directStore.state.MultiLoanApplicationForm.renderData?.dataFieldIdsByType ?? {};
				await this.directStore.dispatch.MultiLoanApplicationForm.loadDataObjects({
					ids: [customer.id],
					type: dataObjectType,
					dataFieldMetadata
				});

				this.$emit('selected', customer);
			} finally {
				this.isLoading = false;
			}
		},
		async saveNewCustomer() {
			try {
				this.isLoading = true;

				const newCustomer = createCustomerObjectfromForm(this.newCustomerForm);

				if (!newCustomer) {
					this.isLoading = false;
					return;
				}

				const savedCustomer = await AutoFactory.get(CustomersService).create(newCustomer);
				await this.customerSelected(savedCustomer);
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error(err);
				this.isLoading = false;
			}
		}
	}
});
