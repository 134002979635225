import { PersonalCashAccountTypeSelect } from '../../../components/form-components/financial-subaccount-components/type-select-components/personal-cash-account-type-select';
import {
	PersonalInsuranceTypeSelect,
	PersonalLiabilityTypeSelect,
	PersonalRetirementAccountTypeSelect
} from '../../../components/form-components/financial-subaccount-components/type-select-components';
import ExtendedSelectField from '../../../components/form-components/simple-inputs/extended-select-field/extended-select-field';
import ExtendedTextField from '../../../components/form-components/simple-inputs/extended-text-field/extended-text-field';
import {
	CashAccountCustomFieldKey,
	CustomComponentType,
	LiabilitiyDueDateOption,
	LiabilityCustomFieldKey,
	LifeInsuranceCustomFieldKey,
	RetirementAccountCustomFieldKey
} from '../../../enums';

const getDefaultComponentsForOtherLiabilities = () => {
	return [
		ExtendedTextField.schema({
			key: LiabilityCustomFieldKey.DueTo,
			label: 'Due To',
			originalLabel: 'Due To'
		}),
		PersonalLiabilityTypeSelect.schema({}),
		ExtendedSelectField.schema({
			key: LiabilityCustomFieldKey.DueDate,
			label: 'Due Date',
			originalLabel: 'Due Date',
			data: {
				values: [
					{ label: 'Due in more than 12-months', value: LiabilitiyDueDateOption.MoreThan12Months },
					{ label: 'Due in the next 12-months', value: LiabilitiyDueDateOption.InNext12Months }
				]
			}
		})
	];
};

const getDefaultComponentsForCashAccounts = () => {
	return [
		ExtendedTextField.schema({
			key: CashAccountCustomFieldKey.Name,
			label: 'Name of Institution',
			originalLabel: 'Name of Institution'
		}),
		PersonalCashAccountTypeSelect.schema({})
	];
};

const getDefaultComponentsForLifeInsurance = () => {
	return [
		ExtendedTextField.schema({
			key: LifeInsuranceCustomFieldKey.NameOfInsuranceCompany,
			label: 'Name of Insurance Company',
			originalLabel: 'Name of Insurance Company'
		}),
		PersonalInsuranceTypeSelect.schema({})
	];
};

const getDefaultComponentsForRetirementAccounts = () => {
	return [
		ExtendedTextField.schema({
			key: RetirementAccountCustomFieldKey.NameOfInstitution,
			label: 'Name of Institution',
			originalLabel: 'Name of Institution'
		}),
		PersonalRetirementAccountTypeSelect.schema({})
	];
};

export const getDefaultComponents = (parentComponentType: CustomComponentType | null) => {
	switch (parentComponentType) {
		case CustomComponentType.otherLiabilities:
			return getDefaultComponentsForOtherLiabilities();
		case CustomComponentType.cashAccount:
			return getDefaultComponentsForCashAccounts();
		case CustomComponentType.lifeInsurance:
			return getDefaultComponentsForLifeInsurance();
		case CustomComponentType.retirementAccount:
			return getDefaultComponentsForRetirementAccounts();
		default:
			return [];
	}
};
