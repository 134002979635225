import { DataObject, TemplateDataField } from '@sageworks/dynamic-forms';
import { OnlinePortalDataFieldsService, AutoFactory, DataField } from '@sageworks/jpi';

export const dataFieldIdsByType: { [type in DataObject.TypeEnum]?: TemplateDataField[] } = {
	[DataObject.TypeEnum.BeneficialOwnerDetails]: [
		TemplateDataField.BeneficialOwnerDateUpdated,
		TemplateDataField.BeneficialOwnerPercent,
		TemplateDataField.BeneficialOwnerRoleType,
		TemplateDataField.BeneficialOwnerCompanyCustomerId,
		TemplateDataField.BeneficialOwnerOwnerCustomerId
	],
	[DataObject.TypeEnum.FinancialSubaccounts]: [
		TemplateDataField.SubaccountFinancialDataId,
		TemplateDataField.SubaccountFinancialStatement,
		TemplateDataField.SubaccountCustomer
	],
	[DataObject.TypeEnum.PersonalFinancial]: [TemplateDataField.PfsCustomer],
	[DataObject.TypeEnum.BusinessInvestments]: [
		TemplateDataField.BusinessInvestmentValue,
		TemplateDataField.BusinessInvestmentInvestmentEntityId,
		TemplateDataField.BusinessInvestmentOwnerFinancialDataId
	]
};

export const fetchPresetDataFields = async () => {
	const allDataFieldIds: TemplateDataField[] = [];
	Object.values(dataFieldIdsByType).forEach(x => {
		if (!x) return;

		allDataFieldIds.push(...x);
	});

	const dataFieldsService = AutoFactory.get(OnlinePortalDataFieldsService);
	const dataFields = (await dataFieldsService.getPaged(1, allDataFieldIds.length, undefined, undefined, false, allDataFieldIds)).items ?? [];

	const dataFieldsByType: { [type in DataObject.TypeEnum]?: DataField[] } = {};

	Object.keys(dataFieldIdsByType).forEach(x => {
		const objectType = x as DataObject.TypeEnum;
		const fieldIds = dataFieldIdsByType[objectType];

		if (!fieldIds) return;
		dataFieldsByType[objectType] = dataFields.filter(y => fieldIds.includes(y.templateDataFieldId as number));
	});

	return dataFieldsByType;
};
