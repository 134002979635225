import { defineModule, localActionContext } from 'direct-vuex';
import {
	OnlinePortalUser,
	AutoFactory,
	OnlinePortalUsersService,
	OnlinePortalUserPasswordChangeRequest,
	LoanOfficer,
	OnlinePortalLoanOfficersService,
	OnlinePortalInstitution
} from '@sageworks/jpi';
import { PendoUtils, UrlUtils } from '@/utils';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => localActionContext(context, UserModule);

export interface UserModuleState {
	user: OnlinePortalUser | null;
	proxyUser: LoanOfficer | null; // Loan officers can connect on behalf of an OnlinePortalUser
}
const UserModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			user: null,
			proxyUser: null
		} as UserModuleState;
	},
	mutations: {
		SET_USER(state, user: OnlinePortalUser | null) {
			state.user = user;
		},
		SET_PROXY_USER(state, proxyUser: LoanOfficer | null) {
			state.proxyUser = proxyUser;
		}
	},
	actions: {
		async loadCurrentUser(context): Promise<void> {
			const { state, commit } = actionContext(context);
			if (state.user || state.proxyUser) {
				return;
			}

			const usersService = AutoFactory.get(OnlinePortalUsersService);
			commit.SET_USER(await usersService.getCurrentUser());

			const loanOfficersService = AutoFactory.get(OnlinePortalLoanOfficersService);
			commit.SET_PROXY_USER(await loanOfficersService.getCurrentLoanOfficer());

			const institution: OnlinePortalInstitution = await UrlUtils.getOnlinePortalInstitutionFromUrl();
			if (state.proxyUser) {
				PendoUtils.initializePendoForLoanOfficerProxyUser(state.proxyUser, institution);
			} else if (state.user) {
				PendoUtils.initializePendoForOnlinePortalUser(state.user, institution);
			}
		},
		async patchCurrentUser(context, userPropertiesToOverride: OnlinePortalUser): Promise<void> {
			const { state, commit } = actionContext(context);
			// Copy store.state's user and override values with the user object passed to update
			let updateModel = {
				...state.user,
				...userPropertiesToOverride
			};

			const usersService = AutoFactory.get(OnlinePortalUsersService);
			const savedUser = await usersService.updateCurrentUser(updateModel);
			commit.SET_USER(savedUser);
		},
		async updateCurrentUserPassword(context, passwordChangeRequest: OnlinePortalUserPasswordChangeRequest): Promise<void> {
			const usersService = AutoFactory.get(OnlinePortalUsersService);
			await usersService.updateCurrentUserPassword(passwordChangeRequest);
		}
	},
	getters: {
		fullName(state): string {
			if (!state.user) return '';

			if (state.user.firstName === null || state.user.lastName === null) {
				return '';
			}

			const fullName: string = state.user.firstName + ' ' + state.user.lastName;
			return fullName.trim();
		},
		isLender(state): boolean {
			return !!state.proxyUser;
		},
		email(state): string | undefined {
			return state.user?.email ?? undefined;
		}
	}
});

export default UserModule;
