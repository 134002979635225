module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }

 for(var i=0; i < ctx.activeDataFields.length; i++) { ;
__p += '\n	<div id="fieldset-' +
((__t = (ctx.activeDataFields[i].id)) == null ? '' : __t) +
'" role="form" class="form-group">\n		<label for="' +
((__t = (ctx.activeDataFields[i].id)) == null ? '' : __t) +
'" class="d-block">' +
((__t = ( ctx.activeDataFields[i].name )) == null ? '' : __t) +
'</label>\n		<div>\n			<input\n			data-test-id="' +
((__t = (ctx.activeDataFields[i].id)) == null ? '' : __t) +
'"  \n			id="' +
((__t = (ctx.activeDataFields[i].id)) == null ? '' : __t) +
'" \n			ref="addNewEntityField" \n			type="' +
((__t = (ctx.activeDataFields[i].dataFieldType)) == null ? '' : __t) +
'" \n			class="form-control" \n			value="' +
((__t = (ctx.activeDataFields[i].data)) == null ? '' : __t) +
'">\n		</div>\n	</div>\n';
 } ;

return __p
}