import { CustomComponentType } from '@sageworks/dynamic-forms';
import round from 'lodash/round';
import moment from 'moment';

export function formatValue(value: any, componentSchema: any) {
	switch (componentSchema.type) {
		case CustomComponentType.extendedPercent:
			return formatPercent(value, componentSchema);
		case CustomComponentType.extendedCurrency:
			return formatCurrency(value);
		case CustomComponentType.extendedRadio:
			return formatRadio(value, componentSchema);
		case CustomComponentType.extendedSelectField:
		case CustomComponentType.collateralTypeSelect:
		case CustomComponentType.vehicleStateSelect:
		case CustomComponentType.proposedProductSelect:
		case CustomComponentType.appTypeSelect:
		case CustomComponentType.customerTypeSelect:
		case CustomComponentType.personalIncomeTypeSelect:
		case CustomComponentType.personalExpenseTypeSelect:
		case CustomComponentType.personalLiabilityTypeSelect:
		case CustomComponentType.personalCashAccountTypeSelect:
		case CustomComponentType.personalInvestmentTypeSelect:
		case CustomComponentType.personalPropertyTypeSelect:
		case CustomComponentType.applicantBusinessQuestion:
		case CustomComponentType.personalInsuranceTypeSelect:
		case CustomComponentType.personalRetirementAccountTypeSelect:
		case CustomComponentType.personalOtherAssetsTypeSelect:
			return formatSelect(value);
		case CustomComponentType.extendedDateTime:
			return formatDateTime(value, componentSchema);
		default:
			return value;
	}
}

function formatPercent(value: any, schema: any) {
	if (isNaN(value)) {
		return undefined;
	}

	value = parseFloat(value);

	// Second check if parseFloat fails to parse
	if (isNaN(value)) {
		return undefined;
	}

	const { decimalLimit } = schema;
	return round(value * 100, decimalLimit ?? 2);
}

// Note - this will only handle USD
function formatCurrency(value: any) {
	if (typeof value === 'string') {
		value = value.replace('$', '');
	}
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD'
	});

	if (isNaN(value)) {
		value = parseFloat(value);

		// Second check if parseFloat fails to parse
		if (isNaN(value)) return undefined;
	}

	return formatter.format(value);
}

function formatRadio(value: any, componentSchema: any) {
	const normalizedOptions = componentSchema.values.map((x: any) => {
		return {
			label: x.label,
			value: normalizeValue(x.value)
		};
	});

	const normalizedValue = normalizeValue(value);
	const matchingLabel = normalizedOptions.find((x: any) => x.value === normalizedValue)?.label;
	return matchingLabel ?? value;
}

function formatSelect(selectValue: { label?: string; value?: string } = {}) {
	if (selectValue == null) return selectValue;

	return selectValue.label ?? selectValue.value;
}

function formatDateTime(value: any, schema: any) {
	if (typeof value !== 'string') {
		return value;
	}

	let dateFormat = 'MM/dd/yyyy';
	if (schema.format != null) {
		dateFormat = schema.format;
	}

	const momentFormat = convertFormatToMoment(dateFormat);
	const result = moment.utc(value).format(momentFormat);
	return result === 'Invalid date' ? value : result;
}

function normalizeValue(value: any) {
	// If the value is a number, convert it to integer.  Fixes case where string '0' evaluates to true.
	if (typeof value !== 'boolean' && !isNaN(value)) {
		value = parseInt(value);
	}

	// Coerce a boolean using the double negation method and check for the edge case where value is string of 'false'
	return !(!value || value.toString() === 'false');
}

// Copied directly from formio
export function convertFormatToMoment(format: string) {
	return (
		format
			// Year conversion.
			.replace(/y/g, 'Y')
			// Day in month.
			.replace(/d/g, 'D')
			// Day in week.
			.replace(/E/g, 'd')
			// AM/PM marker
			.replace(/a/g, 'A')
			// Unix Timestamp
			.replace(/U/g, 'X')
	);
}
