import { CustomComponentType } from '../../../../enums/custom-component-type';
import EditableApiSelectOptions from '../../../configuration-components/editable-api-select-options/editable-api-select-options';

export default [
	{
		type: CustomComponentType.appTypeSelectOptions,
		input: true,
		label: 'Data Source Values',
		key: 'data.values',
		disableAddingRemovingRows: true,
		tooltip: 'Values to use as the data source. Labels are shown in the select field. Values are the corresponding values saved with the submission.',
		weight: 10,
		components: EditableApiSelectOptions.defaultComponents
	}
];
