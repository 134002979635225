import { StoreType } from '@/store';
import { FetchDataHookContext, UpdateDataHookContext, RemoveOnChangeHandlerHookContext, FetchDataHookOnChangeHandler } from '@sageworks/dynamic-forms';
import { DataFetchHelper } from './data-fetch-helper';
import { DataUpdateHelper } from './DataUpdateHelper';

export class ComponentDataHandler {
	private dataOnChangeHandlers = new Map<string, Set<FetchDataHookOnChangeHandler>>();

	constructor(private store: StoreType, private dataFetchHelper = new DataFetchHelper(store), private dataUpdateHelper = new DataUpdateHelper()) {}

	public createFetchDataHook() {
		return this.fetchDataHook.bind(this);
	}

	public createUpdateDataHook() {
		return this.updateDataHook.bind(this);
	}

	public createRemoveOnChangeHandlerHook() {
		return this.removeOnChangeHook.bind(this);
	}

	private async fetchDataHook({ fetchContext = {}, fetchType, cacheKey, onChange }: FetchDataHookContext, error: Function, success: Function) {
		try {
			// Check cache before fetching data
			if (cacheKey) {
				// Add handler to list to be called on next value change
				if (onChange) {
					this.addOnChangeHandler(cacheKey, onChange);
				}

				// Use cached value if exists
				const cacheItem = await this.store.dispatch.FormCache.getValue({ key: cacheKey });
				if (cacheItem) {
					success(cacheItem);
					return;
				}
			}
			// Fetch new value
			const data = await this.dataFetchHelper.getData(fetchType, fetchContext);

			// Add new value to cache
			if (cacheKey) {
				await this.store.dispatch.FormCache.setValue({ key: cacheKey, item: data });
				// Ignore the newly added handler
				const ignoredHandlers = onChange ? [onChange] : [];
				this.executeOnChangeHandlers(cacheKey, data, ignoredHandlers);
			}

			success(data);
		} catch (err) {
			error(err);
		}
	}

	private async updateDataHook({ updateContext, updateType, cacheKey }: UpdateDataHookContext, error: Function, success: Function) {
		try {
			const data = await this.dataUpdateHelper.updateData(updateType, updateContext);

			// Add new value to cache and execute all changed handlers
			if (cacheKey) {
				await this.store.dispatch.FormCache.setValue({ key: cacheKey, item: data });
				this.executeOnChangeHandlers(cacheKey, data);
			}

			success(data);
		} catch (err) {
			error(err);
		}
	}

	private async removeOnChangeHook({ cacheKey, onChangeHandler }: RemoveOnChangeHandlerHookContext, error: Function, success: Function) {
		const isRemoved = this.removeOnChangeHandler(cacheKey, onChangeHandler);

		if (isRemoved) {
			success();
		} else {
			error();
		}
	}

	private executeOnChangeHandlers(cacheKey: string, value: any, ignoredHandlers: FetchDataHookOnChangeHandler[] = []) {
		const handlers = this.dataOnChangeHandlers.get(cacheKey);

		if (handlers) {
			handlers.forEach(handler => {
				if (!ignoredHandlers.includes(handler)) {
					handler(value);
				}
			});
		}
	}

	private addOnChangeHandler(cacheKey: string, onChange: FetchDataHookOnChangeHandler) {
		const handlers = this.dataOnChangeHandlers.get(cacheKey) ?? new Set<FetchDataHookOnChangeHandler>();
		handlers.add(onChange);
		this.dataOnChangeHandlers.set(cacheKey, handlers);
	}

	private removeOnChangeHandler(cacheKey: string, onChange: FetchDataHookOnChangeHandler) {
		const handlers = this.dataOnChangeHandlers.get(cacheKey) ?? new Set<FetchDataHookOnChangeHandler>();
		const isRemoved = handlers.delete(onChange);
		this.dataOnChangeHandlers.set(cacheKey, handlers);

		return isRemoved;
	}
}
