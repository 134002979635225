
import { Component, Watch, Mixins } from 'vue-property-decorator';
import { ScaleMixin } from '@/mixins';
import { isSessionActive, USER_INACTIVITY_COUNTDOWN_LENGTH_SECONDS } from '@/utils/session-utils';
import { LocalStorageUtils } from '@/utils';
import { LocalStorageKeys } from '@/enums';

@Component({})
export default class SessionInactivityPrompt extends Mixins(ScaleMixin) {
	private remainingTimeToConfirm: number = USER_INACTIVITY_COUNTDOWN_LENGTH_SECONDS;
	private logoutInProgress: boolean = false;

	get showLogoutModal() {
		return this.directStore.state.SessionActivity.showLogoutModal;
	}

	get countdownIntervalId() {
		return this.directStore.state.SessionActivity.countdownIntervalId;
	}

	private confirmActiveSession() {
		document.title = document.title.replace(/Session Expiring\s*-\s*/, '');
		this.directStore.dispatch.SessionActivity.updateLastUserActionTime();
	}

	@Watch('showLogoutModal')
	private onShowLogoutModuleChange(val: boolean) {
		if (val === true) {
			document.title = 'Session Expiring - ' + document.title;
			this.$bvModal.show('sessionModal');
			this.remainingTimeToConfirm = USER_INACTIVITY_COUNTDOWN_LENGTH_SECONDS;
			this.directStore.commit.SessionActivity.SET_COUNTDOWN_INTERVAL_ID(
				setInterval(() => {
					if (
						this.remainingTimeToConfirm <= 0 ||
						LocalStorageUtils.getItem(LocalStorageKeys.userToken) == null // If another tab has logged out before this one
					) {
						this.logoutInProgress = true;
						this.directStore.dispatch.Authentication.logout();
						this.directStore.commit.SessionActivity.SET_COUNTDOWN_INTERVAL_ID(0);
					}
					if (isSessionActive()) {
						// If this or another tab has confirmed they want to stay logged in
						this.confirmActiveSession();
					}
					this.remainingTimeToConfirm -= 1;
				}, 1000)
			);
		} else {
			this.$bvModal.hide('sessionModal');
		}
	}
}
