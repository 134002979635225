import RadioComponent from 'formiojs/components/radio/Radio';
import editForm from './extended-radio.form';
import { CustomComponentType } from '../../../../enums';
import {
	getKey,
	attachWithChangeEvent,
	checkConditionalityAcrossForms,
	handleSetDefaultValue,
	shouldFieldUseOriginalConditionality
} from '../extended-field-helper';
import { shouldUpdateOriginalLabel, updateOriginalLabel } from '../../../../utils/data-field-metadata/data-field-metadata-utils';
import { FormioConditionalityUtils } from '../../../../utils/formio-conditionality-utils';

export default class ExtendedRadio extends RadioComponent {
	static schema(...extend: any) {
		return RadioComponent.schema(
			{
				type: CustomComponentType.extendedRadio,
				forceRequired: false,
				dataFieldId: null
			},
			...extend
		);
	}

	static get builderInfo() {
		return {
			title: 'Radio',
			group: '',
			weight: 10,
			schema: ExtendedRadio.schema()
		};
	}

	static editForm = editForm;

	// @ts-ignore
	set visible(value: boolean) {
		var isChanged = this.visible !== value;

		super.visible = value;

		if (isChanged) {
			this.setValue(this.dataValue);
		}
	}

	get visible() {
		return super.visible;
	}

	// @ts-ignore
	get key(): string {
		return getKey(this, super.key);
	}

	async attach(element: HTMLElement) {
		const superAttach = super.attach(element);

		superAttach.then(() => {
			attachWithChangeEvent(this);
		});

		if (shouldUpdateOriginalLabel(this)) {
			await updateOriginalLabel(this);
		}

		return superAttach;
	}

	conditionallyVisible(data: any): boolean {
		if (shouldFieldUseOriginalConditionality(this)) {
			return super.conditionallyVisible(data);
		}

		return checkConditionalityAcrossForms(this);
	}

	checkCondition(row: any, data: any) {
		return FormioConditionalityUtils.checkCondition(
			this.component,
			row || this.data,
			data || this.rootValue,
			this.root ? (this.root as any)._form : {},
			this
		);
	}

	/**
	 * Set the value at a specific index.
	 *
	 * @param index
	 * @param value
	 */
	setValueAt(index: number, value: number, flags: any = {}) {
		if (this.refs.input && this.refs.input[index] && value !== null && value !== undefined) {
			const inputValue = this.normalizeValue((this.refs.input[index] as HTMLInputElement).value);
			value = this.normalizeValue(value);
			(this.refs.input[index] as HTMLInputElement).checked = inputValue === value;
		}

		handleSetDefaultValue(this, index, value, flags);
	}

	normalizeValue(value: any) {
		const dataType = this.component.dataType || 'auto';

		if (value == null || value === this.emptyValue) {
			return value;
		}

		switch (dataType) {
			case 'auto':
				value = this.normalizeAuto(value);
				break;
			case 'string':
				value = this.normalizeString(value);
				break;
			// Will probably need to change this if we start supporting true numeric radios
			case 'number':
			case 'boolean':
				value = this.normalizeBoolean(value);
				break;
		}

		if (this.component.multiple && !Array.isArray(value)) {
			value = value ? [value] : [];
		}

		return value;
	}

	normalizeAuto(value: any) {
		if (!isNaN(parseFloat(value)) && isFinite(value)) {
			value = +value;
		}
		if (value === 'true') {
			value = true;
		}
		if (value === 'false') {
			value = false;
		}

		return value;
	}

	normalizeString(value: any) {
		if (typeof value === 'object') {
			value = JSON.stringify(value);
		} else {
			value = value.toString();
		}

		return value;
	}

	normalizeBoolean(value: any) {
		// If the value is a number, convert it to integer.  Fixes case where string '0' evaluates to true.
		if (typeof value !== 'boolean' && !isNaN(value)) {
			value = parseInt(value);
		}

		// Coerce a boolean using the double negation method and check for the edge case where value is string of 'false'
		return !(!value || value.toString() === 'false');
	}
}
