import { defineActions } from 'direct-vuex';
import dataObjectsPersistActions from './data-objects-persist-actions';
import lienPersistActions from './lien-persist-actions';
import pfsPersistActions from './pfs-persist-actions';
import primaryRolePersistActions from './primary-role-persist-actions';
import relatedRolePersistActions from './related-role-persist-actions';

export default defineActions({
	...dataObjectsPersistActions,
	...lienPersistActions,
	...primaryRolePersistActions,
	...relatedRolePersistActions,
	...pfsPersistActions
});
