import { CashAccountTypeOption, CustomComponentType } from '../../../enums';
import { FinancialSubaccountSelectOptions } from './financial-subaccount-type-select-options';

export class PersonalCashAccountTypeSelectOptions extends FinancialSubaccountSelectOptions {
	static schema(...extend: any) {
		return super.schema(
			{
				type: CustomComponentType.personalCashAccountTypeSelectOptions,
			},
			...extend
		);
	}

	static get builderInfo() {
		return {
			weight: 10,
			schema: PersonalCashAccountTypeSelectOptions.schema({})
		};
	}

	protected async getApiOptions(): Promise<any> {
		return {
			items: [
				{
					label: CashAccountTypeOption.Checking,
					value: CashAccountTypeOption.Checking,
				},
				{
					label: CashAccountTypeOption.Savings,
					value: CashAccountTypeOption.Savings,
				},
				{
					label: CashAccountTypeOption.MoneyMarket,
					value: CashAccountTypeOption.MoneyMarket,
				},
				{
					label: CashAccountTypeOption.CD,
					value: CashAccountTypeOption.CD,
				},
				{
					label: CashAccountTypeOption.Other,
					value: CashAccountTypeOption.Other,
				},
			]
		};
	}
}
