import { checkConditionalityAcrossForms, shouldFieldUseOriginalConditionality } from '../../simple-inputs/extended-field-helper';
import editForm from './modified-table-component.form';
import TableComponent from 'formiojs/components/table/Table';
import { FormioConditionalityUtils } from '../../../../utils/formio-conditionality-utils';

export default class ModifiedTableComponent extends TableComponent {
	static editForm = editForm;

	conditionallyVisible(data: any): boolean {
		if (shouldFieldUseOriginalConditionality(this)) {
			return super.conditionallyVisible(data);
		}

		return checkConditionalityAcrossForms(this);
	}

	checkCondition(row: any, data: any) {
		return FormioConditionalityUtils.checkCondition(
			this.component,
			row || this.data,
			data || this.rootValue,
			this.root ? (this.root as any)._form : {},
			this
		);
	}
}
