import editForm from './primary-borrower-entity.form';
import { CustomBuilderGroups, CustomComponentType, LoanRoleType } from '../../../enums';
import { PrimaryRoleRepeaterWidgetVue } from '../primary-role-repeater-widget/primary-role-repeater-widget-vue';

export default class PrimaryBorrowerEntityWidgetVue extends PrimaryRoleRepeaterWidgetVue {
	static schema(...extend: any) {
		return PrimaryRoleRepeaterWidgetVue.schema(
			{
				label: 'Primary Borrower',
				type: CustomComponentType.primaryBorrowerEntityVue,
				key: CustomComponentType.primaryBorrowerEntityVue,
				addAnother: 'Add a Primary Borrower'
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: 'Primary Borrower',
			group: CustomBuilderGroups.widgetsVue,
			weight: 10,
			schema: PrimaryBorrowerEntityWidgetVue.schema({})
		};
	}

	init() {
		this.roleType = LoanRoleType.PrimaryBorrower;
		super.init();
	}
}
