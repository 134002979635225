import { BaseEditTab} from './base.edit.tab';
import { IBaseWidgetConfigurationEditTabOptions } from './base.edit.tab.options';
import BaseEditConditionalComponents from './base.edit.conditional.components';

interface IBaseWidgetConfigurationEditConditionalOptions extends IBaseWidgetConfigurationEditTabOptions {}

export class BaseEditConditional extends BaseEditTab<IBaseWidgetConfigurationEditConditionalOptions> {
	public get tabKey(): string {
		return 'conditional';
	}

	public get label(): string {
		return 'Conditional';
	}

	public get weight(): number {
		return 40;
	}

	public getComponents(): any[]{
		return BaseEditConditionalComponents;
	} 

	public defaultOptions: IBaseWidgetConfigurationEditConditionalOptions = {
		beforeForm: false
	};
}
