
import Vue, { PropType } from 'vue';
import { ApplicationWizardCard, ApplicationSpinner } from '../../../components/application-wizard';
import { SubmitBody } from '../../../components/submit-page';
import { ApplicationPdf } from '../../../components/application-pdf';
import { ApplicationValidationModal } from '../../../components/application-validation-modal';
import { RecreateSignatureModal } from '../../../components/recreate-signature-modal';
import { AutoFactory, LoanApplication, LoanOfficer, OnlinePortalEmailService, Permission, RecipientSignatureStatus } from '@sageworks/jpi';
import { ApplicationValidation, ApplicationFormPdfCssStyleUtils } from '../../../utils';
import moment from 'moment';
import { PromiseHelper } from '@sageworks/core-logic';
import { OnlinePortalRoutes } from '../../../OnlinePortalRoutes';
import { ApplicationValidationStage } from '../../../enums';
import { ToApplicationTemplateType } from '../../../utils/loan-app-type-helper/LoanAppTypeHelper';
import { ApplicationSubmitValidationErrors, ApplicationSubmitValidationResults } from '../../../utils/application-validation';
import { isApplicationLocked } from '../../../logic/loan-application.service';
import { UsableProducts } from '@sageworks/jpi';
import { isInFinalStatus as isApplicationInFinalStatus } from '../../../utils/application-final-status-helper/application-final-status-helper';
import { mapGetters } from 'vuex';

export default Vue.extend({
	name: 'MultiLoanApplicationSubmit',
	components: {
		ApplicationWizardCard,
		ApplicationPdf,
		ApplicationSpinner,
		ApplicationValidationModal,
		RecreateSignatureModal,
		SubmitBody
	},
	props: {
		applicationId: {
			type: String as PropType<string | null>,
			default: null
		}
	},
	data() {
		return {
			isInitializing: false,
			isSubmitting: false,
			isValidating: false,
			isSendingESignEmails: false,
			skipApplicationSubmissionStatusValidation: false,
			isPdfLoaded: false,
			submissionSuccessful: undefined as boolean | undefined,
			eSignEmailSendingSucceeded: undefined as boolean | undefined,
			validator: undefined as ApplicationValidation.MultiLoanApplicationValidator | undefined,
			validationErrors: undefined as ApplicationSubmitValidationErrors | undefined,
			ApplicationValidationStage: ApplicationValidationStage,
			isBypassingSignatures: false,
			isBypassingReview: false
		};
	},
	computed: {
		...mapGetters('LoanApplicationMetadata', ['loanRequiresReview']),
		shouldMatchGeneralMessage(): boolean {
			return this.directStore.getters.InstitutionSettings.shouldMatchGeneralDocumentsPageMessage;
		},
		applicationIdAsNumber(): number | null {
			if (this.applicationId == null) {
				return null;
			}
			const id = Number.parseInt(this.applicationId, 10);
			if (Number.isNaN(id)) {
				return null;
			}
			return id;
		},
		applicationType(): LoanApplication.TypeEnum | null | undefined {
			return this.directStore.state.LoanApplicationData.loanApplication?.type;
		},
		currentUserIsLender(): boolean {
			return this.directStore.getters.User.isLender;
		},
		loading(): boolean {
			const hasMessage = this.directStore.getters.TermsAndConditions.termsAndConditionsMessageHtml != null;
			const hasLoadedBorrowerRecipients = this.directStore.getters.AdobeESignModule.adobeRecipientStatuses != null;

			return this.isInitializing || !hasMessage || (this.customerCanUseEsign && !hasLoadedBorrowerRecipients);
		},
		submittedDate(): Date | null {
			return this.directStore.getters.LoanApplicationData.loanApplicationSubmittedDate;
		},
		submittedDateText(): string {
			const date = this.submittedDate;
			if (!date) {
				return '';
			}
			return moment(date).format('MM/DD/YYYY');
		},
		loanApplication(): LoanApplication | null {
			return this.directStore.state.LoanApplicationData.loanApplication;
		},
		logoUrl(): string {
			return this.directStore.getters.StylingSettings.logoUrl;
		},
		renderData() {
			return this.directStore.state.PDFPreviewModule.renderData ?? [];
		},
		loanOfficer(): LoanOfficer | null {
			return this.directStore.state.LoanApplicationData.loanOfficer;
		},
		adobeAgreementCreated(): boolean {
			return this.directStore.getters.AdobeESignModule.adobeAgreementCreated;
		},
		allRecipientsHaveSignedAgreement(): boolean {
			return this.directStore.getters.AdobeESignModule.allRecipientsHaveSignedAgreement;
		},
		customerCanUseEsign(): boolean {
			return this.directStore.state.AdobeESignModule.customerCanUseEsign || false;
		},
		isLocked(): boolean {
			const loanApplication = this.directStore.state.LoanApplicationData.loanApplication;
			return loanApplication && isApplicationLocked(loanApplication);
		},
		loadingText(): string {
			return !this.currentUserIsLender && this.isLocked ? 'Submission successful, returning to home' : '';
		},
		currentValidationStage(): ApplicationValidationStage {
			return this.validator?.currentStage ?? ApplicationValidationStage.NotValidating;
		},
		validationResults(): ApplicationSubmitValidationResults {
			return this.validator?.stepResults ?? {};
		},
		canSubmitApplicationWithoutESignatures(): boolean {
			return this.directStore.getters.LenderAuthorizationModule.canAccessFeature(Permission.ProductFeatureEnum.SubmitApplicationWithoutESignatures);
		},
		itemLabel(): string {
			if (this.hasCommunityLendingSubscription === undefined) {
				return '';
			}
			return this.hasCommunityLendingSubscription ? 'Request Form' : 'Application';
		},
		applicationStatus(): LoanApplication.ApplicationStatusEnum | null {
			return this.directStore.state.LoanApplicationData.loanApplication?.applicationStatus ?? null;
		},
		canSubmitBeforeLenderReview(): boolean {
			return this.directStore.getters.LenderAuthorizationModule.canAccessFeature(Permission.ProductFeatureEnum.SubmitApplicationBeforeLenderReview);
		},
		canLenderMarkReadyForLenderReview(): boolean {
			return this.directStore.getters.LenderAuthorizationModule.canAccessFeature(Permission.ProductFeatureEnum.MarkApplicationReadyForLenderReview);
		},
		isInFinalStatus(): boolean {
			return isApplicationInFinalStatus(this.applicationStatus);
		},

		title(): string {
			if (!this.isInFinalStatus && !this.canSubmitBeforeLenderReview && this.loanRequiresReview) {
				return 'Review';
			}
			return 'Submit ' + this.itemLabel;
		},
		defaultPreSubmitMessage(): string {
			return 'Thank you for completing your ' + this.itemLabel + '! Once submitted, we will be in contact with next steps.';
		},
		defaultPreMarkForReviewMessage(): string {
			// eslint-disable-next-line max-len
			return 'Thank you for completing your request! Notify a lender by clicking on “Mark Ready for Review”. Once we review your request, we will be in contact with next steps.';
		},
		hasCommunityLendingSubscription(): boolean | undefined {
			return this.directStore.state.UsableProducts.usableProducts?.has('abrigoCommunityLending' as UsableProducts.ProductsEnum);
		},
		preSubmitMessage(): string {
			// If the setting to use general is not enabled, and there is a custom message, use the custom message for this application type
			if (!this.shouldMatchGeneralMessage && this.directStore.state.UserCreatedMessages.SubmitApplicationPreMessageHtml?.trim().length !== 0) {
				// If result is undefined or null, use the default message
				return this.directStore.state.UserCreatedMessages.SubmitApplicationPreMessageHtml ?? this.defaultPreSubmitMessage;
			}
			// Use the general message but if that is null use the default message
			if (this.shouldMatchGeneralMessage && this.directStore.state.InstitutionSettings.submitApplicationPreMessage.message?.trim().length !== 0) {
				// If result is undefined or null, use the default message
				return this.directStore.state.InstitutionSettings.submitApplicationPreMessage.message ?? this.defaultPreSubmitMessage;
			}
			return this.defaultPreSubmitMessage;
		},
		// Default post submit message is empty for now but easy to change later if we need to.
		defaultPostSubmitMessage(): string {
			return '';
		},
		postSubmitMessage(): string {
			// If the setting to use general is not enabled, and there is a custom message, use the custom message for this application type
			if (!this.shouldMatchGeneralMessage && this.directStore.state.UserCreatedMessages.SubmitApplicationPostMessageHtml?.trim().length !== 0) {
				return this.directStore.state.UserCreatedMessages.SubmitApplicationPostMessageHtml ?? this.defaultPostSubmitMessage;
			}
			// Use the general message but if that is null there is no message
			if (this.shouldMatchGeneralMessage && this.directStore.state.InstitutionSettings.submitApplicationPostMessage.message?.trim().length !== 0) {
				return this.directStore.state.InstitutionSettings.submitApplicationPostMessage.message ?? this.defaultPostSubmitMessage;
			}

			return this.defaultPostSubmitMessage;
		}
	},
	async mounted() {
		this.validator = new ApplicationValidation.MultiLoanApplicationValidator(this.directStore, this.applicationIdAsNumber ?? undefined);
		this.loadInitialData();
	},

	methods: {
		async loadInitialData() {
			this.isInitializing = true;
			this.isPdfLoaded = false;

			try {
				// Load loan application data
				await this.directStore.dispatch.LoanApplicationData.loadLoanApplication({
					loanApplicationId: this.applicationIdAsNumber as number
				});

				if (!this.applicationType || !this.applicationIdAsNumber) {
					throw new Error('loan application was not loaded');
				}

				// Load PDF data but do not wait for it, so we don't prevent the user from submitting
				// 	before the PDF is loaded
				this.loadPdfData();

				// Get the indicator of if we should use the general messages or the custom messages - if the custom messages, load them...
				await this.directStore.dispatch.InstitutionSettings.fetchShouldMatchGeneralDocumentsPageMessage({ applicationType: this.applicationType });
				if (!this.shouldMatchGeneralMessage) {
					await this.directStore.dispatch.UserCreatedMessages.fetchSubmitApplicationPreMessage(ToApplicationTemplateType(this.applicationType));
					await this.directStore.dispatch.UserCreatedMessages.fetchSubmitApplicationPostMessage(ToApplicationTemplateType(this.applicationType));
				}

				// Load submission data and settings
				await Promise.all([
					// Terms and conditions
					this.directStore.getters.TermsAndConditions.termsAndConditionsMessageHtml == null
						? this.directStore.dispatch.TermsAndConditions.fetchTermsAndConditionsMessage(ToApplicationTemplateType(this.applicationType))
						: Promise.resolve(),

					// Auto lock setting
					this.directStore.dispatch.InstitutionSettings.fetchApplicationAutoLockSettings(),

					// e-sign setting
					this.directStore.dispatch.AdobeESignModule.getUserCanUseEsign(this.applicationType),

					// phone auth setting
					this.directStore.dispatch.AdobeESignModule.getUserPhoneAuthEnabled(this.applicationType),

					// metadata and proposed products
					this.directStore.dispatch.LoanApplicationMetadata.loadMetadata({
						loanApplicationId: this.applicationIdAsNumber,
						ignoreFormioProperties: true
					}),
					this.directStore.dispatch.LenderAuthorizationModule.loadUserPermissions(),
					this.directStore.dispatch.UsableProducts.fetchUsableProducts()
				]);
			} catch (e) {
				this.showToast('Loading Failure', 'An error occurred while loading the form for submission.');
			}

			this.isInitializing = false;
		},
		async loadPdfData() {
			await Promise.all([
				this.directStore.dispatch.LoanApplicationData.fetchLoanOfficer(),
				this.directStore.dispatch.PDFPreviewModule.loadPdfData(this.applicationIdAsNumber as number)
			]);

			this.isPdfLoaded = true;
		},
		async submitApplication() {
			const validForSubmit = await this.validateForSubmit();
			if (!validForSubmit) {
				return;
			}
			this.isSubmitting = true;

			try {
				await this.directStore.dispatch.LoanApplicationData.submitApplication();
				await this.saveApplicationPdfToDocLibrary();

				if (!this.currentUserIsLender && this.isLocked) {
					await new Promise(resolve => setTimeout(resolve, 1000));
					this.$router.push(OnlinePortalRoutes.Home.path);
				}
				this.submissionSuccessful = true;
			} catch (error) {
				this.submissionSuccessful = false;
				this.isBypassingSignatures = false;
				this.validationErrors = {
					messages: [
						{
							title: 'Unable to Submit Application',
							message:
								'An error occurred during submission. Please contact your institution if you are unable to complete the submission process.'
						}
					],
					productsNotValid: []
				};
			}
			this.isSubmitting = false;
		},
		async markReadyForReview() {
			this.isBypassingSignatures = true;
			const validForReview = await this.validateForSubmit();
			if (!validForReview) {
				return;
			}
			this.isSubmitting = true;

			try {
				await this.directStore.dispatch.LoanApplicationData.updateApplicationStatus({
					applicationStatus: LoanApplication.ApplicationStatusEnum.LenderReview
				});

				const emailService = AutoFactory.get(OnlinePortalEmailService);
				emailService.sendHardcodedEmail('applicationReadyForReview', this.applicationIdAsNumber as number);

				this.submissionSuccessful = true;
			} catch (error) {
				this.submissionSuccessful = false;
				this.isBypassingSignatures = false;
				this.validationErrors = {
					messages: [
						{
							title: 'Unable to Submit for Review',
							message:
								'An error occurred during submission. Please contact your institution if you are unable to complete the submission process.'
						}
					],
					productsNotValid: []
				};
			}
			this.isSubmitting = false;
		},
		async cancelReview() {
			try {
				await this.directStore.dispatch.LoanApplicationData.updateApplicationStatus({
					applicationStatus: LoanApplication.ApplicationStatusEnum.ProfileComplete
				});
			} catch (error) {
				this.showToast('Error', 'Unable to cancel review request.');
			}
		},
		async saveApplicationPdfToDocLibrary() {
			if (this.applicationType === null) {
				return;
			}
			await this.directStore.dispatch.InstitutionSettings.fetchAutoSavePdfToDocLibOnSubmission({ applicationType: this.applicationType });
			const autoSavePdfToDocLib = this.directStore.getters.InstitutionSettings.autoSavePdfToDocLibOnSubmission;
			const isEsignEnabledButSignaturesAreIgnored = this.customerCanUseEsign && this.isBypassingSignatures;

			if (autoSavePdfToDocLib || isEsignEnabledButSignaturesAreIgnored) {
				const html = await this.generateHtmlForPdf();
				await this.directStore.dispatch.LoanApplicationData.saveApplicationPdfToDocumentLibrary({ html });
			}
		},
		async recreateSignaturePackage() {
			this.sendEmailsForSignatures(true);
		},
		// eslint-disable-next-line max-lines-per-function
		async sendEmailsForSignatures(recreateSignaturePackage: boolean = false) {
			try {
				if (this.recipientsAreMissingEmails()) {
					this.showToast('Warning', 'Email address is required to send for signature.');
					return;
				}

				if (this.directStore.state.AdobeESignModule.customerHasPhoneAuthEnabled && this.recipientsAreMissingPhoneNumbers()) {
					this.showToast('Warning', 'Phone Number is required to send for signature.');
					return;
				}

				// Lenders should be able to recreate signatures even after the application has already been submitted
				this.skipApplicationSubmissionStatusValidation = this.currentUserIsLender;

				// Opens the application validation modal, which will remain open until the end of this method/when the user closes it
				const validForSubmit = await this.validateForSubmit();
				if (!validForSubmit) {
					return;
				}

				this.isSendingESignEmails = true;
				const html = await this.generateHtmlForPdf();

				if (!recreateSignaturePackage) {
					await this.directStore.dispatch.AdobeESignModule.sendApplicationEmailsForSignatures({
						applicationId: (this.applicationIdAsNumber as number) as number,
						html
					});
				} else {
					await this.directStore.dispatch.AdobeESignModule.resendApplicationEmailsForSignatures({
						applicationId: (this.applicationIdAsNumber as number) as number,
						html
					});
				}

				await this.directStore.dispatch.AdobeESignModule.fetchLoanApplicationRecipientStatuses({ applicationId: this.applicationIdAsNumber as number });
				this.eSignEmailSendingSucceeded = true;
			} catch (error) {
				this.eSignEmailSendingSucceeded = false;
				this.validationErrors = {
					messages: [{ title: 'Unable to Send Emails', message: 'An error occurred while sending the signature emails.' }],
					productsNotValid: []
				};
			}

			this.isSendingESignEmails = false;
		},
		async readyForBorrowerToSubmit() {
			if (this.directStore.state.LoanApplicationData.loanApplication?.applicationStatus === LoanApplication.ApplicationStatusEnum.LenderReview) {
				try {
					await this.directStore.dispatch.LoanApplicationData.updateApplicationStatus({
						applicationStatus: LoanApplication.ApplicationStatusEnum.ReadyForBorrowerSubmission
					});
					await this.sendBorrowerEmail();
				} catch (error) {
					this.showToast('Error', 'Unable to mark ready for borrower submission.');
				}
			}
		},
		async generateHtmlForPdf() {
			// Need to wait to ensure all the data is loaded for the pdf, since we do not wait for the PDF to fully load
			// before allowing the user to submit the app
			await PromiseHelper.waitForConditionToBeMet(() => this.isPdfLoaded);

			// Wait one tick in order to allow the image logo to show in the HTML element
			await this.$nextTick();

			const htmlContent = (this.$refs['pdf'] as Vue).$el?.innerHTML || '';
			const stylesContent = ApplicationFormPdfCssStyleUtils.retrieveApplicationFormPdfStyles();
			return '<html><head><meta charset="utf-8"/>' + stylesContent + '</head><body>' + htmlContent + '</body></html>';
		},
		async showRecreateSignatureModal() {
			this.$root.$bvModal.show('recreate-signature-modal');
		},
		async sendBorrowerEmail() {
			await this.directStore.dispatch.LoanApplicationData.sendEmailLoanReadyForBorrowerSubmission({
				loanApplicationId: this.applicationIdAsNumber
			});
		},
		async validateForSubmit(): Promise<boolean> {
			this.$root.$bvModal.show('application-validation-process-modal');
			this.validationErrors = undefined;
			this.isValidating = true;

			if (!this.validator) {
				throw new Error('validator was not properly set up');
			}

			const validationErrors = await this.validator.validate(
				this.skipApplicationSubmissionStatusValidation,
				!this.currentUserIsLender && this.loanRequiresReview && !this.isInFinalStatus
			);

			// Wait 2 seconds so the user sees the validation steps before moving to submission or email sending
			// Only wait if we've failed validation
			if (Object.values(this.validationResults ?? {}).some(item => item === false)) {
				await new Promise(f => setTimeout(f, 2000));
			}

			this.validationErrors = validationErrors;
			this.isValidating = false;

			if (this.validationErrors?.messages.length === 0) {
				return true;
			}

			return false;
		},
		resetValidationData(): void {
			this.isSubmitting = false;
			this.isValidating = false;
			this.isSendingESignEmails = false;
			this.skipApplicationSubmissionStatusValidation = false;
			this.submissionSuccessful = undefined;
			this.eSignEmailSendingSucceeded = undefined;
			this.validationErrors = undefined;
		},
		recipientsAreMissingEmails(): boolean {
			let recipientStatuses = this.directStore.getters.AdobeESignModule.adobeRecipientStatuses;
			let invalidBorrowers = recipientStatuses.filter((r: RecipientSignatureStatus) => {
				return r.email === null || r.email === undefined || r.email === '';
			});
			return invalidBorrowers.length > 0;
		},
		recipientsAreMissingPhoneNumbers(): boolean {
			let recipientStatuses = this.directStore.getters.AdobeESignModule.adobeRecipientStatuses;
			let invalidBorrowers = recipientStatuses.filter((r: RecipientSignatureStatus) => {
				return r.phone === null || r.phone === undefined || r.phone === '';
			});
			return invalidBorrowers.length > 0;
		},
		showToast(title: string, message: string) {
			this.$root.$bvToast.toast(message, {
				title: title,
				solid: true,
				autoHideDelay: 3000,
				toaster: 'b-toaster-bottom-right'
			});
		},
		submitApplicationWithoutESignatures() {
			this.isBypassingSignatures = true;
			this.submitApplication();
		},
		submitApplicationBypassingReview() {
			this.isBypassingReview = true;
			this.submitApplicationWithoutESignatures();
		}
	}
});
