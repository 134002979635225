import { DataField } from '@sageworks/jpi';
import { TemplateDataField } from '../../../../../enums';
import { DataFieldConvertStrategy } from '../../data-field-convert-strategy';
import { DefaultDataFieldConverter } from '../default-data-field-converter';

export class InvestmentRealEstateDataFieldConverter implements DataFieldConvertStrategy {
	private readonly allowedFields = new Set<number>([TemplateDataField.RealEstatePropertyOwnership]);

	private readonly defaultDataFieldConverter = new DefaultDataFieldConverter();

	isFieldValid(dataField: DataField): boolean {
		return this.allowedFields.has(dataField.templateDataFieldId!);
	}

	convert(dataField: DataField, currentProperties: any) {
		const properties = { ...currentProperties };

		switch (dataField.templateDataFieldId) {
			case TemplateDataField.RealEstatePropertyOwnership:
				// This field needs to be force to percent type since custom table has this is a numeric type
				dataField.dataFieldType = DataField.DataFieldTypeEnum.Percent;
				break;
		}

		return this.defaultDataFieldConverter.convert(dataField, properties);
	}
}
