export enum FormioComponentType {
	// This list in incomplete - Add to it as needed
	Tabs = 'tabs',
	Columns = 'columns',
	Content = 'content',
	Fieldset = 'fieldset',
	HTML = 'htmlelement',
	Panel = 'panel',
	Table = 'table',
	Well = 'well',
	Form = 'form'
}
