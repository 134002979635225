
import { Component, Mixins } from 'vue-property-decorator';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import { ScaleMixin, RouteMixin } from '@/mixins';
import { ValidationUtils, UrlUtils } from '@/utils';
import { OnlinePortalRegistrationRequest } from '@sageworks/jpi';

@Component({
	components: {
		FontAwesomeIcon
	},
	validations: {
		password: {
			required,
			minLength: minLength(8),
			containsUppercase: ValidationUtils.containsUppercase,
			containsAlpha: ValidationUtils.containsAlpha,
			containsNumeric: ValidationUtils.containsNumeric
		},
		confirmation: {
			required,
			sameAs: sameAs('password')
		}
	}
})
export default class CreatePassword extends Mixins(ScaleMixin, RouteMixin) {
	private password: string = '';
	private confirmation: string = '';
	private showValidation: boolean = false;
	private errorMessage: string = '';
	private validationMessages: Map<string, string> = new Map([
		['password', 'Password must meet all requirements'],
		['confirmation', 'Passwords must match']
	]);
	private submitting = false;

	private clearValidation() {
		this.showValidation = false;
		this.errorMessage = '';
	}

	get userEmail() {
		return this.directStore.state.Login.userEmail;
	}

	private validationState(fieldName: string) {
		if (!this.showValidation || this.errorMessage) {
			return null;
		} else if (this.$v[fieldName].$invalid) {
			return false;
		}
		return true;
	}

	private getErrorMessage(err: any) {
		return err.status === 429
			? 'We have received too many requests for this action. Please try again in a few minutes.'
			: 'Error: Could not create account. An account may already exist using this email address.';
	}
	private async submit(): Promise<void> {
		this.showValidation = true;
		this.submitting = true;
		if (this.$v.$invalid) {
			this.submitting = false;
			return;
		}
		try {
			this.directStore.dispatch.Login.setUserPassword(this.password);
			await this.directStore.dispatch.Authentication.createUserAccount({
				username: this.directStore.state.Login.userEmail,
				firstName: this.directStore.state.Login.userFirstName,
				lastName: this.directStore.state.Login.userLastName,
				secret: this.password,
				subdomain: UrlUtils.getOnlinePortalSubdomain(),
				emailVerificationCode: this.directStore.state.Login.emailVerificationCode
			} as OnlinePortalRegistrationRequest);
			await this.directStore.dispatch.Authentication.login({ username: this.userEmail || undefined, secret: this.password });
			if (this.directStore.state.Authentication.onlinePortalUserToIdVerify) {
				await this.$router.push(this.OnlinePortalRoutes.IdentityProtection.path);
			} else {
				this.directStore.dispatch.Login.setUserEmail(null);
				this.directStore.dispatch.Login.setUserPassword(null);
				await this.$router.push(this.OnlinePortalRoutes.Home.path);
			}
		} catch (err) {
			this.errorMessage = this.getErrorMessage(err);

			this.submitting = false;
		}
	}
}
