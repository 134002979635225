import { defineModule } from 'direct-vuex';
import { Document as OnlinePortalDocument, DocumentPostRequest, AutoFactory, OnlinePortalDocumentsService } from '@sageworks/jpi';
import { CreateDocumentParams } from '@/models/store-action-params';
import { createDocumentPostRequestFromFile } from '@/logic/Document.service';
import { moduleActionContext } from '@/store/index';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => moduleActionContext(context, DocumentModule);

export interface DocumentModuleState {
	currentDocument: OnlinePortalDocument | null;
	currentDocumentContent: string | null;
	currentSelectedSection: string;
}
const DocumentModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			currentDocument: null,
			currentDocumentContent: null,
			currentSelectedSection: 'outstanding'
		} as DocumentModuleState;
	},
	mutations: {
		SET_CURRENT_DOCUMENT(state, newDocument: OnlinePortalDocument | null) {
			state.currentDocument = newDocument;
		},
		SET_CURRENT_DOCUMENT_CONTENT(state, documentContent: string | null) {
			state.currentDocumentContent = documentContent;
		},
		// TODO: Hook into the change event on the docs component tabs (Documents.vue) and update this value
		// it's annoying always being sent back to the 'outstanding' tab when navigating back
		// This should be done by Matt Taylor during the change over to the new bootstrap-vue tabs component
		SET_CURRENT_SELECTED_SECTION(state, newSelectedSection: string) {
			state.currentSelectedSection = newSelectedSection;
		}
	},
	actions: {
		async loadDocumentById(context, documentId?: number): Promise<void> {
			const { commit } = actionContext(context);
			if (!documentId) {
				commit.SET_CURRENT_DOCUMENT(null);
				return;
			}
			const documentsService = AutoFactory.get(OnlinePortalDocumentsService);
			commit.SET_CURRENT_DOCUMENT(await documentsService.getById(documentId));
			commit.SET_CURRENT_DOCUMENT_CONTENT(null);
		},
		async createDocument(context, newDocument: DocumentPostRequest): Promise<void> {
			const { commit } = actionContext(context);
			const documentsService = AutoFactory.get(OnlinePortalDocumentsService);
			let response = await documentsService.create(newDocument);
			commit.SET_CURRENT_DOCUMENT(response);
			commit.SET_CURRENT_DOCUMENT_CONTENT(null);
		},
		async createDocumentFromFile(context, params: CreateDocumentParams): Promise<void> {
			const { dispatch, rootGetters } = actionContext(context);
			const proposedLoanIds = params.proposedLoanId
				? [params.proposedLoanId]
				: params.associationCustomerId
				? rootGetters.LoanApplicationData.loansByCustomerId.get(params.associationCustomerId)
				: undefined;
			let docPostRequest = await createDocumentPostRequestFromFile(params, proposedLoanIds);
			try {
				await dispatch.createDocument(docPostRequest);
				return Promise.resolve();
			} catch (err) {
				return Promise.resolve(err);
			}
		},
		async downloadDocumentById(context, documentId: number): Promise<void> {
			const { commit } = actionContext(context);
			let documentsService = AutoFactory.get(OnlinePortalDocumentsService);
			let documentContent = await documentsService.getContentById(documentId);
			if (documentContent.content) {
				commit.SET_CURRENT_DOCUMENT_CONTENT(documentContent.content);
			} else {
				commit.SET_CURRENT_DOCUMENT_CONTENT(null);
			}
		}
	}
});

export default DocumentModule;
