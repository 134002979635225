
import Vue from 'vue';
import SbaFormFeatures from './SbaFormFeatures.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { SbaFormUtils, SageworksAnalystLinkUtils } from '../../../utils';
import { SbaForm, AnonymousBorrowerMinimumAccessService, AutoFactory } from '@sageworks/jpi';
import FormTypeEnum = SbaForm.FormTypeEnum;

export default Vue.extend({
	name: 'SbaFormDetails',
	components: {
		FontAwesomeIcon,
		SbaFormFeatures
	},
	props: {
		sbaFormId: {
			type: Number
		}
	},
	data() {
		return {};
	},
	computed: {
		sbaForm(): SbaForm | undefined {
			const { sbaForms = [] } = this.directStore.state.SbaForm;
			return sbaForms.find(x => x.id === this.sbaFormId);
		},
		isFormUnderReview(): boolean {
			return this.sbaForm?.status === SbaForm.StatusEnum.UnderReview;
		},
		formTitle(): string {
			if (!this.sbaForm?.formType) return '';
			return SbaFormUtils.getSbaFormTitle(this.sbaForm.formType);
		},
		isFormLenderOnly(): boolean {
			if (!this.sbaForm?.formType) return false;
			return SbaFormUtils.isFormTypeLenderOnly(this.sbaForm.formType);
		},
		disableFillOutFormButton(): boolean {
			return this.sbaForm?.status === SbaForm.StatusEnum.Waived;
		},
		isLender(): boolean {
			return this.directStore.getters.User.isLender;
		},
		statusLabel(): string {
			const { status, statusChangedDate, statusReason } = this.sbaForm ?? {};
			const statusLabel = status ? SbaFormUtils.getSbaFormStatusLabel(status) : '';

			switch (status) {
				case SbaForm.StatusEnum.Approved:
				case SbaForm.StatusEnum.Declined:
				case SbaForm.StatusEnum.Waived:
					return `${statusLabel} ${statusChangedDate} for reason: ${statusReason}`;
				default:
					return `Status: ${statusLabel}`;
			}
		},
		isFormCompleted(): boolean {
			switch (this.sbaForm?.status) {
				case SbaForm.StatusEnum.Approved:
				case SbaForm.StatusEnum.Declined:
				case SbaForm.StatusEnum.Waived:
					return true;
				default:
					return false;
			}
		}
	},
	methods: {
		async onFillOutFormClick(): Promise<void> {
			if (!this.sbaForm?.formType || !this.sbaForm?.id) {
				return;
			}

			let link;
			if (this.formUsesAnonymousBorrowerApp(this.sbaForm.formType)) {
				const service = AutoFactory.get(AnonymousBorrowerMinimumAccessService);
				link = await service.getLink(this.sbaForm.customerId, this.sbaForm.proposedLoanId ?? undefined, false);
			} else {
				link = SageworksAnalystLinkUtils.getLinkToFillOutSbaFormPage(this.sbaForm.formType, this.sbaForm.id);
			}
			window.open(link, '_blank');
		},

		formUsesAnonymousBorrowerApp(formType: FormTypeEnum): boolean {
			const formsInAnonymousBorrowerApp = [FormTypeEnum.Form1919BusinessInformationJan2024Revision, FormTypeEnum.PersonDemographicsInformation];
			return formsInAnonymousBorrowerApp.includes(formType);
		}
	}
});
