import { CustomComponentType, FinancialSubaccountParent } from '../../../enums';
import { FinancialSubaccountSelectOptions } from './financial-subaccount-type-select-options';

export class PersonalInvestmentTypeSelectOptions extends FinancialSubaccountSelectOptions {
	static schema(...extend: any) {
		return super.schema(
			{
				type: CustomComponentType.personalInvestmentTypeSelectOptions,
			},
			...extend
		);
	}

	static get builderInfo() {
		return {
			weight: 10,
			schema: PersonalInvestmentTypeSelectOptions.schema({})
		};
	}

	protected async getApiOptions(): Promise<any> {
		return {
			items: [
				{
					label: 'Public Stocks & Bonds',
					value: FinancialSubaccountParent.Stocks
				},
				{
					label: 'Mutual Funds',
					value: FinancialSubaccountParent.MutualFunds
				},
				{
					label: 'Other Liquid and Semi-Liquid Securities',
					value: FinancialSubaccountParent.OtherLiquidAssets
				}
			]
		};
	}
}
