import TextAreaComponent from 'formiojs/components/textarea/TextArea';
import editForm from './extended-text-area.form';
import { CustomComponentType } from '../../../../enums';
import {
	getKey,
	attachWithChangeEvent,
	checkConditionalityAcrossForms,
	handleSetDefaultValue,
	shouldInputFieldUseOriginalConditionality
} from '../extended-field-helper';
import { shouldUpdateOriginalLabel, updateOriginalLabel } from '../../../../utils/data-field-metadata/data-field-metadata-utils';
import { FormioConditionalityUtils } from '../../../../utils/formio-conditionality-utils';

export default class ExtendedTextArea extends TextAreaComponent {
	static schema(...extend: any) {
		return TextAreaComponent.schema(
			{
				type: CustomComponentType.extendedTextArea,
				forceRequired: false,
				dataFieldId: null
			},
			...extend
		);
	}

	static get builderInfo() {
		return {
			title: 'TextArea',
			group: '',
			weight: 10,
			schema: ExtendedTextArea.schema()
		};
	}

	static editForm = editForm;

	// @ts-ignore
	set visible(value: boolean) {
		var isChanged = this.visible !== value;

		super.visible = value;

		if (isChanged) {
			this.setValue(this.dataValue);
		}
	}

	get visible() {
		return super.visible;
	}

	// @ts-ignore
	get key(): string {
		return getKey(this, super.key);
	}

	async attach(element: HTMLElement) {
		const superAttach = super.attach(element);

		attachWithChangeEvent(this);

		if (shouldUpdateOriginalLabel(this)) {
			await updateOriginalLabel(this);
		}

		return superAttach;
	}

	conditionallyVisible(data: any): boolean {
		if (shouldInputFieldUseOriginalConditionality(this)) {
			return super.conditionallyVisible(data);
		}

		return checkConditionalityAcrossForms(this);
	}

	checkCondition(row: any, data: any) {
		return FormioConditionalityUtils.checkCondition(
			this.component,
			row || this.data,
			data || this.rootValue,
			this.root ? (this.root as any)._form : {},
			this
		);
	}

	/**
	 * Set the value at a specific index.
	 *
	 * @param index
	 * @param value
	 */
	setValueAt(index: number, value: number, flags: any = {}) {
		const result = super.setValueAt(index, value, flags);
		handleSetDefaultValue(this, index, value, flags);

		return result;
	}
}
