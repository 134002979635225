import { DataObjectsByType } from '../../models/data-objects-by-type';
import { DataFieldValue } from '@sageworks/jpi';
import { CustomComponentType, DataObject, LoanRoleType } from '../../enums';
import { ObjectPropertyValuesWithRoleType } from '../../models/object-property-values-with-role-type';
import { DataObjectsByTypeFactory } from '../data-objects-by-type-factory';

/**
 * Converts dynamic-form submissionData to jpi DataObjects (ObjectPropertyValues)
 */
export class DataObjectMapper {
	static readonly MAX_DEPTH: number = 1000;

	static readonly submissionDataPropertiesLookup: Record<string, DataObject.TypeEnum> = {
		[CustomComponentType.businessInfo]: DataObject.TypeEnum.Business,
		[CustomComponentType.personalInfo]: DataObject.TypeEnum.Person,
		[CustomComponentType.farmInfo]: DataObject.TypeEnum.Farm,
		[CustomComponentType.nonprofitInfo]: DataObject.TypeEnum.NonProfit,
		[CustomComponentType.relatedContacts]: DataObject.TypeEnum.Contact,
		// [CustomComponentType.portfolioLoan]: DataObject.TypeEnum.PortfolioLoan,
		[CustomComponentType.loans]: DataObject.TypeEnum.ProposedLoan,
		[CustomComponentType.collateralInfo]: DataObject.TypeEnum.Collateral,
		// [CustomComponentType.businessFinancial]: DataObject.TypeEnum.BusinessFinancial,
		// [CustomComponentType.personalFinancial]: DataObject.TypeEnum.PersonalFinancial,
		[CustomComponentType.financialSubaccount]: DataObject.TypeEnum.FinancialSubaccounts,
		[CustomComponentType.businessInvestment]: DataObject.TypeEnum.BusinessInvestments
	};

	static readonly roleTypeLookup: Record<string, string> = {
		[CustomComponentType.primaryBorrowerEntity]: LoanRoleType.PrimaryBorrower,
		[CustomComponentType.coBorrowerEntity]: LoanRoleType.CoBorrower,
		[CustomComponentType.authorizedSigner]: LoanRoleType.AuthorizedSigner,
		[CustomComponentType.coSignerEntity]: LoanRoleType.CoSigner,
		[CustomComponentType.creditApplicantEntity]: LoanRoleType.CreditApplicant,
		[CustomComponentType.trustee]: LoanRoleType.Trustee,
		[CustomComponentType.beneficialOwnership]: LoanRoleType.BeneficialOwner,
		[CustomComponentType.guarantorEntity]: LoanRoleType.Guarantor
	};

	static toDataObjectsByType(submissionData: any): DataObjectsByType {
		// TODO: change param:submissionData type from 'any' to ISubmissionData once we understand/define the shape

		const dataObjects: DataObjectsByType = DataObjectsByTypeFactory.createDataObjectByType();

		this.recurseAndPopulateDataObjectsByType(submissionData, dataObjects, 0, undefined);
		return dataObjects;
	}

	static recurseAndPopulateDataObjectsByType(data: any, dataObjectsByType: DataObjectsByType, depth: number, roleType?: string): void {
		if (data == null || dataObjectsByType == null) {
			return;
		}

		if (depth >= DataObjectMapper.MAX_DEPTH) {
			return;
		}

		Object.keys(data).forEach(key => {
			this.populateDataObject(key, data, dataObjectsByType, roleType);

			if (typeof data[key] === 'object') {
				const currentRoleType = DataObjectMapper.roleTypeLookup[key] ?? roleType;
				this.recurseAndPopulateDataObjectsByType(data[key], dataObjectsByType, depth++, currentRoleType);
			}
		});
	}

	static populateDataObject(key: string, data: any, dataObjectsByType: DataObjectsByType, roleType?: string): void {
		if (key == null || data == null || data[key] == null) {
			return;
		}

		const propName = this.submissionDataPropertiesLookup[key];
		if (propName == null || dataObjectsByType[propName] == null) {
			return;
		}

		if (Array.isArray(data[key])) {
			// @ts-ignore TS2531: Object is possibly 'null'. (Lies! I just checked for null and undefined)
			dataObjectsByType[propName] = dataObjectsByType[propName].concat(this.arrayToDataObject(data[key], roleType));
		} else if (typeof data[key] === 'object') {
			// @ts-ignore TS2531: Object is possibly 'null'. (Lies! I just checked for null and undefined)
			dataObjectsByType[propName].push(this.objectToDataObject(data[key], roleType));
		}
	}

	static arrayToDataObject(array: any[], roleType?: string): ObjectPropertyValuesWithRoleType[] {
		return array.map(d => {
			if (d.form == null) {
				throw Error('missing form object'); // data integrity will be iffy at best
			}

			return this.objectToDataObject(d.form, roleType);
		});
	}

	static objectToDataObject(component: any, roleType?: string): ObjectPropertyValuesWithRoleType {
		return {
			id: component.data?.id ?? undefined,
			parentLoanRoleId: component.data?.parentLoanRoleId ?? undefined,
			canEditRoleEntity: component.data?.canEditRoleEntity ?? undefined,
			roleType: roleType,
			dataFieldValues: Object.keys(component.data)
				.filter(key => {
					return /__id_[0-9]+__/.test(key);
				})
				.map(key => {
					const id = /__id_([0-9]+)__/.test(key) ? RegExp.$1 : 0;
					const rawValue = component.data[key];
					return { id: Number(id) as number, value: rawValue !== '' ? rawValue : null } as DataFieldValue;
				}) as Array<DataFieldValue>
		};
	}
}
