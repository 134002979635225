
import Vue from 'vue';
import { TuxInputText } from '@tuxedo/components/src/components';

export default Vue.extend({
	name: 'AddNewPersonForm',
	components: { TuxInputText },
	props: {
		firstName: String,
		lastName: String,
		email: String
	}
});
