import editForm from './cash-account.form';
import { CustomComponentType, CustomComponentLabel, CashAccountCustomFieldKey } from '../../../../enums';
import { FinancialSubaccount } from '../financial-subaccount/financial-subaccount';

export class CashAccountWidget extends FinancialSubaccount {
	static schema(...extend: any) {
		return FinancialSubaccount.schema(
			{
				label: CustomComponentLabel()[CustomComponentType.cashAccount],
				type: CustomComponentType.cashAccount,
				key: CustomComponentType.cashAccount,
				addAnother: 'Add Cash Account'
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: CustomComponentLabel()[CustomComponentType.cashAccount],
			group: '',
			weight: 10,
			schema: CashAccountWidget.schema()
		};
	}

	protected getFormattedDescription(_: string, row: any) {
		return `${row.data?.[CashAccountCustomFieldKey.Name]} - ${row.data?.[CashAccountCustomFieldKey.AccountType]}`;
	}
}
