import { defineModule, localActionContext } from 'direct-vuex';
import { AutoFactory, OnlinePortalBorrowerAccessRestrictionsService, OnlinePortalUserBorrowerAccessMapping } from '@sageworks/jpi';
import RelationshipEnum = OnlinePortalUserBorrowerAccessMapping.RelationshipTypeEnum;

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => localActionContext(context, BorrowerAccessRestrictionsModule);

export interface BorrowerAccessRestrictionsModuleState {
	currentUserBorrowerAccessMappings: OnlinePortalUserBorrowerAccessMapping[] | null;
	currentUserWritableEntities: number[] | null;
}
const BorrowerAccessRestrictionsModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			currentUserBorrowerAccessMappings: null,
			currentUserWritableEntities: null
		} as BorrowerAccessRestrictionsModuleState;
	},
	mutations: {
		SET_CURRENT_USER_BORROWER_ACCESS_MAPPINGS(state, currentUserBorrowerAccessMappings: OnlinePortalUserBorrowerAccessMapping[]) {
			state.currentUserBorrowerAccessMappings = currentUserBorrowerAccessMappings;
		},
		SET_CURRENT_USER_WRITABLE_ENTITIES(state, currentUserWritableEntities: number[]) {
			state.currentUserWritableEntities = currentUserWritableEntities;
		}
	},
	actions: {
		async fetchCurrentUserBorrowerAccessMappings(context): Promise<void> {
			const { commit } = actionContext(context);

			const onlinePortalBorrowerAccessRestrictionsService = AutoFactory.get(OnlinePortalBorrowerAccessRestrictionsService);
			const mappings = await onlinePortalBorrowerAccessRestrictionsService.getCurrentUserBorrowerAccessMappings();
			commit.SET_CURRENT_USER_BORROWER_ACCESS_MAPPINGS(mappings);
		},
		async fetchCurrentUserWritableEntities(context): Promise<void> {
			const { commit, state, dispatch } = actionContext(context);

			if (state.currentUserBorrowerAccessMappings === null) {
				await dispatch.fetchCurrentUserBorrowerAccessMappings();
			}

			if (!state.currentUserBorrowerAccessMappings) {
				throw new Error('Failed to load access settings for current user');
			}
			const writableEntities = state.currentUserBorrowerAccessMappings
				.filter(
					(mapping: OnlinePortalUserBorrowerAccessMapping) =>
						mapping.relationshipType === RelationshipEnum.IsProfitEntity ||
						mapping.relationshipType === RelationshipEnum.HasWriteAccessToProfitEntity
				)
				.map((mapping: OnlinePortalUserBorrowerAccessMapping) => mapping.entityId ?? 0);
			commit.SET_CURRENT_USER_WRITABLE_ENTITIES(writableEntities);
		}
	}
});

export default BorrowerAccessRestrictionsModule;
