import { CustomComponentType } from '../../../../../enums';
import { BaseEditTab } from '../../../../configuration-components/base-widget-configuration/base.edit.tab';
import { IBaseWidgetConfigurationEditTabOptions } from '../../../../configuration-components/base-widget-configuration/base.edit.tab.options';
import EditableApiSelectOptions from '../../../../configuration-components/editable-api-select-options/editable-api-select-options';
import ForceRequiredField from '../../../../configuration-components/force-required/force-required';

export interface ISubaccountConfigurationEditDataOptions extends IBaseWidgetConfigurationEditTabOptions  {
	optionsComponentType?: CustomComponentType,
}

export class SubaccountEditData extends BaseEditTab<ISubaccountConfigurationEditDataOptions> {

	public get tabKey(): string {
		return 'data';
	}

	public get label(): string {
		return 'Data';
	}

	public get weight(): number {
		return 10;
	}

	public get defaultOptions(): ISubaccountConfigurationEditDataOptions {
		return { beforeForm: true };
	}

	public getComponents(customOptions: ISubaccountConfigurationEditDataOptions): any[] {
		const components = [
			...ForceRequiredField,
			{
				type: customOptions.optionsComponentType,
				input: true,
				label: 'Data Source Values',
				key: 'data.values',
				disableAddingRemovingRows: true,
				// eslint-disable-next-line max-len
				tooltip: 'Values to use as the data source. Labels are shown in the select field. Values are the corresponding values saved with the submission.',
				weight: 10,
				components: EditableApiSelectOptions.defaultComponentsHiddenValue
			}
		];

		return components;
	}
}