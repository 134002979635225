import { CustomComponentType } from '../../../../enums';
import Component from 'formiojs/components/_classes/component/Component';
import { IPopupBodyComponent } from '../../popup';
import { EncodingHelper } from '@sageworks/core-logic';

export default class ApplicantBusinessConfirmRemovePopup extends Component implements IPopupBodyComponent {
	public preClose(): Promise<void> {
		return Promise.resolve();
	}

	public static schema(...extend: any) {
		return Component.schema(
			{
				type: CustomComponentType.applicantBusinessConfirmRemovePopup,
				key: CustomComponentType.applicantBusinessConfirmRemovePopup,
				input: false,
				businessName: ''
			},
			...extend
		);
	}

	public readonly encodeHtml = EncodingHelper.encodeHtml;
	public resultPromise!: Promise<void>;
	public isLoading = false;

	private resultPromiseResolve!: (result: any) => void;

	private get template() {
		return {
			body: `
				<div class="mb-3">
					Are you sure you would like to remove {{ instance.encodeHtml(ctx.businessName) }} as 
					the applicant business? This will not remove {{ instance.encodeHtml(ctx.businessName) }}
					from the application.
				</div>
				<div>
					Removing {{ instance.encodeHtml(ctx.businessName) }} as the applicant business will 
					remove its SBA forms from the Documentation section. Before submitting
					the applicant, another business must be selected as the applicant business.
				</div>
			`,
			footer: `
				<button class="btn btn-primary" ref="appBusinessRemoveRemoveButton">Remove</button>
				<button class="btn btn-primary" ref="appBusinessRemoveCancelButton">Cancel</button>
			`
		};
	}

	public init() {
		this.resultPromise = new Promise(resolve => (this.resultPromiseResolve = resolve));
	}

	public render(): any {
		const body = this.renderString(this.template.body, {
			businessName: this.component.businessName
		});
		const footer = this.renderString(this.template.footer, {});

		return super.render(
			this.renderTemplate('styledDialog', {
				body,
				footer
			})
		);
	}

	public async attach(element: any) {
		const attachResult = await super.attach(element);

		this.loadRefs(element, {
			appBusinessRemoveRemoveButton: 'single',
			appBusinessRemoveCancelButton: 'single'
		});

		const { appBusinessRemoveRemoveButton: removeButton, appBusinessRemoveCancelButton: cancelButton } = this.refs;

		this.removeEventListener(removeButton, 'click');
		this.addEventListener(removeButton as any, 'click', this.onRemoveClick.bind(this));

		this.removeEventListener(cancelButton, 'click');
		this.addEventListener(cancelButton as any, 'click', this.onCancelClick.bind(this));

		return attachResult;
	}

	private onRemoveClick() {
		this.resultPromiseResolve(true);
	}

	private onCancelClick() {
		this.resultPromiseResolve(false);
	}
}
