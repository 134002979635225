
import Vue, { PropType } from 'vue';
import Multiselect from 'vue-multiselect';
import { DateFormatting } from '@sageworks/core-logic';
import {
	AutoFactory,
	DocumentLibrarySavePdfFromHtmlRequestOptions,
	OnlinePortalLoanApplicationsService,
	SaveToDocLibraryFolderOption,
	SaveToDocLibraryOptionEntry
} from '../../../../../JPI';
import { required } from 'vuelidate/lib/validators';
import { DocumentFolderOptionsGroupedByCategory } from '@/models/DocumentFoldersGroupedByCategory';
import { getDefaultFolder, getFoldersGroupedByCategory } from '@/utils/save-to-document-library/save-to-document-library-helper';
export default Vue.extend({
	name: 'SaveToDocLibraryModal',
	components: { Multiselect },
	validations: {
		selectedDocumentName: {
			required
		},
		selectedDocumentEntityId: {
			required
		},
		selectedDocumentFolder: {
			required
		}
	},
	props: {
		applicationId: {
			type: Number as PropType<number>,
			required: true
		}
	},
	data() {
		return {
			dataIsLoaded: false,
			entityList: [] as SaveToDocLibraryOptionEntry[],
			folderListGroupedByCategory: [] as DocumentFolderOptionsGroupedByCategory[],
			loanList: [] as SaveToDocLibraryOptionEntry[],
			collateralList: [] as SaveToDocLibraryOptionEntry[],
			workflowList: [] as SaveToDocLibraryOptionEntry[],
			documentNamesList: [] as SaveToDocLibraryOptionEntry[],
			allowFreeTypeDocumentNames: false,
			selectedDocumentEntityId: null as number | null | undefined,
			selectedDocumentFolder: null as SaveToDocLibraryFolderOption | null | undefined,
			selectedLoans: [] as SaveToDocLibraryOptionEntry[],
			selectedCollateral: [] as SaveToDocLibraryOptionEntry[],
			selectedWorkflows: [] as SaveToDocLibraryOptionEntry[],
			selectedDocumentName: null as SaveToDocLibraryOptionEntry | string | null | undefined,
			selectedDocumentDate: '',
			validationMessages: new Map([
				['selectedDocumentName', 'A document name is required'],
				['selectedDocumentEntityId', 'A customer is required'],
				['selectedDocumentFolder', 'A folder is required']
			]),
			showValidation: false
		};
	},
	computed: {},
	mounted() {
		this.initData();
	},
	methods: {
		async initData() {
			const optionsResult = await AutoFactory.get(OnlinePortalLoanApplicationsService).getSaveApplicationToDocLibraryOptions(this.applicationId);

			this.entityList = optionsResult.entities ?? [];
			if (this.entityList.length > 0) {
				this.selectedDocumentEntityId = this.entityList[0].rowId ?? undefined;
			}
			this.loanList = optionsResult.proposedBankLoans ?? [];
			this.selectedLoans = [...(optionsResult.proposedBankLoans ?? [])];

			this.collateralList = optionsResult.bankLoanCollateral ?? [];
			this.selectedCollateral = [...(optionsResult.bankLoanCollateral ?? [])];

			this.selectedDocumentDate = DateFormatting.formatDateForJpi(new Date());

			this.workflowList = optionsResult.workflows ?? [];
			this.folderListGroupedByCategory = getFoldersGroupedByCategory(optionsResult.documentFolders ?? []);

			this.setDefaultFolder(optionsResult.defaultFolderId);

			this.documentNamesList = optionsResult.documentNames ?? [];
			this.allowFreeTypeDocumentNames = optionsResult.allowFreeTypeDocumentNames ?? false;
			this.$v.selectedDocumentName.$model = this.allowFreeTypeDocumentNames ? 'Loan Application' : undefined;

			this.dataIsLoaded = true;
		},
		submit(e: any) {
			e.preventDefault();
			this.showValidation = true;
			if (this.$v.$invalid) {
				return;
			}
			this.$emit('save-to-doc-library', {
				documentName: this.selectedDocumentName?.label ?? this.$v.selectedDocumentName.$model,
				profitEntitiesId: this.selectedDocumentEntityId,
				proposedBankLoans: this.selectedLoans.map(loan => loan.rowId),
				bankLoanCollateral: this.selectedCollateral.map(collateral => collateral.rowId),
				workflows: this.selectedWorkflows.map(workflow => workflow.rowId),
				documentFolderId: this.selectedDocumentFolder?.id ?? 0,
				documentDate: this.selectedDocumentDate,
				documentNamesId: this.selectedDocumentName?.rowId ?? undefined
			} as DocumentLibrarySavePdfFromHtmlRequestOptions);
		},
		setDefaultFolder(folderId: number | null | undefined) {
			this.selectedDocumentFolder = getDefaultFolder(folderId, this.folderListGroupedByCategory);
		},
		validationState(fieldName: string) {
			if (!this.showValidation) {
				return null;
			} else if (this.$v[fieldName].$invalid) {
				return false;
			}
			return true;
		}
	}
});
