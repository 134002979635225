import { Templates } from 'formiojs';
import FormioBootstrap from './formio-bootstrap';
import BootstrapTemplate from 'formiojs/templates/bootstrap';
import { CustomTemplateFramework } from '../enums';

export default class TemplateManager {
	static CustomTemplateFrameworks: { [key in CustomTemplateFramework]: BootstrapTemplate } = {
		[CustomTemplateFramework.formioBootstrap]: FormioBootstrap
	};

	static addCustomBootstrapTemplate() {
		Object.keys(TemplateManager.CustomTemplateFrameworks).forEach(key => {
			const frameworkKey = key as CustomTemplateFramework;
			Templates.setTemplate(frameworkKey, TemplateManager.CustomTemplateFrameworks[frameworkKey]);
		});

		Templates.framework = TemplateManager.defaultFramework;
	}

	static defaultFramework = CustomTemplateFramework.formioBootstrap;
}
