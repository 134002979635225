import { CustomComponentType } from '../../../enums/custom-component-type';
import { CollectionResponseDataFieldOption, DataFieldOption } from '@sageworks/jpi';
import EditableApiSelectOptions from '../editable-api-select-options/editable-api-select-options';
import { getCollateralTypeOptions } from './collateral-type-options.helper';

export default class CollateralTypeSelectOptions extends EditableApiSelectOptions {
	static schema(...extend: any) {
		return super.schema(
			{
				type: CustomComponentType.collateralTypeSelectOptions
			},
			...extend
		);
	}

	static get builderInfo() {
		return {
			weight: 10,
			schema: CollateralTypeSelectOptions.schema({})
		};
	}

	protected async getApiOptions(): Promise<CollectionResponseDataFieldOption> {
		return await getCollateralTypeOptions();
	}

	protected getOptionLabelForItem(item: DataFieldOption): string {
		return item.label ?? '';
	}

	protected getOptionValueForItem(item: DataFieldOption): string {
		return (item.value as unknown) as string;
	}
}
