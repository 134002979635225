import { OnlinePortalCss } from '@sageworks/jpi';
import { defineModule, localActionContext } from 'direct-vuex';
import { CustomCssUtils, StylingSettingsHelper, ObjectUtils } from '@/utils';
import { StylingConstants } from '@/enums/styling-constants';
import { Route } from 'vue-router';

export interface StylingSettingsModuleState {
	stylingSettings: Record<OnlinePortalCss.CssCategoryEnum, OnlinePortalCss>;
	activeCssCategory: OnlinePortalCss.CssCategoryEnum | null;
}

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => localActionContext(context, StylingSettingsModule);

const StylingSettingsModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			stylingSettings: {} as Record<OnlinePortalCss.CssCategoryEnum, OnlinePortalCss>,
			activeCssCategory: null
		} as StylingSettingsModuleState;
	},
	mutations: {
		SET_STYLING_SETTINGS(state, stylingSettings: Record<OnlinePortalCss.CssCategoryEnum, OnlinePortalCss>) {
			state.stylingSettings = stylingSettings;
		},
		SET_ACTIVE_CSS_CATEGORY(state, activeCssCategory: OnlinePortalCss.CssCategoryEnum) {
			state.activeCssCategory = activeCssCategory;
			if (activeCssCategory in state.stylingSettings) {
				CustomCssUtils.createAndAttachCustomCssElement(state.stylingSettings[activeCssCategory]);
			} else {
				CustomCssUtils.createAndAttachCustomCssElement(state.stylingSettings.generalPortal);
			}
		}
	},
	actions: {
		async fetch(context): Promise<void> {
			const { commit } = actionContext(context);
			let allPortalCss: OnlinePortalCss[];

			try {
				allPortalCss = (await CustomCssUtils.fetchAllCss()) || [];
			} catch (err) {
				allPortalCss = [];
			}

			let stylingSettingsDictionary = {} as Record<OnlinePortalCss.CssCategoryEnum, OnlinePortalCss>;
			allPortalCss.forEach((stylingSetting: OnlinePortalCss) => {
				stylingSettingsDictionary[stylingSetting.cssCategory] = stylingSetting;
			});
			commit.SET_STYLING_SETTINGS(stylingSettingsDictionary);
		},
		async refreshStyling(context, route: Route) {
			const { state, commit, dispatch } = actionContext(context);
			if (ObjectUtils.isEmptyObject(state.stylingSettings)) {
				await dispatch.fetch();
			}
			let activeRouteCssCategory: OnlinePortalCss.CssCategoryEnum = await StylingSettingsHelper.getCssCategoryForRoute(route);
			if (activeRouteCssCategory !== state.activeCssCategory) {
				commit.SET_ACTIVE_CSS_CATEGORY(activeRouteCssCategory);
			}
		}
	},
	getters: {
		logoUrl(state): string {
			if (!state.activeCssCategory) return StylingConstants.fallbackLogoUrl;
			if (state.activeCssCategory in state.stylingSettings) {
				return state?.stylingSettings[state.activeCssCategory]?.logoUrl || StylingConstants.fallbackLogoUrl;
			} else {
				return state?.stylingSettings?.generalPortal?.logoUrl || StylingConstants.fallbackLogoUrl;
			}
		}
	}
});

export default StylingSettingsModule;
