import { OnlinePortalDocumentRequest, DocumentRequestCard, DocumentRequestTypes } from '@/models';
import {
	AutoFactory,
	DocumentRequest,
	OnlinePortalDocumentRequestsService,
	OnlinePortalLoanApplicationDocumentRequestsService,
	OnlinePortalApplicationDocumentRequestsService,
	OnlinePortalDocumentsService,
	LoanApplicationDocumentRequest,
	ApplicationDocumentRequest
} from '@sageworks/jpi';
import { NavigationCardGroup } from '@/models/NavigationCardGroup';
import VueRouter from 'vue-router';
import { OnlinePortalRoutes } from '@/OnlinePortalRoutes';

function transformDocRequestToCard(
	docRequestToConvert: OnlinePortalDocumentRequest,
	iconName: string,
	iconStyle: string,
	requestCompletionType: string,
	isLenderView: boolean,
	router?: VueRouter,
	adobeTemplateStatuses?: []
): DocumentRequestCard {
	let onClick: Function = function() {};
	if (router) {
		onClick = function() {
			router.push({ name: OnlinePortalRoutes.DocumentRequestDetails.name, params: { documentRequestId: docRequestToConvert.id!.toString() || '' } });
		};
	}
	let messageCondition: Function = () => true;
	return {
		onClickFunction: onClick,
		messageCondition,
		primaryMessage: getCustomerFacingName(docRequestToConvert),
		secondaryMessage: '',
		filterTag: getFilterTag(docRequestToConvert, adobeTemplateStatuses),
		icon: getIcon(iconName, iconStyle),
		id: docRequestToConvert.id,
		internalDocumentName: docRequestToConvert.documentName,
		isLenderView: isLenderView,
		requestCompletionType: requestCompletionType
	} as DocumentRequestCard;
}

function getFilterTag(docRequestToConvert: OnlinePortalDocumentRequest, adobeTemplateStatuses?: []) {
	let isAdobeTemplate = adobeTemplateStatuses[docRequestToConvert.id] !== 'NOT_A_VALID_ADOBE_TEMPLATE';

	return ((docRequestToConvert.isOpenRequest) ||
		(isAdobeTemplate &&
			adobeTemplateStatuses[docRequestToConvert.id] !== 'SIGNED') ? ['outstanding'] : ['completed']);
}

function getCustomerFacingName(docRequestToConvert: OnlinePortalDocumentRequest) {
	return docRequestToConvert.customerFacingName ? docRequestToConvert.customerFacingName : docRequestToConvert.documentName;
}

function getIcon(iconName: string, iconStyle: string) {
	return {
		color: 'text-primary',
		size: 'lg',
		name: iconName,
		style: iconStyle
	};
}

function combineDocumentRequests(
	documentRequests: DocumentRequest[],
	loanApplicationDocumentRequests: LoanApplicationDocumentRequest[],
	dynamicApplicationDocumentRequests: ApplicationDocumentRequest[],
	loanApplicationIdsToFilterOn?: number[]
) {
	let onlinePortalDocumentRequests: OnlinePortalDocumentRequest[] = [];

	for (let request of documentRequests) {
		onlinePortalDocumentRequests.push(new OnlinePortalDocumentRequest(request));
	}
	for (let request of loanApplicationDocumentRequests) {
		if (!loanApplicationIdsToFilterOn || (loanApplicationIdsToFilterOn && loanApplicationIdsToFilterOn.includes(request.applicationId))) {
			onlinePortalDocumentRequests.push(new OnlinePortalDocumentRequest(request));
		}
	}
	for (let request of dynamicApplicationDocumentRequests) {
		if (
			!loanApplicationIdsToFilterOn ||
			(loanApplicationIdsToFilterOn && request.loanApplicationId && loanApplicationIdsToFilterOn.includes(request.loanApplicationId))
		) {
			onlinePortalDocumentRequests.push(new OnlinePortalDocumentRequest(request));
		}
	}

	return onlinePortalDocumentRequests;
}

export function getStatusMessageForDocumentRequest(documentRequest: OnlinePortalDocumentRequest) {
	let docStatusMessage = '';

	if (documentRequest.dateUploaded) {
		docStatusMessage = 'Uploaded ' + documentRequest.dateUploaded;
	} else if (documentRequest.dateWaived && documentRequest.waivedReason === 'Document on file') {
		docStatusMessage = 'Marked on file ' + documentRequest.dateWaived;
	} else if (documentRequest.dateWaived) {
		docStatusMessage = 'Waived ' + documentRequest.dateWaived + ' for reason: ' + documentRequest.waivedReason;
	}

	if (documentRequest.documentRequestType !== DocumentRequestTypes.LoanApplicationDocumentRequest) {
		return docStatusMessage;
	}

	// Need more discussion around retrieving arbitrary users by ID
	if ((documentRequest.request as LoanApplicationDocumentRequest).uploadedByUserType === LoanApplicationDocumentRequest.UploadedByUserTypeEnum.LoanOfficer) {
		// Get user by ID from JPI and add 'by {{user.FullName}} to message
	} else if (
		(documentRequest.request as LoanApplicationDocumentRequest).uploadedByUserType ===
		LoanApplicationDocumentRequest.UploadedByUserTypeEnum.OnlinePortalUser
	) {
		// get bankCustomerUser by ID from JPI and add 'by {{user.FullName}} to message
	}

	return docStatusMessage;
}

export function transformDocRequestsToCards(
	documentRequests: OnlinePortalDocumentRequest[],
	isLenderView: boolean,
	router?: VueRouter,
	adobeTemplateStatuses?: []
): Array<NavigationCardGroup> {
	function getRequestCompletionType(templateDocumentsId?: number) {
		return !templateDocumentsId ? 'upload' : 'complete-online';
	}
	let uploadRequestCards: DocumentRequestCard[] = [];
	let completeOnlineRequestCards: DocumentRequestCard[] = [];
	let docRequestCards = [
		{
			title: 'Upload',
			navigationCards: uploadRequestCards
		},
		{
			title: 'Complete Template',
			navigationCards: completeOnlineRequestCards
		}
	] as Array<NavigationCardGroup>;

	for (let request of documentRequests) {
		let requestCompletionType = getRequestCompletionType(request.templateDocumentsID, request.id);
		let cardIconName = requestCompletionType === 'upload' ? 'clipboard-list' : 'file-signature';
		let cardArrayToPushTo = requestCompletionType === 'upload' ? uploadRequestCards : completeOnlineRequestCards;
		cardArrayToPushTo.push(transformDocRequestToCard(request, cardIconName, 'far', requestCompletionType, isLenderView, router, adobeTemplateStatuses));
	}
	return docRequestCards;
}


async function transformAndCombineDocumentRequests(
	page: number,
	perPage: number,
	loanApplicationIdsToFilterOn?: number[]
): Promise<OnlinePortalDocumentRequest[]> {
	const onlinePortalDocumentRequestsService: OnlinePortalDocumentRequestsService = AutoFactory.get(OnlinePortalDocumentRequestsService);
	const onlinePortalLoanApplicationDocumentRequestsService: OnlinePortalLoanApplicationDocumentRequestsService = AutoFactory.get(
		OnlinePortalLoanApplicationDocumentRequestsService
	);
	const onlinePortalApplicationDocumentRequestsService: OnlinePortalApplicationDocumentRequestsService = AutoFactory.get(
		OnlinePortalApplicationDocumentRequestsService
	);

	const documentRequestPromiseArray = await Promise.all([
		onlinePortalDocumentRequestsService.getPaged(page, perPage),
		onlinePortalLoanApplicationDocumentRequestsService.getPaged(page, perPage),
		onlinePortalApplicationDocumentRequestsService.getPaged(page, perPage)
	]);

	const documentRequests = documentRequestPromiseArray[0];
	const onlinePortalApplicationDocumentRequests = documentRequestPromiseArray[1];
	// ApplicationDocumentRequests are documents pulled from dynamic application. These are tied with a loanApplication.
	const dynamicApplicationDocumentRequests = documentRequestPromiseArray[2];

	let onlinePortalDocumentRequests: OnlinePortalDocumentRequest[] = combineDocumentRequests(
		documentRequests.items || [],
		onlinePortalApplicationDocumentRequests.items || [],
		dynamicApplicationDocumentRequests.items || [],
		loanApplicationIdsToFilterOn
	);

	return onlinePortalDocumentRequests;
}

/**
 * Retrieves all documentRequests and applicationDocumentRequests from the JPI
 * Returns an array of OnlinePortalDocumentRequest
 *
 * @param page default: 1
 * @param perPage default: 1000
 * @param loanApplicationIdsToFilterOn
 */
export async function getPaged(page?: number, perPage?: number, loanApplicationIdsToFilterOn?: number[]): Promise<OnlinePortalDocumentRequest[]> {
	page = page ? page : 1;
	perPage = perPage ? perPage : 1000;

	let onlinePortalDocumentRequests = await transformAndCombineDocumentRequests(page, perPage, loanApplicationIdsToFilterOn);
	onlinePortalDocumentRequests.sort(function(a: OnlinePortalDocumentRequest, b: OnlinePortalDocumentRequest) {
		return a.dateRequested! < b.dateRequested! ? -1 : a.dateRequested! > b.dateRequested! ? 1 : 0;
	});
	return onlinePortalDocumentRequests.slice(perPage * (page - 1), perPage * page);
}

export async function update(requestToUpdate: OnlinePortalDocumentRequest): Promise<void> {
	if (requestToUpdate.documentRequestType === DocumentRequestTypes.LoanApplicationDocumentRequest) {
		const docRequestService: OnlinePortalLoanApplicationDocumentRequestsService = AutoFactory.get(OnlinePortalLoanApplicationDocumentRequestsService);
		docRequestService.update(requestToUpdate.id as number, requestToUpdate.request as LoanApplicationDocumentRequest);
	} else if (requestToUpdate.documentRequestType === DocumentRequestTypes.DocumentRequest) {
		const docRequestService: OnlinePortalDocumentRequestsService = AutoFactory.get(OnlinePortalDocumentRequestsService);
		docRequestService.update(requestToUpdate.id as number, requestToUpdate.request as DocumentRequest);
	}
}

export async function reopen(requestToReopen: OnlinePortalDocumentRequest): Promise<OnlinePortalDocumentRequest> {
	(requestToReopen.request.dateUploaded as any) = null;
	(requestToReopen.request.dateWaived as any) = null;
	(requestToReopen.request.waivedReason as any) = null;
	(requestToReopen.request.waivedByLoanOfficerId as any) = null;
	if (requestToReopen.documentId) {
		const documentsService: OnlinePortalDocumentsService = AutoFactory.get(OnlinePortalDocumentsService);
		documentsService._delete(requestToReopen.documentId);
		(requestToReopen.request.documentId as any) = null;
	}

	if (requestToReopen.documentRequestType === DocumentRequestTypes.LoanApplicationDocumentRequest) {
		(requestToReopen.request as any).notUploadedReason = null;
	}

	await update(requestToReopen);
	return Promise.resolve(requestToReopen);
}
