import Formio from 'formiojs/Formio';
import { Utils as FormioUtils } from 'formiojs';
import { BaseRoleRepeaterWidget } from '../base/base-role-repeater-widget/base-role-repeater-widget';
import { ContextDataProperty, CustomComponentType, FetchDataType, LoanRoleType } from '../../../enums';
import { PrimaryRoleAddRowPopup } from './primary-role-add-row-popup/primary-role-add-row-popup';
import { detectAllowedEntityTypes } from '../../../utils/component-finder/component-finder';
import { PopupWrapper } from '../popup';
import { AddCustomerResult } from '../add-customer/add-customer-result';
import FormComponent from 'formiojs/components/form/Form';
import { AutoFactory, Customer, OnlinePortalLoanApplicationsService } from '@sageworks/jpi';
import BorrowerAccessPopupHelper, { BorrowerAccessModalResult } from '../add-customer/borrower-access-popup-helper';
import { ComponentDataHelper, RepeaterWidgetUtils } from '../../../utils';
import { getContextDataValue } from '../../../utils/context-data-helper/context-data-helper';
import { BorrowerAccessModalState } from '../add-customer/borrower-access-popup';
import { showLoadingPopup } from '../../../utils/modals/loading-modal-helper';
import { convertEntityTypeToCustomerType } from '../../../utils/customer-type-utils/customer-type-utils';

export class PrimaryRoleRepeaterWidget extends BaseRoleRepeaterWidget {
	private _isImportingCustomer = false;
	private _currentUserWritableEntities: number[] | null = null;
	private _isLoadingUserWritableEntities = true;
	private _skipUserWriteAccessCheck = false;
	protected showEditGridNav = false;

	public get isLoadingUserWritableEntities() {
		return this._isLoadingUserWritableEntities && !this._skipUserWriteAccessCheck;
	}

	public get isImportingCustomer() {
		return this._isImportingCustomer;
	}

	private get loanApplicationId() {
		return getContextDataValue(this, ContextDataProperty.LoanApplicationId);
	}

	private get shouldAutoOpen() {
		return !this.dataValue?.length && this.autoOpenEnabled && !this.editRows.length && !this.builderMode;
	}

	private userHasWriteAccess(row: any) {
		if (this._skipUserWriteAccessCheck) {
			return true;
		}
		if (!this._currentUserWritableEntities) {
			return false;
		}

		const entityId = this.rowGetEntityId(row);
		return this._currentUserWritableEntities.indexOf(+entityId) > -1;
	}

	init() {
		this._isImportingCustomer = false;
		super.init();
		this.autoOpen();
	}

	public async attach(element: any) {
		await super.attach(element);
		await this.loadInitData();
		if (this.hasOpenRows()) {
			this.emitEditEvent();
		}
		if (this.autoOpenEnabled) {
			await this.saveAutoOpenedRows();
		}
	}

	async saveAutoOpenedRows() {
		const savePromises: Promise<any>[] = [];
		if (!this.editRows?.length) {
			return;
		}
		this.editRows.forEach((row: any, index: number) => {
			const rowData = row?.data as any;
			const autoOpenFormType = this.component.autoOpenFormType.value;
			if (rowData[autoOpenFormType] == null) {
				return;
			}

			if (rowData[this.component.autoOpenFormType.value]?.data?.id < 1) {
				savePromises.push(this.partialSave(index));
			}
		});

		await Promise.all(savePromises);
	}

	async saveNewRow(rowIndex: number, borrowerAccessResult: BorrowerAccessModalResult) {
		this.saveRow(rowIndex);

		const savePromise = this.partialSaveRow(rowIndex).finally(() => {
			if (borrowerAccessResult?.modalState != null && !borrowerAccessResult.modalState.canEnterBorrowerInfo) {
				// shouldn't edit newly created borrower if user can't edit info for this borrower
				return;
			}
			return this.editRow(rowIndex);
		});
		showLoadingPopup(savePromise);

		await savePromise;
	}

	async showAddRowPopup(rowIndex: number, roleType: LoanRoleType): Promise<void> {
		const createPopupPromise = this.createAddRowPopup(rowIndex, roleType);
		showLoadingPopup(createPopupPromise);
		const addRowPopup = await createPopupPromise;
		const popupWrapper = new PopupWrapper({}, {}, {}, addRowPopup);

		popupWrapper.showPopup();
		const selectedCustomer = await popupWrapper.actionCompleted;

		if (selectedCustomer == null) {
			// No customer was selected, let Formio handle the cancel logic to reset the row to the proper state
			this.cancelRow(rowIndex);
			return;
		}

		this._isImportingCustomer = true;
		try {
			// Timeout is required, if we call saveOrEditRow immediately, the editGrid/primary role component will be replaced by the single editRow
			// This only seems to affect borrower view, and only if we skip the primary-role-add-row-popup, so we don't want a delay if we're a lender
			if (this.isLender) {
				await this.saveOrEditRow(rowIndex, selectedCustomer);
			} else {
				setTimeout(async () => {
					await this.saveOrEditRow(rowIndex, selectedCustomer);
				}, 1000);
			}
		} finally {
			this._isImportingCustomer = false;
		}
	}

	protected shouldPartialSaveOnSaveRow(_rowIndex: number, isNew: boolean) {
		return this.autoOpenEnabled || !isNew;
	}

	private async handleBorrowerAccessInvite(rowIndex: number, addCustomerResult: AddCustomerResult) {
		let priorModalState: BorrowerAccessModalState | undefined = undefined;
		let borrowerAccessResult: BorrowerAccessModalResult;
		let inviteBorrowerFlowComplete = true;
		do {
			borrowerAccessResult = await BorrowerAccessPopupHelper.handleBorrowerAccess(this, rowIndex, addCustomerResult, priorModalState);
			priorModalState = borrowerAccessResult.modalState;
			if (borrowerAccessResult.canceled) {
				return { borrowerAccessResult, addCustomerResult };
			}
			inviteBorrowerFlowComplete = true;
			if (borrowerAccessResult?.modalState != null && !borrowerAccessResult.modalState.canEnterBorrowerInfo) {
				try {
					addCustomerResult = await this.getAddCustomerResultFromInvitation(rowIndex, borrowerAccessResult);
				} catch (_) {
					this.emit(
						'custom-error',
						// eslint-disable-next-line
						'Failed to send invitation email. Ensure the email address is correct and try again. If this issue persists, please contact your lender.'
					);
					inviteBorrowerFlowComplete = false;
				}
			}
		} while (!inviteBorrowerFlowComplete);
		return {
			borrowerAccessResult,
			addCustomerResult
		};
	}

	protected async saveOrEditRow(rowIndex: number, addCustomerResult: AddCustomerResult) {
		const formComponent: FormComponent = this.editRows[rowIndex].components[0];

		const borrowerInviteResult = await this.handleBorrowerAccessInvite(rowIndex, addCustomerResult);
		let borrowerAccessResult: BorrowerAccessModalResult = borrowerInviteResult.borrowerAccessResult;
		if (borrowerAccessResult.canceled) {
			return;
		}
		addCustomerResult = borrowerInviteResult.addCustomerResult;

		await formComponent.subFormReady;
		formComponent.setValue(
			{
				...formComponent.subForm.getValue(),
				data: this.buildSaveData(addCustomerResult, borrowerAccessResult)
			},
			{ fromSubmission: true }
		);

		this.editRows[rowIndex].data = formComponent.data;
		await this.saveNewRow(rowIndex, borrowerAccessResult);
		await this.getWritableEntities(true);
		this._currentUserWritableEntities = null;
	}

	private async getAddCustomerResultFromInvitation(rowIndex: number, borrowerAccessResult: BorrowerAccessModalResult) {
		// inviteBorrowerToApplication API call will
		// 1. Create a new BankCustomerUser and/or ProfitEntity if necessary
		// 2. Send email to the associated email to alert they have been added to an application
		// 3. Insert necessary access mappings rows
		// 4. Return the Sageworks.Api.Models.Customer return object for the newly created customer
		let invitedCustomer = {} as Customer;
		const loadingPromise = AutoFactory.get(OnlinePortalLoanApplicationsService)
			.inviteToApplication({
				loanApplicationId: this.loanApplicationId,
				firstName: borrowerAccessResult?.modalState?.firstName,
				lastName: borrowerAccessResult?.modalState?.lastName,
				email: borrowerAccessResult?.modalState?.email
			})
			.then(result => {
				invitedCustomer = result;
				return this.createAddNewCustomerPopup(rowIndex, this.roleType);
			});
		// would be nice to consolidate this loading popup with the save one, but would require
		// refactoring I wasn't comfortable doing in this ticket
		await showLoadingPopup(loadingPromise);
		let addCustomerPopup = await loadingPromise;
		return addCustomerPopup.buildExistingCustomerResult(invitedCustomer);
	}

	protected buildSaveData(addCustomerResult: AddCustomerResult, borrowerAccessResult?: BorrowerAccessModalResult) {
		const data = RepeaterWidgetUtils.createRowData(addCustomerResult, this.parentLoanRoleId);

		switch (addCustomerResult.customerType) {
			case Customer.TypeEnum.Person: {
				if (borrowerAccessResult?.modalState != null && !borrowerAccessResult.modalState.canEnterBorrowerInfo) {
					data[CustomComponentType.personalInfo].data.canEditRoleEntity = false;
				}
				break;
			}
		}

		return data;
	}

	editRow(rowIndex: number) {
		const result: any = super.editRow(rowIndex);
		this.emitEditEvent();

		return result;
	}

	saveRow(rowIndex: number, modified?: boolean) {
		const result = super.saveRow(rowIndex, modified);

		if (result) {
			this.emitEditEndEvent();
		}

		return result;
	}

	cancelRow(rowIndex: number) {
		const editRow = this.editRows[rowIndex];
		const originalState = editRow.state;

		const result = super.cancelRow(rowIndex);

		if (originalState !== editRow.state) {
			this.emitEditEndEvent();
		}

		return result;
	}

	addRow(): any {
		this.component.modal = true;
		var newRow = undefined;

		try {
			newRow = super.addRow();
		} finally {
			this.component.modal = false;
		}

		return newRow;
	}

	addRowModal(rowIndex: number): any {
		var editRow = this.editRows[rowIndex];

		if (editRow.state === 'new') {
			this.showAddRowPopup(rowIndex, this.roleType);
		} else {
			return super.addRowModal(rowIndex);
		}
	}

	async isUseBorrowerAccessRestrictionsSettingEnabled(): Promise<boolean> {
		return await ComponentDataHelper.fetchData<boolean>(this, {
			fetchType: FetchDataType.UseBorrowerAccessRestrictions,
			fetchContext: {}
		});
	}
	async getWritableEntities(reloadFromServer: boolean): Promise<number[]> {
		return await ComponentDataHelper.fetchData<number[]>(this, {
			fetchType: FetchDataType.CurrentUserWritableEntities,
			fetchContext: { reloadFromServer: reloadFromServer }
		});
	}
	async loadInitData() {
		if (this._currentUserWritableEntities !== null) {
			return;
		}
		let useBorrowerAccessRestrictionsSettingEnabled = await this.isUseBorrowerAccessRestrictionsSettingEnabled();
		this._skipUserWriteAccessCheck = this.isLender || !useBorrowerAccessRestrictionsSettingEnabled;
		if (this._skipUserWriteAccessCheck) {
			this._currentUserWritableEntities = [];
			this.redraw();
			return;
		}
		const writableEntities = await this.getWritableEntities(false);
		this._currentUserWritableEntities = writableEntities;
		this._isLoadingUserWritableEntities = false;
		this.redraw();
	}

	get rowTemplate() {
		return `<div class="row d-flex align-items-center">
					<div class="col">
						<span>{{ instance.encodeHtml(instance.rowDescription(row.form, rowIndex)) }}</span>
					</div>
				{% if (!instance.disabled) { %}
					<div class="col-auto">
					{% if (instance.isLoadingUserWritableEntities) { %}
						<div class="d-flex align-items-center pull-right">
							<span class="spinner-border text-primary"></span>
						</div>
					{% } else if (!instance.userHasWriteAccess(row.form)) { %}
					<div class="d-flex align-items-center pull-right">
						<i class="{{ iconClass('lock') }}"></i> <span>Profile Locked</span>
					</div>
					{% } else { %}
					<div class="btn-group pull-right">
						<button class="btn btn-sm btn-primary editRow"><i aria-label="Edit row" class="{{ iconClass('edit') }}"></i>Edit</button>
						{% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}
						<button class="btn btn-danger btn-sm removeRow"><i aria-label="Remove row" class="{{ iconClass('trash') }}"></i></button>
						{% } %}
					</div>
					{% } %}
			
					</div>
				{% } %}
				</div>
		`;
	}

	protected async getEntityTypes(): Promise<CustomComponentType[]> {
		const formio = new Formio(Formio.getProjectUrl() + '/form/' + this.component.form);
		const form = await formio.loadForm({ params: { live: 1 } });
		return detectAllowedEntityTypes(form.components ?? []);
	}

	private async createAddRowPopup(rowIndex: number, roleType: LoanRoleType) {
		const ignoredCustomerIds = this.getAllCustomerIDs();
		const editRow = this.editRows[rowIndex];
		const entityTypes = await this.getEntityTypes();
		const applicationEntities = await this.getApplicationEntities(true, entityTypes, ignoredCustomerIds);

		return new PrimaryRoleAddRowPopup(
			PrimaryRoleAddRowPopup.schema({
				roleType: roleType,
				addNewEntityDataFields: [],
				applicationEntities: applicationEntities,
				ignoredCustomerIds: ignoredCustomerIds,
				entityTypes: entityTypes
			}),
			{
				...this.options,
				skipInit: false
			},
			(FormioUtils as any).fastCloneDeep(editRow.data)
		);
	}

	public async autoOpen() {
		const componentTypeToOpen = this.component.autoOpenFormType?.value as CustomComponentType;

		if (this.shouldAutoOpen) {
			const addCustomerResult = {
				customerType: convertEntityTypeToCustomerType(componentTypeToOpen),
				[componentTypeToOpen]: {
					data: { id: -1 }
				}
			} as AddCustomerResult;

			this.createRow(this.buildSaveData(addCustomerResult), 0);
			const formComponent: FormComponent = this.editRows[0].components[0];

			await formComponent.subFormReady;
			formComponent.setValue(
				{
					...formComponent.subForm.getValue(),
					data: this.buildSaveData(addCustomerResult)
				},
				{ fromSubmission: true }
			);

			this.setPristine(false);
		}
	}
}
