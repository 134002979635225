export const setElementAt = <T>(array: T[], element: T, index: number) => {
	return [
		// part of the array before the specified index
		...array.slice(0, index),
		// inserted items
		element,
		// part of the array after the specified index
		...array.slice(index + 1)
	];
};

export const upsert = <O>(list: O[], item: O, findElementEvaluater: (item: O) => boolean) => {
	list = [...list];

	const index = list.findIndex(findElementEvaluater);
	if (index >= 0) {
		list[index] = item;
	} else {
		list.push(item);
	}

	return list;
};