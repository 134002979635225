import Component from 'formiojs/components/_classes/component/Component';
import { Utils as FormioUtils } from 'formiojs';
import { containsOpenEditGrid } from '../edit-grid-helpers';
import { CustomComponentType, FormioComponentType } from '../../enums';
import { useComponentManager } from '../../components/component-manager';
import ModifiedFormComponent from '../../components/form-components/formio-components/form/modified-form-component';
import BaseRepeaterWidget from '../../components/form-components/base/base-repeater-widget/base-repeater-widget';
import FormComponent from 'formiojs/components/form/Form';
import ExtendedSelectField from '../../components/form-components/simple-inputs/extended-select-field/extended-select-field';
import VueFormioComponent from '../../components/form-components/base/vue-formio-component/vue-formio-component';
import * as FormioComponentUtils from '../formio-component-utils';

export function isRootLevelFormPath(path: any) {
	return FormioComponentUtils.isRootLevelFormPath(path);
}

export function isInPreviewMode(component: Component) {
	return FormioComponentUtils.isInPreviewMode(component);
}

// eslint-disable-next-line max-lines-per-function
export function shouldDisplayNav(wizard: any, topLevel: boolean = true): boolean {
	if (wizard == null || wizard.wizard == null || wizard.components == null) {
		return false;
	}

	if (topLevel) {
		// special short-circuit to hide the navigation if an edit grid is open on the current view
		const currentRootComponentId = wizard.currentPanel?.id ?? wizard.root?.components[0]?.id ?? '';
		const currentRootComponent = wizard.root?.components?.find((c: any) => c.id === currentRootComponentId);
		if (currentRootComponent && containsOpenEditGrid(currentRootComponent)) {
			return false;
		}
	}

	if (!topLevel && wizard.wizard?.display === 'wizard' && wizard.components.length > 1) {
		return true;
	}

	const currentPage = wizard.currentPanel ?? wizard.components[0];
	const components = currentPage?.components ?? [];
	let childDisplayNav = false;
	FormioUtils.eachComponent(
		components,
		(c: any) => {
			if (!c.visible) {
				return true;
			} else if (c.formSrc && c.formSrc.length > 0) {
				if (c.subForm) {
					childDisplayNav = childDisplayNav || shouldDisplayNav(c.subForm, false);
				} else {
					c.subFormReady?.then(() => {
						wizard.redrawNavigation();
					});
				}
			}
		},
		true
	);

	return (topLevel && !childDisplayNav) || (!topLevel && childDisplayNav);
}

export function shouldWizardShowCancelButton(wizard: any) {
	if (!wizard) {
		return false;
	}
	// currently, we never show. eventually, PMs want to add something smarter for repeating widgets.
	return false;
}

export function keyToDataFieldId(key: string): number | null {
	var regex = new RegExp('__id_([0-9]+)__');

	if (!regex.test(key)) {
		return null;
	}

	return parseInt((regex.exec(key) as any)[1], 10);
}

export function isFormTypeComponent(type: CustomComponentType) {
	const { componentManager } = useComponentManager();
	let componentConstructor: typeof Component = componentManager.CustomComponents[type];
	if (componentConstructor != null) {
		return (
			componentConstructor.prototype instanceof ModifiedFormComponent ||
			componentConstructor.prototype instanceof BaseRepeaterWidget ||
			componentConstructor.prototype instanceof VueFormioComponent
		);
	}
	componentConstructor = componentManager.FormioComponentOverrides[(type as unknown) as FormioComponentType];
	if (componentConstructor != null) {
		return componentConstructor.prototype instanceof FormComponent;
	}

	return false;
}

let _listOfFieldsWithOver100Options: string[] = ['industryCode'];
export function reloadSelectComponentOptions(contextComponent: ExtendedSelectField) {
	if (!contextComponent.checkConditions()) {
		return;
	}

	let root = contextComponent.root;
	while (root != null && root.root != null && root.id !== root.root.id) {
		root = root.root;
	}

	if (root != null) {
		root.ready
			.then(function() {
				// Ensure we pass in the value for the select fields that might have over 100 options
				const optionFound = _listOfFieldsWithOver100Options.find(option => {
					return option === contextComponent.component.valueProperty;
				});

				if (optionFound == null) {
					contextComponent.updateItems('', true);
				}

				return contextComponent.itemsLoaded;
			})
			.then(function() {
				// TODO: Research why component.element is undefined for *some of the
				if (contextComponent.element == null && contextComponent.id != null) {
					contextComponent.element = document.getElementById(contextComponent.id);
				}

				contextComponent.redraw();
			});
	}
}
