import editForm from './other-assets.form';
import { CustomComponentType, CustomComponentLabel } from '../../../../enums';
import { FinancialSubaccount } from '../financial-subaccount/financial-subaccount';

export class OtherAssetsWidget extends FinancialSubaccount {
	static schema(...extend: any) {
		return FinancialSubaccount.schema(
			{
				label: CustomComponentLabel()[CustomComponentType.otherAssets],
				type: CustomComponentType.otherAssets,
				key: CustomComponentType.otherAssets,
				addAnother: 'Add Other Assets'
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: CustomComponentLabel()[CustomComponentType.otherAssets],
			group: '',
			weight: 10,
			schema: OtherAssetsWidget.schema()
		};
	}
}
