export default [
	{
		label: 'Required',
		type: 'checkbox',
		tooltip: 'A required field must be filled in before the form can be submitted.',
		key: 'validate.required',
		input: true,
		logic: [
			{
				name: 'checkDisable',
				trigger: {
					type: 'javascript',
					javascript: "result = data['forceRequired'] === true"
				},
				actions: [
					{
						name: 'disable',
						type: 'property',
						property: {
							label: 'Disabled',
							value: 'disabled',
							type: 'boolean'
						},
						state: true
					},
					{
						name: 'Set Required',
						type: 'value',
						value: 'value = true'
					}
				]
			}
		]
	}
];
