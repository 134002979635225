import { DataFieldConvertStrategy } from '../data-field-convert-strategy';
import { DataField } from '@sageworks/jpi';
import { CustomComponentType } from '../../../../enums';

export class DefaultDataFieldConverter implements DataFieldConvertStrategy {
	isFieldValid(_dataField: DataField): boolean {
		return true;
	}

	convert(dataField: DataField, currentProperties: any) {
		return {
			...currentProperties,
			...this.getNumberProperties(dataField),
			...this.getCurrencyProperties(dataField),
			...this.getPercentProperties(dataField),
			...this.getTextProperties(dataField),
			...this.getTextAreaProperties(dataField),
			...this.getBooleanProperties(dataField),
			...this.getDateProperties(dataField),
			...this.getPhoneNumberProperties(dataField),
			...this.getSocialSecurityNumberProperties(dataField)
		};
	}

	private getNumberProperties(dataField: DataField): any {
		const properties: any = {};

		if (dataField.dataFieldType !== DataField.DataFieldTypeEnum.Numeric) {
			return properties;
		}

		properties.type = CustomComponentType.extendedNumber;

		if (dataField.formatString) {
			properties.decimalLimit = dataField.formatString.charAt(1);
		}

		return properties;
	}

	private getCurrencyProperties(dataField: DataField): any {
		const properties: any = {};

		if (dataField.dataFieldType !== DataField.DataFieldTypeEnum.Currency) {
			return properties;
		}

		properties.type = CustomComponentType.extendedCurrency;

		return properties;
	}

	private getPercentProperties(dataField: DataField): any {
		const properties: any = {};

		if (dataField.dataFieldType !== DataField.DataFieldTypeEnum.Percent) {
			return properties;
		}

		properties.type = CustomComponentType.extendedPercent;
		properties.suffix = '%';

		if (dataField.formatString) {
			properties.decimalLimit = dataField.formatString.charAt(1);
		}

		return properties;
	}

	private getTextProperties(dataField: DataField): any {
		const properties: any = {};

		if (dataField.dataFieldType !== DataField.DataFieldTypeEnum.Text) {
			return properties;
		}

		properties.type = CustomComponentType.extendedTextField;

		return properties;
	}

	private getTextAreaProperties(dataField: DataField): any {
		const properties: any = {};

		if (dataField.dataFieldType !== DataField.DataFieldTypeEnum.TextArea) {
			return properties;
		}

		properties.type = CustomComponentType.extendedTextArea;

		return properties;
	}

	private getBooleanProperties(dataField: DataField) {
		const properties: any = {};

		if (dataField.dataFieldType !== DataField.DataFieldTypeEnum.Boolean) {
			return properties;
		}

		properties.type = CustomComponentType.extendedRadio;
		properties.dataType = 'boolean';
		properties.values = [
			{
				label: 'Yes',
				value: '1'
			},
			{
				label: 'No',
				value: '0'
			}
		];

		return properties;
	}

	private getDateProperties(dataField: DataField) {
		const properties: any = {};

		if (dataField.dataFieldType !== DataField.DataFieldTypeEnum.Date) {
			return properties;
		}

		properties.type = CustomComponentType.extendedDateTime;
		properties.time_24hr = false;
		properties.enableTime = false;
		properties.customOptions = {
			frameRoot: '.content-frame-root'
		};

		return properties;
	}

	private getPhoneNumberProperties(dataField: DataField) {
		const properties: any = {};

		if (dataField.dataFieldType !== DataField.DataFieldTypeEnum.PhoneNumber) {
			return properties;
		}

		properties.type = CustomComponentType.extendedPhoneNumber;
		properties.inputMask = '(999) 999-9999';

		return properties;
	}

	private getSocialSecurityNumberProperties(dataField: DataField) {
		const properties: any = {};

		if (dataField.dataFieldType !== DataField.DataFieldTypeEnum.SocialSecurityNumber) {
			return properties;
		}

		properties.type = CustomComponentType.extendedTextField;
		properties.inputMask = '999-99-9999';

		return properties;
	}
}
