import { RelatedLoanRole } from '@sageworks/jpi';
import { LoanRoleType } from '@sageworks/dynamic-forms';

/* 
	This is strongly tied to logic from LoanRoleModelConverter in the JPI
	where we are manually assigning role types. If that logic changes it 
	will break this helper.
*/
export function ConvertParentLoanRoleType(loanRoleType: LoanRoleType | undefined): RelatedLoanRole.ParentLoanRoleTypeEnum {
	switch (loanRoleType) {
		case LoanRoleType.CoBorrower:
			return RelatedLoanRole.ParentLoanRoleTypeEnum.CoBorrower;
		case LoanRoleType.Guarantor:
			return RelatedLoanRole.ParentLoanRoleTypeEnum.Guarantor;
		default:
			return RelatedLoanRole.ParentLoanRoleTypeEnum.Other;
	}
}