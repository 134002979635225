import editForm from './app-type-select.form';
import { CustomComponentType } from '../../../enums/custom-component-type';
import SelectComponent from 'formiojs/components/select/Select';
import { checkConditionalityAcrossForms, shouldSelectFieldUseOriginalConditionality } from '../simple-inputs/extended-field-helper';
import { FormioConditionalityUtils } from '../../../utils/formio-conditionality-utils';

export default class AppTypeSelect extends SelectComponent {
	static schema(...extend: any) {
		return SelectComponent.schema(
			{
				label: 'Application Type',
				type: CustomComponentType.appTypeSelect,
				key: CustomComponentType.appTypeSelect,
				dataSrc: 'values',
				template: '<span>{{ item.label }}</span>',
				lazyLoad: false,
				searchEnabled: false,
				forceRequired: true
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: 'Application Type',
			group: 'productSelectionWidgets',
			weight: 10,
			schema: AppTypeSelect.schema()
		};
	}

	conditionallyVisible(data: any): boolean {
		if (shouldSelectFieldUseOriginalConditionality(this)) {
			return super.conditionallyVisible(data);
		}

		return checkConditionalityAcrossForms(this);
	}

	checkCondition(row: any, data: any) {
		return FormioConditionalityUtils.checkCondition(
			this.component,
			row || this.data,
			data || this.rootValue,
			this.root ? (this.root as any)._form : {},
			this
		);
	}

	attach(element: HTMLElement) {
		if (element.id != null && document.getElementById(element.id) != null) {
			element = document.getElementById(element.id)!;
		}

		return super.attach(element);
	}

	setItems(items: any, fromSearch: boolean): void {
		let massagedItems = items;
		if (items && Array.isArray(items)) {
			massagedItems = items.filter(option => option.enabledForConsumers);
		}
		super.setItems(massagedItems, fromSearch);
	}

	// @ts-ignore
	get key() {
		if (this.builderMode || this.options.attachMode === 'builder') {
			return super.key;
		}

		// check if editing this components properties in an editForm
		if (this.root?.element != null && this.root.element.getAttribute('ref') === 'editForm') {
			return super.key;
		}

		return `${this.component.type}`;
	}

	addOption(value: any, label: any, attrs = {}) {
		if (this.component.dataType === 'string') {
			value = this.normalizeSingleValue(value);
		}
		super.addOption(value, label, attrs);
	}
}
