/** @description When using foreach syntax to iterate an object, TS has difficulties with non-string key values
 * This fixes that issue by returning out an iterable array of the object's keys
 *
 * @param {Object} object The object to process the keys for.
 *
 * @return {Array} An iterable array containing the keys for the passed-in object.
 */
export function getObjectKeys<O extends Object>(object: O): Array<keyof O> {
	return Object.keys(object) as Array<keyof O>;
}

/** @description Iterates over an object and returns whether the object or all of its keys conform to falsy values
 *
 * @param {Object} object The object to check for emptiness
 *
 * @return {Boolean} The result of the emptiness check
 */
export function isEmptyObject<O extends Object>(object: O | null): boolean {
	if (!object) {
		return true;
	}
	return Object.values(object).every(x => !x);
}
