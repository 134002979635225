
import { Customer, DataFieldOption } from '@sageworks/jpi';
import Vue, { PropType } from 'vue';
import AddNewPersonForm from './add-new-person-form.vue';
import AddNewBusinessForm from './add-new-business-form.vue';

export default Vue.extend({
	name: 'AddNewCustomerForm',
	components: { AddNewPersonForm, AddNewBusinessForm },
	props: {
		allowedEntityTypes: {
			type: Array as PropType<Customer.TypeEnum[]>,
			default: () => []
		},
		entityType: String as PropType<Customer.TypeEnum>,
		firstName: String,
		lastName: String,
		email: String,
		businessName: String,
		industryCode: String,
		state: String,
		states: {
			type: Array as PropType<DataFieldOption>,
			default: () => []
		},
		industryCodes: {
			type: Array as PropType<DataFieldOption>,
			default: () => []
		},
	},
	data() {
		return {
			CustomerType: Customer.TypeEnum,
		};
	},
	computed: {
		entityOptions() {
			return (this.allowedEntityTypes ?? [])
				.map(type => ({
					value: type,
					label: type
				}));
		}
	},
	methods: {
		onEntityTypeChange(event: Event) {
			const target = event.target as HTMLInputElement;

			this.$emit('update:entity-type', target.value);
		}
	}
});
