
import { Component, Vue, Prop } from 'vue-property-decorator';
import { OnlinePortalRoutes } from '@/OnlinePortalRoutes';
import { AutoFactory, OnlinePortalAuth0Service } from '@sageworks/jpi';
import { UrlUtils } from '../../../utils';

@Component({})
export default class PortalDataLoadRedirect extends Vue {
	@Prop({ default: OnlinePortalRoutes.Home.path })
	private redirectRoutePath!: string;

	private mounted(): void {
		this.loadAllRequiredData();
	}

	private async loadAllRequiredData() {
		await Promise.all([this.directStore.dispatch.User.loadCurrentUser(), this.directStore.dispatch.Application.fetchLoanApplications()]);
		await Promise.all([
			this.directStore.dispatch.InstitutionSettings.fetchLogoutUrl(),
			this.directStore.dispatch.InstitutionSettings.fetchAuthorizationSetting(),
			this.directStore.dispatch.ContactInformation.fetchContactInformation(),
			this.directStore.dispatch.ApplicationTemplates.fetchPreAppTemplate(),
			this.directStore.dispatch.InstitutionSettings.fetchAllMessages(),
			this.directStore.dispatch.DocumentRequest.loadAllDocumentRequests(),
			this.directStore.dispatch.InstitutionSettings.fetchAllowBorrowersToStartApplications(),
			this.directStore.dispatch.UsableProducts.fetchUsableProducts(),
			this.directStore.dispatch.InstitutionSettings.fetchDynamicApplicationSettings()
		]);
		const auth0FFEnabled = await AutoFactory.get(OnlinePortalAuth0Service).isAuth0FeatureFlagActive({ subdomain: UrlUtils.getOnlinePortalSubdomain() });
		if (!!this.directStore.state.User.user && (!this.directStore.state.User.user.firstName || !this.directStore.state.User.user.lastName)) {
			if (auth0FFEnabled) {
				this.$router.push({ path: OnlinePortalRoutes.MyInfo.path, params: { redirectRoutePath: this.redirectRoutePath } });
			} else {
				this.$router.push({ path: OnlinePortalRoutes.SetContactInformation.path, params: { redirectRoutePath: this.redirectRoutePath } });
			}
		} else {
			this.$router.push({ path: this.redirectRoutePath });
		}
	}
}
