
/**
 * Returns true if value is null or undefined
 * @param value value to chec
 */
export function isNullOrUndefined(value: any) {
    return value === null || value === undefined;
}

/**
 * Compares equality of two items treating null and undefined as the same
 * @param value1 first value to compare
 * @param value2 second value to compare
 */
export function nullableEquals(value1: any, value2: any) : boolean {
    if (isNullOrUndefined(value1)) {
        return isNullOrUndefined(value2);
    } else {
        return value1 === value2;
    }
}

/**
 * Compares two nullable dates (typically for sorting)
 * @param date1 first date to compare
 * @param date2 second date to compare
 * @param nullIsLatestDate 
 */
export function nullableDateCompare(date1: any, date2: any, nullIsLatestDate: boolean) : number {
    if (nullableEquals(date1, date2)) {
        return 0;
    }
    if (isNullOrUndefined(date1)) {
        return nullIsLatestDate ? 1 : -1;
    }
    return new Date(date1).getTime() - new Date(date2).getTime();
}