import editForm from './trustee.form';
import { CustomComponentType, LoanRoleType } from '../../../enums';
import { RelatedRoleRepeaterWidget } from '../related-role-repeater-widget/related-role-repeater-widget';

export default class TrusteeWidget extends RelatedRoleRepeaterWidget {
	static schema(...extend: any) {
		return RelatedRoleRepeaterWidget.schema(
			{
				label: 'Trustee',
				type: CustomComponentType.trustee,
				key: CustomComponentType.trustee,
				addAnother: 'Add a Trustee'
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: 'Trustee',
			group: '',
			weight: 10,
			schema: TrusteeWidget.schema({ skipRemoveConfirm: true })
		};
	}

	render(children: any, topLevel?: boolean): any {
		return super.render(children, topLevel);
	}

	init() {
		this.roleType = LoanRoleType.Trustee;
		super.init();
	}
}
