import { LoanApplicationMetadataModuleState } from '../../../../store/LoanApplicationMetadataModule';
import { MultiLoanApplicationFormModuleState } from '../../../../store/MultiLoanApplicationFormModule/state';
import {
	CustomComponentType,
	DataHandler,
	DataObjectMapper,
	DataObjectUtils,
	DeletePayload,
	FormDataSubmissionDataMapper,
	LoanRoleType,
	SubmitPayload
} from '@sageworks/dynamic-forms';
import { AggregatedFormDataModel } from '@sageworks/jpi';
import { StoreType } from '../../../../store';
import { findChildCustomerComponentType } from '../data-handler-utils';
import { PfsDataHelper } from '../../../../store/MultiLoanApplicationFormModule/helpers';

export class LoanRoleDataHandler implements DataHandler {
	private _loanRoleTypeLookup = new Map<CustomComponentType, LoanRoleType>([
		[CustomComponentType.primaryBorrowerEntity, LoanRoleType.PrimaryBorrower],
		[CustomComponentType.primaryBorrowerEntityVue, LoanRoleType.PrimaryBorrower],
		[CustomComponentType.guarantorEntity, LoanRoleType.Guarantor],
		[CustomComponentType.guarantorEntityVue, LoanRoleType.Guarantor],
		[CustomComponentType.coBorrowerEntity, LoanRoleType.CoBorrower],
		[CustomComponentType.coBorrowerEntityVue, LoanRoleType.CoBorrower],
		[CustomComponentType.coSignerEntity, LoanRoleType.CoSigner],
		[CustomComponentType.coSignerEntityVue, LoanRoleType.CoSigner],
		[CustomComponentType.creditApplicantEntity, LoanRoleType.CreditApplicant],
		[CustomComponentType.creditApplicantEntityVue, LoanRoleType.CreditApplicant]
	]);

	private get loanRoleType() {
		return this._loanRoleTypeLookup.get(this.componentType) as LoanRoleType;
	}

	private get formData() {
		const { renderData } = this._store.state.MultiLoanApplicationForm as MultiLoanApplicationFormModuleState;
		return renderData?.formData ?? {};
	}

	private get metadata() {
		const { loanIndex } = this._store.state.MultiLoanApplicationForm as MultiLoanApplicationFormModuleState;
		const { metadata } = this._store.state.LoanApplicationMetadata as LoanApplicationMetadataModuleState;

		return metadata[loanIndex];
	}

	constructor(private _store: StoreType, private componentType: CustomComponentType) {}

	async submit({ submission }: SubmitPayload) {
		if (submission.data == null) {
			throw new Error('no submission.data');
		}

		const propName = findChildCustomerComponentType(submission.data);
		if (propName == null) {
			throw new Error(`could not find dataObject of a valid customer type when attempting to submit ${this.loanRoleType}`);
		}

		const dataObjectId = submission.data[propName]?.data?.id;
		if (dataObjectId == null || dataObjectId <= 0) {
			throw new Error(`no dataObject.id for dataObject.type ${propName} when attempting to submit ${this.loanRoleType}`);
		}

		const dataObjectType = DataObjectMapper.submissionDataPropertiesLookup[propName];
		const role = await this._store.dispatch.MultiLoanApplicationForm.addLoanRoleForDataObject({
			dataObjectType,
			customerId: dataObjectId,
			loanRoleType: this.loanRoleType
		});
		const isNew = submission.data[propName].data.parentLoanRoleId == null;

		// fetch complete form data if a new loan role
		if (isNew) {
			const dataFieldMetadata = this.metadata.dataFieldIdsByType ?? {};
			await this._store.dispatch.MultiLoanApplicationForm.loadRelatedDataForLoanRole({
				id: role.dataObjectID!,
				loanRoleType: this.loanRoleType,
				customerObjectType: DataObjectUtils.dataObjectTypeToCustomerType(dataObjectType)!,
				dataFieldMetadata
			});

			submission.data = this.generateSubmissionData(role.dataObjectID!, this.loanRoleType);
		}
		return submission;
	}

	async delete({ submission }: DeletePayload) {
		const propName = findChildCustomerComponentType(submission?.form?.data);

		if (propName == null) {
			throw new Error(`could not find dataObject of a valid customer type when attempting to delete ${this.loanRoleType}`);
		}

		const customerId = submission?.form?.data[propName]?.data?.id;

		if (customerId == null) {
			throw new Error(`no dataObject.id for dataObject.type ${propName} when attempting to delete ${this.loanRoleType}`);
		}

		await this._store.dispatch.MultiLoanApplicationForm.removeLoanRolesForCustomer({ loanRoleType: this.loanRoleType, customerId });
		await this.deleteLoanRoleAssociatedData(customerId);
	}

	private async deleteLoanRoleAssociatedData(customerId: number) {
		await this.deletePfsMapping(customerId);
	}

	private async deletePfsMapping(customerId: number) {
		const pfsMapping = PfsDataHelper.locatePfsMapping(this._store, customerId);

		// Graciously end if there's PFS data to delete
		if (pfsMapping == null || pfsMapping.id == null) {
			return;
		}

		await this._store.dispatch.MultiLoanApplicationForm.removePFS({ mappingId: pfsMapping.id });
	}

	private generateSubmissionData(loanRoleId: number, type: LoanRoleType) {
		const { presetDataFieldsByDataObject } = this._store.state.LoanApplicationMetadata as LoanApplicationMetadataModuleState;
		const formData: AggregatedFormDataModel = {
			...this.formData,
			primaryBorrowerMapping: type === LoanRoleType.PrimaryBorrower ? this.formData.primaryBorrowerMapping : undefined,
			coBorrowerMappings:
				type === LoanRoleType.CoBorrower ? (this.formData.coBorrowerMappings ?? []).filter(x => x.dataObjectID === loanRoleId) : undefined,
			coSignerMappings: type === LoanRoleType.CoSigner ? (this.formData.coSignerMappings ?? []).filter(x => x.dataObjectID === loanRoleId) : undefined,
			guarantorMappings: type === LoanRoleType.Guarantor ? (this.formData.guarantorMappings ?? []).filter(x => x.dataObjectID === loanRoleId) : undefined,
			creditApplicantMappings:
				type === LoanRoleType.CreditApplicant ? (this.formData.creditApplicantMappings ?? []).filter(x => x.dataObjectID === loanRoleId) : undefined
		};

		const dataBuilder = new FormDataSubmissionDataMapper(formData.dataObjects ?? {});

		const submissionData = dataBuilder
			.setPresetFields(presetDataFieldsByDataObject)
			.setAggregatedFormData(formData)
			.getSubmissionData();

		const componentType = FormDataSubmissionDataMapper.RoleToComponentKeyLookup[type];

		if (submissionData.data[componentType].length !== 1) {
			throw new Error('Submission data contain incorrect amount of data');
		}

		return submissionData.data[componentType][0].form.data;
	}
}
