import { ProposedProduct } from '@sageworks/jpi';

export function getProposedProductName(product: ProposedProduct) {
	let productName = 'Application';
	if ((product?.customerFacingName ?? '').length > 0) {
		productName = product?.customerFacingName as string;
	} else if ((product?.name ?? '').length > 0) {
		productName = product?.name as string;
	}
	return productName;
}
