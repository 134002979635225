import { StoreType } from '../../store';
import { CrudHookFactory, DynamicFormsHookType } from '@sageworks/dynamic-forms';
import { BasicSubmitHook } from '@sageworks/dynamic-forms/src/hooks/crud-hooks/basic-submit-hook';
import { BasicDeleteHook } from '@sageworks/dynamic-forms/src/hooks/crud-hooks/basic-delete-hook';
import { MultiLoanDataHandlerFactory } from '../dya-hooks/multi-loan-data-handlers/multi-loan-data-handler-factory';
import { createCrudHookConfig } from './create-crud-hook-config';
import { ComponentDataHandler } from '../component-data-handler/ComponentDataHandler';
import { ComponentCreator } from '../../formio-vue/utils/component-creator';

export class HooksBuilder {
	private hooks: any = {};
	private componentDataHandler?: ComponentDataHandler;

	public addCrudHooks(parentVueComponent: any, store: StoreType) {
		const isProfileCompleteResolver = () =>
			(parentVueComponent.$refs.form as any[])[0]?.$refs.$formioForm?.formio.components?.reduce(
				(check: boolean, comp: any) => comp.checkValidity(undefined, true, undefined, true) && check,
				true
			) ?? false;
		const crudHookConfigs = createCrudHookConfig(parentVueComponent.directStore, isProfileCompleteResolver);
		const crudHookFactory = new CrudHookFactory(new MultiLoanDataHandlerFactory(store));

		this.hooks = {
			...this.hooks,
			...crudHookFactory.createHooks(crudHookConfigs, new BasicSubmitHook(), new BasicDeleteHook()),
		};

		return this;
	}

	public addFetchDataHook(store: StoreType) {
		const componentDataHandler = this.getOrCreateComponentDataHandler(store);

		this.hooks = {
			...this.hooks,
			[DynamicFormsHookType.FetchData]: componentDataHandler.createFetchDataHook(),
		};

		return this;
	}

	public addUpdateDataHook(store: StoreType) {
		const componentDataHandler = this.getOrCreateComponentDataHandler(store);

		this.hooks = {
			...this.hooks,
			[DynamicFormsHookType.UpdateData]: componentDataHandler.createUpdateDataHook(),
			[DynamicFormsHookType.RemoveOnChangeHandler]: componentDataHandler.createRemoveOnChangeHandlerHook()
		};

		return this;
	}

	public addCreateComponentHook(parentComponent: any) {
		const componentCreator = new ComponentCreator(parentComponent);

		this.hooks = {
			...this.hooks,
			[DynamicFormsHookType.CreateVueComponent]: componentCreator.createHook(),
		};

		return this;
	}

	public build() {
		return this.hooks;
	}

	private getOrCreateComponentDataHandler(store: StoreType) {
		return this.componentDataHandler ?? new ComponentDataHandler(store);
	}
}