import { FormioFormSchema, FormioFormDisplayType } from '../../../formio-interfaces/FormioFormSchema';
import { Formio, Utils as FormioUtils } from 'formiojs';
import { FormioComponentType } from '../../../enums';
import { OpnPlatformType } from '@sageworks/core-logic/src/enums';
import { CopyFormUtils } from '../../../utils';
import { ComponentCleaner } from './component-cleaner';

export async function buildCopiedForm(
	schema: FormioFormSchema,
	id: number,
	rootInfo: any,
	componentInfo: any,
	templateDataFieldMappings: { [templateDataFieldId: number]: number },
	isBestPractice: boolean
) {
	const newSchema = Object.assign({}, schema);
	newSchema._id = '';
	newSchema.machineName = '';
	newSchema.owner = '';
	newSchema.path = rootInfo.path + '/' + componentInfo.type + '/' + id;
	newSchema.title = rootInfo.title + ' > ' + componentInfo.label;
	newSchema.name = rootInfo.name + '-' + componentInfo.type + '-' + id;
	newSchema.display = schema.display ?? FormioFormDisplayType.wizard;
	delete newSchema.access;

	newSchema.components = await new ComponentCleaner(templateDataFieldMappings, isBestPractice, newSchema.components ?? []).clean();
	return newSchema;
}

// eslint-disable-next-line max-lines-per-function
export async function buildNestedComponents(
	schema: FormioFormSchema,
	isBestPractice: boolean,
	platformType: OpnPlatformType,
	inSandbox: boolean,
	templateDataFieldMappings: { [templateDataFieldId: number]: number }
) {
	var promises: Promise<any>[] = [];
	if (schema.components != null && schema.components.length > 0) {
		const rootInfo = {
			path: schema.path,
			title: schema.title,
			name: schema.name
		};
		// eslint-disable-next-line max-lines-per-function

		schema.components = await new ComponentCleaner(templateDataFieldMappings, isBestPractice, schema.components ?? []).clean();
		FormioUtils.eachComponent(schema.components, (component: any) => {
			if (component.form != null && component.form !== '' && component.type !== FormioComponentType.Form) {
				const projectUrl = isBestPractice ? CopyFormUtils.getBestPracticeProjectUrl(platformType, inSandbox) : Formio.getProjectUrl();
				const formRequestPath = projectUrl + '/form/' + component.form;
				promises.push(
					Formio.makeRequest(undefined, 'form', formRequestPath, 'get', null, undefined).then(async (result: any) => {
						const componentInfo = {
							type: component.type,
							label: component.label
						};
						const newForm = await handleFormCopy(result, rootInfo, componentInfo, templateDataFieldMappings, isBestPractice);
						// Update the path to the new form and persist back to formio
						component.formPath = newForm.path;
						component.form = newForm._id;
						delete schema.modified;
						delete schema._vid;
						await Formio.makeRequest(undefined, 'form', Formio.getProjectUrl() + '/' + schema.path, 'put', schema, undefined);
						await buildNestedComponents(newForm, isBestPractice, platformType, inSandbox, templateDataFieldMappings);
					})
				);
			}
		});
	}

	await Promise.all(promises);
}

export async function handleFormCopy(
	schema: FormioFormSchema,
	rootInfo: any,
	componentInfo: any,
	templateDataFieldMappings: { [templateDataFieldId: number]: number },
	isBestPractice: boolean,
	id?: number
): Promise<any> {
	id = id || 1;
	const newForm = await buildCopiedForm(schema, id, rootInfo, componentInfo, templateDataFieldMappings, isBestPractice);
	let existingForm = await Formio.makeRequest(undefined, 'form', Formio.getProjectUrl() + '/form?path=' + newForm.path, 'get', null, { ignoreCache: true });
	if (existingForm.length === 0) {
		return await Formio.makeRequest(undefined, 'form', Formio.getProjectUrl() + '/form', 'post', newForm, undefined);
	} else {
		return await handleFormCopy(newForm, rootInfo, componentInfo, templateDataFieldMappings, isBestPractice, (id as number) + 1);
	}
}

export function removeInvalidFormsForCurrentComponent(component: any, items: any[]) {
	if (items == null || !Array.isArray(items)) {
		return [];
	}

	const formTypeRegex = new RegExp(`/${component.component.componentType}/[0-9]+/?$`);
	return items.filter(item => {
		return formTypeRegex.test(item.path);
	});
}
