// TODO: figure out if this guy should live in the JPI

export enum LoanRoleType {
	PrimaryBorrower = 'Primary Borrower',
	CoBorrower = 'Co-Borrower',
	AuthorizedSigner = 'Authorized Signer',
	CoSigner = 'Cosigner',
	CreditApplicant = 'Credit Applicant',
	Trustee = 'Trustee',
	BeneficialOwner = 'Beneficial Owner',
	Guarantor = 'Guarantor'
}
