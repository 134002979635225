export enum FormioHookName {
	component = 'component',
	attachComponent = 'attachComponent',
	setDataValue = 'setDataValue',
	addComponents = 'addComponents',
	addComponent = 'addComponent',
	attachComponents = 'attachComponents',
	beforeCancel = 'beforeCancel',
	beforeSubmit = 'beforeSubmit',
	customValidation = 'customValidation',
	treeAddChild = 'tree.addChild',
	treeCancelNode = 'tree.cancelNode',
	treeEditNode = 'tree.editNode',
	treeRemoveNode = 'tree.removeNode',
	treeRevertNode = 'tree.revertNode',
	treeSaveNode = 'tree.saveNode',
	treeToggleNode = 'tree.toggleNode',
	treeSetRoot = 'tree.setRoot',
	onCalendarOpen = 'onCalendarOpen',
	onCalendarClose = 'onCalendarClose'
}

export const CustomOnSubmitHookName = (componentType: string) => {
	return `on-${componentType}-submit`;
};

export const CustomOnDeleteHookName = (componentType: string) => {
	return `on-${componentType}-delete`;
};