
import { Component, Mixins } from 'vue-property-decorator';
import { ScaleMixin, RouteMixin } from '@/mixins';
import { required, email } from 'vuelidate/lib/validators';

@Component({
	validations: {
		email: {
			required,
			email
		}
	}
})
export default class LoginEmail extends Mixins(ScaleMixin, RouteMixin) {
	private email: string | null = null;
	private showValidation: boolean = false;
	private invalidFeedback: string = 'Please enter a valid email address';

	private mounted() {
		this.email = this.directStore.state.Login.userEmail;
		(this.$refs.emailInput as any).$el.focus();
	}

	private beforeDestroy() {
		if (this.email === '' || !this.$v.email.$invalid) {
			this.directStore.dispatch.Login.setUserEmail(this.email);
		}
	}

	get emailValidationState() {
		if (!this.showValidation) {
			return null;
		} else if (this.$v.email.$invalid) {
			return false;
		}
		return true;
	}

	private submit(): void {
		this.showValidation = true;
		if (this.$v.$invalid) {
			return;
		}

		this.directStore.dispatch.Login.setUserEmail(this.email);
		this.$router.push(this.OnlinePortalRoutes.LoginPassword.path);
	}
}
