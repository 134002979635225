import { Components } from 'formiojs';
import { CustomComponentType, FormioComponentType } from '../enums';
import ComponentBaseWithCustomTypings from 'formiojs/components/_classes/component/Component';

const componentManager = {
	CustomComponents: {} as any,
	FormioComponentOverrides: {} as any
};

export default {
	CustomComponents: {},
	FormioComponentOverrides: {}
};

export function initializeComponentsManager(componentList: any, componentOverrides: any) {
	for (let componentType in componentList) {
		// @ts-ignore
		componentManager.CustomComponents[componentType] = componentList[componentType];
	}

	for (let componentType in componentOverrides) {
		// @ts-ignore
		componentManager.FormioComponentOverrides[componentType] = componentOverrides[componentType];
	}
}
export const useComponentManager = () => {
	/**
	 * Adds custom components to formio
	 * NOTE: If a value for allowedComponents is not passed, then all custom components will be added to formio
	 *
	 * @param allowedComponents optional: specifies which components will be allowed
	 */
	function addCustomComponents(allowedComponents: typeof ComponentBaseWithCustomTypings[] | null = null) {
		if (allowedComponents) {
			allowedComponents.forEach(component => {
				componentManager.CustomComponents[component.schema().type] = component;
				Components.addComponent(component.schema().type, component);
			});
		} else {
			// Allow all custom components if a value for allowedComponents was not passed
			Object.keys(componentManager.CustomComponents).forEach(key => {
				const type = key as CustomComponentType;
				Components.addComponent(type, componentManager.CustomComponents[type]);
			});
		}
	}

	/**
	 * Adds formio component overrides to formio
	 * NOTE: If a value for allowedComponents is not passed, then all formio component overrides will be added to formio
	 *
	 * @param allowedComponents optional: specifies which components will be allowed
	 */
	function addComponentOverrides(allowedComponents: typeof ComponentBaseWithCustomTypings[] | null = null) {
		if (allowedComponents) {
			allowedComponents.forEach(component => {
				componentManager.FormioComponentOverrides[component.schema().type] = component;
				Components.addComponent(component.schema().type, component);
			});
		} else {
			// Allow all custom components if a value for allowedComponents was not passed
			Object.keys(componentManager.FormioComponentOverrides).forEach(key => {
				const type = key as FormioComponentType;
				Components.addComponent(type, componentManager.FormioComponentOverrides[type]);
			});
		}
	}

	return {
		componentManager,
		addComponentOverrides,
		addCustomComponents
	};
};
