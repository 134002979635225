import { DataField } from '@sageworks/jpi';
import { convertDataFieldToComponent } from '../data-field-metadata/data-field-metadata-utils';
import { FormioComponentSchema, FormioValidationSchema } from '../../formio-interfaces';
import { CustomComponentType } from '../../enums';
import { useComponentManager } from '../../components/component-manager';

export class StaticFormUtils {
	static dataFieldListToSchemaMap(dataFields: DataField[], componentType?: CustomComponentType) {
		const initialValue: { [defaultId: number]: FormioComponentSchema<any, FormioValidationSchema> } = {};
		const { componentManager } = useComponentManager();
		return dataFields.reduce((map, field) => {
			const { schema } = convertDataFieldToComponent(field, { weight: 0, parentComponentType: componentType });
			const schemaType = schema?.type as CustomComponentType | undefined;

			if (schemaType && field.templateDataFieldId) {
				const componentClass = componentManager.CustomComponents[schemaType];

				if (!componentClass) {
					return map;
				}

				// Need to call the schema method on the component to ensure we have the entire schema
				map[field.templateDataFieldId] = componentClass.schema(schema);
			}

			return map;
		}, initialValue);
	}
}
