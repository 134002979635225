import { AutoFactory, ApplicationTemplate, DynamicApplicationMessagesService, DynamicApplicationMessage } from '@sageworks/jpi';
import { defineModule, localActionContext } from 'direct-vuex';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => localActionContext(context, UserCreatedMessagesModule);

export interface UserCreatedMessagesModuleState {
	DocumentsPageMessageHtml: string | null;
	SubmitApplicationPreMessageHtml: string | null;
	SubmitApplicationPostMessageHtml: string | null;
}
const UserCreatedMessagesModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			DocumentsPageMessageHtml: null,
			SubmitApplicationPreMessageHtml: null,
			SubmitApplicationPostMessageHtml: null
		} as UserCreatedMessagesModuleState;
	},
	mutations: {
		SET_DOCUMENTS_PAGE_MESSAGE(state, DocumentsPageMessageHtml: string | null) {
			state.DocumentsPageMessageHtml = DocumentsPageMessageHtml;
		},
		SET_SUBMIT_APPLICATION_PRE_MESSAGE(state, SubmitApplicationPreMessageHtml: string | null) {
			state.SubmitApplicationPreMessageHtml = SubmitApplicationPreMessageHtml;
		},
		SET_SUBMIT_APPLICATION_POST_MESSAGE(state, SubmitApplicationPostMessageHtml: string | null) {
			state.SubmitApplicationPostMessageHtml = SubmitApplicationPostMessageHtml;
		}
	},
	actions: {
		async fetchDocumentsPageMessage(context, applicationType: ApplicationTemplate.TypeEnum): Promise<void> {
			const { commit } = actionContext(context);
			const dynamicApplicationMessagesService = AutoFactory.get(DynamicApplicationMessagesService);

			const appTypes = [applicationType];
			const messageTypes = [DynamicApplicationMessage.MessageTypeEnum.DocumentsPage];
			const response = await dynamicApplicationMessagesService.getPaged(1, 1, appTypes, messageTypes);

			if (response.items != null && response.items.length === 1) {
				const DocumentsPageMessageHtml = response.items[0].message ?? '';
				commit.SET_DOCUMENTS_PAGE_MESSAGE(DocumentsPageMessageHtml);
			} else {
				commit.SET_DOCUMENTS_PAGE_MESSAGE('');
			}
		},
		async fetchSubmitApplicationPreMessage(context, applicationType: ApplicationTemplate.TypeEnum): Promise<void> {
			const { commit } = actionContext(context);
			const dynamicApplicationMessagesService = AutoFactory.get(DynamicApplicationMessagesService);

			const appTypes = [applicationType];
			const messageTypes = [DynamicApplicationMessage.MessageTypeEnum.SubmitApplicationPreSubmittal];
			const response = await dynamicApplicationMessagesService.getPaged(1, 1, appTypes, messageTypes);

			if (response.items != null && response.items.length === 1) {
				const SubmitApplicationPreMessageHtml = response.items[0].message ?? '';
				commit.SET_SUBMIT_APPLICATION_PRE_MESSAGE(SubmitApplicationPreMessageHtml);
			} else {
				commit.SET_SUBMIT_APPLICATION_PRE_MESSAGE('');
			}
		},
		async fetchSubmitApplicationPostMessage(context, applicationType: ApplicationTemplate.TypeEnum): Promise<void> {
			const { commit } = actionContext(context);
			const dynamicApplicationMessagesService = AutoFactory.get(DynamicApplicationMessagesService);

			const appTypes = [applicationType];
			const messageTypes = [DynamicApplicationMessage.MessageTypeEnum.SubmitApplicationPostSubmittal];
			const response = await dynamicApplicationMessagesService.getPaged(1, 1, appTypes, messageTypes);

			if (response.items != null && response.items.length === 1) {
				const SubmitApplicationPostMessageHtml = response.items[0].message ?? '';
				commit.SET_SUBMIT_APPLICATION_POST_MESSAGE(SubmitApplicationPostMessageHtml);
			} else {
				commit.SET_SUBMIT_APPLICATION_POST_MESSAGE('');
			}
		}
	},
	getters: {
		DocumentsPageMessageHtml(state): string | null {
			return state?.DocumentsPageMessageHtml ?? null;
		},
		SubmitApplicationPreMessageHtml(state): string | null {
			return state?.SubmitApplicationPreMessageHtml ?? null;
		},
		SubmitApplicationPostMessageHtml(state): string | null {
			return state?.SubmitApplicationPostMessageHtml ?? null;
		}
	}
});

export default UserCreatedMessagesModule;
