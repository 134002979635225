import Component from 'formiojs/components/_classes/component/Component';
import { Customer } from '@sageworks/jpi';
import { EventUtils } from '../../../utils';

export default class CustomerSearchOptions extends Component {
	private searchResults!: Customer[];

	constructor(component: any, options: object, data: object) {
		super(component, options, data);
		this.searchResults = [];
	}

	public render() {
		return this.renderTemplate('customerSearchOptions', {
			searchResults: this.searchResults || []
		});
	}

	public async attach(element: HTMLElement) {
		await super.attach(element);

		this.loadRefs(element, {
			optionsList: 'single',
			optionItem: 'multiple',
			optionsDropdown: 'single'
		});

		const { optionItem } = this.refs as any;
		optionItem.forEach((option: HTMLElement) => {
			this.addEventListener(option, 'click', (e: any) => this.handleCustomerSelect(e));
			this.addEventListener(option, 'keypress', (e) => {
				if (EventUtils.isClickEquivalent(e)) {
					this.handleCustomerSelect(e);
				}
			});
		});
	}

	public setSearchResults(results: Customer[]) {
		if (results.length === 0) {
			results.push({
				id: -1,
				name: 'No results'
			} as Customer);
		}

		this.searchResults = results;
		this.redraw();
		this.checkAndShowSearchResults();
	}

	public hide() {
		this.addClass(this.refs.optionsDropdown, 'd-none');
	}

	public checkAndShowSearchResults() {
		if (this.searchResults.length > 0) {
			this.removeClass(this.refs.optionsDropdown, 'd-none');
		}
	}

	private handleCustomerSelect(e: any) {
		const selectedId = Number.parseInt(e.target.id);
		const customer = this.searchResults.find(x => x.id === selectedId);
		this.emit('customerSearchSelect', customer);
	}
}
