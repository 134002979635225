
import { ApplicationSpinner, ApplicationWizardCard } from '@/components/application-wizard';
import { AuthenticatedForm, DynamicFormsContextData, FormSubmissionDataUtils } from '@sageworks/dynamic-forms';
import { UrlUtils } from '@/utils';
import { IAppConfiguration } from '@/models';
import Vue from 'vue';
import { OnlinePortalRoutes } from '@/OnlinePortalRoutes';
import { FormioFormSchema } from '@sageworks/dynamic-forms';
import { mapState } from 'vuex';
import {UsableProducts} from '@sageworks/jpi/';

export default Vue.extend({
	name: 'ApplicationPreapp',
	components: {
		ApplicationWizardCard,
		AuthenticatedForm,
		ApplicationSpinner
	},
	data() {
		return {
			applicationLoading: false
		};
	},
	computed: {
		...mapState('Authentication', ['apiToken']),
		formPath(): string {
			return 'application/preApp/' + this.directStore.getters.ApplicationTemplates.preAppTemplateId;
		},
		subdomain(): string {
			return UrlUtils.getOnlinePortalSubdomain();
		},
		loaded(): boolean {
			return this.directStore.state.AppConfig.appConfig != null && !this.applicationLoading;
		},
		formioUrl(): string {
			if (!this.loaded) {
				return '';
			}

			return (this.directStore.state.AppConfig.appConfig as IAppConfiguration).formioUrl;
		},
		currentUserId(): number | undefined {
			return this.directStore.state.User.user?.id;
		},
		currentProxyUserId(): number | undefined {
			return this.directStore.state.User.proxyUser?.id;
		},
		currentUserIsLender(): boolean {
			return this.directStore.getters.User.isLender;
		},
		contextData(): DynamicFormsContextData {
			return {
				applicationId: 0,
				productName: 'Pre Application',
				hideNavigationHeader: true,
				isPreapp: true,
				showSubmit: true,
				userInfo: {
					userId: this.currentUserId,
					proxyUserId: this.currentProxyUserId
				}
			};
		},
		hasCommunityLendingSubscription(): boolean | undefined {
			return this.directStore.state.UsableProducts.usableProducts?.has('abrigoCommunityLending' as UsableProducts.ProductsEnum);
		},
		itemLabel(): string {
			if (this.hasCommunityLendingSubscription === undefined) {
				return '';
			}
			return this.hasCommunityLendingSubscription ? 'Request Form': 'Application';
		}
	},
	methods: {
		async startApplication({ data, form }: { data: any; form: FormioFormSchema }) {
			var preAppData = FormSubmissionDataUtils.buildPreAppSelections(form, data);

			if (preAppData.applicationType && preAppData.proposedProductsId) {
				try {
					this.applicationLoading = true;
					await this.directStore.dispatch.StartApplication.startApplication(preAppData);
					this.$router.push({
						name: OnlinePortalRoutes.ApplicationForm.name,
						params: { applicationId: this.directStore.getters.StartApplication.applicationId.toString() }
					});
				} catch {
					this.showToast(`Pre-${this.itemLabel}`, `Failed to start ${this.itemLabel.toLowerCase()}.`);
				} finally {
					this.applicationLoading = false;
				}
			}
		},
		showToast(title: string, message: string) {
			this.$root.$bvToast.toast(message, {
				title: title,
				solid: true,
				autoHideDelay: 3000,
				toaster: 'b-toaster-bottom-right'
			});
		}
	}
});
