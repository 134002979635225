
import Vue, { PropType } from 'vue';

export default Vue.extend({
	name: 'RecreateSignatureModal',
	components: {},
	props: {
		applicationId: {
			type: Number as PropType<number | null>,
			required: true
		}
	},
	data() {
		return {
			dataIsLoaded: false,
			fields: [
				{
					key: 'role',
					label: 'Role'
				},
				{
					key: 'name',
					label: 'Name'
				},
				{
					key: 'email',
					label: 'Email'
				}
			]
		};
	},
	computed: {
		recipientInformation() {
			return this.directStore.state.AdobeESignModule.applicationRecipientInformation ?? [];
		}
	},
	mounted() {},
	methods: {
		resendEmailsForSignatures() {
			this.$emit('recreate-signatures');
		},
		async loadInitialData() {
			try {
				if (!this.dataIsLoaded) {
					await this.directStore.dispatch.AdobeESignModule.fetchLoanApplicationRecipientInformation({ applicationId: this.applicationId as number });
				}
			} catch (e) {
				// TODO: frontend logging
				this.showToast('Loading Failure', 'An error occured while loading the recipient information.');
			}
			this.dataIsLoaded = true;
		},
		showToast(title: string, message: string) {
			this.$root.$bvToast.toast(message, {
				title: title,
				solid: true,
				autoHideDelay: 3000,
				toaster: 'b-toaster-bottom-right'
			});
		}
	}
});
