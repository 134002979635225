import { CustomComponentType } from '@sageworks/dynamic-forms';
import { BeneficialOwnership, PrimaryBorrower, CoBorrower, GuarantorEntity, CoSigner, CreditApplicant, PlaidComponent } from '../../components';
export class ComponentCreator {
	constructor(private rootComponent: any) {}

	public createHook() {
		return ({ componentType, schema }: { componentType: string; schema: any }) => this.create(componentType, schema);
	}

	private create(componentType: string, schema: any) {
		let component: any;

		switch (componentType) {
			case CustomComponentType.beneficialOwnershipVue:
				component = this.createBeneficialOwnershipComponent(schema);
				break;
			case CustomComponentType.primaryBorrowerEntityVue:
				component = new PrimaryBorrower({
					parent: this.rootComponent
				});
				break;
			case CustomComponentType.coBorrowerEntityVue:
				component = new CoBorrower({
					parent: this.rootComponent
				});
				break;
			case CustomComponentType.guarantorEntityVue:
				component = new GuarantorEntity({
					parent: this.rootComponent
				});
				break;
			case CustomComponentType.coSignerEntityVue:
				component = new CoSigner({
					parent: this.rootComponent
				});
				break;
			case CustomComponentType.creditApplicantEntityVue:
				component = new CreditApplicant({
					parent: this.rootComponent
				});
				break;
			case CustomComponentType.plaid:
				component = new PlaidComponent({
					parent: this.rootComponent,
					propsData: {
						componentId: schema.id,
						addNewBtnLabel: schema?.addAnother === null || schema?.addAnother === '' ? undefined : schema?.addAnother
					}
				});
				break;
		}

		component?.$mount();

		return component;
	}

	private createBeneficialOwnershipComponent(schema: any) {
		return new BeneficialOwnership({
			parent: this.rootComponent,
			propsData: {
				componentId: schema.id,
				addNewBtnLabel: schema?.addAnother === null || schema?.addAnother === '' ? undefined : schema?.addAnother,
				validationConfig: {
					required: schema.validate?.required,
					multiple: schema.allowRepeat,
				}
			}
		});
	}
}
