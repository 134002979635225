
import Vue from 'vue';
import { UsableProducts } from '@sageworks/jpi';
import { OnlinePortalRoutes } from '@/OnlinePortalRoutes';

export default Vue.extend({
	name: 'PortalCardNavigationBar',
	components: {},
	computed: {
		opnRoutes() {
			return OnlinePortalRoutes;
		},
		applicationsNavItemName(): string {
			if (this.hasCommunityLendingSubscription === undefined) {
				return '';
			}
			return this.hasCommunityLendingSubscription ? 'Request Forms': 'Applications';
		},
		hasCommunityLendingSubscription(): boolean | undefined {
			return this.directStore.state.UsableProducts.usableProducts?.has('abrigoCommunityLending' as UsableProducts.ProductsEnum);
		},
		activeTab() {
			if (this.$route.path.includes(this.opnRoutes.Applications.path)) {
				return 'applications';
			} else if (this.$route.path.includes(this.opnRoutes.Documents.path)) {
				return 'documents';
			} else if (this.$route.path.includes(this.opnRoutes.Home.path)) {
				return 'tasks';
			} else if (this.$route.path.includes(this.opnRoutes.MyInfo.path)) {
				return 'myInfo';
			} else {
				return '';
			}
		}
	},
	methods: {
		setItemClasses(tabName: string) {
			return { 
				'bg-white': this.activeTab !== tabName,
				active: this.activeTab === tabName,
				'router-link-active': this.activeTab === tabName
			};
		}
	}
});
