module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div ref="value">\n	';
 
	var industryCodeTemplateId = 10
	if (ctx.value) {
		if (ctx.self.selectOptions && ctx.self.selectOptions.length > 0 && ctx.component.templateDataFieldId !== industryCodeTemplateId) {
			var optionFound = ctx.self.selectOptions.find(option => {
				return option.value == ctx.value;
			});

			if (optionFound != undefined) { ;
__p += '\n				' +
((__t = ( optionFound.label )) == null ? '' : __t) +
'	\n			';
 }
			else { ;
__p += '\n				' +
((__t = ( ctx.self.itemValue(ctx.value) )) == null ? '' : __t) +
'\n			';
 }
		}
		else { ;
__p += '\n			' +
((__t = ( ctx.value )) == null ? '' : __t) +
'\n		';

			
			if (ctx.options.builderTemplateHelpers.hasOwnProperty('reloadSelectComponentOptions')) {
				// This will trigger a force reload of the option items
				ctx.options.builderTemplateHelpers.reloadSelectComponentOptions(ctx.self)
			}			
		}
	}
	;
__p += '\n</div>';
return __p
}