
import Vue, { PropType } from 'vue';
import { DemographicInformationAssociations, FeatureFlagging, DynamicApplicationGMIFormLink } from '@sageworks/jpi';

import { formIsComplete } from '@/utils/hmda-gmi-api-helper';
import HmdaGmiForm from '../hmda-gmi-form.vue';
import DemographicForm1071 from './demographic-form-1071.vue';
export default Vue.extend({
	name: 'DemographicForms',
	components: { DemographicForm1071, HmdaGmiForm },
	props: {
		applicationId: {
			type: String as PropType<string | null>,
			default: null
		},
		ownersRequiring1071Forms: {
			type: Array as PropType<DemographicInformationAssociations[]>,
			required: true
		},
		customers: {
			type: Object as PropType<{ [customerId: string]: string } | null>,
			default: null
		}
	},
	data() {
		return {
			isLoading: true,
			canShowGmiForm: false,
			demographicFormsEmailsEnabled: false,
			formLinkIntervalId: 0,
			formLinkTimeoutId: 0,
			intervalLength: 2000,
			timeoutLength: 300000
		};
	},
	computed: {
		loanApplicationId(): number {
			if (!this.applicationId) {
				return 0;
			}
			const id = parseInt(this.applicationId, 10);
			return Number.isNaN(id) ? 0 : id;
		},
		hmdaGmiFormsByCustomerId() {
			return this.directStore.getters.HmdaGmi.gmiFormLinksByCustomerId;
		},
		shouldShowHmdaGmiForms(): boolean {
			return this.canShowGmiForm && Object.keys(this.hmdaGmiFormsByCustomerId).length > 0;
		},
		shouldShow1071Forms(): boolean {
			return this.demographicFormsEmailsEnabled && this.ownersRequiring1071Forms.length > 0;
		}
	},
	async beforeMount(): Promise<void> {
		this.canShowGmiForm = await this.directStore.dispatch.FeatureFlag.fetchIsFeatureFlagActive(FeatureFlagging.FeatureFlagEnum.EnableHmdainDya);
		if (this.canShowGmiForm) {
			this.directStore.dispatch.HmdaGmi.loadGmiForms({ loanApplicationId: this.loanApplicationId });
		}
		this.demographicFormsEmailsEnabled = await this.directStore.dispatch.FeatureFlag.fetchIsFeatureFlagActive(
			FeatureFlagging.FeatureFlagEnum.Enable1071FormEmails
		);
	},
	methods: {
		async formLinkOpened(formLinkInfo: DynamicApplicationGMIFormLink) {
			clearInterval(this.formLinkIntervalId);
			clearTimeout(this.formLinkTimeoutId);

			// Stop checking after 5 minutes, clear interval
			this.formLinkTimeoutId = setTimeout(() => {
				clearInterval(this.formLinkIntervalId);
			}, this.timeoutLength);

			this.formLinkIntervalId = setInterval(() => this.checkForCompleteForm(formLinkInfo), this.intervalLength);
		},
		async checkForCompleteForm(formLinkInfo: DynamicApplicationGMIFormLink) {
			const isComplete = await formIsComplete(formLinkInfo);
			if (isComplete) {
				clearInterval(this.formLinkIntervalId);
				clearTimeout(this.formLinkTimeoutId);
				await this.directStore.dispatch.HmdaGmi.loadGmiForms({ loanApplicationId: this.loanApplicationId });
			}
		}
	}
});
