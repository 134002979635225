export default {
	key: 'columns',
	components: [
		{
			key: 'offset',
			ignore: true
		},
		{
			key: 'push',
			ignore: true
		},
		{
			key: 'pull',
			ignore: true
		}
	],
	validate: {
		custom:
			'var columnWidthSum = data.columns.reduce((partialSum, a) => partialSum + a.width, 0);' +
			'valid = columnWidthSum <= 12 ? true : "The sum of all column widths must be equal to 12 or less";'
	}
};
