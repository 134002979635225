import { CustomComponentType } from '../../../enums/custom-component-type';
import { AutoFactory, ApplicationTypesService, CollectionResponseApplicationType } from '@sageworks/jpi';
import EditableApiSelectOptions from '../editable-api-select-options/editable-api-select-options';

export default class AppTypeSelectOptions extends EditableApiSelectOptions {
	static schema(...extend: any) {
		return super.schema(
			{
				type: CustomComponentType.appTypeSelectOptions
			},
			...extend
		);
	}

	static get builderInfo() {
		return {
			weight: 10,
			schema: AppTypeSelectOptions.schema({})
		};
	}

	protected getApiOptions(): Promise<CollectionResponseApplicationType> {
		const applicationTypesService: ApplicationTypesService = AutoFactory.get(ApplicationTypesService);
		return applicationTypesService.get();
	}

	protected getOptionLabelForItem(item: any): string {
		switch (item) {
			case 'business':
				return 'Business';
			case 'person':
				return 'Person';
			case 'sba':
				return 'SBA';
			case 'nonprofit':
				return 'Nonprofit';
			default:
		}
		return item ? item + '' : '';
	}
}
