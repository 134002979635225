
import Vue, { PropType } from 'vue';
import { OnlinePortalProposedLoanStage, OnlinePortalProposedLoanStageGroup } from '@sageworks/jpi';

export default Vue.extend({
	name: 'StageGroupProgressBar',
	props: {
		currentStage: Object as PropType<OnlinePortalProposedLoanStage>,
		stageGroups: {
			type: Array as PropType<OnlinePortalProposedLoanStageGroup>,
			required: true,
		}
	},
	computed: {
		currentStageGroup(): OnlinePortalProposedLoanStageGroup | undefined {
			const stageGroupId = this.currentStage?.proposedLoanStageGroupsID ?? -1;

			return this.stageGroups.find(x => x.id === stageGroupId);
		}
	},
	methods: {
		createBlockStyleClasses(stageGroup: OnlinePortalProposedLoanStageGroup, index: number) {
			return {
				'bg-gray-200': !this.currentStageGroup || stageGroup.displayOrder > this.currentStageGroup?.displayOrder,
				'bg-gray-300': stageGroup.displayOrder < this.currentStageGroup?.displayOrder,
				'rounded-left': stageGroup.displayOrder === 1,
				'rounded-right': index + 1 === this.stageGroups.length
			};
		},
		createBlockOverrideStyles(stageGroup: OnlinePortalProposedLoanStageGroup) {
			if (this.currentStageGroup?.id !== stageGroup.id) {
				return {};
			}

			return {
				'background-color': `#${this.currentStage.color ?? ''}`
			};
		}
	}
});
