import { PrimaryRoleRepeaterWidget } from '../primary-role-repeater-widget/primary-role-repeater-widget';
import editForm from './guarantor-entity.form';
import { CustomComponentType, CustomBuilderGroups, LoanRoleType } from '../../../enums';

export default class GuarantorEntityWidget extends PrimaryRoleRepeaterWidget {
	static schema(...extend: any) {
		return PrimaryRoleRepeaterWidget.schema(
			{
				label: 'Guarantor',
				type: CustomComponentType.guarantorEntity,
				key: CustomComponentType.guarantorEntity,
				addAnother: 'Add a Guarantor'
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: 'Guarantor',
			group: CustomBuilderGroups.widgets,
			weight: 10,
			schema: GuarantorEntityWidget.schema({
				skipRemoveConfirm: true
			})
		};
	}

	init() {
		this.roleType = LoanRoleType.Guarantor;

		super.init();
	}
}
