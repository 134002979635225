import { CollectionResponseDataField, DataField } from '@sageworks/jpi';
import { DataFieldAlias } from '../enums';

export interface GetPropertiesParams {
	fetch: (page: number, perPage: number, fields?: DataFieldAlias[]) => Promise<CollectionResponseDataField>;
	allowedFields?: DataFieldAlias[];
}

export const fetchAllProperties = async ({ fetch, allowedFields }: GetPropertiesParams, perPage = 500) => {
	let pageNumber = 1;
	let dataFields: DataField[] = [];
	let response: CollectionResponseDataField | null = null;
	perPage = Math.max(perPage, allowedFields?.length ?? 0);

	while (!response || response.items?.length === perPage) {
		response = await fetch(pageNumber, perPage, allowedFields);
		dataFields = [...dataFields, ...response.items ?? []];
		pageNumber += 1;
	}

	return dataFields;
};