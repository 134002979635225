
import Vue from 'vue';
import { BModal } from 'bootstrap-vue';
import { TuxInputText } from '@tuxedo/components/src/components';
import { AutoFactory, OnlinePortalRegulation1071EmailService, Regulation1071Email } from '@sageworks/jpi';
import { getFailureOptions } from '../../../../../dynamic-forms/src/utils/toastr-utils';

export default Vue.extend({
	name: 'SendDemographicRequestPopup',
	components: { BModal, TuxInputText },
	props: {
		customerId: {
			type: Number,
			required: true
		},
		proposedLoanId: {
			type: Number,
			required: true
		},
		ownerName: {
			type: String,
			required: true
		},
		ownerEmail: {
			type: String,
			default: '',
			required: true
		},
		open: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			userEmail: this.ownerEmail,
			ownerNameOnEmail: this.ownerName
		};
	},
	watch: {
		open(val) {
			if (val) {
				(this.$refs.modal as BModal).show();
			} else {
				(this.$refs.modal as BModal).hide();
			}
		}
	},
	mounted() {
		// attaches our new functionality to the bootstrap hidden event
		(this.$refs.modal as BModal).$on('hidden', this.emitCloseEvent);
	},
	methods: {
		async handleEmailForm() {
			if (!this.customerId || !this.proposedLoanId) {
				return;
			}
			try {
				const service = AutoFactory.get(OnlinePortalRegulation1071EmailService);
				const emailData: Regulation1071Email = {
					proposedLoanId: this.proposedLoanId,
					profitEntityId: this.customerId,
					regulation1071EmailTemplateType: Regulation1071Email.Regulation1071EmailTemplateTypeEnum.DemographicDataRequest,
					recipientEmail: this.userEmail,
					recipientName: this.ownerNameOnEmail
				};
				await service.send(emailData);
			} catch {
				this.$root.$bvToast.toast(`Error sending Email.`, getFailureOptions());
			} finally {
				this.$emit('email-sent');
				this.closeModal();
			}
		},
		closeModal() {
			(this.$refs.modal as BModal).hide();
		},
		emitCloseEvent() {
			this.$emit('modal-closed');
		}
	}
});
