import { BeneficialOwnerFormDataModel, Customer, DataObject, ObjectPropertyValues } from '@sageworks/jpi';
import { TemplateDataField } from '@sageworks/dynamic-forms';
import { getCustomerName } from '../customer-store';
import { OwnerRowData } from '../../models';
import { BeneficialOwnership1071Status, BeneficialOwnership1071StatusByCustomerId } from './beneficial-ownership-1071-status';

export const createFormattedList = (
	beneficialOwners: Array<BeneficialOwnerFormDataModel>,
	dataObjects: { [key: string]: Array<DataObject> },
	templateDataFieldIdToDataFieldId: { [templateDataFieldId: number]: number },
	demographic1071Statuses: BeneficialOwnership1071StatusByCustomerId
) => {
	return beneficialOwners.map<OwnerRowData>(owner => {
		return {
			id: owner.id ?? 0,
			customerId: owner.customerId,
			ownerCustomerId: owner.ownerCustomerId,
			roleType: owner.role,
			lastUpdated: owner.lastUpdated,
			ownerName: getCustomerName(owner.ownerCustomerId ?? 0, dataObjects, templateDataFieldIdToDataFieldId),
			ownershipPercent: owner.ownershipPercent ?? 0,
			entityType: owner.ownerEntityType,
			demographic1071InformationStatus: owner.ownerEntityType === BeneficialOwnerFormDataModel.OwnerEntityTypeEnum.Person
				? demographic1071Statuses[owner.ownerCustomerId ?? -1]
				: BeneficialOwnership1071Status.NotApplicable
		};
	});
};

export const convertToObjectPropertyValues = (
	data: OwnerRowData,
	templateDataFieldIdToDataFieldId: { [templateDataFieldId: number]: number }
): ObjectPropertyValues => {
	const customerIdFieldId = templateDataFieldIdToDataFieldId[TemplateDataField.BeneficialOwnerCompanyCustomerId];
	const ownerCustomerIdFieldId = templateDataFieldIdToDataFieldId[TemplateDataField.BeneficialOwnerOwnerCustomerId];
	const roleTypeFieldId = templateDataFieldIdToDataFieldId[TemplateDataField.BeneficialOwnerRoleType];
	const lastUpdatedFieldId = templateDataFieldIdToDataFieldId[TemplateDataField.BeneficialOwnerDateUpdated];
	const ownershipPercentFieldId = templateDataFieldIdToDataFieldId[TemplateDataField.BeneficialOwnerPercent];

	return {
		id: data.id,
		dataFieldValues: [
			{ id: customerIdFieldId, value: data.customerId as any },
			{ id: ownerCustomerIdFieldId, value: data.ownerCustomerId as any },
			{ id: roleTypeFieldId, value: data.roleType as any },
			{ id: lastUpdatedFieldId, value: data.lastUpdated as any },
			{ id: ownershipPercentFieldId, value: data.ownershipPercent as any }
		]
	};
};

export const convertCustomerTypeToOwnerEntityType = (customerType: Customer.TypeEnum | null): BeneficialOwnerFormDataModel.OwnerEntityTypeEnum | null => {
	switch (customerType) {
		case Customer.TypeEnum.Business:
			return BeneficialOwnerFormDataModel.OwnerEntityTypeEnum.Business;
		case Customer.TypeEnum.Person:
			return BeneficialOwnerFormDataModel.OwnerEntityTypeEnum.Person;
		case Customer.TypeEnum.Farm:
			return BeneficialOwnerFormDataModel.OwnerEntityTypeEnum.Farm;
		case Customer.TypeEnum.NonProfit:
			return BeneficialOwnerFormDataModel.OwnerEntityTypeEnum.NonProfit;
		default:
			return null;
	}
};