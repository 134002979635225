import baseEditForm from 'formiojs/components/currency/Currency.form';

import ForceRequiredField from '../../../configuration-components/force-required/force-required';
import OriginalLabel from '../../../configuration-components/base-widget-configuration/display/original-label';
import BaseEditConditionalComponents from '../../../configuration-components/base-widget-configuration/base.edit.conditional.components';
import { BaseEditData } from '../../../configuration-components/base-widget-configuration/data/base.edit.data';
import TextFieldComponents from '../extended-text-field/extended-text-field.form.data';
import LenderOnly from '../../../configuration-components/base-widget-configuration/display/lender-only-field-checkbox';
// eslint-disable-next-line max-lines-per-function
export default function(...extend: any[]) {
	return baseEditForm(
		[
			{
				key: 'api',
				ignore: true
			},
			{
				key: 'display',
				components: [OriginalLabel, LenderOnly]
			},
			new BaseEditData().getTab({
				components: {
					defaultComponents: [
						{
							key: 'currency',
							ignore: true
						},
						...TextFieldComponents
					]
				}
			}),
			{
				key: 'validation',
				components: [
					...ForceRequiredField,
					{
						type: 'currency',
						label: 'Minimum Value',
						key: 'validate.min',
						input: true,
						placeholder: 'Minimum Value',
						tooltip: 'The minimum value this field must have before the form can be submitted.',
						weight: 150
					},
					{
						type: 'currency',
						label: 'Maximum Value',
						key: 'validate.max',
						input: true,
						placeholder: 'Maximum Value',
						tooltip: 'The maximum value this field can have before the form can be submitted.',
						weight: 160
					}
				]
			},
			{
				key: 'conditional',
				components: BaseEditConditionalComponents
			}
		],
		...extend
	);
}
