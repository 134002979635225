import { defineModule, localActionContext } from 'direct-vuex';
import { ObjectUtils } from '@/utils';
import { MediaBreakpoints } from '@/enums';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => localActionContext(context, WindowInfoModule);

export interface WindowInfoModuleState {
	scaleBreakpoints: Set<String>;
	currentBreakpoint: string;
	shouldScaleDownComponents: boolean;
}
const WindowInfoModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			scaleBreakpoints: new Set(['xs', 'sm']),
			currentBreakpoint: 'xs',
			shouldScaleDownComponents: false
		} as WindowInfoModuleState;
	},
	mutations: {
		SET_CURRENT_BREAKPOINT(state, newBreakpoint: string) {
			state.currentBreakpoint = newBreakpoint;
		},
		SET_SCALE_DOWN_COMPONENTS(state, scaleDownComponents: boolean) {
			state.shouldScaleDownComponents = scaleDownComponents;
		}
	},
	actions: {
		recalculateCurrentBreakpoint(context) {
			const { state, commit } = actionContext(context);
			const windowWidth: number = window.innerWidth;
			for (const key of ObjectUtils.getObjectKeys(MediaBreakpoints)) {
				if (windowWidth < MediaBreakpoints[key]) {
					commit.SET_CURRENT_BREAKPOINT(key);
					if (state.scaleBreakpoints.has(state.currentBreakpoint)) {
						commit.SET_SCALE_DOWN_COMPONENTS(true);
						break;
					}
					commit.SET_SCALE_DOWN_COMPONENTS(false);
					break;
				}
			}
		}
	}
});

export default WindowInfoModule;
