import { AddCustomerResult } from '../../components/form-components/add-customer/add-customer-result';
import { CustomComponentType } from '../../enums';
import { Customer } from '@sageworks/jpi';

export const createRowData = (addCustomerResult: AddCustomerResult, parentLoanRoleId: number) => {
	const data: { [key: string]: any } = {};
	switch (addCustomerResult.customerType) {
		case Customer.TypeEnum.Person: {
			data[CustomComponentType.personalInfo] = addCustomerResult[CustomComponentType.personalInfo] ?? { data: {} };
			data[CustomComponentType.personalInfo].data.parentLoanRoleId = parentLoanRoleId;
			break;
		}
		case Customer.TypeEnum.Business: {
			data[CustomComponentType.businessInfo] = addCustomerResult[CustomComponentType.businessInfo] ?? { data: {} };
			data[CustomComponentType.businessInfo].data.parentLoanRoleId = parentLoanRoleId;
			break;
		}
		case Customer.TypeEnum.NonProfit: {
			data[CustomComponentType.nonprofitInfo] = addCustomerResult[CustomComponentType.nonprofitInfo] ?? { data: {} };
			data[CustomComponentType.nonprofitInfo].data.parentLoanRoleId = parentLoanRoleId;
			break;
		}
		case Customer.TypeEnum.Farm: {
			data[CustomComponentType.farmInfo] = addCustomerResult[CustomComponentType.farmInfo] ?? { data: {} };
			data[CustomComponentType.farmInfo].data.parentLoanRoleId = parentLoanRoleId;
			break;
		}
		default: {
			throw new Error('Unsupported customer type');
		}
	}
	return data;
};
