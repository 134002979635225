
import BaseTemplateMixin from './base-template-mixin.vue';

export default BaseTemplateMixin.extend({
	name: 'Table',
	components: {},

	computed: {
		hasHeader() {
			return this.exportMetadata.ComponentTree?.header?.length > 0;
		},
		cellClass() {
			return this.exportMetadata.ComponentTree.cellAlignment !== '' ? `cell-align-${this.exportMetadata.ComponentTree.cellAlignment}` : '';
		}
	},
	methods: {}
});
