import { LoanApplication } from '@sageworks/jpi';

const finalStatuses = [
	LoanApplication.ApplicationStatusEnum.ReadyForBorrowerSubmission,
	LoanApplication.ApplicationStatusEnum.ApplicationComplete,
	LoanApplication.ApplicationStatusEnum.Approved,
	LoanApplication.ApplicationStatusEnum.Declined,
	LoanApplication.ApplicationStatusEnum.Withdrawn,
	LoanApplication.ApplicationStatusEnum.Locked
];

export function isInFinalStatus(status: LoanApplication.ApplicationStatusEnum) {
	return finalStatuses.includes(status);
}
