import Component from 'formiojs/components/_classes/component/Component';

export function getFormPath(component: Component) : string {
    return getParentFormPath(component);
}

function getParentFormPath(component: any) : string{
    if (component == null){
        return '';
    }
    else if (component.form?.path){
        return component.form.path;
    }

    return getParentFormPath(component?.parent);
}