import { LoanApplicationCard } from '@/models';
import { LoanApplication } from '@sageworks/jpi';
import VueRouter from 'vue-router';
import { OnlinePortalRoutes } from '@/OnlinePortalRoutes';
import { SageworksAnalystLinkUtils } from '@/utils';

function getApplicationSecondaryMessage(loanApplication: LoanApplication) {
	if (loanApplication.submittedDate != null && loanApplication.submittedDate !== '') {
		return 'Submitted ' + loanApplication.submittedDate;
	} else if (
		loanApplication.createdDate !== loanApplication.lastModifiedDate &&
		loanApplication.lastModifiedDate != null &&
		loanApplication.lastModifiedDate !== ''
	) {
		return 'Last modified ' + loanApplication.lastModifiedDate;
	} else if (loanApplication.createdDate != null && loanApplication.createdDate !== '') {
		return 'Created ' + loanApplication.createdDate;
	}
	return '';
}

function transformLoanApplicationToCard(
	loanApplicationToConvert: LoanApplication,
	iconName: string,
	iconStyle: string,
	cardLabel: string,
	router?: VueRouter,
): LoanApplicationCard {
	return {
		messageCondition: () => true,
		onClickFunction: createCardOnClickFunction(loanApplicationToConvert, router),
		primaryMessage: cardLabel + ' #' + loanApplicationToConvert.applicationNumber,
		secondaryMessage: getApplicationSecondaryMessage(loanApplicationToConvert),
		filterTag: loanApplicationToConvert.submittedDate != null && loanApplicationToConvert.submittedDate !== '' ? ['completed'] : ['current'],
		icon: {
			color: 'text-primary',
			size: 'lg',
			name: iconName,
			style: iconStyle
		},
		loanApplicationId: loanApplicationToConvert.id
	} as LoanApplicationCard;
}

export function transformLoanApplicationsToCards(loanApplications: Array<LoanApplication>,cardLabel?: string,  router?: VueRouter): Array<LoanApplicationCard> {
	let applicationCards: Array<LoanApplicationCard> = [];
	if (!cardLabel) {
		cardLabel = 'Loan Application';
	}
	for (let application of loanApplications) {
		let cardIconName = application.submittedDate != null && application.submittedDate !== '' ? 'ballot-check' : 'ballot';
		applicationCards.push(transformLoanApplicationToCard(application, cardIconName, 'far', cardLabel, router ));
	}
	return applicationCards;
}

export function isApplicationLocked({ lockedDate, applicationStatus }: LoanApplication) {
	return (lockedDate != null && lockedDate !== '') || applicationStatus === LoanApplication.ApplicationStatusEnum.Locked;
}

function createCardOnClickFunction(application: LoanApplication, router?: VueRouter): () => void {
	if (router == null) {
		return () => {
			return;
		};
	}
	const applicationRoute: string = isApplicationLocked(application)
			? OnlinePortalRoutes.ExportToPdf.name
			: OnlinePortalRoutes.ApplicationForm.name;

	if (application.isDynamicApplication) {
		return () => router.push({ name: applicationRoute, params: { applicationId: application.id.toString() } });
	}
	return () => window.open(SageworksAnalystLinkUtils.getLinkToLoanApplication(application.id), '_blank');
}
