import { ApplicationSubmitValidationErrorMessage } from './application-submit-validation-error';
import { ApplicationSubmitValidationResults } from './application-submit-validation-results';

export function buildValidationMessages(validationItems: ApplicationSubmitValidationResults, isLender: boolean): ApplicationSubmitValidationErrorMessage[] {
	if (!validationItems) return [];

	var validationMessages: ApplicationSubmitValidationErrorMessage[] = [];

	if (validationItems.termsAndConditions != null && !validationItems.termsAndConditions && !isLender) {
		validationMessages.push({ title: 'Terms and Conditions', message: 'You must agree to the terms and conditions.' });
	}

	if (Object.prototype.hasOwnProperty.call(validationItems, 'canBeSubmitted') && !validationItems.canBeSubmitted) {
		validationMessages.push({ title: 'Submission Status', message: 'Application has already been submitted.' });
	}

	if (Object.prototype.hasOwnProperty.call(validationItems, 'formDataValid') && !validationItems.formDataValid) {
		validationMessages.push({ title: 'Required Fields', message: 'Required fields are incomplete.' });
	}

	if (Object.prototype.hasOwnProperty.call(validationItems, 'documentRequestsValid') && !validationItems.documentRequestsValid) {
		validationMessages.push({ title: 'Required Documents', message: 'Required documents are incomplete.' });
	}

	return validationMessages;
}
