import keyBy from 'lodash/keyBy';
import { CustomBuilderGroups, CustomComponentType, TemplateDataField } from '../../../enums';
import { FormioBuilderGroupSchema, FormioComponentSchema, FormioValidationSchema } from '../../../formio-interfaces';
import { RequiredFieldsByWidgetType } from '../../required-field-utils';
import { ApplicationTemplate, DataField } from '@sageworks/jpi';
import { showFieldBuilderGroups } from '../../../components/widget-builder/entity-subwidget-helper';
import { getDataFieldLabel } from '../../data-field-metadata/data-field-label-builder';
import { convertDataFieldToComponent } from '../../data-field-metadata/data-field-metadata-utils';
import { getDefaultComponents } from '../default-components-utils';

const isAcceptedDataField = (dataField: DataField) => {
	return dataField.name !== null && dataField.name.length > 0 && dataField.dataFieldType !== DataField.DataFieldTypeEnum.Unknown;
};

export class BuilderGroupFactory {
	get forcedRequiredFields(): TemplateDataField[] {
		if (this.componentType == null) {
			return [];
		}

		return RequiredFieldsByWidgetType[this.componentType];
	}

	constructor(private properties: DataField[], private componentType: CustomComponentType | null, private applicationTemplate: ApplicationTemplate) {}

	public createDefaultFieldComponentBuilderGroup(
		title: string = 'Sageworks Fields',
		weight: number = 10,
		has1071Product: boolean = false
	): FormioBuilderGroupSchema {
		const defaultComponents = getDefaultComponents(this.componentType).map(
			schema => ({ title: schema.label, schema } as FormioComponentSchema<any, FormioValidationSchema>)
		);

		let allComponents = [...this.createDataFieldComponents(x => !x.isUserDefined || !!x.templateDataFieldId), ...defaultComponents];

		if (!has1071Product) {
			allComponents = allComponents.filter(
				x =>
					x.schema.templateDataFieldId !== TemplateDataField.GrossAnnualRevenue &&
					x.schema.templateDataFieldId !== TemplateDataField.DidBusinessExceed5Million &&
					x.schema.templateDataFieldId !== TemplateDataField.BorrowerAddressType
			);
		}

		this.sortBySchemaLabelandUpdateWeight(allComponents);

		return {
			title,
			weight,
			default: false,
			key: CustomBuilderGroups.defaultFields,
			subgroups: [],
			ignore: !showFieldBuilderGroups(this.componentType),
			components: {
				...keyBy(allComponents, x => (x.schema.dataFieldId as string) ?? x.schema.key)
			}
		};
	}

	public createCustomFieldComponentBuilderGroup(title: string = 'Custom Fields', weight: number = 15): FormioBuilderGroupSchema {
		const components = this.createDataFieldComponents(x => !!x.isUserDefined && !x.templateDataFieldId);
		this.sortBySchemaLabelandUpdateWeight(components);

		return {
			title,
			weight,
			default: false,
			key: CustomBuilderGroups.udFields,
			subgroups: [],
			ignore: !showFieldBuilderGroups(this.componentType),
			components: keyBy(components, 'schema.dataFieldId')
		};
	}

	private createDataFieldComponents(filter: (dataField: DataField) => boolean = () => true) {
		return (
			this.properties
				.filter((p: DataField) => isAcceptedDataField(p) && filter(p))
				// TODO: Would like to eventually move this into Data Field Converters instead of having it here
				.map(dataField => ({ ...dataField, name: getDataFieldLabel(dataField, this.componentType) }))
				.map((p, index, allDataFields) => {
					return convertDataFieldToComponent(p, {
						weight: index + 1,
						forcedRequiredFields: this.forcedRequiredFields,
						parentComponentType: this.componentType,
						allDataFields,
						applicationTemplate: this.applicationTemplate
					});
				})
				.filter(x => x && x.schema)
		);
	}

	private sortBySchemaLabelandUpdateWeight(builderComponents: FormioComponentSchema<any, FormioValidationSchema>[]) {
		builderComponents
			.sort(this.sortBySchemaLabel)
			// Adjust the weight each component so we keep the sorting in the builder
			.forEach((x, index) => {
				x.weight = index + 1;
				return x;
			});
	}

	private sortBySchemaLabel(
		{ schema: schema1 }: FormioComponentSchema<any, FormioValidationSchema>,
		{ schema: schema2 }: FormioComponentSchema<any, FormioValidationSchema>
	) {
		const label1 = schema1?.label?.toUpperCase() ?? ''; // ignore upper and lowercase
		const label2 = schema2?.label?.toUpperCase() ?? ''; // ignore upper and lowercase

		if (label1 < label2) {
			return -1;
		}
		if (label1 > label2) {
			return 1;
		}

		// names must be equal
		return 0;
	}
}
