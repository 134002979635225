import { ConvertParentLoanRoleType } from '../../../utils/application-form-related-loan-role-helper/ApplicationFormRelatedLoanRoleHelper';
import { locateRelatedRoles, locateRole } from '../../../utils/form-data-utils';
import { DataObject, LoanRoleType, TemplateDataField } from '@sageworks/dynamic-forms';
import { AutoFactory, BeneficialOwnerFormDataModel, 
	ObjectPropertyValues, OnlinePortalRelatedLoanRolesService,
	RelatedLoanRole, RelatedLoanRoleData
} from '@sageworks/jpi';
import { defineActions } from 'direct-vuex';
import { actionContext } from '../store-helper';
import { AddBeneficialOwnerParams, AddRelatedRoleParams, DeleteRelatedRoleParams  } from './persist-action-params';


export default defineActions({
	async removeBeneficialOwner(context, { id }: { id: number }) {
		const { commit, dispatch } = actionContext(context);

		await dispatch.removeDataObject({
			dataObjectType: DataObject.TypeEnum.BeneficialOwnerDetails,
			id
		});

		commit.DELETE_BENEFICIAL_OWNER({ id });
	},
	async addBeneficialOwner(context, { dataObject, customerId, ownerCustomerId, ownerEntityType }: AddBeneficialOwnerParams) {
		const { commit, dispatch, rootState } = actionContext(context);

		const savedDataObject: ObjectPropertyValues = await dispatch
			.saveDataObject({ dataObjectType: DataObject.TypeEnum.BeneficialOwnerDetails, dataObject });

		const ownershipPercentFieldId = rootState.CustomComponentProperties.templateDataFieldIdToDataFieldId[TemplateDataField.BeneficialOwnerPercent];
		const roleTypeFieldId = rootState.CustomComponentProperties.templateDataFieldIdToDataFieldId[TemplateDataField.BeneficialOwnerRoleType];
		const dateUpdatedFieldId = rootState.CustomComponentProperties.templateDataFieldIdToDataFieldId[TemplateDataField.BeneficialOwnerDateUpdated];

		commit.UPSERT_BENEFICIAL_OWNERS({ beneficialOwners: [
			{
				id: savedDataObject.id,
				customerId,
				ownerCustomerId,
				ownerEntityType,
				ownershipPercent: savedDataObject.dataFieldValues?.find(x => x.id === ownershipPercentFieldId)?.value,
				role: savedDataObject.dataFieldValues?.find(x => x.id === roleTypeFieldId)?.value, 
				lastUpdated: savedDataObject.dataFieldValues?.find(x => x.id === dateUpdatedFieldId)?.value
			}
		] as BeneficialOwnerFormDataModel[] });

		return savedDataObject;
	},
	async addRelatedRole(context, { customerId, parentLoanRoleId, roleType }: AddRelatedRoleParams) {
		const { state: { renderData = {} }, getters, commit } = actionContext(context);
		const proposedLoanId = getters.proposedLoanId;
		const roles = locateRelatedRoles(renderData?.formData ?? {}, roleType);
		const existingRole = roles.find(role => role.customerID === customerId);

		if (existingRole) {
			return existingRole;
		}

		
		const parentLoanRole = locateRole(renderData?.formData ?? {}, parentLoanRoleId);
		if (!parentLoanRole) {
			throw new Error(`Could not locate parent loan role with id = ${parentLoanRoleId}`);
		}

		const newRelatedRole: RelatedLoanRole = {
			customerId,
			proposedLoanId,
			roleType,
			parentLoanRoleId: parentLoanRole.dataObjectID,
			parentLoanRoleType: ConvertParentLoanRoleType(parentLoanRole.roleType as LoanRoleType),
		};

		const relatedLoanRolesService = AutoFactory.get(OnlinePortalRelatedLoanRolesService);
		const savedRelatedRole = await relatedLoanRolesService.create(newRelatedRole);

		const formattedRelatedRole: RelatedLoanRoleData = {
			id: savedRelatedRole.id,
			customerID: savedRelatedRole.customerId,
			parentLoanRoleID: savedRelatedRole.parentLoanRoleId ?? undefined,
			roleType: savedRelatedRole.roleType,
		};

		commit.UPSERT_RELATED_ROLE({ relatedRole: formattedRelatedRole, roleType });
	},
	async deleteRelatedRole(context, { loanRoleType, customerId, parentLoanRoleId }: DeleteRelatedRoleParams): Promise<void> {
		const { state: { renderData = {} }, commit } = actionContext(context);

		if (customerId == null) {
			return;
		}

		const relatedRoleToRemove = locateRelatedRoles(renderData?.formData ?? {}, loanRoleType)
			.find(x => x.customerID === customerId && x.parentLoanRoleID === parentLoanRoleId && x.id != null && x.roleType != null);

		if (!relatedRoleToRemove) {
			return;
		}

		const relatedLoanRolesService = AutoFactory.get(OnlinePortalRelatedLoanRolesService);
		await relatedLoanRolesService._delete(relatedRoleToRemove.id!);

		commit.REMOVE_RELATED_ROLES({ ids: [relatedRoleToRemove.id!], roleType: loanRoleType });
	},
});
