import {
	CashAccountCustomFieldKey,
	CashAccountsUtils,
	CustomComponentType,
	FinancialStatementType,
	FinancialSubaccountUtils,
	SubmitPayload,
} from '@sageworks/dynamic-forms';
import { formatDataFieldIdKey } from '@sageworks/dynamic-forms/src/components/form-components/simple-inputs/extended-field-helper';
import { ObjectPropertyValues } from '@sageworks/jpi';
import { FinancialSubaccountDataHandler } from '../financial-subaccount-data-handler/financial-subaccount-data-handler';

export class CashAccountsDataHandler extends FinancialSubaccountDataHandler {

	public async submit(payload: SubmitPayload): Promise<any> {
		if (this.missingValues(payload)) {
			return;
		}

		// Grab the custom fields' values so we can format the subaccount's name and parent type correctly
		const name = payload.submission.data[CashAccountCustomFieldKey.Name];
		const accountType = payload.submission.data[CashAccountCustomFieldKey.AccountType];

		const nameFieldKey = formatDataFieldIdKey(this.nameDataFieldId.toString());
		const parentFieldKey = formatDataFieldIdKey(this.parentDataFieldId.toString());
	

		payload.submission.data[nameFieldKey] = FinancialSubaccountUtils.createSubaccountName(name, accountType);
		payload.submission.data[parentFieldKey] = CashAccountsUtils.calculateParent(accountType);

		return await super.submit(payload);
	}

	protected getFinancialStatementName(widgetType: CustomComponentType) {
		switch (widgetType) {
			case CustomComponentType.cashAccount:
				return FinancialStatementType.CurrentAssets;
			default:
				throw Error('Widget type is not mapped to a financial statement name');
		}
	}

	protected createFormObject(submitPayload: SubmitPayload, dataFieldValues: ObjectPropertyValues) {
		const updatedFormObject = super.createFormObject(submitPayload, dataFieldValues);

		// These values might change since when we re-create the form object it uses the financial subaccounts to set the values which
		// 	might be stale since only the data object is updated, so we update them here to ensure they remain the same
		updatedFormObject.data[CashAccountCustomFieldKey.AccountType] = submitPayload.submission.data[CashAccountCustomFieldKey.AccountType];
		updatedFormObject.data[CashAccountCustomFieldKey.Name] = submitPayload.submission.data[CashAccountCustomFieldKey.Name];

		return updatedFormObject;
	}
}
