import BaseComponent from '../base/base-widget/base-widget';
import editForm from './location-verification.form';
import { CustomComponentType } from '../../../enums/custom-component-type';

export default class LocationVerificationWidget extends BaseComponent {
	static schema(...extend: any) {
		return BaseComponent.schema(
			{
				label: 'Location Verification',
				type: CustomComponentType.locationVerification,
				key: CustomComponentType.locationVerification
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: 'Location Verification',
			group: '', // Set group as 'preAppWidgets' once it is ready to be added back
			weight: 10,
			schema: LocationVerificationWidget.schema()
		};
	}
}
