import { defineModule, localActionContext } from 'direct-vuex';
import { AutoFactory, HMDAGMIService, DynamicApplicationGMIFormLink } from '@sageworks/jpi';
import { listToMapByCustomerId } from '@/utils/gmi-form-utils';
import Vue from 'vue';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => localActionContext(context, HmdaGmiFormModule);

export interface HmdaGmiFormModuleState {
	gmiFormLinks: DynamicApplicationGMIFormLink[];
	allHmdaGmiFormsValidForSubmit: boolean | null;
}

const HmdaGmiFormModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			gmiFormLinks: [],
			allHmdaGmiFormsValidForSubmit: null
		} as HmdaGmiFormModuleState;
	},
	mutations: {
		SET_GMI_FORM_LINKS(state, gmiFormLinks: DynamicApplicationGMIFormLink[]) {
			state.gmiFormLinks = gmiFormLinks;
		},
		UPDATE_GMI_FORM_LINK(state, gmiFormLink: DynamicApplicationGMIFormLink) {
			const index = state.gmiFormLinks.findIndex(x => x.id === gmiFormLink.id);
			if (index <= -1) {
				return;
			}

			Vue.set(state.gmiFormLinks, index, gmiFormLink);
		},
		SET_ALL_HMDA_GMI_FORMS_VALID_FOR_SUBMIT(state, allValid: boolean | null) {
			state.allHmdaGmiFormsValidForSubmit = allValid;
		}
	},
	actions: {
		async loadGmiForms(context, { loanApplicationId }: { loanApplicationId: number }) {
			const { commit } = actionContext(context);

			const gmiService = AutoFactory.get(HMDAGMIService);

			const gmiFormLinks = await gmiService.getGmiFormLinks(loanApplicationId);
			commit.SET_GMI_FORM_LINKS(gmiFormLinks);
		},
		async toggleWaiveForm(context, gmiFormLink: DynamicApplicationGMIFormLink) {
			const { commit } = actionContext(context);

			const gmiService = AutoFactory.get(HMDAGMIService);
			const updatedForm = await gmiService.toggleWaiveForm(gmiFormLink);

			commit.UPDATE_GMI_FORM_LINK(updatedForm);
		},
		async loadAllHmdaGmiFormsValidForSubmit(context, loanApplicationId) {
			const { commit } = actionContext(context);

			const gmiService = AutoFactory.get(HMDAGMIService);

			const formsAreComplete = await gmiService.formsAreComplete(loanApplicationId);
			commit.SET_ALL_HMDA_GMI_FORMS_VALID_FOR_SUBMIT(formsAreComplete);
		}
	},
	getters: {
		gmiFormLinksByCustomerId({ gmiFormLinks }): { [customerId: number]: DynamicApplicationGMIFormLink[] } {
			return listToMapByCustomerId(gmiFormLinks);
		}
	}
});

export default HmdaGmiFormModule;
