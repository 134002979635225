/* eslint-disable no-redeclare */
import Component from 'formiojs/components/_classes/component/Component';
import { ContextDataProperty } from '../../enums/context-data-property';
import { UserInfo } from '../../formio-interfaces/DynamicFormsContextData';
import { FeatureFlagging, LoanApplication } from '@sageworks/jpi';

const isLender = (userInfo: UserInfo) => {
	return userInfo == null || userInfo?.proxyUserId != null;
};

export function getContextDataValue(component: Component, propertyName: ContextDataProperty.IsLender): boolean;
export function getContextDataValue(component: Component, propertyName: ContextDataProperty.LoanApplicationType): LoanApplication.TypeEnum;
export function getContextDataValue(component: Component, propertyName: ContextDataProperty.IsJpiAuthenticated): boolean;
export function getContextDataValue(component: Component, propertyName: ContextDataProperty.LoanApplicationId): number;
export function getContextDataValue(component: Component, propertyName: ContextDataProperty.CurrentUserInfo): UserInfo;
export function getContextDataValue(component: Component, propertyName: ContextDataProperty.FeatureFlags): FeatureFlagging.FeatureFlagEnum[] | undefined;
export function getContextDataValue(component: Component, propertyName: ContextDataProperty): any;
export function getContextDataValue(component: Component, propertyName: ContextDataProperty): any {
	if (!component?.options?.contextData) {
		return undefined;
	}

	if (propertyName === ContextDataProperty.IsLender) {
		const userInfo = getContextDataValue(component, ContextDataProperty.CurrentUserInfo);
		return isLender(userInfo);
	} else {
		return component.options?.contextData[propertyName] ?? undefined;
	}
}
