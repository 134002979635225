
import Vue, { PropType } from 'vue';
import AddNewPrimaryRole from '@/formio-vue/components/primary-role/add-new-primary-role.vue';
import { Customer } from '@sageworks/jpi/';
import { CustomComponentType } from '@sageworks/dynamic-forms';
import EntityList from '../entity-list/entity-list.vue';
import ExistingEntitySelector from '../existing-entity-selector/existing-entity-selector.vue';

export default Vue.extend({
	name: 'EntityRepeater',
	components: { AddNewPrimaryRole, EntityList, ExistingEntitySelector },
	props: {
		entityTypes: {
			type: Array as PropType<Customer.TypeEnum[]>,
			default: () => []
		},
		componentType: {
			type: String as PropType<CustomComponentType>,
			default: () => {}
		},

		rowDetails: {
			type: Array as PropType<string[]>,
			default: () => [],
			required: true
		},
		maxRows: {
			type: Number,
			default: () => 0
		}
	},
	computed: {
		// Get the set of previously entered entities for the current user - only
		// returns data for borrower type users with previous loan applications with entities
		// attached in primary roles that user has access to use.
		existingEntities() {
			return this.directStore.state.MultiLoanApplicationForm.formattedPriorEntities;
		},

		// Read setting to determine if existing entities should be shown on the dynamic loan application
		showExistingEntitiesOnDynamicLoanApplication(): boolean {
			return this.directStore.getters.InstitutionSettings.showExistingEntitiesOnDynamicLoanApplication;
		}
	}
});
