import { FormioLogicSchema } from './FormioFieldLogicSchema';

// SOURCE: https://github.com/formio/formio.js/wiki/Components-JSON-Schema

export interface FormioValidationSchema {
	required?: boolean;
	minLength?: number;
	maxLength?: number;
	pattern?: RegExp;
	custom?: any;
	customPrivate: boolean;
}

export interface FormioConditionalSchema {
	show?: boolean;
	when?: string;
	eq?: string;
	json?: JSON;
}

export interface FormioErrorsSchema {
	required?: string;
	min?: string;
	max?: string;
	minLength?: string;
	maxLength?: string;
	invalid_email?: string;
	invalid_date?: string;
	pattern?: string;
	custom?: string;
}

export enum FormioLabelPosition {
	top = 'top',
	bottom = 'bottom',
	left = 'left',
	right = 'right'
}

export interface FormioComponentSchema<dataType, validateType> {
	id?: string;
	title?: string;
	icon?: string;
	key?: string;
	type: string;
	schema?: any;
	label?: string;
	placeholder?: string;
	input?: boolean;
	tableView?: boolean;
	multiple?: boolean;
	protected?: boolean;
	prefix?: string;
	suffix?: string;
	customClass?: string;
	defaultValue?: dataType | dataType[];
	clearOnHide?: boolean;
	unique?: boolean;
	persistent?: boolean;
	hidden?: boolean;
	validate?: validateType;
	conditional?: FormioConditionalSchema;
	errors?: FormioErrorsSchema;
	logic?: FormioLogicSchema;
	components?: FormioComponentSchema<any, validateType>[];
	dataGridLabel?: boolean;
	labelPosition?: FormioLabelPosition;
	labelWidth?: number;
	labelMargin?: number;
	description?: string;
	errorLabel?: string;
	tooltip?: string;
	hideLabel?: boolean;
	tabindex?: number;
	disabled?: boolean;
	weight?: number;
	nextPage?: string;
	form?: string;
	formPath?: string;
	formJson?: string;
	submitMetadata?: object;
	calculateServer?: boolean;
	reference?: boolean;
	keyModified?: boolean;
	[key: string]: any;
}
