import { reactive } from 'vue';
import { createAuth0Client, Auth0Client, Auth0ClientOptions, User } from '@auth0/auth0-spa-js';

let resolveLoadingPromise: () => void;
let rejectLoadingPromise: (reason: any) => void;

const loadingPromise = new Promise<void>(function(resolve, reject) {
	resolveLoadingPromise = resolve;
	rejectLoadingPromise = reject;
});

const auth0 = reactive<{
	client: Auth0Client | null;
	loading: boolean;
	loadingPromise: Promise<void> | null;
	isAuthenticated: boolean;
	user: User;
	error: any;
}>({
	client: null,
	loading: true,
	loadingPromise: loadingPromise,
	isAuthenticated: false,
	user: {},
	error: null
});

async function instantiateAuth0Client(options: Auth0ClientOptions, onRedirectCallback: (appState: any) => void) {
	// Create a new instance of the SDK client using members of the given options object
	auth0.client = await createAuth0Client(options);

	try {
		// If the user is returning to the app after authentication
		if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
			// handle the redirect and retrieve tokens
			const { appState } = await auth0.client?.handleRedirectCallback();

			// Notify subscribers that the redirect callback has happened, passing the appState
			// (useful for retrieving any pre-authentication state)
			onRedirectCallback(appState);
		}
	} catch (e) {
		auth0.error = e;
	} finally {
		// Initialize our internal authentication state
		auth0.isAuthenticated = await auth0.client?.isAuthenticated();
		auth0.user = (await auth0.client?.getUser()) as User;
		auth0.loading = false;
		auth0.error ? rejectLoadingPromise(auth0.error) : resolveLoadingPromise();
	}
}

export function useAuth0() {
	return {
		auth0,
		instantiateAuth0Client
	};
}
