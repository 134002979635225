
import Vue, { PropType } from 'vue';
import { Customer, Borrower } from '@sageworks/jpi/';
import { CustomComponentLabel, CustomComponentType } from '@sageworks/dynamic-forms';
import IconSelectorButton from '../icon-selector-button/icon-selector-button.vue';
import { PriorEntitiesDisplay } from '@/models/PriorEntitiesDisplay';

export default Vue.extend({
	name: 'ExistingEntitySelector',
	components: {
		IconSelectorButton
	},
	props: {
		entityTypes: {
			type: Array as PropType<Customer.TypeEnum[]>,
			default: () => []
		},
		componentType: {
			type: String as PropType<CustomComponentType>,
			required: true
		},
		borrowers: {
			type: Array as PropType<PriorEntitiesDisplay[]>,
			required: true,
			default: () => []
		}
	},
	computed: {
		roleType(): string {
			return CustomComponentLabel()[this.componentType];
		},
		persons(): Borrower[] {
			return this.borrowers.filter(borrower => borrower.type.toUpperCase() === 'PERSON' && borrower.hasBeenUsed === false && borrower.name.length > 0);
		},
		businesses(): Borrower[] {
			return this.borrowers.filter(borrower => borrower.type.toUpperCase() === 'COMPANY' && borrower.hasBeenUsed === false && borrower.name.length > 0);
		}
	},
	methods: {
		// When a borrower is selected, mark them as used so they can't be used again
		useBorrower(_id: number) {
			// This is a placeholder for DYA-3347 where a service will be called to set the borrower as used
		}
	}
});
