import { DataField } from '@sageworks/jpi';
import { TemplateDataField } from '../../../../../enums';
import { DataFieldConvertStrategy } from '../../data-field-convert-strategy';
import { setSharedSelectProperties, setCustomSelectProperties } from '../../select-field-converter-utils';

export class CollateralPledgedByDataFieldConverter implements DataFieldConvertStrategy {
	private readonly allowedFields = new Set<number>([TemplateDataField.CollateralPledgedBy]);

	isFieldValid(dataField: DataField): boolean {
		return this.allowedFields.has(dataField.templateDataFieldId!);
	}

	convert(dataField: DataField, currentProperties: any) {
		const properties = { ...currentProperties };
		setSharedSelectProperties(properties);
		setCustomSelectProperties(properties);
		properties.dataType = 'number';
		properties.data = {
			custom: 'return formDataHelpers.getApplicationEntities(instance);'
		};
		properties.searchField = 'label';
		properties.valueProperty = 'value';
		return properties;
	}
}
