import TabsComponent from 'formiojs/components/tabs/Tabs';
import { Utils as FormioUtils } from 'formiojs';
import { checkConditionalityAcrossForms, shouldFieldUseOriginalConditionality } from '../../simple-inputs/extended-field-helper';
import editForm from './tabs.form';
import { FormioConditionalityUtils } from '../../../../utils/formio-conditionality-utils';

export default class ModifiedTabsComponent extends TabsComponent {

	constructor(component: any, options: any, data: any) {
		super(component, options, data);
		this.currentTab = -1;
		this.noField = true;
	}

	static editForm = editForm;

	init() {
		this.components = [];
		this.tabs = [];
		for (var i = 0; i < this.component.components.length; i++) {
			const tab = this.component.components[i];
			this.tabs[i] = [];
			// Initialize empty tabs.
			tab.components = tab.components || [];

			if (this.currentTab < 0 && !tab.hidden) {
				this.currentTab = i;
			}

			for (var j = 0; j < tab.components.length; j++) {
				const comp = tab.components[j];
				const component = this.createComponent(comp);
				component.tab = i;
				this.tabs[i].push(component);
			}
		}
	}

	tabLogic(tab: any, data: any, row: any): boolean {
		data = data || this.rootValue;
		row = row || this.data;
		const logics = tab.logic || [];

		// If there aren't logics configured, don't go further.
		if (logics.length === 0) {
			return false;
		}

		const originalTab = FormioUtils.searchComponents(this.originalComponent.components, { key: tab.key })[0];
		const newTab = (FormioUtils as any).fastCloneDeep(originalTab);

		let changed = logics.reduce((changed: any, logic: any) => {
			const result = FormioUtils.checkTrigger(newTab, logic.trigger, row, data, this.root ? (this.root as any)._form : {}, this);

			return (result ? this.applyTabActions(tab, newTab, logic.actions, result, row, data) : false) || changed;
		}, false);

		// If tab definition changed, replace and mark as changed.
		if (changed) {
			const tabIndex = this.component.components.findIndex((t: any) => t.key === tab.key);
			this.component.components[tabIndex] = newTab;
			// If disabled changed, be sure to distribute the setting.
			this.disabled = this.shouldDisabled;
			changed = true;
		}

		return changed;
	}

	tabsLogic(data: any, row: any): boolean {
		let changed = false;
		let currentTabHidden = false;
		let firstShownTabIndex = -1;
		for (var i = 0; i < this.component.components.length; i++) {
			let tab = this.component.components[i];
			changed = this.tabLogic(tab, data, row) || changed;
			tab = this.component.components[i];

			if (firstShownTabIndex < 0 && !tab.hidden) {
				firstShownTabIndex = i;
			}

			if (this.currentTab === i && tab.hidden) {
				currentTabHidden = true;
			}
		}

		if (currentTabHidden) {
			this.setTab(firstShownTabIndex);
		}

		return changed;
	}

	fieldLogic(data: any, row: any) {
		let changed = super.fieldLogic(data, row);

		changed = this.tabsLogic(data, row) || changed;

		return changed;
	}

	applyTabActions(originalTab: any, newTab: any, actions: any, result: any, row: any, data: any) {
		data = data || this.rootValue;
		row = row || this.data;

		return actions.reduce((changed: boolean, action: any) => {
			switch (action.type) {
				case 'property': {
					FormioUtils.setActionProperty(newTab, action, result, row, data, originalTab);

					const property = action.property.value;
					if (originalTab[property] !== newTab[property]) {
						changed = true;
					}

					break;
				}
			}

			return changed;
		}, false);
	}

	conditionallyVisible(data: any): boolean {
		if (shouldFieldUseOriginalConditionality(this)) {
			return super.conditionallyVisible(data);
		}

		return checkConditionalityAcrossForms(this);
	}

	checkCondition(row: any, data: any) {
		return FormioConditionalityUtils.checkCondition(
			this.component,
			row || this.data,
			data || this.rootValue,
			this.root ? (this.root as any)._form : {},
			this
		);
	}

	// Disable autofocus within tabs components
	// Tabs should always open to the first tab
	beforeFocus() {
		if ('beforeFocus' in this.parent) {
			this.parent.beforeFocus(this);
		}
	}
}
