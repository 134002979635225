
import Vue, { PropType } from 'vue';
import moment from 'moment';
import { UsableProducts, DetailedProposedLoan, OnlinePortalProposedLoanStageGroup } from '@sageworks/jpi';
import { NumberFormatting } from '@sageworks/core-logic';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import LoanApplicationCardFieldValue from './field-value.vue';
import StageGroupProgressBar from './stage-group-progress-bar.vue';

const EMPTY_VALUE_PLACEHOLDER = '--';

export default Vue.extend({
	name: 'ProposedLoanSection',
	components: {
		FontAwesomeIcon,
		LoanApplicationCardFieldValue,
		StageGroupProgressBar
	},
	props: {
		proposedLoan: {
			type: Object as PropType<DetailedProposedLoan>,
			required: true
		},
		applicationNumber: {
			type: Number,
		},
		stageGroups: {
			type: Array as PropType<OnlinePortalProposedLoanStageGroup[]>,
			required: true
		}
	},
	computed: {
		cardTitle() {
			return this.proposedLoan.proposedProduct?.name ?? EMPTY_VALUE_PLACEHOLDER;
		},
		loanOfficerName() {
			const loanOfficer = this.proposedLoan.loanOfficer ?? {};
			const name = `${loanOfficer.firstName || ''} ${loanOfficer.lastName || ''}`;

			return name.trim() || EMPTY_VALUE_PLACEHOLDER;
		},
		loanAmount() {
			if (this.proposedLoan.balance == null) {
				return EMPTY_VALUE_PLACEHOLDER;
			}

			const price = Number(this.proposedLoan.balance) || 0;
			return NumberFormatting.formatAsCurrency(price, 2, true);
		},
		statusName() {
			const { loanStage } = this.proposedLoan;

			const stageGroup = this.stageGroups
				.find(x => x.id === loanStage?.proposedLoanStageGroupsID);

			return stageGroup?.name?.trim() || EMPTY_VALUE_PLACEHOLDER;
		},
		createDate() {
			const { createDate } = this.proposedLoan;

			return createDate ? moment(createDate).format('MM/DD/YYYY') : EMPTY_VALUE_PLACEHOLDER;
		},
		tagLabel() {
			const label = this.hasCommunityLendingSubscription ? 'Request' : 'Application';
			return `${label} #${this.applicationNumber ?? ''}`;
		},
		hasCommunityLendingSubscription(): boolean | undefined {
			return this.directStore.state.UsableProducts.usableProducts?.has('abrigoCommunityLending' as UsableProducts.ProductsEnum);
		},
		showLoanStatusInBorrowerPortal(): boolean {
			return this.directStore.getters.InstitutionSettings.showLoanStatusInBorrowerPortal;
		}
	}
});
