module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div>\n	<header class="modal-header">\n		<h2 class="modal-title">' +
((__t = ( ctx.name )) == null ? '' : __t) +
'</h2>\n	</header>\n	<div class="modal-body">\n		<p>\n			' +
((__t = ( ctx.message )) == null ? '' : __t) +
'\n		</p>\n		<div class="form-group form-check">\n			<input id="AgreeCheckbox" class="form-check-input" ref="dialogAuthorizeCreditCheckbox" type="checkbox" />\n			<label for="AgreeCheckbox" class="form-check-label">I agree to the above terms</label>\n		</div>\n		<div class="alert alert-danger d-none" role="alert" ref="dialogNeedAgreementMessage">\n			You must agree to the above terms in order to authorize credit.\n		</div>\n	</div>\n	<footer class="modal-footer d-flex justify-content-start">\n		';
 if (!ctx.isLoading) {  ;
__p += '\n			<button class="btn btn-primary" ref="dialogAuthorizeCreditButton">Authorize Credit</button>\n		';
 } else { ;
__p += '\n			<button class="btn btn-primary" disabled>Authorize Credit</button>\n		';
 } ;
__p += '\n\n		';
 if (ctx.isLoading) {  ;
__p += '\n			<span>Loading...</span>\n		';
 } ;
__p += '\n	</footer>\n</div>	\n';
return __p
}