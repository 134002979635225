export default [
	{
		type: 'checkbox',
		input: true,
		key: 'allowRepeat',
		persistent: true,
		label: 'Allow Widget to Repeat',
		tooltip:
			'Some widgets have the ability to repeat to allow multiple objects to be entered within the widget. ' +
			'For example, allowing the "Co-borrower" widget to repeat will allow a user or applicant to add multiple co-borrowers ' +
			'and fill out the data within this widget for each.',
		weight: -10
	},
	{
		type: 'hidden',
		key: 'validate.maxLength',
		logic: [
			{
				name: 'AllowRepeatValidation',
				trigger: {
					type: 'javascript',
					javascript: "result = data['allowRepeat'] === false"
				},
				actions: [
					{
						name: 'Limit 1',
						type: 'value',
						value: 'value = 1'
					}
				]
			},
			{
				name: 'DisallowRepeatValidation',
				trigger: {
					type: 'javascript',
					javascript: "result = data['allowRepeat'] === true"
				},
				actions: [
					{
						name: 'No Limit',
						type: 'value',
						value: 'value = 0'
					}
				]
			}
		]
	}
];
