import { CustomComponentType, RetirementAccountTypeOption } from '../../../enums';
import { FinancialSubaccountSelectOptions } from './financial-subaccount-type-select-options';

export class PersonalRetirementAccountTypeSelectOptions extends FinancialSubaccountSelectOptions {
	static schema(...extend: any) {
		return super.schema(
			{
				type: CustomComponentType.personalInsuranceTypeSelectOptions,
			},
			...extend
		);
	}

	static get builderInfo() {
		return {
			weight: 10,
			schema: PersonalRetirementAccountTypeSelectOptions.schema({})
		};
	}

	protected async getApiOptions(): Promise<any> {
		return {
			items: [
				{
					label: RetirementAccountTypeOption.IRA,
					value: RetirementAccountTypeOption.IRA,
				},
				{
					label: RetirementAccountTypeOption.RothIRA,
					value: RetirementAccountTypeOption.RothIRA,
				},
				{
					label: RetirementAccountTypeOption.Annuity,
					value: RetirementAccountTypeOption.Annuity,
				},
				{
					label: RetirementAccountTypeOption.Retirement401k,
					value: RetirementAccountTypeOption.Retirement401k,
				},
				{
					label: RetirementAccountTypeOption.Pension,
					value: RetirementAccountTypeOption.Pension,
				},
			]
		};
	}
}
