import { DataField } from '@sageworks/jpi';
import { TemplateDataField } from '../../../../../enums';
import { DataFieldConvertStrategy } from '../../data-field-convert-strategy';
import * as SelectFieldConverterUtils from '../../select-field-converter-utils';

export class DropdownDataFieldConverter implements DataFieldConvertStrategy {
	private allowedTemplateFields = [TemplateDataField.BeneficialOwnerRoleType];

	// We want to allow any fields with the dataObject type, as well as any additional custom fields
	isFieldValid(dataField: DataField): boolean {
		const filterPredicates: ((x: DataField) => boolean)[] = [
			x => x.dataFieldType === DataField.DataFieldTypeEnum.DataObject,
			x => x.templateDataFieldId != null && this.allowedTemplateFields.includes(x.templateDataFieldId)
		];

		return filterPredicates.some(predicate => predicate(dataField));
	}

	convert(dataField: DataField, currentProperties: any) {
		const properties = { ...currentProperties };

		properties.dataType = 'number';

		SelectFieldConverterUtils.setSharedSelectProperties(properties);
		SelectFieldConverterUtils.setUrlSelectProperties(properties);
		SelectFieldConverterUtils.setDataFieldDropdownProperties(properties);

		return properties;
	}
}
