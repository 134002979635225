
import Vue, { PropType } from 'vue';
import ReviewLender from './review-lender.vue';
import ReviewBorrower from './review-borrower.vue';
import { LoanApplication } from '@sageworks/jpi';
import { isInFinalStatus as isApplicationInFinalStatus } from '../../utils/application-final-status-helper/application-final-status-helper';

export default Vue.extend({
	name: 'ReviewBody',
	components: {
		ReviewLender,
		ReviewBorrower
	},
	props: {
		currentUserIsLender: {
			type: Boolean as PropType<boolean | null>,
			default: null
		},
		isValidatingOrSubmitting: {
			type: Boolean as PropType<boolean | null>,
			default: null
		},
		itemLabel: {
			type: String as PropType<string | null>,
			default: null
		},
		canSubmitBeforeLenderReview: {
			type: Boolean as PropType<boolean | null>,
			default: null
		},
		preSubmitMessage: {
			type: String as PropType<string | null>,
			default: null
		},
		canLenderMarkReadyForLenderReview: {
			type: Boolean as PropType<boolean | null>,
			default: null
		}
	},
	data() {
		return {};
	},
	computed: {
		applicationStatus(): LoanApplication.ApplicationStatusEnum {
			return this.directStore.state.LoanApplicationData.loanApplication?.applicationStatus ?? LoanApplication.ApplicationStatusEnum.New;
		},
		notYetReadyForReview(): boolean {
			return !(this.isLenderReview || this.isReadyForBorrowerSubmission || isApplicationInFinalStatus(this.applicationStatus));
		},
		isLenderReview(): boolean {
			return this.applicationStatus === LoanApplication.ApplicationStatusEnum.LenderReview;
		},
		isReadyForBorrowerSubmission(): boolean {
			return this.applicationStatus === LoanApplication.ApplicationStatusEnum.ReadyForBorrowerSubmission;
		}
	},
	mounted() {},
	methods: {
		markReadyForReview() {
			this.$emit('mark-ready-for-review');
		},
		readyForBorrowerToSubmit() {
			this.$emit('readyForBorrowerToSubmit');
		},
		submitApplication() {
			this.$emit('submitApplication');
		},
		cancelReview() {
			this.$emit('cancel-review');
		}
	}
});
