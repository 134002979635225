
import { OnlinePortalRoutes } from '@/OnlinePortalRoutes';
import { ApplicationSubmitValidationErrors } from '@/utils/application-validation';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue, { PropType } from 'vue';

export default Vue.extend({
	name:'ApplicationValidationErrorMessage',
	components: {
		FontAwesomeIcon
	},
	props: {
		applicationId: Number,
		customerCanUseEsign: Boolean,
		error: {
			type: Object as PropType<ApplicationSubmitValidationErrors>,
			default : (): ApplicationSubmitValidationErrors => ({ messages: [], productsNotValid: [] })
		}
	},
	computed: {
		products(): { name: string, index: number, route: any }[] {
			const products = this.error?.productsNotValid ?? [];

			return products.map(x => ({
				...x,
				route: {
					name: OnlinePortalRoutes.ApplicationForm.name,
					params: { applicationId: this.applicationId.toString() }, query: { product: x.index + 1 }
				}
			}));
		}
	}
});
