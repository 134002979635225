// SOURCE: https://github.com/formio/formio.js/wiki/Form-JSON-Schema#role-permission-schema

export enum FormioRolePermissionTypes {
  create_own = 'create_own',
  create_all = 'create_all',
  read_own = 'read_own',
  read_all = 'read_all',
  edit_own = 'edit_own',
  edit_all = 'edit_all',
  delete_own = 'delete_own',
  delete_all = 'delete_all'
}

export interface FormioRolePermissionSchema {
  type: FormioRolePermissionTypes;
  roles: string[];
}
