import { CustomComponentType, TemplateDataField } from '../../../../../enums';
import { DataField } from '@sageworks/jpi';
import { DataFieldConvertStrategy } from '../../data-field-convert-strategy';
import { DefaultDataFieldConverter } from '../default-data-field-converter';

export class FinancialSubaccountDataFieldConverter implements DataFieldConvertStrategy {
	private allowedFieldByComponentTypeLookup = new Map<number, true | CustomComponentType[]>([
		[
			TemplateDataField.SubaccountName,
			[
				CustomComponentType.income,
				CustomComponentType.expense,
				CustomComponentType.stocksBondsBusinessHoldings,
				CustomComponentType.nonInvestmentRealEstate,
				CustomComponentType.otherAssets
			]
		],
		[
			TemplateDataField.SubaccountParentField,
			[
				CustomComponentType.income,
				CustomComponentType.expense,
				CustomComponentType.stocksBondsBusinessHoldings,
				CustomComponentType.nonInvestmentRealEstate,
				CustomComponentType.otherAssets
			]
		],

		// 'true' represents the data field is allowed for all component types
		[TemplateDataField.SubaccountValue, true]
	]);

	private readonly allowedFields = new Set<number>([
		TemplateDataField.SubaccountParentField,
		TemplateDataField.SubaccountName,
		TemplateDataField.SubaccountValue,

		// Acceptable fields for this convertor for the sole purpose to be ignored in
		// the builder
		TemplateDataField.SubaccountCustomer,
		TemplateDataField.SubaccountFinancialDataId,
		TemplateDataField.SubaccountFinancialStatement
	]);

	private readonly defaultDataFieldConverter: DefaultDataFieldConverter;

	constructor(private componentType?: CustomComponentType) {
		this.defaultDataFieldConverter = new DefaultDataFieldConverter();
	}

	isFieldValid(dataField: DataField): boolean {
		return !!dataField.templateDataFieldId && this.allowedFields.has(dataField.templateDataFieldId);
	}

	convert(dataField: DataField, currentProperties: any) {
		const properties = { ...currentProperties };
		properties.forceRequired = true;

		if (this.isIgnoredField(dataField)) {
			return null;
		}

		switch (dataField.templateDataFieldId) {
			case TemplateDataField.SubaccountParentField:
				this.setParentField(properties);
				break;
			default:
				return this.defaultDataFieldConverter.convert(dataField, properties);
		}

		return properties;
	}

	private isIgnoredField(dataField: DataField) {
		if (!dataField.templateDataFieldId) {
			return true;
		}

		const allowedComponentTypesOrFlag = this.allowedFieldByComponentTypeLookup.get(dataField.templateDataFieldId);

		// If allowedComponentTypesOrFlag is a flag and is true, then the field is allowed for all component types
		if (allowedComponentTypesOrFlag === true) {
			return false;
		}

		if (!(allowedComponentTypesOrFlag instanceof Array)) {
			return true;
		}

		return this.componentType && !allowedComponentTypesOrFlag.includes(this.componentType);
	}

	private setParentField(properties: any): any {
		switch (this.componentType) {
			case CustomComponentType.income:
				properties.type = CustomComponentType.personalIncomeTypeSelect;
				break;
			case CustomComponentType.expense:
				properties.type = CustomComponentType.personalExpenseTypeSelect;
				break;
			case CustomComponentType.stocksBondsBusinessHoldings:
				properties.type = CustomComponentType.personalInvestmentTypeSelect;
				break;
			case CustomComponentType.nonInvestmentRealEstate:
				properties.type = CustomComponentType.personalPropertyTypeSelect;
				break;
			case CustomComponentType.otherAssets:
				properties.type = CustomComponentType.personalOtherAssetsTypeSelect;
				break;
		}

		return properties;
	}
}
