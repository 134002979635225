
import { PropType } from 'vue';
import BaseFormMixin from '../mixins/base-form-mixin';
import FormioFormWrapper from './formio-form-wrapper/formio-form-wrapper.vue';
import { FormioFormSchema, FormioFormOptionsSchema, FormioHookType, FormioFormDisplayType } from '../formio-interfaces';
import { CustomTemplateFramework } from '../enums';
import TokenHandler from '../authentication/token-handler';
import { OpnRenderType } from '../enums/opn-render-type';

// using this syntax for the mixin as it keeps the linter/typescript happy and allows base-mixin methods/props to be seen properly
export default BaseFormMixin.extend({
	name: 'AuthenticatedForm',
	components: {
		FormioFormWrapper
	},
	props: {
		hooks: {
			type: Object as PropType<Map<FormioHookType, Function> | null>,
			required: false,
			default: function() {
				return null;
			}
		}
	},
	computed: {
		// TODO: figure out what to do with mixin options as part of DYA-767
		options(): FormioFormOptionsSchema {
			return {
				builder: this.builder,
				namespace: TokenHandler.GetTokenNamespace(this.subdomain),
				template: CustomTemplateFramework.formioBootstrap,
				base: this.apiUrl,
				project: this.apiUrl + '/' + this.subdomain,
				contextData: {
					...this.contextData,
					vueInstance: this.$root,
					jpiAuthenticated: this.jpiAuthenticated,
					opnRenderMode: OpnRenderType.Form,
				},
				hooks: this.hooks,
				readOnly: this.readOnlyMode,
				renderMode: this.readOnlyMode || this.validationMode ? 'html' : 'wizard',
				validationMode: this.validationMode ?? false,
				noAlerts: true
			} as FormioFormOptionsSchema;
		}
	},
	watch: {
		form: {
			handler: function() {
				this.$emit('form-change', { form: this.form, options: this.options });
			},
			immediate: true
		}
	},
	mounted() {
		this.initWithAuthentication();
	},
	methods: {
		nextPage() {
			this.$emit('next-page');
		},
		prevPage() {
			this.$emit('previous-page');
		},
		saveProgress: function() {
			this.$emit('save-form-data', {
				data: (this.$refs.$formioForm as any).formio.submission.data,
				currentPageIndex: (this.$refs.$formioForm as any).formio.page
			});
		},
		submit() {
			this.$emit('submit-form', {
				data: (this.$refs.$formioForm as any).formio.submission.data,
				form: (this.$refs.$formioForm as any).formio
			});
			return false;
		},
		onFormLoad(form: FormioFormSchema) {
			if (form.path === this.formPath && form.display === FormioFormDisplayType.wizard) {
				(this.$refs.$formioForm as any).formio.setPage((this as any).initialPage);
			}
		},
		checkValidity() {
			const formioInstance = (this.$refs.$formioForm as any).formio;
			return formioInstance.checkValidity(undefined, true, undefined, true);
		},
		saveError() {
			this.$emit('save-error');
		},
		customError(message: string) {
			this.$emit('error', [message]);
		},
		setupComplete() {
			this.$emit('setup-complete');
		},
		componentChange(changeEvent: any, allChangeEvents: any[], isModified: boolean) {
			if (!isModified) return;

			const { changed, isValid } = changeEvent ?? {};
			const { component, value } = changed ?? {};
			this.$emit('component-change', { component, isValid, value });
		},
		validationErrors(payload: any) {
			this.$emit('validation-errors', payload);
		}
	}
});
