import EditableApiSelectOptions from '../editable-api-select-options/editable-api-select-options';

export class FinancialSubaccountSelectOptions extends EditableApiSelectOptions {
	static schema(...extend: any) {
		return super.schema(
			{
				input: true,
				label: 'Data Source Values',
				key: 'data.values',
				disableAddingRemovingRows: true,
				tooltip:
					'Values to use as the data source. Labels are shown in the select field. Values are the corresponding values saved with the submission.',
				weight: 10,
				components: EditableApiSelectOptions.defaultComponentsHiddenValue
			},
			...extend
		);
	}

	static get builderInfo() {
		return {
			weight: 10,
			schema: FinancialSubaccountSelectOptions .schema({})
		};
	}

	protected async getApiOptions(): Promise<any> {}

	protected getOptionLabelForItem(item: any): string {
		return item.label;
	}

	protected getOptionValueForItem(item: any): any {
		return item.value;
	}
}
