module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div>\n	<div ref="modal">\n		<header class="modal-header">\n			<h2 class="modal-title">Add New Collateral</h2>\n		</header>\n		<div class="modal-body">\n			<div class="pb-1 d-flex justify-content-between align-items-center" data-test-id="MainMenu">\n				<h5 class="mb-0">Select Collateral to Import or:</h5>\n				<button id="AddNewCollateral" class="btn btn-primary" ref="addNewCollateralButton" data-test-id="addNewCollateralButton">\n					Add New\n				</button>\n			</div>\n				';
 if (ctx.collateralList && ctx.collateralList.length > 0) { ;
__p += '\n					<div class="list-group">\n						';
 for(var i=0; i < ctx.collateralList.length; i++) { ;
__p += '\n						<button type="button" \n						class="list-group-item list-group-item-action" \n						ref="addExistingCollateral" \n						data-test-id="' +
((__t = ( ctx.collateralList[i].value )) == null ? '' : __t) +
'" \n						id="' +
((__t = ( ctx.collateralList[i].value )) == null ? '' : __t) +
'"\n						>\n						' +
((__t = ( ctx.encodeHtml(ctx.collateralList[i].label) )) == null ? '' : __t) +
'\n						</button>\n						';
 } ;
__p += '\n					</div>\n				';
 } else { ;
__p += '\n				<div class="list-group" data-test-id="NoCollateralList">\n					<div class="list-group-item">No Collateral</div>\n				</div>\n				';
 } ;
__p += '			\n		</div>\n	</div>\n</div>';
return __p
}