export enum ComponentDataLoadStatus {
	NotLoaded,
	Loading,
	Loaded,
	LoadFailure
}

export type DataLoadFunc = () => Promise<any>;

export class ComponentDataLoader {
	private _status = ComponentDataLoadStatus.NotLoaded;
	private _dataLoaderFuncs: DataLoadFunc[] = [];
	private _currentDataLoadPromise?: Promise<void[]>;
	private _dataLoadedResolve!: Function;
	private _dataLoadedReject!: Function;
	private _dataLoadedPromise: Promise<void>;

	constructor() {
		this._dataLoadedPromise = new Promise((resolve, reject) => {
			this._dataLoadedResolve = resolve;
			this._dataLoadedReject = reject;
		});
	}

	public get isSuccessfulPromise() {
		return this._dataLoadedPromise;
	}

	public get status() {
		return this._status;
	}

	public addDataFunc(dataPromise: DataLoadFunc) {
		this._dataLoaderFuncs.push(dataPromise);
		return this;
	}

	public async loadData() {
		if (this._status !== ComponentDataLoadStatus.NotLoaded) return;
		try {
			this._status = ComponentDataLoadStatus.Loading;
			var promises = this._dataLoaderFuncs.map(dataInitFunc => dataInitFunc());

			this._currentDataLoadPromise = Promise.all(promises);
			await this._currentDataLoadPromise;

			this._status = ComponentDataLoadStatus.Loaded;
			this._dataLoadedResolve();
		} catch (err) {
			this._status = ComponentDataLoadStatus.LoadFailure;
			this._dataLoadedReject(err);

			throw err;
		}
	}
}
