
import Vue from 'vue';
import { useAuth0 } from '@/composables';
import { AutoFactory, OnlinePortalAuth0Service, OnlinePortalInstitutionsService } from '@sageworks/jpi';
import { UrlUtils } from '@/utils';
const { auth0 } = useAuth0();

export default Vue.extend({
	name: 'Auth0Login',
	async mounted() {
		if (auth0.loading) {
			await auth0.loadingPromise;
		}
		if (auth0.isAuthenticated) {
			return;
		}
		const windowLocation = window.location.href;
		if (windowLocation.indexOf('set-account-password') > 0 || windowLocation.indexOf('signup') > 0) {
			const subdomain = UrlUtils.getOnlinePortalSubdomain();
			const onlinePortalInstitutionsService = AutoFactory.get(OnlinePortalInstitutionsService);
			onlinePortalInstitutionsService.getBySubdomain(subdomain).then(function(result) {
				auth0.client?.loginWithRedirect({
					authorizationParams: {
						screen_hint: 'signup',
						connection: result.id
					}
				});
			});
		} else {
			const orgId = await AutoFactory.get(OnlinePortalAuth0Service).getAuth0OrganizationIdBySubdomain({ subdomain: UrlUtils.getOnlinePortalSubdomain() });
			auth0.client?.loginWithRedirect({
				authorizationParams: {
					organization: orgId
				}
			});
		}
	}
});
