export default {
	weight: -1,
	type: 'textfield',
	disabled: true,
	input: true,
	key: 'originalLabel',
	label: 'Original Label',
	tooltip: 'This is the label of this field prior to any modifications, and the label that will show when viewing this field across the Sageworks suite.',
	conditional: {
		show: false,
		when: 'originalLabel',
		eq: ''
	}
};
