import { Document as OnlinePortalDocument, DocumentContent } from '@sageworks/jpi';
import { FileUtils } from '@/utils';
import { CreateDocumentParams } from '@/models/store-action-params';
import { DateFormatting } from '@sageworks/core-logic';
import { OnlinePortalDocumentPostRequest } from '@sageworks/jpi';

function createOnlinePortalDocumentFromFile(fileToTransform: File, requestDocumentName: string, documentFolderId?: number): OnlinePortalDocument {
	return {
		name: requestDocumentName,
		fileName: fileToTransform.name,
		sizeInBytes: fileToTransform.size,
		isImage: fileToTransform.type.includes('image'),
		isDeleted: false,
		documentDated: DateFormatting.formatDateForJpi(new Date()),
		dateUploaded: DateFormatting.formatDateForJpi(new Date()),
		documentFolderId
	} as OnlinePortalDocument;
}

export async function createDocumentPostRequestFromFile(
	{
		fileToTransform,
		requestDocumentName,
		requestDocumentPassword,
		documentFolderId,
		associationCustomerId,
		useDocumentationRequestAutoSpreadTaxReturnSetting
	}: CreateDocumentParams,
	proposedLoanIds?: number[]
) {
	let document = createOnlinePortalDocumentFromFile(fileToTransform, requestDocumentName, documentFolderId);
	let fileContents = await FileUtils.fileToBase64String(fileToTransform).catch((err: any) => {
		alert(err);
	});
	return {
		document,
		documentPassword: requestDocumentPassword,
		documentContent: { content: fileContents } as DocumentContent,
		associationCustomerId,
		proposedLoanIds: proposedLoanIds,
		useDocumentationRequestAutoSpreadTaxReturnSetting: useDocumentationRequestAutoSpreadTaxReturnSetting
	} as OnlinePortalDocumentPostRequest;
}
