
import Vue, { PropType } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default Vue.extend({
	name: 'IconSelectorButton',
	components: {
		FontAwesomeIcon
	},
	props: {
		buttonText: {
			type: String as PropType<string>,
			default: '',
			required: true
		},
		buttonId: {
			type: Number as PropType<number>,
			default: 0,
			required: true
		},
		iconLeft: {
			type: String as PropType<String>,
			required: true
		},
		iconRight: {
			type: String as PropType<String>,
			required: true
		}
	},
	methods: {
		handleClick() {
			this.$emit('useBorrower', this.buttonId);
		}
	}
});
