import Field from 'formiojs/components/_classes/field/Field';
import Component from 'formiojs/components/_classes/component/Component';
import { findMatchingField, getContextDataValue } from '../../../utils';
import { ContextDataProperty } from '../../../enums';
import { Utils as FormioUtils, Formio } from 'formiojs';

export function formatDataFieldIdKey(dataFieldId: string) {
	return `__id_${dataFieldId}__`;
}

export function getKey(thisComponent: Field, superKey: string): string {
	if (thisComponent.builderMode || thisComponent.options.attachMode === 'builder') {
		return superKey;
	}

	// check if editing this components properties in an editForm
	if (thisComponent.root?.element != null && thisComponent.root.element.getAttribute('ref') === 'editForm') {
		return superKey;
	}

	if (
		thisComponent.component?.dataFieldId == null ||
		(typeof thisComponent.component.dataFieldId === 'string' && thisComponent.component.dataFieldId.trim() === '')
	) {
		return superKey;
	}

	return formatDataFieldIdKey(thisComponent.component.dataFieldId);
}

export function attachChangeEventEmitter(thisComponent: Field, event: string): void {
	let inputs: Node[] = [];
	if (thisComponent.refs.input instanceof NodeList) {
		inputs = Array.from(thisComponent.refs.input);
	} else if (thisComponent.refs.input instanceof Node) {
		inputs = [thisComponent.refs.input];
	}

	inputs.forEach(htmlInputElement => {
		thisComponent.addEventListener(htmlInputElement as HTMLElement, thisComponent.inputInfo.changeEvent, (e: Event) => {
			const x = (e?.target as HTMLInputElement)?.value;

			thisComponent.emit(event, { value: x, id: thisComponent.id, dataId: thisComponent.root.data.id });
		});
	});
}

export function attachChangeEventHandler(thisComponent: Field, event: string): void {
	thisComponent.on(
		event,
		({ value, id, dataId }: any) => {
			if (id !== thisComponent.id && dataId === thisComponent.root.data.id) {
				thisComponent.refs.input.forEach((_, index) => {
					// use updateValue if need other events to fired
					// however, we want to bypass events and just set the value
					thisComponent.setValueAt(index, value);
				});
			}
		},
		true
	);
}

export function attachWithChangeEvent(thisComponent: Field): void {
	if (
		thisComponent.component?.dataFieldId == null ||
		(typeof thisComponent.component.dataFieldId === 'string' && thisComponent.component.dataFieldId.trim() === '')
	) {
		return;
	}

	let event = `${thisComponent.component.dataFieldId}.${thisComponent.inputInfo.changeEvent}`;

	attachChangeEventEmitter(thisComponent, event);

	attachChangeEventHandler(thisComponent, event);
}

function checkLenderOnly(activeComponent: Component): boolean {
	if (activeComponent.component.lenderOnly) {
		return getContextDataValue(activeComponent, ContextDataProperty.LenderOnlyMode) && getContextDataValue(activeComponent, ContextDataProperty.IsLender);
	}
	return true;
}

export function checkReadOnlyComponentVisibility(activeComponent: Component): boolean {
	// Check PDF related component configurations in readOnly mode
	if (activeComponent.options.readOnly) {
		return !activeComponent.component.hideInPdf;
	}
	return true;
}

export function checkConditionalityAcrossForms(activeComponent: Component): boolean {
	if (activeComponent.builderMode) {
		return !activeComponent.component.hidden;
	}
	if (!checkLenderOnly(activeComponent) || !checkReadOnlyComponentVisibility(activeComponent)) {
		return false;
	}
	if (!activeComponent.hasCondition()) {
		return !activeComponent.component.hidden;
	}
	const dataField = findMatchingField(activeComponent, activeComponent.component.conditional.when);

	// If we didn't find the necessary field, don't change the visibility
	if (dataField == null || Object.keys(dataField).length === 0) {
		return activeComponent.visible;
	}

	let componentIsVisible: boolean;
	if (Array.isArray(activeComponent.component.conditional.eq)) {
		const matchingCondition = activeComponent.component.conditional.eq.some((eqCondition: any) => {
			const currentConditional = { ...activeComponent.component.conditional, eq: eqCondition };
			return activeComponent.component.conditional.show
				? FormioUtils.checkSimpleConditional(null, currentConditional, null, dataField)
				: !FormioUtils.checkSimpleConditional(null, currentConditional, null, dataField);
		});

		componentIsVisible = activeComponent.component.conditional.show ? matchingCondition : !matchingCondition;
	} else {
		componentIsVisible = FormioUtils.checkSimpleConditional(null, activeComponent.component.conditional, null, dataField);
	}

	activeComponent.visible = componentIsVisible;
	return componentIsVisible;
}

export function forcedLoadSubForm(component: any) {
	// Determine if we already have a loaded form object.
	if (component.formObj && component.formObj.components && Array.isArray(component.formObj.components) && component.formObj.components.length) {
		// Pass config down to sub forms.
		if (component.root && component.root.form && component.root.form.config && !component.formObj.config) {
			component.formObj.config = component.root.form.config;
		}
		return Promise.resolve(component.formObj);
	} else if (component.formSrc) {
		return new Formio(component.formSrc).loadForm({ params: { live: 1 } }).then((formObj: any) => {
			component.formObj = formObj;
			return formObj;
		});
	}
	return Promise.resolve();
}

/**
 * Set the default value into the submission when appropriate
 */
export function handleSetDefaultValue(component: Component, index: number, value: number, flags: any = {}) {
	if (!flags.noDefault && (value === null || value === undefined) && !component.component.multiple) {
		component.dataValue = component.defaultValue;
	}
}

const containsCustomConditionality = ({ component }: Component) => {
	const { conditional, customConditional } = component;

	return conditional?.json || customConditional;
};

export function shouldFieldUseOriginalConditionality(instance: Component): boolean {
	const { attached } = instance;

	return checkLenderOnly(instance) && (containsCustomConditionality(instance) || !attached);
}

export function shouldInputFieldUseOriginalConditionality(instance: any): boolean {
	const { triggerUpdateValueAt } = instance;

	return checkLenderOnly(instance) && (containsCustomConditionality(instance) || triggerUpdateValueAt == null);
}

export function shouldSelectFieldUseOriginalConditionality(instance: any): boolean {
	const { triggerUpdate } = instance;

	return checkLenderOnly(instance) && (containsCustomConditionality(instance) || triggerUpdate == null);
}

export function shouldComponentUseOriginalConditionality(instance: Component): boolean {
	return checkLenderOnly(instance) && containsCustomConditionality(instance);
}

export function shouldEditSelectUseOriginalConditionality(instance: Component): boolean {
	return checkLenderOnly(instance) && containsCustomConditionality(instance);
}
