import RadioComponent from 'formiojs/components/radio/Radio';
import Field from 'formiojs/components/_classes/field/Field';
import { isNil } from 'lodash';
import { CustomComponentType, CustomComponentLabel, ContextDataProperty, TemplateDataField } from '../../../enums';
import { LocateParentHelper } from '../../../utils';
import editForm from './applicant-business-question.form';
import { isInPreviewMode } from '../../../utils/formio-template-helpers/builder-template-helpers';
import { getContextDataValue } from '../../../utils/context-data-helper/context-data-helper';
import ApplicantBusinessConfirmChangePopup from './applicant-business-confirm-change-popup/applicant-business-confirm-change-popup';
import { PopupWrapper } from '../popup';
import ApplicantBusinessConfirmRemovePopup from './applicant-business-confirm-remove-popup/applicant-business-confirm-remove-popup';
import { ApplicationTemplate, DataField, ObjectPropertyValues, ProposedLoanSBAInfo } from '@sageworks/jpi';
import { ApplicantBusinessQuestionService } from './applicant-business-question.service';
import { checkConditionalityAcrossForms, shouldFieldUseOriginalConditionality } from '../simple-inputs/extended-field-helper';
import { FormioConditionalityUtils } from '../../../utils/formio-conditionality-utils';

export enum DataLoadStatus {
	NotLoaded,
	Loading,
	Loaded
}

export default class ApplicantBusinessQuestionWidget extends RadioComponent {
	static schema(...extend: any) {
		return RadioComponent.schema(
			{
				label: CustomComponentLabel()[CustomComponentType.applicantBusinessQuestion],
				type: CustomComponentType.applicantBusinessQuestion,
				key: CustomComponentType.applicantBusinessQuestion,
				// Needed for the data field extractor to knows to add these fields when retrieving data
				dataFields: [],
				values: [
					{ label: 'Yes', value: true },
					{ label: 'No', value: false }
				]
			},
			...extend
		);
	}

	static editForm = editForm;

	static createBuilderInfo(dataFields: DataField[], appType: ApplicationTemplate.TypeEnum) {
		const dataFieldIdsNeeded = new Set([TemplateDataField.BusinessName]);
		const filteredDataFields: DataField[] = dataFields.filter(field => dataFieldIdsNeeded.has(field.templateDataFieldId || -1));
		const requireField = appType === ApplicationTemplate.TypeEnum.Sba;

		return {
			...ApplicantBusinessQuestionWidget.builderInfo,
			schema: ApplicantBusinessQuestionWidget.schema({ dataFields: filteredDataFields, forceRequired: requireField })
		};
	}

	static get builderInfo() {
		return {
			title: CustomComponentLabel()[CustomComponentType.applicantBusinessQuestion],
			group: '',
			weight: 10,
			schema: ApplicantBusinessQuestionWidget.schema()
		};
	}

	private get sbaInfo() {
		return this.service.sbaInfo;
	}

	private get loadingTemplate() {
		return `
			<div class="pl-3 pt-2 d-flex flex-column">
				<div class="h-3rem w-3rem spinner-border text-primary" role="status">
					<span class="sr-only">Loading...</span>
				</div>
			</div>
		`;
	}

	private get customerId(): number {
		const parent = LocateParentHelper.locateClosestParent(this, x => x.type === CustomComponentType.businessInfo);
		return parent?.dataValue?.data?.id ?? -1;
	}

	private get canUtilizeData() {
		return !this.builderMode && !isInPreviewMode(this) && getContextDataValue(this, ContextDataProperty.IsJpiAuthenticated);
	}

	private onDataInit: Promise<any> | null = null;
	private dataLoadStatus!: DataLoadStatus;
	private service: ApplicantBusinessQuestionService;
	private dataFieldIdMap!: { [defaultFieldId: number]: number };

	constructor(component: any, options: Object, data: Object) {
		super(component, options, data);

		this.service = new ApplicantBusinessQuestionService(this, { onSbaInfoChange: this.onSbaInfoChange.bind(this) });
	}

	public init() {
		super.init();
		this.dataFieldIdMap = ((this.component.dataFields as DataField[]) ?? []).reduce((map, field) => {
			if (field.templateDataFieldId && field.templateDataFieldId > 0) map[field.templateDataFieldId] = field.id as number;
			return map;
		}, {} as { [defaultFieldId: number]: number });
		this.dataLoadStatus = DataLoadStatus.NotLoaded;
		this.on('componentChange', this.onValueChange.bind(this), true);
	}

	public destroy() {
		super.destroy();
		this.service.destroy();
	}

	public render() {
		if (this.dataLoadStatus === DataLoadStatus.Loading) {
			const loadingTemplate = this.renderString(this.loadingTemplate, {});
			return Field.prototype.render.apply(this, [loadingTemplate]);
		} else {
			return super.render();
		}
	}

	public attach(element: any) {
		this.loadInitData();

		return super.attach(element);
	}

	public updateValue(value: any, flags?: object) {
		const currentValue = isNil(value) ? this.getValue() === 'true' : value;

		// We don't want to trigger another update if the value hasn't changed, b/c this will trigger a reset (set it to the emptyValue)
		// 	which triggers another value change
		if (currentValue === this.previousValue) return;

		super.updateValue(value, flags);
	}

	private async loadInitData() {
		if (this.dataLoadStatus !== DataLoadStatus.NotLoaded || !this.canUtilizeData) {
			return;
		}

		this.dataLoadStatus = DataLoadStatus.Loading;
		this.redraw();

		this.onDataInit = this.service.loadData();
		await this.onDataInit;

		this.setLoadedData();

		this.dataLoadStatus = DataLoadStatus.Loaded;
		this.redraw();
	}

	private async setLoadedData() {
		// Prevent update event so we do not makes an api request to update the sba data entity since we are setting the initial value
		const isBusinessTheApplicantBusiness = this.sbaInfo?.applicantBusinessCustomerId === this.customerId;
		this.updateValue(isBusinessTheApplicantBusiness, { noUpdateEvent: true, modified: true });
	}

	private async updateApplicantBusiness(isApplicantBusiness: boolean) {
		try {
			this.dataLoadStatus = DataLoadStatus.Loading;
			this.redraw();

			const businessId = isApplicantBusiness ? this.customerId : undefined;
			await this.service.updateApplicantBusiness(businessId);
		} catch (err) {
			// Revert value back to previous value since something failed to persist the applicant business
			this.updateValue(!this.dataValue, { noUpdateEvent: true, modified: true });

			throw err;
		} finally {
			this.dataLoadStatus = DataLoadStatus.Loaded;
			this.redraw();
		}
	}

	private async onValueChange(changed: any) {
		// We only care about the value changes of this instance
		// We also don't want to execute an update to the sba data object if data requests are already happening (edge case)
		if (changed.instance !== this || this.dataLoadStatus === DataLoadStatus.Loading || !this.canUtilizeData) return;
		// Ensure we have the data to update the applicant business
		else if (!this.sbaInfo || this.customerId <= 0) return;

		const { applicantBusinessCustomerId: currentApplicantBusinessId } = this.sbaInfo;

		if (!changed.value) {
			await this.showConfirmRemoveModal();
		} else if (currentApplicantBusinessId && currentApplicantBusinessId !== this.customerId) {
			await this.showConfirmChangeModal();
		} else {
			await this.updateApplicantBusiness(true);
		}
	}

	private async showConfirmChangeModal() {
		const currentApplicantBusiness = await this.service.getCurrentApplicantBusiness();
		const currentBusiness = await this.service.getBusiness(this.customerId);

		let confirmChangePopup = new ApplicantBusinessConfirmChangePopup(
			ApplicantBusinessConfirmChangePopup.schema({
				oldBusinessName: currentApplicantBusiness ? this.getBusinessName(currentApplicantBusiness) : '',
				newBusinessName: currentBusiness ? this.getBusinessName(currentBusiness) : ''
			}),
			{
				...this.options,
				parent: this,
				skipInit: false
			},
			{}
		);

		const wasSuccessful = await this.showDecisionModal(confirmChangePopup);

		if (wasSuccessful) {
			this.updateApplicantBusiness(true);
		}
	}

	conditionallyVisible(data: any): boolean {
		if (shouldFieldUseOriginalConditionality(this)) {
			return super.conditionallyVisible(data);
		}

		return checkConditionalityAcrossForms(this);
	}

	checkCondition(row: any, data: any) {
		return FormioConditionalityUtils.checkCondition(
			this.component,
			row || this.data,
			data || this.rootValue,
			this.root ? (this.root as any)._form : {},
			this
		);
	}

	private async showConfirmRemoveModal() {
		const currentBusiness = await this.service.getBusiness(this.customerId);

		let confirmRemovePopup = new ApplicantBusinessConfirmRemovePopup(
			ApplicantBusinessConfirmRemovePopup.schema({
				businessName: this.getBusinessName(currentBusiness)
			}),
			{
				...this.options,
				parent: this,
				skipInit: false
			},
			{}
		);

		const wasSuccessful = await this.showDecisionModal(confirmRemovePopup);

		if (wasSuccessful) {
			this.updateApplicantBusiness(false);
		}
	}

	private async showDecisionModal(popupComponent: any) {
		let popupWrapper = new PopupWrapper({}, { parent: this }, {}, popupComponent);

		popupWrapper.showPopup();
		const actionResult: boolean = await popupWrapper.actionCompleted;

		if (!actionResult) {
			this.updateValue(!this.dataValue, { noUpdateEvent: true, modified: true });
			this.redraw();
		}

		return actionResult;
	}

	private getBusinessName(dataObject: ObjectPropertyValues) {
		const businessNameFieldId = this.dataFieldIdMap[TemplateDataField.BusinessName];
		return dataObject?.dataFieldValues?.find(x => x.id === businessNameFieldId)?.value ?? 'N/a';
	}

	private onSbaInfoChange({ applicantBusinessCustomerId }: ProposedLoanSBAInfo) {
		const isApplicantBusiness = applicantBusinessCustomerId === this.customerId;
		this.updateValue(isApplicantBusiness, { noUpdateEvent: true, modified: true });
		this.redraw();
	}
}
