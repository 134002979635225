import { TermsAndConditionsUtils } from '@/utils';
import {
	AutoFactory,
	ApplicationTemplate,
	DynamicApplicationMessagesService,
	DynamicApplicationMessage,
	OnlinePortalLoanApplicationsService,
	LoanApplication
} from '@sageworks/jpi';
import { defineModule, localActionContext } from 'direct-vuex';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => localActionContext(context, TermsAndConditionsModule);

export interface TermsAndConditionsModuleState {
	termsAndConditionsMessageHtml: string | null;
}
const TermsAndConditionsModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			termsAndConditionsMessageHtml: null
		} as TermsAndConditionsModuleState;
	},
	mutations: {
		SET_TERMS_AND_CONDITIONS_MESSAGE(state, termsAndConditionsMessageHtml: string | null) {
			state.termsAndConditionsMessageHtml = termsAndConditionsMessageHtml;
		}
	},
	actions: {
		async fetchTermsAndConditionsMessage(context, applicationType: ApplicationTemplate.TypeEnum): Promise<void> {
			const { commit } = actionContext(context);
			const dynamicApplicationMessagesService = AutoFactory.get(DynamicApplicationMessagesService);

			const appTypes = [applicationType];
			const messageTypes = [DynamicApplicationMessage.MessageTypeEnum.TermsAndConditions];
			const response = await dynamicApplicationMessagesService.getPaged(1, 1, appTypes, messageTypes);

			if (response.items != null && response.items.length === 1) {
				const termsAndConditionsMessageHtml = response.items[0].message ?? null;
				commit.SET_TERMS_AND_CONDITIONS_MESSAGE(termsAndConditionsMessageHtml);
			} else {
				commit.SET_TERMS_AND_CONDITIONS_MESSAGE('');
			}
		},
		async acceptTermsAndConditions(context, { loanApplication, username }: { loanApplication: LoanApplication, username: string })
		: Promise<LoanApplication> {
			const loanApplicationService = AutoFactory.get(OnlinePortalLoanApplicationsService);
			TermsAndConditionsUtils.updateLoanApplicationToAcceptTermsAndConditions(loanApplication, username);
			const savedLoanApplication = await loanApplicationService.update(loanApplication.id, loanApplication);

			return savedLoanApplication;
		}
	},
	getters: {
		termsAndConditionsMessageHtml(state): string | null {
			return state?.termsAndConditionsMessageHtml ?? null;
		}
	}
});

export default TermsAndConditionsModule;
