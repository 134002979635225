import { BeneficialOwnership1071Status } from '@/formio-vue/utils/beneficial-ownership';
import { OwnerRowData } from '../../models';
import { WidgetValidationMessage } from '../widget-validation-message';
import { BeneficialOwnerFormDataModel } from '@sageworks/jpi';
import { ValidationConfig } from '../validation-config';

export const ATLEAST_ONE_OWNER_VALIDATION_MSG = 'At least one beneficial owner is required';
export const CONTAINS_MULTIPLE_OWNERS_VALIDATION_MSG = 'Cannot have multiple beneficial owners';
export const GREATER_THAN_100_PERCENT_VALIDATION_MSG = 'Total ownership of this business cannot be greater than 100%';
export const DEMOGRAPHIC_1071_STATUS_NOT_VALID_VALIDATION_MSG = 'All applicable beneficial owners must have completed 1071 Demographic Data';

/**
 * 
 * Helper functions
 * 
 */

const calculateTotalPercentage = (data: OwnerRowData[]) => {
	return data.reduce((total, owner) => { 
		total += owner.ownershipPercent ?? 0;
		return total;
	}, 0);
};

const areAllDemographic1071StatusesValid = (data: OwnerRowData[]) => {
	const filteredData = data
	.filter(owner => owner.entityType === BeneficialOwnerFormDataModel.OwnerEntityTypeEnum.Person && (owner.ownershipPercent ?? 0) >= .25);

	const allowedStatuses = [BeneficialOwnership1071Status.Completed, BeneficialOwnership1071Status.NotApplicable];
	return filteredData.every(owner => allowedStatuses.includes(owner.demographic1071InformationStatus ?? BeneficialOwnership1071Status.NotApplicable));
};

/**
 * Validation functions for beneficial ownership
 * NOTE: validate function should return true if the data is valid, false otherwise
 */
type ValidateFunc = (data: OwnerRowData[], is1071Loan: boolean, validationConfig: ValidationConfig) => boolean;
const validations: { validate: ValidateFunc, message: string }[] = [
	{
		message: GREATER_THAN_100_PERCENT_VALIDATION_MSG,
		validate: (data) => calculateTotalPercentage(data) <= 1,
	},
	{
		message: ATLEAST_ONE_OWNER_VALIDATION_MSG,
		validate: (data, _, { required }) => !required || data.length > 0,
	},
	{
		message: CONTAINS_MULTIPLE_OWNERS_VALIDATION_MSG,
		validate: (data, _, { multiple }) => multiple || data.length <= 1,
	},
	{
		message: DEMOGRAPHIC_1071_STATUS_NOT_VALID_VALIDATION_MSG,
		validate: (data, is1071Loan) => !is1071Loan || areAllDemographic1071StatusesValid(data),
	},
];

/**
 * Runs all validations on the provided data
 */
export const validateOwners = (data: OwnerRowData[], is1071Loan: boolean, validationConfig: ValidationConfig) => {
	return validations
		.map(({ validate, message }) => validate(data, is1071Loan, validationConfig) ? null : message)
		.filter(message => message !== null)
		.map(message => ({ message: message as string, level: 'error' } as WidgetValidationMessage));
};