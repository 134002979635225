import { DataField } from '@sageworks/jpi';
import { StaticFormUtils } from '../../../utils';
import { TemplateDataField, FormioComponentType } from '../../../enums';
import { FormioFormDisplayType } from '../../../formio-interfaces';
import { generateHiddenRoleFieldComponents } from '../related-role-repeater-widget/create-components-helper';
import ExtendedTextField from '../simple-inputs/extended-text-field/extended-text-field';

export const BeneficialOwnershipDataFieldIds = [
	TemplateDataField.BeneficialOwnerDateUpdated,
	TemplateDataField.BeneficialOwnerPercent,
	TemplateDataField.BeneficialOwnerRoleType
];

export const generateFormJson = (dataFields: DataField[], readOnly: boolean = false) => {
	const dataFieldSchemaMap = StaticFormUtils.dataFieldListToSchemaMap(dataFields);

	var beneficialOwnerComponents: any[] = [
		{
			...dataFieldSchemaMap[TemplateDataField.BeneficialOwnerRoleType],
			onSetItems: (instance: any, items: any) => {
				if (instance.defaultValue === '') {
					instance.component.defaultValue = items['items'][0].value;
				}
			}
		},
		dataFieldSchemaMap[TemplateDataField.BeneficialOwnerPercent],
		{
			...dataFieldSchemaMap[TemplateDataField.BeneficialOwnerDateUpdated],
			defaultDate: 'moment()'
		},
		// Needed so the component is aware that data exists (this will prevent it from being empty and not clear out the existing data)
		...generateHiddenRoleFieldComponents()
	];

	// Add the the benecial owner name ONLY when rendering the fieldset in readOnly mode
	if (readOnly) {
		// Add the new field to the front of the array
		beneficialOwnerComponents.unshift(ExtendedTextField.schema({ key: 'displayName', label: 'Beneficial Owner'}));
	}

	return {
		type: FormioComponentType.Form,
		title: 'beneficial ownership - edit form',
		display: FormioFormDisplayType.form,
		components: beneficialOwnerComponents
	};
};
