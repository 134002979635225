import { CustomComponentType } from '@sageworks/dynamic-forms';

interface BorrowerInfo {
	id: number;
	name: string;
	type: CustomComponentType;
}

// eslint-disable-next-line max-lines-per-function
export function getLoanRoleCustomerInformationFromFormData(
	formData: any,
	borrowerEntityType: CustomComponentType,
	businessNameId: Number,
	personFirstNameId: Number,
	personLastNameId: Number,
	farmNameId: Number
) {
	if (!formData.data || !formData.data[borrowerEntityType] || formData.data[borrowerEntityType].length === 0) return [];

	let loanRoleCustomerInformation: BorrowerInfo[] = [];

	var borrowerEntities = formData.data[borrowerEntityType];
	var borrowerInfo: BorrowerInfo;

	borrowerEntities.forEach((entity: any) => {
		let gatheredBorrowerInfo: Boolean = false;

		if (entity.form.data[CustomComponentType.businessInfo] && businessNameId > 0) {
			borrowerInfo = retrieveEntityInfo(entity, businessNameId, CustomComponentType.businessInfo);
			gatheredBorrowerInfo = borrowerInfo && Boolean(borrowerInfo.id);
		}

		if (!gatheredBorrowerInfo && entity.form.data[CustomComponentType.nonprofitInfo] && businessNameId > 0) {
			borrowerInfo = retrieveEntityInfo(entity, businessNameId, CustomComponentType.nonprofitInfo);
			gatheredBorrowerInfo = borrowerInfo && Boolean(borrowerInfo.id);
		}
		if (!gatheredBorrowerInfo && entity.form.data[CustomComponentType.farmInfo] && farmNameId > 0) {
			borrowerInfo = retrieveEntityInfo(entity, farmNameId, CustomComponentType.farmInfo);
			gatheredBorrowerInfo = borrowerInfo && Boolean(borrowerInfo.id);
		}

		if (!gatheredBorrowerInfo && entity.form.data[CustomComponentType.personalInfo] && personLastNameId > 0 && personLastNameId > 0) {
			borrowerInfo = retrievePersonalInfo(entity, personFirstNameId, personLastNameId);
			gatheredBorrowerInfo = borrowerInfo && Boolean(borrowerInfo.id);
		}

		if (gatheredBorrowerInfo) {
			loanRoleCustomerInformation.push(borrowerInfo);
		}
	});

	return loanRoleCustomerInformation;
}

export function retrieveEntityInfo(entity: any, entityNameId: Number, entityType: CustomComponentType): BorrowerInfo {
	var customerId = entity.form.data[entityType]?.data.id;
	var entityName: string = entity.form.data[entityType].data[`__id_${entityNameId}__`];
	var entityInfo: BorrowerInfo = {
		id: customerId,
		name: entityName || '',
		type: entityType
	};

	return entityInfo;
}

function retrievePersonalInfo(entity: any, personFirstNameId: Number, personLastNameId: Number): BorrowerInfo {
	var customerId = entity.form.data[CustomComponentType.personalInfo]?.data.id;
	var personFirstName = entity.form.data[CustomComponentType.personalInfo].data[`__id_${personFirstNameId}__`];
	var personLastName = entity.form.data[CustomComponentType.personalInfo].data[`__id_${personLastNameId}__`];
	var personBorrowerName = `${personFirstName} ${personLastName}`;

	var personalInfo: BorrowerInfo = {
		id: customerId,
		name: personBorrowerName || '',
		type: CustomComponentType.personalInfo
	};

	return personalInfo;
}
