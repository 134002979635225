import { IPendoAccount, IPendoVisitor, IPendoInitializationObject } from '@/models';
import { OnlinePortalInstitution, LoanOfficer, OnlinePortalUser } from '@sageworks/jpi';

export async function initializePendoForOnlinePortalUser(user: OnlinePortalUser, institution: OnlinePortalInstitution) {
	if (user.id! > 0) {
		_initialize({
			id: 'BCU_' + user.id,
			firstName: user.firstName,
			accountId: institution.id,
			isBankCustomerUser: true
		} as IPendoVisitor);
	}
}

export async function initializePendoForLoanOfficerProxyUser(proxyUser: LoanOfficer, institution: OnlinePortalInstitution) {
	if (proxyUser.id! > 0) {
		_initialize({
			id: proxyUser.id,
			firstName: proxyUser.firstName,
			accountId: institution.id,
			isBankCustomerUser: false
		} as IPendoVisitor);
	}
}

// Call this whenever information about your visitors becomes available
function _initialize(userData: IPendoVisitor) {
	// Please use Strings, Numbers, or Bools for value types.
	var initializationObject = {
		visitor: userData || null,
		// {
		// id: 'VISITOR-UNIQUE-ID'   // Required if user is logged in

		// You can add any additional visitor level key-values here,
		// as long as it's not one of the above reserved names.
		// },

		account:
			({
				id: userData.accountId
			} as IPendoAccount) || null
		// {
		//	id: 'ACCOUNT-UNIQUE-ID' // Highly recommended

		// You can add any additional account level key-values here,
		// as long as it's not one of the above reserved names.
		// }
	} as IPendoInitializationObject;

	// window.pendo.initialize should be registered by index.html
	(window as any).pendo.initialize(initializationObject);
}
