import { Environment } from '@sageworks/core-logic/src/enums/environment';
import { OnlinePortalInstitutionsService, AutoFactory, OnlinePortalInstitution } from '@sageworks/jpi';
import { OnlinePortalRoutes } from '@/OnlinePortalRoutes';

const BLUE = 'blue';
const GREEN = 'green';
const BLUE_UAT = BLUE + Environment.UAT;
const GREEN_UAT = GREEN + Environment.UAT;
const reservedNonProductionSubdomains = [
	'localhost',
	'maintenance',
	Environment.Local,
	Environment.Staging,
	Environment.UAT,
	Environment.HFX,
	Environment.DR,
	BLUE_UAT,
	GREEN_UAT,
	BLUE,
	GREEN
];

export function getOnlinePortalSubdomain(): string {
	let subdomain = getWindowLocationSubdomain();

	// On non production environments our "Customer Subdomain" is stored in the pathname, which occurs before the # route
	// i.e. staging.onlineportalnow.com/demobank/#/login
	if (reservedNonProductionSubdomains.includes(subdomain)) {
		// location.pathname always comes in format of '/subdomain'
		subdomain = _getWindowLocationPathname();
	}

	return subdomain;
}

export function inSandbox(): boolean {
	const subdomain = getOnlinePortalSubdomain();
	return subdomain.endsWith('-sandbox');
}

export async function isSubdomainValid(): Promise<boolean> {
	let subdomain: string = getOnlinePortalSubdomain();
	if (subdomain === null || subdomain === undefined || subdomain.trim() === '') {
		return false;
	}

	// If we got an exception other than 404 with expected message, return inconclusive.
	let subdomainIsValid: boolean | null = null;
	let retryAttempts = 5;
	while (subdomainIsValid === null && retryAttempts > 0) {
		retryAttempts--;
		subdomainIsValid = await _isSubdomainAssociatedWithInstitution();
	}

	if (subdomainIsValid === null) {
		subdomainIsValid = false;
	}
	return subdomainIsValid;
}

var cachedInstitutionPromise: Promise<OnlinePortalInstitution> | null = null;
export async function getOnlinePortalInstitutionFromUrl(): Promise<OnlinePortalInstitution> {
	const onlinePortalInstitutionsService: OnlinePortalInstitutionsService = AutoFactory.get(OnlinePortalInstitutionsService);
	return cachedInstitutionPromise ?? (cachedInstitutionPromise = onlinePortalInstitutionsService.getBySubdomain(getOnlinePortalSubdomain()));
}

async function _isSubdomainAssociatedWithInstitution(): Promise<boolean | null> {
	try {
		await getOnlinePortalInstitutionFromUrl();
		return true;
	} catch (error) {
		// 404 with expected message indicates an invalid subdomain.
		if (error.status === 404 && error.response.message === 'Institution not found') {
			return false;
		}

		// If we got an exception other than 404 with expected message, return inconclusive.
		return null;
	}
}

export function getAnalystApiUrl(): string | undefined {
	if (!window.app) return undefined;

	const url = inSandbox()
		? window.app.sandboxAnalystApiUrl
		: window.app.analystApiUrl;

	return _getWithBlueGreenPrefix(url);
}

export function getAuthUrl(): string | undefined {
	if (!window.app) return undefined;

	// If we add blue/green to auth, we should replace the following line with:
	// return _getWithBlueGreenPrefix(window.app.authUrl);
	return inSandbox() 
		? window.app.sandboxAuthUrl
		: window.app.authUrl;
}

export function getAuth0Domain(): string | undefined {
	if (!window.app) return undefined;
	return window.app.auth0Domain;
}

export function getAuth0ClientId(): string | undefined {
	if (!window.app) return undefined;
	return window.app.auth0ClientId;
}

export function getWindowLocationSubdomain() {
	return window.location.hostname.split('.')[0];
}

export function getRedirectUrl(): string {
	return window.location.href.split('#')[0] + '#' + OnlinePortalRoutes.Home.path;
}

export function getLogoutRedirectUrl(): string {
	return window.location.href.split('#')[0] + '#' + OnlinePortalRoutes.Login.path;
}

function _getWindowLocationPathname() {
	return window.location.pathname.split('/')[1];
}

function _getWithBlueGreenPrefix(url: string) {
	let subdomain = getWindowLocationSubdomain();

	let prefix: string = '';
	if (subdomain === BLUE || subdomain === BLUE_UAT) prefix = BLUE;
	if (subdomain === GREEN || subdomain === GREEN_UAT) prefix = GREEN;

	let https = `https://`;
	if (url.startsWith(https)) {
		url = url.replace(https, https + prefix);
	} else {
		url = prefix + url;
	}

	return url;
}
