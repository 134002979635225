
import { Component, Mixins } from 'vue-property-decorator';
import { RouteMixin } from '@/mixins';
import { IdentityVerificationHeader } from '@/components';
import { UrlUtils } from '@/utils';

@Component({
	components: { IdentityVerificationHeader }
})
export default class IdentityProtection extends Mixins(RouteMixin) {
	institutionName = '';
	newAccount = true;
	identityMessage = "In order to protect your identity, you'll now be asked to enter information about yourself and answer a few questions.";
	async created() {
		this.newAccount = this.directStore.state.Authentication.newAccount;
		if (this.newAccount) {
			this.institutionName = (await UrlUtils.getOnlinePortalInstitutionFromUrl())?.institutionName ?? '';
		}
	}
}
