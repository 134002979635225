import {
	LoanApplicationProposedLien,
	ObjectPropertyValues,
	LoanApplicationCreditAuthorization,
	Customer,
	FinancialSubaccount,
	ProposedLoanSBAInfo
} from '@sageworks/jpi';
import { CustomComponentType, DataObject, LoanRoleType, FinancialStatementType, FinancialSubaccountParent } from '../../enums';
import { LoanRoleWithCustomerType, RelatedRoleWithCustomerType, DataObjectsByType, BeneficialOwnerWithCustomerType } from '../../models';
import { DataObjectUtils } from '../data-object-utils/data-object-utils';
import { convertSubaccountParentToComponentType } from './financial-subaccount-parent-mapping';
import * as FormObjectFactory from './form-object-factory';

export interface IFormDataBuilder {
	setCreditReportAuthorizations(creditReportAuthorizations: LoanApplicationCreditAuthorization[] | null): void;

	addPrimaryBorrower(
		mappings: LoanRoleWithCustomerType[],
		relatedMappings: RelatedRoleWithCustomerType[] | null,
		personalFinancialSubaccounts: FinancialSubaccount[] | null,
		sbaInfo: ProposedLoanSBAInfo | null
	): void;

	addRepeatingRole(mappings: LoanRoleWithCustomerType[], roleType: LoanRoleType, relatedMappings: RelatedRoleWithCustomerType[] | null): void;

	addLiens(lienMappings: LoanApplicationProposedLien[]): void;

	getSubmissionData(): any;
}

/**
 * Converts JPI DataObjects (ObjectPropertyValues) to dynamic-form submissionData
 *
 */

function getSubaccountComponentTypeByFinancialStatementTypeString(
	financialStatementTypeString: string,
	subaccountParent: string,
	subaccountName?: string
): string | null {
	let financialStatementType: FinancialStatementType = financialStatementTypeString as FinancialStatementType;
	if (!Object.values(FinancialStatementType).includes(financialStatementType)) {
		return null;
	}
	switch (financialStatementType) {
		case FinancialStatementType.IncomeExpenses:
		case FinancialStatementType.Liabilities:
		case FinancialStatementType.CurrentAssets:
			return convertSubaccountParentToComponentType(subaccountParent as FinancialSubaccountParent, subaccountName);
		// If there isn't a custom component type mapped to this financial statement type, ignore it
		default:
			return null;
	}
}

export class SubmissionDataMapper implements IFormDataBuilder {
	private readonly _dataObjectsByType: DataObjectsByType;
	private _collateralDataObjectJoinCache: Map<number, ObjectPropertyValues>;
	private _roleDataObjectJoinCache: Map<number, ObjectPropertyValues>;
	private _financialSubaccountsJoinCache: Map<number, ObjectPropertyValues>;
	private _proposedLoanId: number | null;
	private _creditReportAuthorizations: LoanApplicationCreditAuthorization[] | null;
	private _presetDataFieldsByDataObject: FormObjectFactory.PresetDataFieldsByDataObject | null;
	private _beneficialOwners: BeneficialOwnerWithCustomerType[] = [];
	private _financialSubaccounts: FinancialSubaccount[] = [];
	private _sbaInfo: ProposedLoanSBAInfo | null;
	private readonly _submissionData: any;
	private readonly _state: string = 'draft';

	static readonly DataObjectTypeToComponentKeyLookup: Record<string, string> = {
		[DataObject.TypeEnum.Business]: CustomComponentType.businessInfo,
		[DataObject.TypeEnum.Person]: CustomComponentType.personalInfo,
		[DataObject.TypeEnum.Farm]: CustomComponentType.farmInfo,
		[DataObject.TypeEnum.NonProfit]: CustomComponentType.nonprofitInfo,
		[DataObject.TypeEnum.Contact]: CustomComponentType.relatedContacts,
		// [DataObject.TypeEnum.PortfolioLoan]: [CustomComponentType.portfolioLoan,
		[DataObject.TypeEnum.ProposedLoan]: CustomComponentType.loans,
		[DataObject.TypeEnum.Collateral]: CustomComponentType.collateralInfo,
		[DataObject.TypeEnum.BeneficialOwnerDetails]: CustomComponentType.beneficialOwnership,
		// [DataObject.TypeEnum.BusinessFinancial]: [CustomComponentType.businessFinancial,
		[DataObject.TypeEnum.PersonalFinancial]: CustomComponentType.personalFinancialStatement
	};

	static readonly RoleToComponentKeyLookup: Record<string, string> = {
		[LoanRoleType.PrimaryBorrower]: CustomComponentType.primaryBorrowerEntity,
		[LoanRoleType.CoBorrower]: CustomComponentType.coBorrowerEntity,
		[LoanRoleType.AuthorizedSigner]: CustomComponentType.authorizedSigner,
		[LoanRoleType.CoSigner]: CustomComponentType.coSignerEntity,
		[LoanRoleType.CreditApplicant]: CustomComponentType.creditApplicantEntity,
		[LoanRoleType.Trustee]: CustomComponentType.trustee,
		[LoanRoleType.BeneficialOwner]: CustomComponentType.beneficialOwnership,
		[LoanRoleType.Guarantor]: CustomComponentType.guarantorEntity
	};

	protected get factoryContext(): FormObjectFactory.FactoryContext {
		return {
			currentProposedLoanId: this._proposedLoanId,
			dataObjectsByType: this._dataObjectsByType,
			creditReportAuthorizations: this._creditReportAuthorizations,
			beneficialOwners: this._beneficialOwners,
			financialSubaccounts: this._financialSubaccounts,
			presetDataFieldsByDataObject: this._presetDataFieldsByDataObject,
			sbaInfo: this._sbaInfo
		};
	}

	constructor(dataObjectsByType: DataObjectsByType, loanIndex: number = 0) {
		this._dataObjectsByType = dataObjectsByType;
		this._submissionData = { data: {} };
		this._proposedLoanId = null;
		this._creditReportAuthorizations = null;
		this._presetDataFieldsByDataObject = null;
		this._sbaInfo = null;

		this._roleDataObjectJoinCache = new Map<number, ObjectPropertyValues>();
		this._collateralDataObjectJoinCache = new Map<number, ObjectPropertyValues>();
		this._financialSubaccountsJoinCache = new Map<number, ObjectPropertyValues>();
		this.cacheDataObjectsForJoins();
		const proposedLoanDataObjects = dataObjectsByType[DataObject.TypeEnum.ProposedLoan] ?? [];
		if (proposedLoanDataObjects[loanIndex]?.id != null) {
			this.setProposedLoanId(proposedLoanDataObjects[loanIndex].id as number);
		}
	}

	setProposedLoanId(id: number) {
		if (id == null) {
			throw new Error('id cannot be null or undefined');
		}

		this._proposedLoanId = id;

		const loanInfo = this._dataObjectsByType[DataObject.TypeEnum.ProposedLoan]?.find(loan => loan.id === id);
		if (loanInfo == null) {
			throw new Error(`proposedLoan not found for specified id:${id}`);
		}

		this._submissionData.data[CustomComponentType.loans] = FormObjectFactory.createFormObject(CustomComponentType.loans, loanInfo, this.factoryContext);

		return this;
	}

	setCreditReportAuthorizations(creditReportAuthorizations: LoanApplicationCreditAuthorization[] | null) {
		this._creditReportAuthorizations = creditReportAuthorizations;

		return this;
	}

	setBeneficialOwners(beneficialOwners: BeneficialOwnerWithCustomerType[]) {
		this._beneficialOwners = beneficialOwners;

		return this;
	}

	setFinancialSubaccounts(financialSubaccounts: FinancialSubaccount[]) {
		this._financialSubaccounts = financialSubaccounts;

		return this;
	}

	setPresetFields(presetDataFields: FormObjectFactory.PresetDataFieldsByDataObject) {
		this._presetDataFieldsByDataObject = presetDataFields;

		return this;
	}

	setSbaInfo(sbaInfo: ProposedLoanSBAInfo) {
		this._sbaInfo = sbaInfo;

		return this;
	}

	getSubmissionData(): any {
		return this._submissionData;
	}

	// eslint-disable-next-line max-lines-per-function
	addPrimaryBorrower(mappings: LoanRoleWithCustomerType[], relatedMappings: RelatedRoleWithCustomerType[] | null) {
		if (this._submissionData.data[CustomComponentType.primaryBorrowerEntity] == null) {
			this._submissionData.data[CustomComponentType.primaryBorrowerEntity] = [];
		}

		if (this._proposedLoanId == null) {
			return;
		}

		let infos: { [key: string]: FormObjectFactory.Info } = {};
		let addedMappings = false;
		let propertyName = '';
		let parentRoleMapping: LoanRoleWithCustomerType | null = null;
		this.filterMappingsByRoleType(mappings, LoanRoleType.PrimaryBorrower).forEach(m => {
			const { propName, dataObject } = this.getRoleDataObjectAndPropNameByMapping(m);
			if (dataObject != null) {
				propertyName = propName;

				infos[propName] = FormObjectFactory.createFormObject(propName, dataObject, this.factoryContext, m.id);
				addedMappings = true;
				parentRoleMapping = m;
			}
		});

		if (addedMappings) {
			this._submissionData.data[CustomComponentType.primaryBorrowerEntity].push({ form: { data: infos, state: this._state } });
			const index = this._submissionData.data[CustomComponentType.primaryBorrowerEntity].length - 1;
			const primaryBorrowerSubmissionData = this._submissionData.data[CustomComponentType.primaryBorrowerEntity][index].form.data[propertyName].data;

			if (parentRoleMapping == null) {
				return;
			}

			if (relatedMappings != null) {
				this.addRelatedRoles(parentRoleMapping, relatedMappings, primaryBorrowerSubmissionData);
			}

			this.addBeneficialOwners(parentRoleMapping, primaryBorrowerSubmissionData);

			if (propertyName === CustomComponentType.personalInfo) {
				this.addFinancialSubaccounts(parentRoleMapping, primaryBorrowerSubmissionData, CustomComponentType.personalFinancialStatement);
			}
			// else if (propertyName === CustomComponentType.businessInfo) {
			// 	this.addFinancialSubaccounts(parentRoleMapping, primaryBorrowerSubmissionData, CustomComponentType.businessFinancialStatement);
			// }
			this._submissionData.data[CustomComponentType.primaryBorrowerEntityVue] = this._submissionData.data[CustomComponentType.primaryBorrowerEntity];
		}

		return this;
	}

	private getRoleDataObjectAndPropNameByMapping(
		mapping: LoanRoleWithCustomerType | RelatedRoleWithCustomerType
	): { propName: string; dataObject: ObjectPropertyValues | undefined } {
		const { customerType, customerId } = mapping;

		return this.getRoleDataObjectAndPropName(customerType, customerId);
	}

	private getRoleDataObjectAndPropName(
		customerType?: Customer.TypeEnum | null,
		customerId?: number
	): { propName: string; dataObject: ObjectPropertyValues | undefined } {
		if (customerType == null) {
			throw new Error(`customerType cannot be null or undefined`);
		}

		const dataObjectType = DataObjectUtils.customerTypeToDataObjectType(customerType);
		if (!dataObjectType) {
			throw new Error(`Could not find related data object type for customer type [type: ${customerType}]`);
		}

		const propName = SubmissionDataMapper.DataObjectTypeToComponentKeyLookup[dataObjectType];
		if (propName == null) {
			throw new Error(`propName cannot be found for mapping.customerType=${customerType}`);
		}

		if (customerId == null) {
			throw new Error(`customerId cannot be null or undefined`);
		}
		const dataObject = this._roleDataObjectJoinCache.get(customerId);

		return { propName, dataObject };
	}

	private getFinancialSubaccountDataObjectAndPropName(
		financialSubaccountType?: string | null,
		financialSubaccountId?: number,
		financialSubaccountParent?: string,
		financialSubaccountName?: string
	): { propName: string; dataObject: ObjectPropertyValues | undefined } {
		if (financialSubaccountType == null) {
			throw new Error(`financialSubaccountType cannot be null or undefined`);
		}

		if (financialSubaccountParent == null) {
			throw new Error('financialSubaccountParent cannot be null or undefined');
		}

		const propName = getSubaccountComponentTypeByFinancialStatementTypeString(financialSubaccountType, financialSubaccountParent, financialSubaccountName);
		if (propName == null) {
			throw new Error(`propName cannot be found for mapping.financialSubaccountType=${financialSubaccountType}`);
		}

		if (financialSubaccountId == null) {
			throw new Error(`financialSubaccountId cannot be null or undefined`);
		}
		const dataObject = this._financialSubaccountsJoinCache.get(financialSubaccountId);

		return { propName, dataObject };
	}

	// eslint-disable-next-line max-lines-per-function
	addRepeatingRole(mappings: LoanRoleWithCustomerType[], roleType: LoanRoleType, relatedMappings: RelatedRoleWithCustomerType[] | null) {
		const componentKey = SubmissionDataMapper.RoleToComponentKeyLookup[roleType];
		if (componentKey == null) {
			return;
		}

		if (this._submissionData.data[componentKey] == null) {
			this._submissionData.data[componentKey] = [];
		}

		if (this._proposedLoanId == null) {
			return;
		}

		const filteredMappings = this.filterMappingsByRoleType(mappings, roleType);
		filteredMappings.forEach(roleMapping => {
			const { propName, dataObject } = this.getRoleDataObjectAndPropNameByMapping(roleMapping);
			if (dataObject != null) {
				this._submissionData.data[componentKey].push({
					form: FormObjectFactory.createInfoWidgetFormObject(propName, dataObject, this.factoryContext, roleMapping.id)
				});

				const repeatingRoleSubmissionData = this._submissionData.data[componentKey][this._submissionData.data[componentKey].length - 1].form.data[
					propName
				].data;

				if (relatedMappings != null) {
					this.addRelatedRoles(roleMapping, relatedMappings, repeatingRoleSubmissionData);
				}

				this.addBeneficialOwners(roleMapping, repeatingRoleSubmissionData);

				if (propName === CustomComponentType.personalInfo) {
					this.addFinancialSubaccounts(roleMapping, repeatingRoleSubmissionData, CustomComponentType.personalFinancialStatement);
				}
				// else if (propertyName === CustomComponentType.businessInfo) {
				// 	this.addFinancialSubaccounts(parentRoleMapping, primaryBorrowerSubmissionData, CustomComponentType.businessFinancialStatement);
				// }
			}
		});

		const componentKeys = [
			CustomComponentType.coBorrowerEntity,
			CustomComponentType.coSignerEntity,
			CustomComponentType.creditApplicantEntity,
			CustomComponentType.guarantorEntity
		];

		componentKeys.forEach(key => {
			this._submissionData.data[`${key}-vue`] = this._submissionData.data[key];
		});

		return this;
	}

	private addRelatedRoles(roleMapping: LoanRoleWithCustomerType, relatedMappings: RelatedRoleWithCustomerType[], parentRoleSubmissionData: any): void {
		if (parentRoleSubmissionData == null) {
			parentRoleSubmissionData = [];
		}

		relatedMappings
			.filter(r => r.customerType != null && r.roleType !== CustomComponentType.beneficialOwnership) // DYA-1297
			.forEach(relatedMapping => {
				if (relatedMapping.parentLoanRoleId === roleMapping.id) {
					const { propName, dataObject } = this.getRoleDataObjectAndPropNameByMapping(relatedMapping);
					if (dataObject != null) {
						const roleType = SubmissionDataMapper.RoleToComponentKeyLookup[relatedMapping.roleType as string];

						if (parentRoleSubmissionData[roleType] == null) {
							parentRoleSubmissionData[roleType] = [];
						}

						parentRoleSubmissionData[roleType].push({
							form: FormObjectFactory.createRelatedRoleInfoFormObject(
								roleType as CustomComponentType,
								relatedMapping,
								roleMapping,
								propName,
								dataObject,
								this.factoryContext
							)
						});
					}
				}
			});
	}

	private addBeneficialOwners(roleMapping: LoanRoleWithCustomerType, parentRoleSubmissionData: any) {
		this._beneficialOwners
			.filter(x => x.customerId === roleMapping.customerId && x.ownerCustomerType != null)
			.forEach(x => {
				const { propName, dataObject } = this.getRoleDataObjectAndPropName(x.ownerCustomerType, x.ownerCustomerId);

				if (dataObject != null) {
					if (parentRoleSubmissionData[CustomComponentType.beneficialOwnership] == null) {
						parentRoleSubmissionData[CustomComponentType.beneficialOwnership] = [];
					}

					parentRoleSubmissionData[CustomComponentType.beneficialOwnership].push({
						form: FormObjectFactory.createBeneficialOwnershipFormObject(
							x.customerId,
							x.ownerCustomerId,
							propName,
							dataObject,
							this.factoryContext,
							roleMapping.id
						)
					});
				}
			});
	}

	protected addFinancialSubaccounts(roleMapping: LoanRoleWithCustomerType, roleSubmissionData: any, financialStatementType: CustomComponentType): void {
		if (this._financialSubaccounts.length === 0) {
			return;
		}

		roleSubmissionData[financialStatementType] =
			roleSubmissionData[financialStatementType] ?? FormObjectFactory.createPfsFormObject(this.factoryContext, roleMapping.customerId);

		this._financialSubaccounts
			.filter(subaccount => subaccount.customerId === roleMapping.customerId)
			.forEach(subaccount => {
				const { propName, dataObject } = this.getFinancialSubaccountDataObjectAndPropName(
					subaccount.financialStatement,
					subaccount.id,
					subaccount.parent,
					subaccount.name
				);

				if (dataObject != null) {
					roleSubmissionData[financialStatementType].data[propName] = roleSubmissionData[financialStatementType].data[propName] ?? [];

					roleSubmissionData[financialStatementType].data[propName].push({
						form: FormObjectFactory.createFormObject(propName as CustomComponentType, dataObject, this.factoryContext)
					});
				}
			});
	}

	addLiens(liens: LoanApplicationProposedLien[]): void {
		this._submissionData.data[CustomComponentType.collateralInfo] = [];
		if (this._proposedLoanId == null) {
			return;
		}

		const filteredLiens = liens.filter(lien => lien.proposedLoanId === this._proposedLoanId);
		const collateralWithLien: ObjectPropertyValues[] = [];

		filteredLiens?.forEach(lien => {
			if (lien.collateralId == null) {
				throw new Error(`lien.collateralId cannot be null or undefined lien.id=${lien.id}`);
			}

			if (this._collateralDataObjectJoinCache.has(lien.collateralId)) {
				collateralWithLien.push(this._collateralDataObjectJoinCache.get(lien.collateralId) as ObjectPropertyValues);
			}
		});
		this._submissionData.data[CustomComponentType.collateralInfo] = FormObjectFactory.createRepeaterInfo(collateralWithLien);
	}

	private filterMappingsByRoleType(mappings: LoanRoleWithCustomerType[], roleType: LoanRoleType): LoanRoleWithCustomerType[] {
		return mappings.filter(f => {
			// filter out missing customerType, profitEntity was likely deleted : DYA-1297
			return f.customerType != null && f.proposedLoanId === this._proposedLoanId && f.roleType === roleType;
		});
	}

	private cacheDataObjectsForJoins(): void {
		this._roleDataObjectJoinCache = DataObjectUtils.createDictionaryForRoles(this._dataObjectsByType);
		this._collateralDataObjectJoinCache = DataObjectUtils.createDictionaryForCollateral(this._dataObjectsByType[DataObject.TypeEnum.Collateral] ?? []);
		this._financialSubaccountsJoinCache = DataObjectUtils.createDictionaryForFinancialSubaccounts(
			this._dataObjectsByType[DataObject.TypeEnum.FinancialSubaccounts] ?? []
		);
	}
}
