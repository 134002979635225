module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<ul class="list-inline mb-0" id="' +
((__t = ( ctx.wizardKey )) == null ? '' : __t) +
'-nav" role="form">\r\n	';
 if (!ctx.isRepeaterWidget && ctx.instance.isFirstPage() && ctx.parentPreviousTitle) { ;
__p += '\r\n	<li class="list-inline-item mr-2">\r\n		<button class="btn btn-outline-primary btn-wizard-nav-next" ref="' +
((__t = ( ctx.wizardKey )) == null ? '' : __t) +
'-topPrevious">\r\n			Back\r\n			<span class="d-none d-sm-inline-block">to ' +
((__t = ( ctx.t(ctx.parentPreviousTitle) )) == null ? '' : __t) +
'</span>\r\n		</button>\r\n	</li>\r\n	';
 } ;
__p += ' ';
 if (ctx.buttons.previous) { ;
__p += '\r\n	<li class="list-inline-item mr-2">\r\n		<button class="btn btn-outline-primary btn-wizard-nav-previous" ref="' +
((__t = ( ctx.wizardKey )) == null ? '' : __t) +
'-previous">\r\n			' +
((__t = ( ctx.t('previous') )) == null ? '' : __t) +
'\r\n		</button>\r\n	</li>\r\n	';
 } ;
__p += ' ';
 if (ctx.buttons.next) { ;
__p += '\r\n	<li class="list-inline-item">\r\n		<button class="btn btn-primary btn-wizard-nav-next" ref="' +
((__t = ( ctx.wizardKey )) == null ? '' : __t) +
'-next">\r\n			' +
((__t = ( ctx.t('next') )) == null ? '' : __t) +
'\r\n		</button>\r\n	</li>\r\n	';
 } ;
__p += ' ';
 if (ctx.isRepeaterWidget && !ctx.buttons.next) { ;
__p += '\r\n	<li class="list-inline-item">\r\n		<button class="btn btn-primary" ref="' +
((__t = ( ctx.wizardKey )) == null ? '' : __t) +
'-saveEditRow">\r\n			Save and Return\r\n		</button>\r\n	</li>\r\n	';
 } ;
__p += ' ';
 if (!ctx.isRepeaterWidget && ctx.instance.isLastPage() && ctx.parentNextTitle) { ;
__p += '\r\n	<li class="list-inline-item">\r\n		<button class="btn btn-primary btn-wizard-nav-next" ref="' +
((__t = ( ctx.wizardKey )) == null ? '' : __t) +
'-topNext">\r\n			Save and Continue\r\n		</button>\r\n	</li>\r\n	';
 } ;
__p += '\r\n</ul>\r\n';
return __p
}