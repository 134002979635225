import { ApplicationDocumentRequest } from '@sageworks/jpi/';
import { IDocumentRequest } from '@/models';
import { DocumentRequestTypes } from '@/models';

export class OnlinePortalApplicationDocumentRequest implements IDocumentRequest {
	constructor(public request: ApplicationDocumentRequest) {}

	get id(): number | undefined {
		return this.request ? this.request.id : undefined;
	}
	set id(value: number | undefined) {
		if (this.request) {
			this.request.id = value;
		}
	}
	get customerId(): number | undefined {
		return this.request ? this.request.customerId : undefined;
	}
	set customerId(value: number | undefined) {
		if (this.request && value) {
			this.request.customerId = value;
		}
	}

	get proposedBankLoansId(): number | undefined | null {
		return this.request ? this.request.proposedBankLoansId : undefined;
	}
	set proposedBankLoansId(value: number | undefined | null) {
		if (this.request && value) {
			this.request.proposedBankLoansId = value;
		}
	}

	get applicationDocumentRequestSettingsId(): number | undefined {
		return this.request?.applicationDocumentRequestSettingsId ?? undefined;
	}
	set applicationDocumentRequestSettingsId(value: number | undefined) {
		if (this.request && value) {
			this.request.customerId = value;
		}
	}
	get documentName(): string | undefined {
		return this.request ? this.request.documentName : undefined;
	}
	set documentName(value: string | undefined) {
		if (this.request) {
			this.request.documentName = value;
		}
	}
	get documentFolderId(): number | undefined {
		return this.request?.documentFolderId ?? undefined;
	}
	set documentFolderId(value: number | undefined) {
		if (this.request) {
			this.request.documentFolderId = value;
		}
	}
	get dateRequested(): string | undefined {
		return this.request ? (this.request.dateRequested as any) : undefined;
	}
	set dateRequested(value: string | undefined) {
		if (this.request) {
			(this.request.dateRequested as any) = value;
		}
	}
	get dateUploaded(): string | undefined {
		return this.request ? (this.request.dateUploaded as any) : undefined;
	}
	set dateUploaded(value: string | undefined) {
		if (this.request) {
			(this.request.dateUploaded as any) = value;
		}
	}
	get documentId(): number | undefined {
		return this.request?.documentId ?? undefined;
	}
	set documentId(value: number | undefined) {
		if (this.request) {
			this.request.documentId = value;
		}
	}
	get name(): string | undefined {
		return this.request ? this.request.name : undefined;
	}
	set name(value: string | undefined) {
		if (this.request) {
			this.request.name = value;
		}
	}
	get dateWaived(): string | undefined {
		return this.request?.dateWaived ?? undefined;
	}
	set dateWaived(value: string | undefined) {
		if (this.request) {
			this.request.dateWaived = value;
		}
	}
	get waivedReason(): string | undefined {
		return this.request ? this.request.waivedReason : undefined;
	}
	set waivedReason(value: string | undefined) {
		if (this.request) {
			this.request.waivedReason = value;
		}
	}
	get waivedByLoanOfficerId(): number | undefined {
		return this.request?.waivedByLoanOfficerId ?? undefined;
	}
	set waivedByLoanOfficerId(value: number | undefined) {
		if (this.request) {
			this.request.waivedByLoanOfficerId = value;
		}
	}
	get documentNamesId(): number | undefined {
		return this.request?.documentNamesId ?? undefined;
	}
	set documentNamesId(value: number | undefined) {
		if (this.request && value) {
			this.request.documentNamesId = value;
		}
	}
	get templateDocumentsId(): number | null | undefined {
		return this.request ? this.request.templateDocumentsId : undefined;
	}
	get templateName(): string | null | undefined {
		return this.request ? this.request.templateName : undefined;
	}
	get position(): number {
		return this.request?.position || -1;
	}
	get documentRequestType(): number {
		return DocumentRequestTypes.LoanApplicationDocumentRequest;
	}
	get uploadedDocumentFilename(): string {
		return this.request?.uploadedDocumentFilename ?? '';
	}
	set uploadedDocumentFilename(filename: string) {
		if (this.request) {
			this.request.uploadedDocumentFilename = filename;
		}
	}
	get notUploadedReason(): string | undefined {
		return this.request?.notUploadedReason ?? '';
	}
	set notUploadedReason(notUploadedReason: string | undefined) {
		if (this.request) {
			this.request.notUploadedReason = notUploadedReason;
		}
	}
}
