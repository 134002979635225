import SelectComponent from 'formiojs/components/select/Select';

export class PersonalFinancialTypeSelect extends SelectComponent {
	static schema(...extend: any) {
		return SelectComponent.schema(
			{
				dataSrc: 'values',
				template: '<span>{{ item.label }}</span>',
				lazyLoad: false,
				searchEnabled: false,
				forceRequired: true
			},
			...extend
		);
	}

	attach(element: HTMLElement) {
		if (element.id != null && document.getElementById(element.id) != null) {
			element = document.getElementById(element.id)!;
		}
		return super.attach(element);
	}

	setItems(items: any, fromSearch: boolean): void {
		let massagedItems = items;
		if (items && Array.isArray(items)) {
			massagedItems = items.filter(option => option.enabledForConsumers);
		}
		super.setItems(massagedItems, fromSearch);
	}

	addOption(value: any, label: any, attrs = {}) {
		if (this.component.dataType === 'string') {
			value = this.normalizeSingleValue(value);
		}
		super.addOption(value, label, attrs);
	}
}
