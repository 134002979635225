import baseEditForm from 'formiojs/components/columns/Columns.form';
import BaseEditConditionalComponents from '../../../configuration-components/base-widget-configuration/base.edit.conditional.components';
import LenderOnly from '../../../configuration-components/base-widget-configuration/display/lender-only-field-checkbox';
import HideInPdf from '../../../configuration-components/base-widget-configuration/display/hide-in-pdf-checkbox';
import ModifiedColumnsEditForm from './editForm/modified-columns-component.edit.display';

// eslint-disable-next-line max-lines-per-function
export default function(...extend: any[]) {
	return baseEditForm(
		[
			{
				key: 'display',
				components: [HideInPdf, LenderOnly, ModifiedColumnsEditForm]
			},
			{
				key: 'api',
				ignore: true
			},
			{
				key: 'conditional',
				components: BaseEditConditionalComponents
			}
		],
		...extend
	);
}
