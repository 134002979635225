import { DataHandlerFactory } from './data-handler-factory';
import { CustomOnSubmitHookName, CustomOnDeleteHookName } from '../../enums';
import { SubmitHookStrategy, DeleteHookStrategy, CrudHookConfig, AdvancedCrudHookConfig } from './crud-hook-interfaces';

export class CrudHookFactory {
	constructor(private dataHandlerFactory: DataHandlerFactory) {}

	createHooks(hookConfigs: CrudHookConfig[], submitHookStrategy: SubmitHookStrategy, deleteHookStrategy: DeleteHookStrategy) {
		const hooks: { [hookName: string]: Function } = {};

		hookConfigs.forEach(x => {
			const { componentType, postDeleteHook, postSubmitHook, preDeleteHook, preSubmitHook } = this.createAdvancedCrudConfig(x);
			const handler = this.dataHandlerFactory.createHandler(componentType);

			// Create CRUD hooks for component type
			hooks[CustomOnSubmitHookName(componentType)] = submitHookStrategy.createHook(handler, preSubmitHook, postSubmitHook);
			hooks[CustomOnDeleteHookName(componentType)] = deleteHookStrategy.createHook(handler, preDeleteHook, postDeleteHook);
		});

		return hooks;
	}

	private createAdvancedCrudConfig(config: CrudHookConfig): AdvancedCrudHookConfig {
		return typeof config === 'string' ? { componentType: config } : config;
	}
}
