import NumberComponent from 'formiojs/components/number/Number';
import Input from 'formiojs/components/_classes/input/Input';
import editForm from './extended-percent.form';
import { CustomComponentType } from '../../../../enums';
import {
	getKey,
	checkConditionalityAcrossForms,
	attachChangeEventHandler,
	handleSetDefaultValue,
	shouldInputFieldUseOriginalConditionality
} from '../extended-field-helper';
import { shouldUpdateOriginalLabel, updateOriginalLabel } from '../../../../utils/data-field-metadata/data-field-metadata-utils';
import Decimal from 'decimal.js';
import { FormioConditionalityUtils } from '../../../../utils/formio-conditionality-utils';

export default class ExtendedPercent extends NumberComponent {
	static schema(...extend: any) {
		return NumberComponent.schema(
			{
				type: CustomComponentType.extendedPercent,
				forceRequired: false,
				dataFieldId: null,
				suffix: '%'
			},
			...extend
		);
	}

	static get builderInfo() {
		return {
			title: 'Percent',
			group: '',
			weight: 10,
			schema: ExtendedPercent.schema({})
		};
	}

	static editForm = editForm;

	// @ts-ignore
	set visible(value: boolean) {
		var isChanged = this.visible !== value;

		super.visible = value;

		if (isChanged) {
			this.setValue(this.dataValue);
		}
	}

	get visible() {
		return super.visible;
	}

	// @ts-ignore
	get key(): string {
		return getKey(this, super.key);
	}

	async attach(element: HTMLElement) {
		const superAttach = super.attach(element);

		if (this.component?.dataFieldId == null || (typeof this.component.dataFieldId === 'string' && this.component.dataFieldId.trim() === '')) {
			return;
		}

		let event = `${this.component.dataFieldId}.${this.inputInfo.changeEvent}`;

		this.refs.input.forEach(htmlInputElement => {
			this.addEventListener(htmlInputElement, this.inputInfo.changeEvent, (e: Event) => {
				const x = (e?.target as HTMLInputElement)?.value;

				this.emit(event, { value: this.parseNumber(x), id: this.id, dataId: this.root.data.id });
			});
		});

		attachChangeEventHandler(this, event);

		if (shouldUpdateOriginalLabel(this)) {
			await updateOriginalLabel(this);
		}

		return superAttach;
	}

	conditionallyVisible(data: any): boolean {
		if (shouldInputFieldUseOriginalConditionality(this)) {
			return super.conditionallyVisible(data);
		}

		return checkConditionalityAcrossForms(this);
	}

	checkCondition(row: any, data: any) {
		return FormioConditionalityUtils.checkCondition(
			this.component,
			row || this.data,
			data || this.rootValue,
			this.root ? (this.root as any)._form : {},
			this
		);
	}

	parseNumber(value: string) {
		// Remove delimiters and convert decimal separator to dot.
		value = value
			.split(this.delimiter)
			.join('')
			.replace(this.decimalSeparator, '.');

		let parsedValue: number;
		try {
			parsedValue = new Decimal(value).div(100).toNumber();
		} catch (err) {
			parsedValue = 0;
		}

		return parsedValue;
	}

	parseValue(input: any) {
		if (typeof input === 'string') {
			input = input
				.split(this.delimiter)
				.join('')
				.replace(this.decimalSeparator, '.');
		}
		let value: string;
		try {
			value = new Decimal(input)
				.mul(100)
				.toString()
				.replace('.', this.decimalSeparator);
		} catch (err) {
			value = '';
		}
		return value;
	}

	/**
	 * Set the value at a specific index.
	 *
	 * @param index
	 * @param value
	 */
	setValueAt(index: number, value: number, flags: any = {}) {
		const result = super.setValueAt(index, value, flags);
		handleSetDefaultValue(this, index, value, flags);

		return result;
	}

	addFocusBlurEvents(element: any) {
		Input.prototype.addFocusBlurEvents.bind(this)(element);
		this.addEventListener(element, 'blur', () => {
			element.value = this.getValueAsString(this.formatValue(super.parseValue(element.value)));
		});
	}
}
