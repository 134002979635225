export enum ComponentState {
	INIT = 'INIT',
	LOADING = 'LOADING', 
	LOADED = 'LOADED',
	EXPORTING = 'EXPORTING',
	ERROR = 'ERROR',
	DATA_SAVING = 'DATA_SAVING',
	DATA_SAVED = 'DATA_SAVED',
	DATA_SAVING_ERROR = 'DATA_SAVING_ERROR'
}

export const DATA_PROCESSING_COMPONENT_STATES = [ComponentState.LOADING, ComponentState.EXPORTING, ComponentState.DATA_SAVING];