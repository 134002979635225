
import { BeneficialOwnerUtils, CustomComponentType } from '@sageworks/dynamic-forms';
import { Customer } from '@sageworks/jpi';
import BaseTemplateMixin from './base-template-mixin.vue';

interface RelatedRoleDisplayValue {
	labelName: string;
	value: string;
}

interface FormatedRelatedRole {
	id: any;
	values: RelatedRoleDisplayValue[];
}

export default BaseTemplateMixin.extend({
	name: 'RelatedRoleRepeater',
	components: {},
	computed: {
		basePath(): string {
			return `${this.exportMetadata.ComponentPath}.${this.exportMetadata.ComponentTree.id}`;
		},
		relatedRoleValues(): any {
			return this.configurationLookup?.value ?? [];
		},
		formattedData(): FormatedRelatedRole[] {
			const relatedRoles: any[] = this.relatedRoleValues;
			const componentType = this.exportMetadata.ComponentTree.type as CustomComponentType;

			return relatedRoles.map<FormatedRelatedRole>(x => ({ id: x.id, values: this.getDisplayFields(x, componentType) }));
		},
	},
	methods: {
		getDisplayFields(roleData: any, componentType: CustomComponentType): RelatedRoleDisplayValue[] {
			switch (componentType) {
				case CustomComponentType.beneficialOwnership:
				case CustomComponentType.beneficialOwnershipVue:
					return this.buildBeneficialOwnerDisplay(roleData);
				case CustomComponentType.authorizedSigner:
					return this.buildAuthorizedSignerDisplay(roleData);
				default:
					return [];
			}
		},
		buildBeneficialOwnerDisplay(roleData: any): RelatedRoleDisplayValue[] {
			return [
				{
					labelName: 'Beneficial Owner',
					value: roleData.ownerName
				},
				{
					labelName: 'Ownership Role',
					value: BeneficialOwnerUtils.BeneficialOwnerRoleLabel(roleData.roleType)
				},
				{
					labelName: 'Percent Ownership',
					value: `${roleData.ownershipPercent * 100} %`
				},
				{
					labelName: 'Date Updated',
					value: roleData.lastUpdated
				}
			];
		},
		buildAuthorizedSignerDisplay(roleData: any): RelatedRoleDisplayValue[] {
			switch (roleData.customerType as Customer.TypeEnum) {
				case Customer.TypeEnum.Business:
					return this.getFieldsForBusiness(roleData);
				case Customer.TypeEnum.Person:
					return this.getFieldsForPerson(roleData);
				default:
					throw Error(`Unsupported customer type ${roleData.customerType}`);
			}
		},
		getFieldsForPerson(roleData: any): RelatedRoleDisplayValue[] {
			return [
				{
					labelName: 'Name',
					value: roleData.name
				},
				{
					labelName: 'Email',
					value: roleData.email
				}
			];
		},
		getFieldsForBusiness(roleData: any): RelatedRoleDisplayValue[] {
			return [
				{
					labelName: 'Business Name',
					value: roleData.name
				},
				{
					labelName: 'Industry Code',
					value: roleData.industryCode
				},
				{
					labelName: 'State',
					value: roleData.state
				}
			];
		}
	}
});
