import { DataObject, TemplateDataField } from '@sageworks/dynamic-forms';
import { DataObject as DataObjectModel } from '@sageworks/jpi';
import { keyBy } from 'lodash';
import { StoreType } from '../../';
import { MultiLoanApplicationFormModuleState } from '../state';

export const locatePfsDataObject = (store: StoreType, customerId: number): DataObjectModel | undefined => {
	const datafields = (store.state.LoanApplicationMetadata.presetDataFieldsByDataObject ?? {})[DataObject.TypeEnum.PersonalFinancial];
	const datafieldsByTemplateId = keyBy(datafields, 'templateDataFieldId');
	const customerDataFieldId = datafieldsByTemplateId[TemplateDataField.PfsCustomer]?.id;

	const { renderData = {} } = store.state.MultiLoanApplicationForm as MultiLoanApplicationFormModuleState;
	const dataObjectsByType = renderData?.formData?.dataObjects ?? {};
	const personalFinancialDataObjects = dataObjectsByType[DataObject.TypeEnum.PersonalFinancial] ?? [];

	// Locate financial statement by the customer id
	return personalFinancialDataObjects.find(x => {
		const dataFieldValue = (x.values ?? []).find(y => y.id === customerDataFieldId);
		return (dataFieldValue?.value as any) === customerId;
	});
};

export const locatePfsMapping = (store: StoreType, customerId: number) => {
	const pfsDataObject = locatePfsDataObject(store, customerId);

	if (pfsDataObject == null) {
		return undefined;
	}

	const { renderData = {} } = store.state.MultiLoanApplicationForm as MultiLoanApplicationFormModuleState;

	return (renderData?.formData?.personalFinancialMappings ?? [])
		.find(x => x.personalInputDataID === pfsDataObject.rowId);
};
