import { CashAccountCustomFieldKey, CustomComponentType } from '../../../../../enums';
import { PersonalFinancialTypeSelect } from '../personal-financial-type-select/personal-financial-type-select';
import { createSubaccountDefaultEditConfiguration } from '../configuration/default-edit-configuration';

export class PersonalCashAccountTypeSelect extends PersonalFinancialTypeSelect {
	static schema(...extend: any) {
		return PersonalFinancialTypeSelect.schema(
			{
				label: 'Account Type',
				type: CustomComponentType.personalCashAccountTypeSelect,
				key: CashAccountCustomFieldKey.AccountType,
			},
			...extend
		);
	}

	static editForm = createSubaccountDefaultEditConfiguration(CustomComponentType.personalCashAccountTypeSelectOptions);

	static get builderInfo() {
		return {
			title: 'Account Type',
			weight: 10,
			schema: PersonalCashAccountTypeSelect.schema()
		};
	}
}
