import _ from 'lodash';
import { CustomComponentType, FormioComponentType, CreditPullCountryOption, ContextDataProperty } from '../../../../enums';
import { LoanApplicationCreditAuthorization, CreditReportInfo } from '@sageworks/jpi';
import { generateFormJson, CustomPullCreditFieldKey, pullCreditReport, PullCreditReportError } from '../helpers';
import NestedDataComponent from 'formiojs/components/_classes/nesteddata/NestedDataComponent';
import { getContextDataValue } from '../../../../utils/context-data-helper/context-data-helper';
import { DocLibraryHelper } from '../../../../utils';

enum CreditPullRequestStatus {
	NotPulled,
	Pulling,
	Complete
}

export default class CreditReportPullCreditInput extends NestedDataComponent {
	public static readonly BORROWER_ERROR_MESSAGE = 'There was an issue pulling your credit - please contact your institution for assistance.';
	public static readonly BORROWER_SUCCESS_MESSAGE = 'Your credit report was pulled successfully.';
	public static readonly LENDER_SUCCESS_MESSAGE = 'The credit report was pulled successfully.';

	public get isLoading() {
		return this.creditPullRequestStatus === CreditPullRequestStatus.Pulling;
	}

	private get userInfo() {
		return getContextDataValue(this, ContextDataProperty.CurrentUserInfo);
	}

	private get isLender() {
		return getContextDataValue(this, ContextDataProperty.IsLender);
	}

	private get customerId() {
		return this.component.customerId;
	}

	private get formKey() {
		return 'form';
	}

	private get loanApplicationId() {
		return getContextDataValue(this, ContextDataProperty.LoanApplicationId);
	}

	private get loadingTemplate() {
		return `
			<div class="modal-body d-flex flex-column align-items-center">
				<div class="h-3rem w-3rem spinner-border text-primary" role="status">
					<span class="sr-only">Loading...</span>
				</div>
				<div class="py-3 d-flex flex-column align-items-center">
					<div>Your credit report is processing...</div>
					<div>NOTE: This generally takes around 20 seconds.</div>
				</div>
			</div>
		`;
	}

	public static schema(...extend: any) {
		return NestedDataComponent.schema(
			{
				label: 'Credit Authorization',
				type: CustomComponentType.creditReportPullCreditInput,
				key: CustomComponentType.creditReportPullCreditInput,
				input: false,
				customerId: -1,
				dataFields: [],
				authorizationMetadataId: null,
				defaultCredentialsId: null
			},
			...extend
		);
	}

	public authorizationMetadata!: LoanApplicationCreditAuthorization | null;

	private creditPullRequestStatus!: CreditPullRequestStatus;

	private formData!: any;

	private errorMessages: string[] = [];

	public init() {
		this.component.components = [];

		this.authorizationMetadata = null;

		this.formData = {};

		this.creditPullRequestStatus = CreditPullRequestStatus.NotPulled;

		super.init();

		this.noField = true;

		this.setupFormData();

		this.addComponent({ type: FormioComponentType.Form, key: this.formKey, formJson: generateFormJson(this.component.dataFields) }, this.formData);
	}
	private get templateName() {
		return 'dialogCreditReportPullCredit';
	}

	// @ts-ignore
	public get emptyValue(): any {
		return { [this.formKey]: { data: {} } };
	}

	private get creditReportFormData() {
		return this.formData[this.formKey].data;
	}

	public render(): any {
		let template = '';
		switch (this.creditPullRequestStatus) {
			case CreditPullRequestStatus.Pulling:
				template = this.renderString(this.loadingTemplate, { name: this.name });
				break;
			case CreditPullRequestStatus.Complete:
				template = this.renderTemplate('dialogCreditReportPullCreditComplete', {
					name: this.name,
					errorMessages: this.errorMessages,
					isLender: this.isLender,
					borrowerErrorMessage: CreditReportPullCreditInput.BORROWER_ERROR_MESSAGE,
					successMessage: this.isLender ? CreditReportPullCreditInput.LENDER_SUCCESS_MESSAGE : CreditReportPullCreditInput.BORROWER_SUCCESS_MESSAGE
				});
				break;
			default:
				template = this.renderTemplate(this.templateName, {
					name: this.name,
					children: this.renderComponents(),
					nestedKey: this.nestedKey,
					collapsed: this.options.pdf ? false : this.collapsed
				});
				break;
		}

		return super.render(template);
	}

	public async attach(element: any) {
		const superAttachResult = await super.attach(element);

		if (this.creditPullRequestStatus === CreditPullRequestStatus.Complete) {
			this.attachForCompleteTemplate(element);
		} else {
			this.loadRefs(element, {
				dialogPullCreditButton: 'single'
			});

			const { dialogPullCreditButton } = this.refs as any;

			// Setup event handlers
			this.addEventListener(dialogPullCreditButton, 'click', this.onPullCreditButtonClick);
		}

		return superAttachResult;
	}

	private async attachForCompleteTemplate(element: any) {
		this.loadRefs(element, {
			dialogPullCreditCompleteCloseButton: 'single'
		});

		const { dialogPullCreditCompleteCloseButton } = this.refs as any;

		// Setup event handlers
		this.addEventListener(dialogPullCreditCompleteCloseButton, 'click', () => {
			this?.options?.afterPullComplete(this.authorizationMetadata);
		});
	}

	private setupFormData() {
		// Be sure that the data object does not change until the user decides to pull credit
		this.formData = _.cloneDeep(this.dataValue);

		// Defaults
		this.creditReportFormData[CustomPullCreditFieldKey.CreditPullCountry] = CreditPullCountryOption.UnitedStates;
	}

	// eslint-disable-next-line max-lines-per-function
	private async executeCreditPull(
		authorizationMetadataId: number,
		userId: number,
		userType: LoanApplicationCreditAuthorization.PulledByUserTypeEnum,
		creditReportInfo: CreditReportInfo
	) {
		try {
			this.errorMessages = [];
			this.creditPullRequestStatus = CreditPullRequestStatus.Pulling;
			this.redraw();
			const { applicantDocumentId, authorizationMetadata } = await pullCreditReport(
				authorizationMetadataId,
				this.customerId,
				this.loanApplicationId,
				userId,
				userType,
				creditReportInfo
			);
			this.authorizationMetadata = authorizationMetadata;

			if (this.isLender && applicantDocumentId) {
				await DocLibraryHelper.downloadDocFromDocLibrary(applicantDocumentId);
			}
		} catch (err) {
			if (err instanceof PullCreditReportError) {
				this.errorMessages = err.errors;
			} else {
				this.errorMessages = ['Error unknown'];
			}
		} finally {
			this.creditPullRequestStatus = CreditPullRequestStatus.Complete;
			this.redraw();
		}
	}

	/**
	 *  Arrow functions to preserve `this` property
	 */

	private onPullCreditButtonClick = async () => {
		const creditReportInfo: CreditReportInfo = {
			applicantCustomerId: this.customerId,
			ucsCredentialId: this.parent.component.selectedCredentialsId,
			applicantExperianFreezePin: this.creditReportFormData[CustomPullCreditFieldKey.ExperianCreditFreezePin]
		};

		const authorizationMetadataId = this.component.authorizationMetadataId;
		const userId = this.isLender ? this.userInfo?.proxyUserId : this.userInfo?.userId;
		const userType = this.isLender
			? LoanApplicationCreditAuthorization.PulledByUserTypeEnum.Lender
			: LoanApplicationCreditAuthorization.PulledByUserTypeEnum.BankCustomerUser;

		if (!userId) {
			throw new Error("Could not locate current user's ID");
		}

		await this.executeCreditPull(authorizationMetadataId, userId, userType, creditReportInfo);
	};
}
