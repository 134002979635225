
import Vue, { PropType } from 'vue';
import { ToastrUtils } from '@sageworks/dynamic-forms';
import { Modal } from 'bootstrap';

export default Vue.extend({
	name: 'ConfirmLogoutModal',
	components: {},
	props: {
		modalComponent: { type: Object as PropType<Modal | null>, default: null }
	},
	data() {
		return {
			signingOut: false
		};
	},
	computed: {},
	mounted() {},
	methods: {
		async continueSignout() {
			this.signingOut = true;
			try {
				await this.directStore.dispatch.Authentication.logout();
			} catch {
				this.$root.$bvToast.toast('An error occured while attempting to log out! Please try again.', {
					...ToastrUtils.getFailureOptions()
				});
			}

			this.hideModal();
			this.signingOut = false;
		},
		hideModal: function() {
			if (this.$props.modalComponent != null) {
				this.$props.modalComponent.hide();
			}
		}
	}
});
