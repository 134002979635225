import DateTimeComponent from 'formiojs/components/datetime/DateTime';
import editForm from './extended-date-time.form';
import { CustomComponentType } from '../../../../enums';
import {
	getKey,
	attachWithChangeEvent,
	checkConditionalityAcrossForms,
	handleSetDefaultValue,
	shouldInputFieldUseOriginalConditionality
} from '../extended-field-helper';
import { shouldUpdateOriginalLabel, updateOriginalLabel } from '../../../../utils/data-field-metadata/data-field-metadata-utils';
import _ from 'lodash';
import ExtendedCalendarWidget from '../../../../widgets/extended-calendar-widget';
import moment from 'moment';
import { FormioConditionalityUtils } from '../../../../utils/formio-conditionality-utils';
import Component from 'formiojs/components/_classes/component/Component';
import { Utils as FormioUtils } from 'formiojs';

export default class ExtendedDateTime extends DateTimeComponent {
	static schema(...extend: any) {
		return DateTimeComponent.schema(
			{
				type: CustomComponentType.extendedDateTime,
				forceRequired: false,
				dataFieldId: null
			},
			...extend
		);
	}

	static get builderInfo() {
		return {
			title: 'DateTime',
			group: '',
			weight: 10,
			schema: ExtendedDateTime.schema()
		};
	}

	static editForm = editForm;

	constructor(component: any, options: Object, data: Object) {
		super(component, options, data);

		this.component.attributes = this.component.attributes ?? {};
		this.component.attributes['data-input'] = '';
	}

	get widget() {
		const widgetSettings = _.cloneDeep(this.component.widget);

		widgetSettings.static = true;

		const widget = new ExtendedCalendarWidget(widgetSettings, this.component);

		return widget;
	}

	createWidget(index: number) {
		const widget = this.widget;

		widget.on(
			'blur',
			() =>
				this.updateValue(
					widget.getValue(),
					{
						modified: true
					},
					index
				),
			true
		);
		widget.on('redraw', () => this.redraw(), true);
		return widget;
	}

	// @ts-ignore
	set visible(value: boolean) {
		var isChanged = this.visible !== value;

		super.visible = value;

		if (isChanged) {
			this.setValue(this.dataValue);
		}
	}

	get visible() {
		return super.visible;
	}

	// @ts-ignore
	get key(): string {
		return getKey(this, super.key);
	}

	async attach(element: HTMLElement) {
		const superAttach = super.attach(element);

		attachWithChangeEvent(this);

		if (shouldUpdateOriginalLabel(this)) {
			await updateOriginalLabel(this);
		}

		return superAttach;
	}

	conditionallyVisible(data: any): boolean {
		if (shouldInputFieldUseOriginalConditionality(this)) {
			return super.conditionallyVisible(data);
		}

		return checkConditionalityAcrossForms(this);
	}

	checkCondition(row: any, data: any) {
		return FormioConditionalityUtils.checkCondition(
			this.component,
			row || this.data,
			data || this.rootValue,
			this.root ? (this.root as any)._form : {},
			this
		);
	}

	positionCalendar(self: any, customPositionElement: HTMLElement) {
		const { calendarWidth, configPosHorizontal, inputBounds, showOnTop, top } = this._setupPositioningVars(self, customPositionElement);

		this.toggleClass(self.calendarContainer, 'arrowTop', !showOnTop);
		this.toggleClass(self.calendarContainer, 'arrowBottom', showOnTop);

		if (self.config.inline) return;

		let left = window.pageXOffset + inputBounds.left;
		let isCenter = false;
		let isRight = false;

		if (configPosHorizontal === 'center') {
			left -= (calendarWidth - inputBounds.width) / 2;
			isCenter = true;
		} else if (configPosHorizontal === 'right') {
			left -= calendarWidth - inputBounds.width;
			isRight = true;
		}

		this.toggleClass(self.calendarContainer, 'arrowLeft', !isCenter && !isRight);
		this.toggleClass(self.calendarContainer, 'arrowCenter', isCenter);
		this.toggleClass(self.calendarContainer, 'arrowRight', isRight);

		const rightMost = left + calendarWidth > window.document.body.offsetWidth;

		this.toggleClass(self.calendarContainer, 'rightMost', rightMost);

		if (self.config.static) return;

		self.calendarContainer.style.top = `${top}px`;

		this._calendarPositionStyling(self, inputBounds, left, calendarWidth);
	}

	private _setupPositioningVars(self: any, customPositionElement: any) {
		const positionElement = customPositionElement || self._positionElement;
		const calendarHeight = Array.prototype.reduce.call(
			self.calendarContainer.children,
			((acc: number, child: HTMLElement) => acc + child.offsetHeight) as any,
			0
		) as number;
		const calendarWidth = self.calendarContainer.offsetWidth;
		const configPos = self.config.position.split(' ');
		const configPosVertical = configPos[0];
		const configPosHorizontal = configPos.length > 1 ? configPos[1] : null;
		const inputBounds = positionElement.getBoundingClientRect();
		const distanceFromBottom = window.innerHeight - inputBounds.bottom;
		const showOnTop =
			configPosVertical === 'above' || (configPosVertical !== 'below' && distanceFromBottom < calendarHeight && inputBounds.top > calendarHeight);

		const applicationContentRoot = document.body.querySelector(this.component.widget.frameRoot);
		const scrollHeight = applicationContentRoot != null ? applicationContentRoot.scrollTop : window.pageYOffset;
		const top = scrollHeight + inputBounds.top + (!showOnTop ? positionElement.offsetHeight + 2 : -calendarHeight - 2);

		return {
			calendarWidth,
			configPosHorizontal,
			inputBounds,
			showOnTop,
			top
		};
	}

	private _calendarPositionStyling(self: any, inputBounds: DOMRect, left: number, calendarWidth: number) {
		const right = window.document.body.offsetWidth - (window.pageXOffset + inputBounds.right);
		const rightMost = left + calendarWidth > window.document.body.offsetWidth;
		const centerMost = right + calendarWidth > window.document.body.offsetWidth;

		if (!rightMost) {
			self.calendarContainer.style.left = `${left}px`;
			self.calendarContainer.style.right = 'auto';
		} else if (!centerMost) {
			self.calendarContainer.style.left = 'auto';
			self.calendarContainer.style.right = `${right}px`;
		} else {
			this._rightCenterCalendarPosition(self, inputBounds, calendarWidth);
		}
	}

	private _rightCenterCalendarPosition(self: any, inputBounds: DOMRect, calendarWidth: number) {
		const doc = this.getDocumentStyleSheet() as CSSStyleSheet;
		// some testing environments don't have css support
		if (doc === undefined) return;
		const bodyWidth = window.document.body.offsetWidth;
		const centerLeft = Math.max(0, bodyWidth / 2 - calendarWidth / 2);
		const centerBefore = '.flatpickr-calendar.centerMost:before';
		const centerAfter = '.flatpickr-calendar.centerMost:after';
		const centerIndex = doc.cssRules.length;
		const centerStyle = `{left:${inputBounds.left}px;right:auto;}`;
		this.toggleClass(self.calendarContainer, 'rightMost', false);
		this.toggleClass(self.calendarContainer, 'centerMost', true);
		doc.insertRule(`${centerBefore},${centerAfter}${centerStyle}`, centerIndex);
		self.calendarContainer.style.left = `${centerLeft}px`;
		self.calendarContainer.style.right = 'auto';
	}

	getDocumentStyleSheet() {
		const editableSheet = Array.from(document.styleSheets).find(styleSheet => styleSheet);
		return editableSheet ?? this.createStyleSheet();
	}

	createStyleSheet() {
		const style = document.createElement('style');
		document.head.appendChild(style);
		return style.sheet as CSSStyleSheet;
	}

	toggleClass(elem: HTMLElement, className: string, bool: boolean) {
		if (bool === true) return elem.classList.add(className);
		elem.classList.remove(className);
	}

	/**
	 * Set the value at a specific index.
	 *
	 * @param index
	 * @param value
	 */
	setValueAt(index: number, value: number, flags: any = {}) {
		const result = super.setValueAt(index, value, flags);
		handleSetDefaultValue(this, index, value, flags);

		return result;
	}

	formatValue(input: string) {
		const result = moment.utc(input).format(this.momentFormat);
		return result.toLowerCase() === 'invalid date' ? input : result;
	}

	public focus() {
		Component.prototype.focus.bind(this)();
	}

	getValueAsString(value: any) {
		if (!value) {
			return '';
		}

		const format = FormioUtils.convertFormatToMoment(this.component.format);
		const formattedValue = moment.utc(value).format(format);
		return formattedValue.toLowerCase() !== 'invalid date' ? formattedValue : value;
	}
}
