import Component from 'formiojs/components/_classes/component/Component';
import { CustomComponentType, CustomComponentLabel } from '../../../enums/custom-component-type';
import { IPopupBodyComponent } from '../popup';
import { UserDefinedDataFieldPostRequest, DataField, AutoFactory, CustomersService, CollateralsService, ProposedLoansService } from '@sageworks/jpi';
import { buildAddCustomFieldPayload } from './add-custom-field-helper';
import { getWidgetType } from '../../../utils/form-path-utils';

enum ValidationMessages {
	NameRequired = '"Field Name" is required',
	NameMustBeUnique = 'The supplied name already exists'
}

export default class AddCustomField extends Component implements IPopupBodyComponent {
	public resultPromise!: Promise<any>;
	private resultPromiseResolve!: (addedField?: DataField) => void;

	public preClose(): Promise<void> {
		return Promise.resolve();
	}

	public static schema(...extend: any) {
		return Component.schema(
			{
				label: CustomComponentLabel()[CustomComponentType.addCustomField],
				type: CustomComponentType.addCustomField,
				key: CustomComponentType.addCustomField,
				path: ''
			},
			...extend
		);
	}

	public init() {
		super.init();
		this.resultPromise = new Promise(resolve => (this.resultPromiseResolve = resolve));
	}

	public render(): any {
		return this.renderTemplate('dialogAddCustomField', {});
	}

	public async attach(element: any) {
		await super.attach(element);
		this.loadRefsAddListeners(element);
		this.setInputFormatOptions();
	}

	private loadRefsAddListeners(element: any) {
		this.loadRefs(element, {
			dialogAddCustomFieldName: 'single',
			dialogAddCustomFieldNameValidation: 'single',
			dialogAddCustomFieldType: 'single',
			dialogAddCustomFieldInputStringSelect: 'single',
			dialogAddCustomFieldInputStringLabel: 'single',
			dialogAddCustomFieldInputString: 'single',
			dialogAddCustomFieldInputStringOptionsTable: 'single',
			optionInput: 'multiple',
			removeDropdownOption: 'multiple',
			optionRow: 'multiple',
			optionTableBody: 'single',
			addNewDropdownOption: 'single',
			addCustomField: 'single',
			cancel: 'single',
			addCustomFieldSpinner: 'single'
		});

		this.addEventListener(this.refs.dialogAddCustomFieldType as any, 'change', () => this.handleTypeChange());
		this.addEventListener(this.refs.addCustomField as any, 'click', () => this.addCustomField());
		this.addEventListener(this.refs.cancel as any, 'click', () => this.resultPromiseResolve());
		this.addEventListener(this.refs.addNewDropdownOption as any, 'click', (event: Event) => this.addFormatOptionRow(event));

		this.refs.removeDropdownOption.forEach(row => {
			this.addEventListener(row, 'click', (event: Event) => this.removeOptionRow(event));
		});
	}

	private addCustomField() {
		const fieldName: HTMLInputElement = this.refs.dialogAddCustomFieldName as any;
		const inputType: UserDefinedDataFieldPostRequest.DataFieldInputFormatEnum = (this.refs.dialogAddCustomFieldType as any).value;
		const inputString: string = (this.refs.dialogAddCustomFieldInputString as any).value;
		const dropdownOptions: NodeList = this.refs.optionInput as any;

		if (fieldName.value === '') {
			fieldName.classList.add('is-invalid');
			return this.setContent(this.refs.dialogAddCustomFieldNameValidation, ValidationMessages.NameRequired);
		}

		const requestPayload = buildAddCustomFieldPayload(
			fieldName.value,
			inputType,
			dropdownOptions,
			inputString !== '' ? (inputString as UserDefinedDataFieldPostRequest.DataFieldFormatStringEnum) : undefined
		);

		this.makeRequest(requestPayload, fieldName);
		this.removeClass(this.refs.addCustomFieldSpinner, 'd-none');
	}

	private makeRequest(requestPayload: UserDefinedDataFieldPostRequest, fieldName: HTMLInputElement) {
		const service = this.getService();
		service
			.createUserDefinedField(requestPayload)
			.then(response => {
				if (response.id != null) {
					service.getPropertyById(response.id).then(newDataField => {
						this.resultPromiseResolve(newDataField);
					});
				}
			})
			.catch(error => {
				if (error.status === 400 && error?.response?.modelErrors.some((x: any) => x.propertyName === 'name')) {
					fieldName.classList.add('is-invalid');
					this.addClass(this.refs.addCustomFieldSpinner, 'd-none');
					this.setContent(this.refs.dialogAddCustomFieldNameValidation, ValidationMessages.NameMustBeUnique);
				}
			});
	}

	private getService() {
		const widgetType = getWidgetType(this.schema.path);

		switch (widgetType) {
			case CustomComponentType.personalInfo:
			case CustomComponentType.businessInfo:
			case CustomComponentType.nonprofitInfo:
			case CustomComponentType.farmInfo:
				return AutoFactory.get(CustomersService);
			case CustomComponentType.collateralInfo:
				return AutoFactory.get(CollateralsService);
			case CustomComponentType.loans:
				return AutoFactory.get(ProposedLoansService);
			default:
				throw Error('Unsupported widget type');
		}
	}

	private setInputFormatOptions() {
		const selectOptions = [
			{ value: UserDefinedDataFieldPostRequest.DataFieldInputFormatEnum.Text, label: 'Text' },
			{ value: UserDefinedDataFieldPostRequest.DataFieldInputFormatEnum.Numeric, label: 'Numeric' },
			{ value: UserDefinedDataFieldPostRequest.DataFieldInputFormatEnum.Currency, label: 'Currency' },
			{ value: UserDefinedDataFieldPostRequest.DataFieldInputFormatEnum.Percent, label: 'Percent' },
			{ value: UserDefinedDataFieldPostRequest.DataFieldInputFormatEnum.Date, label: 'Date' },
			{ value: UserDefinedDataFieldPostRequest.DataFieldInputFormatEnum.Dropdown, label: 'Dropdown' },
			{ value: UserDefinedDataFieldPostRequest.DataFieldInputFormatEnum.Radio, label: 'Yes / No' }
		];
		this.selectOptions(this.refs.dialogAddCustomFieldType, null, selectOptions, null);
	}

	private handleTypeChange() {
		const selectedType: UserDefinedDataFieldPostRequest.DataFieldInputFormatEnum = (this.refs.dialogAddCustomFieldType as any).value;
		switch (selectedType) {
			case UserDefinedDataFieldPostRequest.DataFieldInputFormatEnum.Numeric:
				this.showNumericFormatOptions();
				break;
			case UserDefinedDataFieldPostRequest.DataFieldInputFormatEnum.Dropdown:
				this.showDropdownFormatOptions();
				break;
			default:
				this.addClass(this.refs.dialogAddCustomFieldInputStringSelect, 'd-none');
				this.addClass(this.refs.dialogAddCustomFieldInputStringOptionsTable, 'd-none');
		}
	}

	private showNumericFormatOptions() {
		this.addClass(this.refs.dialogAddCustomFieldInputStringOptionsTable, 'd-none');

		const selectOptions = [
			{ value: UserDefinedDataFieldPostRequest.DataFieldFormatStringEnum.N0, label: 'Display Whole Number' },
			{ value: UserDefinedDataFieldPostRequest.DataFieldFormatStringEnum.N1, label: 'Display 1 Decimal' },
			{ value: UserDefinedDataFieldPostRequest.DataFieldFormatStringEnum.N2, label: 'Display 2 Decimals' },
			{ value: UserDefinedDataFieldPostRequest.DataFieldFormatStringEnum.N3, label: 'Display 3 Decimals' },
			{ value: UserDefinedDataFieldPostRequest.DataFieldFormatStringEnum.N4, label: 'Display 4 Decimals' }
		];
		this.setContent(this.refs.dialogAddCustomFieldInputStringLabel, 'Number of Decimals:');
		this.selectOptions(this.refs.dialogAddCustomFieldInputString, null, selectOptions, null);
		this.removeClass(this.refs.dialogAddCustomFieldInputStringSelect, 'd-none');
	}

	private showDropdownFormatOptions() {
		this.addClass(this.refs.dialogAddCustomFieldInputStringSelect, 'd-none');
		this.removeClass(this.refs.dialogAddCustomFieldInputStringOptionsTable, 'd-none');
	}

	private removeOptionRow(event: Event) {
		event.preventDefault();
		const deleteElement: HTMLElement = event.target as HTMLElement;
		const tableBody: HTMLElement = this.refs.optionTableBody as any;

		if (this.refs.optionRow.length === 1) {
			(this.refs.optionInput[0] as HTMLInputElement).value = '';
		} else if (deleteElement.parentElement !== null && deleteElement.parentElement.parentElement !== null) {
			// Reference is on the input element, navigate up two level to remove the table row
			tableBody.removeChild(deleteElement.parentElement.parentElement);
			this.removeEventListeners();
			this.loadRefsAddListeners(this.element);
		}
	}

	private addFormatOptionRow(event: Event) {
		event.preventDefault();
		let element = document.createElement('tr') as HTMLElement;
		element.classList.add('d-flex');
		element.setAttribute('ref', 'optionRow');
		element.innerHTML = `<td class="col-1">
				<a href="javascript:;" class="fa fa-times" ref="removeDropdownOption" aria-label="Remove dropdown option"></a>
			</td>
			<td class="col-11">
				<input id="DropdownOptions" class="form-control" type="text" ref="optionInput"/>
			</td>`;

		this.appendChild(this.refs.optionTableBody, element);
		this.removeEventListeners();
		this.loadRefsAddListeners(this.element);
	}
}
