import editForm from './life-insurance.form';
import { CustomComponentType, CustomComponentLabel, LifeInsuranceCustomFieldKey } from '../../../../enums';
import { FinancialSubaccount } from '../financial-subaccount/financial-subaccount';

export class LifeInsuranceWidget extends FinancialSubaccount {
	static schema(...extend: any) {
		return FinancialSubaccount.schema(
			{
				label: CustomComponentLabel()[CustomComponentType.lifeInsurance],
				type: CustomComponentType.lifeInsurance,
				key: CustomComponentType.lifeInsurance,
				addAnother: 'Add Life Insurance'
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: CustomComponentLabel()[CustomComponentType.lifeInsurance],
			group: '',
			weight: 10,
			schema: LifeInsuranceWidget.schema()
		};
	}

	protected getFormattedDescription(_: string, row: any) {
		return `${row.data?.[LifeInsuranceCustomFieldKey.NameOfInsuranceCompany]} - ${row.data?.[LifeInsuranceCustomFieldKey.InsuranceType]}`;
	}
}
