
import Vue, { PropType } from 'vue';
import { DetailedLoanApplication, OnlinePortalProposedLoanStageGroup } from '@sageworks/jpi';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ProposedLoanSection from './proposed-loan-section.vue';

export default Vue.extend({
	name: 'LoanApplicationCard',
	components: {
		FontAwesomeIcon,
		ProposedLoanSection 
	},
	props: {
		loanApplication: {
			type: Object as PropType<DetailedLoanApplication>,
			required: true
		},
		stageGroups: {
			type: Array as PropType<OnlinePortalProposedLoanStageGroup[]>,
				required: true
		}
	}
});
