import BaseComponent from '../base/base-widget/base-widget';
import editForm from './sba-form-1920.form';
import { CustomComponentType } from '../../../enums';

export default class SbaForm1920Widget extends BaseComponent {
	static schema(...extend: any) {
		return BaseComponent.schema(
			{
				label: 'Form 1920',
				type: CustomComponentType.sbaForm1920,
				key: CustomComponentType.sbaForm1920
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: 'Form 1920',
			group: '',
			weight: 10,
			schema: SbaForm1920Widget.schema()
		};
	}
}
