import { defineModule, localActionContext } from 'direct-vuex';
import {
	AutoFactory,
	OnlinePortalDocumentNamesService,
	DocumentName,
	DynamicApplicationAdditionalAllowableDocument,
	DynamicApplicationAdditionalAllowableDocumentsService
} from '@sageworks/jpi';
import { BootstrapVueSelectOption } from '@/models';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => localActionContext(context, DocumentNameModule);

export interface DocumentNameModuleState {
	documentNames: DocumentName[];
	additionalAllowableDocuments: DynamicApplicationAdditionalAllowableDocument[];
}
const DocumentNameModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			documentNames: [],
			additionalAllowableDocuments: []
		} as DocumentNameModuleState;
	},
	mutations: {
		SET_DOCUMENT_NAME_OPTIONS(state, newDocumentNames: DocumentName[]) {
			state.documentNames = newDocumentNames;
		},
		SET_ADDITIONAL_ALLOWABLE_DOCUMENTS(state, additionalAllowableDocuments: DynamicApplicationAdditionalAllowableDocument[]) {
			state.additionalAllowableDocuments = additionalAllowableDocuments;
		}
	},
	actions: {
		async loadDocumentNames(context): Promise<void> {
			const { commit } = actionContext(context);

			const documentNameService = AutoFactory.get(OnlinePortalDocumentNamesService);
			var documentNames = await documentNameService.getPaged(1, 1000);

			documentNames.items = documentNames.items?.sort((name1, name2) => (name1.name as string).localeCompare(name2.name as string));

			commit.SET_DOCUMENT_NAME_OPTIONS(documentNames.items || []);
		},
		async loadAdditionalAllowableDocuments(context, applicationType): Promise<void> {
			const { commit } = actionContext(context);

			const additionalAllowableDocumentsService = AutoFactory.get(DynamicApplicationAdditionalAllowableDocumentsService);
			var allowableDocuments = await additionalAllowableDocumentsService.getByApplicationType(applicationType);

			commit.SET_ADDITIONAL_ALLOWABLE_DOCUMENTS(allowableDocuments || []);
		}
	},
	getters: {
		documentNameById: state => (documentNameId: number) => {
			return state.documentNames.filter(documentName => documentName.id === documentNameId)[0];
		},
		documentNameSelectOptions: state => (isLender: boolean) => {
			return state.documentNames.map((name: DocumentName) => {
				return {
					// If we're a borrower, we want the customer-facing name unless there isn't one defined, in which case we use the regular name
					text: !isLender && name.customerFacingName != null && name.customerFacingName !== '' ? name.customerFacingName : name.name,
					value: name.id
				} as BootstrapVueSelectOption;
			});
		},
		documentNameOptionsForOtherDocuments: (state, getters) => (isLender: boolean) => {
			if (isLender) {
				return getters.documentNameSelectOptions(isLender);
			}
			const allowableDocumentNameIds = state.additionalAllowableDocuments.filter(doc => doc.enabled).map(doc => doc.documentName.id);
			return state.documentNames
				.filter(doc => allowableDocumentNameIds.includes(doc.id))
				.map((name: DocumentName) => {
					return {
						text: name.customerFacingName != null && name.customerFacingName !== '' ? name.customerFacingName : name.name,
						value: name.id
					} as BootstrapVueSelectOption;
				});
		}
	}
});

export default DocumentNameModule;
