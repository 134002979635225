import ModifiedWizard from './modified-wizard';
import { FormioEventName } from '../../enums';
import ModifiedPanelComponent from '../form-components/formio-components/panel/modified-panel-component';
import { LocateParentHelper } from '../../utils';

export class NestedWizard extends ModifiedWizard {
	private _rootWizard: ModifiedWizard | undefined;

	get renderContext() {
		return {
			...super.renderContext,
			isRepeaterWidget: this.isRepeaterWidget,
			primaryRoleName: this.topLevelCustomComponent?.label ?? 'Primary Role',
			parentPreviousTitle: this.rootWizardPreviousPage?.component.title,
			parentNextTitle: this.rootWizardNextPage?.component.title
		};
	}

	// @ts-ignore
	get wizardKey() {
		return `nested-wizard-${this.id}`;
	}

	// @ts-ignore
	get buttons() {
		return {
			...super.buttons,
			saveEditRow: {
				name: 'saveEditRow',
				method: 'emitSaveRow'
			}
		};
	}

	get rootWizard(): ModifiedWizard | undefined {
		if (this._rootWizard == null) {
			this._rootWizard = LocateParentHelper.locateRootWizard(this, 20);
		}

		return this._rootWizard;
	}

	get rootWizardNextPage(): ModifiedPanelComponent | undefined {
		const currentPage = this.rootWizard?.page;
		const rootWizardPages = this.rootWizard?.pages.length ?? -1;

		if (currentPage != null && currentPage + 1 < rootWizardPages) {
			return this.rootWizard?.pages[currentPage + 1];
		}

		return undefined;
	}

	get rootWizardPreviousPage(): ModifiedPanelComponent | undefined {
		const currentPage = this.rootWizard?.page ?? -1;
		if (currentPage > 0) {
			return this.rootWizard?.pages[currentPage - 1];
		}

		return undefined;
	}

	public async attach(element: any) {
		this.loadRefs(element, {
			[`${this.wizardKey}-saveEditRow`]: 'single',
			[`${this.wizardKey}-topPrevious`]: 'single',
			[`${this.wizardKey}-topNext`]: 'single'
		});

		this.addEventListener(this.refs[`${this.wizardKey}-topPrevious`] as any, 'click', () => this.emit(FormioEventName.rootWizardPreviousPage, {}));
		this.addEventListener(this.refs[`${this.wizardKey}-topNext`] as any, 'click', () => this.emit(FormioEventName.rootWizardNextPage, {}));
		if (!this.isRepeaterWidget) {
			this.emit(FormioEventName.hideParentNav, {});
		}
		return await super.attach(element);
	}

	public prepareNavigationSettings(ctx: any) {
		const currentPanel = this.currentPanel;

		if (currentPanel && currentPanel.buttonSettings) {
			Object.keys(currentPanel.buttonSettings).forEach(() => {
				Object.keys(ctx.buttons).forEach(key => {
					if ((typeof currentPanel.buttonSettings[key] !== 'undefined' && !currentPanel.buttonSettings[key]) || ctx.isSubForm) {
						ctx.buttons[key] = null;
					}
				});
			});
		}

		return ctx;
	}

	public render() {
		const ctx = this.renderContext;

		if (this.component.key) {
			ctx.panels.map(panel => {
				if (panel.key === this.component.key) {
					this.currentPanel = panel;
				}
			});
		}

		const wizardNav = this.renderTemplate('nestedWizardNav', this.prepareNavigationSettings(ctx));

		const hideHeader = this.currentPanel && this.currentPanel.breadcrumb === 'none';
		const wizardHeader = hideHeader ? null : this.renderTemplate('nestedWizardHeader', ctx);

		return this.renderTemplate(
			'nestedWizard',
			{
				...ctx,
				className: super.getClassName(),
				wizardHeader,
				wizardNav,
				components: this.renderComponents([...this.prefixComps, ...this.currentPage.components, ...this.suffixComps])
			},
			this.builderMode ? 'builder' : 'form'
		);
	}

	// Copied from Formio, just changed which template to use
	public redrawNavigation() {
		if (this.element) {
			let navElement = this.element.querySelector(`#${this.wizardKey}-nav`);
			if (navElement) {
				this.detachNav();
				navElement.outerHTML = this.renderTemplate('nestedWizardNav', this.renderContext);
				navElement = this.element.querySelector(`#${this.wizardKey}-nav`);
				this.loadRefs(navElement, {
					[`${this.wizardKey}-cancel`]: 'single',
					[`${this.wizardKey}-previous`]: 'single',
					[`${this.wizardKey}-next`]: 'single',
					[`${this.wizardKey}-submit`]: 'single'
				});
				this.attachNav();
			}
		}
	}

	// Copied from Formio, just changed which template to use
	public redrawHeader() {
		if (this.element) {
			let headerElement = this.element.querySelector(`#${this.wizardKey}-header`);
			if (headerElement) {
				this.detachHeader();
				headerElement.outerHTML = this.renderTemplate('nestedWizardHeader', this.renderContext);
				headerElement = this.element.querySelector(`#${this.wizardKey}-header`);
				this.loadRefs(headerElement, {
					[`${this.wizardKey}-link`]: 'multiple',
					[`${this.wizardKey}-tooltip`]: 'multiple'
				});
				this.attachHeader();
			}
		}
	}

	// Copied from latest Formio codebase, current formio code didn't have the if check to ensure the ref existed, aka we were
	// 	getting an error when it didn't exist
	detachHeader() {
		if (this.refs[`${this.wizardKey}-link`]) {
			this.refs[`${this.wizardKey}-link`].forEach((link) => {
				this.removeEventListener(link, 'click');
			});
		}
	}

	// Copied from Formio with changes to not break if the form is currently showing validation errors
	// eslint-disable-next-line max-lines-per-function
	public getNextPage() {
		const data = this.submission.data;
		const form = this.pages[this.page].component;
		// Check conditional nextPage
		if (form) {
			const page = this.pages.length > this.page + 1 ? this.page + 1 : -1;
			if (form.nextPage) {
				const next = this.evaluate(
					form.nextPage,
					{
						next: page,
						data,
						page,
						form
					},
					'next'
				);
				if (next === null) {
					this.currentNextPage = null;
					return null;
				}

				const pageNum = parseInt(next, 10);
				if (!isNaN(pageNum) && isFinite(pageNum)) {
					this.currentNextPage = pageNum;
					return pageNum;
				}

				this.currentNextPage = this.getPageIndexByKey(next);
				return this.currentNextPage;
			}

			this.currentNextPage = page;
			return page;
		}

		this.currentNextPage = null;
		return null;
	}

	public emitWizardPageSelected(index: number) {
		super.emitWizardPageSelected(index);
		this.handlePageChange();
	}

	public emitPrevPage() {
		super.emitPrevPage();
		this.handlePageChange();
	}

	public emitNextPage() {
		super.emitNextPage();
		this.handlePageChange();
	}

	private handlePageChange() {
		this.emit(FormioEventName.nestedWizardPageChange, { parentWidgetId: this.topLevelCustomComponent?.id });
		this.setValue(this.submission);
	}

	private emitSaveRow() {
		this.emit(FormioEventName.nestedWizardSaveAndClose, { parentWidgetId: this.topLevelCustomComponent?.id });
	}
}
