import { OnlinePortalApplicationDocumentRequest } from '@/models';
import { GroupedDocumentRequests } from '@/models/GroupedDocumentRequests';

/**
 * Given an object representing a set of "grouped" requests from the ApplicationDocumentRequestService,
 * expand all the groups and return the full list of requests in a single array.
 * @param groupedRequestsObject
 */
export function expandGroupedDocumentRequests(groupedRequestsObject: GroupedDocumentRequests): OnlinePortalApplicationDocumentRequest[] {
	const requests: OnlinePortalApplicationDocumentRequest[] = [];
	if (!groupedRequestsObject) {
		return requests;
	}

	for (const customerName in groupedRequestsObject) {
		if (!Array.isArray(groupedRequestsObject[customerName])) {
			continue;
		}

		requests.push(...groupedRequestsObject[customerName]);
	}

	return requests;
}
