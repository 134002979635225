import { CustomComponentType, LifeInsuranceCustomFieldKey } from '../../../../../enums';
import { PersonalFinancialTypeSelect } from '../personal-financial-type-select/personal-financial-type-select';
import { createSubaccountDefaultEditConfiguration } from '../configuration/default-edit-configuration';

export class PersonalInsuranceTypeSelect extends PersonalFinancialTypeSelect {
	static schema(...extend: any) {
		return PersonalFinancialTypeSelect.schema(
			{
				label: 'Insurance Type',
				type: CustomComponentType.personalInsuranceTypeSelect,
				key: LifeInsuranceCustomFieldKey.InsuranceType,
			},
			...extend
		);
	}

	static editForm = createSubaccountDefaultEditConfiguration(CustomComponentType.personalInsuranceTypeSelectOptions);

	static get builderInfo() {
		return {
			title: 'Insurance Type',
			weight: 10,
			schema: PersonalInsuranceTypeSelect.schema()
		};
	}
}
