import { DataField } from '@sageworks/jpi';
import { CustomComponentType, TemplateDataField } from '../../../../../enums';
import { DataFieldConvertStrategy } from '../../data-field-convert-strategy';

export class CollateralTypeDataFieldConverter implements DataFieldConvertStrategy {
	private readonly allowedFields = new Set<number>([TemplateDataField.CollateralType]);

	isFieldValid(dataField: DataField): boolean {
		return this.allowedFields.has(dataField.templateDataFieldId!);
	}

	convert(dataField: DataField, currentProperties: any) {
		const properties = { ...currentProperties };

		properties.type = CustomComponentType.collateralTypeSelect;
		properties.dataType = 'number';

		return properties;
	}
}
