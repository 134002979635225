
import { ComponentState } from '@/enums';
import { ApplicationExportMetadata } from '@/models';
import { LoanApplication, LoanOfficer, StaticRenderDataModel, LoanRelationship } from '@sageworks/jpi';
import moment from 'moment';
import Vue, { PropType } from 'vue';
import FormioForm from '../formio-templates/formio-form.vue';
import ApplicationPdfSignatureSection from './application-pdf-signature-section.vue';

export default Vue.extend({
	name: 'ApplicationPdf',
	components: {
		FormioForm,
		SignatureSection: ApplicationPdfSignatureSection
	},
	props: {
		isExporting: { type: Boolean, default: false },
		lenderOnly: { type: Boolean, default: true },
		loanApplication: { type: Object as PropType<LoanApplication | null>, required: true },
		logoUrl: { type: String, required: true },
		loanOfficer: { type: Object as PropType<LoanOfficer | null>, required: true },
		renderData: { type: Array as PropType<StaticRenderDataModel[]>, required: true },
		showEsignLines: { type: Boolean, default: true },
		loanRelationships: { type: Array as PropType<(LoanRelationship | null)[]>, default: () => [] },
		showRefinanceQuestion: { type: Boolean, default: false }
	},
	data() {
		return {};
	},
	computed: {
		componentState(): ComponentState {
			return this.isExporting ? ComponentState.EXPORTING : ComponentState.LOADED;
		},
		applicationNumber(): number | null {
			return this.loanApplication?.applicationNumber || null;
		},
		applicationSubmittedDate(): string | null {
			if (
				this.loanApplication == null ||
				!this.loanApplication.submittedDate ||
				!moment(this.loanApplication.submittedDate, 'YYYY-MM-DD', true).isValid()
			)
				return null;
			return moment(this.loanApplication.submittedDate, 'YYYY-MM-DD').format('MMMM Do, YYYY');
		},
		loanOfficerFullName(): string | null {
			const { firstName, lastName } = this.loanOfficer ?? {};

			if (!firstName || !lastName) {
				return null;
			}
			return `${firstName} ${lastName}`.trim();
		},
		loanOfficerLoanRepresentativeCode(): string | null {
			let loanOfficerLoanRepresentativeCode = this.loanOfficer?.loanRepresentativeCode;
			if (loanOfficerLoanRepresentativeCode && loanOfficerLoanRepresentativeCode?.length > 0) {
				return loanOfficerLoanRepresentativeCode;
			}
			return null;
		},
		signatureDataList() {
			return (this.renderData as Array<StaticRenderDataModel>).map(x => x.signatureData);
		}
	},
	mounted() {
		if (!this.showRefinanceQuestion) {
			return;
		}
		for (let i = 0; i < this.renderData.length; i++) {
			let refinanceRadioId = `isRefinanceRadioFalse_${i}`;
			if (this.isRefinanceLoan(i) === true) {
				refinanceRadioId = `isRefinanceRadioTrue_${i}`;
			}

			this.$refs[refinanceRadioId][0].checked = true;
			this.$refs[refinanceRadioId][0].setAttribute('checked', true);
		}
	},
	methods: {
		getExportMetadata(componentTree: any, loanIndex: number): ApplicationExportMetadata {
			return {
				ComponentTree: componentTree,
				ComponentPath: '',
				LoanIndex: loanIndex,
				LenderOnlyMode: this.lenderOnly
			};
		},
		isRefinanceLoan(index: number): boolean {
			if (index >= this.loanRelationships.length || this.loanRelationships[index] == null) {
				return false;
			}
			return this.loanRelationships[index]?.relatedLoanType === LoanRelationship.RelatedLoanTypeEnum.Refinance ?? false;
		}
	}
});
