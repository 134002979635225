
import Vue from 'vue';
import { BModal } from 'bootstrap-vue';
export default Vue.extend({
	name: 'BorrowerConfirmModal',
	components: {},
	props: {},
	data() {
		return {};
	},
	computed: {},
	mounted() {},
	methods: {
		markReadyForReview() {
			this.$emit('mark-ready-for-review');
			this.closeModal();
		},
		closeModal() {
			(this.$refs.modal as BModal).hide();
		}
	}
});
