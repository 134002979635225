import { getOnlinePortalSubdomain } from './UrlUtils';

export function setItem(key: string, value: string) {
	const prefixedKey = getUniqueStorageKey(key);
	localStorage.setItem(prefixedKey, value);
}

export function removeItem(key: string) {
	const prefixedKey = getUniqueStorageKey(key);
	localStorage.removeItem(prefixedKey);
}

export function getItem(key: string): string | null {
	const prefixedKey = getUniqueStorageKey(key);
	return localStorage.getItem(prefixedKey);
}

// We want to be able to store separate localStorage values for different environments and institutions (subdomains).
// To do this, we generate a prefix for our localStorage keys to keep value storage locations unique.
export function getUniqueStorageKey(key: string): string {
	const subdomain = getOnlinePortalSubdomain();
	const environment = window?.app?.environment;
	return `${environment}_${subdomain}_${key}`;
}
