import nestedComponentForm from 'formiojs/components/_classes/nested/NestedComponent.form';
import {
	BaseEditDisplay,
	BaseEditForm,
	IBaseWidgetConfigurationEditFormOptions,
	BaseEditLogic,
	BaseEditApi,
	BaseEditConditional,
	BaseEditLayout
} from '../../configuration-components/base-widget-configuration';
import { CustomComponentType } from '../../../enums/custom-component-type';

export default function(...extend: any) {
	return nestedComponentForm(
		[
			new BaseEditDisplay().getTab(),
			new BaseEditForm().getTab({ componentType: CustomComponentType.sbaForm1920 } as IBaseWidgetConfigurationEditFormOptions),
			new BaseEditLogic().getTab(),
			new BaseEditApi().getTab(),
			new BaseEditConditional().getTab(),
			new BaseEditLayout().getTab()
		],
		...extend
	);
}
