
import Vue from 'vue';
import { UsableProducts } from '@sageworks/jpi';
import PortalCard from '../portal-card.vue';
import { OnlinePortalRoutes } from '@/OnlinePortalRoutes';

export default Vue.extend({
	name: 'MainContentPortalCard',
	components: {
		PortalCard,
	},
	computed: {
		hasCommunityLendingSubscription(): boolean | undefined {
			return this.directStore.state.UsableProducts.usableProducts?.has('abrigoCommunityLending' as UsableProducts.ProductsEnum);
		},
		title() {
			if (this.$route.path.includes(OnlinePortalRoutes.Applications.path)) {
				return this.hasCommunityLendingSubscription ? 'Request Forms' : 'Applications';
			} else if (this.$route.path.includes(OnlinePortalRoutes.Documents.path)) {
				return 'Documents';
			} else if (this.$route.path.includes(OnlinePortalRoutes.Home.path)) {
				return 'Tasks';
			} else if (this.$route.path.includes(OnlinePortalRoutes.MyInfo.path)) {
				return 'My Info';
			} else {
				return '';
			}
		}
	},
});
