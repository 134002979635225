
import Vue, { PropType } from 'vue';
import { AgreementStatus, RecipientSignatureStatus } from '@sageworks/jpi';
import moment from 'moment';

export default Vue.extend({
	name: 'ApplicationSignatureStatus',
	components: {},
	props: {
		applicationId: {
			type: Number as PropType<number | null>,
			required: true
		}
	},
	data() {
		return {};
	},
	computed: {
		adobeAgreementCreated(): boolean {
			return this.directStore.getters.AdobeESignModule.adobeAgreementCreated ?? false;
		},
		adobeAgreementStatus(): AgreementStatus {
			return this.directStore.state.AdobeESignModule.adobeAgreementStatus ?? {};
		},
		recipientStatuses(): RecipientSignatureStatus[] {
			return this.directStore.getters.AdobeESignModule.adobeRecipientStatuses ?? [];
		},
		customerHasPhoneAuthEnabled(): boolean {
			return this.directStore.state.AdobeESignModule.customerHasPhoneAuthEnabled ?? false;
		},
		formattedRequestedDate(): string {
			return this.adobeAgreementCreated && this.adobeAgreementStatus.requestedDate && moment(this.adobeAgreementStatus.requestedDate).isValid()
				? moment(this.adobeAgreementStatus.requestedDate).format('lll')
				: '';
		}
	},
	mounted() {
		this.loadInitialData();
	},
	methods: {
		async loadInitialData() {
			await this.directStore.dispatch.AdobeESignModule.fetchLoanApplicationRecipientStatuses({ applicationId: this.applicationId as number });
		},
		getVariant(recipientSignatureStatus: RecipientSignatureStatus) {
			return recipientSignatureStatus.dateSigned == null ? 'primary' : 'success';
		},
		getAgreementEmailStatus(recipientSignatureStatus: RecipientSignatureStatus): string {
			if (!this.adobeAgreementCreated) {
				return 'No Email Sent';
			} else if (this.adobeAgreementCreated && !recipientSignatureStatus.isSigned) {
				return 'Email Sent';
			} else if (this.adobeAgreementCreated && recipientSignatureStatus.isSigned) {
				return 'Signed';
			} else if (!recipientSignatureStatus.email) {
				return 'No Email Sent';
			} else {
				return 'No Email Status';
			}
		},
		getFormattedRecipientSignedDate(dateSigned: string): string {
			return moment(dateSigned).isValid() ? moment(dateSigned).format('ll') : dateSigned;
		}
	}
});
