import {
	BeneficialOwnerWithCustomerType,
	DataObject,
	DataObjectUtils,
	LoanRoleWithCustomerType
} from '@sageworks/dynamic-forms';
import {
	convertBeneficialOwnerDataWithCustomerTypeToFormDataModel,
	getProposedLoanId
} from '@sageworks/dynamic-forms/src/utils/submission-data-mapper/form-data-submission-data-mapper-utils';
import {
	AutoFactory,
	Customer,
	LoanRoleDataModel,
	OnlinePortalLoanRolesService,
	DataObject as DataObjectModel
} from '@sageworks/jpi';
import { defineActions } from 'direct-vuex';
import { MultiLoanApplicationFormModuleState } from '../state';
import { actionContext } from '../store-helper';
import { locateRoles } from '../../../utils/form-data-utils';
import { AddLoanRoleParams, LoadRelatedDataForLoanRole, RemoveLoanRolesForCustomerParams } from './persist-action-params';
import { fetchBeneficialOwners } from '../helpers/data-object-fetch-helper';

export default defineActions({
	async addLoanRoleForDataObject(context, { dataObjectType, customerId, loanRoleType }: AddLoanRoleParams): Promise<LoanRoleDataModel> {
		const { state, commit } = actionContext(context);
		const { renderData } = state as MultiLoanApplicationFormModuleState;
		const { formData } = renderData ?? {};

		const proposedLoanId = getProposedLoanId(formData?.dataObjects ?? {});
		if (proposedLoanId == null) {
			throw new Error('proposedLoanId cannot be null or undefined');
		}

		const roles = locateRoles(formData ?? {}, loanRoleType);
		const existingRole = roles.find(role => role.customerID === customerId);

		if (existingRole) {
			return existingRole;
		}

		const newRole: LoanRoleWithCustomerType = {
			customerId,
			proposedLoanId: proposedLoanId,
			percentResponsible: 1,
			roleType: loanRoleType,
			customerType: DataObjectUtils.dataObjectTypeToCustomerType(dataObjectType)
		};
		const LoanRolesService = AutoFactory.get(OnlinePortalLoanRolesService);
		const savedRole = await LoanRolesService.create(newRole);

		const formattedLoanRole: LoanRoleDataModel = {
			dataObjectID: savedRole.id,
			customerID: savedRole.customerId,
			roleType: savedRole.roleType,
		};
		commit.UPSERT_LOAN_ROLE({ loanRole: formattedLoanRole, loanRoleType });

		return formattedLoanRole;
	},
	async removeLoanRolesForCustomer(context, { loanRoleType, customerId }: RemoveLoanRolesForCustomerParams): Promise<void> {
		const { state, commit } = actionContext(context);
		const { renderData } = state as MultiLoanApplicationFormModuleState;
		const { formData } = renderData ?? {};

		if (customerId == null) {
			return;
		}

		const loanRolesToRemove = locateRoles(formData ?? {}, loanRoleType)
			.filter(x => x.customerID === customerId && x.dataObjectID != null && x.roleType != null);

		const loanRolesService = AutoFactory.get(OnlinePortalLoanRolesService);
		const promises = loanRolesToRemove.map(x => loanRolesService._delete(x.dataObjectID!, x.roleType!));
		await Promise.all(promises);

		commit.REMOVE_LOAN_ROLES({ ids: loanRolesToRemove.map(x => x.dataObjectID!), loanRoleType });
	},
	// TODO: Lets eventually move this to the server to try to limit any data stitching on the frontend
	async loadRelatedDataForLoanRole(context, { id, loanRoleType, dataFieldMetadata, customerObjectType }: LoadRelatedDataForLoanRole) {
		const { state, commit, dispatch } = actionContext(context);
		const { renderData } = state as MultiLoanApplicationFormModuleState;
		const { formData } = renderData ?? {};

		const loanRole = locateRoles(formData ?? {}, loanRoleType).find(x => x.dataObjectID === id);

		if (loanRole == null) {
			throw new Error(`Cannot locate Loan Role. id = ${id}`);
		}

		const personIds: number[] = customerObjectType === Customer.TypeEnum.Person ? [loanRole.customerID!] : [];
		const businessIds: number[] = customerObjectType === Customer.TypeEnum.Business ? [loanRole.customerID!] : [];

		// Fetch all the beneficial owners related to the customer associated to the loan role
		let beneficialOwnerFetchPromise = Promise.resolve<DataObjectModel[]>([]);
		const beneficialOwnersExists = (formData?.beneficialOwners ?? []).some(x => x.customerId === loanRole.customerID);
		if (customerObjectType === Customer.TypeEnum.Business && !beneficialOwnersExists) {
			const newBeneficialOwners: BeneficialOwnerWithCustomerType[] = 
				(await fetchBeneficialOwners([{ customerId: loanRole.customerID, id: loanRole.dataObjectID }]));
			commit.UPSERT_BENEFICIAL_OWNERS({ beneficialOwners: convertBeneficialOwnerDataWithCustomerTypeToFormDataModel(newBeneficialOwners) });

			// Ensure we pull the customers associated to the beneficial owner mappings
			newBeneficialOwners.forEach(x => {
				if (x.ownerCustomerType === Customer.TypeEnum.Person) personIds.push(x.ownerCustomerId);
				else if (x.ownerCustomerType === Customer.TypeEnum.Business) businessIds.push(x.ownerCustomerId);
			});

			const beneficialOwnersIds = newBeneficialOwners.map(x => x.id!);

			beneficialOwnerFetchPromise = dispatch
				.loadDataObjects({ ids: beneficialOwnersIds, type: DataObject.TypeEnum.BeneficialOwnerDetails, dataFieldMetadata });
		}

		await Promise.all([
			beneficialOwnerFetchPromise,

			// load all associated customers
			dispatch.loadDataObjects({ ids: personIds, type: DataObject.TypeEnum.Person, dataFieldMetadata }),
			dispatch.loadDataObjects({ ids: businessIds, type: DataObject.TypeEnum.Business, dataFieldMetadata }),
		]);
	}
});
