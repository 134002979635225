import { AutoFactory, DynamicApplicationMessage, DynamicApplicationMessagesService } from '@sageworks/jpi';
import { CustomComponentType } from '../../../../enums';
import CreditReportWidget from '../credit-report';
import { LocateParentHelper } from '../../../../utils';

export const getCreditReportAuthorizationMessage = async (
	loanAppType: DynamicApplicationMessage.ApplicationTypeEnum,
	messageType: DynamicApplicationMessage.MessageTypeEnum
): Promise<DynamicApplicationMessage | null> => {
	try {
		let service = AutoFactory.get(DynamicApplicationMessagesService);

		let result = await service.getPaged(1, 1, [loanAppType], [messageType]);

		let firstResult = result.items?.pop();

		return firstResult || null;
	} catch (e) {
		return null;
	}
};

export const getEntityIdFromCorrectParent = (component: CreditReportWidget): number => {
	const parent = LocateParentHelper.locateClosestParent(
		component,
		x => x.type === CustomComponentType.personalInfo || x.type === CustomComponentType.businessInfo
	);
	return parent?.dataValue?.data?.id ?? -1;
};
