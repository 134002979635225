
import { Component, Mixins } from 'vue-property-decorator';
import { LoginTemplate } from '../../components/templates';
import { OnlinePortalLoginHeader } from '@/components';
import { ScaleMixin, RouteMixin } from '@/mixins';
import { StylingConstants } from '@/enums/styling-constants';
import { Auth0LoginPage } from '../../components/auth0-login-page';

@Component({
	components: {
		OnlinePortalLoginHeader,
		LoginTemplate,
		Auth0LoginPage
	}
})
export default class PortalLoginTemplate extends Mixins(ScaleMixin, RouteMixin) {
	auth0FFEnabled: boolean = false;

	get backgroundClassName(): string {
		return `${StylingConstants.backgroundClassName}`;
	}

	async mounted() {
		this.auth0FFEnabled = await this.directStore.dispatch.Authentication.isAuth0Enabled();
	}
}
