module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="container">\n	<header class="row modal-header">\n		<div class="col-sm">\n			<h2>' +
((__t = ( ctx.title )) == null ? '' : __t) +
'</h2>\n\n			<p>\n			';
 if (ctx.state == 'Credentials') { ;
__p += '\n				Select a set of saved credentials to use for pulling the credit\n				report\n			';
 } ;
__p += '\n\n			';
 if (ctx.state == 'PullCredit' && ctx.isLender) { ;
__p += '\n				This credit report will be made using saved credentials named "' +
((__t = (ctx.credentialsName)) == null ? '' : __t) +
'." <a ref="otherCredentialsLink" href="#">Use Other Credentials</a>\n			';
 } ;
__p += '\n\n			</p>\n		</div>\n\n	</header>\n\n	<div ref="subComponentTemplateSlot" class="modal-body row">\n		<div class="col-sm">\n			' +
((__t = ( ctx.subComponentTemplate )) == null ? '' : __t) +
'\n		</div>\n	</div>\n</div>';
return __p
}