import { Collateral, AutoFactory, OnlinePortalCollateralService } from '@sageworks/jpi';

async function getCollateralOnApplicationForEntities(loanApplicationId: number, relatedEntities: number[]): Promise<Collateral[]> {
	const service = AutoFactory.get(OnlinePortalCollateralService);
	const collateral =  (await service.getByLoanApplicationId(loanApplicationId)) as Collateral[];
	let collateralList: Collateral[] = [];
	collateral.forEach((collateral) => {
		if (relatedEntities.includes(collateral.customerId)) collateralList.push(collateral);
	});
	return collateralList;
}

export async function getCollateralForEntities(relatedEntities: number[], loanApplicationId: number, isLender: boolean): Promise<Collateral[]> {
	if (relatedEntities.length < 1) return [];
	let collateralList: Collateral[] = [];
	if (isLender) {
		const service = AutoFactory.get(OnlinePortalCollateralService);
		collateralList = (await service.getByRelatedEntities(relatedEntities)) as Collateral[];
	}
	else {
		collateralList = await getCollateralOnApplicationForEntities(loanApplicationId, relatedEntities);
	}
	return collateralList.filter((collateral) => {return !collateral.isDeleted});
}
