import { keyBy } from 'lodash';
import { ObjectPropertyValuesWithRoleType } from '@sageworks/dynamic-forms';
import { AutoFactory, DataObject, ObjectPropertyValues, OnlinePortalObjectsByPropertyValuesService } from '@sageworks/jpi';
import { defineActions } from 'direct-vuex';
import { actionContext } from '../store-helper';
import { LoadDataObjectParams, RemoveDataObjectParams, SaveDataObjectParams } from './persist-action-params';
import { FormDataModelConverter } from '../../../utils/form-data-model-converter';
import { DataObjectFetchHelper } from '../helpers';

export default defineActions({
	async saveDataObject(context, { dataObjectType, dataObject }: SaveDataObjectParams): Promise<any> {
		const { commit } = actionContext(context);
		const dataObjectService = AutoFactory.get(OnlinePortalObjectsByPropertyValuesService);
		const { id, dataFieldValues } = dataObject;

		let response: ObjectPropertyValues;
		if (id && id > 0) {
			if ((dataObject as ObjectPropertyValuesWithRoleType).canEditRoleEntity === false) {
				response = dataObject;
			} else {
				response = await dataObjectService.update(dataObjectType, id, { dataFieldValues });
			}
		} else {
			response = await dataObjectService.createSingle(dataObjectType, { dataFieldValues });
		}

		commit.UPSERT_DATA_OBJECT({ type: dataObjectType, dataObject: FormDataModelConverter.convertObjectPropertyValuesToDataObject(response) });

		return response;
	},
	async removeDataObject(context, { dataObjectType, id }: RemoveDataObjectParams): Promise<void> {
		const { commit } = actionContext(context);
		const dataObjectService = AutoFactory.get(OnlinePortalObjectsByPropertyValuesService);

		if (id && id > 0) {
			await dataObjectService._delete(dataObjectType, id);
			commit.REMOVE_DATA_OBJECT({ type: dataObjectType, id });
		}
	},
	async loadDataObjects(context, { ids, type, dataFieldMetadata }: LoadDataObjectParams): Promise<DataObject[]> {
		const {
			state: { renderData = {} },
			commit
		} = actionContext(context);

		let dataObjects = (renderData?.formData?.dataObjects ?? {})[type] ?? [];
		const currentDataObjects = keyBy(dataObjects, 'rowId');

		// Only fetch objects that are not already loaded
		const filteredIds = ids.filter(id => !currentDataObjects[id]);
		const retrievedDataObjects = await DataObjectFetchHelper.loadDataObjects(filteredIds, type, dataFieldMetadata);

		retrievedDataObjects.forEach(dataObject => commit.UPSERT_DATA_OBJECT({ type, dataObject }));

		dataObjects = dataObjects.concat(retrievedDataObjects);

		return dataObjects;
	}
});
