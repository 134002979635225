
import { Component, Mixins } from 'vue-property-decorator';
import { required, email } from 'vuelidate/lib/validators';
import { ScaleMixin, RouteMixin } from '@/mixins';
import { OnlinePortalUser, OnlinePortalAuthRequest } from '@sageworks/jpi';

@Component({
	validations: {
		email: {
			required,
			email
		},
		secret: {
			required
		}
	}
})
export default class ChangeEmail extends Mixins(ScaleMixin, RouteMixin) {
	private email?: string = '';
	private secret?: string = '';
	private submitting: boolean = false;
	private saveFeedback: string | null = null;
	private validationMessages: Map<string, string> = new Map([
		['email', 'Please enter a valid email address'],
		['secret', 'Please enter your password']
	]);
	private showValidation: boolean = false;

	private async created() {
		await this.directStore.dispatch.User.loadCurrentUser();
		this.email = this.directStore.state.User.user!.email || '';
	}

	private validationState(fieldName: string) {
		if (!this.showValidation) {
			return null;
		} else if (this.$v[fieldName].$invalid) {
			return false;
		}
		return true;
	}

	private async submit(): Promise<void> {
		if (this.$v.$invalid) {
			this.showValidation = true;
			return;
		}

		this.submitting = true;
		try {
			if (await this.confirmSecret()) {
				await this.directStore.dispatch.User.patchCurrentUser({
					email: this.email
				} as OnlinePortalUser);
				this.showValidation = true;
				this.saveFeedback = 'Email changed!';
			} else {
				this.showValidation = false;
				this.saveFeedback = 'Invalid email.';
			}
		} catch (err) {
			this.showValidation = false;
			this.saveFeedback = 'Unable to save these changes. The new email you selected may already be linked to another account.';
		}
		this.submitting = false;
	}

	private async confirmSecret(): Promise<boolean> {
		try {
			const token = await this.directStore.dispatch.Authentication.login({
				username: this.directStore.state.User.user!.email || '',
				secret: this.secret
			} as OnlinePortalAuthRequest);
			return !!token;
		} catch {
			return false;
		}
	}
}
