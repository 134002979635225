export enum FinancialSubaccountParent {
	PersonalIncome = 'PersonalIncome',
	OtherIncome = 'OtherIncome',
	RentPayment = 'RentPayment',
	TaxesPaid = 'TaxesPaid',
	PersonalExpenses = 'PersonalExpenses',
	OtherShortTermLoans = 'OtherShortTermLoans',
	OtherLongTermLoans = 'OtherLongTermLoans',
	Taxes = 'Taxes',
	Cash = 'Cash',
	OtherLiquidAssets = 'OtherLiquidAssets',
	Stocks = 'Stocks',
	MutualFunds = 'MutualFunds',
	Home = 'Home',
	VacationProperties = 'VacationProperties',
	LifeInsurance = 'LifeInsurance',
	Retirement = 'Retirement',
	Jewelry = 'Jewelry',
	Furniture = 'Furniture',
	OtherAssets = 'OtherAssets'
}
