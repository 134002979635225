module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div>\n	';
 if (ctx.header) { ;
__p += '\n		<header class="row modal-header">\n			' +
((__t = ( ctx.header )) == null ? '' : __t) +
'\n		</header>\n	';
 } ;
__p += '\n\n	';
 if (ctx.body) { ;
__p += '\n		<div ref="subComponentTemplateSlot" class="modal-body">\n			' +
((__t = ( ctx.body )) == null ? '' : __t) +
'\n		</div>\n	';
 } ;
__p += '\n\n\n	';
 if (ctx.footer) { ;
__p += '\n		<footer class="modal-footer d-flex justify-content-start">\n			' +
((__t = ( ctx.footer )) == null ? '' : __t) +
'\n		</footer>\n	';
 } ;
__p += '\n</div>\n';
return __p
}