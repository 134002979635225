import BaseRepeaterComponent from '../../base/base-repeater-widget/base-repeater-widget';
import editForm from './financial-subaccount.form';
import { CustomComponentType, CustomComponentLabel } from '../../../../enums/custom-component-type';
import { forcedLoadSubForm, formatDataFieldIdKey } from '../../simple-inputs/extended-field-helper';
import { LocateParentHelper } from '../../../../utils';
import Component from 'formiojs/components/_classes/component/Component';
import { EditRowState, TemplateDataField } from '../../../../enums';
import { Utils as FormioUtils } from 'formiojs';
import ExtendedCurrency from '../../simple-inputs/extended-currency/extended-currency';

export class FinancialSubaccount extends BaseRepeaterComponent {
	protected descriptionPropertyKey = TemplateDataField.SubaccountName;
	protected valuePropertyKey = TemplateDataField.SubaccountValue;
	private closestCustomerParent: Component | undefined;

	protected get customerId(): number | undefined {
		return this.dataValue?.data?.parentLoanRoleId ?? this.closestCustomerParent?.dataValue?.data?.id;
	}

	public get submitMetadata() {
		return {
			customerId: this.customerId
		};
	}

	// @ts-ignore
	public get defaultValue() {
		return [];
	}

	public get inlineEditMode() {
		return true;
	}

	static schema(...extend: any) {
		return BaseRepeaterComponent.schema(
			{
				label: CustomComponentLabel()[CustomComponentType.financialSubaccount],
				type: CustomComponentType.financialSubaccount,
				key: CustomComponentType.financialSubaccount
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: CustomComponentLabel()[CustomComponentType.financialSubaccount],
			group: '',
			weight: 10,
			schema: FinancialSubaccount.schema()
		};
	}
	loadSubForm() {
		return forcedLoadSubForm(this);
	}

	render(children: any, topLevel?: boolean): string {
		// `closestCustomerParent` is resolved here since data is not added to the component at the time of init()
		if (!this.closestCustomerParent) {
			this.closestCustomerParent = this.component.customerParent ?? LocateParentHelper.locateClosestCustomerParent(this);
			// We update components on `component` (the schema) since it's what's used to generate the children for editRows
			this.component.components = this.componentComponents;
			// We need to update each row's children, since the submitMetadata won't be evaluated before the init() is
			// 	ran which is the only time the editRows are created
			this.editRows.forEach((row, index) => {
				row.components = this.createRowComponents(row.data, index);
			});
		}

		return super.render(children, topLevel);
	}

	getDescriptionKeys(): { [key: string]: string } {
		const descriptionKey = this.options?.contextData?.templateDataFieldMappings?.[this.descriptionPropertyKey];
		const valueKey = this.options?.contextData?.templateDataFieldMappings?.[this.valuePropertyKey];

		return { descriptionKey, valueKey };
	}

	rowDescription(row: any, rowIndex: number): string {
		try {
			if (!this.rowDescriptionSubformLoaded(rowIndex)) {
				return 'Loading...';
			}

			const { descriptionKey, valueKey } = this.getDescriptionKeys();

			const description = this.getFormattedDescription(descriptionKey, row);
			const value = this.getFormattedValueDescription(rowIndex, valueKey);

			if (description == null || value == null) {
				throw new Error(this.rowDescriptionError);
			}

			return `${description} | ${value}`;
		} catch (error) {
			return 'Unable to load preview';
		}
	}

	protected getFormattedDescription(descriptionDatafieldId: string, row: any) {
		return row.data?.[formatDataFieldIdKey(descriptionDatafieldId)];
	}

	protected getFormattedValueDescription(rowIndex: number, dataFieldId: string) {
		const components = this.editRows[rowIndex]?.components;

		const valueField: any = FormioUtils.searchComponents(components[0].subForm.component.components, { dataFieldId: dataFieldId })[0];
		if (valueField == null) {
			throw new Error('Cannot find value field');
		}
		const currencyValue = new ExtendedCurrency(valueField, {}, components[0].data.form.data);

		return currencyValue.formatValue(currencyValue.getValueAsString(currencyValue.dataValue));
	}

	public checkComponentValidity(data: any, dirty: boolean, row: any, options: any = {}) {
		const superValid = super.checkComponentValidity(data, dirty, row, options);

		if (this.minLength > 0 && this.editRows.length < this.minLength) {
			this.setCustomValidity(this.t(`At least ${this.minLength} row is required`), dirty);
			return false;
		}

		return superValid;
	}

	setValue(value: any, flags: any = {}) {
		const superSetValue = super.setValue(value, flags);
		this.dataValue.forEach((row: any, rowIndex: number) => {
			if (row?.form?.data?.id == null) {
				this.editRows[rowIndex].state = EditRowState.New;
			}
		});
		return superSetValue;
	}
}
