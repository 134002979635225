
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { StartNewApplicationEnums } from '@/enums/start-new-application-options';
import { FeatureFlagging, AutoFactory, PrelimAccountOpeningSettingsService, PrelimAccountCredentialInternal } from '@sageworks/jpi';

export default Vue.extend({
	name: 'StartNewApplicationModal',
	components: {
		FontAwesomeIcon
	},
	data() {
		return {
			prelimFFEnabled: false,
			credentials: {} as PrelimAccountCredentialInternal
		};
	},
	computed: {},
	async mounted() {
		this.prelimFFEnabled = await this.directStore.dispatch.FeatureFlag.fetchIsFeatureFlagActive(FeatureFlagging.FeatureFlagEnum.EnablePrelimAccountOpening);
		await this.getCredentials();
	},
	methods: {
		async selectOption(option: any) {
			if (this.prelimFFEnabled) {
				switch (option) {
					case StartNewApplicationEnums.OPENACCOUNT: {
						this.openURLInCurrentPage();
						break;
					}
					case StartNewApplicationEnums.REQUESTLOAN:
						this.$emit('requestLoan');
						break;
					case StartNewApplicationEnums.BOTH:
						this.openURLInCurrentPage();
						break;
				}
			}
		},
		openURLInCurrentPage() {
			if (this.credentials.apiUrl) {
				window.open(this.credentials.apiUrl, '_self');
			} else {
				this.showToast('Unable to open URL', 'Verify API URL');
			}
		},
		async getCredentials() {
			try {
				const results = await AutoFactory.get(PrelimAccountOpeningSettingsService).getCredentials();

				this.credentials = results;
			} catch (err) {
				this.showToast('Error:', 'Please check your Prelim URL on the Third Party Settings page');
			}
		},
		showToast(title: string, message: string) {
			this.$root.$bvToast.toast(message, {
				title: title,
				solid: true,
				autoHideDelay: 3000,
				toaster: 'b-toaster-bottom-right'
			});
		}
	}
});
