import { CustomComponentType, LiabilityTypeOption } from '../../../enums';
import { FinancialSubaccountSelectOptions } from './financial-subaccount-type-select-options';

export class PersonalLiabilityTypeSelectOptions extends FinancialSubaccountSelectOptions {
	static schema(...extend: any) {
		return super.schema(
			{
				type: CustomComponentType.personalLiabilityTypeSelectOptions,
			},
			...extend
		);
	}

	static get builderInfo() {
		return {
			weight: 10,
			schema: PersonalLiabilityTypeSelectOptions.schema({})
		};
	}

	protected async getApiOptions(): Promise<any> {
		return {
			items: [
				{
					label: LiabilityTypeOption.AccountsPayable,
					value: LiabilityTypeOption.AccountsPayable
				},
				{
					label: LiabilityTypeOption.TaxesOwed,
					value: LiabilityTypeOption.TaxesOwed
				},
				{
					label: LiabilityTypeOption.Other,
					value: LiabilityTypeOption.Other
				}
			]
		};
	}
}
