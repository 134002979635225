import Component from 'formiojs/components/_classes/component/Component';
import { IPopupBodyComponent } from './IPopupBodyComponent';

export default class PopupWrapper extends Component {
	private popupContent!: IPopupBodyComponent;
	public actionCompleted!: Promise<any>;
	private actionCompletedResolve!: (result: any) => any;

	static schema(...extend: any) {
		return Component.schema(
			{
				label: 'PopupWrapper',
				type: 'PopupWrapper',
				key: 'PopupWrapperComponent'
			},
			...extend
		);
	}

	public constructor(schema: any, options: any, data: any, component: IPopupBodyComponent) {
		super(PopupWrapper.schema(schema), options, data);

		this.popupContent = component;

		this.actionCompleted = new Promise(resolve => (this.actionCompletedResolve = resolve));
	}

	public attach(e: Element) {
		return this.popupContent.attach(e);
	}

	public render() {
		return this.popupContent.render();
	}

	public async showPopup() {
		const { wrapper, dialog } = this.createPopup(this, 'wrapperName', '', {}, this.popupContent.preClose);

		let result: any;
		// Added post close logic here so it also gets called when user clicks X
		// button or clicks outside the overlay
		this.addEventListener(dialog, 'close', () => {
			this.actionCompletedResolve(result);

			// Need to redraw to render the authorization audit message and toggle buttons
			this.redraw();
		});

		await this.popupContent.build(wrapper);
		dialog.refs.dialogContents.focus();

		result = await this.popupContent.resultPromise;
		dialog.close();

		return result;
	}

	protected createPopup(component: Component, wrapperRefName: string, contents = '', refs = {}, canClosePromise?: () => Promise<void>) {
		// Setup wrapper element for modal
		const wrapper = component.ce('div', { ref: wrapperRefName }) as any;
		wrapper.innerHTML = contents;

		// Setup refs for wrapper
		(wrapper as any).refs = {};
		component.loadRefs.call(wrapper, wrapper, refs);

		const dialog = component.createModal(wrapper, undefined, canClosePromise) as any;

		// Reset some dialog styles
		dialog.refs.dialogContents.style.minHeight = 'auto';
		dialog.refs.dialogContents.tabIndex = '0';

		const close = (event: any) => {
			event.preventDefault();
			dialog.close();
		};

		return { wrapper, dialog, close };
	}
}
