import { CustomComponentType, FinancialSubaccountParent } from '../../../enums';
import { FinancialSubaccountSelectOptions } from './financial-subaccount-type-select-options';

export class PersonalExpenseTypeSelectOptions extends FinancialSubaccountSelectOptions {
	static schema(...extend: any) {
		return super.schema(
			{
				type: CustomComponentType.personalExpenseTypeSelectOptions,
			},
			...extend
		);
	}

	static get builderInfo() {
		return {
			weight: 10,
			schema: PersonalExpenseTypeSelectOptions.schema({})
		};
	}

	protected async getApiOptions(): Promise<any> {
		return {
			items: [
				{
					label: 'Yearly Rent Payments',
					value: FinancialSubaccountParent.RentPayment
				},
				{
					label: 'Taxes Paid',
					value: FinancialSubaccountParent.TaxesPaid
				},
				{
					label: 'Personal Expense',
					value: FinancialSubaccountParent.PersonalExpenses
				}
			]
		};
	}
}
