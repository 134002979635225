
import { Component, Mixins } from 'vue-property-decorator';
import { required, email } from 'vuelidate/lib/validators';
import { ScaleMixin, RouteMixin } from '@/mixins';

@Component({
	validations: {
		email: {
			required,
			email
		}
	}
})
export default class ForgotPassword extends Mixins(ScaleMixin, RouteMixin) {
	private email: string | null = null;
	private invalidFeedback: string = 'Please enter a valid email address';
	private showValidation: boolean = false;
	private showSuccessMessage: boolean = false;

	private showEmailSendingErrorMessage: boolean = false;
	private emailSendingFailedErrorMessage: string =
		'There was an error requesting a password reset email. If this continues to occur, please contact customer support.';

	private mounted() {
		this.email = this.directStore.state.Login.userEmail || '';
	}

	private beforeDestroy() {
		if (this.email === '' || !this.$v.email.invalid) {
			this.directStore.dispatch.Login.setUserEmail(this.email);
		}
	}

	private async submit(): Promise<void> {
		this.showValidation = true;
		this.showEmailSendingErrorMessage = false;
		this.directStore.dispatch.Login.setUserEmail(this.email);
		try {
			await this.directStore.dispatch.Login.sendPasswordResetEmail();
		} catch (err) {
			this.showEmailSendingErrorMessage = true;
		} finally {
			this.showSuccessMessage = true;
			this.directStore.dispatch.Login.setUserEmail(null);
		}
	}

	get emailValidationState() {
		if (!this.showValidation) {
			return null;
		} else if (this.$v.email.$invalid) {
			return false;
		}
		return true;
	}
}
