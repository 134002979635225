
import { Component, Mixins } from 'vue-property-decorator';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import { ScaleMixin, RouteMixin } from '@/mixins';
import { ValidationUtils } from '@/utils';
import { OnlinePortalUserPasswordResetRequest } from '@sageworks/jpi';

@Component({
	components: {
		FontAwesomeIcon
	},
	validations: {
		password: {
			required,
			minLength: minLength(8),
			containsUppercase: ValidationUtils.containsUppercase,
			containsAlpha: ValidationUtils.containsAlpha,
			containsNumeric: ValidationUtils.containsNumeric
		},
		confirmation: {
			required,
			sameAs: sameAs('password')
		}
	}
})
export default class SetAccountPassword extends Mixins(ScaleMixin, RouteMixin) {
	private password: string = '';
	private confirmation: string = '';
	private showValidation: boolean = false;
	private showErrorMessage: boolean = false;
	private validationMessages: Map<string, string> = new Map([
		['password', 'Password must meet all requirements'],
		['confirmation', 'Passwords must match']
	]);
	private errorMessage: string =
		'Error when creating password for this account. Please contact your institution if you are having difficulty with password creation';
	private submitting = false;

	private showSuccessMessage = false;

	private clearValidation() {
		this.showValidation = false;
		this.showErrorMessage = false;
	}

	get userEmail() {
		return this.directStore.state.Login.userEmail;
	}

	private validationState(fieldName: string) {
		if (!this.showValidation || this.showErrorMessage) {
			return null;
		} else if (this.$v[fieldName].$invalid) {
			return false;
		}
		return true;
	}

	private async submit(): Promise<void> {
		this.showValidation = true;
		this.submitting = true;
		if (this.$v.$invalid) {
			this.submitting = false;
			return;
		}
		try {
			let resetRequest = {
				newSecret: this.password,
				authToken: this.directStore.state.Authentication.userToken
			} as OnlinePortalUserPasswordResetRequest;
			await this.directStore.dispatch.Login.resetUserPassword(resetRequest);
			await this.directStore.commit.Authentication.CLEAR_USER_TOKEN();
		} catch (err) {
			this.showErrorMessage = true;
			this.submitting = false;
			this.showSuccessMessage = false;
		} finally {
			this.showSuccessMessage = true;
			this.submitting = false;
		}
	}
}
