import editForm from './expense.form';
import { CustomComponentType, CustomComponentLabel } from '../../../../enums/custom-component-type';
import { FinancialSubaccount } from '../financial-subaccount/financial-subaccount';

export class ExpenseWidget extends FinancialSubaccount {
	static schema(...extend: any) {
		return FinancialSubaccount.schema(
			{
				label: CustomComponentLabel()[CustomComponentType.expense],
				type: CustomComponentType.expense,
				key: CustomComponentType.expense,
				addAnother: 'Add Expense'
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: CustomComponentLabel()[CustomComponentType.expense],
			group: '',
			weight: 10,
			schema: ExpenseWidget.schema()
		};
	}
}
