import { CustomComponentType } from '../../../enums/custom-component-type';
import { AutoFactory, DataFieldsService, PropertyOptionsService, CollectionResponseDataFieldOption, DataFieldOption } from '@sageworks/jpi';
import EditableApiSelectOptions from '../../configuration-components/editable-api-select-options/editable-api-select-options';
import { TemplateDataField } from '../../../enums';

export default class VehicleStateSelectOptions extends EditableApiSelectOptions {
	static schema(...extend: any) {
		return super.schema(
			{
				type: CustomComponentType.vehicleStateSelectOptions
			},
			...extend
		);
	}

	static get builderInfo() {
		return {
			weight: 10,
			schema: VehicleStateSelectOptions.schema({})
		};
	}

	static get defaultComponents() {
		return [
			VehicleStateSelectOptions.getEnabledComponent(false),
			VehicleStateSelectOptions.getLabelComponent(false),
			VehicleStateSelectOptions.getFormattedValueComponent(true),
			{
				...VehicleStateSelectOptions.getValueComponent(true),
				hidden: true
			}
		];
	}

	// Needed so the value shown to the user is the default label instead of the ID assocaited to the state
	protected static getFormattedValueComponent(disabled: boolean) {
		return {
			label: 'Value',
			key: 'formattedValue',
			input: true,
			type: 'textfield',
			disabled: disabled
		};
	}

	protected async getApiOptions(): Promise<CollectionResponseDataFieldOption> {
		const dataFieldService: DataFieldsService = AutoFactory.get(DataFieldsService);
		const propertyOptionsService: PropertyOptionsService = AutoFactory.get(PropertyOptionsService);
		const collectionResponse = await dataFieldService.getPaged(1, 1, undefined, undefined, undefined, [TemplateDataField.VehicleState]);
		const dataField = collectionResponse?.items?.pop();
		return await propertyOptionsService.getSingle(dataField?.id ?? 0);
	}

	protected createOptionFromItem(item: any, enabledForConsumers: boolean = true) {
		const option = super.createOptionFromItem(item, enabledForConsumers);
		option.formattedValue = option.label;
		return option;
	}

	protected getOptionLabelForItem(item: DataFieldOption): string {
		return item.label ?? '';
	}

	protected getOptionValueForItem(item: DataFieldOption): string {
		return (item.value as unknown) as string;
	}
}
