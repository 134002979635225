import { DataFieldConvertStrategy } from './data-field-convert-strategy';
import { DataField } from '@sageworks/jpi';
import { NoopDataFieldConverter } from './converter-strategies';

export interface DataFieldComponentConverterOptions {
	forcedRequiredFields?: number[];
}

export class DataFieldComponentConverter {
	constructor(private availableStrategies: DataFieldConvertStrategy[], private options: DataFieldComponentConverterOptions = {}) {
		options.forcedRequiredFields = options.forcedRequiredFields ?? [];
	}

	public convert(dataField: DataField) {
		let properties: any = {
			label: dataField.name,
			dataFieldAlias: dataField.alias,
			originalLabel: dataField.name,
			templateDataFieldId: dataField.templateDataFieldId,
			dataFieldId: dataField.id,
			forceRequired: this.options.forcedRequiredFields?.some(id => dataField.templateDataFieldId === id) ?? false,
			clearOnHide: false
		};

		const convertStrategy = this.availableStrategies.find(strategy => strategy.isFieldValid(dataField)) ?? new NoopDataFieldConverter();
		return convertStrategy.convert(dataField, properties);
	}
}
