import { IAPIConfiguration, SetupApi, AutoFactory, IAuthConfiguration, SetupAuth } from '@sageworks/jpi';
import * as UrlUtils from './UrlUtils';
import * as LocalStorageUtils from './LocalStorageUtils';
import * as DevToolUtils from './DevToolUtils';
import { LocalStorageKeys } from '@/enums';
import { Environment } from '@sageworks/core-logic/src/enums/environment';
import Vue from 'vue';

const developmentSubdomains = ['localhost', Environment.Local, Environment.Staging];

export async function loadAppConfigJSON(): Promise<string> {
	var targetURL = window.location.origin + '/app-config.json';
	return new Promise((resolve, reject) => {
		var xobj = new XMLHttpRequest();
		xobj.overrideMimeType('application/json');
		xobj.open('GET', targetURL, true);
		xobj.onload = () => {
			if (xobj.readyState === 4 && xobj.status === 200) {
				resolve(xobj.responseText);
			}
			reject();
		};
		xobj.send(null);
	});
}

export function configureApi() {
	let apiConfiguration: IAPIConfiguration;
	if (AutoFactory.isBound('IAPIConfiguration')) {
		apiConfiguration = AutoFactory.get('IAPIConfiguration');
	} else {
		apiConfiguration = {} as IAPIConfiguration;
		SetupApi(apiConfiguration);
	}

	apiConfiguration.basePath = UrlUtils.getAnalystApiUrl();
	apiConfiguration.username = process.env.VUE_APP_API_USERNAME;
	apiConfiguration.password = process.env.VUE_APP_API_SECRET;
}

export function configureAuth() {
	let authConfiguration: IAuthConfiguration;
	if (AutoFactory.isBound('IAuthConfiguration')) {
		authConfiguration = AutoFactory.get('IAuthConfiguration');
	} else {
		authConfiguration = {} as IAuthConfiguration;
		SetupAuth(authConfiguration);
	}

	authConfiguration.basePath = UrlUtils.getAuthUrl();
	authConfiguration.clientId = process.env.VUE_APP_API_USERNAME;
	authConfiguration.clientSecret = process.env.VUE_APP_API_SECRET;
	authConfiguration.grantType = 'online_portal_token';
	authConfiguration.subdomain = UrlUtils.getOnlinePortalSubdomain();

	// JPI/src/token.service.ts will use this cookieName to retrieve our GrantToken from localStorage.
	authConfiguration.cookieName = LocalStorageUtils.getUniqueStorageKey(LocalStorageKeys.userToken);
}

export function enableDevtoolsIfDevEnvironment() {
	const isDevelopmentEnvironment = developmentSubdomains.includes(UrlUtils.getWindowLocationSubdomain());
	Vue.config.devtools = isDevelopmentEnvironment;

	if (isDevelopmentEnvironment) {
		Object.defineProperty(window, 'shareableLocalLink', {
			get: DevToolUtils.getShareableLocalLink
		});
		Object.defineProperty(window, 'shareableStagingLink', {
			get: DevToolUtils.getShareableStagingLink
		});
	}
}
