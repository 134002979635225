import { DataField } from '@sageworks/jpi';
import { TemplateDataField } from '../../../../../enums';
import { DataFieldConvertStrategy } from '../../data-field-convert-strategy';
import { DropdownDataFieldConverter } from '../dropdown/dropdown-data-field-converter';

export class LoanCodesDataFieldConverter implements DataFieldConvertStrategy {
	private readonly allowedFields = new Set<number>([
		TemplateDataField.ProductCode,
		TemplateDataField.LoanTypeCode,
		TemplateDataField.PurposeCode,
		TemplateDataField.CollateralCode,
		TemplateDataField.CallCode
	]);

	private readonly dropdownDataFieldConverter = new DropdownDataFieldConverter();

	isFieldValid(dataField: DataField): boolean {
		return this.allowedFields.has(dataField.templateDataFieldId!);
	}

	convert(dataField: DataField, currentProperties: any) {
		const properties = { ...currentProperties };
		const convertedDataFieldProperties = this.dropdownDataFieldConverter.convert(dataField, properties);

		convertedDataFieldProperties.shouldSortOptionsForField = true;

		return convertedDataFieldProperties;
	}
}
