
import Vue, { PropType } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { DocumentRequestTypes, OnlinePortalApplicationDocumentRequest } from '../../models';
import { DocumentDetailsLenderFeatures } from '../lender-features/document-details';
import { ApplicationDocumentRequest } from '@sageworks/jpi';

export default Vue.extend({
	name: 'DocumentUploadHeader',
	components: { FontAwesomeIcon, DocumentDetailsLenderFeatures },
	props: {
		documentRequest: Object as PropType<OnlinePortalApplicationDocumentRequest>,
			adobeTemplateStatus: String,
	},
	computed: {
		isLenderView(): boolean {
			return this.directStore.getters.User.isLender;
		},
		showLenderFeatures(): boolean {
			const { applicationDocumentRequestSettingsId, dateRequested } = this.documentRequest;
			const hasRequestSettingId = applicationDocumentRequestSettingsId != null && applicationDocumentRequestSettingsId > 0;

			return this.isLenderView && (hasRequestSettingId || this.isAdditionalDocument || !!dateRequested);
		},
		isApplicationDocumentRequest(): boolean {
			return this.documentRequest.documentRequestType === DocumentRequestTypes.LoanApplicationDocumentRequest;
		},
		isOpenApplicationDocumentRequest(): boolean {
			return (
				!this.documentRequest.dateUploaded &&
				!this.documentRequest.dateWaived &&
				!(this.documentRequest as ApplicationDocumentRequest).waivedReason &&
				!(this.documentRequest as ApplicationDocumentRequest).waivedByLoanOfficerId &&
				!(this.documentRequest as ApplicationDocumentRequest).notUploadedReason &&
				this.adobeTemplateStatus !== 'SIGNED'
			);
		},
		isAdditionalDocument(): boolean {
			return !this.documentRequest.id || this.documentRequest.id < 0 || !this.documentRequest.dateRequested;
		},
	},
	methods: {
	}
});
