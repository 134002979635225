import { StoreType } from '../../../../store';
import { DataObject, ObjectPropertyValuesWithRoleType, SubmitPayload, TemplateDataField } from '@sageworks/dynamic-forms';

export const createPfsSubmissionDataObject = ({ submission, metadata }: SubmitPayload, pfsCustomerDataFieldId: number): ObjectPropertyValuesWithRoleType  => {
	const { customerId } = metadata ?? {};

	if (customerId == null || customerId <= 0) {
		throw new Error('Customer Id was not set in metadata or invalid');
	}

	return {
		id: submission.data?.id ?? undefined,
		dataFieldValues: [{ id: pfsCustomerDataFieldId, value: customerId as any }]
	};
};

export const getOrForgePfs = async (payload: SubmitPayload, loanMappingId: number, store: StoreType): Promise<number | null> => {
	const dataObjects = store.state.MultiLoanApplicationForm.renderData?.formData?.dataObjects;
	if (dataObjects == null) {
		throw new Error('Unable to load dataObjects');
	}


	const pfsCustomerDataField = store.state.LoanApplicationMetadata.presetDataFieldsByDataObject[DataObject.TypeEnum.PersonalFinancial]
		?.find(x => x.templateDataFieldId === TemplateDataField.PfsCustomer);

	if (!pfsCustomerDataField?.id) {
		return null;
	}

	const existingPfsObject = dataObjects[DataObject.TypeEnum.PersonalFinancial].find(x =>
		x.values?.find(val => val.id === pfsCustomerDataField.id && val.value === payload.metadata?.customerId)
	);

	if (existingPfsObject != null) {
		return existingPfsObject.rowId!;
	}

	const dataObject = createPfsSubmissionDataObject(payload, pfsCustomerDataField.id);
	const savedObject = await store.dispatch.MultiLoanApplicationForm.saveDataObject({
		dataObjectType: DataObject.TypeEnum.PersonalFinancial,
		dataObject
	});

	await store.dispatch.MultiLoanApplicationForm.addPFS({ id: savedObject.id, loanMappingId: loanMappingId });
	return savedObject.id;
};
