
import Vue, { PropType } from 'vue';
import { BFormDatepicker } from 'bootstrap-vue';
import { DataFieldOption } from '@sageworks/jpi';
import { Multiselect } from 'vue-multiselect';
import { TuxInputPercent } from '@tuxedo/components/src/components';
import { OwnerRowData } from '../../../models';

export default Vue.extend({
	name: 'BeneficialOwnershipEditForm',
	components: {
		Multiselect,
		TuxInputPercent,
		// TODO: Remove use of BFormDatepicker when possible
		BFormDatepicker  
	},
	props: {
		owner: {
			type: Object as PropType<OwnerRowData>,
			required: true
		},
		roleOptions: {
			type: Array as PropType<DataFieldOption[]>,
			default: () => [],
		},
		loading: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			formData: {
				roleType: undefined as { value: string, label: string } | undefined,
				ownershipPercent: undefined as number | undefined,
				lastUpdated: undefined as string | undefined,
			} as Pick<OwnerRowData, 'id' | 'ownerName' | 'ownershipPercent' | 'lastUpdated'> & { roleType: { value: string, label: string}}
		};
	},
	watch: {
		owner: {
			handler: function() {
				this.formData = {
					// "as any" is needed since roleType is the wrong type, but we fix it in the next line
					...this.owner as any,
					roleType: this.roleOptions.find(x => x.value === this.owner.roleType)
				};
			},
			immediate: true
		}
	},
	methods: {
		save() {
			const updatedOwner: OwnerRowData = {
				...this.owner,
				// "as any" is needed since roleType is the wrong type, but we fix it in the next line
				...this.formData as any,
				roleType: this.formData.roleType?.value
			};

			this.$emit('save', updatedOwner);
		}
	}
});
