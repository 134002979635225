import baseEditForm from 'formiojs/components/textfield/TextField.form';

import ForceRequiredField from '../../../configuration-components/force-required/force-required';
import OriginalLabel from '../../../configuration-components/base-widget-configuration/display/original-label';
import BaseEditConditionalComponents from '../../../configuration-components/base-widget-configuration/base.edit.conditional.components';
import { BaseEditData } from '../../../configuration-components/base-widget-configuration/data/base.edit.data';
import TextFieldComponents from './extended-text-field.form.data';
import LenderOnly from '../../../configuration-components/base-widget-configuration/display/lender-only-field-checkbox';
import DisableMultipleMask from '../../../configuration-components/base-widget-configuration/display/disable-multiple-mask';

// eslint-disable-next-line max-lines-per-function
export default function(...extend: any[]) {
	return baseEditForm(
		[
			{
				key: 'api',
				ignore: true
			},
			{
				key: 'display',
				components: [OriginalLabel, LenderOnly, DisableMultipleMask]
			},
			new BaseEditData().getTab({
				components: {
					defaultComponents: TextFieldComponents
				}
			}),
			{
				key: 'validation',
				components: ForceRequiredField
			},
			{
				key: 'conditional',
				components: BaseEditConditionalComponents
			}
		],

		...extend
	);
}
