
import BaseFormBuilder from '../base-form-builder/base-form-builder.vue';
import { FormioBuilderOptionsSchema, FormioBuilderGroupSchema } from '../../formio-interfaces/FormioBuilderOptionsSchema';
import { OnlinePortalWidgetBuilderOptionsSchema } from '../../formio-interfaces/OnlinePortalWidgetBuilderOptionsSchema';
import { ApplicationTemplate, FeatureFlagging } from '@sageworks/jpi';
import { BuilderGroupFactory } from '../../utils/builder';
import { getEntitySubwidgets, getWidgetGroupsForEntityInfo } from './entity-subwidget-helper';
import { getWidgetType, getAppType, getApplicationTemplateId } from '../../utils/form-path-utils';
import { ValidationBuilder } from '../../utils/validation-builder';
import { TemplateDataField, CustomComponentType, CustomBuilderGroups, CustomComponentLabel, FinancialSubaccountCustomComponentTypes } from '../../enums';
import { FormPathUtils } from '../../utils';

export default BaseFormBuilder.extend({
	name: 'WidgetBuilder',
	computed: {
		builder(): FormioBuilderOptionsSchema {
			const builderGroupFactory = new BuilderGroupFactory(this.properties ?? [], this.widgetType, this.applicationTemplate);
			const builder = {
				// TODO: Move getEntitySubwidgets and getWidgetGroupsForEntityInfo into BuilderGroupFactory
				...getEntitySubwidgets(this.widgetType, this.appType, this.templateDataFieldMapping, this.properties || [], this.hasPlaid),
				...getWidgetGroupsForEntityInfo(this.widgetType, this.appType, this.templateDataFieldMapping, this.properties || [], this.hasPlaid),
				[CustomBuilderGroups.udFields]: builderGroupFactory.createCustomFieldComponentBuilderGroup(),
				[CustomBuilderGroups.defaultFields]: builderGroupFactory.createDefaultFieldComponentBuilderGroup(
					undefined,
					undefined,
					this.hasProduct('fairLending1071') ?? false
				)
			} as OnlinePortalWidgetBuilderOptionsSchema;

			Object.keys(builder)
				.map(groupKey => (builder as any)[groupKey] as FormioBuilderGroupSchema)
				.sort((groupA, groupB) => groupA.weight - groupB.weight)[0].default = true;
			return builder;
		},
		additionalOptions(): Object {
			return {
				customComponentType: this.widgetType
			};
		},
		applicationTemplate(): ApplicationTemplate {
			return {
				id: this.applicationTemplateId,
				type: this.appType
			} as ApplicationTemplate;
		},
		appType() {
			return getAppType(this.formPath);
		},
		widgetType(): CustomComponentType | null {
			return getWidgetType(this.formPath);
		},
		applicationTemplateId() {
			return getApplicationTemplateId(this.formPath);
		},
		hasPlaid() {
			return this.featureFlags.some(x => x === FeatureFlagging.FeatureFlagEnum.EnablePlaidInDya) && this.hasProduct('plaid') && this.plaidEnabled;
		}
	},
	methods: {
		validateForm(): string[] {
			if (this.form == null) {
				return [];
			}

			const validationBuilder = new ValidationBuilder(this.form.components);

			if (FinancialSubaccountCustomComponentTypes.includes(this.widgetType!)) {
				this.addFinancialSubaccountValidation(validationBuilder);
			}

			switch (this.widgetType) {
				case CustomComponentType.personalInfo:
					this._addPersonalInfoValidations(validationBuilder);
					break;
				case CustomComponentType.businessInfo:
					this._addBusinessInfoValidations(validationBuilder);
					break;
				case CustomComponentType.collateralInfo:
					this._addCollateralInfoValidations(validationBuilder);
					break;
				case CustomComponentType.nonprofitInfo:
					this._addNonprofitInfoValidations(validationBuilder);
					break;
				default:
					this._addEntityTypeInfoValidations(validationBuilder);
					break;
			}

			return validationBuilder.buildValidations();
		},
		_addPersonalInfoValidations(validationBuilder: ValidationBuilder): void {
			const widgetAlias = 'Personal Info';
			this.addRequiredFieldValidation(validationBuilder, widgetAlias, 'First Name', TemplateDataField.PersonalFirstName);
			this.addRequiredFieldValidation(validationBuilder, widgetAlias, 'Last Name', TemplateDataField.PersonalLastName);
		},
		_addBusinessInfoValidations(validationBuilder: ValidationBuilder): void {
			const widgetAlias = 'Business Info';
			this.addRequiredFieldValidation(validationBuilder, widgetAlias, 'Business Name', TemplateDataField.BusinessName);
			this.addRequiredFieldValidation(validationBuilder, widgetAlias, 'State', TemplateDataField.BusinessState);
			this.addRequiredFieldValidation(validationBuilder, widgetAlias, 'Industry Code', TemplateDataField.BusinessIndustryCode);

			const allowedLoanRolesForApplicantBusiness = [
				CustomComponentType.primaryBorrowerEntity,
				CustomComponentType.coBorrowerEntity,
				CustomComponentType.guarantorEntity
			];
			if (this.appType === ApplicationTemplate.TypeEnum.Sba && FormPathUtils.isInWidget(this.formPath, allowedLoanRolesForApplicantBusiness)) {
				const componentLabel = CustomComponentLabel()[CustomComponentType.applicantBusinessQuestion];
				validationBuilder.validateForExactUsages(
					1,
					{ type: CustomComponentType.applicantBusinessQuestion },
					`Business Info for the SBA Application Template must include one and only one "${componentLabel}" widget`
				);
			} else if (this.appType === ApplicationTemplate.TypeEnum.Sba) {
				const componentLabel = CustomComponentLabel()[CustomComponentType.applicantBusinessQuestion];
				validationBuilder.validateForMaxUsages(
					1,
					{ type: CustomComponentType.applicantBusinessQuestion },
					`Business Info for the SBA Application Template can only include one "${componentLabel}" widget`
				);
			}
		},
		_addCollateralInfoValidations(validationBuilder: ValidationBuilder): void {
			const widgetAlias = 'Collateral Info';
			this.addRequiredFieldValidation(validationBuilder, widgetAlias, 'Collateral Description', TemplateDataField.CollateralDescription);
			this.addRequiredFieldValidation(validationBuilder, widgetAlias, 'Pledged By', TemplateDataField.CollateralPledgedBy);
		},
		_addNonprofitInfoValidations(validationBuilder: ValidationBuilder): void {
			const widgetAlias = 'Nonprofit Info';
			this.addRequiredFieldValidation(validationBuilder, widgetAlias, 'Nonprofit Name', TemplateDataField.NonprofitName);
			this.addRequiredFieldValidation(validationBuilder, widgetAlias, 'State', TemplateDataField.NonprofitState);
			this.addRequiredFieldValidation(validationBuilder, widgetAlias, 'Sector Code', TemplateDataField.NonprofitSectorCode);
		},
		_addEntityTypeInfoValidations(validationBuilder: ValidationBuilder): void {
			validationBuilder.validateForMaxUsages(
				1,
				{
					type: CustomComponentType.nonprofitInfo
				},
				'Nonprofit Info cannot be included more than once'
			);

			validationBuilder.validateForMaxUsages(
				1,
				{
					type: CustomComponentType.personalInfo
				},
				'Personal Info cannot be included more than once'
			);

			validationBuilder.validateForMaxUsages(
				1,
				{
					type: CustomComponentType.businessInfo
				},
				'Business Info cannot be included more than once'
			);
		},
		ignoreField(templateDataFieldId: number | null | undefined): boolean {
			const fieldsToIgnore: TemplateDataField[] = [
				TemplateDataField.SubaccountCustomer,
				TemplateDataField.SubaccountFinancialDataId,
				TemplateDataField.SubaccountFinancialStatement
			];

			return templateDataFieldId == null ? false : fieldsToIgnore.includes(templateDataFieldId);
		},
		addFinancialSubaccountValidation(validationBuilder: ValidationBuilder): void {
			const dataFieldSchemas = this.builder.defaultFields.components;
			Object.keys(dataFieldSchemas).forEach(_key => {
				const schema = dataFieldSchemas[_key].schema;
				const validationQuery =
					schema.dataFieldId != null ? { dataFieldId: dataFieldSchemas[_key].schema.dataFieldId } : { key: dataFieldSchemas[_key].schema.key };

				validationBuilder.validateForExactUsages(1, validationQuery, `You must include one and only one ${dataFieldSchemas[_key].title} field`);
			});
		},
		addRequiredFieldValidation(validationBuilder: ValidationBuilder, widgetAlias: string, fieldAlias: string, fieldId: TemplateDataField) {
			validationBuilder.validateForMinUsages(
				1,
				{
					templateDataFieldId: fieldId
				},
				`${widgetAlias} must include the ${fieldAlias} field`
			);
		}
	}
});
