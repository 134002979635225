import { CustomComponentType } from '../../../../../enums/custom-component-type';
import { PersonalFinancialTypeSelect } from '../personal-financial-type-select/personal-financial-type-select';
import { getKey } from '../../../../../components/form-components/simple-inputs/extended-field-helper';
import { createSubaccountDefaultEditConfiguration } from '../configuration';

export class PersonalExpenseTypeSelect extends PersonalFinancialTypeSelect {
	static schema(...extend: any) {
		return PersonalFinancialTypeSelect.schema(
			{
				label: 'Expense Type',
				type: CustomComponentType.personalExpenseTypeSelect,
				key: CustomComponentType.personalExpenseTypeSelect
			},
			...extend
		);
	}

	static editForm = createSubaccountDefaultEditConfiguration(CustomComponentType.personalExpenseTypeSelectOptions);

	static get builderInfo() {
		return {
			title: 'Expense Type',
			weight: 10,
			schema: PersonalExpenseTypeSelect.schema()
		};
	}

	// @ts-ignore
	get key(): string {
		return getKey(this, super.key);
	}
}
