
import Vue from 'vue';
import { UsableProducts } from '@sageworks/jpi';

export default Vue.extend({
	name: 'ApplicationSpinner',
	components: {},
	props: {
		label: {
			type: String,
		}
	},
	data() {
		return {};
	},
	computed: {
		defaultLabel(): string{
			if (this.label == null) {
				if (this.hasCommunityLendingSubscription === undefined) {
					return '';
				}
				return this.hasCommunityLendingSubscription ? 'Preparing Request Form' : 'Preparing application';

			}
			return this.label;
		},
		hasCommunityLendingSubscription(): boolean | undefined {
			return this.directStore.state.UsableProducts.usableProducts?.has('abrigoCommunityLending' as UsableProducts.ProductsEnum);
		}
	},
	async mounted() {
		await this.directStore.dispatch.UsableProducts.fetchUsableProducts();
	},
	methods: {}
});
