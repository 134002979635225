import nestedComponentForm from 'formiojs/components/_classes/nested/NestedComponent.form';
import {
	BaseEditApi,
	BaseEditConditional,
	BaseEditDisplay,
	BaseEditForm,
	BaseEditLayout,
	BaseEditLogic,
	BaseEditValidate,
	IBaseWidgetConfigurationEditFormOptions
} from '../../../configuration-components/base-widget-configuration';
import { CustomComponentType } from '../../../../enums/custom-component-type';
import AutoOpenCheckbox from '../../../configuration-components/base-widget-configuration/display/auto-open-checkbox';

export default function(componentType: CustomComponentType, ...extend: any) {
	return nestedComponentForm(
		[
			new BaseEditDisplay().getTab({ components: { showAllowRepeat: false, showLenderOnly: true } }),
			new BaseEditForm().getTab({ componentType: componentType } as IBaseWidgetConfigurationEditFormOptions),
			new BaseEditLogic().getTab(),
			new BaseEditApi().getTab(),
			new BaseEditConditional().getTab(),
			new BaseEditLayout().getTab(),
			{
				key: 'display',
				components: [AutoOpenCheckbox]
			},
			new BaseEditValidate().getTab({ componentType: componentType } as IBaseWidgetConfigurationEditFormOptions)
		],
		...extend
	);
}
