module.exports = function(ctx) {
var __t, __p = '';
__p += '<div class="builder-component" ref="dragComponent">\n	<div class="component-btn-group" data-noattach="true" role="form">\n		<div class="btn btn-xxs btn-danger component-settings-button component-settings-button-remove" ref="removeComponent" aria-label="Remove">\n			<i class="' +
((__t = ( ctx.iconClass('remove') )) == null ? '' : __t) +
'"></i>\n		</div>\n		<div class="btn btn-xxs btn-primary component-settings-button component-settings-button-copy" ref="copyComponent" aria-label="Copy">\n			<i class="' +
((__t = ( ctx.iconClass('copy') )) == null ? '' : __t) +
'"></i>\n		</div>\n		<div class="btn btn-xxs btn-primary component-settings-button component-settings-button-paste" ref="pasteComponent" aria-label="Paste">\n			<i class="' +
((__t = ( ctx.iconClass('save') )) == null ? '' : __t) +
'"></i>\n		</div>\n		<div class="btn btn-xxs btn-primary component-settings-button component-settings-button-edit-json" ref="editJson" aria-label="Edit json">\n			<i class="' +
((__t = (ctx.iconClass('wrench'))) == null ? '' : __t) +
'"></i>\n		  </div>\n		<div class="btn btn-xxs btn-primary component-settings-button component-settings-button-move" ref="moveComponent" aria-label="Move">\n			<i class="' +
((__t = ( ctx.iconClass('move') )) == null ? '' : __t) +
'"></i>\n		</div>\n		<div class="btn btn-xxs btn-primary component-settings-button component-settings-button-edit" , ref="editComponent" aria-label="Edit">\n			<i class="' +
((__t = ( ctx.iconClass('cog') )) == null ? '' : __t) +
'"></i>\n		</div>\n	</div>\n	' +
((__t = ( ctx.html )) == null ? '' : __t) +
'\n</div>\n';
return __p
}