import { AutoFactory, DataFieldsService, PropertyOptionsService } from '@sageworks/jpi';
import { TemplateDataField } from '../../../enums';

export const getCollateralTypeOptions = async function() {
	const dataFieldService: DataFieldsService = AutoFactory.get(DataFieldsService);
	const propertyOptionsService: PropertyOptionsService = AutoFactory.get(PropertyOptionsService);
	const collectionResponse = await dataFieldService.getPaged(1, 1, undefined, undefined, undefined, [TemplateDataField.CollateralType]);
	const dataField = collectionResponse?.items?.pop();
	return await propertyOptionsService.getSingle(dataField?.id ?? 0);
};
