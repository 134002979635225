import { Customer, ObjectPropertyValues } from '@sageworks/jpi';
import { convertCustomerTypeToEntityType, convertEntityTypeToCustomerType } from '../../utils/customer-type-utils/customer-type-utils';
import { ComponentDataHelper } from '../component-data-helper';
import { DataObjectUtils } from '../data-object-utils/data-object-utils';
import { CustomComponentType, FetchDataType } from '../../enums';
import { formatDataFieldIdKey } from '../../components/form-components/simple-inputs/extended-field-helper';
import { AddCustomerResult } from '../../components/form-components';

export async function addBlankNewRoleForType(component: any, roleType: CustomComponentType) {
	const newCustomer: Customer = {
		id: -1,
		type: convertEntityTypeToCustomerType(roleType),
		addresses: []
	};

	return await buildExistingCustomerResult(component, newCustomer);
}

export async function buildExistingCustomerResult(component: any, customer: Customer) {
	const customerType = customer.type;
	let submissionKey = convertCustomerTypeToEntityType(customerType as Customer.TypeEnum);

	let dataObject: ObjectPropertyValues | null = null;

	if (customer.id != null && customer.id > 0) {
		const dataObjectType = DataObjectUtils.customerTypeToDataObjectType(customer.type);
		dataObject = await ComponentDataHelper.fetchData<ObjectPropertyValues>(component, {
			fetchType: FetchDataType.DataObject,
			fetchContext: { type: dataObjectType, id: customer.id }
		});
	}

	const data: any = { id: customer.id };
	if (dataObject != null) {
		(dataObject.dataFieldValues ?? []).forEach(x => {
			if (x.id) {
				data[formatDataFieldIdKey(String(x.id))] = x.value;
			}
		});
	}

	return {
		customerType: customerType,
		[submissionKey]: { data }
	} as AddCustomerResult;
}
