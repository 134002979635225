import { DateFormatting } from '@sageworks/core-logic';
import { LoanApplication } from '@sageworks/jpi';
import moment from 'moment';

export function buildAuthorizationMessage(acceptedByUser: string | null, acceptedByDate: Date | null): string {
	if (!acceptedByUser || !acceptedByDate) {
		return '';
	}
	const acceptedByDateString = moment(acceptedByDate).format('MM/DD/YYYY');
	return `Terms and conditions authorized by ${acceptedByUser} on ${acceptedByDateString}`;
}

export function updateLoanApplicationToAcceptTermsAndConditions(loanApplication: LoanApplication, username: string, agreedDate: Date = new Date()) {
	loanApplication.agreedToTerms = true;
	loanApplication.agreedToTermsDate = DateFormatting.formatDateForJpi(agreedDate);
	loanApplication.agreedToTermsUsername = username;
} 