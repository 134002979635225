import { Utils as FormioUtils } from 'formiojs';
import { findMatchingField } from '../data-field-search/data-field-search-util';
import { cloneDeep, set } from 'lodash';
import { formatEq } from '../conditionality';

// An unchanged copy from formiojs source code (https://github.com/formio/formio.js/blob/master/src/utils/utils.js#L276)
// NOTE: This method needed to be copied as our version of formio does not have this method
const getRow = (component: any, row: any, instance: any, conditional?: any) => {
	const condition = conditional || component.conditional;
	// If no component's instance passed (happens only in 6.x server), calculate its path based on the schema
	if (!instance) {
		instance = cloneDeep(component);
		(FormioUtils as any).setPathToComponentAndPerentSchema(instance);
	}
	const dataParent = (FormioUtils as any).getDataParentComponent(instance);
	const parentPathWithoutIndicies = dataParent?.path ? (FormioUtils as any).getComponentPathWithoutIndicies(dataParent.path) : null;
	if (dataParent && condition.when?.startsWith(parentPathWithoutIndicies)) {
		const newRow = {};
		set(newRow, parentPathWithoutIndicies, row);
		row = newRow;
	}

	return row;
};

/**
 * Overrode formio's checkSimpleConditional to allow multiple `eq` values
 * @param component
 * @param conditional
 * @param row
 * @param data
 */
const checkSimpleConditional = (component: any, conditional: any, row: any, data: any): boolean => {
	conditional = { ...conditional, eq: formatEq(conditional.eq) };

	const conditionResult = conditional.eq.some((eqCondition: any) => {
		const currentConditional = { ...conditional, eq: eqCondition };
		const currentConditionalResult = FormioUtils.checkSimpleConditional(component, currentConditional, row, data);

		return currentConditional.show ? currentConditionalResult : !currentConditionalResult;
	});

	return conditional.show ? conditionResult : !conditionResult;
};

export const checkCondition = (component: any, row: any, data: any, form: any, instance: any): boolean => {
	const { customConditional, conditional } = component;

	// We need to ensure we supply the conditionality evaluation the data field value
	// Also ensure custom conditional takes precendence, ignore simple conditional when a custom one is present
	if (!customConditional && conditional && conditional.when) {
		const dataFieldData = findMatchingField(instance, conditional.when);
		data = { ...data, ...dataFieldData };

		row = getRow(component, row, instance);
		return checkSimpleConditional(component, conditional, row, data);
	}

	return FormioUtils.checkCondition(component, row, data, form, instance);
};
