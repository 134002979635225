import {
	CustomComponentType,
	LoanRoleType,
	CustomerCustomComponentTypes,
	DataObjectMapper,
	SubmitPayload,
	DataHandler,
	DeletePayload,
	DataObjectUtils,
} from '@sageworks/dynamic-forms';
import { ObjectPropertyValues } from '@sageworks/jpi';
import { StoreType } from '../../../../store';
import { findChildCustomerComponentType } from '../data-handler-utils';
import { AddRelatedRoleParams } from '../../../../store/MultiLoanApplicationFormModule/persist-actions/persist-action-params';
import { MultiLoanApplicationFormModuleState } from '@/store/MultiLoanApplicationFormModule/state';
import { locateRole, setParentProfitEntityToDataFieldValues } from '@/utils/form-data-utils';

export class RelatedRoleDataHandler implements DataHandler {
	private _relatedLoanRoleTypeLookup = new Map<CustomComponentType, LoanRoleType>([[CustomComponentType.authorizedSigner, LoanRoleType.AuthorizedSigner]]);

	private get loanRoleType() {
		return this._relatedLoanRoleTypeLookup.get(this.componentType);
	}

	private get formData() {
		const { renderData } = this._store.state.MultiLoanApplicationForm as MultiLoanApplicationFormModuleState;
		return renderData?.formData ?? {};
	}

	constructor(private _store: StoreType, private componentType: CustomComponentType) {}

	async submit({ submission, metadata }: SubmitPayload) {
		const { parentLoanRoleId } = metadata ?? {};
		const parentLoanRole = locateRole(this.formData, parentLoanRoleId);
		const propName = Object.keys(submission.data).find(key => CustomerCustomComponentTypes.includes(key as CustomComponentType));

		if (!this.loanRoleType) {
			throw new Error(`Cannot look up loan role type for component: ${this.componentType}`);
		} else if (!parentLoanRole?.roleType) {
			throw new Error(`cannot locate parent loan role with id = ${parentLoanRoleId}`);
		} else if (propName == null) {
			throw new Error('no dataObject of the correct type');
		}

		let dataObjectType = DataObjectMapper.submissionDataPropertiesLookup[propName!];
		const customerDataObject = DataObjectMapper.objectToDataObject(submission.data[propName!], this.loanRoleType);

		// If the loan role type is AuthorizedSigner, we need to set the profit entity to the parent profit entity
		setParentProfitEntityToDataFieldValues(
			dataObjectType, 
			customerDataObject, 
			parentLoanRole,
			this.loanRoleType,
			this._store.state.CustomComponentProperties?.templateDataFieldIdToDataFieldId,
		);

		const savedCustomerDataObject: ObjectPropertyValues = await this._store.dispatch.MultiLoanApplicationForm.saveDataObject({
			dataObjectType,
			dataObject: customerDataObject
		});
		submission.data[propName!].data.id = savedCustomerDataObject.id;

		const params: AddRelatedRoleParams = {
			customerId: savedCustomerDataObject.id!,
			customerType: DataObjectUtils.dataObjectTypeToCustomerType(dataObjectType)!,
			parentLoanRoleId: parentLoanRole.dataObjectID!,
			roleType: this.loanRoleType
		};

		await this._store.dispatch.MultiLoanApplicationForm.addRelatedRole(params);

		return submission;
	}

	async delete({ submission }: DeletePayload) {
		const propName = findChildCustomerComponentType(submission?.form?.data);

		if (propName == null) {
			throw new Error(`could not find dataObject of a valid customer type when attempting to delete ${this.loanRoleType}`);
		}

		const customerObject = submission?.form?.data[propName]?.data;
		if (customerObject == null) {
			throw new Error(`no data for dataObject.type ${propName} when attempting to delete ${this.loanRoleType}`);
		}

		const customerId = customerObject.id;
		if (customerId == null) {
			throw new Error(`cannot locate customer ID from submission data. ${this.loanRoleType}`);
		}

		try {
			await this._store.dispatch.MultiLoanApplicationForm.deleteRelatedRole({
				loanRoleType: this.loanRoleType!,
				parentLoanRoleId: customerObject.parentLoanRoleId,
				customerId
			});
		} catch (err) {
			throw new Error(`An error occured when deleting the ${this.loanRoleType}`);
		}
	}

	
}
