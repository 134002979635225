
import Vue, { PropType } from 'vue';
import ApplicationWizardNavigation from './ApplicationWizardNavigation.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default Vue.extend({
	name: 'ApplicationWizardCard',
	components: {
		ApplicationWizardNavigation,
		FontAwesomeIcon
	},
	props: {
		applicationId: {
			type: String as PropType<string | null>,
			default: null
		},
		customNavigation: {
			type: Boolean,
			required: false,
			default: false
		},
		navigationMetadata: {
			type: Array,
			required: false,
			default: () => {
				return [];
			}
		},
		pageIndex: {
			type: Number,
			required: false,
			default: 0
		},
		showMenuToggle: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	data() {
		return {
			isDrawerMenuOpen: false
		};
	},
	computed: {
		applicationIdAsNumber(): Number {
			if (!this.applicationId) {
				return 0;
			}
			const id = parseInt(this.applicationId, 10);
			return Number.isNaN(id) ? 0 : id;
		},
		useCustomNavigation(): boolean {
			return this.customNavigation;
		}
	},
	mounted() {},
	methods: {
		toggleDrawerMenu() {
			this.isDrawerMenuOpen = !this.isDrawerMenuOpen;
		}
	}
});
