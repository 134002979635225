
import Vue, { PropType } from 'vue';
import { ApplicationFormSignature } from '../../../components/application-form-signature';
import { ApplicationFormLoanRoleInfoHelper } from '@/utils';
import { CustomComponentType, LoanRoleType } from '@sageworks/dynamic-forms';
import { ComponentState } from '@/enums';

export default Vue.extend({
	name: 'SignatureSection',
	components: {
		ApplicationFormSignature
	},
	props: {
		componentState: { type: String as PropType<ComponentState>, default: null },
		formioDataObjects: { type: Object as PropType<Object>, default: () => {} }
	},
	data() {
		return {
			LoanRoleType
		};
	},
	computed: {
		applicationTermsAndConditionsMessage() {
			return this.directStore.getters.LoanApplicationData.applicationTermsAndConditionsMessage;
		},
		exportingState() {
			return ComponentState.EXPORTING;
		}
	},
	async mounted() {},
	methods: {
		proposedLoanPrimaryBorrower(formData: any) {
			return this._getLoanRoleCustomerInformation(CustomComponentType.primaryBorrowerEntity, formData);
		},
		proposedLoanCoBorrowers(formData: any) {
			return this._getLoanRoleCustomerInformation(CustomComponentType.coBorrowerEntity, formData);
		},
		proposedLoanGuarantors(formData: any) {
			return this._getLoanRoleCustomerInformation(CustomComponentType.guarantorEntity, formData);
		},
		proposedLoanCreditApplicants(formData: any) {
			return this._getLoanRoleCustomerInformation(CustomComponentType.creditApplicantEntity, formData);
		},
		proposedLoanCoSigners(formData: any) {
			return this._getLoanRoleCustomerInformation(CustomComponentType.coSignerEntity, formData);
		},
		_getLoanRoleCustomerInformation(
			borrowerEntityType: CustomComponentType,
			formData: any
		): ReturnType<typeof ApplicationFormLoanRoleInfoHelper.getLoanRoleCustomerInformationFromFormData> {
			// Only retrieve borrwer customer information if the form is loaded AND we are in read only mode:
			if (this._pageIsReadyToLoadLoanRoleCustomerInformation()) {
				const {
					businessNameId = 0,
					personFirstNameId = 0,
					personLastNameId = 0,
					farmNameId = 0
				} = this.directStore.state.LoanApplicationMetadata.borrowerNameDataFieldIds;

				return ApplicationFormLoanRoleInfoHelper.getLoanRoleCustomerInformationFromFormData(
					formData,
					borrowerEntityType,
					businessNameId,
					personFirstNameId,
					personLastNameId,
					farmNameId
				);
			} else {
				return [];
			}
		},
		_pageIsReadyToLoadLoanRoleCustomerInformation(): boolean {
			return this.formioDataObjects && (this.componentState === ComponentState.LOADED || this.componentState === ComponentState.EXPORTING);
		}
	}
});
