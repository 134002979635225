import { CustomComponentType } from '../../../enums';
import { getCopyFormComponent } from './form/copy-form-factory';
import { BaseEditTab } from './base.edit.tab';
import { IBaseWidgetConfigurationEditFormOptions } from './base.edit.form.options';

export class BaseEditForm extends BaseEditTab<IBaseWidgetConfigurationEditFormOptions> {
	public get tabKey(): string {
		return 'form';
	}

	public get label(): string {
		return 'Form';
	}

	public get weight(): number {
		return 10;
	}

	public getComponents(customOptions: IBaseWidgetConfigurationEditFormOptions): any[] {
		const components = [];

		if (customOptions.components && customOptions.components.showCopyForm) {
			components.push(getCopyFormComponent(customOptions.componentType));
		}

		return components;
	}

	public defaultOptions: IBaseWidgetConfigurationEditFormOptions = {
		components: {
			showCopyForm: true
		},
		componentType: CustomComponentType.primaryBorrowerEntity,
		beforeForm: true
	};
}
