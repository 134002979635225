import {
	AutoFactory,
	LoanApplicationCreditAuthorization,
	LoanOfficer,
	OnlinePortalLoanApplicationCreditAuthorizationService,
	OnlinePortalLoanOfficersService,
	OnlinePortalCreditReportService,
	CreditReportInfo
} from '@sageworks/jpi';
import { DateFormatting } from '@sageworks/core-logic';

export class PullCreditReportError extends Error {
	readonly errors: string[];

	constructor(errors: string[]) {
		super();
		this.errors = errors;
	}
}

export const upsertAuthorizationObject = async (authorizationMetadata: LoanApplicationCreditAuthorization) => {
	const service = AutoFactory.get(OnlinePortalLoanApplicationCreditAuthorizationService);

	let result;
	if (authorizationMetadata.id != null && authorizationMetadata.id > 0) {
		result = await service.update(authorizationMetadata.id, authorizationMetadata);
	} else {
		authorizationMetadata.id = undefined;
		result = await service.create(authorizationMetadata);
	}

	return result;
};

export const authorizeCreditPull = async (
	creditAuthorizationId: number | undefined,
	loanApplicationsId: number,
	authorizedCustomerId: number,
	authorizedByUserId: number,
	authorizedByUserType: LoanApplicationCreditAuthorization.AuthorizedByUserTypeEnum
): Promise<LoanApplicationCreditAuthorization> => {
	const date: string = DateFormatting.formatDateForJpi(new Date());

	const authorizationObject: LoanApplicationCreditAuthorization = {
		id: creditAuthorizationId,

		authorizedDate: date as any,
		customerId: authorizedCustomerId,

		authorizedByUserId: authorizedByUserId,
		authorizedByUserType: authorizedByUserType,

		loanApplicationId: loanApplicationsId
	};

	return await upsertAuthorizationObject(authorizationObject);
};

export const waiveCreditPull = async (
	creditAuthorizationId: number | undefined,
	loanApplicationsId: number,
	waivedCustomerId: number,
	waivedByUserId: number,
	waivedReason: string
): Promise<LoanApplicationCreditAuthorization> => {
	let date: string = DateFormatting.formatDateForJpi(new Date());

	let authorizationObject: LoanApplicationCreditAuthorization = {
		id: creditAuthorizationId,

		waivedDate: date as any,
		customerId: waivedCustomerId,

		waivedByUserId,
		waivedReason,

		loanApplicationId: loanApplicationsId
	};

	return await upsertAuthorizationObject(authorizationObject);
};

export const unwaiveCreditPull = async (creditAuthorizationId: number): Promise<LoanApplicationCreditAuthorization> => {
	let authorizationObject: LoanApplicationCreditAuthorization = {
		id: creditAuthorizationId,

		waivedDate: null as any,
		waivedByUserId: null as any,
		waivedReason: null as any
	};

	return await upsertAuthorizationObject(authorizationObject);
};

export const pullCreditReport = async (
	creditAuthorizationId: number | undefined,
	customerId: number,
	loanApplicationId: number,
	pulledByUserId: number,
	pulledByUserType: LoanApplicationCreditAuthorization.PulledByUserTypeEnum,
	creditReportInfo?: CreditReportInfo
) => {
	const service = AutoFactory.get(OnlinePortalCreditReportService);
	const result = await service.pullCreditReport(creditReportInfo);

	if (result.errors && result.errors.length > 0) {
		throw new PullCreditReportError(result.errors);
	}

	let authorizationMetadata = await upsertAuthorizationObject({
		id: creditAuthorizationId,
		customerId,
		loanApplicationId,

		pullComplete: true,
		pullDate: DateFormatting.formatDateForJpi(new Date()) as any,
		pulledByUserId,
		pulledByUserType
	});

	return {
		applicantDocumentId: result.applicantDocumentId,
		authorizationMetadata
	};
};

export const getCreditPullAuthorization = async (customerId: number, loanApplicationsId: number): Promise<LoanApplicationCreditAuthorization | null> => {
	const service = AutoFactory.get(OnlinePortalLoanApplicationCreditAuthorizationService);

	let results: LoanApplicationCreditAuthorization[] = [];
	let page = 1;
	const pageSize = 100;
	let wasFullPage = false;
	do {
		let batch = await service.getPaged(page++, pageSize, loanApplicationsId);

		if (batch?.items) {
			results = results.concat(batch?.items);
			wasFullPage = batch.items.length === pageSize;
		}
	} while (wasFullPage); // If it wasn't a full page, then we are at the end

	return results?.filter(f => f.customerId === customerId)?.pop() ?? null;
};

export const getUserDisplayName = async (userId?: number | null): Promise<string> => {
	// return userId?.toString() ?? '';
	const service = AutoFactory.get(OnlinePortalLoanOfficersService);
	let loanOfficer: LoanOfficer;

	if (userId != null) {
		loanOfficer = await service.getLoanOfficerById(userId);
		if (loanOfficer == null) return '';
		return loanOfficer.firstName + ' ' + loanOfficer.lastName;
	} else {
		return '';
	}
};
