import { DataObjectDataHandler } from '../data-object-data-handler';
import { DeletePayload, SubmitPayload } from '@sageworks/dynamic-forms';

export class CollateralDataHandler extends DataObjectDataHandler {
	async submit(payload: SubmitPayload): Promise<any> {
		const dataObject = this.createSubmissionDataObject(payload);

		const result = await this._store.dispatch.MultiLoanApplicationForm.saveDataObject({ dataObjectType: this.dataObjectType, dataObject });

		if (result == null) {
			return;
		}

		try {
			const loanApplicationId = await this._store.state.LoanApplicationData.loanApplication.id;
			await this._store.dispatch.MultiLoanApplicationForm.createNewProposedLien({ loanApplicationId, collateral: result });
		} catch (err) {
			throw new Error('Unable to create new lien. There was unexpected error when attempting to create the requested item.');
		}

		return this.createFormObject(payload, result);
	}

	async delete({ submission }: DeletePayload) {
		const collateralId = submission?.form?.data?.id;

		if (collateralId == null) {
			return;
		}

		try {
			await this._store.dispatch.MultiLoanApplicationForm.removeProposedLien({ id: collateralId });
		} catch (err) {
			throw new Error('Unable to remove. There was unexpected error when attempting to remove the requested item.');
		}
	}
}
