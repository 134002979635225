module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div ref="value">\n  ';
 
	var filtered = ctx.values.filter(function(item) {
		// Include if item value AND context value are equal after being normalized
		if (ctx.instance.normalizeValue(item.value) === ctx.instance.normalizeValue(ctx.value)) {
			return true;
		}
		
		// (We think) This will handle multi-select instances for radio buttons. Something we do not currently support
		// Assumption: context value stores all options as properties with the value of EACH property as it has been selected
		return (ctx.value != null && typeof ctx.value === 'object' && ctx.value.hasOwnProperty(item.value) && ctx.value[item.value])
	}).map(function(item) { 
		return ctx.t(item.label)
	}).join(', ')
	;
__p += '\n  ' +
((__t = ( filtered.length > 0 ? filtered : "-" )) == null ? '' : __t) +
'\n</div>\n';
return __p
}