import { StaticFormUtils } from '../../../../utils';
import { DataField } from '@sageworks/jpi';
import { CustomComponentType, FormioComponentType, TemplateDataField } from '../../../../enums';
import { FormioComponentSchema, FormioFormDisplayType, FormioValidationSchema } from '../../../../formio-interfaces';
import ExtendedNumber from '../../simple-inputs/extended-number/extended-number';

export const BusinessInvestmentFields = [TemplateDataField.BusinessInvestmentValue];

export const generateFormJson = (dataFields: DataField[]) => {
	const dataFieldSchemaMap = StaticFormUtils.dataFieldListToSchemaMap(dataFields);
	const validationSchema: FormioValidationSchema = {
		customPrivate: false,
		required: true
	};

	if (dataFieldSchemaMap[TemplateDataField.BusinessInvestmentValue] != null) {
		dataFieldSchemaMap[TemplateDataField.BusinessInvestmentValue].validate = validationSchema;
	}

	const businessInvestmentComponents: FormioComponentSchema<any, FormioValidationSchema>[] = [
		dataFieldSchemaMap[TemplateDataField.BusinessInvestmentValue],
		// Needed so the component is aware that data exists (this will prevent it from being empty and not clear out the existing data)
		ExtendedNumber.schema({ key: `${CustomComponentType.businessInfo}.data.id`, hidden: true, disabled: true }),
		ExtendedNumber.schema({ key: `${CustomComponentType.farmInfo}.data.id`, hidden: true, disabled: true })
	];

	return {
		type: FormioComponentType.Form,
		title: 'Business Investment - edit form',
		display: FormioFormDisplayType.form,
		components: businessInvestmentComponents
	};
};
