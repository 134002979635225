import { CustomComponentLabel, CustomComponentType, RetirementAccountCustomFieldKey } from '../../../../../enums';
import { PersonalFinancialTypeSelect } from '../personal-financial-type-select/personal-financial-type-select';
import { createSubaccountDefaultEditConfiguration } from '../configuration/default-edit-configuration';

export class PersonalRetirementAccountTypeSelect extends PersonalFinancialTypeSelect {
	static schema(...extend: any) {
		return PersonalFinancialTypeSelect.schema(
			{
				label: CustomComponentLabel()[CustomComponentType.personalRetirementAccountTypeSelect],
				type: CustomComponentType.personalRetirementAccountTypeSelect,
				key: RetirementAccountCustomFieldKey.RetirementAccountType
			},
			...extend
		);
	}

	static editForm = createSubaccountDefaultEditConfiguration(CustomComponentType.personalRetirementAccountTypeSelectOptions);

	static get builderInfo() {
		return {
			title: CustomComponentLabel()[CustomComponentType.personalRetirementAccountTypeSelect],
			weight: 10,
			schema: PersonalRetirementAccountTypeSelect.schema()
		};
	}
}
