import { DynamicFormsHookType } from '../enums';
import { FetchDataHookContext, UpdateDataHookContext, RemoveOnChangeHandlerHookContext } from '../models';
import Component from 'formiojs/components/_classes/component/Component';

export const fetchData = async <T = any>(component: Component, hookContext: FetchDataHookContext) => {
	return await new Promise<T>((resolve, reject) => {
		component.hook(DynamicFormsHookType.FetchData, hookContext, reject, resolve);
	});
};

export const updateData = async <T = any>(component: Component, hookContext: UpdateDataHookContext) => {
	return await new Promise<T>((resolve, reject) => {
		component.hook(DynamicFormsHookType.UpdateData, hookContext, reject, resolve);
	});
};

export const removeOnChangeHandler = async <T = any>(component: Component, hookContext: RemoveOnChangeHandlerHookContext) => {
	return await new Promise<T>((resolve, reject) => {
		component.hook(DynamicFormsHookType.RemoveOnChangeHandler, hookContext, reject, resolve);
	});
};

export const ComponentDataHelper = {
	fetchData,
	updateData,
	removeOnChangeHandler
};
