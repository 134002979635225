import editForm from './co-signer-entity.form';
import { CustomComponentType, CustomBuilderGroups, LoanRoleType } from '../../../enums';
import { PrimaryRoleRepeaterWidgetVue } from '../primary-role-repeater-widget/primary-role-repeater-widget-vue';

export default class CoSignerEntityWidgetVue extends PrimaryRoleRepeaterWidgetVue {
	static schema(...extend: any) {
		return PrimaryRoleRepeaterWidgetVue.schema(
			{
				label: 'Co-Signer',
				type: CustomComponentType.coSignerEntityVue,
				key: CustomComponentType.coSignerEntityVue,
				addAnother: 'Add a Co-Signer'
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: 'Co-Signer',
			group: CustomBuilderGroups.widgetsVue,
			weight: 10,
			schema: CoSignerEntityWidgetVue.schema({})
		};
	}

	init() {
		this.roleType = LoanRoleType.CoSigner;

		super.init();
	}
}
