import { DynamicFormsHookType } from '../../../../enums';
import Component from 'formiojs/components/_classes/component/Component';
import { configureLinkTemplate } from '../../base/edit-link-helper';
import { isInPreviewMode } from '../../../../utils/formio-component-utils';
import { checkConditionalityAcrossForms, shouldComponentUseOriginalConditionality } from '../../simple-inputs/extended-field-helper';
import { FormioConditionalityUtils } from '../../../../utils/formio-conditionality-utils';
export default class VueFormioComponent extends Component {
	static schema(...extend: any): any {
		return Component.schema({}, ...extend);
	}

	get isInPreviewMode() {
		return isInPreviewMode(this);
	}

	public vueComponent: any;

	constructor(component: any, options: any, data: object) {
		super(component, options, data);

		// We override this list so that we ignore formio's default validators and use the ones from the vue component
		this.validators = [];

		const hookParams = { componentType: this.component.type, schema: this.component };
		const hookResponse = this.hook(DynamicFormsHookType.CreateVueComponent, hookParams);

		// Ensure the response is not null or the parameters passed in (for some reason, formio returns back the params if it can't find a matching hook)
		if (hookResponse != null && hookResponse !== hookParams) {
			this.vueComponent = hookResponse;
		}
	}

	render(_children: any, topLevel?: boolean): any {
		if (this.builderMode) {
			return configureLinkTemplate(this, { showEditButton: false });
		}

		return super.render('', topLevel);
	}

	async attach(element: HTMLElement) {
		if (this.vueComponent && this.visible) {
			// Vue component props need to be updated here since this is the only
			// 	place in the lifecycle that we're confident will contain the latest values
			this.updateVueComponentProps(this.data);

			element.appendChild(this.vueComponent.$el);
		}

		return await super.attach(element);
	}

	checkComponentValidity(data: any, dirty: boolean, row: any, options: any = {}): boolean | Promise<boolean> {
		data = data || this.rootValue;
		row = row || this.data;
		const { async = false, silentCheck = false } = options;

		if (this.shouldSkipValidation(data, dirty, row)) {
			this.setCustomValidity('');
			return async ? Promise.resolve(true) : true;
		}

		const superResult = super.checkComponentValidity(data, dirty, row, options);

		if (async) {
			return (superResult as Promise<boolean>).then(valid => {
				const isVueComponentValid = this.validateVueComponent(data, dirty, silentCheck);

				return valid && isVueComponentValid;
			});
		}

		return superResult && this.validateVueComponent(data, dirty, silentCheck);
	}

	addMessages(_messages: any) {
		// Overwriting existing implementation and leaving empty since we don't want formio to show the validation errors b/c they are being
		// 	controlled by the vue component
	}

	protected updateVueComponentProps(_data: any) {
		// This should be overwritten by the child class to update the vue component props
	}

	protected validateVueComponent(data: any, dirty: boolean, silentCheck: boolean): boolean {
		// Need to update the vue component props with the latest data since it may be stale if the component is not visible
		this.updateVueComponentProps(data);

		// Run validation within the vue component to get the validation messages
		this.vueComponent?.validate?.();
		const validationMessages = this.vueComponent?.validationMessages ?? [];

		// Set the validation messages so formio can pick them up
		this.setComponentValidity(validationMessages, dirty, silentCheck);

		return validationMessages.length === 0;
	}

	conditionallyVisible(data: any): boolean {
		if (shouldComponentUseOriginalConditionality(this)) {
			return super.conditionallyVisible(data);
		}

		return checkConditionalityAcrossForms(this);
	}

	checkCondition(row: any, data: any) {
		return FormioConditionalityUtils.checkCondition(
			this.component,
			row || this.data,
			data || this.rootValue,
			this.root ? (this.root as any)._form : {},
			this
		);
	}
}
