
import { Component, Mixins } from 'vue-property-decorator';
import { required, email } from 'vuelidate/lib/validators';
import { ScaleMixin, RouteMixin } from '@/mixins';

@Component({
	validations: {
		firstName: {
			required
		},
		lastName: {
			required
		},
		email: {
			required,
			email
		}
	}
})
export default class CreateAccount extends Mixins(ScaleMixin, RouteMixin) {
	private submitting = false;
	private firstName: string | null = null;
	private lastName: string | null = null;
	private email: string | null = null;
	private validationMessages: Map<string, string> = new Map([
		['firstName', 'This field is required'],
		['lastName', 'This field is required'],
		['email', 'Please enter a valid email address']
	]);
	private showValidation: boolean = false;

	private mounted() {
		this.firstName = this.directStore.state.Login.userFirstName;
		this.lastName = this.directStore.state.Login.userLastName;
		this.email = this.directStore.state.Login.userEmail;
	}

	private beforeDestroy() {
		if (this.email === '' || !this.$v.email.$invalid) {
			this.directStore.dispatch.Login.setUserEmail(this.email);
		}
	}

	private validationState(fieldName: string) {
		if (!this.showValidation) {
			return null;
		} else if (this.$v[fieldName].$invalid) {
			return false;
		}
		return true;
	}

	private submit(): void {
		this.showValidation = true;
		if (this.$v.$invalid) {
			return;
		}
		this.submitting = true;
		this.directStore.dispatch.Login.setUserEmail(this.email);
		this.directStore.dispatch.Login.setUserFirstName(this.firstName);
		this.directStore.dispatch.Login.setUserLastName(this.lastName);
		this.$router.push(this.OnlinePortalRoutes.VerifyEmail.path);
	}
}
