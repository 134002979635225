import { LoanApplication, AutoFactory, OnlinePortalLoanApplicationsService } from '@sageworks/jpi';
import { defineModule, localActionContext } from 'direct-vuex';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => localActionContext(context, ApplicationModule);

export interface ApplicationModuleState {
	loanApplications: LoanApplication[] | null;
}
const ApplicationModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			loanApplications: null
		} as ApplicationModuleState;
	},
	mutations: {
		SET_LOAN_APPLICATIONS(state, loanApplications: LoanApplication[]) {
			state.loanApplications = loanApplications;
		},
		ADD_LOAN_APPLICATION(state, loanApplication: LoanApplication) {
			if (!state.loanApplications) {
				state.loanApplications = [];
			}
			state.loanApplications.push(loanApplication);
		},
		SET_LOAN_APPLICATION(state, loanApplication: LoanApplication) {
			const applicationIndex: number = state.loanApplications?.findIndex(application => application.id === loanApplication.id) ?? -1;
			if (applicationIndex !== -1 && state.loanApplications != null) {
				state.loanApplications[applicationIndex] = loanApplication;
			}
		}
	},
	actions: {
		async fetchLoanApplications(context): Promise<void> {
			const { commit } = actionContext(context);
			const loanApplicationService = AutoFactory.get(OnlinePortalLoanApplicationsService);
			try {
				let response = await loanApplicationService.getPaged();
				let loanApplications = response.items || [];
				commit.SET_LOAN_APPLICATIONS(loanApplications);
			} catch (err) {
				return;
			}
		},
		async fetchApplicationById(context, loanApplicationId: number): Promise<void> {
			const { commit, getters } = actionContext(context);
			if (getters.applicationById(loanApplicationId) != null) {
				return;
			}
			const loanApplicationService = AutoFactory.get(OnlinePortalLoanApplicationsService);
			try {
				let loanApplication = await loanApplicationService.getById(loanApplicationId);
				if (loanApplication) {
					commit.ADD_LOAN_APPLICATION(loanApplication);
				}
			} catch (err) {
				return;
			}
		},
		async updateLoanApplication(context, loanApplication: LoanApplication) {
			const { commit, getters } = actionContext(context);
			if (getters.applicationById(loanApplication.id) == null) {
				return;
			}
			commit.SET_LOAN_APPLICATION(loanApplication);
		}
	},
	getters: {
		numberOfOpenApplications(state): number {
			if (!state.loanApplications) {
				return 0;
			}
			return state.loanApplications.reduce((totalOpenApplications, applicationToCheck) => {
				let isOpenApplication = !applicationToCheck.submittedDate;
				if (isOpenApplication) {
					totalOpenApplications += 1;
				}
				return totalOpenApplications;
			}, 0);
		},
		applicationById(state): (loanApplicationId: number) => LoanApplication | undefined {
			return function(loanApplicationId: number): LoanApplication | undefined {
				return state.loanApplications?.filter(loanApplication => loanApplication.id === loanApplicationId)[0];
			};
		}
	}
});

export default ApplicationModule;
