module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<nav aria-label="navigation" id="' +
((__t = ( ctx.wizardKey )) == null ? '' : __t) +
'-header" class="dynamic-forms-nested-wizard-header p-3 ' +
((__t = (ctx.panels.length === 1 ? 'd-none' : '' )) == null ? '' : __t) +
'">\n  <ul class="pagination mb-0">\n    ';
 ctx.panels.forEach(function(panel, index) { ;
__p += '\n    <li class="page-item' +
((__t = (ctx.currentPage === index ? ' active' : '')) == null ? '' : __t) +
'" style="cursor: pointer;">\n    	<span \n			class="page-link border-0 ' +
((__t = (ctx.currentPage === index ?  ' bg-primary text-white' : ' bg-transparent text-primary' )) == null ? '' : __t) +
'" \n			ref="' +
((__t = (ctx.wizardKey)) == null ? '' : __t) +
'-link"\n			tabindex="0"\n		>		\n			' +
((__t = (ctx.t(panel.component.title, { _userInput: true }))) == null ? '' : __t) +
'\n			';
 if (panel.tooltip && ctx.currentPage === index) { ;
__p += '\n			<i ref="' +
((__t = (ctx.wizardKey)) == null ? '' : __t) +
'-tooltip" class="' +
((__t = (ctx.iconClass('question-sign'))) == null ? '' : __t) +
' text-muted" data-tooltip="' +
((__t = (panel.tooltip)) == null ? '' : __t) +
'"></i>\n			';
 } ;
__p += '\n    	</span>\n    </li>\n    ';
 }) ;
__p += '\n  </ul>\n</nav>\n';
return __p
}