import { AutoFactory, OnlinePortalLoanApplicationsService, DetailedLoanApplication } from '@sageworks/jpi';

export const fetchDetailedLoanApplications = async (isSubmitted: boolean, page?: number, perPage?: number) => {
	const service = AutoFactory.get(OnlinePortalLoanApplicationsService);
	const result = await service.getPagedWithAllDetails(
		page, perPage, undefined, undefined,
		undefined, undefined, undefined, undefined,
		undefined, isSubmitted);

	return result.items ?? [] as DetailedLoanApplication[];
};