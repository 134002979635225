import { CustomComponentLabel, CustomComponentType } from '../../../../../enums/custom-component-type';
import { PersonalFinancialTypeSelect } from '../personal-financial-type-select/personal-financial-type-select';
import { getKey } from '../../../../../components/form-components/simple-inputs/extended-field-helper';
import { createSubaccountDefaultEditConfiguration } from '../configuration';

export class PersonalPropertyTypeSelect extends PersonalFinancialTypeSelect {
	static schema(...extend: any) {
		return PersonalFinancialTypeSelect.schema(
			{
				label: CustomComponentLabel()[CustomComponentType.personalPropertyTypeSelect],
				type: CustomComponentType.personalPropertyTypeSelect,
				key: CustomComponentType.personalPropertyTypeSelect
			},
			...extend
		);
	}

	static editForm = createSubaccountDefaultEditConfiguration(CustomComponentType.personalPropertyTypeSelectOptions);

	static get builderInfo() {
		return {
			title: CustomComponentLabel()[CustomComponentType.personalPropertyTypeSelect],
			weight: 10,
			schema: PersonalPropertyTypeSelect.schema()
		};
	}

	// @ts-ignore
	get key(): string {
		return getKey(this, super.key);
	}
}
