
import Vue, { PropType } from 'vue';
import ApplicationSignatureStatus from '../../components/application-signature-status/ApplicationSignatureStatus.vue';

export default Vue.extend({
	name: 'SubmitEsign',
	components: {
		ApplicationSignatureStatus
	},
	props: {
		itemLabel: {
			type: String as PropType<string | null>,
			default: null
		},
		submittedDate: {
			type: Date as PropType<Date | null>,
			default: null
		},
		currentUserIsLender: {
			type: Boolean as PropType<boolean | null>,
			default: null
		},
		canSubmitApplicationWithoutESignatures: {
			type: Boolean as PropType<boolean | null>,
			default: null
		},
		isValidatingOrSubmitting: {
			type: Boolean as PropType<boolean>,
			default: null
		},
		allRecipientsHaveSignedAgreement: {
			type: Boolean as PropType<boolean | null>,
			default: null
		},
		adobeAgreementCreated: {
			type: Boolean as PropType<boolean | null>,
			default: null
		},
		applicationIdAsNumber: {
			type: Number as PropType<number | null>,
			default: null
		}
	},
	data() {
		return {};
	},
	computed: {},
	mounted() {},
	methods: {
		showRecreateSignatureModal() {
			this.$emit('showRecreateSignatureModal');
		},
		submitApplicationWithoutESignatures() {
			this.$emit('submitApplicationWithoutESignatures');
		},
		submitApplication() {
			this.$emit('submitApplication');
		},
		sendEmailsForSignatures() {
			this.$emit('sendEmailsForSignatures');
		}
	}
});
