module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div>\r\n	<div ref="modal">\r\n		<header class="modal-header">\r\n			<h2 class="modal-title">' +
((__t = ( ctx.modalTitle )) == null ? '' : __t) +
'</h2>\r\n		</header>\r\n		<div class="modal-body" role="form">\r\n			';
 if (ctx.popupState === 'MainMenu') { ;
__p += '\r\n			<div class="pb-1 d-flex justify-content-between align-items-center" data-test-id="MainMenu">\r\n				<h5 class="mb-0">Select from related applicants or:</h5>\r\n				<button id="AddNewCustomerButton" class="btn btn-primary" ref="addNewCustomerButton" data-test-id="addNewCustomerButton">\r\n					Add New\r\n				</button>\r\n			</div>\r\n\r\n			';
 if (ctx.applicationEntities && ctx.applicationEntities.length > 0) { ;
__p += '\r\n			<div class="list-group">\r\n				';
 for(var i=0; i < ctx.applicationEntities.length; i++) { ;
__p += '\r\n				<button type="button" \r\n					class="list-group-item list-group-item-action" \r\n					ref="addExistingEntity" \r\n					data-test-id="' +
((__t = ( ctx.applicationEntities[i].value )) == null ? '' : __t) +
'" \r\n					id="' +
((__t = ( ctx.applicationEntities[i].value )) == null ? '' : __t) +
'"\r\n					>\r\n					' +
((__t = ( ctx.applicationEntities[i].label )) == null ? '' : __t) +
'\r\n				</button>\r\n				';
 } ;
__p += '\r\n			</div>\r\n			';
 } else { ;
__p += '\r\n			<div class="list-group" data-test-id="NoRelatedApplications">\r\n				<div class="list-group-item">No related applicants</div>\r\n			</div>\r\n			';
 } ;
__p += '\r\n			';
 if (ctx.isLender) { ;
__p += '\r\n			<div class="mt-4">\r\n				<h5>Search all customers (internal-only):</h5>\r\n				<div ref="customerSearch" class="mt-2" data-test-id="customerSearch">\r\n					' +
((__t = ( ctx.customerSearch )) == null ? '' : __t) +
'\r\n				</div>\r\n			</div>\r\n			';
 } ;
__p += ' \r\n			';
 } else if (ctx.popupState === 'AddNewEntityMapping' || ctx.popupState === 'EditEntity') { ;
__p += '\r\n			<div ref="addCustomerForm" data-test-id=\'AddNewEntityMapping\'>\r\n				' +
((__t = ( ctx.addCustomerDataFields )) == null ? '' : __t) +
'\r\n			</div>\r\n			';
 } ;
__p += '\r\n		</div>\r\n	</div>\r\n\r\n	';
 if (ctx.popupState === 'AddNewEntityMapping' || ctx.popupState === 'EditEntity') { ;
__p += '\r\n	<footer class="modal-footer">\r\n		<button data-test-id="AddCustomerSaveButton" id="AddCustomerSaveButton" class="btn btn-primary" ref="saveNewEntityButton">Save</button>\r\n        ';
 if (!ctx.bypassCustomerSearch) { ;
__p += '\r\n		    <button data-test-id="AddCustomerCancelButton" id="AddCustomerCancelButton" class="btn btn-secondary" ref="cancelButton">Cancel</button>\r\n        ';
 } ;
__p += '\r\n	</footer>\r\n	';
 } ;
__p += '\r\n</div>\r\n';
return __p
}