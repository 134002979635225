import { DataObject, TemplateDataField } from '@sageworks/dynamic-forms';
import { LoanRoleDataModel, DataObject as DataObjectModel } from '@sageworks/jpi';

const getForgeMappingId = (defaultTemplateFieldId: TemplateDataField, templateDataFieldMappings: any) => {
	return templateDataFieldMappings?.[defaultTemplateFieldId];
};

const getValue = (dataObject: DataObjectModel, defaultDataFieldId: TemplateDataField, templateDataFieldMappings: any): string => {
	const dataFieldId = getForgeMappingId(defaultDataFieldId, templateDataFieldMappings);
	const value = dataObject?.values?.find(value => value.id === dataFieldId)?.value;
	return typeof value === 'object' ? JSON.stringify(value) : value ?? '';
};

export const getDescription = (dataObject: DataObjectModel, type: DataObject.TypeEnum, templateDataFieldMappings: any): string => {
	switch (type) {
		case DataObject.TypeEnum.Person:
			return (
				getValue(dataObject, TemplateDataField.PersonalFirstName, templateDataFieldMappings) +
				' ' +
				getValue(dataObject, TemplateDataField.PersonalLastName, templateDataFieldMappings)
			);
		case DataObject.TypeEnum.Business:
			return getValue(dataObject, TemplateDataField.BusinessName, templateDataFieldMappings);
		case DataObject.TypeEnum.NonProfit:
			return getValue(dataObject, TemplateDataField.NonprofitName, templateDataFieldMappings);
		case DataObject.TypeEnum.Farm:
			return getValue(dataObject, TemplateDataField.FarmName, templateDataFieldMappings);
		default:
			return '';
	}
};

export const getRowDetails = (
	dataObjectsDetails: Array<{ type: DataObject.TypeEnum; dataObject: DataObjectModel }> | null,
	templateDataFieldMappings: { [templateDataFieldId: number]: number }
): string[] | undefined => {
	let entityDetails: string[] = [];
	dataObjectsDetails?.forEach(dataObjectDetails => {
		entityDetails.push(getDescription(dataObjectDetails.dataObject, dataObjectDetails.type, templateDataFieldMappings));
	});
	return entityDetails;
};

export const getDataObjectsDetails = (
	mapping: LoanRoleDataModel | Array<LoanRoleDataModel>,
	dataObjects: { [p: string]: DataObjectModel[] }
): Array<{ type: DataObject.TypeEnum; dataObject: DataObjectModel }> | null => {
	if (!mapping) return null;
	const mappings = Array.isArray(mapping) ? mapping : [mapping];
	const customerIds = mappings.map(x => x.customerID);

	if (!customerIds.length) return null;

	let dataObjectsDetails = Object.keys(dataObjects)
		.flatMap(key =>
			dataObjects[key].filter(dataObject => customerIds.includes(dataObject.rowId)).map(dataObject => ({ type: key as DataObject.TypeEnum, dataObject }))
		) // order is important since we use the index to call EditRow
		.sort((a, b) => customerIds.indexOf(a.dataObject.rowId) - customerIds.indexOf(b.dataObject.rowId));

	return dataObjectsDetails.length ? dataObjectsDetails : null;
};
