import { CustomComponentType } from '../../../enums/custom-component-type';

const createGeneratedScriptTextAreaSchema = (label: string, value: string) => ({
	label,
	type: CustomComponentType.extendedTextArea,
	showInCard: true,
	clearOnHide: false,
	conditional: {
		show: true,
		when: 'tempScriptType',
		eq: value,
	},
	wysiwyg: {
		theme: 'ace/theme/dracula',
		maxLines: 12,
		minLines: 12,
		tabSize: 2,
		mode: 'ace/mode/javascript',
	},
	key: value,
	rows: 5,
	editor: 'ace',
	as: 'javascript',
	input: true
});

export default [
	{
		key: 'script-conditional',
		weight: -10,
		components: [
			{
				label: 'Select your code destination',
				type: CustomComponentType.extendedSelectField,
				key: 'tempScriptType',
				uniqueOptions: true,
				customOptions: {
					shouldSort: true
				},
				data: {
					values: [
						{ label: 'Default Value', value: 'customDefaultValue' },
						{ label: 'Calculated Value', value: 'calculateValue' },
					],
				}
			},
			{
				type: CustomComponentType.extendedTextArea,
				input: true,
				label: 'Enter your prompt:',
				key: 'userInput',
				dataSrc: '',
				data: {},
				dataType: 'string'
			},
			{
				type: 'button',
				label: 'Generate',
				key: 'generateButton',
				action: 'custom',
				custom: `const userInput = utils.getComponent(instance.parent.components, 'userInput');
								var mappings = [];
								   utils.eachComponent(instance.options.editForm.components, (component) => 
									{
									 if (component.dataFieldId != null) {
									  mappings.push({label: component.label, id: component.dataFieldId});
									 }  
									}, 
								   true);
								
								const scriptTypeComponent = utils.getComponent(instance.parent.components, 'tempScriptType');
								const property = scriptTypeComponent.dataValue;

								const requestBody = {
									UserPrompt: userInput.dataValue,
									MappingTable: mappings
								};
								
								const propertyComponent = utils.getComponent(instance.parent.components, property);
								Formio.makeRequest(undefined, '', 'http://loan-genie-alb-1443357119.us-east-2.elb.amazonaws.com/dynamic-application/open-ai', 'post', requestBody, null)
									.then(response => {
										var cleaned = response.replace(/\`|javascript/g, '');
										propertyComponent.setValue(cleaned);
									})
									.catch(error => {
										console.error('Error generating script:', error);
									});`
			},
			createGeneratedScriptTextAreaSchema('Generated Script: Calculated Value', 'calculateValue'),
			createGeneratedScriptTextAreaSchema('Generated Script: Default Value', 'customDefaultValue')
		]
	}
];
