
import BaseFormBuilder from './base-form-builder/base-form-builder.vue';
import { ValidationBuilder } from '../utils/validation-builder';
import { FormioBuilderOptionsSchema, FormioBuilderGroupSchema } from '../formio-interfaces/FormioBuilderOptionsSchema';
import { OnlinePortalWidgetBuilderOptionsSchema } from '../formio-interfaces/OnlinePortalWidgetBuilderOptionsSchema';
import { CustomComponentType, CustomBuilderGroups } from '../enums';
import { AutoFactory, FeatureFlagging, FeatureFlaggingService } from '@sageworks/jpi';

// using this syntax for the mixin as it keeps the linter/typescript happy and allows base-mixin methods/props to be seen properly
export default BaseFormBuilder.extend({
	name: 'PreApplicationTemplateBuilder',
	data() {
		return {
			enableCustomInputsForPreApplicationConfiguration: false
		};
	},
	computed: {
		builder(): FormioBuilderOptionsSchema {
			const builder: OnlinePortalWidgetBuilderOptionsSchema = {
				[CustomBuilderGroups.preAppWidgets]: {
					title: 'Widgets',
					weight: 2,
					default: true
				} as FormioBuilderGroupSchema
			};

			if (this.enableCustomInputsForPreApplicationConfiguration) {
				builder.basic = undefined; // undefined overrides the default value of false
			}

			return builder;
		}
	},
	methods: {
		validateForm(): string[] {
			return new ValidationBuilder((this as any).form.components)
				.validateForMinUsages(1, { type: CustomComponentType.productSelection }, 'Pre-application must include a Product Selection widget.')
				.validateForMaxUsages(1, { type: CustomComponentType.productSelection }, 'Only one Product Selection widget is allowed on the Pre-application.')
				.buildValidations();
		}
	},
	async mounted() {
		const featureFlaggingService = AutoFactory.get(FeatureFlaggingService);
		this.enableCustomInputsForPreApplicationConfiguration = (await featureFlaggingService.getActiveFeatures())?.includes(
			FeatureFlagging.FeatureFlagEnum.EnableCustomInputsInDynamicAppPreApp
		);
	}
});
