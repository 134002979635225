import { BaseRoleRepeaterWidget } from '../base/base-role-repeater-widget/base-role-repeater-widget';
import { AddCustomerResult } from './add-customer-result';
import BorrowerAccessPopup, { BorrowerAccessModalState } from './borrower-access-popup';
import { PopupWrapper } from '../popup';
import { Customer } from '@sageworks/jpi';
import { FetchDataType } from '../../../enums';
import { ComponentDataHelper } from '../../../utils';

export interface BorrowerAccessModalResult {
	canceled: boolean;
	modalState?: BorrowerAccessModalState;
}

export default class BorrowerAccessPopupHelper {
	public static async handleBorrowerAccess(
		component: BaseRoleRepeaterWidget,
		rowIndex: number,
		addCustomerResult: AddCustomerResult,
		priorModalState: BorrowerAccessModalState | undefined = undefined
	): Promise<BorrowerAccessModalResult> {
		if (await this.shouldShowBorrowerAccessPopup(component, addCustomerResult)) {
			const borrowerAccessPopup = this.createBorrowerAccessPopup(component, priorModalState);
			const borrowerAccessPopupWrapper = new PopupWrapper({}, {}, {}, borrowerAccessPopup);
			borrowerAccessPopupWrapper.showPopup();
			const result: BorrowerAccessModalState = await borrowerAccessPopupWrapper.actionCompleted;
			if (!result || result.canceled) {
				component.cancelRow(rowIndex);
				return {
					canceled: true
				};
			} else if (!result.canEnterBorrowerInfo) {
				return {
					canceled: false,
					modalState: result
				};
			}
		}
		return { canceled: false };
	}

	private static async shouldShowBorrowerAccessPopup(component: BaseRoleRepeaterWidget, addCustomerResult: AddCustomerResult) {
		return (
			addCustomerResult.customerType === Customer.TypeEnum.Person &&
			!component.isLender &&
			(await ComponentDataHelper.fetchData<boolean>(component, {
				fetchType: FetchDataType.UseBorrowerAccessRestrictions,
				fetchContext: {}
			})) &&
			(await ComponentDataHelper.fetchData<boolean>(component, {
				fetchType: FetchDataType.AllowBorrowerToSendInvites,
				fetchContext: {}
			}))
		);
	}

	private static createBorrowerAccessPopup(component: BaseRoleRepeaterWidget, priorModalState: BorrowerAccessModalState | undefined) {
		const popup = new BorrowerAccessPopup(
			BorrowerAccessPopup.schema({
				roleType: component.roleType
			}),
			{
				...component.options,
				skipInit: false
			},
			{}
		);
		popup.modalState = priorModalState ?? popup.modalState;
		return popup;
	}
}
