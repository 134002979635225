
import Vue from 'vue';
import { BvEvent } from 'bootstrap-vue';

export default Vue.extend({
	name: 'ConfirmReasonModal',
	props: {
		id: { type: String, default: null },
		title: { type: String, default: null },
		reasonLabel: { type: String, default: 'Reason:' },
		reasonPlaceholder: { type: String, default: 'Enter a reason...' },
		noReasonErrorMessage: { type: String, default: 'Please enter a reason for this request' }
	},
	data() {
		return {
			reason: '' as string,
			reasonValidity: null as boolean | null,
			reasonInvalidLengthMessage: 'Reasons can only be 500 characters long',
			invalidMessage: ''
		};
	},
	computed: {
		textAreaId(): string {
			return `${this.id}-reasonTextArea`;
		}
	},
	methods: {
		saveReason(modalEvent: BvEvent) {
			const maySave = this.validateReason();
			if (maySave) {
				this.$emit('save', { reason: this.reason });
			} else {
				modalEvent.preventDefault();
			}
		},
		async resetReason() {
			this.reason = '';
		},
		validateReason() {
			if (!this.reason) {
				this.reasonValidity = false;
				this.invalidMessage = this.noReasonErrorMessage;
				return false;
			}
			if (this.reason.length > 500) {
				this.reasonValidity = false;
				this.invalidMessage = this.reasonInvalidLengthMessage;
				return false;
			}
			this.reasonValidity = null;
			return true;
		}
	}
});
