/**
 * This component's purpose is to mediate between the pull credit input and the
 * credentials input. It shows the appropriate one when the window is opened,
 * and swaps between them.
 */

import Component from 'formiojs/components/_classes/component/Component';
import { CreditReportCredential } from '@sageworks/jpi';
import { CustomComponentType, ContextDataProperty } from '../../../../enums';
import CreditReportPullCreditInput from '../credit-report-pull-credit-input/credit-report-pull-credit-input';
import CreditReportCredentialsInput from '../credit-report-credentials-input/credit-report-credentials-input';

import { getDefaultCredential } from '../helpers';
import { getContextDataValue } from '../../../../utils/context-data-helper/context-data-helper';
import { IPopupBodyComponent } from '../../popup';

enum PopupState {
	PullCredit = 'PullCredit',
	Credentials = 'Credentials'
}

export default class CreditReportPullCreditPopup extends Component implements IPopupBodyComponent {
	public preClose = () => {
		if (this.isLoading) {
			return Promise.reject();
		}

		return Promise.resolve();
	};

	public resultPromise!: Promise<any>;
	public resultPromiseResolve: any;

	private get userInfo() {
		return getContextDataValue(this, ContextDataProperty.CurrentUserInfo);
	}
	private get isLender() {
		return this.userInfo == null || this.userInfo?.proxyUserId != null;
	}

	private get authorizationMetadataId(): number {
		return this.component.authorizationMetadataId;
	}

	private get activeCredentialId(): number | null {
		let credential = null;

		if (this.selectedUcsCredential) {
			credential = this?.selectedUcsCredential?.id ?? null;
		} else if (!this.isLender) {
			credential = this.defaultClientCredential;
		} else {
			credential = this?.defaultLenderCredential?.id ?? null;
		}

		return credential;
	}
	private get defaultClientCredential(): null | number {
		return this.component?.externalIntegrationUniversalCreditReportOptionsID;
	}
	private defaultLenderCredential!: CreditReportCredential | null;
	private selectedUcsCredential: CreditReportCredential | null = null;

	public get customerId(): number {
		return this?.component?.customerId;
	}
	public get dataFields(): any {
		return this?.component?.dataFields;
	}

	private state: PopupState = PopupState.PullCredit;

	public get isLoading() {
		return this.creditReportPullCreditInput?.isLoading ?? false;
	}

	private creditReportPullCreditInput!: CreditReportPullCreditInput;
	private creditReportCredentialsInput!: CreditReportCredentialsInput;

	private get currentChildComponent(): Component {
		if (this.state === PopupState.Credentials) {
			return this.creditReportCredentialsInput;
		} else if (this.state === PopupState.PullCredit) {
			return this.creditReportPullCreditInput;
		} else {
			return {} as Component;
		}
	}

	public static schema(...extend: any) {
		return Component.schema(
			{
				label: 'Credit Authorization',
				type: CustomComponentType.creditReportPullCreditInput,
				key: CustomComponentType.creditReportPullCreditInput,
				customerId: -1,
				dataFields: [],
				authorizationMetadataId: -1,
				defaultCredentialsId: -1,
				selectedCredentialsId: -1,
				input: false
			},
			...extend
		);
	}

	public init() {
		super.init();

		this.resultPromise = new Promise(r => (this.resultPromiseResolve = r));
	}

	public render(): any {
		let subComponentTemplate = this?.currentChildComponent?.render();

		return this.renderTemplate('creditReportPullCreditPopup', {
			subComponentTemplate,
			isLender: this.isLender,
			state: this.state,
			title: 'Authorize Credit',
			credentialsName: this?.selectedUcsCredential?.name
		});
	}

	public async attach(element: any) {
		await super.attach(element);

		await this.getDefaultCredentialNameAndRedraw();

		this.loadRefs(element, {
			dialogPullCreditButton: 'single',
			otherCredentialsLink: 'single',
			subComponentTemplateSlot: 'single'
		});

		const { otherCredentialsLink, subComponentTemplateSlot } = this.refs as any;

		this.addEventListener(otherCredentialsLink, 'click', this.onOtherCredentialsLinkClick);
		this.component.selectedCredentialsId = this.activeCredentialId;

		await this?.currentChildComponent?.attach(subComponentTemplateSlot);
	}

	// eslint-disable-next-line max-lines-per-function
	private async getDefaultCredentialNameAndRedraw() {
		this.defaultLenderCredential = await getDefaultCredential();
		if (!this.selectedUcsCredential && this.defaultLenderCredential) {
			this.selectedUcsCredential = this.defaultLenderCredential;

			this.creditReportPullCreditInput = new CreditReportPullCreditInput(
				CreditReportPullCreditInput.schema({
					customerId: this.customerId,
					dataFields: this.dataFields,
					authorizationMetadataId: this.authorizationMetadataId,
					defaultCredentialsId: this.activeCredentialId
				}),
				{
					...this.options,
					parent: this,
					skipInit: false,
					afterPullComplete: async (metadata: any) => {
						this.resultPromiseResolve(metadata);
					}
				},
				this.data
			);

			this.creditReportCredentialsInput = new CreditReportCredentialsInput(
				CreditReportCredentialsInput.schema({}),
				{
					parent: this,
					selectCredential: (selectedCredential: CreditReportCredential | null) => {
						if (selectedCredential) {
							this.selectedUcsCredential = selectedCredential;
							this.component.selectedCredentialsId = selectedCredential.id;
						}
						this.state = PopupState.PullCredit;

						this.redraw();
					}
				},
				this.data
			);

			this.redraw();
		}
	}

	/**
	 *  Arrow functions to preserve `this` property
	 */

	private onOtherCredentialsLinkClick = (e: any) => {
		// Since this is a link, we need a way to prevent the link click from
		// going through
		e.preventDefault();

		this.state = PopupState.Credentials;
		this.redraw();
	};
}
