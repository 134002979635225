import { CustomComponentType, TemplateDataField } from '../../../../enums';
import { formatDataFieldIdKey } from '../../simple-inputs/extended-field-helper';

export function getRowDescription(row: any, componentOptions: any) {
	if (row.data?.[CustomComponentType.businessInfo] != null) {
		const businessNameDataFieldId = componentOptions?.contextData?.templateDataFieldMappings?.[TemplateDataField.BusinessName];
		return `Business | ${row.data?.[CustomComponentType.businessInfo].data?.[formatDataFieldIdKey(businessNameDataFieldId)] || ''}`;
	} else if (row.data?.[CustomComponentType.farmInfo] != null) {
		const farmNameDataFieldId = componentOptions?.contextData?.templateDataFieldMappings?.[TemplateDataField.FarmName];
		return `Farm | ${row.data?.[CustomComponentType.farmInfo].data?.[formatDataFieldIdKey(farmNameDataFieldId)] || ''}`;
	}
	return null;
}
