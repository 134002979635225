import { defineModule, localActionContext } from 'direct-vuex';
import { AutoFactory, FeatureFlagging, FeatureFlaggingService } from '@sageworks/jpi';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => localActionContext(context, FeatureFlagModule);

export interface FeatureFlagModuleState {
	activeFeatures: Set<FeatureFlagging.FeatureFlagEnum> | null;
}
const FeatureFlagModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			activeFeatures: null
		} as FeatureFlagModuleState;
	},
	mutations: {
		SET_ACTIVE_FEATURES(state, features: Set<FeatureFlagging.FeatureFlagEnum>) {
			state.activeFeatures = features;
		}
	},
	actions: {
		async fetchActiveFeatures(context): Promise<void> {
			const { commit, state } = actionContext(context);

			if (state.activeFeatures !== null) {
				return;
			}

			const featureFlaggingService = AutoFactory.get(FeatureFlaggingService);
			const features = await featureFlaggingService.getActiveFeatures();
			commit.SET_ACTIVE_FEATURES(new Set<FeatureFlagging.FeatureFlagEnum>(features as FeatureFlagging.FeatureFlagEnum[]));
		},
		async fetchIsFeatureFlagActive(context, feature: FeatureFlagging.FeatureFlagEnum): Promise<boolean> {
			const { state, dispatch } = actionContext(context);

			if (state.activeFeatures === null) {
				await dispatch.fetchActiveFeatures();
			}

			if (state.activeFeatures === null) {
				throw new Error('activeFeatures should have been filled by prior fetch call');
			}

			return state.activeFeatures.has(feature);
		}
	}
});

export default FeatureFlagModule;
