
import { Component, Mixins } from 'vue-property-decorator';
import { RouteMixin } from '@/mixins';
import { IdentityVerificationHeader } from '@/components';
import { IdentityVerificationResponse, IdentityVerificationResponseQuestion } from '@sageworks/jpi';
import { getFailureOptions, getSuccessOptions } from '@sageworks/dynamic-forms/src/utils/toastr-utils';

@Component({
	components: { IdentityVerificationHeader }
})
export default class VerifyKnowledgeBasedAnswers extends Mixins(RouteMixin) {
	submitting = false;
	rememberThisDevice = true;
	showValidationState = false;

	getValidationState(question: IdentityVerificationResponseQuestion) {
		if (!this.showValidationState || !!(question as any).answer) {
			return null;
		}
		return false;
	}

	get validationStateByIndex() {
		const dictionary = new Map<number, boolean | null>();

		for (let i = 0; i < this.verificationResponse.questions?.length ?? 0; i++) {
			dictionary.set(i, this.getValidationState(this.verificationResponse.questions[i]));
		}
		return dictionary;
	}

	get verificationResponse(): IdentityVerificationResponse {
		return this.directStore.state.Login.identityVerificationResponse;
	}

	async submit() {
		if (this.submitting) {
			return;
		}
		this.showValidationState = true;
		for (const entry of this.validationStateByIndex.values()) {
			if (entry === false) {
				return;
			}
		}
		this.submitting = true;
		if (await this.submitKBAs()) {
			await this.logIn();
			await this.$router.push(this.OnlinePortalRoutes.Home.path);
		}
		this.submitting = false;
	}
	async submitKBAs(): Promise<boolean> {
		let isVerified = false;
		try {
			await this.directStore.dispatch.Login.verifyKnowledgeBasedAnswers(this.rememberThisDevice);
			this.$root.$bvToast.toast(`Identity successfully verified!.`, getSuccessOptions());
			isVerified = true;
		} catch {
			this.$root.$bvToast.toast(
				`Could not verify the responses to the knowledge based questions. Please ensure all questions have been answered correctly.`,
				getFailureOptions()
			);
		}
		return Promise.resolve(isVerified);
	}
	async logIn() {
		try {
			await this.directStore.dispatch.Authentication.login({
				username: this.directStore.state.Login.userEmail,
				secret: this.directStore.state.Login.userPassword
			});
		} catch {
			// This should not be possible unless password changed in the short time
			// between entering it the first time and submitting ID verification
			this.$root.$bvToast.toast(`An error occurred logging in.`, getFailureOptions());
		}
	}
}
