
import { DataObject } from '../../enums';
import { BeneficialOwnerWithCustomerType,
	LoanRoleWithCustomerType, ObjectPropertyValuesWithRoleType, RelatedRoleWithCustomerType } from '../../models';
import {
	AggregatedFormDataModel,
	BeneficialOwnerFormDataModel,
	Customer,
	LoanRoleDataModel,
	DataObject as DataObjectModel,
	ProposedLienData, 
	RelatedLoanRoleData,
	DataFieldValue} from '@sageworks/jpi';
import { DataObjectsByTypeFactory } from '..';
import { convertBenOwnerRoleIntEnumToStringEnum, convertBenOwnerRoleStringEnumToIntEnum } from '../beneficial-owner-utils/beneficial-owner-utils';

export class CustomerTypeLookup extends Map<number, Customer.TypeEnum> { }

export const createCustomerTypeLookup = (dataObjects: { [key: string]: Array<DataObjectModel>; }) => {
	const customerTypeLookup = new CustomerTypeLookup(); 

	(dataObjects[DataObject.TypeEnum.Person] ?? [])
		.forEach(x => customerTypeLookup.set(x.rowId as number, Customer.TypeEnum.Person));
	(dataObjects[DataObject.TypeEnum.Business] ?? [])
		.forEach(x => customerTypeLookup.set(x.rowId as number, Customer.TypeEnum.Business));
	(dataObjects[DataObject.TypeEnum.NonProfit] ?? [])
		.forEach(x => customerTypeLookup.set(x.rowId as number, Customer.TypeEnum.NonProfit));
	(dataObjects[DataObject.TypeEnum.Farm] ?? [])
		.forEach(x => customerTypeLookup.set(x.rowId as number, Customer.TypeEnum.Farm));
		
	return customerTypeLookup;
};

export const getProposedLoanId = (dataObjects: { [key: string]: Array<DataObjectModel>; }) => {
	const [proposedLoan] = dataObjects ? (dataObjects[DataObject.TypeEnum.ProposedLoan] ?? []) : [];

	return proposedLoan?.rowId;
};

export const convertDataObject = (dataObject: DataObjectModel) => {
	return { id: dataObject.rowId, dataFieldValues: dataObject.values as Array<DataFieldValue> } as ObjectPropertyValuesWithRoleType;
};

export const convertDataObjects = (dataObjects: { [key: string]: Array<DataObjectModel>; }) => {
	const formattedDataObjects = DataObjectsByTypeFactory.createDataObjectByType();

	for (const type of Object.values(DataObject.TypeEnum)) {
		formattedDataObjects[type] = (dataObjects[type] ?? [])
			.map(x => convertDataObject(x));
	}
	return formattedDataObjects;
};

export const convertLoanRoleData = (loanRole: LoanRoleDataModel, customerTypeLookup: CustomerTypeLookup, proposedLoanId?: number): LoanRoleWithCustomerType => {
	return {
		id: loanRole.dataObjectID,
		customerId: loanRole.customerID,
		proposedLoanId: proposedLoanId,
		customerType: loanRole.customerID ? customerTypeLookup.get(loanRole.customerID) : undefined,
		roleType: loanRole.roleType,
	};
};

export const convertAllLoanRoleData = (
	aggregatedFormData: AggregatedFormDataModel,
	dataObjects: { [key in string]: DataObjectModel[] }, customerTypeLookup: CustomerTypeLookup) => {
	const {
		primaryBorrowerMapping,
		coBorrowerMappings = [],
		coSignerMappings = [],
		creditApplicantMappings = [],
		trusteeMappings = [],
		guarantorMappings = [] } = aggregatedFormData;
	
	const proposedLoanId = getProposedLoanId(dataObjects);

	return [
		...primaryBorrowerMapping ? [convertLoanRoleData(primaryBorrowerMapping, customerTypeLookup, proposedLoanId)] : [],
		...coBorrowerMappings.map(x => convertLoanRoleData(x, customerTypeLookup, proposedLoanId)),
		...coSignerMappings.map(x => convertLoanRoleData(x, customerTypeLookup, proposedLoanId)),
		...creditApplicantMappings.map(x => convertLoanRoleData(x, customerTypeLookup, proposedLoanId)),
		...trusteeMappings.map(x => convertLoanRoleData(x, customerTypeLookup, proposedLoanId)),
		...guarantorMappings.map(x => convertLoanRoleData(x, customerTypeLookup, proposedLoanId)),
	];
};

export const convertRelatedRoleData = (authorizedSignerDataList: RelatedLoanRoleData[], customerTypeLookup: CustomerTypeLookup) => {
	return authorizedSignerDataList
		.map(x => ({
			id: x.id,
			roleType: x.roleType,
			parentLoanRoleId: x.parentLoanRoleID,
			customerId: x.customerID,
			customerType: x.customerID ? customerTypeLookup.get(x.customerID) : null
		} as RelatedRoleWithCustomerType)); 
};

export const convertAllRelatedRoleData = (aggregatedFormData: AggregatedFormDataModel, customerTypeLookup: CustomerTypeLookup) => {
	const { authorizedSigners = [] } = aggregatedFormData;

	return [
		...convertRelatedRoleData(authorizedSigners, customerTypeLookup),
	];
};

export const convertBeneficialOwnerData = (beneficialOwnerDataList: BeneficialOwnerFormDataModel[]) => {
	return beneficialOwnerDataList
		.map(x => ({
			id: x.id,
			role: convertBenOwnerRoleIntEnumToStringEnum(x.role),
			lastUpdated: x.lastUpdated,
			ownershipPercent: x.ownershipPercent,
			ownerCustomerId: x.ownerCustomerId,
			ownerCustomerType: x.ownerEntityType,
			customerId: x.customerId,
		} as BeneficialOwnerWithCustomerType));
};

export const convertBeneficialOwnerDataWithCustomerTypeToFormDataModel = (beneficialOwnerDataList: BeneficialOwnerWithCustomerType[]) => {
	return beneficialOwnerDataList
		.map(x => ({
			id: x.id,
			role: convertBenOwnerRoleStringEnumToIntEnum(x.role),
			lastUpdated: x.lastUpdated,
			ownershipPercent: x.ownershipPercent,
			ownerCustomerId: x.ownerCustomerId,
			ownerEntityType: x.ownerCustomerType,
			customerId: x.customerId,
		} as BeneficialOwnerFormDataModel));
};

export const convertProposedLienData = (proposedLienDataList: ProposedLienData[], dataObjects: { [key in string]: DataObjectModel[] }) => {
	const proposedLoanId = getProposedLoanId(dataObjects);

	return proposedLienDataList
		.map(x => ({
			id: x.id,
			collateralId: x.collateralID,
			proposedLoanId,
		}));
};