
import { Component, Vue, Watch } from 'vue-property-decorator';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { NavigationCardGroup } from '@/models/NavigationCardGroup';
import { transformDocRequestsToCards } from '@/logic/DocumentRequest.service';

@Component({
	components: {
		FontAwesomeIcon
	}
})
export default class DocumentsUpdated extends Vue {
	private REQUEST_PAGE_SIZE = 10;

	private currentPage = 1;
	public filters = [
		{ text: 'Outstanding', value: 'outstanding', emptyMessage: 'No documents currently required.' },
		{ text: 'Completed', value: 'completed', emptyMessage: 'No documents uploaded.' }
	];

	private adobeTemplateStatuses = [];

	data() {
		return {
			currentPageDocumentRequestCards: [] as Array<NavigationCardGroup>
		};
	}

	async beforeMount() {
		await this.getCurrentPageDocumentRequestCards();
	}

	async getCurrentPageDocumentRequestCards(): Array<NavigationCardGroup> {
		let isLenderView = this.directStore.getters.User.isLender;
		var documentRequests = await this.directStore.state.DocumentRequest.currentPageDocumentRequests;
		var adobeStatusPromises = [];
		for (var i = 0; i < documentRequests.length; i++) {
			const statusPromise = this.directStore.dispatch.AdobeESignModule.fetchTemplateStatus({
				documentRequestId: documentRequests[i].id,
				documentNamesId: documentRequests[i].documentNamesId
			});
			adobeStatusPromises.push(statusPromise);
			statusPromise.then(result => {
				var index = adobeStatusPromises.findIndex(element => {
					if (element === statusPromise) {
						return true;
					}
				});
				this.adobeTemplateStatuses[documentRequests[index].id] = result;
			});
		}
		Promise.all(adobeStatusPromises).then(() => {
			this.currentPageDocumentRequestCards = transformDocRequestsToCards(documentRequests, isLenderView, this.$router, this.adobeTemplateStatuses);
		});
	}
	private async mounted(): Promise<void> {
		this.currentPage = Number(this.$route.query.page) || this.currentPage;
		await this.onPageChange();
	}

	@Watch('currentPage')
	private async onPageChange() {
		await this.directStore.dispatch.DocumentRequest.loadPagedDocumentRequests({ page: this.currentPage, perPage: this.REQUEST_PAGE_SIZE });
	}

	documentsExist() {
		for (let group of this.currentPageDocumentRequestCards) {
			if (group.navigationCards.length > 0) {
				return true;
			}
		}
		return false;
	}
}
