import { isNullOrUndefined } from './comparison-helper';
// this seems not ideal but couldn't figure out how to import moment :(
let moment = require('moment');

export function formatAsShortDateString(date: any): string {
	return new Date(date).toLocaleDateString();
}

export function formatAsMonthAndYear(date: any): string {
	let dateToFormat = new Date(date);

	if (isNullOrUndefined(date)) {
		return 'Today';
	}

	return moment(dateToFormat).format('MMM YYYY');
}

export function formatAsArchiveBalancesString(date: any): string {
	if (date == null) {
		return 'Today\'s Balances';
	} else {
		return moment(new Date(date)).format('MMM YYYY') + ' - Balances';
	}
}

function getTwoDigitDay(dateObj: Date): String {
	return getTwoDigitNumber(dateObj.getDate());
}

function getTwoDigitMonth(dateObj: Date): String {
	let month = getOneIndexedMonth(dateObj);
	return getTwoDigitNumber(month);
}

function getTwoDigitNumber(n: number): String {
	let stringRepresentation = n.toString();

	if (stringRepresentation.length === 1) {
		stringRepresentation = `0${stringRepresentation}`;
	}

	return stringRepresentation;
}

function getOneIndexedMonth(dateObj: Date): number {
	return dateObj.getMonth() + 1;
}

/**
 * The JPI Expects its dates to be in the format YYYY-MM-DD.
 * Given a valid Date object, this should return the appropriate date
 */
export function formatDateForJpi(dateObj: Date) {
	return `${dateObj.getFullYear()}-${getTwoDigitMonth(dateObj)}-${getTwoDigitDay(dateObj)}`;
}
