import { LoanApplicationCreditAuthorization } from '@sageworks/jpi';
import moment from 'moment';

export class AuthorizationDisplayObjectFactory {
	static createAuthorizationDisplayObject(authorizationMetadata: LoanApplicationCreditAuthorization | null = null, username: string | null = '') {
		if (authorizationMetadata && authorizationMetadata?.authorizedDate) {
			const dateObject = moment(authorizationMetadata.authorizedDate, 'YYYY-MM-DD');
			return this.createDisplayObject(dateObject.isValid() ? dateObject.format('LL') : authorizationMetadata.authorizedDate, username);
		}

		return null;
	}

	static createCreditPullDisplayObject(authorizationMetadata: LoanApplicationCreditAuthorization | null = null, username: string | null = '') {
		if (authorizationMetadata && authorizationMetadata?.pullComplete) {
			const dateObject = moment(authorizationMetadata.pullDate, 'YYYY-MM-DD');
			return this.createDisplayObject(dateObject.isValid() ? dateObject.format('LL') : (authorizationMetadata.pullDate as any), username);
		}

		return null;
	}

	static createWaivedDisplayObject(authorizationMetadata: LoanApplicationCreditAuthorization | null = null, username: string | null = '') {
		if (authorizationMetadata && authorizationMetadata?.waivedDate) {
			const dateObject = moment(authorizationMetadata.waivedDate, 'YYYY-MM-DD');
			return this.createDisplayObject(dateObject.isValid() ? dateObject.format('LL') : (authorizationMetadata.waivedDate as any), username);
		}

		return null;
	}

	private static createDisplayObject(date: string, name?: string | null) {
		return {
			name,
			date
		};
	}
}
