
import Vue from 'vue';
import { HeaderTemplate } from '@/components/templates';
import { RouteMixin } from '@/mixins';
import { ToastrUtils } from '@sageworks/dynamic-forms';
import { UsableProducts } from '@sageworks/jpi';
import { ConfirmLogoutModal } from '@/components/confirm-logout-modal';
import { Modal } from 'bootstrap';
import { OnlinePortalRoutes } from '@/OnlinePortalRoutes';

export default Vue.extend({
	name: 'PortalHeader',
	components: { HeaderTemplate, ConfirmLogoutModal },
	mixins: [RouteMixin],
	props: {},
	data() {
		return {
			signingOut: false,
			modalComponent: null as Modal | null
		};
	},
	computed: {
		logoUrl: function(): string {
			return this.directStore.getters.StylingSettings.logoUrl;
		},
		activeTab: function(): string {
			if (this.$route.path.includes(OnlinePortalRoutes.Applications.path)) {
				return 'applications';
			} else if (this.$route.path.includes(OnlinePortalRoutes.Documents.path)) {
				return 'documents';
			} else if (this.$route.path.includes(OnlinePortalRoutes.Home.path)) {
				return 'home';
			} else if (this.$route.path.includes(OnlinePortalRoutes.MyInfo.path)) {
				return 'myInfo';
			} else {
				return '';
			}
		},
		applicationNumber: function(): string {
			const applicationNumber = this.directStore.state.LoanApplicationData.loanApplication?.applicationNumber ?? null;
			const routeName = this.$route?.name ?? null;
			return this.getApplicationNumberText(applicationNumber, this.isLender, routeName);
		},
		showHomeLink: function(): boolean {
			// Show 'Portal Home' link in the borrower view of Application pages only.
			return (
				!this.isLender &&
				this.$route.path.includes(OnlinePortalRoutes.Application.path) &&
				!this.$route.path.includes(OnlinePortalRoutes.Applications.path)
				// this last condition needed since /application is substring of /applications
			);
		},
		isLender: function(): boolean {
			return this.directStore.getters.User.isLender;
		},
		itemLabel: function(): string {
			if (this.hasCommunityLendingSubscription === undefined) {
				return '';
			}
			return this.hasCommunityLendingSubscription ? 'Request Form' : 'Application';
		},
		hasCommunityLendingSubscription: function(): boolean | undefined {
			return this.directStore.state.UsableProducts.usableProducts?.has('abrigoCommunityLending' as UsableProducts.ProductsEnum);
		}
	},
	async mounted() {
		await this.directStore.dispatch.UsableProducts.fetchUsableProducts();
	},
	methods: {
		getApplicationNumberText: function(applicationNumber: number | null, isLender: boolean, routeName: string | null): string {
			const applicationRoutes = [
				OnlinePortalRoutes.ApplicationForm.name,
				OnlinePortalRoutes.ApplicationDocumentation.name,
				OnlinePortalRoutes.ApplicationSubmit.name
			];
			const validRoute = routeName != null && applicationRoutes.indexOf(routeName) >= 0;
			const showApplicationNumber = applicationNumber && isLender && validRoute;
			return showApplicationNumber ? `${applicationNumber}` : '';
		},
		logout: async function(): Promise<void> {
			try {
				await this.directStore.dispatch.Authentication.logout();
			} catch {
				this.$root.$bvToast.toast('An error occured while attempting to log out! Please try again.', {
					...ToastrUtils.getFailureOptions()
				});
			}
		},
		showModal: function() {
			if (this.modalComponent == null) {
				this.modalComponent = new Modal(this.$refs.logoutModal.$refs.modalElement, { backdrop: 'static', keyboard: false });
			}
			this.modalComponent.show();
		},
		hideModal: function() {
			if (this.modalComponent == null) {
				this.modalComponent = new Modal(this.$refs.logoutModal.$refs.modalElement, { backdrop: 'static', keyboard: false });
			}
			this.modalComponent.hide();
		}
	}
});
