import Vue from 'vue';
import editForm from './investment-real-estate.form';
import { CustomComponentType, CustomComponentLabel, ContextDataProperty, TemplateDataField, EditRowState } from '../../../../enums';
import { FinancialSubaccount } from '../financial-subaccount/financial-subaccount';
import FormComponent from 'formiojs/components/form/Form';
import { getContextDataValue } from '../../../../utils/context-data-helper/context-data-helper';
import { LocateParentHelper } from '../../../../utils';
import {
	InvestmentRealEstateAddPopupCloseEvent,
	InvestmentRealEstateAddPopupCloseEventType,
	InvestmentRealEstateAddRowPopup
} from '../../../../components/vue-form-components/financial-subaccounts/investment-real-estate';
import { RealEstateProperty } from '@sageworks/jpi';
import { formatDataFieldIdKey } from '../../simple-inputs/extended-field-helper';
import Component from 'formiojs/components/_classes/component/Component';

export class InvestmentRealEstateWidget extends FinancialSubaccount {
	static schema(...extend: any) {
		return FinancialSubaccount.schema(
			{
				label: CustomComponentLabel()[CustomComponentType.investmentRealEstate],
				type: CustomComponentType.investmentRealEstate,
				key: CustomComponentType.investmentRealEstate,
				addAnother: 'Add Investment Real Estate'
			},
			...extend
		);
	}

	static editForm = editForm;

	get vue(): Vue {
		return getContextDataValue(this, ContextDataProperty.vueInstance);
	}

	protected get inputDataId() {
		return this.closestFinancialStatementParent?.dataValue?.data?.id;
	}

	protected descriptionPropertyKey = TemplateDataField.RealEstatePropertyName;
	protected valuePropertyKey = TemplateDataField.RealEstatePropertyValue;
	private vueAddPopup: any;
	private closestFinancialStatementParent: Component | undefined;

	constructor(component: any, options: any, data: object) {
		super(component, options, data);

		this.vueAddPopup = new InvestmentRealEstateAddRowPopup({});
		// vue instance may not be available in the builder so need to account for that
		this.vueAddPopup.$store = this.vue?.$store;
		this.vueAddPopup.$mount();
	}

	static get builderInfo() {
		return {
			title: CustomComponentLabel()[CustomComponentType.investmentRealEstate],
			group: '',
			weight: 10,
			schema: InvestmentRealEstateWidget.schema()
		};
	}

	async attach(element: any) {
		const result = await super.attach(element);

		element.appendChild(this.vueAddPopup.$el);

		return result;
	}

	render(children: any, topLevel?: boolean): string {
		// `closestFinancialStatementParent` is resolved here since data is not added to the component at the time of init()
		if (!this.closestFinancialStatementParent) {
			this.closestFinancialStatementParent = LocateParentHelper.locateClosestPfsParent(this);
		}

		return super.render(children, topLevel);
	}

	addRow(): any {
		this.component.modal = true;
		var newRow = undefined;

		try {
			newRow = super.addRow();
		} finally {
			this.component.modal = false;
		}

		return newRow;
	}

	addRowModal(rowIndex: number): any {
		var editRow = this.editRows[rowIndex];

		if (editRow.state === 'new') {
			this.vueAddPopup.$props.customerId = this.customerId;
			this.vueAddPopup.$props.inputDataId = this.inputDataId;
			this.vueAddPopup.showModal();
			this.vueAddPopup.$once('close', this.createAddPopupCloseHandler(rowIndex));
		} else {
			return super.addRowModal(rowIndex);
		}
	}

	createAddPopupCloseHandler(rowIndex: number) {
		return async ({ type, property }: InvestmentRealEstateAddPopupCloseEvent) => {
			if (type == null || type === InvestmentRealEstateAddPopupCloseEventType.Cancel) {
				this.cancelRow(rowIndex);
				return;
			}

			await this.saveNewRow(rowIndex, property);
		};
	}

	public async saveNewRow(rowIndex: number, property: RealEstateProperty) {
		const formComponent: FormComponent = this.editRows[rowIndex].components[0];
		await formComponent.subFormReady;

		const propertyIdFieldId = this.options?.contextData?.templateDataFieldMappings?.[TemplateDataField.RealEstatePropertyId];
		const propertyTypeFieldId = this.options?.contextData?.templateDataFieldMappings?.[TemplateDataField.RealEstatePropertyType];
		const propertyNameFieldId = this.options?.contextData?.templateDataFieldMappings?.[TemplateDataField.RealEstatePropertyName];

		formComponent.setValue(
			{
				...formComponent.subForm.getValue(),
				data: {
					[formatDataFieldIdKey(propertyIdFieldId)]: property.id,
					[formatDataFieldIdKey(propertyTypeFieldId)]: property.propertyType,
					[formatDataFieldIdKey(propertyNameFieldId)]: property.name
				}
			},
			{ fromSubmission: true }
		);

		// This will persist the row's data to the widget so during save it can access the data
		this.editRows[rowIndex].data = formComponent.data;

		// Ensure we save the row so it remains, then editRow() will help keep the row open so the
		// 	user can proceed to fill out data
		this.editRows[rowIndex].state = EditRowState.Draft;
		this.editRow(rowIndex);
	}
}
