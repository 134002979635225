
import { RenderUtils } from '../../utils';
import FormioForm from './formio-form.vue';

export default FormioForm.extend({
	name: 'EntityInfoForm',
	components: {
	},
	computed: {
		shouldShow(): boolean {
			return this.configurationLookup.value;
		},
	},
	methods: {
		getChildComponents(currentComponent: any): any {
			if (!this.shouldShow) {
				return [];
			}

			return RenderUtils.getChildComponents(currentComponent);
		},
	}
});
