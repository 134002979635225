import { StoreType } from '../../../../store';
import {
	CustomerCustomComponentTypes,
	DataObjectMapper,
	LoanRoleType,
	CustomComponentType,
	createFormObject,
	createBasicFactoryContext,
	DataObject,
	SubmitPayload,
	DeletePayload,
	ObjectPropertyValuesWithRoleType,
} from '@sageworks/dynamic-forms';
import { BeneficialOwnerDataObjectUtils } from '../../../beneficial-owner-data-object-utils';
import { ObjectPropertyValues } from '@sageworks/jpi';
import { MultiLoanApplicationFormModuleState } from '../../../../store/MultiLoanApplicationFormModule/state';
import { locateRole } from '../../../../utils/form-data-utils';
import { LoanApplicationMetadataModuleState } from '../../../../store/LoanApplicationMetadataModule';
import { DataObjectDataHandler } from '../data-object-data-handler';

export class BeneficialOwnerDataHandler extends DataObjectDataHandler {
	constructor(_store: StoreType) {
		super(_store, CustomComponentType.beneficialOwnership, LoanRoleType.BeneficialOwner);
	}

	private get formData() {
		const { renderData } = this._store.state.MultiLoanApplicationForm as MultiLoanApplicationFormModuleState;
		return renderData?.formData ?? {};
	}

	private get beneficialOwnerDataFields() {
		const { presetDataFieldsByDataObject } = this._store.state.LoanApplicationMetadata as LoanApplicationMetadataModuleState;

		return presetDataFieldsByDataObject[DataObject.TypeEnum.BeneficialOwnerDetails] ?? [];
	}

	public async submit(payload: SubmitPayload) {
		const ownerCustomerId = await this.saveCustomer(payload);

		const { submission, metadata } = payload;
		const { parentLoanRoleId } = metadata ?? {};

		const parentLoanRole = locateRole(this.formData, parentLoanRoleId);
		if (parentLoanRole == null) {
			throw new Error(`cannot find parent loan role: ${parentLoanRoleId}`);
		}

		const dataObject = this.createSubmissionDataObject(payload);
		dataObject.parentLoanRoleId = parentLoanRole.dataObjectID;

		// Fill out required fields if this is a new data object
		if (dataObject.id == null || dataObject.id <= 0) {
			this.prepareDataObject(dataObject, submission, parentLoanRole.customerID!);
		}

		const result = await this._store.dispatch.MultiLoanApplicationForm
			.addBeneficialOwner({ dataObject, customerId: parentLoanRole.customerID!, ownerCustomerId });

		return this.createFormObject(payload, result);
	}

	public async delete({ submission }: DeletePayload) {
		const beneficialOwnerId = submission?.form?.data?.id;

		if (beneficialOwnerId == null) {
			return;
		}

		try {
			await this._store.dispatch.MultiLoanApplicationForm.removeBeneficialOwner({ id: beneficialOwnerId });
		} catch (err) {
			throw new Error(`An error occured when deleting the ${LoanRoleType.BeneficialOwner}`);
		}
	}

	protected createFormObject({ submission, metadata }: SubmitPayload, dataFieldValues: ObjectPropertyValues) {
		const { parentLoanRoleId } = metadata ?? {};

		return {
			...submission,
			data: {
				...submission.data,
				...createFormObject(CustomComponentType.beneficialOwnership, dataFieldValues, createBasicFactoryContext(), parentLoanRoleId).data
			},
		};
	}

	private async saveCustomer(payload: SubmitPayload) {
		const { submission, metadata } = payload;
		const propName = Object.keys(submission.data).find(key => CustomerCustomComponentTypes.includes(key as CustomComponentType));
		const ownerCustomerId = submission.data[propName!]?.data?.id;

		if (propName == null) {
			throw new Error('no dataObject of the correct type');
		}

		if (ownerCustomerId == null || ownerCustomerId <= 0) {
			const savedDataObject = await this.saveDataObject(
				DataObjectMapper.submissionDataPropertiesLookup[propName], { submission: submission.data[propName!], metadata });
			submission.data[propName!].data.id = savedDataObject.id;

			return savedDataObject.id;
		} else {
			const dataObjectType = DataObjectMapper.submissionDataPropertiesLookup[propName];

			// We need to still load the owner's data object to ensure the store is up to date
			await this._store.dispatch.MultiLoanApplicationForm.loadDataObjects({
				ids: [ownerCustomerId],
				type: dataObjectType,
				dataFieldMetadata: this._store.state.MultiLoanApplicationForm.renderData?.dataFieldIdsByType ?? {}
			});
		}

		return ownerCustomerId;
	}

	private prepareDataObject(dataObject: ObjectPropertyValuesWithRoleType, submission: any, customerId: number) {
		BeneficialOwnerDataObjectUtils.prepareDataObject(dataObject, submission, customerId, this.beneficialOwnerDataFields);
	}
}
