import { CustomComponentType, CustomValidationRule } from '../../../../../enums';
import { useComponentManager } from '../../../../component-manager';

// eslint-disable-next-line max-lines-per-function
export const createDataTabComponents = (optionComponentType: CustomComponentType) => {
	const { componentManager } = useComponentManager();
	const optionsComponent = componentManager.CustomComponents[optionComponentType] as any;
	return [
		{
			type: optionComponentType,
			input: true,
			label: 'Data Source Values',
			key: 'data.values',
			disableAddingRemovingRows: true,
			tooltip:
				// eslint-disable-next-line max-len
				'Values to use as the data source. Labels are shown in the select field. Values are the corresponding values saved with the submission.',
			weight: 10,
			components: optionsComponent.defaultComponents,
			validations: [
				{
					rule: CustomValidationRule.editableSelectOptionsRequired
				}
			],
			skipMerge: true
		},
		{
			key: 'data.url',
			ignore: true
		},
		{
			key: 'data.headers',
			ignore: true
		},
		{
			key: 'authenticate',
			ignore: true
		},
		{
			key: 'filter',
			ignore: true
		},
		{
			key: 'sort',
			ignore: true
		},
		{
			key: 'selectValues',
			ignore: true
		},
		{
			key: 'valueProperty',
			ignore: true
		},
		{
			key: 'limit',
			ignore: true
		},
		{
			key: 'lazyLoad',
			ignore: true
		},
		{
			key: 'data.values',
			ignore: true
		},
		{
			key: 'multiple',
			ignore: true
		},
		{
			key: 'dataSrc',
			ignore: true
		},
		{
			key: 'dataType',
			ignore: true
		},
		{
			key: 'idPath',
			ignore: true
		},
		{
			key: 'valueProperty',
			ignore: true
		},
		{
			key: 'template',
			ignore: true
		},
		{
			key: 'refreshOn',
			ignore: true
		},
		{
			key: 'clearOnRefresh',
			ignore: true
		},
		{
			key: 'searchEnabled',
			ignore: true
		},
		{
			key: 'selectThreshold',
			ignore: true
		},
		{
			key: 'customOptions',
			ignore: true
		},
		{
			key: 'persistent',
			ignore: true
		},
		{
			key: 'protected',
			ignore: true
		},
		{
			key: 'dbIndex',
			ignore: true
		},
		{
			key: 'encrypted',
			ignore: true
		},
		{
			key: 'calculateServer',
			ignore: true
		}
	];
};
