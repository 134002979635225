import { AutoFactory, DynamicFormsAuthResponse, DynamicApplicationsService } from '@sageworks/jpi';
import 'regenerator-runtime/runtime.js'; // For async tests.'

export default class TokenHandler {
	public static async VerifyOrGetToken(domainName: string): Promise<void> {
		const tokenKey = TokenHandler.GetTokenKey(domainName);
		const tokenExpirationKey = TokenHandler.GetTokenKeyExpiration(domainName);
		let token = localStorage.getItem(tokenKey);
		let expiresAt: Date = new Date(localStorage.getItem(tokenExpirationKey) || '');

		if (token !== null && token.length > 0 && new Date() < expiresAt) {
			return;
		}

		return TokenHandler.GetToken(domainName);
	}

	private static async GetToken(domainName: string) {
		const tokenKey = TokenHandler.GetTokenKey(domainName);
		const tokenExpirationKey = TokenHandler.GetTokenKeyExpiration(domainName);
		const dynamicFormsService: DynamicApplicationsService = AutoFactory.get(DynamicApplicationsService);

		return dynamicFormsService.getToken(domainName).then((response: DynamicFormsAuthResponse) => {
			const token = response.token || '';
			const expiresAt = new Date(response.expiresAtUTC || '');

			if (token === '') {
				throw Error('No token found');
			}

			localStorage.setItem(tokenKey, token as string);
			localStorage.setItem(tokenExpirationKey, expiresAt.toUTCString());

			return;
		});
	}

	public static GetTokenKey(domainName: string) {
		return TokenHandler.GetTokenNamespace(domainName) + 'Token';
	}

	public static GetTokenKeyExpiration(domainName: string) {
		return TokenHandler.GetTokenKey(domainName) + 'Expiration';
	}

	public static GetTokenNamespace(domainName: string) {
		return domainName + 'Formio';
	}
}
