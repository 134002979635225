
import { Component, Mixins } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import { ScaleMixin, RouteMixin } from '@/mixins';
import { OnlinePortalUser } from '@sageworks/jpi';

@Component({
	validations: {
		firstName: {
			required
		},
		lastName: {
			required
		}
	}
})
export default class MyInfo extends Mixins(ScaleMixin, RouteMixin) {
	private firstName?: string = '';
	private lastName?: string = '';
	private email?: string = '';
	private submitting: boolean = false;
	private failedToSaveFeedback: string | null = null;
	private validationMessages: Map<string, string> = new Map([
		['firstName', 'This field is required'],
		['lastName', 'This field is required']
	]);
	private showValidation: boolean = false;
	private auth0FFEnabled: boolean = false;

	private async created() {
		this.auth0FFEnabled = await this.directStore.dispatch.Authentication.isAuth0Enabled();
		await this.directStore.dispatch.User.loadCurrentUser();
		this.firstName = this.directStore.state.User.user!.firstName || '';
		this.lastName = this.directStore.state.User.user!.lastName || '';
		this.email = this.directStore.state.User.user!.email || '';
	}

	private validationState(fieldName: string) {
		if (!this.showValidation) {
			return null;
		} else if (this.$v[fieldName].$invalid) {
			return false;
		}
		return true;
	}

	private async submit(): Promise<void> {
		if (this.$v.$invalid) {
			this.showValidation = true;
			return;
		}

		this.submitting = true;
		try {
			this.showValidation = true;
			this.failedToSaveFeedback = null;
			await this.directStore.dispatch.User.patchCurrentUser({
				firstName: this.firstName,
				lastName: this.lastName
			} as OnlinePortalUser);
		} catch (err) {
			this.showValidation = false;
			this.failedToSaveFeedback = 'Unable to save these changes. Please try again.';
		}
		this.submitting = false;
	}
}
