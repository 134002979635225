export interface Route {
	name: string;
	path: string;
}
export interface Routes {
	[key: string]: Route;
}
export const OnlinePortalRoutes = {
	Login: {
		path: '/login',
		name: 'login',
		title: 'Login'
	},
	LoginEmail: {
		path: '/login/email',
		name: 'login-email',
		title: 'Login'
	},
	LoginPassword: {
		path: '/login/password-input',
		name: 'login-password',
		title: 'Login'
	},
	IdentityProtection: {
		path: '/login/identity-protection',
		name: 'identity-protection',
		title: 'Identity Protection'
	},
	VerifyIdentity: {
		path: '/login/verify-identity',
		name: 'verify-identity',
		title: 'Identity Verification'
	},
	VerifyKnowledgeBasedAnswers: {
		path: '/login/verify-knowledge-based-answers',
		name: 'verify-knowledge-based-answers',
		title: 'Knowledge-based Authentication'
	},
	ForgotPassword: {
		path: '/login/forgot-password',
		name: 'forgot-password',
		title: 'Forgot Password'
	},
	PasswordReset: {
		path: '/login/password-reset',
		name: 'password-reset',
		title: 'Password Reset'
	},
	VerifyEmail: {
		path: '/login/verify-email',
		name: 'verify-email',
		title: 'Verify Email'
	},
	CreatePassword: {
		path: '/login/create-password',
		name: 'create-password',
		title: 'Create Password'
	},
	CreateAccount: {
		path: '/login/create-account',
		name: 'create-account',
		title: 'Create Account'
	},
	SetAccountPassword: {
		path: '/login/set-account-password',
		name: 'set-account-password',
		title: 'Set Password'
	},
	Signup: {
		path: '/signup',
		name: 'signup',
		title: 'Signup'
	},
	SetContactInformation: {
		path: '/login/set-contact-information',
		name: 'set-contact-information',
		title: 'Contact Information'
	},
	Portal: {
		path: '/portal',
		name: 'portal',
		title: 'Portal'
	},
	MyInfo: {
		path: '/portal/my-info',
		name: 'my-info',
		title: 'My Info'
	},
	ChangeEmail: {
		path: '/portal/my-info/email',
		name: 'change-email',
		title: 'Change Email'
	},
	ChangePassword: {
		path: '/portal/my-info/password',
		name: 'change-password',
		title: 'Change Password'
	},
	Home: {
		path: '/portal/home',
		name: 'home',
		title: 'Home'
	},
	Documents: {
		path: '/portal/documents',
		name: 'documents',
		title: 'Documents'
	},
	DocumentRequestDetails: {
		path: '/portal/documents/detail/:documentRequestId',
		name: 'document-request-details',
		title: 'Document Request'
	},
	Applications: {
		path: '/portal/applications',
		name: 'applications',
		title: 'Applications'
	},
	Application: {
		path: '/application',
		name: 'application',
		title: 'Application'
	},
	ApplicationForm: {
		path: '/application/:applicationId([0-9]+)',
		name: 'application-form',
		title: 'Application'
	},
	ApplicationPreview: {
		path: '/application/preview/:formPath(.*)',
		name: 'application-preview',
		title: 'Application Preview'
	},
	ApplicationDocumentation: {
		path: '/application/:applicationId/documentation',
		name: 'application-documentation',
		title: 'Documentation'
	},
	ApplicationSubmit: {
		path: '/application/:applicationId/submit',
		name: 'application-submit',
		title: 'Submit'
	},
	PortalDataLoadRedirect: {
		path: '/login/redirect',
		name: 'portal-data-load-redirect'
	},
	ApplicationDataLoadRedirect: {
		path: '/application/redirect',
		name: 'application-data-load-redirect'
	},
	NotFound: {
		path: '/not-found',
		name: 'not-found',
		title: 'Not Found'
	},
	HealthPage: {
		path: '/health-page',
		name: 'health-page',
		title: 'Health Page'
	},
	PreApplication: {
		path: '/pre-application',
		name: 'pre-application',
		title: 'Pre-Application'
	},
	ExportToPdf: {
		path: '/application/:applicationId/export',
		name: 'export-to-pdf',
		title: 'PDF Export'
	},
	ExportToLenderPdf: {
		path: '/application/:applicationId/export-lender',
		name: 'export-to-lender-pdf',
		title: 'PDF Export'
	}
};
