import editForm from './income.form';
import { CustomComponentType, CustomComponentLabel } from '../../../../enums/custom-component-type';
import { FinancialSubaccount } from '../financial-subaccount/financial-subaccount';

export class IncomeWidget extends FinancialSubaccount {
	static schema(...extend: any) {
		return FinancialSubaccount.schema(
			{
				label: CustomComponentLabel()[CustomComponentType.income],
				type: CustomComponentType.income,
				key: CustomComponentType.income,
				addAnother: 'Add Income'
			},
			...extend
		);
	}

	static editForm = editForm;

	static get builderInfo() {
		return {
			title: CustomComponentLabel()[CustomComponentType.income],
			group: '',
			weight: 10,
			schema: IncomeWidget.schema()
		};
	}
}
