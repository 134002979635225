import { OnlinePortalRoutes } from '@/OnlinePortalRoutes';
import { OnlinePortalCss, LoanApplication } from '@sageworks/jpi';
import { Route } from 'vue-router';
import { getApplicationTypeById } from './get-application-type-by-id';

export const DEFAULT_CSS_CATEGORY: OnlinePortalCss.CssCategoryEnum = OnlinePortalCss.CssCategoryEnum.GeneralPortal;

export async function getCssCategoryForRoute(toRoute: Route): Promise<OnlinePortalCss.CssCategoryEnum> {
	if (!toRoute) return DEFAULT_CSS_CATEGORY;
	if (toRoute.path === OnlinePortalRoutes.ApplicationPreview.path) {
		return _getCssCategoryFromApplicationType(toRoute.params['formPath'] as LoanApplication.TypeEnum);
	}
	if (toRoute.path.includes('redirect')) return DEFAULT_CSS_CATEGORY;

	let loanApplicationIdFromRoute = parseInt(toRoute.params.applicationId);
	if (loanApplicationIdFromRoute) {
		try {
			let appTypeFromRouteId = await getApplicationTypeById(loanApplicationIdFromRoute);
			return _getCssCategoryFromApplicationType(appTypeFromRouteId);
		} catch (err) {
			return DEFAULT_CSS_CATEGORY;
		}
	}
	return DEFAULT_CSS_CATEGORY;
}

function _getCssCategoryFromApplicationType(applicationType: LoanApplication.TypeEnum | null | undefined): OnlinePortalCss.CssCategoryEnum {
	switch (applicationType) {
		case LoanApplication.TypeEnum.Business:
			return OnlinePortalCss.CssCategoryEnum.Business;
		case LoanApplication.TypeEnum.Personal:
			return OnlinePortalCss.CssCategoryEnum.Personal;
		case LoanApplication.TypeEnum.Nonprofit:
			return OnlinePortalCss.CssCategoryEnum.Nonprofit;
		case LoanApplication.TypeEnum.Sba:
			return OnlinePortalCss.CssCategoryEnum.Sba;
		case LoanApplication.TypeEnum.Farm:
			return OnlinePortalCss.CssCategoryEnum.Farm;
		default:
			return DEFAULT_CSS_CATEGORY;
	}
}
