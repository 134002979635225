
import Vue from 'vue';
import { BContainer, BRow, BCol, BNavbar, BCard, BImg, BNavbarBrand } from 'bootstrap-vue';
import HeaderTemplate from './header-template.vue';

export default Vue.extend({
	components: {
		BContainer,
		BRow,
		BCol,
		BNavbar,
		BCard,
		BImg,
		BNavbarBrand,
		HeaderTemplate
	},
	props: {
		backgroundImageSource: {
			type: String,
			required: false,
			default: null
		},
		logoSource: {
			type: String,
			required: false,
			default: null
		}
	},
	computed: {
		backgroundClass: function() {
			if (this.backgroundImageSource) return 'bg-image';
			return 'bg-gradient-primary-75';
		},
		backgroundImageStyle: function() {
			if (this.backgroundImageSource) return 'background-image: url(' + this.backgroundImageSource + ');';
			return '';
		}
	}
});
