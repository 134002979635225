
import { Component, Mixins } from 'vue-property-decorator';
import { HeaderTemplate } from '@/components/templates/';
import { RouteMixin } from '@/mixins/';

@Component({
	components: {
		HeaderTemplate
	}
})
export default class OnlinePortalLoginHeader extends Mixins(RouteMixin) {
	get logoUrl(): string {
		return this.directStore.getters.StylingSettings.logoUrl;
	}
}
