import {
	CustomComponentType,
	DataObjectMapper,
	DataObject,
	SubmitPayload,
	DataHandler,
	DeletePayload,
	createFormObject,
	ObjectPropertyValuesWithRoleType,
	LoanRoleType
} from '@sageworks/dynamic-forms';
import { StoreType } from '../../../../store';
import { ObjectPropertyValues } from '@sageworks/jpi';
import { createFactoryContext } from '../data-handler-utils';
import CreditReportWidget from '@sageworks/dynamic-forms/src/components/form-components/credit-report/credit-report';

export class DataObjectDataHandler implements DataHandler {
	private _dataObjectTypeLookup = new Map<CustomComponentType, DataObject.TypeEnum>([
		[CustomComponentType.personalInfo, DataObject.TypeEnum.Person],
		[CustomComponentType.businessInfo, DataObject.TypeEnum.Business],
		[CustomComponentType.nonprofitInfo, DataObject.TypeEnum.NonProfit],
		[CustomComponentType.farmInfo, DataObject.TypeEnum.Farm],
		[CustomComponentType.collateralInfo, DataObject.TypeEnum.Collateral],
		[CustomComponentType.loans, DataObject.TypeEnum.ProposedLoan],
		[CustomComponentType.personalFinancialStatement, DataObject.TypeEnum.PersonalFinancial],
		[CustomComponentType.income, DataObject.TypeEnum.FinancialSubaccounts],
		[CustomComponentType.expense, DataObject.TypeEnum.FinancialSubaccounts],
		[CustomComponentType.stocksBondsBusinessHoldings, DataObject.TypeEnum.FinancialSubaccounts],
		[CustomComponentType.nonInvestmentRealEstate, DataObject.TypeEnum.FinancialSubaccounts],
		[CustomComponentType.lifeInsurance, DataObject.TypeEnum.FinancialSubaccounts],
		[CustomComponentType.retirementAccount, DataObject.TypeEnum.FinancialSubaccounts],
		[CustomComponentType.investmentRealEstate, DataObject.TypeEnum.RealEstateFinancials],
		[CustomComponentType.businessInvestment, DataObject.TypeEnum.BusinessInvestments],
		[CustomComponentType.otherAssets, DataObject.TypeEnum.FinancialSubaccounts]
	]);

	protected get dataObjectType() {
		return this._dataObjectTypeLookup.get(this._componentType);
	}

	constructor(protected _store: StoreType, protected _componentType: CustomComponentType, protected loanRoleType?: LoanRoleType) {
		if (!_componentType) {
			throw new Error('Must set the component type');
		}
	}

	public async submit(payload: SubmitPayload): Promise<any> {
		const result = await this.saveDataObject(this.dataObjectType!, payload);

		return this.createFormObject(payload, result);
	}

	public async delete({ submission }: DeletePayload) {
		const idToRemove = submission?.form?.data?.id;
		if (idToRemove == null) {
			throw new Error('Object id cannot be null');
		}

		return await this._store.dispatch.MultiLoanApplicationForm.removeDataObject({ dataObjectType: this.dataObjectType!, id: idToRemove });
	}

	protected async saveDataObject(type: DataObject.TypeEnum, payload: SubmitPayload) {
		const dataObject = this.createSubmissionDataObject(payload);

		const savedDataObject: ObjectPropertyValues = await this._store.dispatch.MultiLoanApplicationForm.saveDataObject({ dataObjectType: type, dataObject });

		return savedDataObject;
	}

	protected createSubmissionDataObject({ submission }: SubmitPayload): ObjectPropertyValuesWithRoleType {
		return DataObjectMapper.objectToDataObject(submission, this.loanRoleType);
	}

	protected createFormObject({ submission }: SubmitPayload, dataFieldValues: ObjectPropertyValues) {
		return {
			...submission,
			...createFormObject(
				this._componentType,
				dataFieldValues,
				createFactoryContext(this._store, submission?.data?.[CustomComponentType.creditReport]?.[CreditReportWidget.authorizationKey])
			)
		};
	}
}
