export default {
	weight: 1450,
	type: 'checkbox',
	defaultValue: false,
	disabled: false,
	input: true,
	key: 'hideInPdf',
	label: 'Hide in Exported PDF',
	tooltip: 'Do not show in Application PDF.'
};
