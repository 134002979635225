module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div class="vue-component-container" ref="' +
((__t = ( ctx.ref.vueContainer)) == null ? '' : __t) +
'"></div>\n\n<ul class="vue-formio-edit-rows">\n  ';
 ctx.rows.forEach(function(row, rowIndex) { ;
__p += '\n	<li class="list-group-item ' +
((__t = ( (!ctx.openRows[rowIndex]) ? 'd-none' : '')) == null ? '' : __t) +
'" ref="' +
((__t = (ctx.ref.row)) == null ? '' : __t) +
'">\n		' +
((__t = (row)) == null ? '' : __t) +
'\n		';
 if (ctx.errors[rowIndex]) { ;
__p += '\n			<div class="has-error">\n				<div class="editgrid-row-error help-block">\n					' +
((__t = (ctx.errors[rowIndex])) == null ? '' : __t) +
'\n				</div>\n			</div>\n		';
 } ;
__p += '\n	</li>\n  ';
 }) ;
__p += '\n</ul>\n';
return __p
}