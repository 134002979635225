import Component from 'formiojs/components/_classes/component/Component';
import BaseRepeaterWidget from '../base-repeater-widget/base-repeater-widget';
import { ContextDataProperty, CustomComponentType, CustomerCustomComponentTypes, LoanRoleType } from '../../../../enums';
import { LocateParentHelper } from '../../../../utils';
import { isInPreviewMode } from '../../../../utils/formio-template-helpers/builder-template-helpers';
import formDataHelpers from '../../../../utils/form-data-helpers';
import { DataField } from '@sageworks/jpi';
import { configureLinkBehavior } from '../edit-link-helper';
import { getContextDataValue } from '../../../../utils/context-data-helper/context-data-helper';
import AddCustomerPopup from '../../add-customer/add-customer-popup';
import { Utils as FormioUtils } from 'formiojs';

export class BaseRoleRepeaterWidget extends BaseRepeaterWidget {
	public roleType!: LoanRoleType;
	protected allowedDataFields!: DataField[];
	protected _closestCustomerComponent: Component | undefined;

	protected get parentLoanRoleId() {
		return this.dataValue?.data?.parentLoanRoleId ?? this._closestCustomerComponent?.dataValue?.data?.parentLoanRoleId;
	}

	public get isLender() {
		return getContextDataValue(this, ContextDataProperty.IsLender);
	}

	init() {
		if (!this.roleType) {
			throw new Error(`[${this.constructor.name}]: No loan role type is set.`);
		}

		this.allowedDataFields = this.allowedDataFields ?? [];

		super.init();
	}

	protected async createAddNewCustomerPopup(rowIndex: number, roleType: LoanRoleType, editMode = false) {
		const ignoredCustomerIds = this.getAllCustomerIDs();

		const applicationEntities = await this.getApplicationEntities(false, CustomerCustomComponentTypes, ignoredCustomerIds);
		const editRow = this.editRows[rowIndex];
		return new AddCustomerPopup(
			AddCustomerPopup.schema({
				roleType: roleType,
				addNewEntityDataFields: this.allowedDataFields,
				applicationEntities: applicationEntities,
				ignoredCustomerIds: ignoredCustomerIds
			}),
			{
				...this.options,
				parentVisible: true,
				skipInit: false,
				editMode: editMode
			},
			(FormioUtils as any).fastCloneDeep(editRow.data)
		);
	}

	async attach(element: HTMLElement) {
		await super.attach(element);

		if (this.builderMode) {
			configureLinkBehavior(this, element);
			return;
		} else if (isInPreviewMode(this)) {
			return;
		}

		this._closestCustomerComponent = LocateParentHelper.locateClosestCustomerParent(this);
	}

	protected getAllCustomerIDs() {
		return this.editRows.map<number>(editRow => {
			const data = editRow?.data?.form?.data ?? {};

			if (data[CustomComponentType.personalInfo]) {
				return data[CustomComponentType.personalInfo]?.data?.id;
			} else if (data[CustomComponentType.businessInfo]) {
				return data[CustomComponentType.businessInfo]?.data?.id;
			} else if (data[CustomComponentType.nonprofitInfo]) {
				return data[CustomComponentType.nonprofitInfo]?.data?.id;
			} else if (data[CustomComponentType.farmInfo]) {
				return data[CustomComponentType.farmInfo]?.data?.id;
			} else {
				return null;
			}
		});
	}

	protected async getApplicationEntities(isPrimaryRole: boolean, allowedEntities: CustomComponentType[], ignoredCustomerIds: Number[]) {
		return (await formDataHelpers.getApplicationEntities(this, isPrimaryRole, allowedEntities)).filter(
			x => !ignoredCustomerIds.includes(parseInt(x.value))
		);
	}
}
