
import { Component, Mixins } from 'vue-property-decorator';
import { ScaleMixin, RouteMixin } from '@/mixins';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import { ValidationUtils } from '@/utils';
import { OnlinePortalUserPasswordResetRequest } from '@sageworks/jpi';

@Component({
	validations: {
		password: {
			required,
			minLength: minLength(8),
			containsUppercase: ValidationUtils.containsUppercase,
			containsAlpha: ValidationUtils.containsAlpha,
			containsNumeric: ValidationUtils.containsNumeric
		},
		confirmation: {
			required,
			sameAs: sameAs('password')
		}
	}
})
export default class PasswordReset extends Mixins(ScaleMixin, RouteMixin) {
	private password: string = '';
	private confirmation: string = '';
	private showValidation: boolean = false;
	private validationMessages: Map<string, string> = new Map([
		['password', 'Password must meet all requirements'],
		['confirmation', 'Passwords must match']
	]);
	private showSuccessMessage: boolean = false;

	private resetFailedErrorMessage: string = 'There was an error resetting your password. If this continues to occur, please contact customer support.';
	private showResetErrorMessage: boolean = false;

	private validationState(fieldName: string) {
		if (!this.showValidation) {
			return null;
		} else if (this.$v[fieldName].$invalid) {
			return false;
		}
		return true;
	}

	private async submit(): Promise<void> {
		this.showValidation = true;
		this.showResetErrorMessage = false;
		if (this.$v.$invalid) {
			return;
		}
		try {
			let passwordResetRequest: OnlinePortalUserPasswordResetRequest = {
				newSecret: this.password,
				authToken: this.directStore.state.Authentication.userToken || undefined
			};
			await this.directStore.dispatch.Login.resetUserPassword(passwordResetRequest);
		} catch (err) {
			this.showResetErrorMessage = true;
		} finally {
			this.showSuccessMessage = true;
		}
	}
}
