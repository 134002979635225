export default [
	{
		key: 'inputFormat',
		ignore: true
	},
	{
		key: 'case',
		ignore: true
	}
];
