import Router from 'vue-router';
import { OnlinePortalRoutes } from '@/OnlinePortalRoutes';

export function navigateToApplicationForm(router: Router, applicationId: string | number) {
	router.push({
		name: OnlinePortalRoutes.ApplicationForm.name,
		params: {
			applicationId: '' + (applicationId ?? '')
		}
	});
}

export function navigateToApplicationDocumentation(router: Router, applicationId: string | number) {
	router.push({
		name: OnlinePortalRoutes.ApplicationDocumentation.name,
		params: {
			applicationId: '' + (applicationId ?? '')
		}
	});
}

export function navigateToApplicationSubmit(router: Router, applicationId: string | number) {
	router.push({
		name: OnlinePortalRoutes.ApplicationSubmit.name,
		params: {
			applicationId: '' + (applicationId ?? '')
		}
	});
}
