module.exports = function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<div>\n	<div class="modal-body d-flex flex-column align-items-center">\n		<div class="py-4">\n			';
 if (ctx.errorMessages.length === 0) { ;
__p += '\n				<span>' +
((__t = ( ctx.successMessage )) == null ? '' : __t) +
'</span>\n			';
 } else if (ctx.errorMessages.length > 0 && ctx.isLender) { ;
__p += '\n				<div class="d-flex flex-column align-items-center">\n					<span class="font-weight-bold">Error(s) found:</span>\n					<ul class="list-style-none overflow-y-auto p-0" style="max-height: 200px;">\n						';
 ctx.errorMessages.forEach(function(errorMessage) { ;
__p += '\n							<li>' +
((__t = ( errorMessage )) == null ? '' : __t) +
'</li>\n						';
 }) ;
__p += '\n					</ul>\n				</div>\n			';
 } else if (ctx.errorMessages.length > 0 && !ctx.isLender) { ;
__p += '\n				<span>' +
((__t = ( ctx.borrowerErrorMessage )) == null ? '' : __t) +
'</span>\n			';
 } ;
__p += '\n		</div>\n	</div>\n	<footer class="modal-footer d-flex justify-content-start">\n		<button class="btn btn-primary" ref="dialogPullCreditCompleteCloseButton">Close</button>\n	</footer>\n</div>	\n';
return __p
}