<template>
	<div>
		<b-sidebar
			v-model="showSidebar"
			title="demographic Info"
			variant="dark"
			backdrop
			shadow
			width="90%"
			bg-variant="white"
			right
			no-header
			body-class="overflow-hidden"
			@hidden="drawerHidden()"
		>
			<template #default="{ hide }">
				<sidebar-close-button @click="hide" />
				<slot name="drawer-content" class="h-100 pt-4"></slot>
			</template>
		</b-sidebar>
	</div>
</template>

<script>
import Vue from 'vue';
import SidebarCloseButton from './sidebar-close-button.vue';
export default Vue.extend({
	name: 'DemographicInfo',
	components: {
		SidebarCloseButton
	},
	data() {
		return {
			showSidebar: true
		};
	},
	methods: {
		drawerHidden() {
			this.$emit('drawer-hidden');
		}
	}
});
</script>
