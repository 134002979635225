import { CustomComponentType } from '../../../enums';

const DEFAULT_DROPDOWN_URL_HEADERS = [
	{
		key: 'authorization',
		value: 'Bearer {{Formio.apiToken}}'
	}
];

export interface SetDropdownPropertiesOptions {
	builderAndPreviewModeBasePath?: string;
	path: string;
	selectValues?: string;
	searchField?: string;
	valueProperty?: string;
	template?: string;
}

export const setDropdownProperties = (properties: any, options: SetDropdownPropertiesOptions) => {
	const { selectValues, searchField, valueProperty, template, builderAndPreviewModeBasePath = '', path } = options;

	properties.selectValues = selectValues ?? 'items';
	properties.searchField = searchField ?? 'label';
	properties.valueProperty = valueProperty ?? 'value';
	if (template) properties.template = template;

	const isInBuilderOrPreviewMode = '((instance.builderMode || builderTemplateHelpers.isInPreviewMode(instance))';
	const baseUrl = `{{Formio.apiUrl}}/v1/{{ ${isInBuilderOrPreviewMode} ? "${builderAndPreviewModeBasePath}" : "online-portal/") }}`;
	properties.data = {
		url: `${baseUrl}${path}`,
		headers: DEFAULT_DROPDOWN_URL_HEADERS
	};
};

export const setDataFieldDropdownProperties = (properties: any) => {
	setDropdownProperties(properties, {
		path: 'property-options/{{component.dataFieldId}}?perPage={{limit}}&page={{page + 1}}'
	});
};

export const setSharedSelectProperties = (properties: any) => {
	properties.type = CustomComponentType.extendedSelectField;
};

export const setCustomSelectProperties = (properties: any) => {
	properties.dataSrc = 'custom';
};

export const setUrlSelectProperties = (properties: any) => {
	properties.dataSrc = 'url';
	properties.lazyLoad = false;
};
