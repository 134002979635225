import {
	AutoFactory,
	OnlinePortalUCSCredentialsService,
	CreditReportCredential
} from '@sageworks/jpi';

export const getAllCredentials = async (): Promise<CreditReportCredential[]> => {
	let service = AutoFactory.get(OnlinePortalUCSCredentialsService);

	let result = await service.getAllCredentials();

	return result.items ?? [] as CreditReportCredential[];
};

export const getDefaultCredential = async () : Promise<CreditReportCredential | null> => {
	let result = await getAllCredentials();

	let item = result
		?.filter(c => c.isOrginazationLevelDefault)
		?.pop();

	return  item ?? null;
};