import SelectFieldComponent from 'formiojs/components/select/Select';
import editForm from './extended-select-field.form';
import { CustomComponentType, KeyCode } from '../../../../enums';
import { getKey, checkConditionalityAcrossForms, handleSetDefaultValue, shouldSelectFieldUseOriginalConditionality } from '../extended-field-helper';
import { shouldUpdateOriginalLabel, updateOriginalLabel } from '../../../../utils/data-field-metadata/data-field-metadata-utils';
import _ from 'lodash';
import { Utils as FormioUtils } from 'formiojs';
import { FormioConditionalityUtils } from '../../../../utils/formio-conditionality-utils';
import { PromiseHelper } from '@sageworks/core-logic';
import { shouldSortOptionsForField } from './extended-select-field-helper';

export default class ExtendedSelectField extends SelectFieldComponent {
	dataFieldTypeMapping: any = {};

	constructor(component: any, options: any, data: object) {
		super(component, options, data);
		// Increased paging limit due to Formio's paging issue
		this.component.limit = 1000;
		this.component.customOptions = {
			...this.component.customOptions ?? {},
			shouldSort: true,
		};
	}

	static schema(...extend: any) {
		return SelectFieldComponent.schema(
			{
				type: CustomComponentType.extendedSelectField,
				allowCustomValues: false,
				forceRequired: false,
				dataFieldId: null,
				customOptions: {
					shouldSort: true,
				}
			},
			...extend
		);
	}

	static get builderInfo() {
		return {
			title: 'Select Field',
			group: '',
			weight: 10,
			schema: ExtendedSelectField.schema()
		};
	}

	static editForm = editForm;

	// @ts-ignore
	set visible(value: boolean) {
		var isChanged = this.visible !== value;

		super.visible = value;

		if (isChanged) {
			this.setValue(this.dataValue);
		}
	}

	get visible() {
		return super.visible;
	}

	// @ts-ignore
	get key(): string {
		return getKey(this, super.key);
	}

	get shouldAddDefaultValue() {
		return !this.options.noDefaults || !this.isEmpty(this.component.defaultValue) || this.component.customDefaultValue;
	}

	// eslint-disable-next-line max-lines-per-function
	async attach(element: HTMLElement) {
		const superAttachResult = (await super.attach(element)) ?? Promise.resolve();

		// This does not use the existing helper to do oddities in the refs provided by the select component
		if (this.component?.dataFieldId == null || (typeof this.component.dataFieldId === 'string' && this.component.dataFieldId.trim() === '')) {
			return;
		}

		let event = `${this.component.dataFieldId}.${this.inputInfo.changeEvent}`;

		this.addEventListener(this.refs.selectContainer as any, this.inputInfo.changeEvent, (e: Event) => {
			const x = (e?.target as HTMLInputElement)?.value;

			this.emit(event, { value: x, id: this.id, dataId: this.root.data.id });
		});

		if (this.component.allowCustomValues && this.choices?.input?.element) {
			this.addEventListener(this.choices.input.element, 'keydown', (e: any) => {
				if (e.keyCode === KeyCode.Enter) {
					// Set data to new value and add to list
					this.dataValue = e.target.value;
					this.addValueOptions();
					this.setChoicesValue(this.dataValue);

					// Closes dropdown
					this.choices.hideDropdown(true);
					this.choices.clearInput();
				}
			});
		}

		this.on(
			event,
			({ value, id, dataId }: any) => {
				if (id !== this.id && dataId === this.root.data.id) {
					// use updateValue if need other events to fired
					// however, we want to bypass events and just set the value
					this.setValue(value);
				}
			},
			true
		);

		if (shouldUpdateOriginalLabel(this)) {
			await updateOriginalLabel(this);
		}

		return superAttachResult;
	}

	/**
	 * Method was copied from Formiojs's source code and slightly modified so we could allow passing in boolean values
	 *
	 * Original Method: https://github.com/formio/formio.js/blob/243858a82803e15a53c59f13456d6e9ff6263344/src/components/select/Select.js#L251
	 *
	 * @param value
	 * @param label
	 * @param attrs
	 */

	addOption(value: any, label: any, attrs = {}) {
		if (this.component.dataType === 'string') {
			value = this.normalizeSingleValue(value);
		}

		const id = FormioUtils.getRandomComponentId();
		if (_.isNil(label)) return;
		const idPath = this.component.idPath ? this.component.idPath.split('.').reduceRight((obj: any, key: any) => ({ [key]: obj }), id) : {};
		const option = {
			value: this.getOptionValue(value),
			label,
			...idPath
		};

		const skipOption = this.component.uniqueOptions
			? !!(this.selectOptions as any).find((selectOption: { value: any }) => _.isEqual(selectOption.value, option.value))
			: false;

		if (skipOption) {
			return;
		}
		// this check is the only thing that was changed from the original implementaiton
		if (value != null) {
			(this.selectOptions as any).push(option);
		}

		if (this.refs.selectContainer && this.component.widget === 'html5') {
			this.compomentIsHtml5(option, value, id, attrs);
		}
	}

	compomentIsHtml5(option: any, value: any, id: any, attrs: any) {
		// Replace an empty Object value to an empty String.
		if (option.value && _.isObject(option.value) && _.isEmpty(option.value)) {
			option.value = '';
		}
		// Add element to option so we can reference it later.
		const div = document.createElement('div');
		div.innerHTML = this.sanitize(
			this.renderTemplate('selectOption', {
				selected: _.isEqual(this.dataValue, option.value),
				option,
				attrs,
				id,
				useId: this.valueProperty === '' && _.isObject(value) && id
			})
		).trim();

		option.element = div.firstChild;
		(this.refs.selectContainer as any).appendChild(option.element);
	}

	/**
	 * Method was copied directly from FormioJs's source code. the old code had an issue that did not allow us to use boolean values in radio buttons
	 *
	 * Original Method: https://github.com/formio/formio.js/blob/243858a82803e15a53c59f13456d6e9ff6263344/src/components/select/Select.js#L318
	 *
	 * @param items
	 * @param fromSearch
	 */

	// eslint-disable-next-line max-lines-per-function
	setItems(items: any, fromSearch?: boolean) {
		// If the items is a string, then parse as JSON.
		if (typeof items === 'string') {
			try {
				items = JSON.parse(items);
			} catch (err) {
				// @ts-ignore
				// eslint-disable-next-line no-console
				console.warn(err.message);
				items = [];
			}
		}

		// Allow js processing (needed for form builder)
		if (this.component.onSetItems && typeof this.component.onSetItems === 'function') {
			const newItems = this.component.onSetItems(this, items);
			if (newItems) {
				items = newItems;
			}
		}

		if (!this.choices && this.refs.selectContainer) {
			this.empty(this.refs.selectContainer as any);
		}

		// If they provided select values, then we need to get them instead.
		if (this.component.selectValues) {
			items = _.get(items, this.component.selectValues, items) || [];
		}

		let areItemsEqual;

		if (this.isInfiniteScrollProvided) {
			areItemsEqual = this.isSelectURL ? _.isEqual(items, this.downloadedResources) : false;

			const areItemsEnded = this.component.limit > items.length;
			const areItemsDownloaded = areItemsEqual && this.downloadedResources && this.downloadedResources.length === items.length;

			if (areItemsEnded) {
				this.disableInfiniteScroll();
			} else if (areItemsDownloaded) {
				this.selectOptions = [];
			} else {
				this.serverCount = items.serverCount;
			}
		}

		if (this.isScrollLoading && items) {
			if (!areItemsEqual) {
				this.downloadedResources = this.downloadedResources ? this.downloadedResources.concat(items) : items;
			}

			this.downloadedResources.serverCount = items.serverCount || this.downloadedResources.serverCount;
		} else {
			this.downloadedResources = items || [];
			this.selectOptions = [];
			// If there is new select option with same id as already selected, set the new one
			if (!_.isEmpty(this.dataValue) && this.component.idPath) {
				const selectedOptionId = _.get(this.dataValue, this.component.idPath, null);
				const newOptionWithSameId =
					!_.isNil(selectedOptionId) &&
					items.find((item: any) => {
						const itemId = _.get(item, this.component.idPath);

						return itemId === selectedOptionId;
					});

				if (newOptionWithSameId) {
					this.setValue(newOptionWithSameId);
				}
			}
		}

		// Add the value options.
		if (!fromSearch) {
			this.addValueOptions(items);
		}

		if (this.component.widget === 'html5' && !this.component.placeholder) {
			this.addOption(null, '');
		}

		// Iterate through each of the items.
		_.each(items, item => {
			// preventing references of the components inside the form to the parent form when building forms
			if (this.root && this.root.options.editForm && this.root.options.editForm._id && this.root.options.editForm._id === item._id) return;
			this.addOption(this.itemValue(item), this.itemTemplate(item), {});
		});

		if (this.choices) {
			this.choices.setChoices(this.selectOptions, 'value', 'label', true);
		} else if (this.loading) {
			// Re-attach select input.
			// this.appendTo(this.refs.input[0], this.selectContainer);
		}

		// We are no longer loading.
		this.isScrollLoading = false;
		this.loading = false;

		const searching = fromSearch && this.choices?.input?.isFocussed;

		if (!searching) {
			// If a value is provided, then select it.
			if (!this.isEmpty()) {
				this.setValue(this.dataValue, {
					noUpdateEvent: true
				});
			} else if (this.shouldAddDefaultValue) {
				// If a default value is provided then select it.
				const defaultValue = this.defaultValue;
				if (!this.isEmpty(defaultValue)) {
					this.setValue(defaultValue);
				}
			}
		}

		// Say we are done loading the items.
		this.itemsLoadedResolve();
	}

	conditionallyVisible(data: any): boolean {
		if (shouldSelectFieldUseOriginalConditionality(this)) {
			return super.conditionallyVisible(data);
		}

		return checkConditionalityAcrossForms(this);
	}

	checkCondition(row: any, data: any) {
		return FormioConditionalityUtils.checkCondition(
			this.component,
			row || this.data,
			data || this.rootValue,
			this.root ? (this.root as any)._form : {},
			this
		);
	}

	/**
	 * Set the value at a specific index.
	 *
	 * @param index
	 * @param value
	 */
	setValueAt(index: number, value: number, flags: any = {}) {
		const result = super.setValueAt(index, value, flags);
		handleSetDefaultValue(this, index, value, flags);

		return result;
	}

	choicesOptions() {
		const options = super.choicesOptions();

		if (shouldSortOptionsForField(this.component)) {
			options.shouldSort = true;
		}

		if (this.component.allowCustomValues) {
			const existingText = options.noResultsText;
			options.noResultsText = () => {
				if (!this.choices) {
					return existingText;
				}

				const value = this.choices?.input?.element?.value ?? '';
				return `Press Enter to add <b>${value}</b>`;
			};
		}

		return options;
	}

	/**
	 * Method was copied from Formiojs's source code and slightly modified so we could allow passing in a component's key
	 * instead of the component's path
	 *
	 * Original Method: https://github.com/formio/formio.js/blob/243858a82803e15a53c59f13456d6e9ff6263344/src/components/_classes/component/Component.js#L1218
	 *
	 * @param refreshData
	 * @param changed
	 * @param flags
	 */
	checkRefresh(refreshData: string, changed: any, flags: any) {
		const changePath = _.get(changed, 'instance.path', false);
		// Don't let components change themselves.
		if (changePath && this.path === changePath) {
			return;
		}

		const valuesToMatch = [(FormioUtils as any).getComponentPathWithoutIndicies(changePath), _.get(changed, 'instance.key', false)];

		if (refreshData === 'data') {
			this.refresh(this.data, changed, flags);
		} else if (
			changePath &&
			valuesToMatch.includes(refreshData) &&
			changed?.instance &&
			// Make sure the changed component is not in a different "context". Solves issues where refreshOn being set
			// in fields inside EditGrids could alter their state from other rows (which is bad).
			this.inContext(changed.instance)
		) {
			this.refresh(changed.value, changed, flags);
		}
	}

	/*
	 *	Begin copied code from newer Formio version
	 *	Allows the component.data.custom function to make async calls
	 */

	getCustomItems() {
		const customItems = this.evaluate(
			this.component.data.custom,
			{
				values: []
			},
			'values'
		);

		this.asyncValues = PromiseHelper.isPromise(customItems);

		return customItems;
	}

	asyncCustomValues() {
		if (!_.isBoolean(this.asyncValues)) {
			this.getCustomItems();
		}

		return this.asyncValues;
	}

	updateCustomItems(forceUpdate: boolean = true) {
		if (this.asyncCustomValues()) {
			if (!forceUpdate && !this.active) {
				this.itemsLoadedResolve();
				return;
			}

			this.loading = true;
			this.getCustomItems()
				.then((items: any) => {
					this.loading = false;
					this.setItems(items || []);
				})
				.catch((err: any) => {
					this.handleLoadingError(err);
				});
		} else {
			this.setItems(this.getCustomItems() || []);
		}
	}

	/*
	 *	End copied code from Formio
	 */

	addPlaceholder() {
		// Formio override that prevents the placeholder from appearing in the select options
	}
}
