import {
	CustomComponentType,
	FinancialStatementType,
	LiabilityCustomFieldKey,
	OtherLiabilitiesUtils,
	SubmitPayload,
} from '@sageworks/dynamic-forms';
import { formatDataFieldIdKey } from '@sageworks/dynamic-forms/src/components/form-components/simple-inputs/extended-field-helper';
import { ObjectPropertyValues } from '@sageworks/jpi';
import { FinancialSubaccountDataHandler } from '../financial-subaccount-data-handler/financial-subaccount-data-handler';

export class OtherLiabilitiesDataHandler extends FinancialSubaccountDataHandler {

	public async submit(payload: SubmitPayload): Promise<any> {
		if (this.missingValues(payload)) {
			return;
		}

		// Grab the custom fields' values so we can format the subaccount's name and parent type correctly
		const dueTo = payload.submission.data[LiabilityCustomFieldKey.DueTo];
		const liabilityType = payload.submission.data[LiabilityCustomFieldKey.LiabilityType];
		const dueDate = payload.submission.data[LiabilityCustomFieldKey.DueDate];

		const nameFieldKey = formatDataFieldIdKey(this.nameDataFieldId.toString());
		const parentFieldKey = formatDataFieldIdKey(this.parentDataFieldId.toString());

		payload.submission.data[nameFieldKey] = OtherLiabilitiesUtils.createSubaccountName(dueTo, liabilityType, dueDate);
		payload.submission.data[parentFieldKey] = OtherLiabilitiesUtils.calculateParent(liabilityType, dueDate);

		return await super.submit(payload);
	}

	protected getFinancialStatementName(widgetType: CustomComponentType) {
		switch (widgetType) {
			case CustomComponentType.otherLiabilities:
				return FinancialStatementType.Liabilities;
			default:
				throw Error('Widget type is not mapped to a financial statement name');
		}
	}

	protected createFormObject(submitPayload: SubmitPayload, dataFieldValues: ObjectPropertyValues) {
		const updatedFormObject = super.createFormObject(submitPayload, dataFieldValues);

		// These values might change since when we re-create the form object it uses the financial subaccounts to set the values which
		// 	might be stale since only the data object is updated, so we update them here to ensure they remain the same
		updatedFormObject.data[LiabilityCustomFieldKey.LiabilityType] = submitPayload.submission.data[LiabilityCustomFieldKey.LiabilityType];
		updatedFormObject.data[LiabilityCustomFieldKey.DueDate] = submitPayload.submission.data[LiabilityCustomFieldKey.DueDate];
		updatedFormObject.data[LiabilityCustomFieldKey.DueTo] = submitPayload.submission.data[LiabilityCustomFieldKey.DueTo];

		return updatedFormObject;
	}
}
